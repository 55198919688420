/**
 * Main AxDroppable directive DOD (Directive Object Declaration)
 */
function AxDroppable() {

  return {
    restrict: 'A',

    link: function ($scope, $element, $attrs, $ctrl) {

      $element.attr('droppable', true);

      $element.on("dragenter", function (evt) {
        $scope.$apply(function () {
          $scope.$eval($element.attr("ax-on-drag-enter"), { evt: evt });
        });
        return false;
      });

      $element.on('dragleave', function (evt) {
        $scope.$apply(function () {
          $scope.$eval($element.attr("ax-on-drag-leave"), { evt: evt });
        });
      });

      $element.on('drop', function (evt) {
        $scope.$apply(function () {
          $scope.$eval($element.attr("ax-on-drop"), { evt: evt });
        });

      });

      $element.on("dragover", function (evt) {
        //evt = evt.originalEvent||evt;
        evt.originalEvent.dataTransfer.dropEffect = 'copy';
        $scope.$apply(function () {
          $scope.$eval($element.attr("ax-on-drag-over"), { evt: evt });
        });
        // Necessary. Allow us to drop
        evt.preventDefault();
        return false;
      });

    }
  }
}

export { AxDroppable };
