import template from './AxSurveySingleFieldCheckBox.html';

function AxSurveySingleFieldCheckBox() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  vm.axC.setValue = function (val) {
    if (val || val === false) {
      vm.axC.Value = helpers.isTrue(val) ? "True" : "False";
    } else {
      vm.axC.Value = null;
    }
    return true;
  };
  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      if (!val && val !== false) {
        return "fieldIsRequired";
      }
    }
    return null;
  };
  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.input = $element.find("input[type=checkbox]").on("click", function () {
    var value = $scope.vm.input.prop("checked");
    var oldValue = $scope.vm.axC.getValue();
    if (value === true) {
      $scope.vm.axC.setValue(true);
    }
    if (value === false) {
      $scope.vm.axC.setValue(false);
    }
    $scope.vm.onValueSet($scope.vm.axC);
  });
  var val = $scope.vm.axC.getValue();
  if (val || val === false) {
    $scope.vm.input.prop("checked", val === "True");
  }
}

export { AxSurveySingleFieldCheckBox }
