import * as React from 'react';
import {
  MicrophoneBasicIcon, MicrophoneSlashIcon
} from '../components/icons';
import { MediaControlProps } from '../models';

export const MuteAudioButton = ({ media }: { media: MediaControlProps | any }) => {
  const { isMuted, mute } = media;
  return (
    <button onClick={mute}>
      {isMuted ? (
        <MicrophoneSlashIcon/>
      ) : (
        <MicrophoneBasicIcon/>
      )}
    </button>
  );
};
