/**
 * Base Error object
 * @class
 * @param {Object} o
 * @param {Number} o.code
 * @param {String} [o.message=""]
 * @param {?Object} [o.data=null]
 */
function Error(o) {
  this.code = o.code;
  this.message = "message" in o ? o.message : "";
  this.data = "data" in o ? o.data : null;
}

Error.prototype = Object.create(Object.prototype, /** @lends Error.prototype */ {
  code: { value: null, writable: true },
  message: { value: "", writable: true },
  data: { value: null, writable: true }
});

function SERVICE_UNAVAILABLE() {
}

SERVICE_UNAVAILABLE.prototype = Object.create(Error.prototype, {
  code: { value: 2, enumerable: true },
  message: { value: "Service unavailable", enumerable: true }
});

function OTHER_EXCEPTION() {
}

OTHER_EXCEPTION.prototype = Object.create(Error.prototype, {
  code: { value: 3, enumerable: true },
  message: { value: "Other exception", enumerable: true }
});

function CONNECTION_REFUSED() {
}

CONNECTION_REFUSED.prototype = Object.create(Error.prototype, {
  code: { value: 4, enumerable: true },
  message: { value: "Connection refused", enumerable: true }
});

function INVALID_TOKEN() {
}

INVALID_TOKEN.prototype = Object.create(Error.prototype, {
  code: { value: 5, enumerable: true },
  message: { value: "Invalid token", enumerable: true }
});

function UNKNOWN_ERROR() {
}

UNKNOWN_ERROR.prototype = Object.create(Error.prototype, {
  code: { value: 255, enumerable: true },
  message: { value: "Unknown error", enumerable: true }
});

var Errors = {
  Error: Error,
  CommonRestErrors: Object.create(null, {
    SERVICE_UNAVAILABLE: {
      value: SERVICE_UNAVAILABLE,
      enumerable: true
    },
    OTHER_EXCEPTION: {
      value: OTHER_EXCEPTION,
      enumerable: true
    },
    CONNECTION_REFUSED: {
      value: CONNECTION_REFUSED,
      enumerable: true
    },
    INVALID_TOKEN: {
      value: INVALID_TOKEN,
      enumerable: true
    },
    UNKNOWN_ERROR: {
      value: OTHER_EXCEPTION,
      enumerable: true
    }
  })
};

export { Errors };
