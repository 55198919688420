import template from './AxDataRecordAbortEdit.html';

function AxDataRecordAbortEdit() {
  return {
    restrict: 'A',
    scope: {
      li18n: '=axI18n',
      inter: "=axInteractionItem",
      name: "=axName",
      editValue: "=ngModel",
      userId: '=axUserId',
      terminateSessionReasons: "=axTerminateSessionReasons",
      orientation: "=axOrientation",
      callSessionId: '=axCallSessionId',
      campaignId: "=axCampaignId",
      recordId: "=axRecordId",
      sessionWasAborted: "=axSessionWasAborted",
      recordWasShifted: "=axRecordWasShifted",
      dataRecordPhoneIndex: "=axDataRecordPhoneIndex",
      mandatory: "=axMandatory"
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', '$rootScope', controller]
  }
}

function controller(helpers, $rootScope) {
  var vm = this;
  vm.message = null;
  vm.isSending = false;
  vm.helpers = helpers;
  vm.editValue = vm.editValue || 0;
  vm.isTrue = helpers.isTrue;
  vm.allwoShiftOrAbort = function () {
    if (vm.recordWasShifted == null && vm.sessionWasAborted == null) {
      return true;
    }
    return false;
  };
  vm.canAbortSession = function () {
    if (vm.allwoShiftOrAbort() === false && vm.isSending == false) {
      return false;
    }
    return true;
  };
  vm.abortSession = function () {
    if (vm.canAbortSession() === false) {
      return;
    }
    var result = confirm(vm.li18n.messages.recordAbortingMsg);
    if (result === false) {
      return;
    }
    vm.isSending = true;
    //sessionId, dataRecordId, campaignId, terminateReason, dataRecordPhoneIndex, callback
    vm.helpers.terminateSession(vm.callSessionId, vm.recordId, vm.campaignId, vm.editValue, vm.dataRecordPhoneIndex, function (res) {
      $rootScope.$apply(function () {
        vm.sessionWasAborted = res;
      });
      if (res === ax.SessionLib.AxTerminateRecordRequestResult.Terminated) {
        vm.helpers.sendTerminateSessionAction(res);
      }
      vm.isSending = false;
    });
  };

  vm.items = [];
  if (vm.terminateSessionReasons && vm.terminateSessionReasons.length > 0) {
    vm.terminateSessionReasons.map(function (tr) {
      vm.items.push({ "Key": tr, "Value": vm.li18n.axTerminateSessionReason[tr] });
    });
  }
  else {
    for (var key in ax.SessionLib.axTerminateSessionReason) {
      vm.items.push({
        "Key": ax.SessionLib.axTerminateSessionReason[key],
        "Value": vm.li18n.axTerminateSessionReason[ax.SessionLib.axTerminateSessionReason[key]]
      });
    }
  }

}

function link($scope, $element, $attrs, $ctrl) {
  $scope.$watch("vm.sessionWasAborted", function () {
    var val = $scope.vm.sessionWasAborted;
    if (val || val === 0) {
      if (val === ax.SessionLib.AxTerminateRecordRequestResult.Terminated) {
        $scope.vm.message = "[color=green]" + $scope.vm.li18n.messages.recordTerminatedSuccesfully + "[color]";
      } else if ( val == ax.SessionLib.AxTerminateRecordRequestResult.InvalidRecordState ) {
        $scope.vm.message = "[color=green]" + $scope.vm.li18n.messages.unableToTerminateRecord + ": " + $scope.vm.li18n.messages.invalidRecordState + "[color]";
      } else if (val == ax.SessionLib.AxTerminateRecordRequestResult.InvalidRecord ) {
        $scope.vm.message = "[color=green]" + $scope.vm.li18n.messages.unableToTerminateRecord + ": " + $scope.vm.li18n.messages.invalidRecord + "[color]";
      } else {
        $scope.vm.message = "[color=red]" + $scope.vm.li18n.messages.unableToTerminateRecord + ": " + $scope.vm.li18n.messages.providerError + "[color]";
      }
    }
    else {
      $scope.vm.message = null;
    }
  });
}

export { AxDataRecordAbortEdit };
