function UfpService($http, SettingsData, CONFIG) {

  this.login = login;
  this.getMenu = getMenu;
  this.switchLang = switchLang;
  this.logout = logout;
  this.execCustomAction = execCustomAction;

  /**
   * Login user to UFP
   * @param {Object} Parameters
   * @param {String} p.url
   * @param {Boolean} [p.withCredentials=false]
   * @param {String} p.login
   * @param {String} p.password - must be already hashed
   * @return {Sring} Promise Promise resolved with response data
   */
  function login(p) {

    return new Promise(function (resolve, reject) {

      $http.post(p.url,
        {
          username: p.login,
          password: p.password
        },
        { withCredentials: p.withCredentials ? p.withCredentials : false }
      ).then(function (res) {
        var data = res.data;

        if (data.success === true) {

          // TODO: Czy tutaj nadpisywać menu już docelowym???
          // Tak, ale dopiero jak będzimy mieli informację o języku w response
          //data.ufpMainMenuItems

          resolve(data);

        } else {
          reject({
            msg: "UFP Login error",
            data: data
          });
        }

      }).catch(function (res) {
        var data = res.data, status = res.status;

        reject({
          msg: "UFP Login error. Response status: " + status,
          data: data
        });

      });

    });

  }

  function prepareMenu(ufpMainMenuItems, ufpUserMenuItems, li18n) {

    // Add cc menu
    var i, l, profile, current,
      ccMenu = [
        {
          item: "interactions",
          name: li18n.menu.interactions,
          src: null,
          iconClass: "fa-th-list",
          submodules: null,
          webagent: "interactions",
          hidden: true
        },
        {
          item: "contactCenter",
          name: li18n.menu.contactCenter,
          src: null,
          iconClass: "fa-cloud",
          submodules: [
            { name: li18n.menu.preview, src: "", webagent: "preview" },
            { name: li18n.menu.statistics, src: "", webagent: "statistics" }

          ]
        }];

    // Add ccMenu after dashboard
    l = ufpMainMenuItems.length;
    for (i = 0; i < l; i++) {
      current = ufpMainMenuItems[i];
      if (current.item === "ufp_dashboard") {
        ufpMainMenuItems = ufpMainMenuItems.slice(0, i + 1).concat(ccMenu).concat(ufpMainMenuItems.slice(i + 1));
        break;
      }

    }

    // Add profile menu
    var profileMenu = [];

    l = ufpUserMenuItems.length;
    for (i = 0; i < l; i++) {
      profile = ufpUserMenuItems[i];
      if (profile.item === "ufp_profile") {
        profile.iconClass = "fa-user";
        profileMenu.push(ufpUserMenuItems[i]);
        break;
      }
    }

    //return ccMenu.concat(ufpMainMenuItems).concat(profileMenu);
    return ufpMainMenuItems.concat(profileMenu);

  }

  /**
   * Gets menu structure
   * @param {Object} p Parameteres
   * @param {String} p.url
   * @param {Object} p.li18n
   * @param {Boolean} [p.withCredentials=false]
   * @return Promise
   */
  function getMenu(p) {

    // Deferred wrapper
    // We want to return native promise
    return new Promise(function (resolve, reject) {

      $http.get(p.url, {
        withCredentials: p.withCredentials ? p.withCredentials : false,
        headers: {
          'X-Requested-With': "XMLHttpRequest" // UFP Must to know that this is AJAX request
        }
      }).then(function (res) {
        var data = res.data;

        if (data.success === true) {

          var ret = prepareMenu(data.ufpMainMenuItems, data.ufpUserMenuItems, p.li18n);
          resolve(ret);

        } else {

          reject({
            msg: "Menu request failure",
            data: data
          });
        }

      }).catch(function (res) {
        var data = res.data, status = res.status;

        reject({
          msg: "Error getting menu data. Response status: " + status,
          data: data
        });
      });

    });

  }

  /**
   * Changes language
   * @param {Object} p Parameters
   * @param {String} p.url
   * @param {Srtring} p.lang
   * @param {Booelan} p.withCredentials
   * @retun Promise
   */
  function switchLang(p) {

    return new Promise(function (resolve, reject) {

      $http.get(p.url + "/" + p.lang, {
        withCredentials: p.withCredentials ? p.withCredentials : false,
        headers: {
          'Content-Type': 'text/plain',
          'X-Requested-With': "XMLHttpRequest" // UFP Must to know that this is AJAX request
        }
      }).then(function () {

        resolve(true);

      }).catch(function (res) {
        var data = res.data, status = res.status;

        reject({
          msg: "Error setting language: " + status,
          data: data
        });

      });

    })

  }

  function logout(sync) {
    var ufpServiceAddress = SettingsData.get('UfpServiceAddress');
    var logoutUrl = ufpServiceAddress + CONFIG.ufp.logoutAddress;

    if (sync === true) {
      return new Promise(function (resolve, reject) {
        navigator.sendBeacon(logoutUrl) ? resolve(true) : reject(false);
      })
    }

    return $http.get(logoutUrl, {
      withCredentials: true
    });
  }


    /**
     * Execute custom UFP Action
     * Returned resolved promise contains array of fetched customers.
     * If customers will not be found, returns rejected promise
     * @param {Object} p Parameters object
     * @param {String} p.actionIdent Action name
     * @param {String} p.data Action data
     * @return {Promise}
     */
  function execCustomAction(p) {

      return new Promise( function(resolve, reject) {

        $http.post( SettingsData.get('UfpServiceAddress') + CONFIG.ufp.customActionAddress, p, { withCredentials: false } ).then(function (res) {

          var data = res.data;

          if (data.success === true) {

            resolve(data.resultData);

          } else {

            reject({
              msg: "UFP execute custom action error: response success is false",
              data: data
            });
          }

        }).catch(function (res) {
          var data = res.data, status = res.status;
          console.log("Rejecting 2");
          reject({
            msg: "UFP Execute custom action error. Response status: " + status,
            data: data
          });

        });

      } );

  }

}

export { UfpService };
