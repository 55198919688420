var operator = function Operator(name, value) {
  this.name = name;
  this.value = value;
}

/**
 * OperatorFactory
 */
function OperatorFactory() {

  return operator;

}

export { OperatorFactory };
