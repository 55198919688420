export function SoundService(
  $ngRedux,
  playSoundEnd,
  playSoundStart,
  getSoundSettings,
  PhoneSound,
  beep,
  SoundType,
  isSoundPlaying
) {
  var phoneSounds = {
    routing: new PhoneSound(425, 50),
    beep: function (freq, incDuration, decDuration, volume) {
      beep(freq, incDuration, decDuration, volume)
    }
  };

  var sounds = {
    chatStart: new Audio('assets/sounds/long.mp3'),
    chatNewMessage: new Audio('assets/sounds/dial.wav'),
    chatEnd: new Audio('assets/sounds/failed.wav'),
    newEmail: new Audio('assets/sounds/dial.wav')
  };

  for (var s in sounds) {
    if (sounds.hasOwnProperty(s)) {
      sounds[s].addEventListener('ended', function () {
        $ngRedux.dispatch(playSoundEnd(s));
      });

      sounds[s].addEventListener('play', function () {
        $ngRedux.dispatch(playSoundStart(s));
      });
    }
  }

  this.play = play;
  this.playRoutingSound = playRoutingSound;
  this.stopRoutingSound = stopRoutingSound;
  this.beep = phoneSounds.beep;

  function play(soundType) {
    var state = $ngRedux.getState();
    var isSoundEnabled = getSoundSettings(state);

    if (soundType === SoundType.routing && isSoundEnabled[soundType]) {
      this.beep(600, 0.02, 0.3, 0.35);
      return;
    }

    if (isSoundEnabled[soundType] &&
      !isSoundPlaying(state)
    ) {
      sounds[soundType].play();
    }
  }

  function playRoutingSound() {
    phoneSounds.routing.play();
  }

  function stopRoutingSound() {
    phoneSounds.routing.stop();
  }
}
