export function AxPopup() {
  return {
    restrict: 'A',
    link: function ($scope, $element) {
      $element.on("click", function (e) {
        e.originalEvent.isFromPopup = true;
      });
    }
  }
}
