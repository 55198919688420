/** @ngInject */
export function AxPhoneNumber(Data, $rootScope) {
  return {
    restrict: 'A',
    bindToController: true,
    link: function ($scope, $element, $attrs) {

      $element.on("click", () =>

        $rootScope.$broadcast("chatPhoneNumberClicked", {

          interaction: Data.currentInteraction,
          number: $attrs.axPhoneNumber

        })
      );

    }
  }
}
