export function getSoundSettings(state) {
  return state.settings.sound;
}

export function SoundSettingsReducers(
  SETTINGS_SOUND_ENABLE,
  SETTINGS_SOUND_DISABLE,
  SETTINGS_SOUND_TOGGLE
) {

  this.reducer = sound;

  function sound(state, action) {
    state = state || {};

    switch (action.type) {
      case SETTINGS_SOUND_ENABLE:
        var newState = {};
        newState[action.payload.soundType] = true;

        return Object.assign(state, newState)
      case SETTINGS_SOUND_DISABLE:
        var newState = {};
        newState[action.payload.soundType] = false;

        return Object.assign(state, newState)
      case SETTINGS_SOUND_TOGGLE:
        var newState = {};
        newState[action.payload.soundType] = !state[action.payload.soundType];

        return Object.assign(state, newState)
      default:
        return state;
    }
  }
}
