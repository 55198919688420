import template from './AxTimeSelect.html';

function AxTimeSelect() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      time: '=ngModel',
    },
    link: link,
    require: 'ngModel',
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: [controller]
  }

}

function controller() {

  var vm = this;
  vm.hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  vm.minutes = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];
  vm.seconds = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];

  vm.selectedHour = "00";
  vm.selectedMinute = "00";
  vm.selectedSecond = "00";

}

function link($scope, $element, $attrs, $ngModel) {

  $scope.$watch('vm.selectedHour', function (newVal, oldVal) {

    console.log("new: " + newVal + ", old: " + oldVal);
    $ngModel.$setViewValue([newVal, $scope.vm.selectedMinute, $scope.vm.selectedSecond].join(":"))

  });

  $scope.$watch('vm.selectedMinute', function (newVal, oldVal) {

    console.log("new: " + newVal + ", old: " + oldVal);
    $ngModel.$setViewValue([$scope.vm.selectedHour, newVal, $scope.vm.selectedSecond].join(":"))

  });

  $scope.$watch('vm.selectedSecond', function (newVal, oldVal) {

    console.log("new: " + newVal + ", old: " + oldVal);
    $ngModel.$setViewValue([$scope.vm.selectedHour, $scope.vm.selectedMinute, newVal].join(":"))

  });
}

export { AxTimeSelect }
