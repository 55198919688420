function surveyModel($filter, $window, $parse, $timeout) {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      var vm = scope.vm;
      ngModel.$parsers.push(function (data) {
        //View -> Model
        if (vm.axC.setValue(data)) {
          return vm.axC.getValue();
        }
        return data;
      });
      ngModel.$formatters.push(function (data) {
        //Model -> View
        if (vm.onValueSet) {
          vm.onValueSet(vm.axC);
        }
        return data;
      });
    }
  };
}

/**
 * Service with common helper functions
 * @constructor
 */
function Helpers(SessionHelperService, SurveyService, userPhone, $rootScope) {

  this.initField = function (vm) {
    vm.helpers = this;
    vm.isInitialized = false;
    vm.field = vm.axC.Field;
    vm.getColor = this.getColor;
    vm.axControlStyle = this.axControlStyle;
    vm.axControlEditorStyle = this.axControlEditorStyle;
    vm.axControlLabelStyle = this.axControlLabelStyle;
    vm.sendSetValueAction = this.sendSetValueAction;
    vm.onValueSetTimeout = null;

    vm.setValid = function () {
      vm.isValid = true;
      vm.invalidMessage = "";
    }

    vm.setInvalid = function (errMsg) {
      vm.isValid = false;
      vm.invalidMessage = vm.li18n.messages[errMsg] || errMsg;
    }

    vm.validate = function () {

      vm.invalidMessage = vm.axC.Validate(vm.axC.getValue());
      if (vm.invalidMessage) {
        vm.isValid = false;
        vm.invalidMessage = vm.li18n.messages[vm.invalidMessage] || vm.invalidMessage;
      }
      else {
        vm.isValid = true;
      }

    };

    vm.axC.getValue = function () {
      if (typeof vm.axC.Value !== "undefined") {
        return vm.axC.Value;
      }
      return null;
    };
    vm.axC.setValue = function (val) {
      vm.axC.Value = val;
      return true;
    };
    vm.axC.clearValue = function () {
      var oldValue = vm.axC.getValue();
      vm.axC.setValue(null);
      if (vm.onValueSet && oldValue !== null)
        vm.onValueSet(vm.axC);
    };
  };

  this.endInitField = function (vm) {
    vm.isInitialized = true;
  };

  this.canDisconnectCall = function () {
    if (SessionHelperService.baseSessionIsEnded() === true) {
      return false;
    }
    var states = ax.AxCommonLib.Telephony.AxCallState;
    if (userPhone && userPhone.phone && userPhone.phone.activeCall) {
      var call = userPhone.phone.activeCall;
      if (call.State === states.Connected || call.State === states.Alerting || call.State === states.Holded) {
        return true;
      }
    }
    return false;
  };

  this.disconnectCall = function () {
    if (this.canDisconnectCall() === true) {
      userPhone.phone.disconnectCall(userPhone.phone.activeCall, ax.AxCommonLib.Telephony.AxCallEventCause.NormalClearing);
    }
    ;
  }

  /**
   * Sets after session state
   * @param {Object} p Parameter object
   * @param {Number} p.stateId
   * @param {?Number} p.stateTime State time in seconds
   * @return {external:Promise}
   */
  this.setAfterSessionState = function (p) {

    var session = SessionHelperService.getCurrentInteraction();

    return SessionHelperService.setSessionParamPromise({
      sessionId: session.session.Session.SessionId,
      param: ax.SessionLib.AxSessionParam.SetAfterSessionStateId,
      paramValue: p.stateId,
      paramValue2: p.stateTime
    });

  }


  this.getCurrentSession = function () {
    return SessionHelperService.getCurrentInteraction();
  }

  this.canMakeCall = function (dnis) {
    if (!userPhone || !userPhone.phone || !dnis) {
      return false;
    }
    if (SessionHelperService.baseSessionIsEnded() === true) {
      return false;
    }
    var states = ax.AxCommonLib.Telephony.AxCallState;
    var calls = userPhone.phone.calls.getAsArray();
    var counter = 0;
    for (var i = 0; i < calls.length; i++) {
      if (calls[i].State != states.Disconnected) {
        counter++;
      }
    }
    if (counter > 1) {
      return false;
    }
    return true;
  };

  this.makeCall = function (dnis, ani) {
    if (this.canMakeCall(dnis) === true) {
      var inter = SessionHelperService.getCurrentInteraction();
      if (inter && inter.additionalParams) {
        userPhone.phone.makeCall({
          dnis: dnis,
          ani: ani,
          userId: inter.additionalParams.value.UserId,
          campaignId: inter.additionalParams.value.CampaignId,
          sessionServerSessionSerialId: inter.additionalParams.value.SerialSessionId,
          dataRecordId: inter.additionalParams.value.DataRecordId
        });
      }
    }
    ;
  }

  this.canBlindTransfer = function (dnis) {
    if (!userPhone || !userPhone.phone || !dnis) {
      return false;
    }
    if (SessionHelperService.baseSessionIsEnded() === true) {
      return false;
    }
    var states = ax.AxCommonLib.Telephony.AxCallState;
    var call = userPhone.phone.activeCall;
    if (call) {
      if (call.State === states.Connected || call.State === states.Holded) {
        return true;
      }
    }
    return false;
  };

  this.blindTransfer = function (dnis, ani) {
    if (this.canBlindTransfer(dnis) === true) {
      userPhone.phone.blindTransfer(userPhone.phone.activeCall, dnis);
    }
  }

  this.canSendDtmf = function (dtmf) {
    if (!userPhone || !userPhone.phone || !dtmf) {
      return false;
    }
    if (SessionHelperService.baseSessionIsEnded() === true) {
      return false;
    }
    var states = ax.AxCommonLib.Telephony.AxCallState;
    if (userPhone.phone.activeCall) {
      var call = userPhone.phone.activeCall;
      if (call.State === states.Connected) {
        return true;
      }
    }
    return false;
  };

  this.sendDtmf = function (dtmf) {
    if (this.canSendDtmf(dtmf) === true) {
      userPhone.phone.sendDtmf(userPhone.phone.activeCall, dtmf);
    }
    ;
  }

  this.canSendFlowButtonAction = function () {
    return SessionHelperService.canSendFlowButtonAction();
  };

  this.sendFlowButtonAction = function (vm) {
    SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.FlowButton, vm.field.FieldId, vm.axC.getValue(), null, null, null);
  };

  /**
   * @param {Number} level
   * @return {external:Promise}
   */
  this.sendSetDataRecordStage = function (level) {

    return SurveyService.sendSetDataRecordStage(level);

  };

  this.sendSms = function (accountName, to, body, callback) {
    SessionHelperService.sendSms(accountName, to, body, callback);
  };

  this.sendMail = function (accountName, from, to, title, body, sendRenderedAttachments, renderAttachements, attachments, callback) {
    SessionHelperService.sendMail(accountName, from, to, title, body, sendRenderedAttachments, renderAttachements, attachments, callback);
  };

  this.sendSetValueAction = function (vm) {
    if (vm.isInitialized === true) {
      if (vm.sendSetValueActionTimeout) {
        clearTimeout(vm.sendSetValueActionTimeout);
      }
      vm.sendSetValueActionTimeout = setTimeout(function () {
        //type, fieldId, value, shiftResult, terminateResult, record
        SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.SetValue, vm.field.FieldId, vm.axC.getValue(), null, null, null);
      }, 250);
    }
  };

  this.sendTerminateSessionAction = function (result) {
    SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.TerminateSession, null, null, null, result, null);
  };

  this.sendShiftSessionAction = function (result) {
    SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.ShiftSession, null, null, result, null, null);
  };

  this.sendSetDataRecordAction = function (dataRecord) {
    SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.SetDataRecord, null, null, null, null, dataRecord);
  };

  this.getExternalData = function (dataSourceId, callback) {
    SessionHelperService.getExternalData(dataSourceId, callback);
  };

  this.getRecordHistory = function (userId, campaignId, dataRecordId, callback) {
    SessionHelperService.getRecordHistory(userId, campaignId, dataRecordId, callback);
  };

  this.createNewRecord = function (userId, campaignId, sessionId, importId, ani, callback) {
    SessionHelperService.createNewRecord(userId, campaignId, sessionId, importId, ani, callback);
  };

  this.deleteDataRecord = function (userId, campaignId, recordId, callback) {
    SessionHelperService.deleteDataRecord(userId, campaignId, recordId, callback);
  };

  this.modifyDataRecordPhone = function (userId, callback) {
    SessionHelperService.modifyDataRecordPhone(userId, callback);
  };

  this.modifyDataRecord = function (userId, callback) {
    SessionHelperService.modifyDataRecord(userId, callback);
  };

  this.terminateSession = function (sessionId, dataRecordId, campaignId, terminateReason, dataRecordPhoneIndex, callback) {
    SessionHelperService.terminateSession(sessionId, dataRecordId, campaignId, terminateReason, dataRecordPhoneIndex, callback);
  };

  this.shiftSession = function (sessionId, dataRecordId, campaignId, note, tco, forUserId, shiftOn, phoneIndex, callback) {
    SessionHelperService.shiftSession(sessionId, dataRecordId, campaignId, note, tco, forUserId, shiftOn, phoneIndex, callback);
  };

  this.findDataRecord = function (userId, campaignId, phoneNumber, offset, limit, resultAsDataTable, callback) {
    SessionHelperService.findDataRecord(userId, campaignId, phoneNumber, offset, limit, resultAsDataTable, callback);
  };

  this.getDataRecord = function (userId, campaignId, dataRecordId, callback) {
    SessionHelperService.getDataRecord(userId, campaignId, dataRecordId, callback);
  };

  this.setSessionParam = function (sessionId, param, paramValue, campaignId, callback) {
    SessionHelperService.setSessionParam(sessionId, param, paramValue, campaignId, callback);
  };

  //helpers
  this.isTrue = function (value) {
    return (value === true || value === "True" || value === "true");
  };

  this.isFalse = function (value) {
    return (value === false || value === "False" || value === "false");
  };

  this.phoneStateColor = function (state) {
    var states = ax.SessionLib.AxDataContactPhoneState;
    switch (state) {
      case states.Open:
        return "background-color-yellow";
      case states.UserClosed:
        return "background-color-grey";
      case states.ClosedInvalidNumber:
        return "background-color-red";
      case states.ClosedVoiceMail:
      case states.ClosedFax:
      case states.ClosedAttendant:
        return "background-color-grey";
      default:
        return "background-color-black";
    }
  };

  this.getColor = function (color) {
    if (color) {
      if (color.indexOf("#") > -1 && color.length > 7)
        return '#' + color.substring(3);
    }
    return color;
  };

  this.axControlStyle = function (field, style) {

    style = style || {};
    if (field.BackgroundColor) {
      style.backgroundColor = this.getColor(field.BackgroundColor);
    }
    if (field.ForeColor) {
      style.color = this.getColor(field.ForeColor);
    }

    if (field.Margin) {

      let margins = field.Margin.split(",");

      if (margins && margins.length !== 4) {
        margins =  field.Margin.split(" ");
      }

      if (margins && margins.length === 4) {
        style.paddingLeft = margins[0] + "px";
        style.paddingTop = margins[1] + "px";
        style.paddingRight = margins[2] + "px";
        style.paddingBottom = margins[3] + "px";
      }

    }

    return style;
  };

  this.axControlEditorStyle = function (field, style) {
    style = style || {};
    if (field.FieldWidth)
      style.width = field.FieldWidth;
    return style;
  };

  this.axControlLabelStyle = function (field, style) {
    style = style || {};
    if (field.FontSize)
      style.fontSize = field.FontSize + "px";

    if (field.FontFamily)
      style.fontFamily = field.FontFamily + ", sans-serif";

    if (field.FontStyle)
      style.fontStyle = field.FontStyle.toLowerCase();

    if (field.FontDecoration) {
      style.textDecoration = "";
      if (field.FontDecoration.indexOf("Underline") > -1 || field.FontDecoration.indexOf("Baseline") > -1)
        style.textDecoration += "underline ";
      if (field.FontDecoration.indexOf("Strikethrough") > -1)
        style.textDecoration += "line-through ";
      if (field.FontDecoration.indexOf("OverLine") > -1)
        style.textDecoration += "overline";
    }

    if (field.FontWeight) {
      switch (field.FontWeight) {
        case "Thin":
        case "Light":
        case "ExtraLight":
        case "UltraLight":
          style.fontWeight = "lighter";
          break;
        case "Regular":
        case "Normal":
        case "Medium":
          style.fontWeight = "normal";
          break;
        case "Heavy":
        case "ExtraBold":
        case "ExtraBlack":
        case "UltraBold":
        case "UltraBlack":
          style.fontWeight = "bolder";
          break;
        case "Black":
        case "Bold":
        case "SemiBold":
        case "DemiBold":
          style.fontWeight = "bold";
          break;
      }
    }
    if (field.TextAlignment) {
      switch (field.TextAlignment) {
        case "Left":
          style.textAlign = "left";
          break;
        case "Center":
          style.textAlign = "center";
          break;
        case "Right":
          style.textAlign = "right";
          break;
        case "Justify":
          style.textAlign = "justify";
          break;
      }
      ;
    }
    return style;
  };

  this.validatePesel = function (pesel) {
    var reg = /^[0-9]{11}$/;
    if (reg.test(pesel) == false) {
      return false;
    }
    else {
      var dig = ("" + pesel).split("");
      var crc = (1 * parseInt(dig[0]) + 3 * parseInt(dig[1]) + 7 * parseInt(dig[2]) + 9 * parseInt(dig[3]) + 1 * parseInt(dig[4]) + 3 * parseInt(dig[5]) + 7 * parseInt(dig[6]) + 9 * parseInt(dig[7]) + 1 * parseInt(dig[8]) + 3 * parseInt(dig[9])) % 10;
      if (crc == 0) crc = 10;
      crc = 10 - crc;
      if (parseInt(dig[10]) == crc)
        return true;
      else
        return false;
    }

  };

  this.validateNip = function (nip) {
    var nip_bez_kresek = nip.replace(/-/g, "");
    var reg = /^[0-9]{10}$/;
    if (reg.test(nip_bez_kresek) == false) {
      return false;
    }
    else {
      var dig = ("" + nip_bez_kresek).split("");
      var crc = (6 * parseInt(dig[0]) + 5 * parseInt(dig[1]) + 7 * parseInt(dig[2]) + 2 * parseInt(dig[3]) + 3 * parseInt(dig[4]) + 4 * parseInt(dig[5]) + 5 * parseInt(dig[6]) + 6 * parseInt(dig[7]) + 7 * parseInt(dig[8])) % 11;
      if (parseInt(dig[9]) == crc)
        return true;
      else
        return false;
    }

  };

  this.validateRegon = function (regon) {
    var reg = /^[0-9]{9}$/;
    if (reg.test(regon) == false) {
      return false;
    }
    else {
      var dig = ("" + regon).split("");
      var crc = (8 * parseInt(dig[0]) + 9 * parseInt(dig[1]) + 2 * parseInt(dig[2]) + 3 * parseInt(dig[3]) + 4 * parseInt(dig[4]) + 5 * parseInt(dig[5]) + 6 * parseInt(dig[6]) + 7 * parseInt(dig[7])) % 11;
      if (crc == 10) crc = 0;
      if (parseInt(dig[8]) == crc)
        return true;
      else
        return false;
    }
  };

  this.validateIban = function (nrb) {
    nrb = nrb.replace(/[^0-9]+/g, '');
    var Wagi = new Array(1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57);
    if (nrb.length == 26) {
      var moduloNr = nrb.substr(2) + "252100"; // A=10, B=11, ..., L=21, ..., P=25 oraz 2 zera
      var moduloSum = 0;
      for (var i = 0; i < moduloNr.length; i++) {
        moduloSum += Wagi[29 - i] * parseInt(moduloNr[i].toString());
      }
      var modulo = 98 - (moduloSum % 97);
      var isValid = modulo === parseInt(nrb.substr(0, 2));
      return isValid;
    }
    else {
      return false;
    }
  };

  this.validateEmail = function (email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  };

  function allowedKeys(e) {
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 108, 110, 188, 190]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      //Allow minus
      (e.keyCode == 109 || e.keyCode == 189)) {
      // let it happen, don't do anything
      return true;
    }

    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      return false;
    }

    return true;

  }

  /**
   * Check if entered value is float value
   */
  this.keyDownFloatOnly = function (e) {

    var cursorPosition, currentValue, newValue;
    if (allowedKeys(e)) {
      // 46 - delete
      // 8 - backspace
      // 9 - tab
      // 13 - enter
      // 37 - arrow left
      // 39 - arrow right
      // 27 - escape
      // 190 - dot (period) - go forward
      // 110 - dot (period) numerical keyboard - go forward
      if ($.inArray(e.keyCode, [46, 8, 37, 39, 9, 27, 13]) !== -1 ||
        // Allow: Ctrl+A, Command+A
        (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
        // Allow: home, end, left, right, down, up
        (e.keyCode >= 35 && e.keyCode <= 40) ||
        //Allow minus
        (e.keyCode == 109 || e.keyCode == 189)) {
        // let it happen, don't do anything

        return;
      }
      cursorPosition = e.currentTarget.selectionStart;
      currentValue = e.currentTarget.value;

      newValue = currentValue.slice(0, cursorPosition) + e.key + currentValue.slice(cursorPosition);

      if (newValue.indexOf(',') !== -1) {
        newValue = newValue.replace(",", ".");
      }

      if (isNaN(newValue)) {
        e.preventDefault();
      }
    }

  }

  this.keyDownIntegersOnly = function (e) {
    // 46 - delete
    // 8 - backspace
    // 9 - tab
    // 13 - enter
    // 37 - arrow left
    // 39 - arrow right
    // 27 - escape
    // 190 - dot (period)
    // 110 - dot (period)
    if ($.inArray(e.keyCode, [46, 8, 37, 39, 9, 27, 13]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      //Allow minus
      (e.keyCode == 109 || e.keyCode == 189)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) || e.keyCode === 110 || e.keyCode === 190) {
      e.preventDefault();
    }


  };

  this.keyDownNumbersOnly = function (e) {
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 108, 110, 188, 190]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      //Allow minus
      (e.keyCode == 109 || e.keyCode == 189)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  };

  this.round = function (val, decimals) {
    val = parseFloat(val || 0);
    decimals = parseInt(decimals || 0);
    var result = Math.round(val);
    if (decimals > 0) {
      result = Math.round(val * (Math.pow(10, decimals))) / Math.pow(10, decimals);
    }
    return result;
  };

  this.phoneNumberRegex = /^$|^\+?[0-9\*\#]{0,64}$/;

  this.loginRegex = /^$|^[a-zA-Z0-9_@\.\\\#\-\:]*$/;

  this.nameRegex = /^$|^[0-9\p{L}\-\x20]*$/;

  this.variableNameRegex = /^$|^[a-zA-Z_][a-zA-Z0-9_]*$/;

  this.variableNameReplaceRegex = /[^a-zA-Z0-9_]/;

  this.emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/;

  /**
   * @param domain
   * @return {external:Promise}
   */
  this.sendGetDnsRequest = function (domain) {

    return SurveyService.sendGetDnsRequest({
      domain: domain,
      qType: ax.AxEnvironmentLib.AxMsgGetDnsRecordsQType.MX
    });

  };

  /**
   * @param {Object} p
   * @param {String} p.email Email to validate
   * @param {Number} [p.timeout=null] Check domain timeout in seconds
   * @param {Boolean} p.validateDns If dns record must be checked
   * @param {Boolean} p.validateSmtp If SMTP server must be checked
   * @param {Array<Number} p.smtpPorts
   * @param {Array<Number>} p.smtpSslPorts
   * @param {String} p.heloDomain Domain sent in HELO SMTP message
   **/
  this.sendValidateEmailRequest = function (p) {

    return SurveyService.sendValidateEmailRequest(p);

  };


};


/**
 * Service with datetime helpers functions
 * @constructor
 */
function DateTimeHelpers() {

  this.checkDateTimeFormatRegexp = checkDateTimeFormatRegexp;
  this.checkDateInTime = checkDateInTime;
  this.checkTimeInDate = checkTimeInDate;
  this.dateRegexp = dateRegexp;
  this.timeRegexp = timeRegexp;
  this.dateTimeRegexp = dateTimeRegexp;
  this.getDatePart = getDatePart;
  this.getTimePart = getTimePart;


  /**
   * Checks if input string is in valid format XXXX-XX-XX XX:XX where X is any alphanumeric sign
   * @param {String} s
   * @return {Boolean}
   */
  function checkDateTimeFormatRegexp(s) {

    if (s !== null) {

      return s.match(/[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{2}\-[a-zA-Z0-9]{2}\s[a-zA-Z0-9]{2}:[a-zA-Z0-9]{2}/) ? true : false;

    }

    return false;

  }

  /**
   * Checks if date part in datetimestring has valid format
   * Function expects string in format XXXX-XX-XX XX:XX
   * Throws exception if input is in bad format
   * @param dt
   * @return {Boolean}
   */
  function checkDateInTime(dt) {

    if (checkDateTimeFormatRegexp(dt)) {

      return dateRegexp(dt.slice(0, 10));
    }

    throw "Input " + dt + " differs from expected XXXX-XX-XX XX:XX";

  }

  /**
   * Checks if time part in datetimestring has valid format
   * Function expects string in format XXXX-XX-XX XX:XX
   * Throws exception if input is in bad format
   * @param dt
   * @return {Boolean}
   */
  function checkTimeInDate(dt) {

    if (checkDateTimeFormatRegexp(dt)) {

      return timeRegexp(dt.slice(11, 16));

    }

    throw "Input " + dt + " differs from expected XXXX-XX-XX XX:XX";

  }

  /**
   * Check if string is valid date in format YYYY-MM-DD
   * @param {String} dt
   * @return Boolean
   */
  function dateRegexp(dt) {

    if (dt !== null) {
      return dt.match(/\d{4}\-\d{2}\-\d{2}/) ? true : false;
    }

    return false;

  }

  /**
   * Check if string is valid time in format HH:MM
   * @param {String} t
   * @return Boolean
   */
  function timeRegexp(t) {

    if (t !== null) {
      return t.match(/\d{2}:\d{2}/) ? true : false;
    }

    return false;

  }

  /**
   * Check if string is valid datetime format YYYY-MM-DD HH:MM
   * This function only check format and digits, not time!
   * @param {String} dt
   * @return Boolean
   */
  function dateTimeRegexp(dt) {

    if (dt !== null) {
      return dt.match(/\d{4}\-\d{2}\-\d{2}\s{1}\d{2}:\d{2}/) ? true : false;
    }

    return false;

  }

  /**
   * Gets date part from datetime string
   * Input string must be in format XXXX-XX-XX XX:XX
   * @return {String}
   */
  function getDatePart(dt) {

    return dt.slice(0, 10)
  }

  /**
   * Gets date part from datetimestring
   * Input string must be in format XXXX-XX-XX XX:XX
   * @return {String}
   * @param dt
   */
  function getTimePart(dt) {
    return dt.slice(11, 16);
  }

}

export { Helpers, DateTimeHelpers, surveyModel };
