function ComClient(UtilsService) {
  var service = {
    initialize: initialize,
    register: register,
    disconnect: disconnect,
    getPasswordFlags: getPasswordFlags,
    getPasswordMinLength: getPasswordMinLength,
    PASS_CONSTRAINTS: Object.freeze(Object.create(null, {
      DIGITS_AND_LETTERS: { value: 2 },
      BIG_AND_SMALL_LETTERS: { value: 4 }
    })),
  };

  var client = undefined,
    PASS_CONSTRAINTS = service.PASS_CONSTRAINTS,
    minPassLength = undefined,
    passwordFlags = 0;

  return service;

  /**
   * Initialize ComClient
   * @param {Object} p.webLib Initialized WebLib instance
   * @param {ax.AxComLib.AxComClientSettings} p.settings
   * @return {Promise}
   */
  function initialize(p) {
    if (!client) {
      client = new ax.AxComLib.AxComClient(p.webLib);
    }

    return client.initialize(p.settings).then(function (res) {

      minPassLength = (function () {
        var set = getSystemSettingFromResponse("PasswordMinlLength", res);
        if (typeof set !== "undefined") {
          var parsedInt = parseInt(set.Value);
          return !isNaN(parsedInt) ? parsedInt : 0;
        } else {
          return 0;
        }

      })();

      passwordFlags |= (function () {
        var set = getSystemSettingFromResponse("PasswordBigAndSmallLetters", res);
        if (typeof set !== "undefined") {
          return !!parseInt(set.Value) ? PASS_CONSTRAINTS.BIG_AND_SMALL_LETTERS : 0;
        } else {
          return 0;
        }

      })();

      passwordFlags |= (function () {
        var set = getSystemSettingFromResponse("PasswordDigitsAndLetters", res);
        if (typeof set !== "undefined") {
          return !!parseInt(set.Value) ? PASS_CONSTRAINTS.DIGITS_AND_LETTERS : 0;
        } else {
          return 0;
        }
      })();

      return res.registerResponse;
    });
  }

  /**
   * Register to system. Wrapper for AxComLib.AxComClient.register method
   * @return {Promise}
   */
  function register(p) {
    return client.register(p);
  }

  /**
   * Disconnect ComClient from server
   */
  function disconnect() {
    return client.disconnect();
  }

  /**
   * Gets system variable by name from register response.
   * If not found returns undefined
   * @param {String} varName
   * @param {ax.AxComLib.AxMessages.AxMsgRegisterClientResponse} response
   * @returns {ax.AxEnvironmentLib.Collections.AxSystemVarDataElement}
   */
  function getSystemSettingFromResponse(varName, response) {
    if (response && response.registerResponse !== null && response.registerResponse.SystemVariables) {
      return UtilsService.findSystemSetting(varName, response.registerResponse.SystemVariables);
    }
  }

  function getPasswordFlags() {
    return passwordFlags;
  }

  function getPasswordMinLength() {
    return minPassLength;
  }
}

export { ComClient };
