import template from './AxAcceptableSessionItem.html';

export function AcceptableSessionItem() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      session: '=axSession',
      userId: '=axUserId'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller
  }

}

/** @ngInject */
function controller($scope, $rootScope, SessionClient, SessionService) {

  var vm = this;
  vm.acceptInProgress = false;
  vm.accepted = false;

  function broadcastAcceptSessionState(item, state) {

    $rootScope.$broadcast('acceptSessionChanged', {
      state: state,
      item: state
    });
  }

  vm.rejectSession = function () {

    $scope.$emit('rejectInteractionClick', {
      item: vm.session
    });

  };

  vm.isAcceptInProgress = function () {

    return vm.acceptInProgress || vm.accepted;

  };

  vm.acceptSession = function () {

    broadcastAcceptSessionState(vm.session, true);
    SessionClient.acceptSession({
      sessionId: vm.session.session.Session.SessionId,
      userId: vm.userId
    }).then(function (res) {

      vm.accepted = true;
      broadcastAcceptSessionState(vm.session, false);
      SessionService.temporaryRejectAcceptableSessions(0);

    }).catch(function (err) {

      console.error("Error accepting session", err);
      broadcastAcceptSessionState(vm.session, false);
      // TODO: Emit error i w apce globalny handler na errory pokazujący jakoś ładnie błędy
      // niekonieczenie znikające w pasku na dole, powinien być też jakiś log tych błędów dostępny z GUI

    });

  };

}

function link($scope) {

  $scope.vm.duration = "00:00:00";
  var countDown = false;
  var lastStateTime = new Date();
  var currentTime = new Date();

  var aa = findAcceptAction($scope.vm.session);
  if (aa !== null && aa.TimeoutSeconds > 0) {
    lastStateTime.setSeconds(lastStateTime.getSeconds() + aa.TimeoutSeconds);
    countDown = true;

    $scope.vm.duration = dateDiff(lastStateTime, currentTime);
  }

  var intervalId = null;

  function countStateTime() {
    var currentTime = new Date();
    var diff;
    if (countDown === false) {
      $scope.vm.duration = dateDiff(currentTime, lastStateTime);
    } else {

      diff = dateDiff(lastStateTime, currentTime);
      // Gdyby tak zaczęło liczyć w dół bo usuwanie sesji jest oparte na timerz, który się znajduje w menu :(
      if (diff == "00:00:00") {
        clearInterval(intervalId);
      }

      $scope.vm.duration = diff;

    }

  }

  intervalId = setInterval(countStateTime, 1000);

  // Clear interval when destoy directive instance
  $scope.$on('$destroy', function () {
    clearInterval(intervalId);
  });

  $scope.$on('acceptSessionChanged', function (event, data) {

    $scope.vm.acceptInProgress = data.state;

  });

}


/**
 * @param {InteractionItem} inter
 * @returns {?ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession}
 */
function findAcceptAction(inter) {

  var actions = inter.session.Actions;
  if (actions === null) {
    return null;
  }
  var retActions = actions.filter(function (action) {

    return action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession;

  });

  if (retActions.length > 0) {
    return retActions[0];
  } else {
    return null;
  }
}

function zeroPad(n) {
  return n < 10 ? '0' + n : n;
}

/**
 * Przelicza różnicę pomiędzy datami zwraca wynik w postaci obiektu AxInterval
 * Odejmuje date2 od date1
 * @param {Date} date1 Data, od której odejmujemy
 * @param {Date} date2 Data, którą odejmujemy
 */
function dateDiff(date1, date2) {

  var diff = date1.getTime() - date2.getTime();
  var days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);

  var hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  var mins = Math.floor(diff / (1000 * 60));
  diff -= mins * (1000 * 60);

  var seconds = Math.floor(diff / (1000));

  return zeroPad(hours) + ':' + zeroPad(mins) + ':' + zeroPad(seconds);

}
