import template from './AxSurveyFieldSendMail.html';

function AxSurveyFieldSendMail() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  vm.isSending = false;
  vm.canSend = function () {

    return vm.field.AccountName.trim() && vm.field.To.trim() && (vm.field.Body.trim() || vm.field.MessageTemplate.trim()) && vm.isSending === false;

  };
  vm.axC.setValue = function (val) {
    if (val) {
      if (val === true || val === "True") {
        vm.axC.Value = "True";
      }
      if (val === false || val === "False") {
        vm.axC.Value = "False";
      }
      if (vm.axC.Value) {
        if (vm.axC.Value === "True") {
          vm.SendMessage = "[color=green]" + vm.li18n.messages.sendMessageSuccess + "[/color]"
        }
        else if (vm.axC.Value === "False") {
          vm.SendMessage = "[color=red]" + vm.li18n.messages.sendMessageFailed + "[/color]"
        }
      }
      else {
        vm.SendMessage = null;
      }
    }
    else {
      vm.axC.Value = null;
      vm.SendMessage = null;
    }
    return true;
  };
  vm.send = function () {
    vm.isSending = true;
    vm.SendMessage = vm.li18n.messages.sending;
    //accountName, from, to, title, body, sendRenderedAttachments, renderAttachements, attachments, callback
    helpers.sendMail(vm.field.AccountName, vm.field.From, vm.field.To, vm.field.Title, vm.field.Body, vm.field.SendRenderedAttachments, vm.field.RenderedAttachmentsTemplates, vm.field.FixedAttachments, function (result) {
      vm.field.Sended = true;
      vm.axC.setValue(result);
      vm.isSending = false;
    });
  };

  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      if (!val) {
        return "fieldIsRequired";
      }
    }
    return null;
  };
  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.input = $element.find("input[data-input-type=text]");
}

export { AxSurveyFieldSendMail };
