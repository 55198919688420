/**
 * settings actions
 */
export const SOUND_PLAY_START = 'SOUND_PLAY_START';
export const SOUND_PLAY_END = 'SOUND_PLAY_END';

/**
 * Action creators
 */
export function playSoundStart(soundType) {
  return {
    type: SOUND_PLAY_START,
    payload: {
      soundType: soundType
    }
  }
}

export function playSoundEnd(soundType) {
  return {
    type: SOUND_PLAY_END,
    payload: {
      soundType: soundType
    }
  }
}
