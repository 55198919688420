function LoginService(Errors, CONFIG, $http) {

  this.login = login;
  this.getAccessToken = getAccessToken;
  this.refreshSession = refreshSession;

  /**
   * Login user to login service.
   * Obtains JWT token with sessionId
   * @param {Object} Parameters
   * @param {String} p.login
   * @param {String} p.password
   * @param {Boolean} [p.withCredentials=false]
   * @return {Promise} Promise resolved with response data
   */
  function login(p) {

    var path = "login";

    return new Promise(function (resolve, reject) {

      var req = {
        login: p.login,
        password: p.password
      };

      $http.post(createUrl(CONFIG, path),
        JSON.stringify(req),
        { withCredentials: p.withCredentials ? p.withCredentials : false }
      ).then(function (res) {
        var data = res.data, status = res.status;

        if (status === 200) {

          if (data.result === 0) {
            resolve(data);
          } else {
            reject(raiseError(ERRORS.INVALID_CREDENTIALS));
          }

        } else {
          reject(raiseError(ERRORS.SERVICE_UNAVAILABLE));
        }

      }).catch(function (res) {
        var status = res.status;

        if (status === 0) {
          reject(raiseError(ERRORS.CONNECTION_REFUSED));
        } else if (status === 503) {
          reject(raiseError(ERRORS.SERVICE_UNAVAILABLE));
        } else {
          reject(raiseError(ERRORS.OTHER_EXCEPTION));
        }

      });

    });

  }

  /**
   * Sends get access token request to login service
   * @param {Object} p parameters
   * @param {String} p.refreshToken
   * @param {String} [p.tokenType="generic"] Token type
   * @param {?Object} [p.tokenParams=null] Token params - literal object
   * @return {Promise} Promise resolved with response data
   */
  function getAccessToken(p) {

    var path = "token/get-access-token";

    var tokenType = "tokenType" in p ? p.tokenType : "generic";
    var tokenParams = "tokenParams" in p ? p.tokenParams : null;

    var tokenParamsArray = [];
    if (tokenParams !== null) {

      for (var tp in tokenParams) {
        tokenParamsArray.push(tp + "=" + tokenParams[tp]);
      }
    }

    var tokenParamsString = tokenParamsArray.join("&");
    if (tokenParamsString.length > 0) {
      tokenParamsString = "&" + tokenParamsString
    }

    return new Promise(function (resolve, reject) {

      $http.get(createUrl(CONFIG, path) + "?refresh_token=" + p.refreshToken + "&token_type=" + tokenType + tokenParamsString,
        {
          withCredentials: false,
          headers: {
            "Authorization": "Bearer " + p.refreshToken
          }
        }
      ).then(function (res) {
        var data = res.data, status = res.status;

        if (status === 200) {

          if (data.result === 0) {
            resolve(data);
          } else if (data.result === 1) {
            reject(raiseError(ERRORS.INVALID_TOKEN));
          } else {
            reject(raiseError(ERRORS.OTHER_EXCEPTION));
          }
        } else {
          reject(raiseError(ERRORS.SERVICE_UNAVAILABLE));
        }

      }).catch(function (res) {
        var status = res.status;

        if (status === 0) {
          reject(raiseError(ERRORS.CONNECTION_REFUSED));
        } else if (status === 503) {
          reject(raiseError(ERRORS.SERVICE_UNAVAILABLE));
        } else {
          reject(raiseError(ERRORS.OTHER_EXCEPTION));
        }

      });

    });

  }

  /**
   * Sends refresh token (session) request to login service
   * @param {Object} p parameters
   * @param {String} p.refreshToken
   * @return {Promise} Promise resolved with response data
   */
  function refreshSession(p) {
    var path = "token/refresh-session";

    return new Promise(function (resolve, reject) {

      $http.get(createUrl(CONFIG, path) + "?refresh_token=" + p.refreshToken,
        {
          withCredentials: false,
          headers: {
            "Authorization": "Bearer " + p.refreshToken
          }
        }
      ).then(function (res) {
        var data = res.data, status = res.status;

        if (status === 200) {

          if (data.result === 0) {
            resolve(data);
          } else if (data.result === 1) {
            reject(raiseError(ERRORS.INVALID_TOKEN));
          } else {
            reject(raiseError(ERRORS.OTHER_EXCEPTION));
          }
        } else {
          reject(raiseError(ERRORS.SERVICE_UNAVAILABLE));
        }

      }).catch(function (res) {
        var status = res.status;

        if (status === 0) {
          reject(raiseError(ERRORS.CONNECTION_REFUSED));
        } else if (status === 503) {
          reject(raiseError(ERRORS.SERVICE_UNAVAILABLE));
        } else {
          reject(raiseError(ERRORS.OTHER_EXCEPTION));
        }

      });

    });

  }

  function createUrl(config, path) {
    var retUrl = "", port = "";

    if (config.services.login.fromDomain === true) {

      if (window.location.port.length > 0) {
        port = ":" + window.location.port;
      }

      retUrl = window.location.protocol + "//" + window.location.host + port + "/" + config.services.login.url + "/" + path;

    } else {
      retUrl = config.services.login.url + "/" + path;
    }

    return retUrl;

  };

  function raiseError(err) {
    return new err;
  }

  /** Error definitions */
  function Error() {
  }

  Error.prototype = Object.create(Errors.Error.prototype);

  function INVALID_CREDENTIALS() {
  }

  INVALID_CREDENTIALS.prototype = Object.create(Error.prototype, {
    code: { value: 1, enumerable: true },
    message: { value: "Invalid credentials", enumerable: true }
  });

  function SERVICE_UNAVAILABLE() {
  }

  SERVICE_UNAVAILABLE.prototype = Object.create(Error.prototype, {
    code: { value: 2, enumerable: true },
    message: { value: "Service unavailable", enumerable: true }
  });

  function OTHER_EXCEPTION() {
  }

  OTHER_EXCEPTION.prototype = Object.create(Error.prototype, {
    code: { value: 3, enumerable: true },
    message: { value: "Other exception", enumerable: true }
  });

  function CONNECTION_REFUSED() {
  }

  CONNECTION_REFUSED.prototype = Object.create(Error.prototype, {
    code: { value: 4, enumerable: true },
    message: { value: "Connection refused", enumerable: true }
  });

  function INVALID_TOKEN() {
  }

  INVALID_TOKEN.prototype = Object.create(Error.prototype, {
    code: { value: 5, enumerable: true },
    message: { value: "Invalid token", enumerable: true }
  });

  function UNKNOWN_ERROR() {
  }

  UNKNOWN_ERROR.prototype = Object.create(Error.prototype, {
    code: { value: 255, enumerable: true },
    message: { value: "Unknown error", enumerable: true }
  });

  var ERRORS = Object.create(null, {
    INVALID_CREDENTIALS: {
      value: INVALID_CREDENTIALS,
      enumerable: true
    },
    SERVICE_UNAVAILABLE: {
      value: SERVICE_UNAVAILABLE,
      enumerable: true
    },
    OTHER_EXCEPTION: {
      value: OTHER_EXCEPTION,
      enumerable: true
    },
    CONNECTION_REFUSED: {
      value: CONNECTION_REFUSED,
      enumerable: true
    },
    INVALID_TOKEN: {
      value: INVALID_TOKEN,
      enumerable: true
    },
    UNKNOWN_ERROR: {
      value: OTHER_EXCEPTION,
      enumerable: true
    }
  });

  this.Error = Error;
  this.ERRORS = ERRORS;
}

export { LoginService };
