import template from './AxFilterBuilder.html';

function AxFilterBuilder(FilterItem) {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      // Array of AxDataContactRecordDataField
      fields: '=axFields'
    },
    require: "ngModel",
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['FilterItem', controller]
  }

}

function controller(FilterItem) {

  var vm = this;

  /**
   * @var {Array.<FilterItem>
   */
  vm.filterItems = [];

  vm.addFilter = function () {

    var newItem = new FilterItem("");
    vm.filterItems.push(newItem);

  };

  vm.removeFilter = function (filterItem) {

    var idx = vm.filterItems.indexOf(filterItem);
    if (idx !== -1) {
      vm.filterItems.splice(idx, 1);
    }

  };

  vm.removeAllFilters = function () {
    vm.filterItems.length = 0;
  }

}

function link($scope, $element, $attrs, $ngModel) {

  $scope.$watchCollection("vm.filterItems", function (newVal, oldVal) {

    $ngModel.$setViewValue(newVal);

  });

}

export { AxFilterBuilder };
