import template from './AxSurveyFieldDateTime.html';

function AxSurveyFieldDateTime() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', 'DateTimeHelpers', controller]
  }
}


function controller(helpers, dth) {

  var vm = this;
  helpers.initField(vm);

  vm.dateTimeFormat = "YYYY-MM-DD HH:mm";
  vm.dateFormat = "YYYY-MM-DD";
  vm.timeFormat = "HH:mm";
  vm.axC.TimeValue = null;
  vm.axC.DateValue = null;
  vm.axC.getValue = function () {
    if (vm.axC.Value) {

      var valToMatch = vm.axC.Value,
        valueMoment;
      if (dth.dateTimeRegexp(valToMatch)) {
        valueMoment = moment(valToMatch, vm.dateTimeFormat);
        if (valueMoment.isValid()) {
          return valueMoment.format(vm.dateTimeFormat);
        }
      }

      return valToMatch;

    }
    return null;
  };

  // Uwaga kod walidacyjnyjest nadmiarowy ze względu na to, że sprawdza dozwolone zakresy czyasu
  // co aktualnie nie jest wymagane, ponieważ kontrolka wewnętrzna ax-date-time-picker nie pozawala aktualnie
  // na wprowadzenie danych z poza dozwolonego zakresu
  // ale kod tutaj zostaje, ponieważ może się okazać, że zmieni się działanie kontrolki
  // lub też z powodu na jakieś inne nieprzewidziane okoliczności zostaną jendak wprowadzone niepoprawne zakresy
  vm.axC.Validate = function (val) {

    var v = val,
      valueMoment,
      maxValueMoment,
      minValueMoment,
      errorMessage = [];
    if (vm.field.Mandatory === true) {
      if (!val) {
        return "fieldIsRequired";
      }

    } else { // Jeżeli nie jest mandatory, a jest nullem, to jest OK

      if (val === null) {
        return null;
      }

    }

    if (dth.checkDateTimeFormatRegexp(val) === false) {
      // Jeżeli format daty podany jako wartość nie jest w ogóle poprawny (np. jeżeli wartość domyślna)
      // to out i nie będzie i tak ustawiona
      return null;
    }

    // Formats
    if (dth.checkDateInTime(val) === false) {

      errorMessage.push(vm.li18n.messages.dateSurveyFieldError);

    }

    if (dth.checkTimeInDate(val) === false) {

      if (errorMessage.length === 1) {

        errorMessage.push(vm.li18n.messages.and);

      }

      errorMessage.push(vm.li18n.messages.time);

    }

    if (errorMessage.length > 0) {

      return [vm.li18n.messages.badFormat, " - ", vm.li18n.messages.check].concat(errorMessage).join(" ");

    }

    // Range
    // Sprawdzmy czy czas nie jest w złym zakresie w stosunku do min i max
    // i dodatkowo validacja momentjs, czy np nie jest 13 miesiąc, chociać to jest załatawiane maską na wejściu
    valueMoment = moment(v, vm.dateTimeFormat);

    if (vm.field.MinDateTime) {

      minValueMoment = moment(vm.field.MinDateTime);

      if (valueMoment.isBefore(minValueMoment)) {
        return [vm.li18n.messages.enteredDate, vm.li18n.messages.isEarlierThan, minValueMoment.format(vm.dateTimeFormat)].join(" ");
      }

    }

    if (vm.field.MaxDateTime) {

      maxValueMoment = moment(vm.field.MaxDateTime);

      if (valueMoment.isAfter(maxValueMoment)) {
        return [vm.li18n.messages.enteredDate, vm.li18n.messages.isLaterThan, maxValueMoment.format(vm.dateTimeFormat)].join(" ");
      }

    }

    return null;

  };

  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true && ((!vm.axC.getValue() || vm.dateTimeFormat.length === vm.axC.getValue().length))) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {

  var vm = $scope.vm,
    field = vm.field;

  vm.acceptedTimeRange = formatAcceptedTimeMessage(field.MinDateTime, field.MaxDateTime, vm.li18n);

  $scope.$watch('vm.li18n', function (newVal) {
    vm.acceptedTimeRange = formatAcceptedTimeMessage(field.MinDateTime, field.MaxDateTime, newVal)
  });
}

/**
 * Formats message with accepted time
 * @param {String} from
 * @param {String} to
 * @return {String}
 */
function formatAcceptedTimeMessage(min, max, li18n) {

  var acceptedTimeRange = [li18n.messages.acceptedDateTimeRange + ":"],
    minMoment = moment(min),
    maxMoment = moment(max),
    dateTimeFormat = "YYYY-MM-DD HH:mm",
    lFrom = li18n.messages.from.slice(0, 1).toLowerCase() + li18n.messages.from.slice(1).toLowerCase(),
    lTo = li18n.messages.to.slice(0, 1).toLowerCase() + li18n.messages.to.slice(1).toLowerCase();

  if (!min && !max) {
    acceptedTimeRange.push(li18n.messages.anyTimeRange);
  }

  if (min && !max) {
    acceptedTimeRange = acceptedTimeRange.concat([lFrom, minMoment.format(dateTimeFormat), lTo, li18n.messages.inifinity1]);
  }

  if (min && max) {
    acceptedTimeRange = acceptedTimeRange.concat([lFrom, minMoment.format(dateTimeFormat), lTo, maxMoment.format(dateTimeFormat)]);
  }

  if (!min && max) {
    acceptedTimeRange = acceptedTimeRange.concat([lFrom, li18n.messages.inifinity1, lTo, maxMoment.format(dateTimeFormat)]);
  }

  return acceptedTimeRange.join(" ");

}

export { AxSurveyFieldDateTime };
