import template from 'lodash-es/template';
import MediaProxyClient from '@uf/media-proxy-client/dist/MediaProxyClient.esm';
import * as JsonRpc from '@adt/json-rpc/dist/JsonRpc.esm';
import md5 from 'js-md5';
import { createEpicMiddleware } from 'redux-observable';
import emailStatusHTML from './templates/email-status.html';
import version from '../scripts/version';
import { uniq } from "lodash-es";
import { SimpleEncode } from "./ax.stringencryptor";

const emailStatusTemplate = template(emailStatusHTML);

(function (e, ng, undefined) {

  // Stany aktualnego połączenia video
  var AxVideoCallState = {
    none: 0, // Brak stanu, potrzebne do utworzenia domyślnego obiektu w danych angulara
    disconnected: 1, // Rozłączone i posprzątane
    initiated: 2, // zainicjowane - czyli nie się jeszcze w nim nie wydarzyło
    getMediaRequested: 3, // Zostało wysłane żądanie pobrania getMedia
    getMediaAccepted: 4, // Żądanie get media zostało zaakceptowane przez użytkownika
    createOfferRequested: 5, // Uruchomione createOffer
    offerCreated: 6, // Create offer zostało wykonane
    setLocalDescriptionRequested: 7, // Uruchomione set local description
    localDescriptionSet: 8, // Local description zostąło ustawione
    candidatesReady: 9, // Wszystkie ice candidate zostały wygenerowane
    makeCallRequestSent: 10, // Zostało wysłane makeCall do serwera
    makeCallResponseReceived: 11, // Zostało otrzymane response na makeCall
    connected: 12, // Połączenie zostało nawiązane
    disconnecting: 20, // Rozpoczęta procedura rozłączania
    hangupCallRequestSent: 21, // Zostało wysłane żądanie rozłączenia
    hangupCallResponseReceived: 22, // Zostało otrzymane response na hangupVideoCall
    remoteStreamAdded: 24 // Zdalny stream został dodany
  };

  var pc = null;
  var peerConnection = null;
  var peerConnections = [];

  var incomingCall = null;
  var callSession = null;
  var makeVideoCall = null;

  // MOCK
  var mock = {
    video: {
      options: {
        /*optional: [
                           {DtlsSrtpKeyAgreement: true},
                           {RtpDataChannels: false}
                       ]*/
      },
      media: { video: true, audio: true }
      /*
                 media : { video : {
                   mandatory: {
                     maxWidth: 320,
                     maxHeight: 180
                     //maxWidth: 640,
                     //maxHeight: 480

                   },
                     optional: []
                 }, audio : true}*/

    }
  };


  // Set WebRtc objects
  navigator.getMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
  var RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
  var RTCSessionDescription = window.RTCSessionDescription || window.mozRTCSessionDescription || window.webkitRTCSessionDescription;

  // Translations
  var i18n = {
    pl: {
      pikaday: {
        previousMonth: 'Poprzedni miesiąc',
        nextMonth: 'Następny miesiąc',
        months: ['Styczeń', 'Luty', 'marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        weekdays: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        weekdaysShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pi', 'Sob']
      },
      statuses: {
        0: "Wylogowany",
        1: "Zalogowany",
        2: "Dostępny",
        3: "Wrapup",
        4: "Przerwa",
        5: "Nieobecny",
        10: "W sesji",
        11: "Zestawianie",
        32767: "Nieznany"
      },
      messages: {
        add: "Dodaj",
        clear: "Wyczyść",
        me: "Ja",
        loggedIn: "Zalogowany",
        loggingIn: "Logowanie",
        alreadyLogged: "Już zalogowany",
        connecting: "Łączenie",
        agentConnecting: "Łączenie z systemem",
        error: "Błąd",
        connected: "Połączony",
        connectionError: "Nie można nawiązać połączenia",
        otherError: "Inny błąd",
        connectionLost: "Utracono połączenie z serwerem",
        invalidCredentials: "Błędne dane użytkownika",
        networkAccessRestricted: "Nie możesz logować się z tego komputera",
        licenceError: "Błąd licencji",
        passwordExpired: "Twoje hasło wygasło",
        providerError: "Błąd providera",
        pleasePickupTeleset: "Proszę podnieść słuchawkę na telefonie",
        serviceTemporaryUnavailable: "Serwis czasowo niedostępny",
        serviceUnavailable: "Serwis niedostępny",
        stationAccessedByAnotherUser: "Stacja jest zajęta przez innego użytkownika",
        invalidStation: "Niepoprawna stacja",
        waitingBeforeReconnect: "Oczekiwanie przed połączeniem",
        unknown: "Nieznany",
        videoError: "błąd video",
        sent: "wysłany",
        notSent: "nie wysłany",
        beforeUnload: "Przeładowanie strony spowoduje wylogowanie!",
        fieldIsRequired: "To pole jest wymagane",
        blindTransfer: "Ślepy transfer",
        makeCall: "Zadzwoń",
        sendDtmf: "Wyślij DTMF",
        disconnectCall: "Rozłącz",
        abort: "Przerwij",
        invalidFormat: "Nieprawidłowy format",
        validationError: "Błąd walidacji",
        answers: "Odpowiedzi",
        scriptValues: "Zmienne",
        result: "Rezultat",
        intermediateResults: "Wyniki pośrednie",
        from: "Od",
        replyTo: "Odpowiedz do",
        mailCopy: "Kopia",
        mailBlindCopy: "Kopia ukryta",
        to: "Do",
        title: "Tytuł",
        message: "Wiadomość",
        sending: "Wysyłanie...",
        sendMessageFailed: "Błąd podczas wysyałnia wiadomości",
        sendMessageSuccess: "Poprawnie wysłano wiadomość",
        summary: "Podsumowanie",
        attachments: "Załączniki",
        addAttachment: "Dodaj załącznik",
        save: "Zapisz",
        cancel: "Anuluj",
        edit: "Edytuj",
        back: "Wstecz",
        next: "Dalej",
        break: "Przerwij",
        finish: "Zakończ",
        send: "Wyślij",
        tries: "Próby",
        billingTries: "Próby billingowe",
        lastTryTime: "Ostatnia próba",
        expectation: "Prawdopodobieństwo",
        closePhone: "Zamknij telefon",
        closePhoneMsg: "Czy na pewno chcesz zamknąć {0}({1})?",
        recordHistory: "Historia rekordu",
        newRecord: "Nowy rekord",
        contactPhones: "Telefony",
        recordAborting: "Przerwanie",
        recordAbortingMsg: "Czy na pewno przerwać?",
        recordTerminatedSuccesfully: "Rekord został przerwany",
        unableToTerminateRecord: "Nie można przerwać rekordu",
        recordShiftedSuccesfully: "Rekord został przełożony",
        unableToShiftRecord: "Nie można przełożyć rekordu",
        invalidRecordState: "Niepoprawny stan rekordu",
        invalidRecord: "Niepoprawny rekord lub brak identyfikacji klienta",
        recordShifting: "Przekładanie",
        recordShiftingMsg: "Czy na pewno przełożyć?",
        shiftTime: "Czas przełożenia",
        shiftReason: "Powód przełożenia",
        shiftPhone: "Telefon",
        automatic: "Automatycznie",
        toMe: "Do mnie",
        campaign: "Kampania",
        campaigns: "Kampanie",
        users: "Użytkownicy",
        note: "Notatka",
        shiftRecord: "Przełóż",
        select: "Wybierz",
        recordState: "Stan rekordu",
        records: "Rekordy",
        loginLocalisation: "Lokalizacja",
        loginUser: "Użytkownik",
        loginPassword: "Hasło",
        logIn: "Zaloguj",
        logout: "Wyloguj",
        newPassword: "Nowe hasło",
        confirmNewPassword: "Potwierdź nowe hasło",
        loginIntoSystem: "LOGOWANIE DO SYSTEMU",
        interactions: "Interakcje",
        crm: "Crm",
        preview: "Preview",
        fieldRequired: "To pole jest wymagane",
        phoneNumber: "Numer telefonu",
        inCampaignContent: "W ramach kampanii",
        useOwnPresentationNumber: "Użyj własnego numeru prezentacji",
        dial: "Połącz",
        transfer: "Transfer",
        id: "Id",
        thingName: "Nazwa",
        availables: "Dostępni",
        loggedIns: "Zalogowani",
        queuedSessions: "Zakolejkowane sesje",
        avgWaitingTime: "Średni czas oczekiwania",
        state: "Stan",
        stateTime: "Czas stanu",
        callEnded: "Połączenie zakończone",
        notFound: "Nie znaleziono wyniku",
        predictiveCall: "Połączenie predictive",
        inboundCall: "Połączenie inbound",
        queueWaitingTime: "Czas oczekiwania w kolejce",
        chat: "Chat",
        incomingCall: "Połączenie",
        autoNavigateToInteractions: "Automatycznie przenoś do interakcji",
        fetchRecords: "Pobierz rekordy",
        releaseRecords: "Zwolnij rekordy",
        details: "Detale",
        contactHistory: "Historia kontaktu",
        waitingForClientVideo: "Oczekiwanie na video klienta",
        files: "Pliki",
        availableFiles: "Dostępne pliki",
        receivedFiles: "Otrzymane pliki",
        hints: "Podpowiedzi",
        noActiveSessions: "Nie masz żadnych aktywnych sesji",
        language: "Język",
        customer: "Klient",
        get: "Pobierz",
        insert: "Wstaw",
        gettingMenervaHint: "Pobieram podpowiedź",
        errorGettingMenervaHint: "Błąd pobierania podpowiedzi",
        noHintsAvailable: "Brak dostępnych podpowiedzi",
        holdUnhold: "Zawieś/odwieś",
        holdDisabled: "Zawieszenie niedostępne",
        dialpad: "Wybierz numer",
        sendSMS: "Wyślij SMS",
        pickup: "Odbierz",
        mute: "Wyłącz mikrofon",
        transferDisabled: "Transfer niedostępny",
        muteDisabled: "Wyłączenie mikrofonu niedostępne",
        sessions: "Sesje",
        value: "wartość",
        isGreaterThan: "jest większa niż",
        isLowerThan: "jest mniejsza niż",
        maximum: "maksymalna",
        minimum: "minimalna",
        emailCheckingInProgress: "Trwa sprawdzanie adresu email",
        domainNotExists: "Domena nie istnieje",
        setState: "Ustaw status",
        statusSetAfterSession: "Status po zakończeniu sesji",
        errorSettingStatus: "Błąd ustawiania statusu",
        setStage: "Ustaw etap",
        stageSetTo: "Ustawiony poziom",
        setStageError: "Błąd ustawiania poziomu",
        badFormat: "Błędny format",
        and: "i",
        check: "sprawdź",
        time: "czas",
        dateSurveyFieldError: "datę",
        enteredDate: "Wprowadzona data",
        isEarlierThan: "jest wcześniejsza niż",
        isLaterThan: "jest późniejsza niż",
        acceptedRange: "Dozwolony zakres",
        acceptedDateTimeRange: "Dozwolony zakres czasu",
        anyTimeRange: "dowolny",
        infinity: "nieskończoność",
        inifinity1: "nieskończoności",
        download: "Pobierz",
        fileShared: "Udostępniono plik",
        clientSendsFile: "Klient wysłał plik",
        doubleClickToShare: "Kliknij dwa razy aby udostępnić",
        urlAddress: "Adres URL",
        nameAndSurname: "Imię i nazwisko",
        email: "Email",
        unreadMessages: "Nieprzeczytanych wiadomości",
        toggleMenu: "Przełącz menu",
        selectCustomer: "Wybierz klienta",
        inCall: "Połączenie przychodzące",
        accept: "Akceptuj",
        notExists: "nie istnieje",
        saveToRepository: "Zapisz do repozytorium",
        system: "System",
        clientEndedChat: "Klient zakończył chat",
        voiceCall: "Rozmowa głosowa",
        videoCall: "Połączenie video",
        voiceCallEnded: "Rozmowa głosowa zakończona",
        videoCallEnded: "Połączenie video zakończone",
        doubleClickToCopy: "Kliknij dwa razy aby skopiować do schowka",
        newChatSession: "Nowa sesja chat",
        newSessions: "Nowe sesje",
        unreadChatMessages: "Nowe wiadomości chat",
        voice: "Głos",
        closeChatSessionMsg: "Czy na pewno zakończyć sesję chat?",
        video: "Video",
        stationNotAssigned: "Telefon nieprzypisany",
        stationInitializeError: "Błąd podczas inicjalizacji telefonu",
        unsupportedStationType: "Nieobsługiwany typ telefonu",
        initializingPhone: "Inicjalizacja telefonu",
        stationDisconnected: "Telefon rozłączony",
        survey: "Skrypt",
        emailRemoved: "usunięty",
        emailRejected: "odrzucony",
        emailReturnedToQueue: "zwrócony do kolejki",
        emailMarkedAsSpam: "oznaczony jako spam",
        emailSent: "został wysłany",
        emailProcessed: "został przetworzony",
        requiredActions: "Wymagane działania",
        endCall: "zakończenie połączenia",
        assignCustomer: "przypisanie klienta",
        closeEmail: "obsługa e-maila",
        completeSurvey: "zakończenie skryptu",
        endSession: "zakończenie sesji",
        replyPlaceholder: "Wpisz wiadomość...",
        passwordsNotMatch: "Hasła nie pasują do siebie",
        changePassword: "Zmień hasło",
        passwordChangeRequired: "WYMAGANA ZMIANA HASŁA",
        oldPassword: "Aktualne hasło",
        changingPassword: "Zmieniam hasło",
        passwordChanged: "Hasło zostało zmienione",
        passwordChangeError: "Błąd podczas zmiany hasła",
        minimumPasswordLength: "Minimalna długość hasła to",
        signs: "znaków",
        passwordMustContain: "Hasło musi zawierać",
        digitsAndLetters: "cyfry i litery",
        lowercaseAndUppercaseLetters: "małe i duże litery",
        tooManyUnsuccessfulLogin: "Zbyt wiele nieudanych logowań. Twoje konto zostało zablokowane na jakiś czas.",
        clickToContactSearch: "Kliknij aby wyszukać kontakt"
      },
      menu: {
        dashboard: "Pulpit",
        interactions: "Interakcje",
        workflow: "Workflow",
        errands: "Zlecenia",
        mailbox: "Skrzynka pocztowa",
        tasks: "Zadania",
        projects: "Projekty",
        contactCenter: "Contact center",
        preview: "Preview",
        administration: "Administracja",
        users: "Użytkownicy",
        userDictionaries: "Słowniki użytkownika",
        systemDictionaries: "Słowniki systemowe",
        permissions: "Role",
        permissionsView: "Uprawnienia widok",
        userGroups: "Grupy użytkowników",
        kb: "Baza wiedzy",
        events: "Wydarzenia",
        offer: "Oferta",
        logistics: "Logistyka",
        map: "Mapa",
        routes: "Trasy",
        employees: "Pracownicy",
        crews: "Załogi",
        crm: "CRM",
        customers: "Klienci",
        email: "Poczta",
        myProfile: "Mój Profil",
        repository: "Repozytorium",
        report: "Raport",
        systemTemplates: "Szablony systemowe",
        emailTemplates: "Szablony e-mail",
        emailAccounts: "Konta e-mail",
        messageTemplates: "Szablony wiadomości",
        whisbearOrders: "Zamówienia",
        resources: "Zasoby",
        statistics: "Statystyki"
      },
      axRecordHistoryColumns: {
        Date: "Data",
        User: "Użytkownik",
        SessionSubType: "Typ sesji",
        Duration: "Czas trwania",
        Result: "Rezultat",
        IntermediateResult: "Wynik pośredni",
        CreateCause: "Powód utworzenia",
        CampaignRecordState: "Stan rekordu",
        InitiationResult: "Wynik inicjacji",
        Dnis: "Dnis",
        Note: "Notatka",
        SerialSessionId: "Id sesji",
        SerialConnectionId: "Id połączenia",
        SerialScriptId: "Id sesji skryptu"
      },
      axTerminateSessionReason: {
        0: "Automat",
        1: "Fax",
        2: "Nieosiągalny",
        3: "Błędny numer",
        4: "Poczta głosowa",
        5: "Nie odebrane",
        6: "Zajęte",
        7: "Odrzucone",
        8: "Błąd",
        9: "Drop"
      },
      axSessionSubtype: {
        0: "Połączenie Inbound",
        1: "Czat",
        3: "Email",
        4: "Social",
        5: "Workflow",
        10: "Połączenie predictive",
        11: "Połączenie preview",
        12: "Oddzwonienie"
      },
      axCallEventCause: {
        0: 'None',
        1: 'ActiveParticipation',
        2: 'Alternate',
        3: 'Zajęty',
        4: 'CallBack',
        5: 'CallCancelled',
        6: 'CallForwardImmediate',
        7: 'CallForwardBusy',
        8: 'CallForwardNoAnswer',
        9: 'Przekazanie',
        10: 'Brak odpowiedzi',
        11: 'CallPickup',
        12: 'CampOn',
        13: 'Nieosiągalny',
        14: 'DoNotDisturb',
        15: 'IncompatibleDestination',
        16: 'InvalidAccountCode',
        17: 'KeyOperation',
        18: 'Lockout',
        19: 'Maintenance',
        20: 'NetworkCongestion',
        21: 'NetworkNotObtainable',
        22: 'Inbound',
        23: 'NoAvailableAgents',
        24: 'Overrided',
        25: 'Park',
        26: 'Overflow',
        27: 'Recall',
        28: 'Przekazane',
        29: 'ReorderTone',
        30: 'ResourcesNotAvailable',
        31: 'SilentParticipation',
        32: 'Transfer',
        33: 'TrunksBusy',
        35: 'Blocked',
        36: 'CharacterCountReached',
        37: 'Konsultacja',
        38: 'Distributed',
        39: 'DTMFDigitDetected',
        40: 'DurationExceeded',
        41: 'EndOfMessageDetected',
        42: 'EnteringDistribution',
        43: 'ForcedPause',
        44: 'Make call',
        45: 'MessageSizeExceeded',
        46: 'NetworkSignal',
        47: 'NextMessage',
        48: 'Zakończone',
        49: 'NoSpeechDetected',
        50: 'NumberChanged',
        51: 'Konferencja single step',
        52: 'SingleStepTransfer',
        53: 'SpeechDetected',
        54: 'Rezygnacja',
        55: 'TerminationCharacterReceived',
        56: 'Timeout',
        57: 'ACDBusy',
        58: 'ACDForward',
        59: 'ACDSaturated',
        60: 'AlertTimeExpired',
        61: 'AutoWork',
        62: 'CampOnTrunks',
        63: 'Konferencja',
        64: 'DestDetected',
        65: 'DestOutOfOrder',
        66: 'DistributionDelay',
        67: 'ForcedTransition',
        68: 'Wtargnięcie',
        69: 'Błędny numer',
        70: 'JoinCall',
        71: 'KeyOperationInUse',
        72: 'Predictive Call',
        73: 'MessageDurationExceeded',
        74: 'MultipleAlerting',
        75: 'MultipleQueuing',
        76: 'NetworkDialling',
        77: 'NetworkOutOfOrder',
        78: 'Zakończone',
        79: 'NotAvaliableBearerService',
        80: 'NotSupportedBearerService',
        81: 'Błędny numer',
        82: 'QueueCleared',
        83: 'RemainsInQueue',
        84: 'Reserved',
        85: 'SelectedTrunkBusy',
        86: 'Suspend',
        87: 'UnauthorisedBearerService',
        88: 'Preview Call',
        89: 'BaseCallDisconnected',
        90: 'MakePbxCall',
        91: 'MakePbxConsultation',
        92: 'Przekazanie połączenia'
      },
      axDataContactRecordState: {
        0: "Otwarty",
        1: "Przełożony",
        10: "Zamknięty",
        50: "W trakcie"
      },
      axSessionInitiationResult: {
        0: 'Połączony',
        1: 'Połączono brak głosu',
        2: 'Busy',
        3: 'Brak odpowiedzi',
        4: 'Błąd sieci',
        5: 'Brak odpowiedzi sieci',
        6: 'Niedostępny',
        7: 'Poczta głosowa',
        8: 'Automatyczna sekretarka',
        9: 'Fax',
        10: 'Błąd linii wewnętrznej',
        11: 'Numer nieprzydzielony',
        12: 'Przerwane przez uzytkownika',
        13: 'Błędny format numeru',
        14: 'Sieć zajeta',
        15: 'Brak wolnych kanałów',
        16: 'Odrzucone',
        17: 'Rozłączony podczas wykrywania głosu',
        18: 'Błąd'
      },
      axCreateReason: {
        0: "Nowa",
        1: "Transfer",
        2: "Przełożenie",
        3: "Przełożenie TCO"
      },
      axSessionState: {
        0: "Zaakceptowana",
        1: "W kolejce",
        2: "Przekazana do użytkownika",
        3: "Odebrana przez użytkownika",
        4: "Odrzucona przez użytkownika",
        5: "Rozłączenie respondenta",
        6: "Zakończona przez respondenta",
        7: "Zakończona przez użytkownika",
        8: "Invalid destination",
        9: "Przetransferowana",
        10: "Błąd przekazania",
        11: "Przerwana systemowo",
        12: "Waiting timeout",
        13: "Inicjowanie",
        14: "Nie udało się połączyć",
        15: "Establishing with user",
        16: "Unable to establish with user",
        17: "Odrzucona",
        65535: "Nie ustawiony"
      },
      axDataContactShiftReason: {
        0: "Błędna próba",
        1: "Użytkownik",
        2: "Drop",
        3: "Operacje na danych",
        4: "Błąd"
      },

      axDataQueryCondition: {
        0: "Równa się",
        1: "Nie równa się",
        2: "Większe niż",
        3: "Mniejsze niż",
        4: "Większe lub równe",
        5: "Mniejsze lub równe",
        6: "Wyrażenie regularne",
        7: "Różne: wyrażenie regularne",
        8: "Jest",
        9: "Nie jest",
        10: "Zawiera",
        11: "Nie zawiera",
        12: "Pomiędzy",
        13: "ArrayContains",
        14: "Null",
        15: "Nie null"
      },
      recordingButton: {
        recording: "Nagrywanie",
        recordingUser: "Nagrywanie użytkownika",
        stopped: "Zatrzymane",
        paused: "Pauza",
        stop: "Zatrzymaj",
        recordBoth: "Nagrywaj obie strony",
        recordUser: "Nagrywaj użytkownika"
      },

      preview: {
        allCampaigns: "Wszystkie"
      },
      soundSettings: {
        title: "Ustawienia dźwięku",
        newChatSound: "Dźwięk rozpoczęcia chatu",
        newEmailSound: "Dźwięk nowego e-maila",
        newChatMessageSound: "Dźwięk nowej wiadomości chat",
        sessionEndSound: "Dźwięk końca sesji",
        routingSound: "Dźwięk zestawienia połączenia"

      },
      statistics: {
        'STATISTICS.TILES.LOGGED': 'Zalogowany (total)',
        'STATISTICS.TILES.AVAILABLE': 'Dostępny (total)',
        'STATISTICS.TILES.IN_SESSION': 'W sesji(total)',
        'STATISTICS.TILES.PRODUCTIVE_BREAKS': 'Przerwy produktywne',
        'STATISTICS.TILES.NONPRODUCTIVE_BREAKS': 'Przerwy nieproduktywne',
        'STATISTICS.TILES.WRAPUP': 'Wrapup (total)',
        'STATISTICS.TILES.INCOMING': 'Przychodzące rozmowy',
        'STATISTICS.TILES.INCOMING_TOOLTIP': 'Odebrane/Odrzucone',
        'STATISTICS.TILES.OUTGOING': 'Wychodzące rozmowy',
        'STATISTICS.TILES.OUTGOING_TOOLTIP': 'Odebrane/Odrzucone',
        'STATISTICS.TILES.MANUAL': 'Manualne',
        'STATISTICS.TILES.MANUAL_TOOLTIP': 'Połączone/Zainicjowane',
        'STATISTICS.TILES.PREVIEW': 'Rozmowy preview',
        'STATISTICS.TILES.PREVIEW_TOOLTIP': 'Połączone/Zainicjowane',
        'STATISTICS.TILES.EMAIL': 'Email',
        'STATISTICS.TILES.EMAIL_TOOLTIP': 'Odebrane/Odrzucone',
        'STATISTICS.TILES.CHAT': 'Chat',
        'STATISTICS.TILES.CHAT_TOOLTIP': 'Odebrane/Odrzucone',
        'STATISTICS.TILES.SOCIAL': 'Social',
        'STATISTICS.TILES.SOCIAL_TOOLTIP': 'Odebrane/Odrzucone',
        'STATISTICS.TILES.WORKFLOW': 'Workflow',
        'STATISTICS.TILES.WORKFLOW_TOOLTIP': 'Odebrane/Przekazane',
        'STATISTICS.COUNTERS.SERVICE_TIME': 'Czas obsługi',
        'STATISTICS.COUNTERS.SUBSTANTIVE_PRODUCTIVITY': 'Produktywność merytoryczna',
        'STATISTICS.COUNTERS.REAL_PRODUCTIVITY': 'Produktywność rzeczywista',
        'STATISTICS.COUNTERS.INBOUND_SUCCEED': 'Połączenia inbound zakończone sukcesem',
        'STATISTICS.COUNTERS.INBOUND_TIME': 'Połączenia inbound czas w sesji',
        'STATISTICS.COUNTERS.INBOUND_AVG_TIME': 'Połączenia inbound śr. czas w sesji',
        'STATISTICS.COUNTERS.INBOUND_SHIFTED': 'Połączenia inbound przełożone',
        'STATISTICS.COUNTERS.OUTBOUND_SUCCEED': 'Połączenia outbound zakończone sukcesem',
        'STATISTICS.COUNTERS.OUTBOUND_TIME': 'Połączenia outbound czas w sesji',
        'STATISTICS.COUNTERS.OUTBOUND_AVG_TIME': 'Połączenia outbound śr. czas w sesji',
        'STATISTICS.COUNTERS.OUTBOUND_SHIFTED': 'Połączenia outbound przełożone',
        'STATISTICS.COUNTERS.PREVIEW_SUCCEED': 'Połączenia preview zakończone sukcesem',
        'STATISTICS.COUNTERS.PREVIEW_TIME': 'Połączenia preview czas w sesji',
        'STATISTICS.COUNTERS.PREVIEW_AVG_TIME': 'Połączenia preview śr. czas w sesji',
        'STATISTICS.COUNTERS.PREVIEW_SHIFTED': 'Połączenia preview przełożone',
        'STATISTICS.COUNTERS.MANUAL_SUCCEED': 'Połączenia manualne zakończone sukcesem',
        'STATISTICS.COUNTERS.MANUAL_TIME': 'Połączenia manualne czas w sesji',
        'STATISTICS.COUNTERS.MANUAL_AVG_TIME': 'Połączenia manualne śr. czas w sesji',
        'STATISTICS.COUNTERS.MANUAL_SHIFTED': 'Połączenia manualne przełożone',
        'STATISTICS.CHARTS.STATES.TITLE': 'Stany',
        'STATISTICS.CHARTS.STATES.SUBTITLE': 'Czasy w statusach',
        'STATISTICS.CHARTS.STATES.LABELS.AVAILABLE': 'Dostępny',
        'STATISTICS.CHARTS.STATES.LABELS.IN_SESSION': 'W sesji',
        'STATISTICS.CHARTS.STATES.LABELS.WRAPUP': 'Wrapup',
        'STATISTICS.CHARTS.STATES.LABELS.BREAKS': 'Przerwa',
        'STATISTICS.CHARTS.BREAKS.TITLE': 'Przerwy',
        'STATISTICS.CHARTS.BREAKS.SUBTITLE': 'Poza i w czasie pracy',
        'STATISTICS.CHARTS.BREAKS.LABELS.PRODUCTIVE': 'Przerwy produktywne',
        'STATISTICS.CHARTS.BREAKS.LABELS.NON_PRODUCTIVE': 'Przerwy nieproduktywne',
        'STATISTICS.CHARTS.SESSIONS.TITLE': 'Sesje',
        'STATISTICS.CHARTS.SESSIONS.SUBTITLE': 'Typy sesji',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CONNECTIONS': 'Połączenia',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CHAT': 'Chat',
        'STATISTICS.CHARTS.SESSIONS.LABELS.EMAIL': 'Email',
        'STATISTICS.CHARTS.SESSIONS.LABELS.SOCIAL': 'Social',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.TITLE': 'Zaakceptowane sesje',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.SUBTITLE': 'Sesje odebrane i odrzucone',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.ACCEPTED': 'Zaakceptowane sesje',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.REJECTED': 'Odrzucone',
        'STATISTICS.CHARTS.SUCCESSES.TITLE': 'Sukcesy',
        'STATISTICS.CHARTS.SUCCESSES.SUBTITLE': 'Sukcesy',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.SUCCESSES': 'Sukcesy',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.ACCEPTED': 'Zaakceptowane sesje'
      }
    },

    en: {
      pikaday: {
        previousMonth: 'Previous month',
        nextMonth: 'Next month',
        months: ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      },
      statuses: {
        0: "Logged out",
        1: "Logged in",
        2: "Available",
        3: "Wrapup",
        4: "Break",
        5: "Away",
        10: "In session",
        11: "Connecting",
        32767: "Unknown"
      },
      messages: {
        add: "Add",
        clear: "Clear",
        me: "Me",
        loggedIn: "Logged in",
        loggingIn: "Logging in",
        alreadyLogged: "Already logged",
        connecting: "Connecting",
        agentConnecting: "Connecting with service",
        error: "Error",
        connected: "Connected",
        connectionError: "Unable to connect",
        otherError: "Other error",
        connectionLost: "Lost connection with service",
        invalidCredentials: "Invalid credentials",
        networkAccessRestricted: "You cannot login from this computer",
        licenceError: "License error",
        passwordExpired: "Your password expired",
        providerError: "Provider error",
        pleasePickupTeleset: "Please pickup teleset",
        serviceTemporaryUnavailable: "Service temporary unavailable",
        serviceUnavailable: "Service unavailable",
        stationAccessedByAnotherUser: "Station is already in use by another user",
        invalidStation: "Invalid station",
        waitingBeforeReconnect: "Waiting before reconnect",
        unknown: "Unknown",
        notFound: "Value not found",
        videoError: "video error",
        sent: "sent",
        notSent: "not sent",
        beforeUnload: "Reloading this page will cause the log out",
        fieldIsRequired: "This field is required",
        blindTransfer: "Blind transfer",
        makeCall: "Call",
        sendDtmf: "Send DTMF",
        disconnectCall: "Disconnect",
        abort: "Abort",
        invalidFormat: "Invalid format",
        validationError: "Validation error",
        answers: "Answers",
        scriptValues: "Values",
        result: "Results",
        intermediateResults: "Intermediate results",
        from: "From",
        replyTo: "Reply to",
        mailCopy: "Copy",
        mailBlindCopy: "Blind copy",
        to: "To",
        title: "Title",
        message: "Message",
        sending: "Sending...",
        sendMessageFailed: "An error occured during sending the message",
        sendMessageSuccess: "Message sent successfully",
        summary: "Summary",
        attachments: "Attachments",
        addAttachment: "Add attachment",
        save: "Save",
        cancel: "Cancel",
        edit: "Edit",
        back: "Back",
        next: "Next",
        break: "Break",
        finish: "Finish",
        send: "Send",
        tries: "Tries",
        billingTries: "Billing tries",
        lastTryTime: "Last try time",
        expectation: "Expectation",
        closePhone: "Close phone",
        closePhoneMsg: "Are you sure you want to close {0}({1})?",
        recordHistory: "Record history",
        newRecord: "New record",
        contactPhones: "Phones",
        recordAborting: "Abort",
        recordAbortingMsg: "Are you sure you want to abort ?",
        recordTerminatedSuccesfully: "Record terminated",
        unableToTerminateRecord: "Unable to terminate record",
        recordShiftedSuccesfully: "Record shifted",
        unableToShiftRecord: "Unable to shift record",
        invalidRecordState: "Invalid record state",
        invalidRecord: "Invalid record or missing customer identification",
        recordShifting: "Shifting",
        recordShiftingMsg: "Do you want to shift ?",
        shiftTime: "Shift time",
        shiftReason: "Shift reason",
        shiftPhone: "Phone",
        automatic: "Automatic",
        toMe: "To me",
        campaign: "Campaign",
        campaigns: "Campaigns",
        users: "Users",
        note: "Note",
        shiftRecord: "Shift",
        select: "Select",
        recordState: "Record state",
        records: "Records",
        loginLocalisation: "Localization",
        loginUser: "User",
        loginPassword: "Password",
        logIn: "Log in",
        logout: "Logout",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        loginIntoSystem: "LOGIN INTO COMMUNICATIONS SYSTEM",
        interactions: "Interactions",
        crm: "Crm",
        preview: "Preview",
        fieldRequired: "This field is required",
        phoneNumber: "Phone number",
        inCampaignContent: "Call in campaign content",
        useOwnPresentationNumber: "Use private identification number",
        dial: "Dial",
        transfer: "Transfer",
        id: "Id",
        thingName: "Name",
        availables: "Available",
        loggedIns: "Logged users",
        queuedSessions: "Queued session",
        avgWaitingTime: "Average waiting time",
        state: "State",
        stateTime: "State time",
        callEnded: "Call ended",
        predictiveCall: "Predictive call",
        inboundCall: "Inbound call",
        queueWaitingTime: "In queue time",
        chat: "Chat",
        incomingCall: "Incoming call",
        autoNavigateToInteractions: "Auto navigate to interactions",
        fetchRecords: "Fetch records",
        releaseRecords: "Release records",
        details: "Details",
        contactHistory: "Contact history",
        waitingForClientVideo: "Waiting for client video",
        files: "Files",
        availableFiles: "Available files",
        receivedFiles: "Received files",
        hints: "Hints",
        noActiveSessions: "You don't have any active session",
        language: "Language",
        customer: "Customer",
        get: "Get",
        insert: "Insert",
        gettingMenervaHint: "Searching for hint",
        errorGettingMenervaHint: "Error getting hint",
        noHintsAvailable: "No hints available",
        holdUnhold: "Hold/unhold",
        holdDisabled: "Hold disabled",
        dialpad: "Dialpad",
        sendSMS: "Send SMS",
        pickup: "Pickup",
        mute: "Mute",
        transferDisabled: "Transfer disabled",
        muteDisabled: "Mute disabled",
        sessions: "Sessions",
        value: "value",
        isGreaterThan: "is greater than",
        isLowerThan: "is lower than",
        maximum: "maximum",
        minimum: "minimum",
        emailCheckingInProgress: "Email checking in progress",
        domainNotExists: "Domain not exists",
        setState: "Set state",
        statusSetAfterSession: "After session state",
        errorSettingStatus: "Error setting status",
        setStage: "Set stage",
        stageSetTo: "Data record stage",
        setStageError: "Set stage error",
        badFormat: "Bad syntax",
        and: "and",
        check: "check",
        time: "time",
        dateSurveyFieldError: "date",
        enteredDate: "Entered date",
        isEarlierThan: "is earlier than",
        isLaterThan: "is later than",
        acceptedRange: "Allowed range",
        acceptedDateTimeRange: "Allowed time range",
        anyTimeRange: "any",
        infinity: "infinity",
        inifinity1: "infinity",
        download: "Download",
        fileShared: "Shared file",
        clientSendsFile: "Client sends file",
        doubleClickToShare: "Double click to share",
        urlAddress: "URL Address",
        nameAndSurname: "Name",
        email: "Email",
        unreadMessages: "Unread messages",
        toggleMenu: "Toggle menu",
        selectCustomer: "Select customer",
        inCall: "Incoming call",
        accept: "Accept",
        notExists: "not exists",
        saveToRepository: "Save to repository",
        system: "System",
        clientEndedChat: "Client ended chat",
        voiceCall: "Voice call",
        videoCall: "Video call",
        voiceCallEnded: "Voice call ended",
        videoCallEnded: "Video call ended",
        doubleClickToCopy: "Double click to copy to clipboard",
        newChatSession: "New chat session",
        newSessions: "New sessions",
        unreadChatMessages: "Unread chat messages",
        voice: "Voice",
        closeChatSessionMsg: "Are you sure?",
        video: "Video",
        stationNotAssigned: "Phone not assigned",
        stationInitializeError: "Error during phone initialization",
        unsupportedStationType: "Unsupported phone type",
        initializingPhone: "Initializing phone",
        stationDisconnected: "Phone disconnected",
        survey: "Survey",
        emailRemoved: "deleted",
        emailRejected: "rejected",
        emailReturnedToQueue: "returned to queue",
        emailMarkedAsSpam: "marked as spam",
        emailSent: "sent",
        emailProcessed: "processed",
        requiredActions: "Required actions",
        endCall: "finish call",
        assignCustomer: "assign customer",
        closeEmail: "close e-mail",
        completeSurvey: "complete survey",
        endSession: "end session",
        replyPlaceholder: 'Type a message...',
        passwordsNotMatch: "Hasła nie pasują do siebie",
        changePassword: "Change password",
        passwordChangeRequired: "PASSWORD CHANGE REQUIRED",
        oldPassword: "Current password",
        changingPassword: "Changing password",
        passwordChanged: "Password changed",
        passwordChangeError: "Error while changing password",
        minimumPasswordLength: "Minimal password length",
        signs: "signs",
        passwordMustContain: "Password must contain",
        digitsAndLetters: "digits and letters",
        lowercaseAndUppercaseLetters: "lowercase and uppercase letters",
        tooManyUnsuccessfulLogin: "Too many login failures. Your account has been locked on some time.",
        clickToContactSearch: "Click to search for contact"
      },

      menu: {
        dashboard: "Dashboard",
        interactions: "Interactions",
        workflow: "Workflow",
        errands: "Errands",
        mailbox: "Mailbox",
        tasks: "Tasks",
        projects: "Projects",
        contactCenter: "Contact center",
        preview: "Preview",
        administration: "Administration",
        users: "Users",
        userDictionaries: "User dictionaries",
        systemDictionaries: "System dictionaries",
        permissions: "Roles",
        permissionsView: "Permissions view",
        userGroups: "Accounts groups",
        kb: "Knowledge base ",
        events: "Events",
        offer: "Offer",
        logistics: "Logistics",
        map: "Map",
        routes: "Routes",
        employees: "Employees",
        crews: "Crews",
        crm: "CRM",
        customers: "Customers",
        email: "Mailbox",
        myProfile: "My profile",
        repository: "Repository",
        report: "Report",
        systemTemplates: "System templates",
        emailTemplates: "E-Mail templates",
        emailAccounts: "E-mail accounts",
        messageTemplates: "Message templates",
        whisbearOrders: "Orders",
        resources: "Resources",
        statistics: "Statistics"
      },
      axRecordHistoryColumns: {
        Date: "Date",
        User: "User",
        SessionSubType: "Session type",
        Duration: "Duration",
        Result: "Results",
        IntermediateResult: "Intermediate results",
        CreateCause: "Create cause",
        CampaignRecordState: "Record state",
        InitiationResult: "Initation result",
        Dnis: "Dnis",
        Note: "Note",
        SerialSessionId: "Session Id",
        SerialConnectionId: "Connection Id",
        SerialScriptId: "Script Id"
      },
      axTerminateSessionReason: {
        0: "IVR",
        1: "Fax",
        2: "Unavailable",
        3: "Bad number",
        4: "Answering machine",
        5: "No answer",
        6: "Busy",
        7: "Aborted",
        8: "Error",
        9: "Drop"
      },
      axSessionSubtype: {
        0: "Inbound call",
        1: "Chat",
        3: "Email",
        4: "Social",
        5: "Workflow",
        10: "Predictive call",
        11: "Preview call",
        12: "Call back"
      },
      axCallEventCause: {
        0: 'None',
        1: 'ActiveParticipation',
        2: 'Alternate',
        3: 'Busy',
        4: 'CallBack',
        5: 'CallCancelled',
        6: 'CallForwardImmediate',
        7: 'CallForwardBusy',
        8: 'CallForwardNoAnswer',
        9: 'Redirection',
        10: 'No answer',
        11: 'CallPickup',
        12: 'CampOn',
        13: 'DestNotObtainable',
        14: 'DoNotDisturb',
        15: 'IncompatibleDestination',
        16: 'InvalidAccountCode',
        17: 'KeyOperation',
        18: 'Lockout',
        19: 'Maintenance',
        20: 'NetworkCongestion',
        21: 'NetworkNotObtainable',
        22: 'Inbound',
        23: 'NoAvailableAgents',
        24: 'Overrided',
        25: 'Park',
        26: 'Overflow',
        27: 'Recall',
        28: 'Redirected',
        29: 'ReorderTone',
        30: 'ResourcesNotAvailable',
        31: 'SilentParticipation',
        32: 'Transfer',
        33: 'TrunksBusy',
        35: 'Blocked',
        36: 'CharacterCountReached',
        37: 'Consultation',
        38: 'Distributed',
        39: 'DTMFDigitDetected',
        40: 'DurationExceeded',
        41: 'EndOfMessageDetected',
        42: 'EnteringDistribution',
        43: 'ForcedPause',
        44: 'Make call',
        45: 'MessageSizeExceeded',
        46: 'NetworkSignal',
        47: 'NextMessage',
        48: 'Completed',
        49: 'NoSpeechDetected',
        50: 'NumberChanged',
        51: 'Conference single step',
        52: 'SingleStepTransfer',
        53: 'SpeechDetected',
        54: 'Terminated',
        55: 'TerminationCharacterReceived',
        56: 'Timeout',
        57: 'ACDBusy',
        58: 'ACDForward',
        59: 'ACDSaturated',
        60: 'AlertTimeExpired',
        61: 'AutoWork',
        62: 'CampOnTrunks',
        63: 'Conference',
        64: 'DestDetected',
        65: 'DestOutOfOrder',
        66: 'DistributionDelay',
        67: 'ForcedTransition',
        68: 'Intrusion',
        69: 'Bad number',
        70: 'JoinCall',
        71: 'KeyOperationInUse',
        72: 'Predictive Call',
        73: 'MessageDurationExceeded',
        74: 'MultipleAlerting',
        75: 'MultipleQueuing',
        76: 'NetworkDialling',
        77: 'NetworkOutOfOrder',
        78: 'Normal',
        79: 'NotAvaliableBearerService',
        80: 'NotSupportedBearerService',
        81: 'Bad number',
        82: 'QueueCleared',
        83: 'RemainsInQueue',
        84: 'Reserved',
        85: 'SelectedTrunkBusy',
        86: 'Suspend',
        87: 'UnauthorisedBearerService',
        88: 'Preview Call',
        89: 'BaseCallDisconnected',
        90: 'MakePbxCall',
        91: 'MakePbxConsultation',
        92: 'Call directed'
      },
      axDataContactRecordState: {
        0: "Opened",
        1: "Shifted",
        10: "Closed",
        50: "In progress"
      },
      axSessionInitiationResult: {
        0: 'Connected',
        1: 'Connected no voice',
        2: 'Busy',
        3: 'No answer',
        4: 'Network error',
        5: 'No response from network',
        6: 'Unavailable',
        7: 'Voicemail',
        8: 'Answering machine',
        9: 'Fax',
        10: 'Internal line error',
        11: 'Number unassigned',
        12: 'Aborted by user',
        13: 'Bad number format',
        14: 'Network busy',
        15: 'No free channels',
        16: 'Aborted',
        17: 'Disconnected during detection',
        18: 'Error'
      },
      axCreateReason: {
        0: "New",
        1: "Transfer",
        2: "Shifted",
        3: "Shifted TCO"
      },

      axSessionState: {
        0: "Accepted",
        1: "Queued",
        2: "Directed to user",
        3: "Accepted by user",
        4: "Rejected by user",
        5: "Rejected by remote side",
        6: "Ended by remote side",
        7: "Ended by user",
        8: "Invalid destination",
        9: "Transferred",
        10: "Redirect error",
        11: "System terminated",
        12: "Waiting timeout",
        13: "Initiating",
        14: "Initiation failed",
        15: "Establishing with user",
        16: "Unable to establish with user",
        17: "Rejected",
        65535: "Not set"
      },
      axDataContactShiftReason: {
        0: "Błędna próba",
        1: "Użytkownik",
        2: "Drop",
        3: "Operacje na danych",
        4: "Błąd"
      },

      axDataQueryCondition: {
        0: "Equals",
        1: "Not equals",
        2: "Greater",
        3: "Lower",
        4: "Greater or equals",
        5: "Lower or equals",
        6: "Regular expression",
        7: "Not equals: regular expression",
        8: "In",
        9: "Not in",
        10: "Contains",
        11: "Not contains",
        12: "Between",
        13: "ArrayContains",
        14: "Null",
        15: "Not Null"
      },

      recordingButton: {
        recording: "Recording",
        recordingUser: "Recording user",
        stopped: "Stopped",
        paused: "Paused",
        stop: "Stop",
        recordBoth: "Record both sides",
        recordUser: "Record user side"
      },
      preview: {
        allCampaigns: "All"
      },
      soundSettings: {
        title: "Sound settings",
        newChatSound: "Chat started sound",
        newEmailSound: "Email received sound",
        newChatMessageSound: "Chat message sound",
        sessionEndSound: "Session end sound",
        routingSound: "Phone connecting sound"
      },
      statistics: {
        'STATISTICS.TILES.LOGGED': 'Logged (total)',
        'STATISTICS.TILES.AVAILABLE': 'Available (total)',
        'STATISTICS.TILES.IN_SESSION': 'In session (total)',
        'STATISTICS.TILES.PRODUCTIVE_BREAKS': 'Productive breaks',
        'STATISTICS.TILES.NONPRODUCTIVE_BREAKS': 'Unproductive breaks',
        'STATISTICS.TILES.WRAPUP': 'Wrapup (total)',
        'STATISTICS.TILES.INCOMING': 'Incoming calls',
        'STATISTICS.TILES.INCOMING_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.OUTGOING': 'Outgoing calls',
        'STATISTICS.TILES.OUTGOING_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.MANUAL': 'Manual',
        'STATISTICS.TILES.MANUAL_TOOLTIP': 'Connected/Initialized',
        'STATISTICS.TILES.PREVIEW': 'Calls preview',
        'STATISTICS.TILES.PREVIEW_TOOLTIP': 'Connected/Initialized',
        'STATISTICS.TILES.EMAIL': 'Email',
        'STATISTICS.TILES.EMAIL_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.CHAT': 'Chat',
        'STATISTICS.TILES.CHAT_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.SOCIAL': 'Social',
        'STATISTICS.TILES.SOCIAL_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.WORKFLOW': 'Workflow',
        'STATISTICS.TILES.WORKFLOW_TOOLTIP': 'Answered/Forwarded',
        'STATISTICS.COUNTERS.SERVICE_TIME': 'Service time',
        'STATISTICS.COUNTERS.SUBSTANTIVE_PRODUCTIVITY': 'Substantive productivity',
        'STATISTICS.COUNTERS.REAL_PRODUCTIVITY': 'Real productivity',
        'STATISTICS.COUNTERS.INBOUND_SUCCEED': 'Succeeded inbound calls',
        'STATISTICS.COUNTERS.INBOUND_TIME': 'Inbound calls session time',
        'STATISTICS.COUNTERS.INBOUND_AVG_TIME': 'Inbound calls average in session time',
        'STATISTICS.COUNTERS.INBOUND_SHIFTED': 'Shifted inbound calls',
        'STATISTICS.COUNTERS.OUTBOUND_SUCCEED': 'Succeeded outbound calls',
        'STATISTICS.COUNTERS.OUTBOUND_TIME': 'Outbound calls in session time',
        'STATISTICS.COUNTERS.OUTBOUND_AVG_TIME': 'Outbound calls average in session time',
        'STATISTICS.COUNTERS.OUTBOUND_SHIFTED': 'Shifted outbound calls',
        'STATISTICS.COUNTERS.PREVIEW_SUCCEED': 'Succeeded preview calls',
        'STATISTICS.COUNTERS.PREVIEW_TIME': 'Preview calls in sesion time',
        'STATISTICS.COUNTERS.PREVIEW_AVG_TIME': 'Preview calls avarage in session time',
        'STATISTICS.COUNTERS.PREVIEW_SHIFTED': 'Shifted preview calls',
        'STATISTICS.COUNTERS.MANUAL_SUCCEED': 'Succeeded manual calls',
        'STATISTICS.COUNTERS.MANUAL_TIME': 'Manual calls in session',
        'STATISTICS.COUNTERS.MANUAL_AVG_TIME': 'Manual calls average in session time',
        'STATISTICS.COUNTERS.MANUAL_SHIFTED': 'Shifted manual calls',
        'STATISTICS.CHARTS.STATES.TITLE': 'Statuses',
        'STATISTICS.CHARTS.STATES.SUBTITLE': 'Time in statuses',
        'STATISTICS.CHARTS.STATES.LABELS.AVAILABLE': 'Available',
        'STATISTICS.CHARTS.STATES.LABELS.IN_SESSION': 'In session',
        'STATISTICS.CHARTS.STATES.LABELS.WRAPUP': 'Wrapup',
        'STATISTICS.CHARTS.STATES.LABELS.BREAKS': 'Break',
        'STATISTICS.CHARTS.BREAKS.TITLE': 'Breaks',
        'STATISTICS.CHARTS.BREAKS.SUBTITLE': 'Outside and during work',
        'STATISTICS.CHARTS.BREAKS.LABELS.PRODUCTIVE': 'Productive breaks',
        'STATISTICS.CHARTS.BREAKS.LABELS.NON_PRODUCTIVE': 'Unproductive breaks',
        'STATISTICS.CHARTS.SESSIONS.TITLE': 'Sessions',
        'STATISTICS.CHARTS.SESSIONS.SUBTITLE': 'Session types',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CONNECTIONS': 'Calls',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CHAT': 'Chat',
        'STATISTICS.CHARTS.SESSIONS.LABELS.EMAIL': 'Email',
        'STATISTICS.CHARTS.SESSIONS.LABELS.SOCIAL': 'Social',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.TITLE': 'Accepted sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.SUBTITLE': 'Accepted and rejected sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.ACCEPTED': 'Accepted sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.REJECTED': 'Rejected',
        'STATISTICS.CHARTS.SUCCESSES.TITLE': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.SUBTITLE': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.SUCCESSES': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.ACCEPTED': 'Accepted sessions'
      }
    },

    cs: {

      pikaday: {
        previousMonth: 'Předchozi měsic',
        nextMonth: 'Dalši měsic',
        months: ['Leden', 'Unor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Zaři', 'Řijen', 'Listopad', 'Prosinec'],
        weekdays: ['Neděle', 'Pondeli', 'Utery', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
        weekdaysShort: ['Ned', 'Pon', 'Ut', 'St', 'Čt', 'Pát', 'Sob']
      },

      statuses: {
        0: "Odhlašeny",
        1: "Přihlašeny",
        2: "Dostupny",
        3: "Zabalit",
        4: "Pauza",
        5: "Nepřitomen",
        10: "V činnosti",
        11: "Suhrn",
        32767: "Neznamy"
      },
      messages: {
        add: "Přidej",
        clear: "Vyčisti",
        me: "Ja",
        loggedIn: "Přuhlašeny",
        loggingIn: "Přihlašovani",
        alreadyLogged: "Již přihlašeny",
        connecting: "Spojovaní",
        agentConnecting: "Spojovani s systemem",
        error: "Chyba",
        connected: "Spojeny",
        connectionError: "Problem s navazanim spojení",
        otherError: "Other error",
        connectionLost: "Přerušeni spojení s serverem",
        invalidCredentials: "Chybne jmeno uživatele",
        networkAccessRestricted: "Nemžeš se přihasit k tomu to počitači",
        licenceError: "Chyba licence",
        passwordExpired: "Twoje hasło wygasło",
        providerError: "Tvoje heslo vypršelo",
        pleasePickupTeleset: "Zvednete prosim sluchatko telefonu",
        serviceTemporaryUnavailable: "Servis castecne nedostupny",
        serviceUnavailable: "Nedostupny servis",
        stationAccessedByAnotherUser: "Stanice obsazeny jinym uživatelem",
        invalidStation: "Nespravna stanice",
        waitingBeforeReconnect: "Čekani na spojení",
        unknown: "Neznamy",
        videoError: "Chyba video",
        sent: "wlastni",
        notSent: "neposlany",
        beforeUnload: "Zmena stranky spusobi odhlašení",
        fieldIsRequired: "Vyžadovane poličko",
        blindTransfer: "Nespravny přesun",
        makeCall: "Zavolej",
        sendDtmf: "Pošli DTMF",
        disconnectCall: "Přeruš spojení",
        abort: "Přeruš",
        invalidFormat: "Nespravny format",
        validationError: "Chyba ověřovaní",
        answers: "Odpovědi",
        scriptValues: "Proměnne",
        result: "Vysledek",
        intermediateResults: "Nepřime vysledky",
        from: "Od",
        replyTo: "Odpověz do",
        mailCopy: "Kopie",
        mailBlindCopy: "Zkryta kopie",
        to: "Do",
        title: "Titul",
        message: "Zprava",
        sending: "Odesilani...",
        sendMessageFailed: "Chyba odesliani zpravy",
        sendMessageSuccess: "Zprava odeslana",
        summary: "Zhodnoceni",
        attachments: "Přilohy",
        addAttachment: "Přidej přilohu",
        save: "Ulož",
        cancel: "Zruš",
        edit: "Edituj",
        back: "Zpět",
        next: "Dal",
        break: "Přeruš",
        finish: "Ukončí",
        send: "Odesli",
        tries: "Pokusy",
        billingTries: "Zkoušky vyučtovaní",
        lastTryTime: "Posledni pokus",
        expectation: "Pravděpodobnost",
        closePhone: "Uzavři telefon",
        closePhoneMsg: "Určitě chceš uzavřit {0}({1})?",
        recordHistory: "Historie rekordu",
        newRecord: "Novy rekord",
        contactPhones: "Telefony",
        recordAborting: "Přerušeni",
        recordAbortingMsg: "Určitě chceš přerušit?",
        recordTerminatedSuccesfully: "Rekord byl přerušen",
        unableToTerminateRecord: "Nelze přerušit rekord",
        recordShiftedSuccesfully: "Rekord byl přesunuty",
        unableToShiftRecord: "Nelze presunout rekord",
        invalidRecordState: "Invalid record state",
        invalidRecord: "Invalid record or missing customer identification",
        recordShifting: "Přesun",
        recordShiftingMsg: "Určitě chceš přerušit?",
        shiftTime: "Čas přesunu",
        shiftReason: "Důvod přesunu",
        shiftPhone: "Telefony",
        automatic: "Automaticky",
        toMe: "Ke mně",
        campaign: "Kampaň",
        campaigns: "Kampaňe",
        users: "Uživatele",
        note: "Poznamka",
        shiftRecord: "Přesuň",
        select: "Zvol",
        recordState: "Stav rekordu",
        records: "Zaznamy",
        loginLocalisation: "Loklizace",
        loginUser: "Uživatel",
        loginPassword: "Heslo",
        logIn: "Přihlas se",
        logout: "Logout",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        loginIntoSystem: "PŘIHLASOVANI K SYSTEMU",
        interactions: "Interakce",
        crm: "Crm",
        preview: "Prewiew",
        fieldRequired: "Vyžadovane poličko",
        phoneNumber: "Telefonni čislo",
        inCampaignContent: "V ramci kampaňe",
        useOwnPresentationNumber: "Použij prezentaci vlastniho čisla",
        dial: "Spojení",
        transfer: "Přesun",
        id: "Id",
        thingName: "Nazev",
        availables: "Dostupni",
        loggedIns: "Přihlašení",
        queuedSessions: "Interakce ve frontě",
        avgWaitingTime: "Průmerny čas očekavaní",
        state: "Stav rekordu",
        stateTime: "Čas stavu",
        callEnded: "Ukončene spojení",
        predictiveCall: "Spojeni predictive",
        inboundCall: "Spojeni inbound",
        queueWaitingTime: "Čas čekani ve frontě",
        chat: "Chat",
        incomingCall: "Spojení",
        autoNavigateToInteractions: "Automaticky přesun k interakci",
        fetchRecords: "Vyzvedni zaznam",
        releaseRecords: "Uvolni zaznam",
        details: "Detajly",
        contactHistory: "Historie kontaktu",
        waitingForClientVideo: "Očekavani na video klienta",
        files: "Soubory",
        availableFiles: "Dostupne soubory",
        receivedFiles: "Obdržene soubory",
        hints: "Napovědy",
        noActiveSessions: "Nemaš aktivni sese",
        language: "Jazyk",
        customer: "Klient",
        get: "Přesuň",
        insert: "Vlož",
        gettingMenervaHint: "Vyzvedavam napovědy",
        errorGettingMenervaHint: "Chyva vyzvedavani napovědy",
        noHintsAvailable: "Chybi napovědy",
        holdUnhold: "Odmitnouti/podržení",
        holdDisabled: "Podrženi neni možne",
        dialpad: "Vytoč čislo",
        sendSMS: "Send SMS",
        pickup: "Zvední",
        mute: "Vypni mkrofon",
        transferDisabled: "Transfer nedostupny",
        muteDisabled: "Vypnuti mikrofonu nedostupne",
        sessions: "Sese",
        value: "hodnota",
        isGreaterThan: "je větší něž",
        isLowerThan: "je menši něž",
        maximum: "Maximalni",
        minimum: "Minimalni",
        emailCheckingInProgress: "Probiha kontrola emailove adresy",
        domainNotExists: "Domena neegziistuje",
        setState: "Nastav status",
        statusSetAfterSession: "Status po ukončeni sese",
        errorSettingStatus: "chyba nastaveni statusu",
        setStage: "Nastav etapu",
        stageSetTo: "Nastavena uroveňv",
        setStageError: "Chyba nastavení úrovně",
        badFormat: "Chybny format",
        and: "a",
        check: "zkontroluj",
        time: "čas",
        dateSurveyFieldError: "dat",
        enteredDate: "Nastavene datum",
        isEarlierThan: "je dřivějši něž",
        isLaterThan: "po pozdejši něž",
        acceptedRange: "Povolený rozsah",
        acceptedDateTimeRange: "Povoleny čas",
        anyTimeRange: "povoleny",
        infinity: "nekonečny",
        inifinity1: "nekonečny",
        download: "Vyzvedni",
        fileShared: "Zpřistupněny soubor",
        clientSendsFile: "Client sends file",
        doubleClickToShare: "Pro zpřistupneni klikni dva krat",
        urlAddress: "Adresa URL",
        nameAndSurname: "Jmeno a přijmení",
        email: "Email",
        unreadMessages: "Unread messages",
        toggleMenu: "Toggle menu",
        selectCustomer: "Select customer",
        inCall: "Incoming call",
        accept: "Accept",
        notExists: "not exists",
        saveToRepository: "Save to repository",
        system: "System",
        clientEndedChat: "Client ended chat",
        voiceCall: "Voice call",
        videoCall: "Video call",
        voiceCallEnded: "Voice call ended",
        videoCallEnded: "Video call ended",
        doubleClickToCopy: "Double click to copy to clipboard",
        newChatSession: "New chat session",
        newSessions: "New sessions",
        unreadChatMessages: "Unread chat messages",
        voice: "Voice",
        closeChatSessionMsg: "Are you sure?",
        video: "Video",
        stationNotAssigned: "Phone not assigned",
        stationInitializeError: "Error during phone initialization",
        unsupportedStationType: "Unsupported phone type",
        initializingPhone: "Initializing phone",
        stationDisconnected: "Phone disconnected",
        survey: "Survey",
        emailRemoved: "deleted",
        emailRejected: "rejected",
        emailReturnedToQueue: "returned to queue",
        emailMarkedAsSpam: "marked as spam",
        emailSent: "sent",
        emailProcessed: "processed",
        requiredActions: "Required actions",
        endCall: "finish call",
        assignCustomer: "assign customer",
        closeEmail: "close e-mail",
        completeSurvey: "complete survey",
        endSession: "end session",
        replyPlaceholder: "Type a message...",
        passwordsNotMatch: "Passwords not match",
        changePassword: "Change password",
        passwordChangeRequired: "PASSWORD CHANGE REQUIRED",
        oldPassword: "Current password",
        changingPassword: "Changing password",
        passwordChanged: "Password changed",
        passwordChangeError: "Error while changing password",
        minimumPasswordLength: "Minimal password length",
        signs: "signs",
        passwordMustContain: "Password must contain",
        digitsAndLetters: "digits and letters",
        lowercaseAndUppercaseLetters: "lowercase and uppercase letters",
        tooManyUnsuccessfulLogin: "Too many login failures. Your account has been locked on some time.",
        clickToContactSearch: "Click to search for contact"
      },

      menu: {
        dashboard: "Plocha",
        interactions: "Interakce",
        workflow: "Workflow",
        errands: "Zakazky",
        mailbox: "Poštovní schranka",
        tasks: "Úkoly",
        projects: "Projekty",
        contactCenter: "Contact center",
        preview: "Preview",
        administration: "Administrace",
        users: "Úživatele",
        userDictionaries: "Úživatelske slovniky",
        systemDictionaries: "Systemove slovniky",
        permissions: "Role",
        permissionsView: "Opravnení",
        userGroups: "Skupiny úživatelu",
        kb: "Znalostni databaze",
        events: "Údalostí",
        offer: "Nabidky",
        logistics: "Logistika",
        map: "Mapa",
        routes: "Traťě",
        employees: "Zaměstnanci",
        crews: "Týmy",
        crm: "CRM",
        customers: "Klienti",
        email: "Pošta",
        myProfile: "Můj profil",
        repository: "Uložiště",
        report: "Report",
        systemTemplates: "Systemove šablony",
        emailTemplates: "Emailove šablony",
        emailAccounts: "Emailove účty",
        messageTemplates: "Šablony zprav",
        whisbearOrders: "Objednavky",
        resources: "Zasoby",
        statistics: "Statistika"
      },
      axRecordHistoryColumns: {
        Date: "Datum",
        User: "Úživatel",
        SessionSubType: "Tip sese",
        Duration: "Doba trvaní",
        Result: "Vysledek",
        IntermediateResult: "Prostřední vysledek",
        CreateCause: "Důvod vytvořeni",
        CampaignRecordState: "Stav rekordu",
        InitiationResult: "Vysledek inicjace",
        Dnis: "Dnis",
        Note: "Poznamka",
        SerialSessionId: "ID sese",
        SerialConnectionId: "ID spojeni",
        SerialScriptId: "ID sese skriptu"
      },
      axTerminateSessionReason: {
        0: "Automat",
        1: "Fax",
        2: "Nedostupny",
        3: "Chybne čislo",
        4: "Hlasova pošta",
        5: "Nepřijate",
        6: "Obsazeno",
        7: "Položeno",
        8: "Chyba",
        9: "Drop"
      },
      axSessionSubtype: {
        0: "Spojeni inbound",
        1: "Chat",
        3: "Email",
        4: "Socialni",
        5: "Workflow",
        10: "Spojeni predictive",
        11: "Spojeni preview",
        12: "Zpetne volani"
      },
      axCallEventCause: {
        0: 'Ne',
        1: 'Aktivni učast',
        2: 'Alternativni',
        3: 'Obsazeny',
        4: 'Volat zpět',
        5: 'Volani zrušeno',
        6: 'Přidat Volani vpřed',
        7: 'Volani obsazeneho cisla',
        8: 'Volani nezvadajicimu',
        9: 'Volani presmerovane',
        10: 'Chybi odpověd',
        11: 'Zvednuti hovoru',
        12: 'CampOn',
        13: 'Nedostupny',
        14: 'Nerušit',
        15: 'Nedostupna destinace',
        16: 'Neplatny profix',
        17: 'Klic operace',
        18: 'Uzamceni',
        19: 'Servis',
        20: 'Sit pretizena',
        21: 'Sit nepretizena',
        22: 'Prichozi',
        23: 'Nedostupni agenti',
        24: 'Overrided',
        25: 'Park',
        26: 'Overflow',
        27: 'Recall',
        28: 'Předaní',
        29: 'ReorderTone',
        30: 'ResourcesNotAvailable',
        31: 'SilentParticipation',
        32: 'Transfer',
        33: 'TrunksBusy',
        35: 'Blocked',
        36: 'CharacterCountReached',
        37: 'Konsultacja',
        38: 'Distributed',
        39: 'DTMFDigitDetected',
        40: 'DurationExceeded',
        41: 'EndOfMessageDetected',
        42: 'EnteringDistribution',
        43: 'ForcedPause',
        44: 'Make call',
        45: 'MessageSizeExceeded',
        46: 'NetworkSignal',
        47: 'NextMessage',
        48: 'Zakończone',
        49: 'NoSpeechDetected',
        50: 'NumberChanged',
        51: 'Konferencja single step',
        52: 'SingleStepTransfer',
        53: 'SpeechDetected',
        54: 'Rezygnacja',
        55: 'TerminationCharacterReceived',
        56: 'Timeout',
        57: 'ACD obsazeny',
        58: 'ACDForward',
        59: 'ACDSaturated',
        60: 'AlertTimeExpired',
        61: 'AutoWork',
        62: 'CampOnTrunks',
        63: 'Konference',
        64: 'DestDetected',
        65: 'DestOutOfOrder',
        66: 'DistributionDelay',
        67: 'ForcedTransition',
        68: 'Vkročení',
        69: 'Chybne čislo',
        70: 'JoinCall',
        71: 'KeyOperationInUse',
        72: 'Predictive Call',
        73: 'MessageDurationExceeded',
        74: 'MultipleAlerting',
        75: 'MultipleQueuing',
        76: 'NetworkDialling',
        77: 'NetworkOutOfOrder',
        78: 'Ukonceni',
        79: 'NotAvaliableBearerService',
        80: 'NotSupportedBearerService',
        81: 'Błędny numer',
        82: 'QueueCleared',
        83: 'RemainsInQueue',
        84: 'Reserved',
        85: 'SelectedTrunkBusy',
        86: 'Suspend',
        87: 'UnauthorisedBearerService',
        88: 'Preview Call',
        89: 'BaseCallDisconnected',
        90: 'Volej pres PBX',
        91: 'Konzultace s PBX',
        92: 'Predni spojeni'
      },
      axDataContactRecordState: {
        0: "Otevřeny",
        1: "Přechozeny",
        10: "Uzavřeny",
        50: "V prubehu"
      },
      axSessionInitiationResult: {
        0: 'Spojeny',
        1: 'Spojeni - chybi hlas',
        2: 'Obsazeny',
        3: 'Chybi odpověd',
        4: 'Chyba sitě',
        5: 'Chybi odpověd ze titě',
        6: 'Nedostupny',
        7: 'Hlasova pošta',
        8: 'Automaticka sekretařka',
        9: 'Fax',
        10: 'Chyby vnitřni linky',
        11: 'Číslo není přiřazen',
        12: 'Přerušeni uživarelem',
        13: 'Čislo ve špatnem formatu',
        14: 'Obsazena sit',
        15: 'Chybi volne kanaly',
        16: 'Nepřijma',
        17: 'Rozpojeni při odhalovani hlasu',
        18: 'Chyba'
      },
      axCreateReason: {
        0: "Nova",
        1: "Transfer",
        2: "Přesun",
        3: "Přesun TCO"
      },
      axSessionState: {
        0: "Akceptovana",
        1: "Ve frontě",
        2: "Přesun k uživateli",
        3: "Přijem uživatelem",
        4: "Přerušni uživatelem",
        5: "Přerušeni volnou osobou",
        6: "Ukončení volanou osobou",
        7: "Ukončeni uživatelem",
        8: "Invalid destination",
        9: "Přesměrovan",
        10: "Chyba přesměrovani",
        11: "Přerušeni systemem",
        12: "Čas čekani",
        13: "Inicjace",
        14: "Spojeni se nepovedlo",
        15: "Stanovene uživatelem",
        16: "Nedaři se navazat spojeni s uživatelem",
        17: "Nepřijaty",
        65535: "Nenastaveny"
      },
      axDataContactShiftReason: {
        0: "Chybny pokus",
        1: "Úživatel",
        2: "Drop",
        3: "Operace na datech",
        4: "Chyba"
      },
      axDataQueryCondition: {
        0: "Se rovna",
        1: "Ne rovna se",
        2: "Větši něž",
        3: "Menši něž",
        4: "Větší/rovny",
        5: "Menši/rovny",
        6: "Pravidelna definice",
        7: "Ruzny: pravidelna definice",
        8: "Je",
        9: "Neni",
        10: "Obsahuje",
        11: "Neobsahuje",
        12: "Mezi",
        13: "Obsahuje",
        14: "Nula",
        15: "Jine něž nula"
      },
      recordingButton: {
        recording: "Nahravaní",
        recordingUser: "Nahravani úživatele",
        stopped: "Zastaveni",
        paused: "Pauza",
        stop: "Zastav",
        recordBoth: "Nahravej obě strany",
        recordUser: "Nahravej úživatele"
      },

      preview: {
        allCampaigns: "Všechny"
      },
      soundSettings: {
        title: "Sound settings",
        newChatSound: "Chat started sound",
        newEmailSound: "Email received sound",
        newChatMessageSound: "Chat message sound",
        sessionEndSound: "Session end sound",
        routingSound: "Phone connecting sound"
      },
      statistics: {
        'STATISTICS.TILES.LOGGED': 'Logged (total)',
        'STATISTICS.TILES.AVAILABLE': 'Available (total)',
        'STATISTICS.TILES.IN_SESSION': 'In session (total)',
        'STATISTICS.TILES.PRODUCTIVE_BREAKS': 'Productive breaks',
        'STATISTICS.TILES.NONPRODUCTIVE_BREAKS': 'Unproductive breaks',
        'STATISTICS.TILES.WRAPUP': 'Wrapup (total)',
        'STATISTICS.TILES.INCOMING': 'Incoming calls',
        'STATISTICS.TILES.INCOMING_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.OUTGOING': 'Outgoing calls',
        'STATISTICS.TILES.OUTGOING_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.MANUAL': 'Manual',
        'STATISTICS.TILES.MANUAL_TOOLTIP': 'Connected/Initialized',
        'STATISTICS.TILES.PREVIEW': 'Calls preview',
        'STATISTICS.TILES.PREVIEW_TOOLTIP': 'Connected/Initialized',
        'STATISTICS.TILES.EMAIL': 'Email',
        'STATISTICS.TILES.EMAIL_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.CHAT': 'Chat',
        'STATISTICS.TILES.CHAT_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.SOCIAL': 'Social',
        'STATISTICS.TILES.SOCIAL_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.WORKFLOW': 'Workflow',
        'STATISTICS.TILES.WORKFLOW_TOOLTIP': 'Answered/Forwarded',
        'STATISTICS.COUNTERS.SERVICE_TIME': 'Service time',
        'STATISTICS.COUNTERS.SUBSTANTIVE_PRODUCTIVITY': 'Substantive productivity',
        'STATISTICS.COUNTERS.REAL_PRODUCTIVITY': 'Real productivity',
        'STATISTICS.COUNTERS.INBOUND_SUCCEED': 'Succeeded inbound calls',
        'STATISTICS.COUNTERS.INBOUND_TIME': 'Inbound calls session time',
        'STATISTICS.COUNTERS.INBOUND_AVG_TIME': 'Inbound calls average in session time',
        'STATISTICS.COUNTERS.INBOUND_SHIFTED': 'Shifted inbound calls',
        'STATISTICS.COUNTERS.OUTBOUND_SUCCEED': 'Succeeded outbound calls',
        'STATISTICS.COUNTERS.OUTBOUND_TIME': 'Outbound calls in session time',
        'STATISTICS.COUNTERS.OUTBOUND_AVG_TIME': 'Outbound calls average in session time',
        'STATISTICS.COUNTERS.OUTBOUND_SHIFTED': 'Shifted outbound calls',
        'STATISTICS.COUNTERS.PREVIEW_SUCCEED': 'Succeeded preview calls',
        'STATISTICS.COUNTERS.PREVIEW_TIME': 'Preview calls in sesion time',
        'STATISTICS.COUNTERS.PREVIEW_AVG_TIME': 'Preview calls avarage in session time',
        'STATISTICS.COUNTERS.PREVIEW_SHIFTED': 'Shifted preview calls',
        'STATISTICS.COUNTERS.MANUAL_SUCCEED': 'Succeeded manual calls',
        'STATISTICS.COUNTERS.MANUAL_TIME': 'Manual calls in session',
        'STATISTICS.COUNTERS.MANUAL_AVG_TIME': 'Manual calls average in session time',
        'STATISTICS.COUNTERS.MANUAL_SHIFTED': 'Shifted manual calls',
        'STATISTICS.CHARTS.STATES.TITLE': 'Statuses',
        'STATISTICS.CHARTS.STATES.SUBTITLE': 'Time in statuses',
        'STATISTICS.CHARTS.STATES.LABELS.AVAILABLE': 'Available',
        'STATISTICS.CHARTS.STATES.LABELS.IN_SESSION': 'In session',
        'STATISTICS.CHARTS.STATES.LABELS.WRAPUP': 'Wrapup',
        'STATISTICS.CHARTS.STATES.LABELS.BREAKS': 'Break',
        'STATISTICS.CHARTS.BREAKS.TITLE': 'Breaks',
        'STATISTICS.CHARTS.BREAKS.SUBTITLE': 'Outside and during work',
        'STATISTICS.CHARTS.BREAKS.LABELS.PRODUCTIVE': 'Productive breaks',
        'STATISTICS.CHARTS.BREAKS.LABELS.NON_PRODUCTIVE': 'Unproductive breaks',
        'STATISTICS.CHARTS.SESSIONS.TITLE': 'Sessions',
        'STATISTICS.CHARTS.SESSIONS.SUBTITLE': 'Session types',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CONNECTIONS': 'Calls',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CHAT': 'Chat',
        'STATISTICS.CHARTS.SESSIONS.LABELS.EMAIL': 'Email',
        'STATISTICS.CHARTS.SESSIONS.LABELS.SOCIAL': 'Social',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.TITLE': 'Accepted sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.SUBTITLE': 'Accepted and rejected sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.ACCEPTED': 'Accepted sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.REJECTED': 'Rejected',
        'STATISTICS.CHARTS.SUCCESSES.TITLE': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.SUBTITLE': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.SUCCESSES': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.ACCEPTED': 'Accepted sessions'
      }
    },

    ro: {
      pikaday: {
        previousMonth: 'Luna precedenta',
        nextMonth: 'Luna viitoare',
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        weekdays: ["Luni", "Marti", "Miercuri", "Joi", "Vineri", "Sambata", "Duminica"],
        weekdaysShort: ["Lu", "Ma", "Mie", "Jo", "Vi", "Sa", "Du"]
      },
      statuses: {
        0: "Nu este autentificat",
        1: "Autentificat",
        2: "Disponibil",
        3: "Wrapup",
        4: "Pauză",
        5: "Absent",
        10: "În cadrul sesiunii",
        11: "Compilare",
        32767: "Necunoscut"
      },
      messages: {
        add: "Adauga",
        clear: "Sterge",
        me: "Eu",
        loggedIn: "Autentificat",
        loggingIn: "Autentificare",
        alreadyLogged: "Deja autentificat",
        connecting: "Conectare",
        agentConnecting: "Conectare agent",
        error: "Eroare",
        connected: "Conectat",
        connectionError: "Eroare de conectare",
        otherError: "Other error",
        connectionLost: "Conexiune pierduta",
        invalidCredentials: "Acreditari nevalide",
        networkAccessRestricted: "Acces la retea restrictionat",
        licenceError: "Eroare licenta",
        passwordExpired: "Parola expirata",
        providerError: "Eroare de furnizor",
        pleasePickupTeleset: "Te rugam sa alegi Teleset-ul",
        serviceTemporaryUnavailable: "Serviciu temporar indisponibil",
        serviceUnavailable: "Serviciu indisponibil",
        stationAccessedByAnotherUser: "Statie accesata de un alt utilizator",
        invalidStation: "Statie nevalida",
        waitingBeforeReconnect: "Asteapta inainte sa te reconectezi",
        unknown: "Necunoscut",
        videoError: "Eroare video",
        sent: "Trimis",
        notSent: "Netrimis",
        beforeUnload: "Inainte de descarcare",
        fieldIsRequired: "Campul este obligatoriu",
        blindTransfer: "Transfer orb",
        makeCall: "Apeleaza",
        sendDtmf: "Trimite DTMF",
        disconnectCall: "Deconecteaza apel",
        abort: "Esuat",
        invalidFormat: "Format invalid",
        validationError: "Eroare de validare",
        answers: "Raspunsuri",
        scriptValues: "Valori de script",
        result: "Rezultat",
        intermediateResults: "Rezultate intermediare",
        from: "De la",
        replyTo: "Raspunde la",
        mailCopy: "Copie mail",
        mailBlindCopy: "Copie oarba de mail",
        to: "Catre",
        title: "Titlu",
        message: "Mesaj",
        sending: "Trimitere...",
        sendMessageFailed: "Trimiterea mesajului a esuat",
        sendMessageSuccess: "Trimitere mesaj cu succes",
        summary: "Rezumat",
        attachments: "Atasamente",
        addAttachment: "Adauga atasamanet",
        save: "Salveaza",
        cancel: "Anuleaza",
        edit: "Editeaza",
        back: "Inapoi",
        next: "Urmatorul",
        break: "Pauza",
        finish: "Final",
        send: "Trimis",
        tries: "Incercari",
        billingTries: "Incercari facturare",
        lastTryTime: "Ultimult timp de incercare",
        expectation: "Asteptari",
        closePhone: "Telefon inchis",
        closePhoneMsg: "Mesaj telefon inchis",
        recordHistory: "Inregistreaza istoricul",
        newRecord: "Inregistrare noua",
        contactPhones: "Contacteaza telefoane",
        recordAborting: "Inregistreaza esecul",
        recordAbortingMsg: "Inregistreaza mesajul de esec",
        recordTerminatedSuccesfully: "Inregistreaza incheiat cu succes",
        unableToTerminateRecord: "Incapabil sa incheie inregistrarea",
        recordShiftedSuccesfully: "Inregistrarea a fost schimbata cu succes",
        unableToShiftRecord: "Incapabil sa schimbe inregistrarea",
        invalidRecordState: "Invalid record state",
        invalidRecord: "Invalid record or missing customer identification",
        recordShifting: "Inregistreaza schimbarea",
        recordShiftingMsg: "Inregistreaza mesajul de schimb",
        shiftTime: "Timpul de schimbare",
        shiftReason: "Motivul de schimbare",
        shiftPhone: "Telefon schimbat",
        automatic: "Automat",
        toMe: "Catre mine",
        campaign: "Campanie",
        campaigns: "Campanii",
        users: "Utilizatori",
        note: "Nota",
        shiftRecord: "Schimba inregistrarea",
        select: "Selecteaza",
        recordState: "Inregistreaza statul",
        records: "Inregistrari",
        loginLocalisation: "Localizare autentificare",
        loginUser: "Autentificare utilizator",
        loginPassword: "Parola autentificare",
        logIn: "Autentificare",
        logout: "Logout",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        loginIntoSystem: "Autentificare in sistem",
        interactions: "Interactiuni",
        crm: "CRM",
        preview: "Previzualizare",
        fieldRequired: "Camp obligatoriu",
        phoneNumber: "Numar de telefon",
        inCampaignContent: "Continut din campanie",
        useOwnPresentationNumber: "Foloseste numarul de prezentare propriu",
        dial: "Apeleaza",
        transfer: "Transfer",
        id: "Id",
        thingName: "Nume de lucru",
        availables: "Disponibile",
        loggedIns: "Autentificate",
        queuedSessions: "Sesiuni in asteptare",
        avgWaitingTime: "Timp mediu de asteptare",
        state: "Stat",
        stateTime: "Ora statului",
        callEnded: "Apel incheiat",
        predictiveCall: "Apel predictiv",
        inboundCall: "Apel in asteptare",
        queueWaitingTime: "Timp de asteptare",
        chat: "Conversatie",
        incomingCall: "Apel intrare",
        autoNavigateToInteractions: "Navigare automata in interactiuni",
        fetchRecords: "Inregistrari preluate",
        releaseRecords: "Inregistrari eliberate",
        details: "Detalii",
        contactHistory: "Istoric contacte",
        waitingForClientVideo: "Asteptare pentru videoclipul clientului",
        files: "Fisiere",
        availableFiles: "Fisiere disponibile",
        receivedFiles: "Fisiere primite",
        hints: "Sugestii",
        noActiveSessions: "Sesiuni inactive",
        language: "Limba",
        customer: "Client",
        get: "Primeste",
        insert: "Introduce",
        gettingMenervaHint: "Obtine indicatia Menerva",
        errorGettingMenervaHint: "Eroare la obtinerea indicatiei Menerva",
        noHintsAvailable: "Nici o sugestie disponibila",
        holdUnhold: "Tineti mentinerea",
        holdDisabled: "Mentinere dezactivata",
        dialpad: "Tastatura",
        sendSMS: "Send SMS",
        pickup: "Ridica",
        mute: "Silentios",
        transferDisabled: "Transfer dezactivat",
        muteDisabled: "Mod silentios dezactivat",
        sessions: "Sesiuni",
        value: "Valoare",
        isGreaterThan: "Este mai mare deca",
        isLowerThan: "Este mai mica decat",
        maximum: "Maxim",
        minimum: "Minim",
        emailCheckingInProgress: "Verificare e-mail in desfasurare",
        domainNotExists: "Domeniul nu exista",
        setState: "Seteaza stare",
        statusSetAfterSession: "Stare setata dupa sesiune",
        errorSettingStatus: "Status eroare setari",
        setStage: "Seteaza etapa",
        stageSetTo: "Etapa setata la",
        setStageError: "Seteaza etapa erorii",
        badFormat: "Format rau",
        and: "si",
        check: "verifica",
        time: "ora",
        dateSurveyFieldError: "Eroare date de verificare a campului",
        enteredDate: "Data inserata",
        isEarlierThan: "Este mai devreme decat",
        isLaterThan: "Este mai tarziu decat",
        acceptedRange: "Domeniu permis",
        acceptedDateTimeRange: "Interval de timp acceptat",
        anyTimeRange: "orice interval de timp",
        infinity: "Infinit",
        inifinity1: "Infinit 1",
        download: "Descarcare",
        fileShared: "Fisier partajat",
        clientSendsFile: "Client sends file",
        doubleClickToShare: "Dublu click pentru a partaja",
        urlAddress: "Adresa URL",
        nameAndSurname: "Nume si prenume",
        email: "E-mail",
        unreadMessages: "Unread messages",
        toggleMenu: "Toggle menu",
        selectCustomer: "Select customer",
        inCall: "Incoming call",
        accept: "Accept",
        notExists: "not exists",
        saveToRepository: "Save to repository",
        system: "System",
        clientEndedChat: "Client ended chat",
        voiceCall: "Voice call",
        videoCall: "Video call",
        voiceCallEnded: "Voice call ended",
        videoCallEnded: "Video call ended",
        doubleClickToCopy: "Double click to copy to clipboard",
        newChatSession: "New chat session",
        newSessions: "New sessions",
        unreadChatMessages: "Unread chat messages",
        voice: "Voice",
        closeChatSessionMsg: "Are you sure?",
        video: "Video",
        stationNotAssigned: "Phone not assigned",
        stationInitializeError: "Error during phone initialization",
        unsupportedStationType: "Unsupported phone type",
        initializingPhone: "Initializing phone",
        stationDisconnected: "Phone disconnected",
        survey: "Survey",
        emailRemoved: "deleted",
        emailRejected: "rejected",
        emailReturnedToQueue: "returned to queue",
        emailMarkedAsSpam: "marked as spam",
        emailSent: "sent",
        emailProcessed: "processed",
        requiredActions: "Required actions",
        endCall: "finish call",
        assignCustomer: "assign customer",
        closeEmail: "close e-mail",
        completeSurvey: "complete survey",
        endSession: "end session",
        replyPlaceholder: "Type a message...",
        passwordsNotMatch: "Passwords not match",
        changePassword: "Change password",
        passwordChangeRequired: "PASSWORD CHANGE REQUIRED",
        oldPassword: "Current password",
        changingPassword: "Changing password",
        passwordChanged: "Password changed",
        passwordChangeError: "Error while changing password",
        minimumPasswordLength: "Minimal password length",
        signs: "signs",
        passwordMustContain: "Password must contain",
        digitsAndLetters: "digits and letters",
        lowercaseAndUppercaseLetters: "lowercase and uppercase letters",
        tooManyUnsuccessfulLogin: "Too many login failures. Your account has been locked on some time.",
        clickToContactSearch: "Click to search for contact"
      },
      menu: {
        dashboard: "Tablou de bord",
        interactions: "Interactiuni",
        workflow: "Flux de lucru",
        errands: "Comision",
        mailbox: "casuta postala",
        tasks: "Sarcini",
        projects: "Proiecte",
        contactCenter: "Centru de contact",
        preview: "Previzualizare",
        administration: "Administrare",
        users: "Utilizatori",
        userDictionaries: "Dictionarul utilizatorului",
        systemDictionaries: "Dictionarul sistemului",
        permissions: "Permisiuni",
        permissionsView: "Vizualizare permisiuni",
        userGroups: "Grup utilizatori",
        kb: "Kb",
        events: "evenimente",
        offer: "Oferta",
        logistics: "Logistica",
        map: "Harta",
        routes: "Ruta",
        employees: "Angajati",
        crews: "Echipe",
        crm: "CRM",
        customers: "Clienti",
        email: "E-mail",
        myProfile: "Profilul meu",
        repository: "Repertoriu",
        report: "Raport",
        systemTemplates: "Sabloane de sistem",
        emailTemplates: "Sabloane de e-mail",
        emailAccounts: "Cont de e-mail",
        messageTemplates: "Sabloane de mesaj",
        whisbearOrders: "Orice ordin",
        resources: "Resurse",
        statistics: "statistică"
      },
      axRecordHistoryColumns: {
        Date: "Data",
        User: "Utilizator",
        SessionSubType: "Subtipul sesiunii",
        Duration: "Durata",
        Result: "Rezultat",
        IntermediateResult: "Rezultat intermediar",
        CreateCause: "Creaza cauza",
        CampaignRecordState: "Starea inregistrarii campaniei",
        InitiationResult: "Rezultat initierii",
        Dnis: "DNIS",
        Note: "Nota",
        SerialSessionId: "Sesiune ID serial",
        SerialConnectionId: "Sesiune ID conectare",
        SerialScriptId: "Sesiune ID script"
      },
      axTerminateSessionReason: {
        0: "IVR",
        1: "Fax",
        2: "Unavailable",
        3: "Bad number",
        4: "Answering machine",
        5: "No answer",
        6: "Busy",
        7: "Aborted",
        8: "Error",
        9: "Drop"
      },
      axSessionSubtype: {
        0: "Inbound call",
        1: "Chat",
        3: "Email",
        4: "Social",
        5: "Flux de lucru",
        10: "Predictive call",
        11: "Preview call",
        12: "Call back"
      },
      axCallEventCause: {
        0: "Nici unul",
        1: "Participare activa",
        2: "Alterna",
        3: 'Busy',
        4: "Suna inapoi",
        5: "Apel anulat",
        6: "Redirectionare imediata",
        7: "Apel in asteptare ocupat",
        8: "Redirectionati apelul fara raspuns",
        9: 'Redirection',
        10: 'No answer',
        11: "Apel de preluare",
        12: 'CampOn',
        13: 'DestNotObtainable',
        14: "Nu deranja",
        15: "Destinatie incompatibila",
        16: "Cod cont invalid",
        17: "Operatiune cheie",
        18: "Deconectare",
        19: "Intretinere",
        20: "Rețeaua de congestie",
        21: "Rețeaua nu poate fi obținută",
        22: "Intrare",
        23: "Nici un agent disponibil",
        24: "Suprascrisa",
        25: "A stationa",
        26: "Revarsare",
        27: "Rechemare",
        28: 'Redirected',
        29: "Reordonati tonul",
        30: "Resurse indisponibile",
        31: "Participare silentioasa",
        32: "Transfer",
        33: "Trucuri ocupate",
        35: "Blocat",
        36: "Numar de caractere atins",
        37: 'Konsultacja',
        38: "Distribuit",
        39: "Digit DTMF detectat",
        40: "Durata depasita",
        41: "Sfarsitul mesajului detectat",
        42: "Introducerea distributiei",
        43: "Pauza fortata",
        44: "Apeleaza",
        45: "Dimensiunea mesajului a fost depășită",
        46: "Semnal retea",
        47: "Urmatorul mesaj",
        48: 'Completed',
        49: "Nu a fost detectată nici o vorbire",
        50: "Numar schimbat",
        51: 'Konferencja single step',
        52: "Transferul cu un singur pas",
        53: "Vorbire detectata",
        54: 'Rezygnacja',
        55: "Caracterul de terminare primit",
        56: "Pauza",
        57: "ACD ocupat",
        58: "ACD inainte",
        59: "ACD saturat",
        60: "Alerta timp expirat",
        61: "Munca automata",
        62: "CampOnTrunks",
        63: "Conference",
        64: "Destinatie detectata",
        65: "Destinatie necorespunzatoare",
        66: "Intarziere distributie",
        67: "Tranzitie fortata",
        68: 'Intrusion',
        69: 'Bad number',
        70: "Alaturate apelului",
        71: "Operatiune cheie in utilizare",
        72: "Apel predictiv",
        73: "Durata mesaj depasita",
        74: "Alerta multipla",
        75: "Reglare multipla",
        76: "Apelare prin retea",
        77: "Retea in afara utilizarii",
        78: "Normal",
        79: "Serviciu purtator indisponibil",
        80: "Serviciu purtator nesuportat",
        81: "Bad number",
        82: "Asteptare curatata",
        83: "Ramane in asteptare",
        84: "Rezervat",
        85: "Parte selectata ocupata",
        86: "Suspendat",
        87: "Serviciu purtator neautorizat",
        88: "Apel previzualizat",
        89: "Baza apel deconectata",
        90: "Apeleaza PBX",
        91: "Consultanta PBX",
        92: "Call directed"
      },
      axDataContactRecordState: {
        0: "Opened",
        1: "Shifted",
        10: "Closed",
        50: "In progress"
      },
      axSessionInitiationResult: {
        0: 'Connected',
        1: 'Connected no voice',
        2: 'Busy',
        3: 'No answer',
        4: 'Network error',
        5: 'No response from network',
        6: 'Unavailable',
        7: 'Voicemail',
        8: 'Answering machine',
        9: 'Fax',
        10: 'Internal line error',
        11: 'Number unassigned',
        12: 'Aborted by user',
        13: 'Bad number format',
        14: 'Network busy',
        15: 'No free channels',
        16: 'Aborted',
        17: 'Disconnected during detection',
        18: 'Error'
      },
      axCreateReason: {
        0: "New",
        1: "Transfer",
        2: "Shifted",
        3: "Shifted TCO"
      },
      axSessionState: {
        0: "Accepted",
        1: "Queued",
        2: "Directed to user",
        3: "Accepted by user",
        4: "Rejected by user",
        5: "Rejected by remote side",
        6: "Ended by remote side",
        7: "Ended by user",
        8: "Invalid destination",
        9: "Transferred",
        10: "Redirect error",
        11: "System terminated",
        12: "Waiting timeout",
        13: "Initiating",
        14: "Initiation failed",
        15: "Establishing with user",
        16: "Unable to establish with user",
        17: "Rejected",
        65535: "Not set"
      },
      axDataContactShiftReason: {
        0: "Această încercare nevalidă",
        1: "Utilizator",
        2: "Drop",
        3: "Operațiuni de date",
        4: "Greșeală"
      },

      axDataQueryCondition: {
        0: "Equals",
        1: "Not equals",
        2: "Greater",
        3: "Lower",
        4: "Greater or equals",
        5: "Lower or equals",
        6: "Regular expression",
        7: "Not equals: regular expression",
        8: "In",
        9: "Not in",
        10: "Contains",
        11: "Not contains",
        12: "Between",
        13: "ArrayContains",
        14: "Null",
        15: "Not Null"
      },
      recordingButton: {
        recording: "Inregistrare",
        recordingUser: "Inregistrare utilizator",
        stopped: "Oprit",
        paused: "Pauza",
        stop: "Stop",
        recordBoth: "Inregistrati ambele",
        recordUser: "Inregistrati utilizatorul"
      },
      preview: {
        allCampaigns: "Toate campaniile"
      },
      soundSettings: {
        title: "Sound settings",
        newChatSound: "Chat started sound",
        newEmailSound: "Email received sound",
        newChatMessageSound: "Chat message sound",
        sessionEndSound: "Session end sound",
        routingSound: "Phone connecting sound"
      },
      statistics: {
        'STATISTICS.TILES.LOGGED': 'Logged (total)',
        'STATISTICS.TILES.AVAILABLE': 'Available (total)',
        'STATISTICS.TILES.IN_SESSION': 'In session (total)',
        'STATISTICS.TILES.PRODUCTIVE_BREAKS': 'Productive breaks',
        'STATISTICS.TILES.NONPRODUCTIVE_BREAKS': 'Unproductive breaks',
        'STATISTICS.TILES.WRAPUP': 'Wrapup (total)',
        'STATISTICS.TILES.INCOMING': 'Incoming calls',
        'STATISTICS.TILES.INCOMING_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.OUTGOING': 'Outgoing calls',
        'STATISTICS.TILES.OUTGOING_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.MANUAL': 'Manual',
        'STATISTICS.TILES.MANUAL_TOOLTIP': 'Connected/Initialized',
        'STATISTICS.TILES.PREVIEW': 'Calls preview',
        'STATISTICS.TILES.PREVIEW_TOOLTIP': 'Connected/Initialized',
        'STATISTICS.TILES.EMAIL': 'Email',
        'STATISTICS.TILES.EMAIL_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.CHAT': 'Chat',
        'STATISTICS.TILES.CHAT_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.SOCIAL': 'Social',
        'STATISTICS.TILES.SOCIAL_TOOLTIP': 'Answered/Rejected',
        'STATISTICS.TILES.WORKFLOW': 'Workflow',
        'STATISTICS.TILES.WORKFLOW_TOOLTIP': 'Answered/Forwarded',
        'STATISTICS.COUNTERS.SERVICE_TIME': 'Service time',
        'STATISTICS.COUNTERS.SUBSTANTIVE_PRODUCTIVITY': 'Substantive productivity',
        'STATISTICS.COUNTERS.REAL_PRODUCTIVITY': 'Real productivity',
        'STATISTICS.COUNTERS.INBOUND_SUCCEED': 'Succeeded inbound calls',
        'STATISTICS.COUNTERS.INBOUND_TIME': 'Inbound calls session time',
        'STATISTICS.COUNTERS.INBOUND_AVG_TIME': 'Inbound calls average in session time',
        'STATISTICS.COUNTERS.INBOUND_SHIFTED': 'Shifted inbound calls',
        'STATISTICS.COUNTERS.OUTBOUND_SUCCEED': 'Succeeded outbound calls',
        'STATISTICS.COUNTERS.OUTBOUND_TIME': 'Outbound calls in session time',
        'STATISTICS.COUNTERS.OUTBOUND_AVG_TIME': 'Outbound calls average in session time',
        'STATISTICS.COUNTERS.OUTBOUND_SHIFTED': 'Shifted outbound calls',
        'STATISTICS.COUNTERS.PREVIEW_SUCCEED': 'Succedeed preview calls',
        'STATISTICS.COUNTERS.PREVIEW_TIME': 'Preview calls in sesion time',
        'STATISTICS.COUNTERS.PREVIEW_AVG_TIME': 'Preview calls avarage in session time',
        'STATISTICS.COUNTERS.PREVIEW_SHIFTED': 'Shifted preview calls',
        'STATISTICS.COUNTERS.MANUAL_SUCCEED': 'Succeeded manual calls',
        'STATISTICS.COUNTERS.MANUAL_TIME': 'Manual calls in session',
        'STATISTICS.COUNTERS.MANUAL_AVG_TIME': 'Manual calls average in session time',
        'STATISTICS.COUNTERS.MANUAL_SHIFTED': 'Shifted manual calls',
        'STATISTICS.CHARTS.STATES.TITLE': 'Statuses',
        'STATISTICS.CHARTS.STATES.SUBTITLE': 'Time in statuses',
        'STATISTICS.CHARTS.STATES.LABELS.AVAILABLE': 'Available',
        'STATISTICS.CHARTS.STATES.LABELS.IN_SESSION': 'In session',
        'STATISTICS.CHARTS.STATES.LABELS.WRAPUP': 'Wrapup',
        'STATISTICS.CHARTS.STATES.LABELS.BREAKS': 'Break',
        'STATISTICS.CHARTS.BREAKS.TITLE': 'Breaks',
        'STATISTICS.CHARTS.BREAKS.SUBTITLE': 'Outside and during work',
        'STATISTICS.CHARTS.BREAKS.LABELS.PRODUCTIVE': 'Productive breaks',
        'STATISTICS.CHARTS.BREAKS.LABELS.NON_PRODUCTIVE': 'Unproductive breaks',
        'STATISTICS.CHARTS.SESSIONS.TITLE': 'Sessions',
        'STATISTICS.CHARTS.SESSIONS.SUBTITLE': 'Session types',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CONNECTIONS': 'Calls',
        'STATISTICS.CHARTS.SESSIONS.LABELS.CHAT': 'Chat',
        'STATISTICS.CHARTS.SESSIONS.LABELS.EMAIL': 'Email',
        'STATISTICS.CHARTS.SESSIONS.LABELS.SOCIAL': 'Social',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.TITLE': 'Accepted sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.SUBTITLE': 'Accepted and rejected sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.ACCEPTED': 'Accepted sessions',
        'STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.REJECTED': 'Rejected',
        'STATISTICS.CHARTS.SUCCESSES.TITLE': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.SUBTITLE': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.SUCCESSES': 'Successes',
        'STATISTICS.CHARTS.SUCCESSES.LABELS.ACCEPTED': 'Accepted sessions'
      }
    }
  };

  // Defult language
  // This variable is set by init to value from config or from localStorage
  var li18n = i18n["en"];
  // Póki co global do przekazania dalej, żeby odpalić na onload
  var mainFrame = null;

  var parentFrame = parent.document.getElementById('axWebAgent');

  function zeroPad(n) {
    return n < 10 ? '0' + n : n;
  }

  /**
   * @param {ax.SessionLib.Collections.Chat.AxChatMessageAttachmentDataElement} attachment
   * @returns {Promise}
   */
  function getImagePromiseWrapper(FileService, attachment, messageType, senderName, sentOn, from, refreshToken, msg) {

    var mType = ax.SessionLib.Collections.Chat.AxChatMessageType;
    var retObj = {
      __messageType: "file_share",
      __from: from,
      senderName: senderName,
      SentOn: sentOn,
      fileName: attachment.Name,
      fileUrl: attachment.Payload,
      fileType: attachment.Type,
      fileSize: attachment.Size,
      fileId: attachment.ExternalId,
      fileData: null,
      directLink: false,
      ChatMessageId: UUID()
    };

    if(msg) {
      retObj.ChatMessageId = msg.Id ?? retObj.ChatMessageId;
      retObj.SessionId = msg.SessionId;
    }

    if (attachment.Type === ax.SessionLib.Collections.Chat.AxChatMessageAttachmentDataType.Image) {

      if (messageType === mType.ChatMessage) {

        return FileService.getImage({

          refreshToken: refreshToken,
          fileId: attachment.ExternalId,
          fileUrl: retObj.fileUrl,
          tokenLifetime: 30000

        }).then(function (res) {

          retObj.fileData = res;

          return Promise.resolve(retObj);

        }).catch(function (err) {
          return Promise.resolve(retObj);
        });

      } else if (messageType === mType.FacebookMessage) {

        retObj.fileData = attachment.Payload;
        return Promise.resolve(retObj);
      }

    } else if (attachment.Type === 6 /*ax.SessionLib.Collections.Chat.AxChatMessageAttachmentDataType.Template*/) {
      if(msg) {
        retObj.fileName = msg.Message;
      }
      retObj.fileData = JSON.parse(attachment.Payload);
      return Promise.resolve(retObj);
    } else {

      if (messageType === mType.FacebookMessage) {

        retObj.fileData = attachment.Payload;

        if ( attachment.Type === ax.SessionLib.Collections.Chat.AxChatMessageAttachmentDataType.Fallback ) {
          retObj.directLink = true;
        }

        return Promise.resolve(retObj);

      } else {

        return Promise.resolve(retObj);
      }

    }

  }


  // Check if bit is set in byte
  function isBitSet(needle, haystack) {
    return (haystack & needle) === needle;
  }

  /**
   * Rozszerza iframkę, w której jest osadzony agent
   */
  function openFrame() {
    if (parentFrame) {
      parentFrame.setAttribute("data-small", "false");
    }
  }

  /**
   * Zmniejsza iframkę, w której jest osadzony agent
   */
  function closeFrame() {
    if (parentFrame) {
      parentFrame.setAttribute("data-small", "true");
    }
  }

  /**
   * Pokazuje iframkę, w której jest osadzony agent
   */
  function showFrame() {
    parentFrame.style.display = 'block';
  }

  /**
   * Chowa iframkę, w której jest osadzony agent
   */
  function hideFrame() {
    parentFrame.style.display = 'none';
  }

  /**
   * Połączenie telefoniczne (na razie używane tylko w trybie agenta)
   * @param {integer} direction "Inbound" = 0, "Outbound" = 1, "Internal"= 2 "None" = 65535 AxDict:2004
   * @param {string} ani
   * @param {string} dnis
   * @param {string} statusStr - używany np do wyświetlenia kwadracika "transfer", "active"
   * @param {string} interalCallId - id połączenia - używanenp do matchowania z obiektem sesji z propertisem TransferCallId
   */
  function Call(direction, ani, dnis, statusStr, internalCallId) {
    this.direction = direction;
    this.ani = ani;
    this.dnis = dnis;
    this.statusStr = statusStr;
    this.internalCallId = internalCallId;
  }

  /**
   * Dane sesji związane z web
   * @class
   * @param {String} inboundUrl adres, do którego nawigujemy na sesji inboudowej
   * @param {String} outboundUrl adres, do którego nawigujemy na sesji outboundowej
   * @property {String} inboundUrl
   * @property {String} outboundUrl
   * @property {String} typeName
   * @property {String} stateName
   * @property {Boolean} breakSessionVisible If break session button is available (enabled)
   * @property {Boolean} breakSessionVisible If break session button is available
   * @property {Boolean} shiftSessionVisible If shift session button is available
   * @property {Boolean} editRecordVisible If shift session button is available
   * @property {Boolean} closeChatSessionVisible If close chat session button is visible
   * @property {Boolean} openCustomerSearchVisible If customer search button is visible
   * @property {Boolean} shifted If session record is already shifted (for enable/disable shift button)
   * @property {Boolean} autoCloseForm If session tab should be automatically closed
   * @property {String} customerUrl CRM customer url
   * @property {String} sessionTabWidth Session tab width
   * @property {String} forceAssignSessionToCustomer If session must be assigned to customer
   */
  function SessionWebData(inboundUrl, outboundUrl) {
    this.inboundUrl = inboundUrl;
    this.outboundUrl = outboundUrl;
    this.typeName = '';
    this.stateName = '';
    this.breakSession = false;
    this.breakSessionVisible = false;
    this.shiftSessionVisible = false;
    this.editRecordVisible = false;
    this.closeChatSessionVisible = false;
    this.openCustomerSearchVisible = false;
    this.shifted = false;
    this.autoCloseForm = false;
    this.customerUrl = "";
    this.sessionTabWidth = "";
    this.forceAssignSessionToCustomer = false;

  }

  /**
   * Pomocnicze dane sesji, jakby co dodawać kolejne parametry i przypisywać do kolejnych propertisów
   * @param {SessionWebData} webData - dane webowe
   * @param {?ax.SessionLib.Sessions.Actions.AxSessionEventRecordingAction} recording
   */
  function SessionData(webData, recording) {
    this.web = webData;
    this.recording = recording;
    // Chat messages
    this.messages = [];
    this.unreadChatCounter = 0;
    // Chat answers
    this.chatAnswers = [];
    this.chatAnswersTags = [];
    // Chat hints
    this.chatHints = [];
    this.chatHint = "";
    this.chatHintError = "";
    this.chatHintErrorFlag = false;
    this.receivedFiles = {};
    this.availableFiles = [];
    // Text in chat message input
    this.chatTextInput = "";
    this.videoCall = null;
    this.videoCallActive = false;

  }

  /**
   * Itemka na liście sesji
   * @param {AxMsgSessionDirectedEvent} Sesja
   * @param {SessionData} Obiekt pomocniczy do transferu danych
   * @property {ax.SessionLib.AxMessages.Sessions.AxMsgSessionDirectedEvent} session
   * @property {SessionData} data
   * @property {Number} timeIntervalId
   * @property {Date} startTime
   * @property {String} duration
   * @property {String} queueWaitTime
   * @property {ax.AxSurveysLib.ServerSessions.AxSurveySessionContainer} surveySession
   */
  function InteractionItem(session, data, wl) {
    this.wl = wl;
    this.session = session;
    this.data = data;
    this.timeIntervalId = null;
    this.startTime = new Date();
    this.duration = "00:00:00";
    this.lastRecordingState = ax.SessionLib.AxSessionRecordingState.Stopped;
    this.recordingTimeIntervalId = null;
    this.recordingResumeTime = null;
    this.recordingDuration = null;
    this.queueWaitTime = "00:00:00";
    this.beginSurveySession = null;
    this.surveySession = null;
    this.additionalParams = null;
    this.sessionWasAborted = null;
    this.recordWasShifted = null;
    this.dataFieldsEditCollection = [];
    this.phoneDataFieldsEditCollection = [];
    this.validateExternalDataSources = {};
    this.isSurveyLoading = false;
    this._isEmailReady = false;
    this.typingData = {};

    // Czas oczekiwania w kolejce jako sformatowany string
    // Czas zakolejkowania przeliczamy z timespana na timestamp
    if (session.Session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser) {

      var queuedOn = new Date(session.Session.QueuedOn - 62135604000000);
      var lastStateChangeTime = new Date(session.Session.LastStateChangeTime - 62135604000000);
      this.queueWaitTime = dateDiff(lastStateChangeTime, queuedOn).formatted;
    }

    this.dataRecord = session.DataRecord;
    this.prepareDataFieldsEdit = function (dataRecord) {

      var that = this;
      var externalDataSourceToGet = [];

      if (!this.dataRecord && dataRecord) {
        this.dataRecord = dataRecord;
        this.session.DataRecord = dataRecord;
      }
      if (this.dataRecord && this.wl) {
        var getDataFieldByName = function (name) {
          if (that.dataRecord && that.dataRecord.DataFields) {
            for (var i = 0; i < that.dataRecord.DataFields.length; i++) {
              if (that.dataRecord.DataFields[i].Name === name) {
                return that.dataRecord.DataFields[i];
              }
            }
          }
          return null;
        };
        if (this.dataRecord.ContactPhones) {
          this.dataRecord.ContactPhones.sort(function (a, b) {
            return a.Index - b.Index;
          });
          this.dataRecord.ContactPhones.forEach(function (pf) {
            var df = getDataFieldByName(pf.PhoneName);
            var pfl = that.wl.createAxObject("AxDataFieldChangeLogDataElement");
            pfl.value.FieldId = df.Id;
            pfl.value.DataRecordId = that.dataRecord.Id;
            pfl.value.CampaignId = that.dataRecord.CampaignId;
            pfl.value.SessionServerSessionId = that.session.Session.SessionId;
            pfl.value.Name = pf.PhoneName;
            pfl.value.DisplayName = df.DisplayName || df.Name;
            pfl.value.Value = pf.Number;
            pfl.value.OldValue = pf.Number;
            pfl.value.TempValue = pf.Number;
            pfl.value.DataField = df;
            pfl.value.Type = df.FieldType;
            pfl.value.PhoneDataField = pf;
            pfl.value.IsClosed = pf.State != ax.SessionLib.AxDataContactPhoneState.Open && pf.State != ax.SessionLib.AxDataContactPhoneState.BadData;
            pfl.value.CloseSet = false;
            if (df.ValidateExternalDataSourceId) {
              externalDataSourceToGet.push(df.ValidateExternalDataSourceId);
              pfl.value.ValidationValues = [];
            }
            that.phoneDataFieldsEditCollection.push(pfl);
          });
        }
        if (this.dataRecord.DataFields) {
          this.dataRecord.DataFields.sort(function (a, b) {
            return a.ViewIndex - b.ViewIndex;
          });

          this.dataRecord.DataFields.forEach(function (df) {
            if (df.FieldType !== ax.SessionLib.AxDataContactFieldType.PhoneNumber
              && (!!df.Category || df.Category !== ax.SessionLib.AxDataContactFieldCategory.System)) {
              var dfl = that.wl.createAxObject("AxDataFieldChangeLogDataElement");
              dfl.value.FieldId = df.Id;
              dfl.value.DataRecordId = that.dataRecord.Id;
              dfl.value.CampaignId = that.dataRecord.CampaignId;
              dfl.value.SessionServerSessionId = that.session.Session.SessionId;
              dfl.value.Name = df.Name;
              dfl.value.DisplayName = df.DisplayName || df.Name;
              dfl.value.Value = df.Value;
              dfl.value.OldValue = df.Value;
              dfl.value.TempValue = df.Value;
              dfl.value.DataField = df;
              dfl.value.Type = df.FieldType;
              if (df.ValidateExternalDataSourceId) {
                //that.validateExternalDataSources[df.ValidateExternalDataSourceId] = null;
                if (externalDataSourceToGet.indexOf(df.ValidateExternalDataSourceId) === -1) {
                  externalDataSourceToGet.push(df.ValidateExternalDataSourceId);
                }
                dfl.value.ValidationValues = [];
              }
              that.dataFieldsEditCollection.push(dfl);
            }
          });
        }

        var promiseRegister = [];
        log.debug("[InteractionItem.prepareDataFieldEdit] External data source identifiers to get: ", externalDataSourceToGet);
        externalDataSourceToGet.forEach(function (vs) {
          log.debug("Create promise for " + vs);
          var sPromise = that.wl.getExternalData({
            DataSourceId: parseInt(vs)
          }).then(function (r) {

            var res = that.wl.unpackObject(r);

            if (!res.Message) {
              var dateTable = res.DataTable;
              var validationValues = [];

              if (dateTable && dateTable.Rows) {
                dateTable.Rows.map(function (row) {
                  if (row && row.length > 0) {
                    validationValues.push(row[0]);
                  }
                });
              }
              that.validateExternalDataSources[vs] = dateTable;
              that.phoneDataFieldsEditCollection.map(function (phone) {
                if (phone.value.DataField.ValidateExternalDataSourceId == res.ExternalDataSourceId) {
                  phone.value.ValidationValues = validationValues;
                }
              });
              that.dataFieldsEditCollection.map(function (field) {
                if (field.value.DataField.ValidateExternalDataSourceId == res.ExternalDataSourceId) {
                  field.value.ValidationValues = validationValues;
                }
              });
              return Promise.resolve();
            } else {

              log.error("[InteractionItem.prepareDataFieldEdit] Error getting external data for fields: " + res.Message);
              return Promise.reject(res);
            }

          }).catch(function (err) {
            log.error("[InteractionItem.prepareDataFieldEdit] Error getting external data for fields", err)
            return Promise.reject(err);
          });

          promiseRegister.push(sPromise);


        });


        Promise.all(promiseRegister).then(function (res) {
          log.debug("[InteractionItem.prepareDataFieldEdit] Data from all data sources for data fields received. Received data sources length: " + res.length);
        }).catch(function (err) {
          log.error("[InteractionItem.prepareDataFieldEdit] Error getting data from exteranal sources for data fields", err);
        })


      }

    };
    this.clearDataFieldsEdit = function () {
      this.dataRecord = null;
      this.phoneDataFieldsEditCollection = [];
      this.dataFieldsEditCollection = [];
      this.validateExternalDataSources = {};
    };

    this.isAcceptable = function () {
      var actions, l;
      if (this.session !== null && this.session.Actions !== null) {
        actions = this.session.Actions;
        l = this.session.Actions.length;
        for (var i = 0; i < l; i++) {
          if (actions[i] instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession) {
            return true;
          }
        }

        return false;

      } else {
        return false;
      }

    };

    this.isStateAcceptable = function () {
      var axState = ax.SessionLib.AxSessionState,
        state = this.session.Session.SessionState;
      //return ( state === axState.Queued || state === axState.DirectedToUser);
      return state === axState.Queued;
    };

    this.getAcceptSessionAction = function () {
      var actions, l;
      if (this.session !== null && this.session.Actions !== null) {
        actions = this.session.Actions;
        l = this.session.Actions.length;
        for (var i = 0; i < l; i++) {
          if (actions[i] instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession) {
            return actions[i];
          }
        }

        return null;

      } else {
        return null;
      }

      return null;

    };

    this.prepareDataFieldsEdit();

  }

  InteractionItem.prototype = Object.create(Function.prototype, {

    isCrmReady: {
      get: function () {
        if (this.data.web.forceAssignSessionToCustomer === true) {
          return this.session.Session.ContactCustomerId !== null;
        }
        return true;
      }
    },

    isSurveyReady: {
      get: function () {
        if (this.surveySession) {
          if (this.surveySession.Ended === true) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },

    isEmailReady: {
      get: function () {
        if (this.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {
          return this._isEmailReady;
        } else {
          return true;
        }
      }
    }

  });

  /**
   * Calculates time from .net to javascript
   * @param {Number} ms Milliseconds from .Net
   * @retrun Date
   */
  function dotNetToJsTime(ms) {
    return new Date(ms - 62135604000000);
  }

  /**
   * Item on contact list (chat)
   * @class
   * @param {ax.AxDataLib.Users.AxUserAccountElement} account
   * @param {ax.SessionLib.AxUserStateParam} state
   * @param {String} [dialableNumber=""]
   * @property {String} sortableName
   * @property {Boolean} unread If we have unread messages from this contact
   * @property {unreadCounter} Count of unread messages from this contact
   * @property {ax.AxDataLib.Users.AxUserAccountElement} user
   * @property {ax.SessionLib.AxUserStateParam} state
   * @property {Integer} stateDuration
   */
  function ContactListItem(account, state, dialableNumber) {

    var dialNo = typeof dialableNumber !== "undefined" && dialableNumber !== null ? dialableNumber.toString() : "";

    Object.defineProperty(this, 'user', { writable: true, value: account });
    Object.defineProperty(this, 'user', { writable: false });
    Object.defineProperty(this, 'state', { writable: true, value: state });
    Object.defineProperty(this, 'state', { writable: false });
    Object.defineProperty(this, 'sortableName', { writable: true, value: account.Name + ' ' + account.Surname });
    Object.defineProperty(this, 'sortableName', { writable: false });
    Object.defineProperty(this, 'dialableNumber', { writable: true, value: dialNo });
    Object.defineProperty(this, 'dialableNumber', { writable: false });
    Object.freeze(this.user);
    Object.freeze(this.state);
  }

  ContactListItem.prototype = Object.create(null, {
    state: { value: null },
    _stateDuration: { value: 0 },
    stateDuration: {
      set: function (v) {
        Object.defineProperty(this, '_stateDuration', { writable: true, value: v, configurable: true });
        Object.defineProperty(this, '_stateDuration', { writable: false, configurable: true });
      },
      get: function () {
        return this._stateDuration;
      }
    },
    user: { value: null },
    unread: { value: false, configurable: true },
    sortableName: { value: true },
    dialableNumber: { value: "" },
    _unreadCounter: { value: 0, configurable: true },
    unreadCounter: {
      set: function (v) {
        Object.defineProperty(this, '_unreadCounter', { writable: true, value: v, configurable: true });
        Object.defineProperty(this, '_unreadCounter', { writable: false, configurable: true });
        var u = false;
        if (v > 0) {
          u = true;
        } else {
          u = false;
        }
        Object.defineProperty(this, 'unread', { writable: true, value: u, configurable: true });
        Object.defineProperty(this, 'unread', { writable: false, configurable: true });
      },
      get: function () {
        return this._unreadCounter;
      }
    }
  });

  /**
   * Formatuje czas z postaci otrzymanej w msg od serwera do postaci YYYY-MM-DD HH24:MM:SS
   * @param {Number}
   */
  function formatDateTime(time) {

    var dt = new Date(time - 62135604000000);
    return dt.getFullYear() + '-' + zeroPad(dt.getMonth() + 1) + '-' + zeroPad(dt.getDate()) + ' ' + zeroPad(dt.getHours()) + ':' + zeroPad(dt.getMinutes()) + ':' + zeroPad(dt.getSeconds());
  }

  /**
   * Pomocniczy do przedstawiania czasu
   * @param {integer} interval Czas interwału w sekundach
   */
  function AxInterval(interval) {

    // Czas jako integer
    this.interval = interval;
    // Czas sformatowany w postaci HH:MM:SS
    this.formatted = null;

  }

  /**
   * Przelicza różnicę pomiędzy datami zwraca wynik w postaci obiektu AxInterval
   * Odejmuje date2 od date1
   * @param {Date} date1 Data, od której odejmujemy
   * @param {Date} date2 Data, którą odejmujemy
   */
  function dateDiff(date1, date2) {

    var diff = date1.getTime() - date2.getTime();
    var rem = diff;
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= days * (1000 * 60 * 60 * 24);

    var hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    var mins = Math.floor(diff / (1000 * 60));
    diff -= mins * (1000 * 60);

    var seconds = Math.floor(diff / (1000));
    diff -= seconds * (1000);

    var interval = new AxInterval(rem);

    interval.formatted = zeroPad(hours) + ':' + zeroPad(mins) + ':' + zeroPad(seconds);

    return interval;

  }

  /**
   *
   * Gets cookie value for key
   * If key not exists returns null
   * @param {string} k Cookie key
   * @returns {string}
   */
  function getCookie(k) {
    return document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + k + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1") || null;
  }

  // Logger
  var log = log4javascript.getLogger("WebAgent");
  log.setLevel(log4javascript.Level.TRACE);
  var consoleAppender = new log4javascript.BrowserConsoleAppender();

  consoleAppender.setThreshold(log4javascript.Level.TRACE);
  //var consoleLayout = new log4javascript.PatternLayout("%d{yyyy-MM-dd HH:mm:ss.SSS} [%c] [%-5p] - %m{10}%n");
  //consoleAppender.setLayout(consoleLayout);
  log.addAppender(consoleAppender);

  var workerMsgProto = Object.create(null, {
    type: { value: "", writable: true },
    value: { value: {}, writable: true }
  });

  function createWorkerMessage(type, value) {

    var msg = Object.create(Object.getPrototypeOf(workerMsgProto));
    msg.type = type;
    msg.value = value;
    return msg;
  }

  // Temporary commented out UFWEBAGENT-384
  //var logWorker = new Worker("js/logger-worker.js");
  /*logWorker.addEventListener("message",  function(mes) {

        var data = mes.data;
        console.log("Message from logger worker", mes);

        if ( data === 'logger-worker-initialized') {
            logWorker.postMessage(createWorkerMessage("get-db-data",{sth:""}));
        }

    });*/

  //var workerAppender = new log4javascript.WorkerAppender(logWorker);
  //log.addAppender(workerAppender);

  var app = ng.module('ax.WebCommunications');

  // Filtr do zaufanego htmla
  app.filter('to_trusted', ['$sce', function ($sce) {
    return function (text) {
      return $sce.trustAsHtml(text);
    };
  }]);

  // Filtr do zaufanych adresów url
  app.filter('to_trusted_url', ['$sce', function ($sce) {
    return function (url) {
      return $sce.trustAsResourceUrl(url);
    };
  }]);

  app.filter('bbcode', ['$sce', "$sanitize", function ($sce, $sanitize) {
    return function (post) {
      var opentags = [];           // open tag stack
      var crlf2br = true;     // convert CRLF to <br>?
      var noparse = false;    // ignore BBCode tags?

      var urlstart = -1;      // beginning of the URL if zero or greater (ignored if -1)

      // aceptable BBcode tags, optionally prefixed with a slash
      var tagname_re = /^\/?(?:b|i|u|pre|center|samp|code|colou?r|size|noparse|url|link|s|q|(block)?quote|img|u?list|li)$/i;

      // color names or hex color
      var color_re = /^(:?black|silver|gray|white|maroon|red|purple|fuchsia|green|lime|olive|yellow|navy|blue|teal|aqua|#(?:[0-9a-f]{3})?[0-9a-f]{3})$/i;

      // numbers
      var number_re = /^[\\.0-9]{1,8}$/i;

      // reserved, unreserved, escaped and alpha-numeric [RFC2396]
      var uri_re = /^[-;\/\?:@&=\+\$,_\.!~\*'\(\)%0-9a-z]{1,512}$/i;

      // main regular expression: CRLF, [tag=option], [tag="option"] [tag] or [/tag]
      var postfmt_re = /([\r\n])|(?:\[([a-z]{1,16})(?:=(?:"|'|)([^\x00-\x1F"'\(\)<>\[\]]{1,256}))?(?:"|'|)\])|(?:\[\/([a-z]{1,16})\])/ig;

      // stack frame object
      function taginfo_t(bbtag, etag) {
        return {
          bbtag: bbtag,
          etag: etag
        };
      }

      // check if it's a valid BBCode tag
      function isValidTag(str) {
        if (!str || !str.length)
          return false;

        return tagname_re.test(str);
      }

      //
      // m1 - CR or LF
      // m2 - the tag of the [tag=option] expression
      // m3 - the option of the [tag=option] expression
      // m4 - the end tag of the [/tag] expression
      //
      function textToHtmlCB(mstr, m1, m2, m3, m4, offset, string) {
        //
        // CR LF sequences
        //
        if (m1 && m1.length) {
          if (!crlf2br)
            return mstr;

          switch (m1) {
            case '\r':
              return "";
            case '\n':
              return "<br>";
          }
        }

        //
        // handle start tags
        //
        if (isValidTag(m2)) {
          var m2l = m2.toLowerCase();

          // if in the noparse state, just echo the tag
          if (noparse)
            return "[" + m2 + "]";

          // ignore any tags if there's an open option-less [url] tag
          if (opentags.length && (opentags[opentags.length - 1].bbtag == "url" || opentags[opentags.length - 1].bbtag == "link") && urlstart >= 0)
            return "[" + m2 + "]";

          switch (m2l) {
            case "code":
              opentags.push(new taginfo_t(m2l, "</code></pre>"));
              crlf2br = false;
              return "<pre><code>";

            case "pre":
              opentags.push(new taginfo_t(m2l, "</pre>"));
              crlf2br = false;
              return "<pre>";

            case "center":
              opentags.push(new taginfo_t(m2l, "</center>"));
              crlf2br = false;
              return "<center>";

            case "color":
            case "colour":
              if (!m3 || !color_re.test(m3))
                m3 = "inherit";
              opentags.push(new taginfo_t(m2l, "</span>"));
              return "<span style=\"color: " + m3 + ";\">";

            case "size":
              if (!m3 || !number_re.test(m3))
                m3 = "1";
              opentags.push(new taginfo_t(m2l, "</span>"));
              return "<span style=\"font-size: " + Math.min(Math.max(m3, 0.7), 3) + "em\">";

            case "s":
              opentags.push(new taginfo_t(m2l, "</span>"));
              return "<span style=\"text-decoration: line-through\">";

            case "noparse":
              noparse = true;
              return "";

            case "link":
            case "url":
              opentags.push(new taginfo_t(m2l, "</a>"));

              // check if there's a valid option
              if (m3 && uri_re.test(m3)) {
                // if there is, output a complete start anchor tag
                urlstart = -1;
                return "<a target=\"_blank\" href=\"" + m3 + "\">";
              }

              // otherwise, remember the URL offset
              urlstart = mstr.length + offset;

              // and treat the text following [url] as a URL
              return "<a target=\"_blank\" href=\"";
            case "img":
              opentags.push(new taginfo_t(m2l, "\" />"));

              if (m3 && uri_re.test(m3)) {
                urlstart = -1;
                return "<" + m2l + " src=\"" + m3 + "";
              }

              return "<" + m2l + " src=\"";

            case "q":
            case "quote":
            case "blockquote":
              var tag = (m2l === "q") ? "q" : "blockquote";
              opentags.push(new taginfo_t(m2l, "</" + tag + ">"));
              return m3 && m3.length && uri_re.test(m3) ? "<" + tag + " cite=\"" + m3 + "\">" : "<" + tag + ">";

            case "list":
              opentags.push(new taginfo_t('list', '</ol>'));
              return '<ol>';

            case "ulist":
              opentags.push(new taginfo_t('ulist', '</ul>'));
              return '<ul>';

            case "b":
              opentags.push(new taginfo_t('b', '</strong>'));
              return '<strong>';

            case "i":
              opentags.push(new taginfo_t('i', '</em>'));
              return '<em>';

            default:
              // [samp] and [u] don't need special processing
              opentags.push(new taginfo_t(m2l, "</" + m2l + ">"));
              return "<" + m2l + ">";

          }
        }

        //
        // process end tags
        //
        if (isValidTag(m4)) {
          var m4l = m4.toLowerCase();

          if (noparse) {
            // if it's the closing noparse tag, flip the noparse state
            if (m4 == "noparse") {
              noparse = false;
              return "";
            }

            // otherwise just output the original text
            return "[/" + m4 + "]";
          }

          // highlight mismatched end tags
          if (!opentags.length || opentags[opentags.length - 1].bbtag != m4l)
            return "<span style=\"color: red\">[/" + m4 + "]</span>";

          if (m4l == "url" || m4l == "link") {
            // if there was no option, use the content of the [url] tag
            if (urlstart > 0)
              return "\">" + string.substr(urlstart, offset - urlstart) + opentags.pop().etag;

            // otherwise just close the tag
            return opentags.pop().etag;
          }
          else if (m4l == "code" || m4l == "pre")
            crlf2br = true;

          var endTag = opentags.pop();
          if (endTag) {
            // other tags require no special processing, just output the end tag
            var end = endTag.etag;
            return end;
          }
          return "";
        }

        return mstr;
      }

      // actual parsing can begin
      var result = '', endtags, tag;

      // convert CRLF to <br> by default
      crlf2br = true;

      // create a new array for open tags
      if (opentags == null || opentags.length)
        opentags = new Array(0);

      // run the text through main regular expression matcher
      if (post) {

        post = $sanitize(post);

        // idea to replace single *'s from http://patorjk.com/bbcode-previewer/
        post = (function (_post) {
          return _post.replace(/(\[\*\])([^\[]*)/g, function (m0, m1, m2, offset, mstr) {
            return '[li]' + m2 + '[/li]';
          });
        })(post);
        result = post.replace(postfmt_re, textToHtmlCB);

        // reset noparse, if it was unbalanced
        if (noparse)
          noparse = false;

        // if there are any unbalanced tags, make sure to close them
        if (opentags.length) {
          endtags = new String();

          // if there's an open [url] at the top, close it
          if (opentags[opentags.length - 1].bbtag == "url" || opentags[opentags.length - 1].bbtag == "link") {
            opentags.pop();
            endtags += "\">" + post.substr(urlstart, post.length - urlstart) + "</a>";
          }

          // close remaining open tags
          while (opentags.length)
            endtags += opentags.pop().etag;
        }
      }
      var ret = endtags ? result + endtags : result;
      return $sce.trustAsHtml(ret);
    }
  }]);

  // User state filter - changes StatId to state name in actual language
  app.filter('user_state', ['$sce', function ($sce) {
    return function (str) {
      if (str in li18n.statuses) {
        return li18n.statuses[str];
      } else {
        return "Unknown";
      }
    }
  }]);

  // Filtr timespan - zamienia czas podany w milisekundach na hh:mm:ss
  app.filter('millis_to_string', ['$sce', function ($sce) {

    return function (s) {

      var ms = s % 1000;
      s = (s - ms) / 1000;
      var secs = s % 60;
      s = (s - secs) / 60;
      var mins = s % 60;
      var hrs = (s - mins) / 60;

      return zeroPad(hrs) + ':' + zeroPad(mins) + ':' + zeroPad(secs);

    }

  }]);

  // Filtr DateTime - zamienia czas podany w milisekundach jako .net DateTime na YYYY-MM-DD HH24:MM:SS
  app.filter('millis_to_date', ['$sce', function ($sce) {

    return function (s) {

      if (typeof s === "string") {
        return s;
      } else {

        var offset = (new Date()).getTimezoneOffset() * 60 * 1000;
        // var dt = new Date(s - 62135604000000 + offset); // stare
        // Odejmujemy stałą liczbę, dlatego, że z serwera przychodzi czas jako .net DateTime, który jest liczony od 0, a javascript liczy czas jako unix timestamp od 1970-01-01 00:00:00
        // Offset jest to różnica pomiędzy aktualną strefą czasową przeglądarki, a czasem UTC
        var dt = new Date(s - 62135596800000 + offset);
        // Ale to co przyszło z serwera, to jest czas serwera w jakiejś bliżej nieokreślonej strefie czasowej
        // Czyli w sumie to nie wiadomo nawet jak to policzyć, bo trzeba założyć, że serwer ma ustawioną strefę PL i braż pod uwagę zmiany czasu w PL

        return dt.getFullYear() + '-' + zeroPad(dt.getMonth() + 1) + '-' + zeroPad(dt.getDate()) + ' ' + zeroPad(dt.getHours()) + ':' + zeroPad(dt.getMinutes()) + ':' + zeroPad(dt.getSeconds());
      }
    }

  }]);

  // Filtr adresu sip
  app.filter('sip_to_number', ['$sce', function ($sce) {

    return function (s) {

      // Musi być bo inaczej się wywala (chyba jakiś bug angulara)
      if (s) {
        var parsedNum = s.match(/^.*<sip:([0-9]{0,})@.*>$/);
        if (parsedNum && parsedNum.length == 2) {
          s = parsedNum[1];
        }
      }
      return s;
    }

  }]);

  app.filter('associatedSessionNumber', ['SessionService', function (SessionService) {

    return function (callNo, call) {

      var callInteraction,
        baseInteraction,
        ret = callNo;

      if (callNo) {

        //  Find interaction associated with call
        callInteraction = SessionService.getSessionById(call.OwnerSessionId);

        if (callInteraction && callInteraction.session.Session.AttachToSessionId !== null) {

          // Find base interaction
          baseInteraction = SessionService.getSessionById(callInteraction.session.Session.AttachToSessionId);

          if (baseInteraction) {
            ret = baseInteraction.session.Session.RemoteSideFriendlyNameComputed;
          }

        }
      }

      return ret;

    }

  }]);

  app.filter('onlyNotAttachedInteractions', [function () {

    return function (interactions) {
      var ret = [];
      if (interactions) {

        ret = interactions.filter(function (interaction) {

          if (interaction.session.Session.AttachToSessionId === null) {
            return true;
          }
          return false;

        });

      }

      return ret;
    }

  }]);

  //Usage: {{ "From model: {0}; and a constant: {1};" | format:model.property:"constant":...:... }}
  app.filter("format", function () {
    return function (input) {
      var args = arguments;
      return input.replace(/\{(\d+)\}/g, function (match, capture) {
        return args[1 * capture + 1];
      });
    };
  });


  app.filter("bytes_human_readable", function () {

    return function (bytes) {
      var si = true;
      var thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
      var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      var u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + ' ' + units[u];
    };

  });

  var eventNames = [
    'click',
    'dblclick',
    'mousedown',
    'mouseup',
    'mouseover',
    'mouseout',
    'mousemove',
    'mouseenter',
    'mouseleave',
    'keydown',
    'keyup',
    'keypress',
    'submit',
    'focus',
    'blur',
    'copy',
    'cut',
    'paste'
  ];

  /* angular.js v1.6.10 src/ng/compile.js */
  var PREFIX_REGEXP = /^((?:x|data)[:\-_])/i;
  var SPECIAL_CHARS_REGEXP = /[:\-_]+(.)/g;

  function directiveNormalize(name) {
    return name
      .replace(PREFIX_REGEXP, '')
      .replace(SPECIAL_CHARS_REGEXP, function (_, letter, offset) {
        return offset ? letter.toUpperCase() : letter;
      });
  }

  var epicMiddleware = createEpicMiddleware();

  function configureStore($ngReduxProvider) {
    /// TODO
    /// add Redux DevTool Extension support by adding param:
    /// [window.__REDUX_DEVTOOLS_EXTENSION__()]
    /// wait to ng-redux 4.1.0 (solve issue https://github.com/angular-redux/ng-redux/issues/196)
    $ngReduxProvider.createStoreWith(function (state) {
      return state
    }, [epicMiddleware]);
  }


  // Tutaj można dokonfigurować sanitize co ma łapać
  // póki co zostajemy przy domyślnych ustawieniach
  app.config(['$compileProvider', '$provide', '$ngReduxProvider', AppConfig]);

  function AppConfig($compileProvider, $provide, $ngReduxProvider) {
    configureStore($ngReduxProvider);

    //$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file):/);

    // HACK Warning !!!
    // rewrite angular event
    // ----begin HACK

    /* Rewrite event directives via decorators, based on:
        angular.js v1.6.10 src/ng/directive/ngEventDirs.js
        */

    var forceAsyncEvents = {
      'blur': true,
      'focus': true
    };

    eventNames.forEach(function (eventName) {
      var directiveName = directiveNormalize('ng-' + eventName);

      function decorator($delegate, $parse, $rootScope) {
        $delegate[0].compile = function ($element, attr) {
          var fn = $parse(attr[directiveName]);

          return function ngEventHandler(scope, element) {
            element.on(eventName, function (event) {
              var callback = function () {
                // Main change: ignore react events!!!
                // ---- begin
                if (event.originalEvent.isReact && !event.originalEvent.repropagate) {
                  return;
                }
                // ---- end

                fn(scope, { $event: event });
              };

              if (forceAsyncEvents[eventName] && $rootScope.$$phase) {
                scope.$evalAsync(callback);
              } else {
                scope.$apply(callback);
              }
            });
          };
        }

        return $delegate;
      }

      $provide.decorator(directiveName + 'Directive', ['$delegate', '$parse', '$rootScope', decorator]);
    });

    //----end HACK
  }

  /**
   * Add react-event directive to react component
   */
  app.directive('reactEvents', ['$compile', reactEvents])

  function reactEvents($compile) {
    return {
      restrict: 'A',
      compile: function (el) {
        el.removeAttr('react-events')
        el.attr('propagate', '_propagate');
        var fn = $compile(el);

        return function (scope, el, attr) {
          scope._propagate = function (event) {
            var newEvent = new event.constructor(event.type, event);
            newEvent.isReact = true;
            newEvent.repropagate = true;

            el[0].dispatchEvent(newEvent);
          }

          eventNames.forEach(function (eventType) {
            el.on(eventType, function (event) {
              if (!event.originalEvent.isReact) {
                event.originalEvent.isReact = true;
                event.originalEvent.repropagate = false;
              }
            });
          });

          fn(scope);
        }
      }
    }
  }

  app.directive('axContentEditable', ['$sanitize', AxContentEditable]);

  function AxContentEditable($sanitize, $compileProvider) {
    return {
      link: function ($scope, $element) {

        $element.on("paste", function (e) {

          var clipboardData, pastedData;
          e.stopPropagation();
          e.preventDefault();
          clipboardData = e.originalEvent.clipboardData || window.clipboardData;
          pastedData = clipboardData.getData("Text");

          document.execCommand("insertHTML", false, $sanitize(pastedData));
          //$element.html($sanitize(pastedData));

        });
      }
    }
  }

  /**
   * Check if array has at least one item from array
   * @param {Array} permissions User permissions
   * @param {Array} items Searched items
   */
  function hasAnyItem(permissions, items) {

    var l = items.length;
    for (var i = 0; i < l; i++) {

      if (permissions.indexOf(items[i]) !== -1) {
        return true;
      }

    }

    return false;

  }

  /**
   * Check if menu item is enabled
   * @param {Array} config menu items configuration
   * @param {String} path Menu item path second level is separated by . (dot) for example workflow.projects
   */
  function isMenuItemEnabled(config, path) {

    var arrPath = path.split('.');

    if (arrPath.length == 2) {

      return config[arrPath[0]] && config[arrPath[0]].submodules && config[arrPath[0]].submodules[arrPath[1]] && config[arrPath[0]].submodules[arrPath[1]].enabled === true;

    } else if (arrPath.length == 1) {

      return config[arrPath[0]] && config[arrPath[0]].enabled === true;

    }

    return false;

  }

  // Do wymiany danych pomiędzy elementami systemu
  function Data(config) {

    var f1 = new ax.AxSurveysLib.Fields.AxSurveyFieldText();
    f1.Text = "Kontrolka testowa";

    var f2 = new ax.AxSurveysLib.Fields.AxSurveyFieldTextValue();
    f2.Value = "Wartość pola inout";
    f2.Text = "Drugie pole";

    var f3 = new ax.AxSurveysLib.Fields.AxSurveyFieldTime();
    f3.Mandatory = true;
    f3.Name = "Field" + new Date().getUTCMilliseconds();
    f3.Header = "Etykieta Time";
    f3.Hint = "Etykieta Time";

    var dt = {
      loginInProgress: false,
      crmVisible: false,
      sFields: ax.AxSurveysLib.Fields,
      //mockControls: [f1, f2, f3],
      mockControls: null,
      version: "", // Wersja jest przepisywana w init, żeby jej nie ustawiać w kilku miejsach
      CONFIG: config,
      server: "", // From login window
      login: "", // From login window
      password: "", // From login window
      loggedIn: false,
      focus: "", // For login screen
      chatToSend: "",
      message: "",
      statusProgessVisible: false,
      callTooltip: { visible: false, cause: "", text: "", red: false },
      currentChat: { project: { name: "" }, user: { id: null } },
      chatUnreadCounter: 0,
      shiftTime: {
        datePicker: { dt: null },
        timePicker: { time: null },
        selectedButton: null,
        disabledButton: null,
        note: ""
      },
      // Aktualne połączenie
      currentCall: { queue: { name: "" }, number: "" },
      // {AxUserAccountElement}
      currentUser: null,
      currentStatus: { Name: "", AuxName: "", Duration: "00:00:00" },
      tabVisible: {
        statusList: false,
        dialpad: false,
        smsPanel: false,
        comboItems: false, // Rozwinięte/zwinięte combo z listą calli
        chatBox: false,
        breakSession: false,
        shiftSession: false,
        editRecord: false
      },
      mainTabVisible: {
        crm: false,
        interactions: false,
        preview: false,
        ufp: true,
        ufpMail: false,
        ufpMailQueue: false,
        statistics: false
      },
      statusBarError: false, // If status bar is showing error message
      terminateReason: 0,
      videoConferenceVisible: false,
      chatConferenceVisible: false,
      contactListVisible: true,

      noActiveCallsVisible: true,
      currentCallVisible: false,
      lastCallResultVisible: false,
      lastCallResponseCode: 0,
      lastCallCancelledByUser: true, // Czy ostatnie połączenie zostało rozłączone ręcznie przez użytkownika
      currentCallTo: "", // Nazwa, użytkownika, lub numer, na który aktulanie dzwonimy, lub z którym aktualnie rozmawiamy głosowo lub webrtc
      voiceMediaAccepted: false, // Czy zostało zaakceptowane udostępnienie mikrofonu
      smsVisible: false,
      statusMessage: "",
      frameVisibility: config.show,
      statuses: [],
      remoteAudio: { src: null, element: null },
      localRingAudio: { src: "", element: null },
      dialNumber: "",
      dialButtons: {
        48: { bg: "off" },
        49: { bg: "off" },
        50: { bg: "off" },
        51: { bg: "off" },
        "51s": { bg: "off" },
        52: { bg: "off" },
        53: { bg: "off" },
        54: { bg: "off" },
        55: { bg: "off" },
        56: { bg: "off" },
        "56s": { bg: "off" },
        57: { bg: "off" }
      },
      contacts: [], // Lista kontaktów (chat) ContactListItem
      // Aktualnie prowadzone rozmowy - okienka, które pojawiają się po lewej stronie
      // { visible : true, contact : {}, project : { id : null}, sessionId : "", messages : [ { sender : "Ja", sentDate : "2014-11-05 12:32", text : "Hello, jest tam ktoś??" }] }
      conversations: [],
      remoteVideos: [],
      smsNumber: "",
      smsContent: "",
      smsCampaignId: null,
      smsProjectId: null,
      localMedia: null, // Lokalne media pobrane przy makeVideoCall
      incomingCallVisible: false, // Czy widoczna jest informacja o połączeniu przychodzącym
      // Type - 0 - SIP, 1 - webrtc (audio+video), callSstate: 0 - transfer, 1 -connected, 2 - disconnected
      incomingCall: { number: "", type: null, message: null, callState: null, internalCallId: null },
      currentCallConnected: false, // Czy aktualnie nawiązywane połączenie jest już połączone - służy do wyświetlenia Dzwoni do, Rozmowa z
      currentVideoCall: {
        sessionId: null,
        state: AxVideoCallState.none,
        project: { id: null, name: null },
        recipients: []
      },
      conversationOpened: false,
      sentSms: [],
      callList: [], // Call[] Lista połączeń, które są widoczne na selekcie połączeń w pasku na górze w trybie agenta
      interactions: [], // Lista interakcji InteractionItem[]
      notAttachedInteractionsLength: 0, // Ilość interackji na liście Data.interactions, które nie mają interakcji dołączonych (np voice dołączony do chat)
      currentInteraction: null, // AxMsgSessionDirectedEvent
      lastInteraction: null,
      activeInteractions: 0, // Ilość aktywnych sessji (dla licznika na liście sesji)
      toolbar: {
        pickup: "ag_pickup.png",
        hangup: "ag_hangup.png",
        pause: "ag_pause.png",
        mute: "ag_mic.png",
        transfer: false,
        blindTransfer: false
      }, // Różne takie pomocnicze do toolbara
      campaignList: [], // Lista kampanii, do których może zadzwonić user AxCampaignListEntry
      campaignGridSort: { predicate: 'value.Name', reverse: false },
      salaryList: [], // lista wynagrodzeń - wykorzystywane w statystykach
      dialpadMenu: { selected: 'campaigns' },
      phone: null, // AxUserPhone
      sClient: null, // AxSessionServerClient
      mClient: null, // AxMediaServerClient
      callInCampaign: false, // Checkbox czy dzwonić w ramach kampanii
      forceDdiNumber: false,  // Checkbox czy wymusić własny numer prezentacji
      sendDtmf: false, // Send dtmf checkbox on diaplad
      shiftTimeDatePicker: null, // Date picker object for shift time
      shiftTimeClockPicker: null, // Time picker object for shift time
      li18n: li18n,
      autoNavigateToInteraction: true,
      closeAllMainButThis: null,
      phoneVisible: false,
      remoteVideoOverlayVisible: false,
      dragdrop: { currentDraggedItem: null },
      crmAddress: "",
      ufpAddress: "",
      ufpMailAddress: "",
      ufpMailQueueAddress: "",
      sessionCrmFrameAddress: "",
      miniSettingsVisible: false,
      appModules: [],
      lang: "en",
      refreshFrame: false,
      menuVisible: false,
      activeMenuItem: null,
      accesToken: "",
      refreshToken: "",
      searchCustomerVisible: false,
      searchFileVisible: false,
      searchFolderVisible: false,
      acceptSessionInProgress: null,
      callHistory: [],
      logoutTriggeredByUser: false,
      downloadTriggeredByUser: false
    };

    return Object.create(dt, {
      activeMenuItemName: {
        get: function () {
          return this.activeMenuItem !== null ? this.activeMenuItem.name : "";
        }
      }
    })
  }

  app.factory('Data', ['CONFIG', Data]);

  function SystemVariables() {
    return {
      RecordingBrowser_Simplified_Filters: undefined,
      Campaigns_Dialable_Number: undefined,
      // This should arrive from server, but buggy service not send this value if
      // value is not present in database, so set default value to false
      DisableNoCampaignManualCalling: { Value: '1' }, // Duck typing here is ax.AxEnvironmentLib.Collections.AxSystemVarDataElement
      RecordManualCallsWithoutCampaignId: undefined,
      RecordTransferredCallsWithoutCampaignId: undefined,
      PasswordChangeInterval: undefined,
      PasswordMinlLength: undefined,
      PasswordDigitsAndLetters: undefined,
      PasswordBigAndSmallLetters: undefined,
      Red_SessionOrdinator: undefined,
      ChatFilesRepositoryRoot: undefined,
      PhraseDetectorLanguage: undefined,
      DefaultCampaignRecordingPath: undefined,
      ChatCampaignsFilesRepositoryRoot: undefined,
      UsePermissionsToGroups: undefined,
      MaxMultisessions: undefined,
      AllowDirectStationCalling: undefined,
      GlobalCPSLimit: undefined,
      SmsPadAccount: undefined,
      SmsPadEnabled: undefined
    };

  }

  app.factory('SystemVariables', SystemVariables);

  // To pozwala nam na kontrolowane odpalenie całej aplikacji z zewnątrz
  function WebAgent() {
    this.axwl = e.WebLib.init({
      log: {
        level: "OFF",
        //worker: logWorker
      }
    });
  }

  WebAgent.prototype = {

    version,


    /**
     * Initialize webagent application
     * @param {Object} c
     */
    init: function (c) {

      window.name = "AxWebCommunications";

      if ("log" in c && "app" in c.log && "level" in c.log.app) {
        log.setLevel(log4javascript.Level[c.log.app.level]);
        // Global var
        consoleAppender.setThreshold(log4javascript.Level[c.log.app.level]);
      }

      log.info("Initializing UF WebCommunications v" + this.version);

      if (typeof c.proto === "undefined") {
        c.proto = "wss";
      }

      // Set weblib loglevel
      if (typeof c.log !== "undefined" && typeof c.log.weblib !== "undefined") {

        if ("level" in c.log.weblib !== undefined) {

          var axLogger = log4javascript.getLogger("WebLib");
          axLogger.setLevel(log4javascript.Level[c.log.weblib.level]);
          var axConsoleAppender = new log4javascript.BrowserConsoleAppender();

          axConsoleAppender.setThreshold(log4javascript.Level[c.log.weblib.level]);

          axLogger.addAppender(axConsoleAppender);

          this.axwl.setLogger(axLogger);
        }
      }

      // Set default station log level
      if ("log" in c) {

        if (("station" in c.log) === false) {
          c.log.station = { level: "OFF" };
        } else if (typeof c.log.station === "object" && c.log.station !== null) {
          if (("level" in c.log.station) === false) {
            c.log.station.level = "OFF";
          }
        } else {
          throw "config.log is not an object or is null";
        }

      }

      // Set application language
      var lng = getCookie("ax-webagent-lang");
      if (!lng) {
        if ("defaultLang" in c) {
          lng = c.defaultLang;
        } else {
          lng = "en";
        }
      }

      if (!(lng in i18n)) {
        lng = "en";
      }

      // Musimy jakoś przetransportować ustawiony język, a nie mamy tutaj jeszcze dostępu do Data
      c.currentLang = lng;
      li18n = i18n[lng];

      document.cookie = "ax-webagent-lang=" + lng + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";

      if (typeof c.eventListener === "undefined") {
        c.eventListener = function () {
        };
      }

      if (typeof c.templateDir === "undefined") {
        c.templateDir = 'src/templates';
      }

      if (typeof c.mode === "undefined") {
        c.mode = "agent";
      }

      if (typeof c.rejectHuntSessionTimeout === "undefined") {
        c.rejectHuntSessionTimeout = 20000;
      }

      // vPhone default configuration
      if ("vPhone" in c === false) {
        c.vPhone = {};
      }
      c.vPhone.iceTimeout = "iceTimeout" in c.vPhone ? c.vPhone.iceTimeout : 1500;
      c.vPhone.relayOnly = "relayOnly" in c.vPhone ? c.vPhone.relayOnly : false;
      c.vPhone.removeHostCandidates = "removeHostCandidates" ? c.vPhone.removeHostCandidates : false;
      c.vPhone.removeIPv6Candidates = "removeIPv6Candidates" ? c.vPhone.removeIPv6Candidates : false;

      // Notfications default configuration
      if ("notifications" in c === false) {
        c.notifications = {
          showNotifications: "mnimimized"
        }
      } else {
        if ("showNotifications" in c.notifications === false) {
          c.notifications.showNotifications = "mnimimized";
        }
      }

      // Tabs - find properly tabs order and active tab
      (function (tabs) {
        var defaultOrder = ['crm', 'answers', 'hints', 'receivedFiles', 'survey'],
          order = tabs.order || [];

        // Find diff and concat with rest of values
        order = order.concat(difference(defaultOrder, order));

        // Find ative tab and set order
        tabs.active = order.indexOf(tabs.active) !== -1
          ? tabs.active : order[0];

        tabs.order = order;

        /**
         * Creates an array of values not included in the other given array.
         * The order of result values are determined by the first array.
         * @param {Array} array1 The array to inspect.
         * @param {Array} array2 The array to exclude.
         * @returns {Array} Returns the new array of filtered values.
         */
        function difference(array1, array2) {
          return array1.filter(function (value) {
            return array2.indexOf(value) === -1;
          });
        }

      })(c.tabs = c.tabs || {});

      // Media proxy default configuration
      if ("mediaProxy" in c === false) {

        c.mediaProxy = {
          enabled: false,
          serverFromDomain: true,
          address: "/mediaproxy",
          mediaServers: []
        }

      } else {

        if ("enabled" in c.mediaProxy === false) {
          c.mediaProxy.enabled = false;
        }

        if ("serverFromDomain" in c.mediaProxy === false) {
          c.mediaProxy.serverFromDomain = true;
        }

        if ("address" in c.mediaProxy === false) {
          c.mediaProxy.address = "/mediaproxy";
        }

        if ("mediaServers" in c.mediaProxy === false) {
          c.mediaProxy.mediaServers = [];
        }

      }

      // UFP
      if (c.ufp) {
        const ufpConfig = c.ufp;
        console.log(ufpConfig);

        if ( "customActionAddress" in ufpConfig === false ) {
          ufpConfig.customActionAddress = "Account/ExecCustomAction";
        }

        if ( "customCustomerSearchOnPhoneClick" in ufpConfig === false ) {
          ufpConfig.customCustomerSearchOnPhoneClick = false;
        }

        if  ("customCustomerSearchActionIdent" in ufpConfig === false ) {
          ufpConfig.customCustomerSearchActionIdent = ""
        }

        if  ("assignSessionToCustomerWhenCustomFound" in ufpConfig === false ) {
          ufpConfig.assignSessionToCustomerWhenCustomFound = false;
        }

      }

      app.constant('CONFIG', c);
      app.constant('WL', this.axwl);

      // Create an instance of AxSessionServerClient
      var sClient = new ax.SessionLib.AxSessionServerClient(this.axwl);
      app.value('sClient', sClient);

      // Create an instance of AxMediaServerClient
      var mClient = new ax.AxMediaLib.AxMediaServerClient(this.axwl);
      app.value('mClient', mClient);

      // Reserve container for user phone (will be instatiated later, after login)
      // We need to pack phone object to container object becouse of... angular :)
      app.value('userPhone', { phone: null });

      // Reserve container for chat client (will be instatiated later, after login)
      // We need to pack chat client object to container object becouse of... angular :)j
      app.value('chatClient', { client: null });

      if (typeof Notification !== "undefined") {
        if (Notification.permission === 'denied' || Notification.permission === 'default') {
          Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              var n = new Notification("Notifications activated", { icon: '/assets/images/favicon.png' });
              setTimeout(n.close.bind(n), 4000);
            }
          });
        }
      }

      angular.bootstrap(mainFrame, ["ax.WebCommunications"]);
    }
  };

  e.WebAgent = new WebAgent();

  /**
   * Helper tworzący elementy VIDEO
   * Lekarstwo na buga w angularze, który nie potrafi poprawnie obsłużyć src w ng-repeat z elementami video
   * @param {object} src media stream
   * @param {number} userId
   * @param {string} userDescr
   * @return {object}   {video,element}
   */
  function createVideoElement(src, userId, userDescr) {

    // <div>
    //   <video></video>
    //   <div>Czekam na strumień</div>
    //   <div>UserName</div>
    // </div>

    var container = document.createElement('div');
    container.className = "v-participant";
    container.setAttribute("data-userId", userId);
    var bg = document.createElement('div');
    var txt = document.createTextNode("Czekam na strumień AV");
    bg.appendChild(txt);
    var nVideo = document.createElement("video");
    nVideo.autoplay = true;
    nVideo.controls = false;
    nVideo.src = src;
    container.appendChild(nVideo);
    container.appendChild(bg);
    var user = document.createElement('div');
    if (typeof userDescr !== "undefined" && userDescr !== null) {
      user.appendChild(document.createTextNode(userDescr));
    }
    container.appendChild(user);
    return { video: nVideo, element: container };
  }

  // TODO: Zrobić jaką klasę, której obiekt zwracam, a nie na anonimowych obiektach
  // A może w ogóle jakiś manager tych obiektów video jako klasa...???

  /**
   * Wyszukuje element wideo z podanym userId
   * @param {number} userId
   * @return {object} {element,video}
   */
  function findVideoElement(userId) {

    var videoContainer = document.getElementById("ax-video-container");
    var vidElems = videoContainer.getElementsByTagName("div");

    for (var i = 0; i < vidElems.length; i++) {
      var elem = vidElems[i];
      if (elem.getAttribute("data-userId") == userId) {
        return { element: elem, video: elem.getElementsByTagName("video")[0] };
      }
    }
    return null;
  }

  app.run(
    // Funkcja uruchamiająca ma za zadanie utworzenie w locie całego widgeta aplikacji
    // To pozwala nam na insert aplikacji na dowolną stronę, bez ingerowania w jej kod html
    ['$http',
      '$rootScope',
      '$compile',
      'Data',
      'CONFIG',
      'Sender',
      'WL',
      '$ngRedux',
      'RootReducers',
      'RootEpics',
      'UfpService',
      'StatisticHelperService',
      function (
        $http,
        $rootScope,
        $compile,
        Data,
        CONFIG,
        Sender,
        WL,
        $ngRedux,
        RootReducers,
        RootEpics,
        UfpService,
        StatisticHelperService) {
        log.info("UF WebCommunications starting application");

        window.addEventListener('unload', function () {
          if (Data.logoutTriggeredByUser) {
            return;
          }

          UfpService.logout(true);
        }, false);

        window.addEventListener('beforeunload', function (event) {
          if (CONFIG.disableOnUnloadWarning || Data.logoutTriggeredByUser || Data.downloadTriggeredByUser) {
            Data.downloadTriggeredByUser = false;
            return;
          }

          event.returnValue = li18n.messages.beforeUnload;
          return li18n.messages.beforeUnload;
        });

        StatisticHelperService.changeLanguage(li18n.statistics);

        // Tworzy aplikację
        function createApplication() {
          $ngRedux.replaceReducer(RootReducers.reducer);
          epicMiddleware.run(RootEpics.epic);

          Data.version = WebAgent.prototype.version;
          Data.lang = CONFIG.currentLang;

          log.debug("Creating main application frame");
          // Tworzę główny kontener aplikacji
          var appFrame = document.createElement('div');
          appFrame.setAttribute("ng-controller", "MainController");
          appFrame.setAttribute("ng-show", "data.frameVisibility");
          appFrame.setAttribute("axstart", "");
          appFrame.setAttribute("ax-hostapi", "");
          appFrame.setAttribute("data-ax", "ExtentWebAgent");

          // Uwaga! Tutaj nazwa template musi być w pojedyczych uszach, bo dyrektywa ng-include przyjmuje jako argument
          // expression, więc musimy określić, że jest to string
          appFrame.setAttribute("ng-include", "'" + CONFIG.templateDir + '/' + "AxWebAgent.html'");

          var linkFn = $compile(appFrame);
          var element = linkFn($rootScope);

          angular.element(document.body).append(element);
          // Do globala, zeby można sie było odwołać przy inicjalizacji aplikacji
          mainFrame = element;

          Data.frameVisibility = true;

          if (typeof CONFIG.integrationProxy !== "undefined") {

            CONFIG.integrationProxy.addEventListener('message', function (m) {

              var msg = m.data;
              // Typy akcji
              // action :
              // - send_sms
              // - send_chat
              // - sip_call
              // - webrtc_call
              // - send_email
              // - paste_link
              // { action : "", recipient : { id : 0, name : "", surname : "", phoneNumber : }, project : { id : 0, name : "" }, message : { content : "" }}
              if (msg.action === "send_sms") {

                showFrame();
                $rootScope.$apply(function () {
                  Data.contactListVisible = false;
                  Data.dialpadVisible = false;
                  Data.smsVisible = true;
                  Data.smsNumber = msg.recipient.phoneNumber;
                  Data.smsProjectId = msg.project.id;

                });

              } else if (msg.action === "send_chat") {

                showFrame();
                openFrame();
                log.debug("Opening conversation window for contact id: " + msg.recipient.id);
                $rootScope.$apply(function () {

                  Data.contactListVisible = true;
                  Data.dialpadVisible = false;
                  Data.smsVisible = false;

                  Data.conversationOpened = true;
                  Data.videoConferenceVisible = false;
                  Data.chatConferenceVisible = true;
                  // Do nagłówka pakujemy imię i nazwisko usera
                  Data.currentChat.user.name = msg.recipient.name + ' ' + msg.recipient.surname;
                  Data.currentChat.user.id = msg.recipient.id;

                  // Sprawdzamy czy okno istnieje
                  // Jeżeli tak, to przełączmy mu visibility, a wszystkie inne wyłączamy
                  var found = false;
                  for (var i in Data.conversations) {
                    var currentConversation = Data.conversations[i];
                    currentConversation.visible = false;
                    if (currentConversation.contact.UserId == msg.recipient.id) {
                      found = true;
                      currentConversation.visible = true;
                      Data.currentChat.project.name = msg.project.name;
                      Data.currentChat.project.id = msg.project.id;
                      // Naapisujemy id projektu w znalezionej konwersjacji, ponieważ mogła być wcześniej bez projektu, albo w ramach innego projektu
                      currentConversation.project.id = msg.project.id;
                      currentConversation.project.name = msg.project.name;
                    }
                  }

                  // Jeżeli nie, to dodajemy element
                  if (found === false) {
                    // Fejkujemy obiekt kontaktu
                    var cont = {
                      UserId: msg.recipient.id,
                      UserAccount: {
                        value: {
                          Name: msg.recipient.name,
                          Surname: msg.recipient.surname
                        }
                      }
                    };

                    Data.conversations.push({
                      visible: true,
                      contact: cont,
                      session: { id: WL.guid(true) },
                      project: { id: msg.project.id, name: msg.project.name },
                      messages: []
                    });
                    Data.currentChat.project.name = msg.project.name;
                  }

                });

              } else if (msg.action === "sip_call") {

                $rootScope.$apply(function () {
                  Data.lastCallCancelledByUser = false;
                  Data.currentCallTo = msg.recipient.name + ' ' + msg.recipient.surname;
                  Data.currentCall.queue.name = msg.project.name;
                  Data.currentCall.number = msg.recipient.phoneNumber;
                  Data.currentCallVisible = true;
                  Data.noActiveCallsVisible = false;
                });
                showFrame();
                // Składamy numer do dzwonienia w ramach projektu
                var num = 'P_' + msg.project.id + '_' + msg.recipient.phoneNumber;

                // Wysyłamy info do usera, że będziemy do niego dzwonić :)
                // TODO: Tylko do celów prezentacyjnych docelowo to wyleci
                var tempPromise = Sender.tempDataMessage(Data.currentUser.Id, msg.recipient.id, msg.project.id, msg.project.name, 0, "");
                tempPromise.then(function () {
                  //SIP.makeCall(num);
                }, function () {
                  log.debug("Nie udało się wysłać informacji o połączeniu");
                });

              } else if (msg.action === "webrtc_call") {

                $rootScope.$apply(function () {
                  if (makeVideoCall) {
                    showFrame();
                    // Ustawiamy wyświetlanie do kogo dzwoni
                    Data.currentCallTo = msg.recipient.name + ' ' + msg.recipient.surname;
                    Data.currentCallVisible = true;
                    Data.noActiveCallsVisible = false;

                    // Fejkujemy obiekt kontaktu
                    var contact = {
                      value: {
                        UserAccount: {
                          value: {
                            Id: msg.recipient.id,
                            Name: msg.recipient.name,
                            Surname: msg.recipient.surname
                          }
                        }
                      }
                    };
                    makeVideoCall(contact, { id: msg.project.id, name: msg.project.name });
                  }
                });
              } else if (msg.action === "send_email") {
                // TODO
              } else if (msg.action == "paste_link") {

                $rootScope.$apply(function () {
                  // Wklejamy link do aktualnego czatu
                  var link = '<a href="' + msg.link.src + '" target="' + msg.link.target + '">' + msg.link.content + '</a>';
                  Data.chatToSend = Data.chatToSend + link;

                });
              }


            }, false);

          }

          window.addEventListener("resize", function () {
            $rootScope.$broadcast("layoutResize", {});
          });

          log.info("UF WebCommunications started");

        }

        createApplication();
      }]
  );

  // Binduje event listenera do message okna - do komunikacji z API
  app.directive("axHostapi", [
    'CONFIG',
    'Data',
    'SessionService',
    'SessionHelperService',
    'sClient',
    'userPhone',
    'DialpadData',
    'SystemVariables',
    'LoginService',
    'FileService',
    'WL',
    'SessionClient',
    'StatisticHelperService',
    '$http',
    AxHostApi
  ]);

  function AxHostApi(config,
                     Data,
                     SessionService,
                     SessionHelperService,
                     sClient,
                     userPhone,
                     DialpadData,
                     SystemVariables,
                     LoginService,
                     FileService,
                     WL,
                     SessionClient) {

    function sendResponse(originalMessage, responseMessage) {
      if (typeof originalMessage.source !== "undefined") {
        originalMessage.source.postMessage(JSON.stringify(responseMessage), '*');
      } else {
        log.warn("[AxHostApi.sendResponse] originalMessage.source is undefined");
      }
    }

    /**
     * Jeżeli sessionId w requeście jest puste, to
     * sprawdzamy, czy jest w atrybucie ramki, z której przyszedł request
     * Jeżeli nie ma, to ustawiamy, na current sessionJezeli nie ma current session, to reject, bo nie wiadomo dla której sesji wysłać event
     * @param {Object} eventParams
     * @param {Object} frameElement event source frame
     */
    function getSessionId(eventParams, frameElement) {

      var sessionId = null;

      if (!eventParams.sessionId) {

        if (frameElement.hasAttribute("ax-session-id")) {
          sessionId = frameElement.getAttribute("ax-session-id");
        } else {
          // Jeżeli nie ma session id w atrybucie ramki, z której przyszło zapytanie
          // to ustawiamy na currentInteraction
          if (Data.currentInteraction && Data.currentInteraction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {
            sessionId = Data.currentInteraction.session.Session.SessionId;
          }
        }

      } else {
        sessionId = eventParams.sessionId;
      }

      return sessionId;

    }

    return {
      restrict: 'A',
      link: function ($scope, $element, $attrs) {

        window.addEventListener("message", function (msg) {

          var resToSend,
            m,
            sessionId,
            resToSend, mLog = JSON.stringify(msg.data),
            eventParams;

          log.debug("API: Received Message", mLog);

          if (("enableApi" in config) == false || ("enableApi" in config && config.enableApi === false)) {

            log.warn("API is disabled");
            return;

          }

          /**
           * @type {ax.AxCommonLib.ApiMessage}
           */
          try {
            m = JSON.parse(msg.data);
            eventParams = m.params;
          } catch (e) {
            log.error("API: Cant parse received message", mLog);
            return;
          }

          if (m.command === "getSession") {

            var interactionItem = null;

            sessionId = m.params.sessionId;
            if (sessionId === null) {
              interactionItem = Data.currentInteraction;
            } else {
              interactionItem = SessionService.getSessionById(sessionId);
            }

            if (interactionItem !== null) {


              if (interactionItem.session.Session.BaseMessageObject) {

                // Change BigInteger to string
                /*interactionItem.session.Session.BaseMessageObject.Attachements = interactionItem.session.Session.BaseMessageObject.Attachements.map(function (val) {

                                    val.Size = val.Size.toString();
                                    return val;

                                });*/
              }

              resToSend = new ax.AxCommonLib.ApiMessage("getSessionResponse", {
                session: interactionItem.session.Session,
                err: null
              }, m.requestId);
            } else {

              resToSend = new ax.AxCommonLib.ApiMessage("getSessionResponse", {
                session: null,
                err: 1
              }, m.requestId);

            }
            sendResponse(msg, resToSend);

          } else if (m.command === "endSessionWithoutResponse") {
            sessionId = m.params.sessionId;
            log.debug("[API] Ending session without response. Session id: " + sessionId);
            sClient.sendSessionMessage({ sessionId: sessionId, message: null }).then(function (res) {
              var resToSend = new ax.AxCommonLib.ApiMessage("endSessionWithoutResponseResponse", {
                result: res.Result,
                err: null
              }, m.requestId);
              sendResponse(msg, resToSend);
            }).catch(function (res) {
              var resToSend;
              if (res.Result) {
                resToSend = new ax.AxCommonLib.ApiMessage("endSessionWithoutResponseResponse", {
                  result: res.Result,
                  err: true
                }, m.requestId);
              } else {
                resToSend = new ax.AxCommonLib.ApiMessage("endSessionWithoutResponseResponse", {
                  result: null,
                  err: res
                }, m.requestId);
              }
              sendResponse(msg, resToSend);
            });

          } else if (m.command === "closeSession") {

            sessionId = getSessionId(m.params, msg.source.frameElement);

            if (!sessionId) {
              resToSend = new ax.AxCommonLib.ApiMessage("closeSessionResponse", {
                result: 1,
                err: "SessionId not found in neither request, frame source nor current session"
              }, m.requestId);
              sendResponse(msg, resToSend);
              return;
            }

            log.debug("[API] Closing session. Session id: " + sessionId);
            return;
            var sessionToClose = SessionService.getSessionById(sessionId);

            if (sessionToClose) {

              if (sessionToClose.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail ||
                SessionHelperService.canCloseSession(sessionId)) {

                if (sessionToClose.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {

                  WL.closeEmailSession({ sessionId: sessionId }).then(function (response) {

                    SessionHelperService.closeSession(sessionId);
                    resToSend = new ax.AxCommonLib.ApiMessage("closeSessionResponse", {
                      result: 0,
                      err: null
                    }, m.requestId);
                    sendResponse(msg, resToSend);

                  }).catch(function (err) {

                    if (err instanceof ax.SessionLib.AxMessages.Messages.AxMsgCloseMessageSessionResponse) {
                      resToSend = new ax.AxCommonLib.ApiMessage("closeSessionResponse", {
                        result: 2,
                        err: true,
                        errMessage: "Error response from server code: " + err.Result
                      }, m.requestId);
                    } else {
                      resToSend = new ax.AxCommonLib.ApiMessage("closeSessionResponse", {
                        result: 3,
                        err: true,
                        errMessage: "Failed to send request to server"
                      }, m.requestId);
                    }

                    sendResponse(msg, resToSend);

                  });


                } else {

                  SessionHelperService.closeSession(sessionId, true);

                  resToSend = new ax.AxCommonLib.ApiMessage("closeSessionResponse", {
                    result: 0,
                    err: null
                  }, m.requestId);

                }

              } else {
                resToSend = new ax.AxCommonLib.ApiMessage("closeSessionResponse", {
                  result: 5,
                  err: true,
                  errMessage: "Can't close ongoing session"
                }, m.requestId);
              }


            } else {

              resToSend = new ax.AxCommonLib.ApiMessage("closeSessionResponse", {
                result: 1,
                err: true,
                errMessage: "Session not found"
              }, m.requestId);
              sendResponse(msg, resToSend);

            }

          } else if (m.command === "setSessionCustomerContext") {

            $scope.setSessionCustomerContext({
              sessionId: m.params.sessionId,
              customerId: m.params.contactCustomerId,
              contactId: m.params.contactId
            }).then(function () {
              resToSend = new ax.AxCommonLib.ApiMessage("setSessionCustomerContextResponse", {
                result: 0,
                err: null
              }, m.requestId);
              Data.searchCustomerVisible = false;
              sendResponse(msg, resToSend);
            }).catch(function (err) {
              log.error("[API] set session param error", err);
              resToSend = new ax.AxCommonLib.ApiMessage("setSessionCustomerContextResponse", {
                result: 1,
                err: true,
                errMessage: "Can't set session param"
              }, m.requestId);
              Data.searchCustomerVisible = false;
              sendResponse(msg, resToSend);
            });

          } else if (m.command === "makeCall") {

            var dialN = "";
            if (typeof m.params.number === "string") {
              dialN = m.params.number.replace(/\s/g, '').replace(/\-/g, '');
            }

            Data.dialNumber = dialN;
            DialpadData.contactCustomerId = m.params.contactCustomerId;
            if (m.params.contactCustomerId) {
              Data.callInCampaign = true;
            } else {
              Data.callInCampaign = false;
            }
            DialpadData.contactId = m.params.contactId;
            DialpadData.customerName = m.params.customerName;
            DialpadData.selectedCampaign = m.params.campaignId;

            $scope.makeCall().then(function (res) {
              var resToSend;
              if (res === true) {
                resToSend = new ax.AxCommonLib.ApiMessage("makeCallResponse", {
                  result: 0,
                  err: null
                }, m.requestId);
              } else {
                resToSend = new ax.AxCommonLib.ApiMessage("makeCallResponse", {
                  result: null,
                  err: true
                }, m.requestId);
              }
              sendResponse(msg, resToSend);
            }).catch(function (err) {
              resToSend = new ax.AxCommonLib.ApiMessage("makeCallResponse", {
                result: null,
                err: true
              }, m.requestId);
              sendResponse(msg, resToSend);
            });

          } else if (m.command === "openDialpad") {

            log.debug("[API] Opening dialpad with params", m.params);

            log.debug(userPhone.phone);
            if (userPhone.phone === null) {
              var resToSend = new ax.AxCommonLib.ApiMessage("openDialpadResponse", {
                result: 1,
                err: true,
                errMessage: "Station not assigned. Can't open dialpad"
              }, m.requestId);
              sendResponse(msg, resToSend);
              return;
            }

            var dialN = "";
            if (typeof m.params.number === "string") {
              dialN = m.params.number.replace(/\s/g, '').replace(/\-/g, '');
            }

            $scope.$apply(function () {

              Data.dialNumber = dialN;
              DialpadData.contactCustomerId = m.params.contactCustomerId;
              DialpadData.contactId = m.params.contactId;
              DialpadData.customerName = m.params.customerName;

              if (m.params.campaignId !== null) {
                Data.callInCampaign = true;
                DialpadData.selectedCampaign = m.params.campaignId;
              }

            });

            $scope.closeAllButThis('dialpad');
            $scope.toggleFetchContacts();
            $scope.toggleFetchCampaigns({
              inGroup:null,
              withGroups: true,
              onlyDialable:true,
              inboundWorking: null,
              outboundWorking: null,
              withCampaignParameters: false,
              withUserDialingPossibility: true
            });

          } else if (m.command === "sendEmailEvent") {

            log.debug("[API] Sending email event ");
            var eType = eventParams.type;

            sessionId = getSessionId(eventParams, msg.source.frameElement);

            // Jeżeli, nie ma sessionId, to reject, aktualnie tylko dla eventów sesji
            // eventy kolejki i mailboxa mogą mieć null
            if (!sessionId && eType === ax.SessionLib.AxEmailEventsType.Session) {
              resToSend = new ax.AxCommonLib.ApiMessage("sendEmailEventResponse", {
                result: 1,
                err: true,
                errMessage: "SessionId not found in neither request, frame source nor current session"
              }, m.requestId);
              sendResponse(msg, resToSend);
              return;
            }

            sClient.sendEmailEvent({
              type: eventParams.type,
              eventType: eventParams.eventType,
              userId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
              userStatusUid: Data.currentStatus.UId,
              emailMessageId: eventParams.emailMessageId,
              sessionId: sessionId,
              tag: eventParams.tag
            }).then(function (res) {

              resToSend = new ax.AxCommonLib.ApiMessage("sendEmailEventResponse", {
                result: 0,
                err: null
              }, m.requestId);
              sendResponse(msg, resToSend);

              if (sessionId) {
                var session = SessionService.getSessionById(sessionId),
                  eType = eventParams.eventType,
                  eventTypes = ax.SessionLib.AxEmailEventsEventType;
                if (session !== null) {
                  if (eType === eventTypes.Deleted ||
                    eType === eventTypes.Rejected ||
                    eType === eventTypes.Spam ||
                    eType === eventTypes.Answered ||
                    eType === eventTypes.Processed) {
                    // Set flag
                    session._isEmailReady = true;
                    // Show own content in email frame
                    var emailStatusMsg = "";
                    switch (eType) {
                      case eventTypes.Deleted:
                        emailStatusMsg = li18n.messages.emailRemoved;
                        break;
                      case eventTypes.Rejected:
                        emailStatusMsg = li18n.messages.emailReturnedToQueue;
                        break;
                      case eventTypes.Spam:
                        emailStatusMsg = li18n.messages.emailMarkedAsSpam;
                        break;
                      case eventTypes.Answered:
                        emailStatusMsg = li18n.messages.emailSent;
                        break;
                      case eventTypes.Processed:
                        emailStatusMsg = li18n.messages.emailProcessed;
                        break;
                    }

                    session.data.web.srcDoc = emailStatusTemplate({
                      message: li18n.messages.email + " " + emailStatusMsg
                    });
                  }
                }
              }

            }).catch(function (err) {

              log.error("[API] Sending email event error", err);

              if (err instanceof ax.SessionLib.AxMessages.Messages.AxMsgSendSessionMessageResponse) {
                resToSend = new ax.AxCommonLib.ApiMessage("sendEmailEventResponse", {
                  result: 2,
                  err: true,
                  errMessage: "Error response from server code: " + err.Result
                }, m.requestId);
              } else {
                resToSend = new ax.AxCommonLib.ApiMessage("sendEmailEventResponse", {
                  result: 3,
                  err: true,
                  errMessage: "Failed to send request to server"
                }, m.requestId);
              }

              sendResponse(msg, resToSend);

            });

          } else if (m.command == "shareFiles") {

            log.debug("[API] Sharing files");

            Data.searchFileVisible = false;

            Promise.all(eventParams.files.map(function (file) {

                return LoginService.getAccessToken({
                  refreshToken: Data.refreshToken,
                  tokenType: "download",
                  tokenParams: {
                    file_id: file.id,
                    token_lifetime: 86400 /* Doba */ // TODO: Skąd brać ten lifetime i od czego on powinien być zależny??
                  }
                }).then(function (res) {

                  //var accessToken = "/" + res.accessToken;
                  var accessToken = "?token=" + res.accessToken;

                  var fileType = ax.SessionLib.Collections.Chat.AxChatMessageAttachmentDataType.File;
                  if (file.mimeType.match("image.*")) {
                    fileType = ax.SessionLib.Collections.Chat.AxChatMessageAttachmentDataType.Image;
                  }

                  return SessionClient.sendSessionChatResponseMessage({
                      project: { Id: null, Name: null },
                      sessionId: Data.currentInteraction.session.Session.SessionId,
                      message: "",
                      attachments: [{
                        Payload: file.url + accessToken,
                        Type: fileType,
                        ExternalId: file.id,
                        Name: file.name,
                        Size: file.size
                      }]
                    }
                  ).then(function (response) {

                    return Promise.resolve({
                      res: response,
                      accessToken: accessToken,
                      fileType: fileType
                    });
                  });

                }).then(function (res) {

                  var attachment = new ax.SessionLib.Collections.Chat.AxChatMessageAttachmentDataElement();
                  attachment.Name = file.name;
                  attachment.Payload = file.url;
                  attachment.Type = res.fileType;
                  attachment.Size = file.size;
                  attachment.ExternalId = file.id;

                  return getImagePromiseWrapper(FileService, attachment,
                    ax.SessionLib.Collections.Chat.AxChatMessageType.ChatMessage,
                    Data.sClient.loginResponse.LoginResponseParams.AccountData.Name + ' ' + Data.sClient.loginResponse.LoginResponseParams.AccountData.Surname,
                    moment().format("YYYY-MM-DD HH:mm:ss"), "agent", Data.refreshToken);
                })
              }
            )).then(function (messages) {

              var interMessages = Data.currentInteraction.data.messages;
              interMessages.push.apply(interMessages, messages);

              resToSend = new ax.AxCommonLib.ApiMessage("shareFilesResponse", {
                result: 0,
                err: null
              }, m.requestId);
              sendResponse(msg, resToSend);

            }).catch(function (err) {

              log.error("Send error", err);
              resToSend = new ax.AxCommonLib.ApiMessage("shareFilesResponse", {
                result: null,
                err: true
              }, m.requestId);
              sendResponse(msg, resToSend);

            });

          } else if (m.command === "closeModalDialog") {

            log.debug("[API] Sharing file");
            if (eventParams.id === "folderSearch") {
              Data.searchFolderVisible = false;
            } else if (eventParams.id == "fileSearch") {
              Data.searchFileVisible = false;
            }

          }

        });
      }
    }
  }

  // Dyrektywa startowa - łączy się z serwerem
  app.directive("axstart", ['CONFIG', 'Data', 'WL', 'Receiver', function (config, data, WL, Receiver) {
    return {
      restrict: "A",
      link: function ($scope, element, attrs) {

        // TODO: To jakoś mądrzej pobierać, a nie po idku
        data.remoteAudio.element = document.getElementById('axRemoteAudio');
        data.localRingAudio.element = document.getElementById('axLocalRingAudio');
        data.videoContainer = document.getElementById("ax-video-container");

        WL.on("commessage", Receiver.onMessage);

        data.server = getCookie('server');
        data.login = getCookie('login');

        // Set server to value from config if cookie is empty
        if (config.defaultServerFromDomain === false) {
          if (!data.server && "defaultServer" in config) {
            data.server = config.defaultServer;
          }
        } else {
          // Get server field value from domain
          data.server = window.location.hostname;
        }

      }
    };
  }]);

  /**
   * Send data Service
   * @param {String} config
   * @param {Object} Data
   * @param {ax.WebLib} WL
   * @param {Object} $rootScope
   */
  function Sender(config, Data, WL, sClient, $rootScope) {

    this.login = function () {
      return WL.login(config.login, config.password);
    };

    // Wysyła żądanie pobrania listy userów
    // @param {numeric} GroupId
    // @return {Promise}
    this.getUsersList = function (GroupId) {
      var ret = WL.getUsersList(GroupId);
      return ret;
    };

    // Wysyła żądanie przypisania stacji
    // @param {number} StationId
    // @param {number} AccountId
    this.assignStationRequest = function (StationId, AccountId) {
      log.debug("Sending assign station request");
      var assignStationParams = WL.createAxObject("AxUserAssignStationRequestParams");
      // TODO: A tutaj można by jeszczw w obiekcie dodać metody getSet i wtedy chainingiem można pięknie ustawiać propertisy
      // Wystarczy get i set dodać do definicji obiektów w weblibie:)
      // Zaraz sobie to przećwiczymy... :)
      assignStationParams.value.StationId = StationId;
      assignStationParams.value.AccountId = AccountId;
      var assignStationReq = WL.createAxObject("AxMsgUserAssignStationRequest");
      assignStationReq.value.Params = assignStationParams;
      log.debug(assignStationReq);
      WL.send(assignStationReq);
    };

    /**
     * Wysyła wiadomość czatu do innego usera
     * @param {object} sender
     * @param {object} receiver
     * @param {object} project
     * @param {string} sessionId
     * @param {string} message
     * @return {Promise}
     */
    this.sendInternalChatMessage = function (sender, receiver, project, sessionId, message) {

      return WL.sendInternalChatMessage(sender, receiver, project, sessionId, message);

    };

    /**
     * Wysyła wiadomość sms
     * @param {String} from
     * @param {Object} project { id : id}
     * @param {Object} campaign { id: id}
     * @param {String} to
     * @param {String} content
     * @param {String} accountName
     * @return {Promise}
     */
    this.sendSms = function (from, to, project, campaign, content, accountName) {
      return WL.sendSms(from, to, project, campaign, content, accountName);
    };

    /**
     * Wysyła wiadomość do serwera AxMsgSendServerMessageRequest
     * @param {object} p
     * @return {Promise}
     */
    this.sendServerMessage = function (p) {
      return WL.sendServerMessage(p);
    };

    /**
     * Wysyła żądanie przesłania informacji o połączeniu
     * @param {integer} fromAccountId
     * @param {integer} toAccountId
     * @param {integer} projectId
     * @param {string} projectName
     * @param {integer} campaignId
     * @param {string} campaignName
     * @return {Promise}
     */
    this.tempDataMessage = function (fromAccountId, toAccountId, projectId, projectName, campaignId, campaignName) {
      return WL.tempDataMessage(fromAccountId, toAccountId, projectId, projectName, campaignId, campaignName);
    };

    /**
     * Wysyła logowanie do mediavideo serwera
     * @param {numeric} accountId Id usera, którego logujemy czyli własne
     * @return {Promise}
     */
    this.sendWebRTCAssignStation = function (accountId) {
      return WL.sendWebRTCAssignStation(accountId);
    };

    /**
     * Wysyła żądanie nawiązania połączenia video
     * @param {numeric} recipientId id odbiorcy
     * @param {String} senderName Nazwa sendera
     * @param {String} sdp moje sdp
     * @param {object} project
     * @return {Promise}
     */
    this.makeVideoCall = function (recipientId, senderName, sdp, project) {
      return WL.makeVideoCall(recipientId, senderName, sdp, project);
    };

    /**
     * Dodaje usera do konferencji video
     * @param {number} accountId
     * @param {string} sessionId
     * @return {Promise}
     */
    this.addToVideoConference = function (accountId, sessionId) {
      return WL.addToVideoConference(accountId, sessionId);
    };

    /**
     * Wysyła żądanie odebrania połączenia video
     * @param {String} sessionId - jako guid
     * @param {number} accountId Id konta użytkownika, dla którego odbieramy połączenie
     * @param {String} sdp
     * @return {Promise}
     */
    this.pickupVideoCall = function (sessionId, accountId, sdp) {
      return WL.pickupVideoCall(sessionId, accountId, sdp);
    };

    /**
     * Wysyła żądanie rozłączenia połączenia video
     * @param {String} sessionId Id sesji do rozłączenia jako guid
     * @return {Promise}
     */
    this.hangupVideoCall = function (sessionId) {
      return WL.hangupVideoCall(sessionId);
    };

    /**
     * Wysyła żądanie pobrania listy kampanii
     * @param {integer} inGroup
     * @param {bool} withGroups
     * @param {onlyDialable}
     * @return {promise}
     */
    this.getCampaignList = function (inGroup, withGroups, onlyDialable) {
      return WL.getCampaignList({inGroup, withGroups, onlyDialable});
    };

    /**
     * Sends survey page
     * @param {Object} p Parameters
     * @param {String} p.sessionId Session GUID
     * @param {String} p.formId Form GUID
     * @param {Array.<ax.AxSurveysLib.ServerSessions.AxSurveyFieldContainer>} p.fields Fields
     * @return {external:Promise}
     */
    this.sendSurveyPage = function (p) {

      return WL.sendSurveyPage(p).then(function (res) {

        var unpacked = WL.unpackObject(res);

        if (unpacked.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {
          return Promise.resolve(unpacked);
        } else {
          return Promise.reject(unpacked);
        }

      }, function (err) {
        return Promise.reject(err);
      });

    };

    /**
     * Sends request for back survey page
     * @function
     * @param {Object} p
     * @param {String} p.sessionId
     * @param {String} p.formId
     */
    this.surveySessionBack = function (p) {

      return WL.surveySessionBack(p).then(function (res) {

        var unpacked = WL.unpackObject(res);

        if (unpacked.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {
          return Promise.resolve(unpacked);
        } else {
          return Promise.reject(unpacked);
        }
      }, function (err) {
        return Promise.reject(err);
      });

    };

    this.surveySessionEnd = function (p) {
      var sPromise = WL.surveySessionEnd(p);
      sPromise.then(function (res) {
        if (res.value.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {
          return Promise.resolve(res);
        } else {
          return Promise.reject(res);
        }
      }, function (err) {
        return Promise.reject(err);
      });
      return sPromise;
    };

    this.sendSurveyPageAction = function (p) {
      var sPromise = WL.sendSurveyPageAction(p);
      sPromise.then(function (res) {
        if (res.value.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {
          return Promise.resolve(res);
        } else {
          return Promise.reject(res);
        }
      }, function (err) {
        return Promise.reject(err);
      });
      return sPromise;
    };

    this.getExternalData = function (p) {
      var sPromise = WL.getExternalData(p);
      sPromise.then(function (res) {
        return Promise.resolve(res);
      }, function (err) {
        return Promise.reject(err);
      });
      return sPromise;
    };

    this.getRecordHistory = function (p) {
      var sPromise = WL.getRecordHistory(p);
      sPromise.then(function (res) {
        return Promise.resolve(res);
      }, function (err) {
        return Promise.reject(err);
      });
      return sPromise;
    };

    this.createNewRecord = function (p) {
      var sPromise = WL.createNewRecord(p);
      sPromise.then(function (res) {
        if (res && res.value && res.value.Record) {
          res.value.Record = WL.unpackObject(res.value.Record);
          return Promise.resolve(res);
        } else {
          return Promise.reject(res);
        }
      }, function (err) {
        return Promise.reject(err);
      });
      return sPromise;
    };

    this.modifyDataRecordPhone = function (p) {

      return WL.modifyDataRecordPhone(p).then(function (res) {
        return Promise.resolve(res);
      }, function (err) {
        return Promise.reject(err);
      });

    };

    this.modifyDataRecord = function (p) {
      var sPromise = WL.modifyDataRecord(p);
      sPromise.then(function (res) {
        return Promise.resolve(res);
      }, function (err) {
        return Promise.reject(err);
      });
      return sPromise;
    };

  }

  app.service('Sender', ["CONFIG", "Data", "WL", "sClient", "$rootScope", Sender]);

  var sentLogoutReq = false;
  // TODO: A może receiver powinien być jako worker?
  // Serwis obsługujący dane przychodzące
  function Receiver(config, Data, Sender, WL, SessionService, TimerService, userPhone, sClient, $rootScope) {

    function updateStatus(status) {
      $rootScope.$apply(function () {
        Data.statusMessage = status;
      });
    }

    this.onMessage = function (evt) {

      log.debug("Callback on message", evt);

      var m = WL.unpackObject(evt.message);

      log.debug("[WA.Receiver.onMessage] Unpacked", m);

      m = m || evt.message;

      if (m instanceof ax.AxCommonLib.AxSerialization.AxObject) {

      } else {

        if (typeof m.className !== "undefined") {
          var msg = m.value;
          if (m.className === "AxMsgUserAssignStationResponse") { //

            var StationData = m.value.StationData.value;
            // Jeżeli udało nam się przypisać stację poprawnie, to coś tam możemy zrobić
            if (StationData.Result === 0) {
              log.debug("Station assigned");
            } else {
              log.warn("Station not assigned result: " + StationData.Result);
            }

          } else if (m.className === "AxMsgWebRtcAlertingEvent") {

            // Otwieramy ramkę komunikatora
            openFrame();
            Data.conversationOpened = true;

            Data.currentVideoCall.state = AxVideoCallState.initiated;
            // Wykonanie zewnętrznego listenera np dla pokazania info o przychodzącym połączeniu w systemie zewnętrznym
            config.eventListener({ type: "new_video_call" });

            $rootScope.$apply(function () {

              // TODO: Na razie id sesji sobie tutaj zapisuję, może się to gdzieś przeniesie
              Data.currentVideoCall.sessionId = m.value.SessionId;
              Data.currentVideoCall.project.id = m.value.ProjectId;
              Data.currentVideoCall.project.name = m.value.ProjectName;

              // Szukamy na liśce kontaktów usera, żeby wyświetlić kto dzwoni
              // TODO: Teraz bierzemy pierwszego z brzegu participanta i szukamy na naszej liście gościa
              // Ale docelowo dane do wyświetlenia powinniśmy brać z dodatkowego pola originator
              // a nazywy ludzików z pola Name z eventa, które będzie ustawiane przez serwer
              var l = Data.contacts.length;
              var found = null;
              for (var i = 0; i < l; i++) {
                var contact = Data.contacts[i];
                if (contact.value.UserId == m.value.Participants[0].value.AccountId) {
                  found = contact;
                  break;
                }
              }

              // Włączam info o połączeniu przychodzącym
              if (found) {

                Data.incomingCall.number = contact.value.UserAccount.value.Name + ' ' + contact.value.UserAccount.value.Surname;
                Data.currentVideoCall.recipients.push({
                  id: contact.value.UserAccount.value.Id,
                  name: contact.value.UserAccount.value.Name,
                  surname: contact.value.UserAccount.value.Surname
                });

              } else {
                Data.incomingCall.number = li18n.messages.unknown;
                Data.currentVideoCall.recipients.push({
                  id: null,
                  name: li18n.messages.unknown,
                  surname: li18n.messages.unknown
                });
              }
              Data.localRingAudio.element.pause();
              Data.localRingAudio.element.src = '/assets/sounds/incoming.wav';
              Data.localRingAudio.element.play().then(function (p) {
                log.trace("[WebCommunications.AxMsgWebRtcAlertingEvent] Playing incoming sound started");
              }).catch(function (err) {
                log.trace("[WebCommunications.AxMsgWebRtcAlertingEvent] Playing incoming sound interrupted", err);
              });
              Data.incomingCallVisible = true;
              Data.incomingCall.type = 1;
              Data.incomingCall.message = m;
              // Żeby się nie pluło komunikatem o zaakceptowanie mikrofonu
              // bo tutaj kolejność jest trochę inna
              Data.voiceMediaAccepted = true;

            });

          } else if (m.className === "AxMsgWebRtcConnectedEvent") {
            log.debug("Received AxMsgWebRtcConnectedEvent");

            // Ustawiam, że connected i wyłączam dzwonek
            $rootScope.$apply(function () {
              Data.currentCallConnected = true;
              Data.localRingAudio.element.pause();
            });

            // Tworzymy element video dla dołączonego usera
            // Znajduję na liście userów, kto dzwoni
            var l = Data.contacts.length;
            var found = null;
            for (var i = 0; i < l; i++) {
              var contact = Data.contacts[i];
              if (contact.value.UserId == m.value.Participant.value.AccountId) {
                found = contact;
                break;
              }
            }

            var callFrom = found !== null ? found.value.UserAccount.value.Name + ' ' + found.value.UserAccount.value.Surname : li18n.messages.unknown;
            var remoteVideo = findVideoElement(m.value.Participant.value.AccountId);
            if (remoteVideo == null) {
              var remoteVideo = createVideoElement(null, m.value.Participant.value.AccountId, callFrom);
              Data.videoContainer.appendChild(remoteVideo.element);
            }

            // Tworzę peer connection, do odbioru danych od usera, do którego dzwoniłem, lub który się podłączył
            pc = new RTCPeerConnection({ iceServers: config.rtcConfiguration.iceServers }, mock.video.options);

            pc.onaddstream = function (e) {
              log.debug("Remote stream added");
              var vid = findVideoElement(m.value.Participant.value.AccountId);
              if (vid !== null) {
                log.debug("Dodaję stream do elementu video dla usera " + m.value.Participant.value.AccountId);
                vid.video.src = window.URL.createObjectURL(e.stream);
                Data.currentVideoCall.state = AxVideoCallState.remoteStreamAdded;
              }
            };

            pc.onremovestream = function (e) {
              log.debug("Remote stream removed");
              log.debug(e);
            };

            /***/
            function sendPickupVideoCall() {

              log.debug("Sending pickupVideoCall");
              log.debug("All candidates for remote endpoint are set. Sending pickup video call");
              var pickupVideoCallPromise = Sender.pickupVideoCall(m.value.SessionId, m.value.Participant.value.AccountId, pc.localDescription.sdp);
              // Then stąd powinien polecieć na zewnątrz, bo po co zagnieżdzać..
              pickupVideoCallPromise.then(function (res) {

                log.debug("Response z odebrania videocalla");
                log.debug(res.value.Sdp);

                var remoteDescr = new RTCSessionDescription({ type: "answer", sdp: res.value.Sdp });
                pc.setRemoteDescription(remoteDescr, function () {
                  log.debug("Ustawiłem remote description 789");

                  log.debug(pc.getRemoteStreams());
                  log.debug(pc.getRemoteStreams()[0].getAudioTracks());
                  log.debug(pc.getRemoteStreams()[0].getVideoTracks());

                }, function (err) {
                  log.debug("Wyjebało się ustawianie remote description");
                  log.error(err);
                });

              }, function (err) {
                log.debug("Pickup call response error");
              });

            }

            // onICECandidate
            var iceCandidatePromise = new Promise(function (resolve, reject) {

              log.debug("Ice candidate function");
              var ended = false;
              pc.onicecandidate = function (e) {
                log.debug("Ice candidate for remote endpoint");
                log.debug(e);
                // Jeżeli był kandydat, to czekamy
                if (e.candidate !== null) {
                  ended = false;
                  return;
                } else { // Jeżeli kandydat był nullem, to znaczy, że zostały wygenerowane wszystkie
                  log.debug("Ice candidate for remote endpoint is null. Resolving promise");
                  resolve();
                }

              };

            });

            // TODO: W sumie to te funkcje powinny być definiowane gdzieś na zewnątrz, a nie inline
            // Wtedy całość będzie działała szybciej
            iceCandidatePromise.then(sendPickupVideoCall, // Cała oferta, gotowa, możemy ją ustawić
              function (e) {
                log.error("Candidate generation for remote endpoint failed");
                log.error(e);
              });

            pc.createOffer(function (desc) {
              log.debug("Offer for remote endpoint created. Setting local description");

              log.debug("Creating offer for remote endpoint");
              log.debug(desc);

              // Po wywołaniu tej funkcji zaczynają się generować ICEcandidate
              pc.setLocalDescription(desc, function () {
                log.debug("Local description set succesfully for remote endpoint");
              }, function (err) {
                log.debug("Error creating local description");
                log.debug(err);
              });

            }, function (err) {
              log.debug("Create offer in connected event failed");
              log.debug(err);
            }, { offerToReceiveAudio: true, offerToReceiveVideo: true });


          } else if (m.className == "AxMsgWebRtcDisconnectedEvent") {

            // Usuwamy video usera
            var el = findVideoElement(m.value.Participant.value.AccountId);
            if (el !== null) {
              el.element.parentNode.removeChild(el.element);
            }


            // Usuwamy go z tablicy peerConnection
            var found = null;
            for (var i = 0; i < peerConnections.length; i++) {
              var current = peerConnections[i];
              if (current.accountId == m.value.Participant.value.AccountId) {
                found = current;
                break;
              }
            }

            log.debug(peerConnections);

            // Jeżeli tutaj jest jeszcze tylko jeden, przed usunięciem to wyłączam kamerkę
            // Jest tak dlatego, że jeżeli było to połączenia przychodzące, to nie ma w ogóle połączenia w peerConnection
            // są taylko w tablicy
            if (peerConnections.length == 1 && found !== null) {
              var lM = found.connection.getLocalStreams();
              if (lM.length > 0) {
                lM[0].stop();
              }
            }

            peerConnections.splice(i, 1);

            log.debug(peerConnections);

            Data.localRingAudio.element.pause();

            if (peerConnections.length == 0) {

              $rootScope.$apply(function () {
                Data.incomingCallVisible = false;
                Data.noActiveCallsVisible = true;
                Data.currentCallVisible = false;
                Data.lastCallResultVisible = false;
                Data.chatConferenceVisible = false;
                Data.videoConferenceVisible = true;
                Data.currentVideoCall.sessionId = null;
              });


              // Wyłączamy kamerkę
              if (peerConnection !== null) {
                var localMedia = peerConnection.getLocalStreams();
                if (localMedia.length > 0) {
                  localMedia[0].stop();
                }
                // Usuwamy połączenie
                peerConnection = null;

              }

              $rootScope.$apply(function () {
                Data.currentVideoCall.sessionId = null;
                Data.currentVideoCall.state = AxVideoCallState.disconnected;

                // Żeby w nagłówku nie pokazywało
                Data.currentChat.user.name = "";
                Data.currentChat.project.name = "";
                Data.currentVideoCall.recipients = [];
                Data.currentVideoCall.project.id = null;
                Data.currentVideoCall.project.name = null;

              });

            }

          } else if (m.className == "AxMsgUniversalFailure") {
            log.warn("[webagent] Universal failure received");
          } else {
            log.debug("[webagent] Unknown message received");
          }

        } else {
          log.debug("[webagent] Message is undefined");
        }
      }
    };
  };

  app.service("Receiver", ["CONFIG", "Data", "Sender", "WL", "SessionService", "TimerService", "userPhone", "sClient", "$rootScope", Receiver]);

  // Serwis połączeń telefonicznych - wykonuje operacje na kolekcji połączeń
  function CallService(config, Data, WL, $rootScope) {

    /**
     * Wyszukuje call po internalCallId (case insensitive).
     * String podany w parametrze jest traktowany jako guid bez separatorów
     * Wewnętrznie funkcja przy porównaniu usuwa separatory myślnika z id połączenia z tablicu calli i wykonuje toLowerCase na porównywanym id
     * jak również toLowerCase na przekazanym parametrze
     * @param {string} internalCallId
     * @return {Call}
     */
    this.findByInternalCallId = function (internalCallId) {
      var call;
      for (var i in Data.callList) {
        call = Data.callList[i];
        if (call.internalCallId !== null && internalCallId !== null) {

          if (call.internalCallId.toLowerCase().replace(/-/g, "") === internalCallId.toLowerCase()) {
            return call;
          }
        }
      }
      return null;

    };

  }

  app.service('CallService', ['CONFIG', 'Data', 'WL', '$rootScope', CallService]);

  // Serwis sesji - wykonuje operacje na sesjach
  function SessionService(config, Data, WL, $rootScope) {

    var that = this;
    // Tymczasowo "zrejectowane" sesje (dla hunt grupy)
    var temporaryRejectedSessions = [];

    /**
     * Itemka na tymczasowo zrejectowanej sesji
     * @param {InteractionItem} interactionItem
     * @constructor
     */
    function TemporaryRejectedSession(interactionItem, timeout) {

      var th = this;
      this.timeoutIndex = null;
      this.interactionItem = interactionItem;

      if (timeout > 0) {

        this.timeoutIndex = setTimeout(function () {

          var sessionFromRejects = that.removeSessionFromTemporaryRejectedList(interactionItem.session.Session.SessionId);
          if (sessionFromRejects !== null) {
            that.addSessionToList(sessionFromRejects.interactionItem);
            $rootScope.$broadcast("interactionAddedToList", { interaction: sessionFromRejects.interactionItem });
          }

        }, timeout);

      }

      this.clearTimeout = function () {

        if (th.timeoutIndex !== null) {
          clearTimeout(th.timeoutIndex);
        }
      }

    }

    /**
     * Tymczasowo "rejectuje" sesję, przenosząc ją do tablicy tymczasowo zrejectowanych
     * @param {InteractionItem} sessionToReject
     * @param {Number} timeout - Timeout to automatically restore session from rejected. If timeout is 0. Session will not be restored automatically
     */
    this.temporaryRejectSession = function (sessionToReject, timeout) {

      var rejectedSession = this.getSessionById(sessionToReject.session.Session.SessionId);
      this.removeSessionFromList(sessionToReject.session.Session.SessionId);
      if (rejectedSession !== null) {
        temporaryRejectedSessions.push(new TemporaryRejectedSession(rejectedSession, timeout));

      } else {
        temporaryRejectedSessions.push(new TemporaryRejectedSession(sessionToReject, timeout));
      }

    };

    /**
     * Pobiera wszysktie sesje, które są aktualnie w statusie do akceptacji
     * @returns {Array.<InteractionItem}
     */
    this.getAcceptableSessions = function () {

      return Data.interactions.filter(function (inter) {

        var actions = inter.session.Actions;
        if (actions === null) {
          return false;
        }

        var l = actions.length;
        for (var i = 0; i < l; i++) {
          if (actions[i] instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession) {
            return true;
          }
        }

      });

    };

    /**
     * Tymczasowo rejectuje wszystkie sesje, które są aktualnie do akceptacji
     * @param {Number} timeout - Timeout to automatically restore session from rejected
     * @param {String} omitSessionId
     */
    this.temporaryRejectAcceptableSessions = function (timeout, omitSessionId) {

      this.getAcceptableSessions().forEach(function (acceptableSession) {

        if ((omitSessionId && acceptableSession.session.Session.SessionId !== omitSessionId) || !omitSessionId) {
          that.temporaryRejectSession(acceptableSession, timeout);
        }

      });

    };

    /**
     * Przywraca wszystkie tymczasowo zrejectowane sesje z listy zrejectowanych
     */
    this.restoreRejectedSessions = function () {

      temporaryRejectedSessions.map(function (rejectedSession) {
        rejectedSession.clearTimeout();
        return rejectedSession.interactionItem;
      }).forEach(function (session) {
        Data.interactions.unshift(session);
      });

      temporaryRejectedSessions.length = 0;

    };

    /**
     * Usuwa interakcję z listy tymczasowo zrejectowanych i zwraca usuniętą interackcję
     * @param {String} sessionId
     * @return {InteractionItem}
     */
    this.removeSessionFromTemporaryRejectedList = function (sessionId) {

      var found = false,
        current = null,
        l = temporaryRejectedSessions.length;
      for (var i = 0; i < l; i++) {
        current = temporaryRejectedSessions[i];
        if (current.interactionItem.session.Session.SessionId === sessionId) {

          // Clear timeout, becouse don't want to resume after removed from list
          current.clearTimeout();
          found = true;
          break;
        }
      }

      temporaryRejectedSessions.splice(i, 1);

      return current;
    };

    /**
     * Returns temporaty rejectedSessions size
     * @return {Number}
     */
    this.getTemporaryRejectedSessionsLength = function () {
      return temporaryRejectedSessions.length;
    };

    /**
     * Wyszukuje sesję na liście tymczasowo zrejectowanych po jej identyfikatorze. Jeżeli sesja nie zostanie znaleziona, to zwraca null
     * @param {string} sessionId
     * @return {InteractionItem}
     */
    this.getTemporaryRejectedSession = function (sessionId) {
      var found = false, current;
      for (var i in temporaryRejectedSessions) {
        current = temporaryRejectedSessions[i];
        if (current.session.Session.SessionId === sessionId) {
          return current;
        }
      }

      return null;
    };

    this.stopAllTemporaryRejectedSessionsTimeouts = function () {

      temporaryRejectedSessions.forEach(function (sess) {
        sess.clearTimeout();
      });

    };

    /**
     * Wyszukuje sesję na liście po jej identyfikatorze. Jeżeli sesja nie zostanie znaleziona, to zwraca null
     * @param {string} sessionId
     * @return {InteractionItem}
     */
    this.getSessionById = function (sessionId) {
      var found = false;
      for (var i in Data.interactions) {
        if (Data.interactions[i].session.Session.SessionId === sessionId) {
          return Data.interactions[i];
        }
      }

      return null;
    };

    /**
     * Ustawia stan sesji w kolekcji sesji
     * @param {string} sid
     * @param {integer} state
     */
    this.setSessionState = function (sid, state) {

      Data.interactions = Data.interactions.map(function (interaction) {
        if (interaction.session.value.Session.value.SessionId === sid) {
          interaction.session.value.Session.value.SessionState = state;
        }
        return interaction;
      })

    };

    /**
     * Dodaje interację na początek listy
     * Jeżeli interakcja już istnieje na liście, to ją zamienia na tym samym miejscu, na którym się znajduje
     * @param {SessionItem} session
     */
    this.addSessionToList = function (sess) {

      var found = false;
      Data.interactions = Data.interactions.map(function (interaction) {
        if (interaction.session.Session.SessionId === sess.session.Session.SessionId) {
          found = true;
          // Remember shift params
          var shiftingParams = interaction.session.ShiftingParams;
          var eventShiftingParams = sess.session.ShiftingParams;

          // Jeżeli stan sesji to zakończony, to nie nadpisuję danych rekordu bo rekord przychodzi null,
          // a jest później potrzebny, więc bez sensu się go pozbywać albo trzymać gdzieś na boku
          // Nie nadpisujemy też ShiftingParams bo też przychodzi jako null
          if (sess.session.Session.SessionState === ax.SessionLib.AxSessionState.EndedByRemoteSide
            || sess.session.Session.SessionState === ax.SessionLib.AxSessionState.EndedByUser
            || sess.session.Session.SessionState === ax.SessionLib.AxSessionState.RejectedByRemoteSide
            || sess.session.Session.SessionState === ax.SessionLib.AxSessionState.RejectedByUser
            || sess.session.Session.SessionState === ax.SessionLib.AxSessionState.Transferred
            || sess.session.Session.SessionState === ax.SessionLib.AxSessionState.InitiationFailed) {

            sess.session.DataRecord = interaction.session.DataRecord;
            sess.session.ShiftingParams = interaction.session.ShiftingParams;
            sess.session.AutoAvailable = interaction.session.AutoAvailable;
            interaction.session = sess.session;
            sess.data.web.breakSession = interaction.data.web.breakSession;
            sess.data.web.shifted = interaction.data.web.shifted;
            sess.data.web.editRecordVisible = interaction.data.web.editRecordVisible;
            sess.data.web.shiftSessionVisible = interaction.data.web.shiftSessionVisible;
            sess.data.web.breakSessionVisible = interaction.data.web.breakSessionVisible;
            sess.data.web.autoCloseForm = interaction.data.web.autoCloseForm;
            sess.data.web.customerUrl = interaction.data.web.customerUrl;
            sess.data.web.sessionTabWidth = interaction.data.web.sessionTabWidth;
            sess.data.web.forceAssignSessionToCustomer = interaction.data.web.forceAssignSessionToCustomer;
            sess.data.recording = interaction.data.recording;
            sess.data.messages = interaction.data.messages;
            sess.data.chatAnswers = interaction.data.chatAnswers;
            sess.data.receivedFiles = interaction.data.receivedFiles;
            sess.data.availableFiles = interaction.data.availableFiles;
            sess.data.chatTextInput = interaction.data.chatTextInput;
            sess.data.videoCallActive = interaction.data.videoCallActive;
            sess.data.videoCall = interaction.data.videoCall;

          } else if ((sess.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat || sess.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial)
            && sess.session.Session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser) {
            interaction.session = sess.session;
            sess.data.chatAnswers = interaction.data.chatAnswers;
            sess.data.chatAnswersTags = interaction.data.chatAnswersTags;
          } else {
            interaction.session = sess.session;
          }

          if (!eventShiftingParams && shiftingParams) { //jesli sa parametry z utworzone np. przy wyborze rekordu, a z eventa puste to zostawiamy
            interaction.session.ShiftingParams = shiftingParams;
          }

          // Generalnie przyjmujemy, ze jeżeli jest juz survey, albo aktualnie się ładuje to go nie nadpisuję
          var isSurveyEmpty = interaction.surveySession === null || typeof interaction.surveySession == "undefined";
          var isSurveyLoading = interaction.isSurveyLoading;
          if (isSurveyEmpty && isSurveyLoading === false) {
            interaction.surveySession = sess.surveySession;
          }

          interaction.data = sess.data;

          // Tego nie nadpisujemy, bo na interakcji na liście leci timer, który poźniej chcemy zatrzymać
          // Jeżeli zostało by nadpisane, to stracimy referencję do timera i nie będzie się go dało zatrzymać
          //interaction.timeIntervalId = sess.timeIntervalId;
          interaction.startTime = sess.startTime;
          interaction.duration = sess.duration;
          interaction.queueWaitTime = sess.queueWaitTime
        }
        return interaction;
      });

      // Jeżeli sesja nie została znaleziona na liście to ją dodajemy
      if (found === false) {
        Data.interactions.unshift(sess);
        if (sess.session.Session.AttachToSessionId === null) {
          Data.notAttachedInteractionsLength += 1;
        }
      }

    };

    /**
     * Removes interaction from list.
     * Returns object with two properties index and interaction
     * If session was not found returns null
     * @param {string} sessionId
     * @retrun {Object}
     */
    this.removeSessionFromList = function (sessionId) {

      var found = false,
        l = Data.interactions.length;
      for (var i = 0; i < l; i++) {
        if (Data.interactions[i].session.Session.SessionId === sessionId) {
          found = true;
          break;
        }
      }

      if (found === true && Data.interactions[i].session.Session.AttachToSessionId === null) {
        Data.notAttachedInteractionsLength -= 1;
      }

      if (found === true) {

        return {
          index: i,
          interaction: Data.interactions.splice(i, 1)[0]
        };
      }

      return null;
    };

    /**
     * Wyszukuje sesję po identyfikatorze skojarzonego połączenia
     * @param {string} transferCallId
     */
    this.findByTransferCallId = function (transferCallId) {

      var inter = null;
      for (var i in Data.interactions) {
        inter = Data.interactions[i];
        if (inter.session.TransferCallId !== null && transferCallId !== null) {
          if (inter.session.TransferCallId.toLowerCase().replace(/-/g, "") === transferCallId.toLowerCase().replace(/-/g, "")) {
            return inter;
          }
        }
      }

      return inter;
    };

    /**
     * Wyszukuje siesję po identyfikatorze połączenia video
     * @param {String} videoCallId
     */
    this.findByVideoCallId = function (videoCallId) {

      var retInter = null;
      var filtered = Data.interactions.filter(function (inter) {

        var videoCall = inter.data.videoCall;
        return videoCall !== null && videoCall.id === videoCallId;

      });

      if (filtered.length > 0) {
        retInter = filtered[0];
      }
      return retInter;

    };

    /**
     * Get first record matching predicate or null if no record was found
     * @param {Function} fnc
     */
    this.getFirstOrNull = function (fnc) {
      var inters = Data.interactions,
        l = inters.length,
        i, current;
      for (i = 0; i < l; i++) {
        current = inters[i];
        if (fnc(current)) {
          return current;
        }

      }
      return null;
    };

  }

  app.service('SessionService', ['CONFIG', 'Data', 'WL', '$rootScope', SessionService]);

  // Serwis helper dla obsługi sessji i surveya
  function SessionHelperService(config, Sender, Data, WL, SessionService, userPhone, sClient, $rootScope) {

    var that = this;

    function removeCurrentSession(sessionId) {

      var sid;
      if (sessionId) {
        sid = sessionId;
      } else {
        sid = Data.currentInteraction.session.Session.SessionId;
      }

      // Usunięcie z listy sesji
      var idx = SessionService.removeSessionFromList(sid).index;

      // Zamiana aktywnej sesji na kolejną, która była na liście, a jeżeli nie było następnej, to na pierwszą
      // A jeżeli nie było już żadnej to na null
      Data.currentInteraction = (function (interactions) {

        var notAcceptable = that.findFirstNotAcceptableInteraction({
          startIndex: idx,
          reverse: false,
          acceptable: false
        });

        if (notAcceptable !== null) {
          return interactions[notAcceptable.index];
        } else {

          notAcceptable = that.findFirstNotAcceptableInteraction({
            startIndex: idx,
            reverse: true,
            acceptable: false
          });

          if (notAcceptable !== null) {
            return interactions[notAcceptable.index];
          }

          return null;
        }

      })(Data.interactions);

      if (Data.currentInteraction) {

        // Jeżeli interakcja, na którą zostało wykonane przełączenie jest chatem
        // to resetujemy liczniki nieprzeczytanych
        if (Data.currentInteraction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat
          || Data.currentInteraction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial) {
          Data.currentInteraction.data.unreadChatCounter = 0;

        }

        // TODO: Po kij tutaj są swa eventy w sumie na to samo??
        // Emitujemy event, żeby menu wiedziało, że nastapiła zmiana
        $rootScope.$broadcast("activeInteractionChanged", { interaction: Data.currentInteraction });
        // I emitujemy event, żeby zostały przeliczone wysokości elementów w kontrolce chatu
        // oraz, żeby ufp wiedziało, że ma przeliczyć wysokość maila
        $rootScope.$broadcast("interactionEnter", { interaction: Data.currentInteraction });

      }

    }

    var pageActionResponse = function (res, surveySession) {
      var that = this;
      $rootScope.$apply(function () {
        if (res.value.Actions) {
          res.value.Actions.map(function (el) {
            if (el.className === "AxSurveyNextAction") {
              that.next(el.value.Force);
            }
            if (el.className === "AxSurveyPropertyChangeAction" && el.value.Changes) {
              if (surveySession && surveySession.CurrentForm && surveySession.CurrentForm.Fields) {
                surveySession.CurrentForm.Fields.map(function (f) {
                  el.value.Changes.map(function (change) {
                    if (f.Field.FieldId === change.value.FieldId) {
                      f.Field[change.value.Property] = change.value.Value;
                    }
                  });
                });
              }
            }
            if (el.className === "AxSurveySetValueAction" && el.value.Changes) {
              if (surveySession && surveySession.CurrentForm && surveySession.CurrentForm.Fields) {
                surveySession.CurrentForm.Fields.map(function (f) {
                  el.value.Changes.map(function (change) {
                    if (f.Field.FieldId === change.value.FieldId) {
                      f.setValue(change.value.Value);
                    }
                  });
                });
              }
            }
          });
        }
      });
    };

    /**
     * Returns first interaction from interaction list or null if there is no any interactions
     * @return {?InteractionItem}
     */
    this.getFirstInteraction = function () {

      var interactions = Data.interactions,
        l = interactions.length;
      if (l > 0) {
        return interactions[0];
      }

      return null;

    };

    this.getFirstActiveOrEndedNotAttachedInteraction = function () {

      var l = Data.interactions.length, cInter;

      if (Data.interactions.length > 0) {
        for (var i = 0; i < l; i++) {
          cInter = Data.interactions[i];
          if (cInter.session.Session.SessionState == ax.SessionLib.AxSessionState.AcceptedByUser &&
            cInter.session.Session.AttachToSessionId === null
          ) {
            return cInter;
          }
        }

        // If not found any active intraction return first non attached on list
        for (var i = 0; i < l; i++) {
          cInter = Data.interactions[i];
          if (cInter.session.Session.AttachToSessionId === null) {
            return cInter;
          }
        }

      }

      return null;
    };

    this.getFirstActiveOrEndedInteraction = function () {

      var l = Data.interactions.length, cInter;

      if (Data.interactions.length > 0) {
        for (var i = 0; i < l; i++) {
          cInter = Data.interactions[i];
          if (cInter.session.Session.SessionState == ax.SessionLib.AxSessionState.AcceptedByUser) {
            return cInter;
          }
        }

        // If not found any active intraction return first on list
        return Data.interactions[0];

      }

      return null;
    };

    /**
     * Gets first not acceptable interaction
     * If found returns object with two properties index, and interaction, when index is index of found interaction
     * If not found returns null
     * @param {Object} p Parameters object
     * @param {Number} [p.startIndex=0] From what index start searching
     * @param {Boolean} [p.reverse=false] If searching should be in reverse order
     * @param {Boolean} [p.acceptable=false] Search for acceptable interaction instead for non-acceptable
     * @return {Object}
     */
    this.findFirstNotAcceptableInteraction = function (p) {

      var startIndex = typeof p.startIndex === "number" ? p.startIndex : 0;
      var reverse = typeof p.reverse === "boolean" ? p.reverse : false;
      var acceptable = typeof p.acceptable === "boolean" ? p.acceptable : false;

      if (Data.interactions.length === 0
        || (startIndex >= Data.interactions.length && reverse === false)) {
        return null;
      }

      if (startIndex > Data.interactions.length && reverse === true) {
        startIndex = Data.interactions.length;
      }

      var countTo = (function () {

        if (reverse) {
          return 0;
        } else {
          return Data.interactions.length;
        }

      })();

      var ret = (function () {

        for (var i = startIndex; (function (a) {
          if (reverse === false) {
            return a < countTo;
          } else {
            return a > countTo;
          }
        })(i); i = (function (a) {
          if (reverse === false) {
            return ++a;
          } else {
            return --a;
          }
        })(i)) {

          var current = Data.interactions[reverse ? i - 1 : i];

          if ((acceptable && current.isAcceptable())
            || (acceptable === false && (current.isAcceptable() === false || current.isAcceptable() && current.session.Session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser))) {
            return current;
          }

        }
        return null;
      })();

      if (ret !== null) {
        return {
          index: Data.interactions.indexOf(ret),
          interaction: ret
        }
      } else {
        return ret;
      }

    };

    this.getCurrentInteraction = function () {
      if (Data) {
        return Data.currentInteraction;
      }
      return null;
    };

    /**
     * Checks if session can be closed.
     * @param sessionId
     * @return {boolean}
     */
    this.canCloseSession = function (sessionId) {

      var sessionToClose = SessionService.getSessionById(sessionId),
        AxState = ax.SessionLib.AxSessionState,
        isEmailReady = false,
        isCrmReady = false,
        isSurveyReady = false,
        isSessionEnded = false,
        isCallReady = false,
        sState;

      function checkIsSurveyReady(sessionToClose) {
        if (sessionToClose.surveySession) {
          if (sessionToClose.surveySession.Ended === true) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }

      if (sessionToClose !== null) {

        // Session can be closed only when session is ended
        if (sessionToClose.session) {

          sState = sessionToClose.session.Session.SessionState;

          // Email sessions
          // TODO: Intearakcja ma juz gettery do tego, więc można by to wywalić i korzystać bezpośrednio z nich
          if (sessionToClose.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {

            isSurveyReady = checkIsSurveyReady(sessionToClose);

            isEmailReady = sessionToClose.isEmailReady;
            isCrmReady = sessionToClose.isCrmReady;

            return isEmailReady && isCrmReady && isSurveyReady;

          }

          isSessionEnded = sState === AxState.EndedByRemoteSide ||
            sState === AxState.EndedByUser ||
            sState === AxState.Rejected ||
            sState === AxState.RejectedByRemoteSide ||
            sState === AxState.RejectedByUser ||
            sState === AxState.SystemTerminated ||
            sState === AxState.InitiationFailed ||
            sState === AxState.Transferred;

          // Check if there is no associated calls
          if (sessionToClose.session && userPhone && userPhone.phone && userPhone.phone.calls) {

            var associatedCall = userPhone.phone.calls.getCall(sessionToClose.session.TransferCallId);

            // If no associated call
            if (!associatedCall) {

              isCallReady = true;

            } else {
              isCallReady = false;
            }

          } else {
            isCallReady = true;
          }

          isSurveyReady = checkIsSurveyReady(sessionToClose);
          isCrmReady = sessionToClose.isCrmReady;

          return isSessionEnded && isCallReady && isSurveyReady && isCrmReady;

        }

      }

      return false;

    };

    this.canCloseCurrentSession = function () {
      if (Data && Data.currentInteraction) {

        return this.canCloseSession(Data.currentInteraction.session.Session.SessionId);
      }
      return false;
    };

    this.baseSessionIsEnded = function () {
      if (Data && Data.currentInteraction && Data.currentInteraction.session && Data.currentInteraction.session.Session) {
        var session = Data.currentInteraction.session.Session;
        if (session.IsEnded === true
          || session.SessionState === ax.SessionLib.AxSessionState.RedirectError
          || session.SessionState === ax.SessionLib.AxSessionState.RejectedByUser
          || session.SessionState === ax.SessionLib.AxSessionState.Queued) {
          return true;
        }
      }
      return false;
    };

    /**
     * Warning! Use this function only from hostapi.
     * Function not tests surveys et like closeCurrentSession function
     */
    this.closeSession = function (sessionId) {

      var interactionToClose = SessionService.getSessionById(sessionId);

      removeCurrentSession(sessionId);

      if (interactionToClose !== null) {
        // If session was preview session, then return to preview tab
        if (interactionToClose.session.Session.SubType === ax.SessionLib.AxSessionSubtype.PreviewCall) {
          Data.closeAllMainButThis('preview');
        } else { // In other case if there is no interactions, show ufp

          if (Data.interactions && Data.interactions.length === 0) {
            Data.closeAllMainButThis("ufp");
          }

        }
      }

    };

    /**
     * Closes session from argument, or current if argument is null
     * @param sessionToClose
     * @param {Boolean} disableCloseChecking disable
     */
    this.closeCurrentSession = function (sessionToClose, disableCloseChecking) {

      var that = this,
        interactionToClose;
      if (Data && Data.currentInteraction) {
        interactionToClose = Data.currentInteraction;
      }

      if (typeof sessionToClose !== "undefined") {

        if (!disableCloseChecking && this.canCloseSession(sessionToClose.session.Session.SessionId) === false) {
          return;
        }

        interactionToClose = sessionToClose;

      } else {

        if (!disableCloseChecking && this.canCloseCurrentSession() === false) {
          return;
        }
      }

      var subType = null;
      if (interactionToClose && interactionToClose.session) {
        subType = interactionToClose.session.Session.SubType;
      }

      if ( interactionToClose.session.AutoAvailable  ) {

        const avail = Data.statuses.find( s => s.State === ax.SessionLib.AxUserState.Available);
        if ( typeof avail !== "undefined") {
          sClient.setStatusById(avail.StateId, false).catch(err =>
          log.warn("Set status failed", err));
        }
      }

      if (interactionToClose && interactionToClose.surveySession) {
        var surveySession = interactionToClose.surveySession;
        var updateDataFields = [];
        if (interactionToClose.phoneDataFieldsEditCollection) {
          interactionToClose.phoneDataFieldsEditCollection.map(function (p) {
            var value = p.value.Value || p.value.TempValue;
            if (p.value.OldValue !== value || p.value.CloseSet === true) {
              p.value.Value = p.value.TempValue;
              p.value.UserId = sClient.loginResponse.LoginResponseParams.AccountData.Id;
              updateDataFields.push(p);
            }
          });
        }
        if (interactionToClose.dataFieldsEditCollection) {
          interactionToClose.dataFieldsEditCollection.map(function (p) {
            var value = p.value.Value || p.value.TempValue;
            if (p.value.OldValue !== value || p.value.CloseSet === true) {
              p.value.Value = p.value.TempValue;
              p.value.UserId = sClient.loginResponse.LoginResponseParams.AccountData.Id;
              updateDataFields.push(p);
            }
          });
        }
        var spPromise = Sender.surveySessionEnd({
          sessionId: surveySession.SessionId,
          updateDataFields: updateDataFields
        });

        spPromise.then(function (res) {
          if (res.value.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {
            $rootScope.$apply(function () {
              removeCurrentSession(interactionToClose.session.Session.SessionId);

              // If session was preview session, then return to preview tab
              if (subType === ax.SessionLib.AxSessionSubtype.PreviewCall) {
                Data.closeAllMainButThis('preview');
              } else { // In other case if there is no non-acceptable interactions, show ufp

                if (Data.interactions && that.findFirstNotAcceptableInteraction({
                  startIndex: 0,
                  reverse: false,
                  acceptable: false
                }) === null) {
                  Data.closeAllMainButThis("ufp");
                }

              }
            });

          } else {
            // TODO: Co tutaj zrobić jeżeli jest błąd??? Chyba trzeba do statusu po prostu zapisać?
            log.error("Error eding survey session", res);
          }

        }, function (err) {
          //Co tutaj zrobić, jeżeli jest błąd?
          log.error("Error sending survey page", err);
        });
      } else {
        removeCurrentSession(interactionToClose.session.Session.SessionId);
        // If session was preview session, then return to preview tab
        if (subType === ax.SessionLib.AxSessionSubtype.PreviewCall) {
          Data.closeAllMainButThis('preview');
        } else { // In other case if there is no not acceptable interactions, show ufp
          if (Data.interactions && that.findFirstNotAcceptableInteraction({
            startIndex: 0,
            reverse: false,
            acceptable: false
          }) === null) {
            Data.closeAllMainButThis("ufp");
          }

        }

      }

      // If session was email session, then send close event
      if (subType === ax.SessionLib.AxSessionSubtype.InboundEmail) {

        WL.closeEmailSession({ sessionId: interactionToClose.session.Session.SessionId }).then(function () {

          log.debug("[closeCurrentSession] Session closed");
          return sClient.sendEmailEvent({
            type: ax.SessionLib.AxEmailEventsType.Session,
            eventType: ax.SessionLib.AxEmailEventsEventType.Close,
            userId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
            userStatusUid: Data.currentStatus.UId,
            emailMessageId: interactionToClose.session.Session.BaseMessageObject.Id,
            sessionId: interactionToClose.session.Session.SessionId,
            tag: "WebCommunications"
          });

        }).then(function (res) {

          log.debug("[closeCurrentSession] Close email event sent OK");

        }).catch(function (err) {

          log.error("[closeCurrentSession] Sending close email event failed", err);

          if (err instanceof ax.SessionLib.AxMessages.Messages.AxMsgCloseMessageSessionResponse) {
            log.error("[closeCurrentSession] Error response from server code: " + err.Result, err);
          } else {
            log.error("[closeCurrentSession] Failed to send request to server or sth else", err);
          }

        });

      }

    };

    this.canNext = function () {
      if (Data && Data.currentInteraction && Data.currentInteraction.surveySession && Data.currentInteraction.surveySession.CurrentForm && Data.currentInteraction.surveySession.CurrentForm.Fields) {
        var surveySession = Data.currentInteraction.surveySession;
        var fields = Data.currentInteraction.surveySession.CurrentForm.Fields;
        for (var i = 0; i < fields.length; i++) {
          if (fields[i].Field instanceof ax.AxSurveysLib.Fields.AxSurveysFieldEditableBase && fields[i].Validate && fields[i].Validate(fields[i].getValue())) {
            return false;
          }
        }
        return true;
      }
      return false;
    };

    /**
     * Gets external data sources for fields, or from cache
     * Values ar set in iterated fields nad in cache
     * @param {Array} fields
     * @param {Object} cache
     * @return {external:Promise}
     */
    this.getExternalDataSource = function (fields, cache) {

      log.debug("[SessionHelperService.getExternalDataSource] Processing fields", fields);
      var promiseRegistry = [];
      if (fields !== null) {
        fields.forEach(function (f) {
          var externalDataPromise;

          if (f.Field.ExternalDataSourceId) {
            f.Field.Values = [];
            log.debug("[SessionHelperService.getExternalDataSource] Iterated field has external data source. Checking cache", f);
            if ((f.Field.ExternalDataSourceId in cache) === false) {

              log.debug("[SessionHelperService.getExternalDataSource] Data source id: " + f.Field.ExternalDataSourceId + " not found in cache");

              externalDataPromise = WL.getExternalData({

                DataSourceId: f.Field.ExternalDataSourceId

              }).then(function (r) {

                var res = WL.unpackObject(r);

                if (!res.Result) {
                  var dateTable = res.DataTable;

                  cache[f.Field.ExternalDataSourceId] = dateTable;

                  // First - display
                  // Second - value
                  // Third - Result state
                  // Fourth - is intermediate result state
                  f.Field.Values = dateTable.Rows.map(function (row) {

                    /*return {
                                            First: row[0],
                                            Second: row[0],
                                            Third: null,
                                            Fourth: false
                                        };  */

                    var idxD = 0;
                    var idxV = 0;
                    if (f.Field.DisplayName) {
                      idxD = dateTable.Columns.indexOf(f.Field.DisplayPath);
                    }
                    if (f.Field.ValuePath) {
                      idxV = dateTable.Columns.indexOf(f.Field.ValuePath);
                    }

                    if (idxD === -1) {
                      idxD = 0;
                    }

                    if (idxV === -1) {
                      idxV = 0;
                    }

                    return {
                      First: row[idxD],
                      Second: row[idxV],
                      Third: null,
                      Fourth: false
                    };

                  });

                  return Promise.resolve();

                } else {

                  log.error("[SessionHelperService.getExternalDataSource] Error: " + res.Message);
                  return Promise.reject(res);
                }

              }).catch(function (err) {
                log.error("[SessionHelperService.getExternalDataSource] Error caught: ", err);
              });

              promiseRegistry.push(externalDataPromise);
              log.debug("Promise registry:", promiseRegistry);

            } else {
              log.debug("[SessionHelperService.getExternalDataSource] Get external data from cache");

              f.Field.Values = cache[f.Field.ExternalDataSourceId].Rows.map(function (row) {
                return {
                  First: row[0],
                  Second: row[0],
                  Third: null,
                  Fourth: false
                };

              });

            }

          }

        });
      }
      return Promise.all(promiseRegistry);

    };

    this.next = function (force, callback) {

      var that = this;
      if (this.canNext() === false && force !== true) {
        return;
      }

      if (Data && Data.currentInteraction && Data.currentInteraction.surveySession) {
        var interaction = Data.currentInteraction;
        var surveySession = interaction.surveySession;
        interaction.isSurveyLoading = true;

        // Coś tutaj trzeba z loggerem wymyślić chyba...
        var spPromise = Sender.sendSurveyPage({
          sessionId: surveySession.SessionId,
          formId: surveySession.CurrentForm.Form.FormId,
          fields: surveySession.CurrentForm.Fields
        }).then(function (res) {

          if (res.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {

            var fields = res.SessionContainer.CurrentForm.Fields;
            that.getExternalDataSource(fields, interaction.validateExternalDataSources).then(function (extRes) {
              log.debug("[SessionHelperService.next] All external data sources received. Received data sources: " + extRes.length);
            }, function (err) {
              log.debug("[SessionHelperService.next] Error while getting external data", err);
            }).then(function () {
              $rootScope.$apply(function () {
                Data.currentInteraction.surveySession = res.SessionContainer;
                interaction.isSurveyLoading = false;
              });
            });


          } else {
            $rootScope.$apply(function () {
              interaction.isSurveyLoading = false;
            });
            // TODO: Co tutaj zrobić jeżeli jest błąd??? Chyba trzeba do statusu po prostu zapisać?
          }
        }, function (err) {
          //Co tutaj zrobić, jeżeli jest błąd?
          console.error("Error sending survey page", err);
          $rootScope.$apply(function () {
            interaction.isSurveyLoading = false;
          });
        });
      }
    };

    this.back = function (callback) {

      var that = this;
      if (Data && Data.currentInteraction && Data.currentInteraction.surveySession) {

        var interaction = Data.currentInteraction;
        var surveySession = interaction.surveySession;
        interaction.isSurveyLoading = true;

        Sender.surveySessionBack({
          sessionId: surveySession.SessionId,
          formId: surveySession.CurrentForm.Form.FormId
        }).then(function (res) {

          if (res.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {

            // Get values from external data sources
            var fields = res.SessionContainer.CurrentForm.Fields;
            that.getExternalDataSource(fields, interaction.validateExternalDataSources).then(function () {
              log.debug("All external data sources received");
            }, function (err) {
              log.debug("Error while getting external data", err);
            }).then(function () {
              $rootScope.$apply(function () {
                Data.currentInteraction.surveySession = res.SessionContainer;
                interaction.isSurveyLoading = false;
              });
            });

          } else {
            // TODO: Co tutaj zrobić jeżeli jest błąd??? Chyba trzeba do statusu po prostu zapisać?
            $rootScope.$apply(function () {
              interaction.isSurveyLoading = false;
            });
          }
        }, function (err) {
          //Co tutaj zrobić, jeżeli jest błąd?
          console.error("Error sending survey page", err);
          $rootScope.$apply(function () {
            interaction.isSurveyLoading = false;
          });
        });
      }
    };

    this.sendSurveyPageAction = function (type, fieldId, value, shiftResult, terminateResult, record, scriptValueName, level) {
      if (Data && Data.currentInteraction && Data.currentInteraction.surveySession) {
        var surveySession = Data.currentInteraction.surveySession;

        level = typeof level !== "undefined" ? level : null;

        var param = {
          type: type,
          sessionId: surveySession.SessionId,
          formId: surveySession.CurrentForm.Form.FormId,
          answer: null,
          shiftResult: shiftResult,
          terminateResult: terminateResult,
          record: record,
          scriptValueName: scriptValueName,
          level: level
        };
        if (type === ax.AxSurveysLib.AxSurveyActionType.SetValue ||
          type === ax.AxSurveysLib.AxSurveyActionType.FlowButton ||
          type === ax.AxSurveysLib.AxSurveyActionType.SetScriptValue) {
          param.answer = WL.createAxObject("AxSurveyServerFieldAnswer");
          param.answer.value.FieldId = fieldId;
          param.answer.value.Value = value;
        }

        var spPromise = Sender.sendSurveyPageAction(param);
        var scope = this;
        spPromise.then(function (res) {
          if (res.value.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {
            pageActionResponse.call(scope, res, surveySession);
          } else {
            // TODO: Co tutaj zrobić jeżeli jest błąd??? Chyba trzeba do statusu po prostu zapisać?
          }

        }, function (err) {
          //Co tutaj zrobić, jeżeli jest błąd?
          console.error("Error sending survey page", err)
        });
      }
    };

    this.canSendFlowButtonAction = function () {
      if (Data && Data.currentInteraction && Data.currentInteraction.surveySession && Data.currentInteraction.surveySession.CurrentForm && Data.currentInteraction.surveySession.CurrentForm.Fields) {
        var surveySession = Data.currentInteraction.surveySession;
        var fields = Data.currentInteraction.surveySession.CurrentForm.Fields;
        for (var i = 0; i < fields.length; i++) {
          if (fields[i].Field instanceof ax.AxSurveysLib.Fields.AxSurveyFieldFlowButton == false &&
            fields[i].Field instanceof ax.AxSurveysLib.Fields.AxSurveysFieldEditableBase &&
            fields[i].Validate && fields[i].Validate(fields[i].getValue())) {
            return false;
          }
        }
        return true;
      }
      return false;
    };

    this.sendSms = function (accountName, to, body, callback) {
      if (Data && Data.currentInteraction && Data.currentInteraction.additionalParams) {
        var aP = Data.currentInteraction.additionalParams;
        var spPromise = Sender.sendServerMessage({
          AccountName: accountName,
          Destination: to,
          Content: body,
          StoreAtDatabase: true,
          CampaignId: aP.CampaignId,
          ProjectId: aP.ProjectId,
          CampaignRecordId: aP.DataRecordId
        });
        var that = this;
        spPromise.then(function (res) {
          if (res.value.Result === 0/*Success*/) {
            if (callback)
              callback(true);
          } else {
            if (callback)
              callback(false);
          }

        }, function (err) {
          if (callback)
            callback(false);
        });
      }
      else {
        if (callback)
          callback(false);
      }
    };

    this.sendMail = function (accountName, from, to, title, body, sendRenderedAttachments, renderAttachements, attachments, callback) {
      sendRenderedAttachments = sendRenderedAttachments === "True" || sendRenderedAttachments === "true" || sendRenderedAttachments === true;
      if (Data && Data.currentInteraction && Data.currentInteraction.additionalParams) {
        var aP = Data.currentInteraction.additionalParams;
        var namedAttachements = [];
        if (attachments) {
          for (var i = 0; i < attachments.length; i++) {
            var attatment = WL.createAxObject("AxStringPair");
            attatment.value.First = attachments[i].Key;
            attatment.value.Second = attachments[i].Value;
            namedAttachements.push(attatment);
          }
        }
        var attachementsHtmlContent = [];
        /*if (sendRenderedAttachments === true) {
                    for (var i = 0; i < renderAttachements.length; i++) {
                        attachementsHtmlContent.push({
                            Key: renderAttachements[i].Key,
                            Value: renderAttachements[i].Value
                        });

                    }
                }*/
        attachementsHtmlContent = renderAttachements;
        var spPromise = Sender.sendServerMessage({
          AccountName: accountName,
          From: from,
          Destination: to,
          Subject: title,
          Content: body,
          RenderAttachements: sendRenderedAttachments,
          AttachementsHtmlContent: attachementsHtmlContent,
          NamedAttachements: namedAttachements,
          StoreAtDatabase: true,
          CampaignId: aP.CampaignId,
          ProjectId: aP.ProjectId,
          CampaignRecordId: aP.DataRecordId
        });
        var that = this;
        spPromise.then(function (res) {
          if (res.value.Result === 0/*Success*/) {
            if (callback)
              callback(true);
          } else {
            if (callback)
              callback(false);
          }

        }, function (err) {
          if (callback)
            callback(false);
        });
      }
      else {
        if (callback)
          callback(false);
      }
    };

    this.getExternalData = function (dataSourceId, callback) {
      var spPromise = Sender.getExternalData({
        DataSourceId: dataSourceId
      });
      var that = this;
      spPromise.then(function (res) {
        if (!res.value.Message) {
          if (callback)
            callback(res.value);
        } else {
          if (callback)
            callback(res.value);
        }

      }, function (err) {
        if (callback)
          callback(err);
      });
    };

    /**
     * Gets record history
     * @param {Number} UserId
     * @param {Number} CampaignId
     * @param {Number} DataRecordId
     * @param {Function} callback
     * @return {external:Promise}
     */
    this.getRecordHistory = function (userId, campaignId, dataRecordId, callback) {
      var spPromise = Sender.getRecordHistory({
        UserId: userId,
        CampaignId: campaignId,
        DataRecordId: dataRecordId
      });
      var that = this;
      spPromise.then(function (res) {
        if (res && res.value) {
          if (callback)
            callback(res.value);
        }
        else {
          if (callback)
            callback();
        }

      }, function (err) {
        if (callback)
          callback(err);
      });
    };

    this.createNewRecord = function (userId, campaignId, sessionId, importId, ani, callback) {
      var spPromise = Sender.createNewRecord({
        UserId: userId,
        CampaignId: campaignId,
        SessionId: sessionId,
        ImportId: importId,
        Ani: ani
      });
      var that = this;
      spPromise.then(function (res) {
        if (res && res.value && res.value.Record) {
          if (callback)
            callback(res);
        }
        else {
          if (callback)
            callback();
        }

      }, function (err) {
        if (callback)
          callback(err);
      });
    };

    this.deleteDataRecord = function (userId, campaignId, recordId, callback) {
      var spPromise = WL.deleteDataRecord({
        UserId: userId,
        CampaignId: campaignId,
        RecordId: recordId
      });
      var that = this;
      spPromise.then(function (res) {
        if (res && res.value && res.value.Success === true) {
          if (callback)
            callback(res.value);
        }
        else {
          if (callback)
            callback(res.value);
        }

      }, function (err) {
        if (callback)
          callback(err);
      });
    };

    this.modifyDataRecordPhone = function (affectingUserId, callback) {

      if (Data && Data.currentInteraction) {

        var inter = Data.currentInteraction;

        if (inter && inter.phoneDataFieldsEditCollection && inter.phoneDataFieldsEditCollection.length > 0) {
          var params = [];
          var phonesToSave = [];
          inter.phoneDataFieldsEditCollection.map(function (p) {
            var value = p.value.Value || p.value.TempValue;
            if ((p.value.IsEditing === false && p.value.OldValue !== value) || p.value.CloseSet === true) {
              var mp = WL.createAxObject("AxDataRecordPhoneModifyParams");
              mp.value.CampaignId = p.value.CampaignId || -1;
              mp.value.DataRecordId = p.value.DataRecordId;
              mp.value.NewNumber = p.value.Value;
              mp.value.OldNumber = p.value.OldValue;
              mp.value.PhoneName = p.value.Name;
              mp.value.SessionId = p.value.SessionServerSessionId || WL.guidEmpty;
              mp.value.ClosePhone = p.value.IsClosed;
              mp.value.OpenRecordAndResetPhoneTries = p.value.PhoneDataField != null && p.value.PhoneDataField.State == ax.SessionLib.AxDataContactPhoneState.BadData && !p.value.Value;
              params.push(mp);
              phonesToSave.push(p);
            }
          });
          if (params.length > 0 && affectingUserId) {
            Sender.modifyDataRecordPhone({
              Params: params,
              AffectingUserId: affectingUserId
            }).then(function (res) {
              if (res && res.value) {
                if (res.value.Result === ax.SessionLib.AxModifyDataRecordPhoneResult.Success) {
                  phonesToSave.map(function (p) {
                    p.value.OldValue = p.value.Value;
                    if (p.value.PhoneDataField) {
                      if (p.value.IsClosed === true)
                        p.value.PhoneDataField.State = ax.SessionLib.AxDataContactPhoneState.UserClosed;
                      if (p.value.PhoneDataField.State == ax.SessionLib.AxDataContactPhoneState.BadData && !p.value.Value)
                        p.value.PhoneDataField.State = ax.SessionLib.AxDataContactPhoneState.Open;
                    }
                  });
                  if (callback) {
                    callback(res.value.Result);
                  }
                }
                else {
                  if (callback) {
                    callback(res.value.Result);
                  }
                }
              }
              else {
                if (callback) {
                  callback(ax.SessionLib.AxModifyDataRecordPhoneResult.ProviderError);
                }
              }
            }, function (err) {
              if (callback) {
                callback(ax.SessionLib.AxModifyDataRecordPhoneResult.ProviderError);
              }
            });
          } else {

            if (callback) {
              // Ale jeżeli nie zapisałem, bo nic nie zostało zmienione, to wtedy w callbacku nie pójdzie dalej
              // dlatego tutaj trzeba dać sukcess
              //callback(ax.SessionLib.AxModifyDataRecordPhoneResult.InvalidParams);
              callback(ax.SessionLib.AxModifyDataRecordPhoneResult.Success);
            }
          }
        } else {
          if (callback) {
            callback(ax.SessionLib.AxModifyDataRecordPhoneResult.InvalidParams);
          }
        }
      }
    };

    this.modifyDataRecord = function (affectingUserId, callback) {
      if (Data && Data.currentInteraction) {
        var inter = Data.currentInteraction;

        if (inter && inter.dataFieldsEditCollection && inter.dataFieldsEditCollection.length > 0) {
          var params = [];
          var fieldsToSave = [];
          inter.dataFieldsEditCollection.map(function (p) {
            var value = p.value.Value || p.value.TempValue;
            if ((p.value.IsEditing === false && p.value.OldValue !== value) || p.value.CloseSet === true) {
              var mp = WL.createAxObject("AxDataRecordModifyParams");
              mp.value.CampaignId = p.value.CampaignId || -1;
              mp.value.DataRecordId = p.value.DataRecordId;
              mp.value.FieldValue = p.value.Value;
              mp.value.OldValue = p.value.OldValue;
              mp.value.FieldName = p.value.Name;
              mp.value.SessionId = p.value.SessionServerSessionId || WL.guidEmpty;
              mp.value.Type = p.value.Type;
              params.push(mp);
              fieldsToSave.push(p);
            }
          });

          if (params.length > 0 && affectingUserId) {
            Sender.modifyDataRecord({
              Params: params,
              AffectingUserId: affectingUserId
            }).then(function (res) {

              console.log("Zmodyfikowane z sendera", res);

              if (res && res.value) {
                if (res.value.Result === ax.SessionLib.AxModifyDataRecordResult.Success) {
                  fieldsToSave.map(function (f) {
                    f.value.OldValue = f.value.Value;
                  });
                  if (callback) {
                    callback(res.value.Result);
                  }
                }
                else {
                  if (callback) {
                    callback(res.value.Result);
                  }
                }
              }
              else {
                if (callback) {
                  callback(ax.SessionLib.AxModifyDataRecordResult.ProviderError);
                }
              }

            }, function (err) {
              if (callback) {
                callback(ax.SessionLib.AxModifyDataRecordResult.ProviderError);
              }
            });
          } else {
            if (callback) {
              //callback(ax.SessionLib.AxModifyDataRecordResult.InvalidParams);
              callback(ax.SessionLib.AxModifyDataRecordResult.Success);
            }
          }
        }
        else {
          if (callback) {
            callback(ax.SessionLib.AxModifyDataRecordResult.InvalidParams);
          }
        }
      }
    };

    this.terminateSession = function (sessionId, dataRecordId, campaignId, terminateReason, dataRecordPhoneIndex, callback) {
      var terminatePromise = WL.terminateSession({
        sessionId: sessionId,
        dataRecordId: dataRecordId,
        campaignId: campaignId,
        terminateReason: terminateReason,
        dataRecordPhoneIndex: dataRecordPhoneIndex
      });

      terminatePromise.then(function (res) {
        if (res && res.value) {
          log.debug("Session terminated. Result " + res.value.Result);
          if (callback) {
            callback(res.value.Result);
          }
        } else {
          if (callback) {
            callback(ax.SessionLib.AxTerminateRecordRequestResult.ProviderError);
          }
        }
      }, function (err) {
        log.error("Terminate session failed");
        log.error(err);
        if (callback) {
          callback(ax.SessionLib.AxTerminateRecordRequestResult.ProviderError);
        }
      });
    };

    this.shiftSession = function (sessionId, dataRecordId, campaignId, note, tco, forUserId, shiftOn, phoneIndex, callback) {
      var shiftPromise = WL.shiftRecord({
        sessionId: sessionId,
        campaignId: campaignId,
        dataRecordId: dataRecordId,
        note: note,
        tco: tco,
        forUserId: forUserId,
        shiftOn: shiftOn,
        phoneIndex: phoneIndex
      });

      shiftPromise.then(function (res) {
        if (res && res.value) {
          log.debug("Session shifted. Result " + res.value.Result);
          if (callback) {
            callback(res.value.Result);
          }
        } else {
          if (callback) {
            callback(ax.SessionLib.AxTerminateRecordRequestResult.ProviderError);
          }
        }
      }, function (err) {
        log.error("Shift session failed");
        log.error(err);
        if (callback) {
          callback(ax.SessionLib.AxTerminateRecordRequestResult.ProviderError);
        }
      });
    };

    /**
     * Sets session param for specified session
     * @param {Object} params Parameters object
     * @param {ax.SessionLib.AxSessionServerSessionBase} params.session
     * @param {String} params.sessionId Id (guid) of session to set params
     * @param {ax.SessionLib.AxSessionParam} params.param Parameter to set
     * @param {String} [params.paramValue=""] Parameter value to set
     * @param {?Number} [params.campaignId=null] Campaign id
     * @return {external:Promise}
     */
    this.setSessionParamBySession = function (params) {
      return sClient.setSessionParam({
        sessionId: params.session.SessionId,
        param: params.param,
        paramValue: params.paramValue,
        campaignId: params.campaignId
      });
    };

    this.findDataRecord = function (userId, campaignId, phoneNumber, offset, limit, resultAsDataTable, callback) {
      var prom = WL.findDataRecord({
        UserId: userId,
        CampaignId: campaignId,
        PhoneNumber: phoneNumber,
        Offset: offset,
        Limit: limit,
        ResultAsDataTable: resultAsDataTable
      });
      prom.then(function (res) {
          if (res && res.value) {
            if (callback) {
              callback(res.value);
            }
          } else {
            if (callback) {
              callback();
            }
          }
        },
        function (err) {
          log.error(err);
          if (callback) {
            callback(err);
          }
        });
    };

    this.getDataRecord = function (userId, campaignId, dataRecordId, callback) {
      var prom = WL.getDataRecord({
        UserId: userId,
        CampaignId: campaignId,
        DataRecordId: dataRecordId
      });
      prom.then(function (res) {
          if (res && res.value && res.value.Record) {
            res.value.Record = WL.unpackObject(res.value.Record);
            if (callback) {
              callback(res.value);
            }
          } else {
            if (callback) {
              callback(res);
            }
          }
        },
        function (err) {
          log.error(err);
          if (callback) {
            callback(err);
          }
        });
    };

    this.setSessionParam = function (sessionId, param, paramValue, campaignId, callback) {
      var prom = WL.setSessionParam({
        sessionId: sessionId,
        param: param,
        paramValue: paramValue,
        campaignId: campaignId
      });
      prom.then(function (res) {
          if (res && res.value) {
            if (callback) {
              callback(res.value);
            }
          } else {
            if (callback) {
              callback();
            }
          }
        },
        function (err) {
          log.error(err);
          if (callback) {
            callback(err);
          }
        });
    };

    /**
     * Gets recordset for preview campaign
     * @param {Object} p
     * @param {Number} p.campaignId Campaign id to get records for
     * @param {Boolean} [p.releaseRecords=false] if this flag will be set. Command will release already received records
     * @param {ax.SessionLib.Definitions.AxRequestRecordsFilter} [p.filter=null]
     * @return {external:Promise}
     */
    this.getPreviewRecords = function (p) {

      return sClient.getPreviewRecords(p);

    };

    /**
     * Starts preview session
     * @param {Object} p
     * @param {Number} p.campaignId
     * @param {Number} p.recordId
     * @param {Number} [p.phoneIndex=null]
     * @param {Boolean} [p.appendRecordFromDatabase=false]
     * @return {external:Promise}
     */
    this.startPreviewSession = function (p) {
      return sClient.startPreviewSession(p);
    };

    /**
     * Removes preview record from list
     * @param {Number} recordId
     * @return {Boolean}
     */
    this.removePreviewRecord = function (recordId) {
      return sClient.removePreviewRecord(recordId);
    };

    /**
     * Sends response to session chat message
     * @param {Object} p
     * @param {Object} p.project
     * @param {Number} p.project.Id
     * @param {String} p.project.Name
     * @param {String} p.sessionId
     * @param {String} p.message
     * @param {Array} [p.attachments=[]] Attachments
     * @param {ax.SessionLib.Collections.Chat.AxChatMessageType} [p.messageType=0]
     * @param {String} [p.messageTag=""] Message tag
     * @return {external:Promise}
     */
    this.sendSessionChatResponseMessage = function (p) {
      return sClient.sendSessionChatResponseMessage(p);
    };

    /**
     * Finds session associated with given callId
     * Returns null if session is not found
     * @param string callId
     * @return InteractionItem
     */
    this.findSessionByAssociatedCallId = function (callId) {

      var inters = Data.interactions;
      var l = inters.length,
        inter,
        associatedCalls;

      for (var i = 0; i < l; i++) {
        inter = inters[i];
        associatedCalls = inter.session.Session.AssociatedCallObjects;
        if (associatedCalls && typeof associatedCalls[callId] !== "undefined") {
          return inter;
        }
      }

      return null;

    };


    /**
     * Gets sessions attached to session with given sessionId
     * @param string sessionId
     * @return Array<InteractionItem>
     */
    this.getAttachedSessions = function (sessionId) {

      var attachedSessions = Data.interactions.filter(function (inter) {

        if (inter.session.Session.AttachToSessionId === sessionId) {
          return true;
        }

        return false;

      });

      return attachedSessions;

    };

    /**
     * Sets session params and returns
     * @param {Object} p Arguments object
     * @param {String} p.sessionId
     * @param {ax.SessionLib.AxSessionParam.SetAfterSessionStateId} p.param
     * @param {String} p.paramValue
     * @param {String} p.paramValue2
     * @returns {external:Promise}
     */
    this.setSessionParamPromise = function (p) {
      return sClient.setSessionParam({
        sessionId: p.sessionId,
        param: p.param,
        paramValue: p.paramValue,
        paramValue2: p.paramValue2
      });
    }

  }

  app.service('SessionHelperService', ['CONFIG', 'Sender', 'Data', 'WL', 'SessionService', 'userPhone', 'sClient', '$rootScope', SessionHelperService]);

  /**
   * Serwis timerów zajmuje się odliczaniem czasu
   */
  function TimerService(config, Data, WL, SessionService, $rootScope) {

    var lastStateTime = null;
    var stateIntervalId = null;

    function countStateTime() {
      var currentTime = new Date();
      if (Data.currentStatus !== null) {
        $rootScope.$apply(function () {

          Data.currentStatus.Duration = dateDiff(currentTime, lastStateTime).formatted;
        })
      }
    }

    this.startCountStateTime = function () {
      lastStateTime = new Date();
      clearInterval(stateIntervalId);
      stateIntervalId = setInterval(countStateTime, 1000);
    };

    /**
     * Counts iteraction time
     * @param {InteractionItem} interaction
     */
    function countInteractionTime(interaction) {

      var currentTime = new Date();
      $rootScope.$apply(function () {
        interaction.duration = dateDiff(currentTime, interaction.startTime).formatted;
      });

    }

    /**
     * Starts counting iteraction time
     * @param {InteractionItem} interaction
     */
    this.startCountInteractionTime = function (interaction) {

      // Nadpisujemy, żeby liczyc od dbrego momentu
      interaction.startTime = new Date();
      clearInterval(interaction.timeIntervalId);
      interaction.timeIntervalId = setInterval(function () {
        countInteractionTime(interaction);
      }, 1000);
    };

    /**
     * Stops counting interaction time
     * @param {InteractionItem} interaction
     */
    this.stopCountInteractionTime = function (interaction) {
      clearInterval(interaction.timeIntervalId);
    };

    /**
     * Counts down recording pause time
     * @param {InteractionItem} interaction
     */
    function countdownRecordingPauseTime(interaction) {

      $rootScope.$apply(function () {

        var diff = dateDiff(interaction.recordingResumeTime, new Date());
        interaction.recordingDuration = diff.formatted;
        if (diff.interval <= 1000) {
          clearInterval(interaction.recordingTimeIntervalId);
          interaction.data.recording.RecordingState = interaction.lastRecordingState;
          interaction.recordingDuration = null;
        }
      });

    }

    /**
     * Starts counting recording time
     * @param {InteractionItem} interaction
     */
    this.startCountdownRecordingPauseTime = function (interaction) {
      var now = new Date();
      interaction.recordingResumeTime = new Date(now.getTime() + interaction.data.recording.AutoResumeRecordingAfter);
      interaction.recordingTimeIntervalId = setInterval(function () {
        countdownRecordingPauseTime(interaction);
      }, 1000);
    };

    /**
     * Stops counting down recording pause time
     * @param {InteractionItem} interaction
     */
    this.stopCountdownRecordingPauseTime = function (interaction) {
      clearInterval(interaction.recordingTimeIntervalId);
    }

  }

  app.service('TimerService', ['CONFIG', 'Data', 'WL', 'SessionService', '$rootScope', TimerService]);

  app.controller("MainController", ['$scope', '$ngRedux', 'CONFIG',
    'Data',
    'DialpadData',
    'VideoData',
    'PhoneData',
    'SettingsData',
    'SystemVariables', 'Sender', 'WL',
    'SessionService',
    'SessionHelperService',
    'TimerService',
    'CallService',
    'sClient', 'mClient', 'userPhone', 'chatClient', '$rootScope', '$timeout', '$http',
    'UfpService',
    'ApiRequestWrapperService',
    'LoginService',
    'MiniApiService',
    'FileService',
    'SessionClient', 'Errors', 'SearchFolderData', 'SearchFileData', 'SearchCustomerData',
    'NotificationData',
    'NotificationService',
    'MediaProxyClientService',
    'WebRtcBuddyService',
    'VideoService',
    'StatisticHelperService',
    'SurveyService',
    'UtilsService',
    'ComClient',
    'ChangePasswordData',
    'SoundService',
    'SoundType',
    'UFP_LOGIN_ERROR',
    'toggleSound',
    'loadSettingsRequest',
    'ClipboardService',
    function MainController($scope, $ngRedux, config,
                            Data,
                            DialpadData,
                            VideoData,
                            PhoneData,
                            SettingsData,
                            SystemVariables, Sender, WL,
                            SessionService,
                            SessionHelperService,
                            TimerService,
                            CallService,
                            sClient, mClient, userPhone, chatClient, $rootScope, $timeout, $http,
                            UfpService,
                            ApiRequestWrapperService,
                            LoginService,
                            MiniApiService,
                            FileService,
                            SessionClient, Errors, SearchFolderData, SearchFileData, SearchCustomerData,
                            NotificationData,
                            NotificationService,
                            MediaProxyClientService,
                            WebRtcBuddyService,
                            VideoService,
                            StatisticHelperService,
                            SurveyService,
                            UtilsService,
                            ComClient,
                            ChangePasswordData,
                            SoundService,
                            SoundType,
                            UFP_LOGIN_ERROR,
                            toggleSound,
                            loadSettingsRequest,
                            ClipboardService
    ) {
      $scope.data = Data;
      $scope.DialpadData = DialpadData;
      $scope.SystemVariables = SystemVariables;
      $scope.SessionService = SessionService;
      $scope.SearchFolderData = SearchFolderData;
      $scope.SearchFileData = SearchFileData;
      $scope.SearchCustomerData = SearchCustomerData;
      $scope.VideoData = VideoData;
      $scope.StatisticHelperService = StatisticHelperService;
      $scope.PhoneData = PhoneData;
      $scope.ChangePasswordData = ChangePasswordData;
      $scope.SoundType = SoundType;
      $scope.ClipboardService = ClipboardService;
      $scope.SmsPadEnabled = false;
      $scope.SoundService = SoundService;

      var lastStatusBeforeDisconnect = null;

      var SettingsActions = {
        toggleSound: toggleSound
      }
      var unsubscribe = $ngRedux.connect(mapStateToThis, SettingsActions)($scope);
      $scope.$on('$destroy', unsubscribe);

      function mapStateToThis(state) {
        return state;
      }

      function makeGetRtcConfiguration(rtcConfiguration) {

        function getRtcConfiguration() {

          log.debug("[getRtcConfiguration] Trying to get turn data");
          return MiniApiService.getTurnData({ withCredentials: false }).then(function (res) {

            if (res.result === 1) { // Token is not valid

              return ApiRequestWrapperService.wrapRequest({
                requestFnc: LoginService.getAccessToken,
                requestFncParams: [{ refreshToken: Data.refreshToken }],
                that: MiniApiService,
                errorDef: LoginService.ERRORS.INVALID_TOKEN
              }).then(function (refreshRes) {

                log.debug("[getRtcConfiguration] Token refreshed", refreshRes);
                Data.accessToken = refreshRes.accessToken;
                log.debug("[getRtcConfiguration] Trying to get turn data again");
                return getRtcConfiguration(rtcConfiguration);

              }).catch(function (err) {
                log.error("[getRtcConfiguration] Error response", err);
                // TODO: Sensowna sygnalizacja błędu
                return Promise.reject(err);
              });

            } else {

              rtcConfiguration.iceServers = [res];
              return Promise.resolve(rtcConfiguration);

            }

          }).catch(function (err) {
            log.error("Error getting turn data");
            return Promise.reject(err);
          });

        };

        return getRtcConfiguration;

      };

      /**
       * @param {AxMsgGetUsersListResponse}
       */
      function onGetUserListResolved(evt) {

        var usersList = evt.entries;

        if (usersList.length === 0) {
          log.warn("Received empty user list");
          return;
        }

        /**
         *
         * @param {ax.SessionLib.Definitions.AxUserListEntry} userEntry
         * @returns {ContactListItem}
         */
        function mapAxUserListEntryToContactListItem(userEntry) {

          var listItem = new ContactListItem(userEntry.UserAccount, userEntry.State, userEntry.AssignedStationDialableNumber);

          listItem.stateDuration = userEntry.StateDuration;

          Data.contacts.forEach(
            /**
             * @param {ContactListItem} contact
             */
            function (contact) {
              if (contact.user.Id == listItem.user.Id) {
                listItem.unreadCounter = contact.unreadCounter;
              }
            });
          return listItem;
        }

        var tBody = document.getElementById("dialpadUserList").querySelector("tbody");
        var st = tBody.scrollTop;

        $scope.$apply(function () {

          // Userzy, którzy są już na liście mogą mieć nie przeczytane wiadomości
          // Dlatego musimy każdego srawdzić i przepisać informację o tym to nowo pobranej listy
          var newUserList = usersList.map(mapAxUserListEntryToContactListItem);

          log.debug(newUserList);
          Data.contacts = newUserList;

          $scope.fixTableHeadWidth("dialpadUserList");

        });

        tBody.scrollTop = st;

        // Wykonanie callbacka zewnętrzengo
        config.eventListener({ type: "users_list", data: usersList });

      }

      /**
       * Create conversation
       * @param {ContactListItem} currentContact
       * @param {ax.SessionLib.Collections.Chat.AxChatMessageDataElement} m
       */
      function createConversation(currentContact, m) {

        // Ustawiamy info o projekcie
        Data.currentChat.user.id = m.Message.SenderId;
        Data.currentChat.user.name = currentContact.user.Name + ' ' + currentContact.user.Surname;
        Data.currentChat.project.id = m.Message.ProjectId;
        Data.currentChat.project.name = m.Message.ProjectName;

        // Oznaczamy przy kontakcie, że jest nowa wiadomość
        currentContact.unreadCounter = 1;
        // Dodajemy okienko
        // Jeżeli nie ma żadnej konwersacji, to visible ustawiamy na true
        var visible = false;
        if (Data.conversations.length == 0) {
          visible = true;
          //currentContact.unread = false;
          currentContact.unreadCounter = 0;
          // Jeżeli nie jest wyświetlone video i czat, to pokazuję czat
          if (Data.videoConferenceVisible === false) {
            Data.chatConferenceVisible = true;
          }
        } else { // Są inne konwersacje
          currentContact.unreadCounter = currentContact.unreadCounter ? currentContact.unreadCounter + 1 : 1;
        }

        Data.conversations.push({
          visible: visible,
          contact: currentContact,
          session: { id: m.Message.SessionId },
          project: {
            id: m.Message.ProjectId,
            name: m.Message.ProjectName
          },
          messages: [{
            sender: currentContact.user.Name + " " + currentContact.user.Surname,
            sentDate: formatDateTime(m.Message.SentOn),
            text: m.Message.Message,
            deliveryStatus: 0
          }]
        });
      }

      function processVideoCall(command, session) {

        var code = 0,
          dialVideoResponse;
        if ($scope.isVideoOrAudioCallActive() === true) {

          code = 1;
          dialVideoResponse = JsonRpc.JsonRpcResponseError({
            id: command.id,
            error: JsonRpc.JsonRpcError({
              code: code,
              message: "Busy"
            })
          }).toJSON();

        } else {

          dialVideoResponse = JsonRpc.JsonRpcResponseResult({
            id: command.id,
            result: {
              code: code,
              message: "Alerting"
            }
          }).toJSON();

          sClient.sendStationBusyIndicator({ stationBusy: true });

        }

        // Send response message
        SessionHelperService.sendSessionChatResponseMessage({
          project: { Id: null, Name: null },
          sessionId: session.session.Session.SessionId,
          message: "",
          messageType: ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage,
          messageTag: dialVideoResponse,
          attachments: []
        }).then(function (res) {
          log.debug("Video state was send", res);
        }).catch(function (err) {
          console.log("Error video busy state", err);
        });

        if (code == 1) {
          return;
        }

        var btnStates = ax.AxSwitchIntegrationLib.AxPhoneButtonState;
        var buttons = new ax.AxSwitchIntegrationLib.PhoneButtons();
        buttons.pickup.state = btnStates.Enabled;
        buttons.hangup.state = btnStates.Enabled;
        var changedButtons = [
          buttons.pickup,
          buttons.hangup
        ];

        var evt = new ax.WebLib.Events.AxPhoneButtonStateChangedEvent(buttons, changedButtons);
        $scope.updateButtons(evt);
        VideoService.setAlertingState(true);
        VideoService.setAssociatedInteraction(session);
        VideoService.setCallProperties({ number: command.params.number });

        // Ring sound
        $scope.ringAlertingIfNotRinging();

        // Add system chat message
        $scope.pushIncomingVideoChatMessage(session);

        // Show notification
        NotificationData.newSessionNotification.videoSessionCnt = 1;
        NotificationService.showOrHideSessionNotification({
          videoSessionCnt: NotificationData.newSessionNotification.videoSessionCnt,
          li18n: li18n
        });

      }

      function processHangupRemoteVideo(command, session) {

        if ($scope.isVideoCallActive() === true) {

          VideoService.setAlertingState(false);
          VideoService.setDialingState(false);
          VideoService.setAssociatedInteraction(null);
          NotificationData.newSessionNotification.videoSessionCnt = 1;

          // Change phone buttons in gui
          var btnStates = ax.AxSwitchIntegrationLib.AxPhoneButtonState;
          var buttons = new ax.AxSwitchIntegrationLib.PhoneButtons();
          buttons.pickup.state = btnStates.Disabled;
          buttons.hangup.state = btnStates.Disabled;
          var changedButtons = [
            buttons.pickup,
            buttons.hangup
          ];
          var evt = new ax.WebLib.Events.AxPhoneButtonStateChangedEvent(buttons, changedButtons);
          $scope.updateButtons(evt);

          sClient.sendStationBusyIndicator({ stationBusy: false });
          $scope.stopRingAlerting();
          $scope.pushEndedVideoChatMessage(session);

          // Hide notifications
          NotificationData.resetAllCounters();
          NotificationService.closeAndRemoveSesionNotifications();

        }

      }

      function processChatSessionCommandMessage(m, session) {
        if (m.Message.Tag && m.Message.Tag === 'SetTypingData') {
          session.typingData = JSON.parse(m.Message.Message);
          return;
        }

        try {

          var message = m.Message.Message,
            jsRpc;
          if (typeof message == "string" && message.length > 0) {
            jsRpc = JsonRpc.JsonRpcElement.fromJSON(message);
          } else {
            return;
          }

          if (jsRpc.method === "dialVideo") {

            processVideoCall(jsRpc, session);

          } else if (jsRpc.method === "hangupVideo") {

            processHangupRemoteVideo(jsRpc, session);

          } else {

            log.warn("Unsupported command: " + jsRpc.method);
            return;

          }

        } catch (e) {
          log.warn("Error processing chat session command", e);
          return;
        }

      }

      /**
       * @param evt {ax.WebLib.Events.AxSessionChatMessageReceivedEvent}
       */
      function onSessionChatMessageReceived(evt) {

        /** @type ax.SessionLib.Collections.Chat.AxChatMessageDataElement */
        var m = evt.message;

        var messagesToShow = [];
        m.Message.__from = "client";
        log.debug(m);
        var session = SessionService.getSessionById(m.SessionId);
        log.debug(session);

        if (m.Message.MessageType === ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage) {
          processChatSessionCommandMessage(m, session);
          return;
        }

        // Set sender name if is available in session info
        //var senderName = session.session.Session.SessionInfoRemoteSideFriendlyName;
        var senderName = session.session.Session.RemoteSideFriendlyNameComputed;
        if (senderName !== null) {
          m.Message.SenderFriendlyName = senderName;
        }

        // Add text message
        if (m.Message.Message !== null && (typeof m.Message.Message !== "undefined") && m.Message.Message.length > 0) {
          session.typingData = { isTyping: false };
          messagesToShow.push(m.Message);
        }

        // Add attachments
        if (m.Attachments !== null && m.Attachments.length > 0) {

          Promise.all(m.Attachments.map(function (attachment) {

            var sName = senderName !== null ? senderName : "";
            return getImagePromiseWrapper(FileService, attachment, m.Message.MessageType, sName, m.Message.SentOn, "client", Data.refreshToken, m.Message);

          })).then(function (messages) {

            messagesToShow = messagesToShow.concat(messages);
            getHintAndPush();

          }).catch(function (err) {

            log.error("Error getting files", err);
            getHintAndPush();
          });

        } else {
          getHintAndPush();
        }

        function getHintAndPush() {

          if (config.menerva.autoHintEnabled) {

            $http.post(config.menerva.chatServer,
              {
                "configuration": { "alias": config.menerva.chatAlias },
                "command": "Request",
                "commandValue": "Session=" + m.SessionId + '&Input=' + m.Message.Message
              }
            ).then(function (res) {
              var data = res.data;

              log.debug(data);

              if (data.Body.CRMPROC.TOPIC !== "UNKNOWN.") {
                session.data.chatHints.unshift(data.Body.RESPONSE.TEXT);
              }

              session.data.messages.push.apply(session.data.messages, messagesToShow);

            }).catch(function (res) {
              var status = res.status;
              log.error("Error getting menerva hint");
              log.error("Response status: " + status);
              session.data.messages.push.apply(session.data.messages, messagesToShow);

            });

          } else {
            session.data.messages.push.apply(session.data.messages, messagesToShow);
          }

          if (session !== Data.currentInteraction) {
            session.data.unreadChatCounter += messagesToShow.length;
          }
          SoundService.play(SoundType.chatNewMessage);

          // Show notification
          if (NotificationService.canShowNotification()) {

            NotificationData.newSessionNotification.unreadChatMessagesCnt += messagesToShow.length;

            NotificationService.createSessionNotification({
              body: NotificationService.sessionNotificationBody({
                unreadChatMessagesCnt: NotificationData.newSessionNotification.unreadChatMessagesCnt,
                li18n: li18n
              }),
              li18n: li18n
            });

          }

        }

      }

      /**
       * @param evt {ax.WebLib.Events.AxInternalChatMessageReceivedEvent}
       */
      function onInternalChatMessageReceived(evt) {

        var m = evt.message;

        // Wykonanie zewnętrznych listenerów
        config.eventListener({ type: "new_chat_message" });

        // Zwiększamy counter nieprzeczytanych(czy go widać czy nie jest załatwione w templacie)
        Data.chatUnreadCounter = Data.chatUnreadCounter + 1;
        $rootScope.$apply(function () {

          var receiverType = m.Message.ReceiverType;
          if (receiverType === null || receiverType == 2) { // U
            // ser
            // Szukamy, czy taka rozmowa już istnieje
            var found = false;
            log.debug(Data.conversations);
            for (var i in Data.conversations) {
              var currentConversation = Data.conversations[i];
              log.debug("Iterated conversation");
              log.debug(currentConversation);
              log.debug("SenderId: " + m.Message.SenderId);

              if (currentConversation.contact.user.Id === m.Message.SenderId) {

                // Znajdujemy kontakt i ustawiamy mu unread
                for (var j in Data.contacts) {
                  var currentContact = Data.contacts[j];
                  if (currentContact.user.Id == m.Message.SenderId) {
                    // Zmieniamy tylko, jeżeli konwersacja nie jest aktualnie widoczna
                    if (currentConversation.visible === false) {
                      currentContact.unreadCounter = currentContact.unreadCounter ? currentContact.unreadCounter + 1 : 1;
                    }
                  }
                }

                // Jeżeli konwersacja jest w ramach innej sesji, to podmieniamy sesję
                currentConversation.session.id = m.Message.SessionId;
                // Tal samo robimy z projektem, ustawiamy id projektu aktualnej rozmowy
                currentConversation.project.id = m.Message.ProjectId;
                currentConversation.project.name = m.Message.ProjectName;

                currentConversation.messages.push({
                  sender: currentConversation.contact.user.Name + " " + currentConversation.contact.user.Surname,
                  sentDate: formatDateTime(m.Message.SentOn),
                  text: m.Message.Message,
                  deliveryStatus: 0
                });
                found = true;
                break;

              }

            }

            if (found === false) { // Jeżeli konwersacja nie została znaleziona, to trzeba ją utworzyć

              // 1. Szukamy w kontaktach ludzika, od którego przyszła wiadomość
              var contactFound = false;
              for (var j in Data.contacts) {

                var currentContact = Data.contacts[j];
                if (currentContact.user.Id == m.Message.SenderId) {
                  contactFound = true;
                  openFrame();
                  createConversation(currentContact, m);
                  break;
                }

              }

              // Jeżeli kontakt nie został znaleziony na liście, to
              // Pobieram dane usera
              if (contactFound === false) {

                var getUserPromise = sClient.getUser(m.Message.SenderId);
                getUserPromise.then(
                  /**
                   *
                   * @param {ax.SessionLib.AxMessages.Users.AxMsgGetUserResponse} res
                   */
                  function (res) {
                    var cItem = new ContactListItem(res.User, res.CurrentState);
                    Data.contacts.push(cItem);

                    // Create conversation
                    createConversation(cItem, m);

                  }, function (err) {
                    log.error("Failed to get user data")
                    log.error(err);
                  });
              }

            }

          }

        });
      }

      /**
       * @param {ax.WebLib.Events.AxUserStateListReceivedEvent} evt
       */
      function onUserStateListReceived(evt) {

        // TODO: Wykorzystać tutaj funkcję setStatuses
        log.debug("[webagent.onUserStateListReceived] Received event:", evt);
        var statusesClean = [];
        var statuses = evt.entries;
        var len = statuses.length;
        var current = null;
        for (var i = 0; i < len; i++) {
          var status = statuses[i];
          if (status.Visible === true) {
            status.Name = li18n.statuses[status.State];
            statusesClean.push(status);
            if (Data.currentStatus.StateId === status.StateId) {
              current = status;
            }
          }

        }

        $rootScope.$apply(function () {
          Data.statuses = statusesClean;
          if (current !== null) {
            status.Duration = Data.currentStatus.Duration;
            Data.currentStatus = current;
          }
        });

      }

      /**
       * @param {ax.WebLib.Events.AxCampaignsListReceivedEvent} evt
       */
      function onCampaignsListReceived(evt) {
        log.debug("[webagent.onCampaignsListReceived] Received event:", evt);

        if ( typeof evt.identifier !== "undefined" ) {
          return;
        }

        var i, l, clearSelected = true;

        // Remember scroll position
        var tBody = document.getElementById("dialpadCampaignList").querySelector("tbody");
        var st = tBody && tBody.scrollTop;

        // Przepisuję elementy pojedynczo, bo bez tego jakoś wolno działa i widać jak fixuję pozycję scrolla poniżej
        Data.campaignList.length = 0;
        var el = evt.entries.length;

        for (i = 0; i < el; i++) {
          $rootScope.$apply(function () {
            Data.campaignList.push(evt.entries[i])
          });
        }

        // Bo bez tego przesuwa się scroll liście - przesuwa się kij wie czemu do góry
        // Dlatego fixuję wczesniej zapamiętaną pozycję
        // Jak sie zmieni ilość na liście to się generalnie nic wielkiego nie stanie, wię można to zignorować
        if (tBody) {
          tBody.scrollTop = st;
        }

        $scope.fixTableHeadWidth("dialpadCampaignList");

      }


      /**
       * @param {ax.WebLib.Events.AxSetUserStatusReceivedEvent} evt
       */
      function onSetUserStatusReceived(evt) {
        log.debug("[webagent.onSetUserStatusReceived] Received event:", evt);
        var status = evt.status;
        if (status.Params.Result === ax.SessionLib.AxRequestUserStatusResult.Changed) { // Status has changed
          $rootScope.$apply(function () {

            // Set status in status  item
            status.StateParam.Name = li18n.statuses[status.StateParam.State];
            if (status.Params.MaxTime !== null) {
              var progress = document.getElementById("sProgress");
              progress.style.animationDuration = status.Params.MaxTime + "ms";
              Data.statusProgressVisible = true;
            }
            Data.currentStatus = status.StateParam;
            Data.currentStatus.Duration = "00:00:00";
            TimerService.startCountStateTime();

            // Restore temporary "rejected" sessions
            if (Data.currentStatus.State === ax.SessionLib.AxUserState.Available) {

              if (SessionService.getTemporaryRejectedSessionsLength() > 0) {
                SessionService.restoreRejectedSessions();
                $scope.ringAlerting();
                NotificationData.newSessionNotification.voiceSessionCnt = Data.interactions.reduce(function (counter, currentInter, index) {

                  if (currentInter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundCall) {
                    return ++counter;
                  } else {
                    return counter;
                  }

                }, 0);

                NotificationService.showOrHideSessionNotification({
                  voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
                  li18n: li18n
                });

              }

            } else if (Data.currentStatus.State === ax.SessionLib.AxUserState.InSession) {

              // Remove from temporary rejected
              SessionService.removeSessionFromTemporaryRejectedList(Data.acceptSessionInProgress);
              Data.acceptSessionInProgress = null;

            } else { // Temporary reject all sessions
              // Clear timers in already rejected session to make sure, that its not go back
              if (Data.currentStatus.State !== ax.SessionLib.AxUserState.InSession) {
                SessionService.stopAllTemporaryRejectedSessionsTimeouts();
                SessionService.temporaryRejectAcceptableSessions(0);
                $scope.stopRingAlerting();
              }
            }

          });
        } else {
          log.warn("[webagent.onSetUserStatusReceived] Status not changed Result: " + evt.status.Params.Result);
        }

      }

      /**
       * @param {ax.WebLib.Events.AxSessionDirectedReceivedEvent} evt
       */
      function onSessionDirectedReceived(evt) {

        log.debug("[onSessionDirectedReceived] Start", evt.session);

        var m = evt.session;
        var sState = m.Session.SessionState;
        var createReason = m.Session.CreateReason;
        var AxState = ax.SessionLib.AxSessionState;
        var foundInteraction = SessionService.getSessionById(m.Session.SessionId);
        var al = 0;
        var currentAction;

        // Notification
        if (foundInteraction !== null) {
          log.debug("[onSessionDirectedReceived] Interaction found");
        } else {
          log.debug("[onSessionDirectedReceived] Interaction NOT found");
        }

        if (foundInteraction === null) {
          NotificationService.showSessionNotification({
            event: m,
            li18n: li18n
          });

          switch (m.Session.SubType) {
            case ax.SessionLib.AxSessionSubtype.InboundEmail:
              SoundService.play(SoundType.newEmail);
              break;
            case ax.SessionLib.AxSessionSubtype.InboundChat:
            case ax.SessionLib.AxSessionSubtype.InboundSocial:
              SoundService.play(SoundType.chatStart);
              break;
          }
        }

        function isVoiceSession(sessionType) {
          return [
            ax.SessionLib.AxSessionSubtype.InboundCall,
            ax.SessionLib.AxSessionSubtype.PredictiveCall,
            ax.SessionLib.AxSessionSubtype.PreviewCall,
            ax.SessionLib.AxSessionSubtype.CallBackCall,
            ax.SessionLib.AxSessionSubtype.ManualCall
          ].indexOf(sessionType) !== -1;
        }

        // play session is not attached (f.e. voice inside chat session)
        if (m.Session.AttachToSessionId === null &&
          !isVoiceSession(m.Session.SubType) &&
          m.Session.IsEnded === true) {
          SoundService.play(SoundType.chatEnd);
        }

        // -------------------- WebData
        var webData = new SessionWebData("", "");

        if (m.Session.SubType !== ax.SessionLib.AxSessionSubtype.InboundEmail && config.ufp.enabled === true) {
          webData.openCustomerSearchVisible = true;
        }

        webData.breakSession = true;

        // Allow to shift session??
        if (m.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundCall || m.Session.SubType === ax.SessionLib.AxSessionSubtype.PredictiveCall || m.Session.SubType === ax.SessionLib.AxSessionSubtype.PreviewCall) {
          if (isBitSet(ax.SessionLib.AxSessionButton.ShiftSession, m.SessionButtons)) {
            webData.shiftSessionVisible = true;
          }
        }

        // Allow to break session only if it is predicitive call
        if (m.Session.SubType === ax.SessionLib.AxSessionSubtype.PredictiveCall
          || m.Session.SubType === ax.SessionLib.AxSessionSubtype.PreviewCall) {
          if (isBitSet(ax.SessionLib.AxSessionButton.TerminateSession, m.SessionButtons)) {
            webData.breakSessionVisible = true;
          }
        }

        if (m.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat ||
          m.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial) {
          webData.closeChatSessionVisible = true;
        }

        if (m.DataRecord !== null) {
          if (isBitSet(ax.SessionLib.AxSessionButton.EditDataRecord, m.SessionButtons)) {
            webData.editRecordVisible = true;
          }
        }

        webData.typeName = li18n.axCreateReason[createReason];

        log.debug("[onSessionDirectedReceived] Creating InteractionItem");
        var interaction = new InteractionItem(m, new SessionData(webData, null), WL);
        // -------------------- / WebData

        // Ale, może to być sesja głosowa, która ma kolejkowanie jako huntgrupa
        // I wtedy chcę ją wywalić z listy interakcji, odłożonych na bok (zrejectowanych)
        // Chujowo, ale co zrobić

        // Remove session from temporary rejected if session is ended
        // Session can stuck in temporary rejected when user change status after accept
        if (sState === AxState.EndedByRemoteSide
          || sState === AxState.EndedByRemoteSide
          || sState === AxState.WaitingTimeOut
        ) {

          SessionService.removeSessionFromTemporaryRejectedList(m.Session.SessionId);
          if (Data.acceptSessionInProgress === m.Session.SessionId) {
            Data.acceptSessionInProgress = null;
          }

        }

        if (interaction.isAcceptable()) {
          var acceptAction = interaction.getAcceptSessionAction();
          log.debug("[onSessionDirectedReceived] Interaction is acceptable");

          if (acceptAction.Reset === true) {

            log.debug("[onSessionDirectedReceived] Interaction accept action has reset flag set. Removing from temporary rejected");
            SessionService.removeSessionFromTemporaryRejectedList(m.Session.SessionId);

            // TODO: Czy to nie spowoduje, że jeżeli będzie więcej sesji alertujących, to przestanie dzwonić?
            // chyba dzwonek powienien być wyłączony tylko jeżeli na liście nie pozostały żadne sesje huntgroupowe
            // Albo w ogóle żadne telefoniczne
            if ($scope.isAlertingRinging() === true) {
              $scope.stopRingAlerting();
            }

            /*if (sState === AxState.RejectedByRemoteSide ) {
                         log.debug("[onSessionDirectedReceived] Interaction is rejected by remote side. Removing");
                         SessionService.removeSessionFromList(m.Session.SessionId);
                     }

                     if (sState === AxState.WaitingTimeOut ) {
                         log.debug("[onSessionDirectedReceived] Interaction waiting timeout. Removing");
                         SessionService.removeSessionFromList(m.Session.SessionId);
                     }

                     if ( sState === AxState.DirectedToUser && m.Session.LastUserAcceptedId !== sClient.loginResponse.LoginResponseParams.AccountData.Id ) {
                         log.debug("[onSessionDirectedReceived] Interaction accepted by other user. Removing");
                         SessionService.removeSessionFromList(m.Session.SessionId);
                     }*/

            log.debug("[onSessionDirectedReceived] Interaction accept action has reset flag set. Removing");
            SessionService.removeSessionFromList(m.Session.SessionId);

            return;

          }

          // Jeżeli nie jestem w statusie dostępny a dostałem skierowanie sesji jako huntgrupa, to nie dodaję do listy
          // Wszystko przez to, ze HostService jest zjebany i wysyła hunt zawsze do wszystkich niezależnie od statusu
          if ((Data.currentStatus.State !== ax.SessionLib.AxUserState.Available || Data.acceptSessionInProgress !== null)
            && sState === AxState.Queued) {
            if (Data.currentStatus.State !== ax.SessionLib.AxUserState.Available) {
              log.debug("[onSessionDirectedReceived] Current state is:" + Data.currentStatus.State + " (Not available). Auto reject");
            }
            log.debug("[onSessionDirectedReceived] ");
            if (Data.acceptSessionInProgress !== null) {
              log.debug("[onSessionDirectedReceived] Accept session in progress. Auto reject");
            }

            SessionService.temporaryRejectSession(interaction, 0);
            return;

          }

          /*
                 if ( ( sState === AxState.RejectedByRemoteSide
                     || sState === AxState.WaitingTimeOut ) && Data.acceptSessionInProgress === m.Session.SessionId ) {
                     Data.acceptSessionInProgress = null;
                 }

                 // Remove from sessions list if session is rejected, timeouted or accepted by other user
                 if ( sState === AxState.RejectedByRemoteSide
                     || sState === AxState.WaitingTimeOut
                     || ( sState === AxState.AcceptedByUser && m.Session.LastUserAcceptedId !== sClient.loginResponse.LoginResponseParams.AccountData.Id )) {

                     log.debug("[onSessionDirectedReceived] Interaction is ended or accepted by other user. Removing");
                     SessionService.removeSessionFromTemporaryRejectedList(m.Session.SessionId);
                     SessionService.removeSessionFromList(m.Session.SessionId);

                     // TODO: Czy to nie spowoduje, że jeżeli będzie więcej sesji alertujących, to przestanie dzwonić?
                     // chyba dzwonek powienien być wyłączony tylko jeżeli na liście nie pozostały żadne sesje huntgroupowe
                     // Albo w ogóle żadne telefoniczne
                     if ($scope.isAlertingRinging() === true) {
                         $scope.stopRingAlerting();
                     }
                     return;

                 }

                 // Session accepted by me
                 if ( sState === AxState.AcceptedByUser && m.Session.LastUserAcceptedId === sClient.loginResponse.LoginResponseParams.AccountData.Id) {

                     log.debug("[onSessionDirectedReceived] Interaction is accepted by current user");
                     SessionService.stopAllTemporaryRejectedSessionsTimeouts();
                     SessionService.removeSessionFromTemporaryRejectedList(m.Session.SessionId);

                 }
                 */
        } else {
          log.debug("[onSessionDirectedReceived] Interaction IS NOT acceptable");
        }

        // Bo flaga reset (a właściwie cała akcja) nie przychodzi w niektórych przypadkach
        if (sState === AxState.RejectedByRemoteSide) {
          SessionService.removeSessionFromTemporaryRejectedList(m.Session.SessionId);
          if (Data.acceptSessionInProgress === m.Session.SessionId) {
            Data.acceptSessionInProgress = null;
          }
        }

        if (sState === AxState.RejectedByUser) {

          SessionService.removeSessionFromTemporaryRejectedList(m.Session.SessionId);
          // Clear inprogress flag
          if (Data.acceptSessionInProgress === m.Session.SessionId) {
            Data.acceptSessionInProgress = null;
          }
          // For acceptable (hunt session)
          // When session is rejected ( assumes by current user) by pressing hangup button (red phone)
          if (foundInteraction !== null && foundInteraction.isAcceptable() === true) {
            log.debug("[onSessionDirectedReceived] Accepted interaction is rejected. Removing");
            SessionService.removeSessionFromList(m.Session.SessionId);
            return;
          }

        }

        if ((foundInteraction === null)
          && (sState !== AxState.DirectedToUser
            && sState !== AxState.Initiating && sState !== AxState.AcceptedByUser && sState !== AxState.Queued)
        ) {
          log.debug("[onSessionDirectedReceived] Interaction should not be shown. Returning");
          return;
        }

        // Jeżeli jest to sesja voice skojarzona z sesją chat i została zakończona, to automatycznie ją zamykamy
        if (m.Session.AttachToSessionId !== null
          && m.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundCall
          && m.Session.IsEnded === true) {

          log.debug("Ended voice session is attached to parent session. Closing automatically");
          SessionHelperService.closeCurrentSession(foundInteraction, true);
          var attachedToChatInteraction = SessionService.getSessionById(m.Session.AttachToSessionId);
          if (attachedToChatInteraction !== null) {

            // Add system chat message
            var callSystemMsgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();

            callSystemMsgItem.__from = "system";
            callSystemMsgItem.__messageType = "call_ended";
            callSystemMsgItem.Message = li18n.messages.voiceCallEnded;
            callSystemMsgItem.SentOn = moment().format("YYYY-MM-DD HH:mm");
            callSystemMsgItem.SenderType = ax.AxCommonLib.AxObjectType.Service;
            callSystemMsgItem.SenderFriendlyName = li18n.messages.system;
            callSystemMsgItem.ChatMessageId = UUID();
            attachedToChatInteraction.data.messages.push(callSystemMsgItem);

          }

          return;

        }

        // Jeżeli jest to sesja voice skojarzona z sesją chat i jest to skierowanie lub zakończenie rozmowy
        // to dodajemy nową systemową wiadomość chat
        if (m.Session.AttachToSessionId !== null
          && m.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundCall
          && sState === AxState.DirectedToUser) {
          log.debug("Incoming voice session is attached to parent session. Adding system chat message");
          var attachedToChatInteraction = SessionService.getSessionById(m.Session.AttachToSessionId);
          if (attachedToChatInteraction !== null) {

            // Add system chat message
            var callSystemMsgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();

            callSystemMsgItem.__from = "system";
            callSystemMsgItem.__messageType = "incoming_call";
            callSystemMsgItem.Message = li18n.messages.voiceCall;
            callSystemMsgItem.SentOn = moment().format("YYYY-MM-DD HH:mm");
            callSystemMsgItem.SenderType = ax.AxCommonLib.AxObjectType.Service;
            callSystemMsgItem.SenderFriendlyName = li18n.messages.system;
            attachedToChatInteraction.data.messages.push(callSystemMsgItem);

          }
        }

        // Zapodajemy info z jakiej kolejki przyszło połączenie
        $rootScope.$apply(function () {
          Data.currentCall.queue.name = m.Session.DestinationObjectFriendlyName;
        });

        var interactionFromList = SessionService.getSessionById(interaction.session.Session.SessionId);

        // Set interactions counter
        if (sState === AxState.EndedByRemoteSide ||
          sState === AxState.EndedByUser ||
          sState === AxState.WaitingTimeOut ||
          sState === AxState.InvalidDestination ||
          sState === AxState.RejectedByRemoteSide ||
          sState === AxState.SystemTerminated ||
          sState === AxState.Transferred ||
          sState === AxState.NotSet ||
          sState === AxState.InitiationFailed ||
          sState === AxState.Rejected ||
          sState === AxState.UnableToEstablisheWithUser) {

          Data.activeInteractions = Data.activeInteractions - 1;

        } else {
          if (interactionFromList === null &&
            m.Session.AttachToSessionId === null) {
            Data.activeInteractions = Data.activeInteractions + 1;
          }

        }

        webData.stateName = li18n.axSessionState[sState];

        // New interaction with actions
        if (interactionFromList === null && m.Actions !== null) {

          // Run actions
          m.Actions.forEach(function (action) {

            log.debug(action);

            // Start survey session only if it't not attached session
            if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventSurveyAction
              && interaction.session.Session.AttachToSessionId === null
            ) {

              // Start survey only when this is not session to Accept
              if (interaction.isAcceptable() === false) {
                SurveyService.startSurveySession({
                  interaction: interaction,
                  action: action,
                  log: log
                });
              }

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventGenericAction) {

              var actionType = action.Action;
              log.debug("[onSessionDirectedReceived] Generic event action with type: " + actionType);

              var aTypes = ax.SessionLib.AxSessionEventActionType;

              if (actionType === aTypes.AutoCloseForm) {
                interaction.data.web.autoCloseForm = true;
              }

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventWebBrowserAction) {
              log.debug("[onSessionDirectedReceived] Browser navigation event action");
              if (sState === AxState.DirectedToUser || sState === AxState.AcceptedByUser) {
                interaction.data.web.inboundUrl = action.UrlExpressionToNavigate;
              }

              interaction.data.web.forceAssignSessionToCustomer = action.EnableValidateJsScript;

              if (action.UrlExpressionToNavigate) {
                var urlData = Object.assign({}, Data.currentUser, { Lang: Data.lang });
                interaction.data.web.customerUrl = UtilsService.prepareUrl(action.UrlExpressionToNavigate, urlData);
              } else {
                interaction.data.web.customerUrl = $scope.getCustomerListPageAddress(config);
              }

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventRecordingAction) {
              log.debug("[onSessionDirectedReceived] AxSessionEventRecordingAction");
              // TODO: Recording state 1 przychodzi później (np w przypadku preview), dlatego musimy go też przypisywać nawet jeżeli interactionFromList nie jest nullem....
              interaction.data.recording = action;

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventChatParamsAction) {
              log.debug("[onSessionDirectedReceived] AxSessionEventChatParamsAction");

              if (action.FixedAnswersTags !== null) {

                interaction.data.chatAnswers =  uniq(action.FixedAnswersTags.reduce(function (prev,answer) {

                  return prev.concat(answer.Answers.map( a => a.Message));

                },[])).map( answer => {
                  return {
                    answer,
                    isEllipsis: undefined,
                    answerExpanded: false
                  }} );

                interaction.data.chatAnswersTags = action.FixedAnswersTags;
              }

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession) {
              log.debug("[onSessionDirectedReceived] AxSessionEventAcceptSession");

              if (sState === AxState.Queued) {

                if ($scope.isAlertingRinging() === false) {
                  $scope.ringAlerting();
                }
              }

            }  else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventIdentifyCaller ) {
              log.debug("[onSessionDirectedReceived] ActionsAxSessionEventIdentifyCaller");

              // Czyli teraz co... trzeba to zapodać jako część skryptera, czy jako osobny widok całkiem?

            }

          });


        } else if (interactionFromList !== null && m.Actions !== null) { // Already existed interaction

          m.Actions.forEach(function (action) {

            var session = m.Session;
            var sState = session.SessionState;
            var AxState = ax.SessionLib.AxSessionState;

            if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventRecordingAction) {
              log.debug("[onSessionDirectedReceived] AxSessionEventRecordingAction");
              interaction.data.recording = action;
            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventGenericAction) {

              log.debug("[onSessionDirectedReceived] Generic event action with type: " + action.Action);
              if (action.Action === ax.SessionLib.AxSessionEventActionType.AutoCloseForm) {
                interaction.data.web.autoCloseForm = true;
              }

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventWebBrowserAction) {
              log.debug("[onSessionDirectedReceived] Browser navigation event action");
              if (sState === AxState.AcceptedByUser) {
                interaction.data.web.inboundUrl = action.UrlExpressionToNavigate;
              }
              interaction.data.web.forceAssignSessionToCustomer = action.EnableValidateJsScript;

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession) {

              log.debug("[onSessionDirectedReceived] AxSessionEventAcceptSession. Nothing to do");

            } else if (action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventSurveyAction) {
              log.debug("[onSessionDirectedReceived] AxSessionEventAcceptSession. AxSessionEventSurveyAction");
              var isSurveyEmpty = interactionFromList.surveySession === null || typeof interactionFromList.surveySession == "undefined";
              var isSurveyLoading = interactionFromList.isSurveyLoading;

              if (isSurveyEmpty && isSurveyLoading === false) {
                SurveyService.startSurveySession({
                  interaction: interaction,
                  action: action,
                  log: log
                });
              }

            }

          });

        }

        $rootScope.$apply(function () {

          if (incomingCall) { // Connected

            m.callState = Data.incomingCall.callState;
          }

          // Dodajemy interakcję do listy
          if (sState == 2) { // DirectedToUser
          } else if (sState == 4 || sState == 5 || sState == 6 || sState == 7) {
            m.callState = 2;
          }

          // Zaczynamy odliczać czas interakcji
          if (sState === AxState.AcceptedByUser) {

            // Sprawadzamy, czy interakcja już istnieje na liście
            // Jeżeli istnieje, to timer uruchamiamy na tej z listy
            if (interactionFromList) {
              TimerService.startCountInteractionTime(interactionFromList);
            } else { // Jeżeli nie ma jeszcze na liście, to odpalamy timer na nowej (zostanie później dodana do listy)
              TimerService.startCountInteractionTime(interaction);
            }
          } else {

            if (interactionFromList !== null) {

              // Stopujemy timer
              TimerService.stopCountInteractionTime(interactionFromList);

              interaction.duration = interactionFromList.duration;
              interaction.startTime = interactionFromList.startTime;
              interaction.queueWaitTime = interactionFromList.queueWaitTime;

            }

          }

          //////////////////////////////
          if (interaction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat ||
            interaction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial) {

            // Automatically send accept chat session
            if (sState === ax.SessionLib.AxSessionState.DirectedToUser) {

              log.debug("[onSessionDirectedReceived] Automatically accepting chat session");
              sClient.acceptSession({
                sessionId: interaction.session.Session.SessionId,
                userId: Data.currentUser.Id
              }).then(function (res) {

                log.debug("[onSessionDirectedReceived] Chat session accepted", res);

                return SessionClient.getChatSessionMessages({
                  //sessionId : interaction.session.Session.SessionId,
                  sessionId: null,
                  conversationTag: interaction.session.Session.BaseSessionObject.SessionProperties.ConversationTag,
                  page: null,
                  pageLength: null
                });

              }).then(function (res) {

                log.debug("[onSessionDirectedReceived] Received buffered chat messages", res);

                var newMessages = res.Records.reduce(function (previous, current, index, records) {

                  var promises = [];
                  // MessageType - ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage
                  // Omit commands
                  if (current.MessageType === ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage) {
                    return previous;
                  }

                  // Add only if message has text content
                  if ((current.Message !== null
                    && (typeof current.Message === "string")
                    && current.Message.length > 0)) {
                    var msgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();

                    // TODO: Tutaj przydało by się mieć to: a nie da się, bo tutaj Type to jest inny enum
                    // Patrz też: https://unifiedfactory.atlassian.net/browse/UFWEBAGENT-360

                    if (current.MessageType === ax.SessionLib.Collections.Chat.AxChatMessageType.SessionManagerMessage) {
                      msgItem.__from = "sessionManager";
                      msgItem.SenderFriendlyName = interaction.session.Session.RemoteSideFriendlyNameComputed;
                    } else if (current.MessageType === ax.SessionLib.Collections.Chat.AxChatMessageType.UniscriptMessage) {
                      msgItem.__from = "uniscript";
                    } else if (current.Who === ax.SessionLib.Collections.Chat.AxChatMessageDirection.Out) {
                      msgItem.__from = "agent";
                      msgItem.SenderFriendlyName = current.AgentFirstName + " " + current.AgentLastName;
                    } else {
                      msgItem.__from = "client";
                      msgItem.SenderFriendlyName = interaction.session.Session.RemoteSideFriendlyNameComputed;
                    }
                    msgItem.Message = current.Message;
                    msgItem.SentOn = current.Time;
                    msgItem.SenderType = ax.AxCommonLib.AxObjectType.User;
                    //msgItem.SenderFriendlyName = interaction.session.Session.RemoteSideFriendlyNameComputed;
                    msgItem.ChatMessageId = current.Id;
                    msgItem.SessionId = current.SessionId;
                    promises.push(Promise.resolve(msgItem));

                  }

                  if (current.Attachments !== null && current.Attachments.length > 0) {

                    promises.push.apply(promises, current.Attachments.map(function (attachment) {

                      return getImagePromiseWrapper(FileService,
                        attachment,
                        current.MessageType,
                        interaction.session.Session.RemoteSideFriendlyNameComputed,
                        current.Time, "client", Data.refreshToken, current);

                    }));

                  }

                  return previous.concat(promises);

                }, []);

                Promise.all(newMessages).then(function (messages) {

                  // TODO: Ale, żeby nie było takiego efektu, że wiadomości początkowe pojawiają sie po czasie
                  // To po przyjściu wiadomości z eventa
                  // Trzeba by je buforować do czasu kiedy nie zostaną odebrane wiadomości z requesta
                  // Ten bufor mógłby być jako propertis w obiekcie InteractionItem
                  // Ale ponieważ docelowo mechanizm kolejkowania na serwerze będzie przerobiony, to na razie zostaje tak jak jest
                  var interactionMessages = interaction.data.messages;

                  // Ponieważ zanim przyjdzie response z wiadomościami pobranymi z bufora, może się okazać, że przyjdą te same wiadomości jako eventy
                  // dlatego sprawdzamy, wiadomości z bufora są już na liście, jeżeli tak, to tych instniejących nie dodajemy
                  var nextSessionId = null;
                  var lastSessionMsgCounter = 0;
                  var lastSession = true;
                  for (var i = messages.length - 1; i >= 0; i--) {
                    // Jeżeli wiadomość jest już na liście interakcji, to jej nie dodaję
                    var msg = messages[i];
                    if (interactionMessages.filter(function (iMsg) {

                      return msg.ChatMessageId === iMsg.ChatMessageId

                    }).length > 0 == false) {
                      if (msg.SessionId && nextSessionId !== null && nextSessionId !== msg.SessionId) {
                        var separatorMsgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();
                        separatorMsgItem.__from = "system";
                        separatorMsgItem.__messageType = 'chat_ended';
                        separatorMsgItem.Message = li18n.messages.clientEndedChat;
                        separatorMsgItem.SentOn = messages[i].SentOn;
                        separatorMsgItem.SenderType = ax.AxCommonLib.AxObjectType.Service;
                        separatorMsgItem.SenderFriendlyName = li18n.messages.system;
                        separatorMsgItem.ChatMessageId = UUID();
                        interactionMessages.unshift(separatorMsgItem);
                        lastSession = false;
                      }
                      nextSessionId = msg.SessionId;
                      if (lastSession) {
                        lastSessionMsgCounter++;
                      }

                      interactionMessages.unshift(msg);
                    }
                  }

                  if (interaction !== Data.currentInteraction) {
                    interaction.data.unreadChatCounter += lastSessionMsgCounter;
                  }

                  if (NotificationService.canShowNotification()) {

                    NotificationData.newSessionNotification.unreadChatMessagesCnt = interaction.data.unreadChatCounter;
                    NotificationService.showOrHideSessionNotification({
                      unreadChatMessagesCnt: NotificationData.newSessionNotification.unreadChatMessagesCnt,
                      li18n: li18n
                    });
                  }

                }).catch(function (err) {
                  log.error("Error", err);
                });


              }).catch(function (err) {
                log.error("[onSessionDirectedReceived] Error accepting chat session or getting buffered chat messages", err);
              });

            } else if (sState === ax.SessionLib.AxSessionState.EndedByRemoteSide) {

              // Add system chat message
              var systemMsgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();
              systemMsgItem.__from = "system";
              systemMsgItem.__messageType = "chat_ended";
              systemMsgItem.Message = li18n.messages.clientEndedChat;
              systemMsgItem.SentOn = moment().format("YYYY-MM-DD HH:mm");
              systemMsgItem.SenderType = ax.AxCommonLib.AxObjectType.Service;
              systemMsgItem.SenderFriendlyName = li18n.messages.system;
              systemMsgItem.ChatMessageId = UUID();

              interactionFromList.data.messages.push(systemMsgItem);

            }

            // TODO: Poniżej pobieranie listingu plików, będzie całe do wywalenia raczej, ale póki co jeszcze to komentuję
            /*
                    if (sState === ax.SessionLib.AxSessionState.AcceptedByUser) {

                        WL.getDirectoryListing({ path : "",
                            extensions : "",
                            campaignId : interaction.session.Session.DestinationObjectId
                        }).then(function(res) {

                            var unpackedList = WL.unpackObject(res);

                            if (unpackedList.Files !== null ) {

                                if (config.chat.removeGuidFromFileName === true) {

                                    interaction.data.availableFiles = unpackedList.Files.map(function (file) {

                                        var dotIndex = file.Name.lastIndexOf("."),
                                            guid,
                                            sLen = file.Name.length,
                                            guidEndIdx,
                                            guidPattern = /[a-zA-Z0-9]{8}\-[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{12}/;

                                        if (dotIndex !== -1) {
                                            guidEndIdx = sLen - dotIndex;
                                            // Wycinamy GUID ale sprawdzamy jeszcze czy on na pewno jest, zeby nie uwalić niepotrzebnie czegoś
                                            guid = file.Name.slice(-36 - guidEndIdx, dotIndex);

                                            if (guid.match(guidPattern)) {

                                                file.DisplayName = file.Name.slice(0, dotIndex - 37) + file.Name.slice(dotIndex, sLen);

                                            } else {
                                                file.DisplayName = file.Name;
                                            }

                                        } else { // Plik nie ma rozszerzenia

                                            guid = file.Name.slice(-36, sLen);
                                            if (guid.match(guidPattern)) {
                                                file.DisplayName = file.Name.slice(0, sLen - 37);
                                            } else {
                                                file.DisplayName = file.Name;
                                            }

                                        }

                                        return file;

                                    });

                                } else {
                                    interaction.data.availableFiles = unpackedList.Files.map(function (file) {
                                        file.DisplayName = file.Name;
                                    });
                                }

                        } else {

                            interaction.data.availableFiles = [];

                        }

                    }).catch(function(err){
                            log.error(err);
                    });

                    }
                    */

            if (interaction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat &&
              (interaction.session.Session.BaseSessionObject.SessionProperties.VideoEnabled === true ||
                interaction.session.Session.BaseSessionObject.SessionProperties.AudioEnabled === true)) {

              // Show waiting message
              if (sState === ax.SessionLib.AxSessionState.AcceptedByUser) {
                Data.remoteVideoOverlayVisible = true;

                // Join to server session
                log.debug("Joining to video session id: " + interaction.session.Session.SessionId);

                var constraint = {
                  video: {
                    mandatory: {
                      maxWidth: 320,
                      maxHeight: 240
                    }
                  }, audio: true
                };

                mClient.makeSessionCall(interaction.session.Session.SessionId, constraint);

              } else if (sState === ax.SessionLib.AxSessionState.EndedByRemoteSide ||
                sState === ax.SessionLib.AxSessionState.EndedByUser ||
                sState === ax.SessionLib.AxSessionState.Rejected ||
                sState === ax.SessionLib.AxSessionState.RejectedByRemoteSide ||
                sState === ax.SessionLib.AxSessionState.RejectedByUser ||
                sState === ax.SessionLib.AxSessionState.SystemTerminated) {

                var localVideo = document.getElementById("localVideo");
                localVideo.src = "";

                mClient.leaveSession(interaction.session.Session.SessionId).then(function (res) {
                  log.debug("Leave video session response", res);
                }, function (err) {
                  log.error("Leave video session error", err);
                }).then(function () {
                  // Hide video element

                });

              }

            }

          } else if (interaction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {

            if (interaction.session.Session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser) {
              // Send event to server
              SessionClient.sendEmailEvent({
                type: ax.SessionLib.AxEmailEventsType.Session,
                eventType: ax.SessionLib.AxEmailEventsEventType.Received,
                userId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
                userStatusUid: Data.currentStatus.UId,
                emailMessageId: interaction.session.Session.BaseMessageObject.Id,
                sessionId: interaction.session.Session.SessionId,
                tag: "WebCommunications"
              }).then(function (res) {
                log.debug("Send email event " + ax.SessionLib.AxEmailEventsEventType.Received + " OK");
              }).catch(function (err) {
                log.error("Send email event error", err);
              });
            }

            // Load email to frame
            var sessionPageAddress;
            // If session page has relative address add base ufp address
            if (typeof config.ufp.sessionPage === "string" && config.ufp.sessionPage.substr(0, 5) === "http:") {
              sessionPageAddress = config.ufp.sessionPage;
            } else {
              sessionPageAddress = SettingsData.get('UfpServiceAddress') + config.ufp.sessionPage
            }

            interaction.data.web.inboundUrl = sessionPageAddress + "?" + ["InteractionMailMessageId=" + interaction.session.Session.BaseMessageObject.Id,
              "sessionId=" + interaction.session.Session.SessionId].join('&');

          }

          // Dodajemy sesję (interakcję) do listy sesji, jeżeli już istnieje, to zostaje zamieniona
          SessionService.addSessionToList(interaction);

          var currentInteraction = SessionService.getSessionById(interaction.session.Session.SessionId); // Tak, żeby mieć referencję do dobrego obiektu (funkcja dodająca do listy kopiuje propertisty bo... angular)

          // Set shifting buttons
          var shiftMode;
          if (currentInteraction.session.ShiftingParams !== null) {
            shiftMode = currentInteraction.session.ShiftingParams.ShiftMode;
          }
          if (shiftMode === ax.SessionLib.AxDataRecordShiftingMode.Free
            || shiftMode === ax.SessionLib.AxDataRecordShiftingMode.InCampaignTime) {
            Data.shiftTime.selectedButton = 'campaign';
          } else if (shiftMode === ax.SessionLib.AxDataRecordShiftingMode.OnlyToMe
            || shiftMode === ax.SessionLib.AxDataRecordShiftingMode.OnlyToMeInCampaignTime
            || shiftMode === ax.SessionLib.AxDataRecordShiftingMode.InCampaignTime) {
            Data.shiftTime.selectedButton = 'user';
          } else {
            Data.shiftTime.selectedButton = null; // Must reset becouse of earlier settings
          }

          if (shiftMode === ax.SessionLib.AxDataRecordShiftingMode.OnlyToMe
            || shiftMode === ax.SessionLib.AxDataRecordShiftingMode.OnlyToMeInCampaignTime
            || shiftMode === ax.SessionLib.AxDataRecordShiftingMode.Forbidden) {
            Data.shiftTime.disabledButton = 'campaign';
          } else if (shiftMode === ax.SessionLib.AxDataRecordShiftingMode.InCampaignTime
            || shiftMode === ax.SessionLib.AxDataRecordShiftingMode.Forbidden) {
            Data.shiftTime.disabledButton = 'user';
          } else {
            Data.shiftTime.disabledButton = null; // Must reset becouse of earlier settings
          }

          // Reset shift window values
          Data.shiftTime.note = "";
          Data.shiftTime.timePicker.time = null;
          Data.shiftTime.datePicker.dt = null;

          if (currentInteraction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.PreviewCall) {

            // If session is accepted remove record from preview record list
            if (currentInteraction.session.Session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser ||
              currentInteraction.session.Session.SessionState === ax.SessionLib.AxSessionState.InitiationFailed

            ) {

              //  Jeżeli mamy ustawiony tryb preview onConnect
              // i zadzwonimy na busy, to przychodzi sesionDirected bez rekordu
              // i nie wiemy który rekordu usunąć
              // Może trzeba to usuwać w innym miejscu?
              // Np od razu po połączeniu z kontrolki preview??
              /*if ( currentInteraction.session.DataRecord !== null ) {
                         sClient.removePreviewRecord(currentInteraction.session.DataRecord.Id);
                         }*/

            }

            // This is set in preview directive
            if (Data.autoNavigateToInteraction === true) {

              $scope.closeAllMainButThis('interactions');

              $scope.switchToFirstSession();

            }

          }

          // Auto close session
          if (sState === AxState.EndedByRemoteSide ||
            sState === AxState.EndedByUser ||
            sState === AxState.InitiationFailed ||
            sState === AxState.InvalidDestination ||
            sState === AxState.RejectedByRemoteSide ||
            sState === AxState.Rejected ||
            sState === AxState.RejectedByUser ||
            sState === AxState.SystemTerminated ||
            sState === AxState.UnableToEstablisheWithUser ||
            sState === AxState.WaitingTimeOut) {

            if (currentInteraction.data.web.autoCloseForm === true) {
              log.debug('[WebAgent.onSessionDirectedReceived] Auto closing interaction ' + currentInteraction.session.Session.SessionId);
              $scope.closeSession(currentInteraction);
            }

          }


        });

      }

      /**
       * @param {ax.WebLib.Events.AxSessionClientConectingEvent} e
       * @this ax.SessionLib.AxSessionServerClient
       */
      function onSessionClientConnecting(e) {
        //$rootScope.$apply(function() {
        Data.statusMessage = li18n.messages.connecting;
        //});
      }

      /**
       * @param {ax.WebLib.Events.AxSessionClientConnectedEvent} e
       * @this ax.SessionLib.AxSessionServerClient
       */
      function onSessionClientConnected(e) {
        log.debug("onSessionClientConnected", e);
        Data.statusBarError = false;
        Data.statusMessage = li18n.messages.connected;
        $scope.$apply();
      }

      /**
       * @param {ax.WebLib.Events.AxSessionClientLoginStartEvent} e
       * @this ax.SessionLib.AxSessionServerClient
       */
      function onSessionClientLoginStart(e) {
        log.debug("onSessionClientLoginStart", e);
        Data.statusMessage = li18n.messages.loggingIn;
        Data.loginMessage = li18n.messages.loggingIn;
        $scope.$apply();
      }

      /**
       * @param {ax.WebLib.Events.AxSessionClientLoginResponseReceivedEvent} e
       * @this ax.SessionLib.AxSessionServerClient
       */
      function onSessionClientLoginResponseReceived(e) {
        log.debug("onSessionClientLoginResponseReceived", e);
        var params = e.response,
          message = e.message;
        if (params.Result === ax.SessionLib.AxUserLoginResponseResult.LoggedIn) {
          // Save logged in user
          saveCurrentUser(params.AccountData);
          // Save system variables
          saveSystemVariables(message.SystemVariables);
          $scope.SmsPadEnabled = SystemVariables.SmsPadEnabled && !!parseInt(SystemVariables.SmsPadEnabled.Value);

          Data.statusBarError = false;
          // Generate status list
          var userStatuses = makeStatusList(params.AvailableStatuses.Statuses);

          var stateId;
          if (lastStatusBeforeDisconnect !== null && Data.statuses !== null) {
            // i czy jest na liście statusów
            var sL = userStatuses.statuses.length;
            for (var j = 0; j < sL; j++) {
              if (userStatuses.statuses[j].StateId === lastStatusBeforeDisconnect.StateId) {
                stateId = lastStatusBeforeDisconnect.StateId;
                break;
              }
            }
            lastStatusBeforeDisconnect = null;
          } else {
            // If default state is not set in user settings, set state to first default loggedIn state
            stateId = params.AccountData.AfterLoginStateId || userStatuses.loggedIn.StateId;
          }

          var setStatusPromise = sClient.setStatusById(stateId, true);

          setStatusPromise.then(function (res) {

            if (res.Params.Result === ax.SessionLib.AxRequestUserStatusResult.Changed) {
              setCurrentStatus(res);
            } else {
              log.warn("Status not changed Result: " + res.Params.Result);
            }

          }, function (err) {
            log.error("Status not changed", err);
          });

          Data.statuses = userStatuses.statuses;

        } else {
          Data.statusMessage = getMessageByLoginResult(params.Result);
        }
        $scope.$apply();
      }

      function onUserStatisticReceived(event) {
        if (!(event && event.event && event.event.Data)) {
          return;
        }

        if ( Data.currentUser !== null ) {
          const statisticsData = event.event.Data.get(Data.currentUser.Id);
          StatisticHelperService.setStatisticData(statisticsData);
        }

      }

      function onFileTransferStatus(e) {

        var session = SessionService.getSessionById(e.transferStatus.SessionId);

        function calculatePercent(transferStatus) {

          var transferred = transferStatus.BytesTransferred;
          var fileSize = transferStatus.FileSize;

          return transferred.multiply(100).divide(fileSize).valueOf();

        }

        if (session !== null) {

          var receivedFiles = session.data.receivedFiles;

          var found = false;
          for (var transferId in receivedFiles) {

            if (transferId === e.transferStatus.FileTrasferId) {
              var current = receivedFiles[transferId];
              current.state = e.transferStatus.State;
              if (e.transferStatus.FileSize.valueOf() > 0) {
                current.percent = calculatePercent(e.transferStatus);

              }
              if (e.transferStatus.State === ax.SessionLib.AxChatChannelFileTransferEventState.Completed) {
                current.href = e.transferStatus.FileAccessUrl;
              } else {
                current.href = null;
              }
              found = true;
              break;
            }

          }

          var trId = e.transferStatus.FileTransferId;
          if (found === false) {
            receivedFiles[trId] = {
              name: e.transferStatus.FileName,
              state: e.transferStatus.State,
            };

            if (e.transferStatus.FileSize.valueOf() > 0) {
              receivedFiles[trId].percent = calculatePercent(e.transferStatus);
            }

            if (e.transferStatus.State === ax.SessionLib.AxChatChannelFileTransferEventState.Completed) {
              receivedFiles[trId].href = config.chatFilesRoot ? config.chatFilesRoot + e.transferStatus.FileAccessUrl : e.transferStatus.FileAccessUrl;
            } else {
              receivedFiles[trId].href = null;
            }
          }
        }

      }

      /**
       * @param {ax.WebLib.Events.AxSessionClientDisconnectedEvent} e
       * @this ax.SessionLib.AxSessionServerClient
       */
      function onSessionClientDisconnected(e) {

        log.warn("[WebAgent.onSessionClientDisconnected] Session client disconnected");
        Data.statusBarError = true;

        Data.statusMessage = li18n.messages.connectionLost;
        var cStatus = Data.currentStatus, state = ax.SessionLib.AxUserState;

        if (cStatus !== null && lastStatusBeforeDisconnect === null && (cStatus.State === state.Aux || cStatus.State === state.LoggedIn || cStatus.State === cStatus.Available)) {
          lastStatusBeforeDisconnect = cStatus;

        }

        var response = new ax.SessionLib.AxMessages.AxMsgUserSetStatusResponse();
        var statusParams = new ax.SessionLib.AxUserRequestStatusResponseParams();
        var status = ax.SessionLib.AxUserStateParam.Empty();
        status.CanBeChanged = false;
        response.Params = statusParams;
        response.StateParam = status;

        setCurrentStatus(response);

        Data.statuses = [];

        Data.campaignList = [];
        Data.contacts = [];


        // Hangup underyling media call
        log.debug("[WebAgent.onSessionClientDisconnected] Trying do disconnect all calls");
        userPhone.phone.hangupMediaForAllCalls(ax.AxCommonLib.Telephony.AxCallEventCause.NormalClearing).then(function (res) {
          log.debug("[WebAgent.onSessionClientDisconnected] All calls disconnected");
          userPhone.phone.removeAllCalls();
        }).catch(function (err) {
          log.warn("[WebAgent.onSessionClientDisconnected] Error disconnecting calls, but it's OK");
          userPhone.phone.removeAllCalls();
        });

        $scope.$apply();

      }

      /**
       * Saves informations about current user
       * @param {ax.AxDataLib.Users.AxUserAccountElement} account
       */
      function saveCurrentUser(account) {

        Data.currentUser = account;
        Data.statusMessage = config.server + " " + Data.currentUser.Name + ' ' + Data.currentUser.Surname + " (" + Data.currentUser.Login + ", Id: " + Data.currentUser.Id + ')';
        $scope.$apply();

      }

      /**
       * Saves system variables to SystemVariables object
       * @param Array.<ax.AxEnvironmentLib.Collections.AxSystemVarDataElement>
       */
      function saveSystemVariables(sysVars) {

        var l, i, sV;
        if (!Array.isArray(sysVars)) {
          log.warn("sysVars is not an array");
          return;
        }

        l = sysVars.length;
        for (i = 0; i < l; i++) {
          sV = sysVars[i];
          SystemVariables[sV.Name] = sV;
        }

      }

      /**
       * User statuses ready to assign to template
       * @param {Array.<ax.SessionLib.AxUserStateParam>} statuses
       * @param {ax.SessionLib.AxUserStateParam} loggedIn
       */
      function UserStatuses(statuses, loggedIn) {
        this.statuses = statuses;
        this.loggedIn = loggedIn;
      }

      /**
       * Make status list
       * Returns object with status list and default loggedIn status redy for assign to template
       * @param {Array.<ax.SessionLib.AxUserStateParam>}
       * @return {MainController.UserStatuses}
       */
      function makeStatusList(statuses) {
        // Generujemy listę statusów

        var statusesClean = [], loggedInState = null;

        statusesClean = statuses.filter(function (status) {

          if (loggedInState === null && status.State == 1) {
            loggedInState = status;
          }

          if (status.Visible === true) {
            status.Name = li18n.statuses[status.State];
            return status;
          }

        });

        return new UserStatuses(statusesClean, loggedInState);

      }

      /**
       * Sets current status on list
       * @param {ax.SessionLib.AxMessages.AxMsgUserSetStatusResponse} res
       */
      function setCurrentStatus(res) {

        res.StateParam.Name = li18n.statuses[res.StateParam.State];
        if (res.Params.MaxTime !== null) {
          var progress = document.getElementById("sProgress");
          progress.style.animationDuration = res.Params.MaxTime + "ms";
          Data.statusProgressVisible = true;
        }
        Data.currentStatus = res.StateParam;

        Data.currentStatus.Duration = "00:00:00";
        TimerService.startCountStateTime();

      }

      /**
       * @param ax.SessionLib.AxUserLoginResponseResult
       */
      function getMessageByLoginResult(result) {
        var mes = "";
        var dict = ax.SessionLib.AxUserLoginResponseResult;
        if (result === dict.AlreadyLogged) {
          mes = li18n.messages.alreadyLogged;
        } else if (result === dict.BadCredintials) {
          mes = li18n.messages.invalidCredentials;
        } else if (result === dict.AccountDisabled) {
          // We do not want to show user details about reason
          mes = li18n.messages.invalidCredentials;
        } else if (result === dict.NetworkAccessRestricted) {
          mes = li18n.messages.networkAccessRestricted;
        } else if (result === dict.NotAvailableLicence) {
          mes = li18n.messages.licenceError;
        } else if (result === dict.PasswordExpired) {
          mes = li18n.messages.passwordExpired;
        } else if (result === dict.PleasePickupTeleset) {
          mes = li18n.messages.pleasePickupTeleset;
        } else if (result === dict.ProviderError) {
          mes = li18n.messages.providerError;
        } else if (result === dict.ServiceTemporaryUnavaileable) {
          mes = li18n.messages.serviceTemporaryUnavailable;
        } else if (result === dict.ServiceUnavaileable) {
          mes = li18n.messages.serviceUnavailable;
        } else if (result === dict.StationIsAccessedByAnotherUser) {
          mes = li18n.messages.stationAccessedByAnotherUser;
        } else if (result === dict.StationIsInvalid) {
          mes = li18n.messages.invalidStation;
        }

        return mes;
      }

      function getMessageByMediaProxyError(err) {

        var messages = li18n.messages;
        var mes = "Media proxy ";
        if (err instanceof MediaProxyClientService.ERRORS.CONNECT_ERROR) {
          mes += messages.connectionError;
        } else if (err instanceof MediaProxyClientService.ERRORS.LOGIN_ERROR) {
          mes += messages.loginError;
        } else {
          mes += messages.unknown;
        }

        return mes;

      }

      // Inicjalizuje aplikację
      // 1. Tworzy comclienta
      // 2. Tworzy session clienta
      // 3. Rejestruje ComClienta
      // 4. Loguje się
      // 5. Tworzy klienta czatu
      // 6. Tworzy telefon
      // 7. Tworzy klienta media proxy i loguje się
      $scope.initialize = function () {

        WebRtcBuddyService.initialize({
          iceTimeout: config.vPhone.iceTimeout
        });

        WebRtcBuddyService.on({
          evtName: "usermediaacquired",
          callback: function (evt) {
            log.debug("[WebCommunications] Received usermediaacquired", evt);
            $scope.$apply(function () {
              //VideoData.localVideoSrc = window.URL.createObjectURL(evt.data.stream);
              VideoData.localVideoSrc = function () {
                return evt.data.stream;
              }
            });

          },
          scope: $scope
        });

        WebRtcBuddyService.on({
          evtName: "remotestreamadded",
          callback: function (evt) {
            log.debug("[WebCommunications] Received remotestreamadded", evt);
            //VideoData.remoteVideoSrc = window.URL.createObjectURL(evt.data.stream);
            VideoData.remoteVideoSrc = function () {
              return evt.data.stream;
            };
          },
          scope: $scope
        });

        WebRtcBuddyService.on({
          evtName: "remotetrackadded",
          callback: function (evt) {
            log.debug("[WebCommunications] Received remotetrackadded", evt);
            if (VideoData.remoteVideoSrc === null || VideoData.remoteVideoSrc === "") {
              log.debug("[WebCommunications] Adding stream from track to video element");
              VideoData.remoteVideoSrc = function () {
                return evt.data.streams[0];
              }
            }
          },
          scope: $scope
        });

        WebRtcBuddyService.on({
          evtName: "remotestreamremoved",
          callback: function (evt) {
            log.debug("[WebCommunications] Received ", evt);
            VideoData.remoteVideoSrc = "";
          },
          scope: $scope
        });

        var passwd = SimpleEncode(config.password);
        var passwdMd5 = md5(config.password);
        /*var logWorkerConfig = new ax.AxComLib.AxComClientSettings({
                proto: config.proto,
                server: config.server,
                port: config.loggerPort,
                login: config.login,
                password: passwd,
                // TODO: Services zmienić na provider logów jak będzie zrobiony
                services: [ax.AxCommonLib.AxProviderType.TelephonyProvider],
                filters: []
            });*/

        //logWorker.postMessage(createWorkerMessage("initialize", logWorkerConfig));

        var sessionClient = sClient;
        Data.sClient = sClient;

        var lib = ax.AxSwitchIntegrationLib;

        // Initialize AxSessionServerClient
        var sessionClientSettings = new ax.AxCommonLib.Sessions.AxSessionServerClientSettings({
          proto: config.proto,
          server: config.server,
          port: config.port,
          login: config.login,
          password: passwd,
          autoReconnect: false, // After succes login this is changed to true
          reconnectAfter: 5000,
          getPermissions: true
        });

        sessionClient.removeListener("connecting", onSessionClientConnecting);
        sessionClient.removeListener("loginstart",onSessionClientLoginStart);
        sessionClient.removeListener("loginresponsereceived", onSessionClientLoginResponseReceived);
        sessionClient.removeListener("connected", onSessionClientConnected);
        sessionClient.removeListener("disconnected", onSessionClientDisconnected);

        var comClientSettings = new ax.AxComLib.AxComClientSettings({
          proto: config.proto,
          server: config.server,
          port: config.port,
          login: config.login,
          password: passwd,
          tag: "AxHostWin"
        });

        if (("chat" in config) === false) {

          config.chat = {
            receivedFilesEnabled: true,
            availableFilesRepositoryRoot: "",
            removeGuidFromFileName: false
          }

        } else {

          if (("receivedFilesEnabled" in config.chat) === false) {
            config.chat.receivedFilesEnabled = true;
          }

          if (("availableFilesRepositoryRoot" in config.chat) === false) {
            config.chat.availableFilesRepositoryRoot = "";
          }

          if (("removeGuidFromFileName" in config.chat) === false) {
            config.chat.removeGuidFromFileName = false;
          }
        }

        return ComClient.initialize({
          webLib: WL,
          settings: comClientSettings
        }).then(function (res) {
          log.debug("[WebCommunications] ComClient initialized", res);

          if (res.PasswordExpired) {

            Data.password = "";
            Data.loginMessage = "";
            ChangePasswordData.visible = true;

            return Promise.reject("PASSWORD_CHANGE_REQUIRED");

          } else {

            var ufpServiceAddress = UtilsService.findSystemSetting(
              'UfpServiceAddress', res.SystemVariables
            );

            var value = ufpServiceAddress.Value.endsWith('/')
              ? ufpServiceAddress.Value : ufpServiceAddress.Value + '/';

            SettingsData.set('UfpServiceAddress', value);

            return ComClient.disconnect().then( () => LoginService.login({
              login: config.login,
              password: passwdMd5
            }));

          }

        }).then(function (res) {

          log.debug("[loginToSystem] Login to login service OK");
          log.debug(res);
          // TODO: Jakiś sensowny serwis dla trzymania state, na razie musi być tak
          Data.refreshToken = res.refreshToken;

          // Run token refresh
          // TODO: to właściwie to powinno sobie chyba chodzić w dedykowanym WebWorkerze, żeby było weselej :)
          setInterval(function () {

            log.debug("[RefreshTokenWorker] Trying to refresh token");
            LoginService.refreshSession({
              refreshToken: Data.refreshToken
            }).then(function (res) {

              log.debug("[RefreshTokenWorker] Token refreshed", res);
              Data.refreshToken = res.refreshToken;

            }).catch(function (err) {
              // TODO: Sygnalizacja błędów na zewnątrz
              log.error("[RefreshTokenWorker] Cyclic Refresh token error, ", err);
            });

          }, config.services.login.refreshSessionInterval * 60 * 1000);


          return Promise.resolve(Data.refreshToken);

        }).then(function (refreshToken) { // Get generic token

          return LoginService.getAccessToken({ refreshToken: refreshToken })

        }).then(function (res) { // Remember generic token

          if (res.result == 0) {
            Data.accessToken = res.accessToken
          }

        }).then(function () {

          sessionClient.on("connecting", onSessionClientConnecting);
          sessionClient.on("loginstart", onSessionClientLoginStart);
          sessionClient.on("loginresponsereceived", onSessionClientLoginResponseReceived);
          sessionClient.on("connected", onSessionClientConnected);
          sessionClient.on("disconnected", onSessionClientDisconnected);

          return sessionClient.initialize(sessionClientSettings);

        }).then(function (sClient) {

          log.debug("[webagent.initialize] Session client initialized", sClient);
          Data.currentUser = sClient.loginResponse.LoginResponseParams.AccountData;

          // Load settings from storage;
          var action = loadSettingsRequest(sClient.loginResponse.LoginResponseParams.AccountData.Id);
          $ngRedux.dispatch(action);

          //////////////////////////////////////////////////////
          var mediaServerLoginPromise = Sender.sendWebRTCAssignStation(sClient.loginResponse.LoginResponseParams.AccountData.Id);
          mediaServerLoginPromise.then(function (res) {
            log.debug("WebRTClogin response received");
            log.debug(res);
          }, function (err) {

          });

          // TODO: Inicjalizacja media klienta powinna być chyba niezależna od sClienta, chyba, że zrobimy później jakąś autoryzację
          var participants = [];

          function findParticipantByAccountId(accountId) {

            var l = participants.length, current;
            for (var i = 0; i < l; i++) {
              current = participants[i];
              if (current.participant.participant.AccountId === accountId) {
                return current;
              }

            }

            return null;

          }

          // Initialize media server client
          mClient.on("usermediaacquired", function (evt) {

            log.debug("User media acquired", evt);
            var localVideo = document.getElementById("localVideo");
            log.debug("Local video element", localVideo);
            localVideo.src = window.URL.createObjectURL(evt.stream);

          });

          mClient.on("participantjoined", function (evt) {

            // TODO: To powinno być na response, jak session call się powiedzie.. ale na razie nie mamy takiego miejsa, trzeba dodać chyba dodatkowy event
            // który o tym poinformuje
            $rootScope.$apply(function () {
              Data.remoteVideoOverlayVisible = true;
            });

            log.debug("New participant joined to session", evt.participant);

            var video = document.createElement("video");
            video.autoplay = true;
            var remoteVideos = document.getElementById("remoteVideos");
            remoteVideos.appendChild(video);
            var participant = { video: video, participant: evt.participant };

            participant.participant.on("remotestreamadded", function (sEvt) {
              log.debug("Stream from remote participant was added to peer connection");
              participant.video.src = window.URL.createObjectURL(sEvt.stream);
              $rootScope.$apply(function () {
                Data.remoteVideoOverlayVisible = false;
              });

            });

            participants.push(participant);

          });

          mClient.on("participantdisconnected", function (evt) {
            log.debug("Participant disconnected", evt);
            var participant = findParticipantByAccountId(evt.participant.AccountId);

            if (participant) {
              console.info("Participant found. Removing video element and from collection");
              var remoteVideos = document.getElementById("remoteVideos");
              // Remove participiant
              remoteVideos.removeChild(participant.video);
              participants.splice(participants.indexOf(participant), 1);
            } else {
              console.warn("Disconnected participant not found");
            }

          });

          mClient.initialize({
            rtcConfiguration: config.rtcConfiguration,
            accountId: 1008
          });

          //////////////////////////////////////////////////////

          // After login we want to automatically reconnect
          sClient.autoReconnect = true;
          // 2. Create chat client instance
          chatClient.client = new ax.ChatLib.ChatClient(sClient);
          chatClient.client.on("userslistreceived", onGetUserListResolved);
          chatClient.client.on("internalchatmessagereceived", onInternalChatMessageReceived);
          var msg = sClient.loginResponse;
          var rParams = msg.LoginResponseParams;

          // Save system variables
          saveSystemVariables(msg.SystemVariables);
          $scope.SmsPadEnabled = SystemVariables.SmsPadEnabled && !!parseInt(SystemVariables.SmsPadEnabled.Value);
          //Data.loginInProgress = false;

          // Generate status list
          var userStatuses = makeStatusList(rParams.AvailableStatuses.Statuses);

          // If default state is not set in user settings, set state to first default loggedIn state
          var stateId = msg.LoginResponseParams.AccountData.AfterLoginStateId || userStatuses.loggedIn.StateId;

          var setStatusPromise = sClient.setStatusById(stateId, true);

          setStatusPromise.then(function (res) {

            if (res.Params.Result == ax.SessionLib.AxRequestUserStatusResult.Changed) {
              // Set status in status item
              $rootScope.$apply(function () {
                setCurrentStatus(res);
              });

            } else {
              log.warn("Status not changed Result: " + res.value.Params.value.Result);
            }

          }, function (err) {
            log.error("Status not changed");
            log.error(err);
          });

          Data.statuses = userStatuses.statuses;

          sClient.removeListener("userstatelistreceived", onUserStateListReceived);
          sClient.removeListener("campaignslistreceived", onCampaignsListReceived);
          sClient.removeListener("setuserstatusreceived", onSetUserStatusReceived);
          sClient.removeListener("sessiondirectedreceived", onSessionDirectedReceived);
          sClient.removeListener("sessionchatmessagereceived", onSessionChatMessageReceived);
          sClient.removeListener("filetransferstatus", onFileTransferStatus);
          sClient.removeListener("userstatisticreceived", onUserStatisticReceived);

          sClient.on("userstatelistreceived", onUserStateListReceived);
          sClient.on("campaignslistreceived", onCampaignsListReceived);
          sClient.on("setuserstatusreceived", onSetUserStatusReceived);
          sClient.on("sessiondirectedreceived", onSessionDirectedReceived);
          sClient.on("sessionchatmessagereceived", onSessionChatMessageReceived);
          sClient.on("filetransferstatus", onFileTransferStatus);
          sClient.on("userstatisticreceived", onUserStatisticReceived);

          return Promise.resolve(sClient);

        }).then(function (sClient) {

          // User may not have station assigned
          if (sClient.loginResponse.LoginResponseParams.StationData === null) {
            log.warn("[WebAgent.initialize] Station not assigned");

            return Promise.resolve("STATION_NOT_ASSIGNED");
          }
          // 2. Create an instance of UserPhone
          // Set already reserved null value to created phone instance
          if (sClient.loginResponse.LoginResponseParams.StationData.StationType === ax.AxCommonLib.Telephony.AxStationType.WebRTC) {

            userPhone.phone = new lib.AxVertoUserPhone(WL);
            userPhone.phone.on("remotestreamadded", function (evt) {
              log.debug("[WebCommunications.phone.onremotestreamadded] ", evt);
              var rAudio = document.getElementById("axRemoteAudio");
              try {
                // https://www.chromestatus.com/features/5618491470118912
                rAudio.srcObject = evt.stream;
              } catch (e) {
                rAudio.src = window.URL.createObjectURL(evt.stream);
              }
            });

            userPhone.phone.on("remotetrackadded", function (evt) {
              var rAudio = document.getElementById("axRemoteAudio");
              log.debug("[WebCommunications.phone.onremotetrackadded] ", evt);
              log.debug("[WebCommunications] Creating media stream");
              var stream = new MediaStream([evt.track]);
              rAudio.srcObject = stream;

            });

          } else if (sClient.loginResponse.LoginResponseParams.StationData.StationType === ax.AxCommonLib.Telephony.AxStationType.Virtual) {

            userPhone.phone = new lib.AxSwitchUserPhone(WL);

          } else {

            log.warn("Unsupported station type " + sClient.loginResponse.LoginResponseParams.StationData.StationType);
            return Promise.resolve("UNSUPPORTED_STATION_TYPE");

          }

          PhoneData.stationMessage = li18n.messages.initializingPhone;

          var phone = userPhone.phone;
          Data.phone = phone;
          // 3. Event handler must be here, if you want to catch button state changes after initialization (success or failed)
          // because callbacks are executed before promise resolve
          phone.on("phonebuttonstatechanged", function (evt) {

            log.debug("[WebAgent.phone.phonebuttonstatechanged] Phone buttons state changed", evt);

            // Update buttons states
            $scope.updateButtons(evt);

          });

          phone.on("phonestatechanged", function (evt) {

            $scope.$apply(function () {

              var pState = ax.AxCommonLib.Telephony.AxUserPhoneState;
              log.debug("[WebAgent.phone.phonestatechanged] State: " + evt.state);
              if (evt.state === pState.Closed || evt.state === pState.RegistrationFailed
                || evt.state === pState.Failed || evt.state === pState.ConnectFailed) {

                PhoneData.stationMessage = li18n.messages.stationDisconnected;
                Data.phoneVisible = false;

              } else if (evt.state === ax.AxCommonLib.Telephony.AxUserPhoneState.Connecting) {
                PhoneData.stationMessage = li18n.messages.initializingPhone;
                Data.phoneVisible = false;
              } else if (evt.state === ax.AxCommonLib.Telephony.AxUserPhoneState.Registered) {
                Data.phoneVisible = true;
                PhoneData.stationMessage = "";
              } else if (evt.state === ax.AxCommonLib.Telephony.AxUserPhoneState.Initialized) {
                Data.phoneVisible = true;
                PhoneData.stationMessage = "";
              }

            });


          });


          // 3. Create an instance of AxUserPhoneSettings
          var device = sClient.loginResponse.LoginResponseParams.StationData.DialingNumber;

          var stationData = sClient.loginResponse.LoginResponseParams.StationData;


          var stationLogger = log4javascript.getLogger("VertoUserPhone");
          stationLogger.setLevel(log4javascript.Level[config.log.station.level]);
          var stationConsoleAppender = new log4javascript.BrowserConsoleAppender();
          stationConsoleAppender.setThreshold(log4javascript.Level[config.log.station.level]);
          stationLogger.addAppender(stationConsoleAppender);

          var rtcConfiguration = config.rtcConfiguration;

          if (phone instanceof ax.AxSwitchIntegrationLib.AxVertoUserPhone) {
            rtcConfiguration = makeGetRtcConfiguration(config.rtcConfiguration);

          }

          var conf = new ax.AxCommonLib.Telephony.AxUserPhoneSettings({
            device: device,
            stationData: stationData,
            rtcConfiguration: rtcConfiguration,
            iceTimeout: config.vPhone.iceTimeout,
            relayOnly: config.vPhone.relayOnly,
            removeHostCandidates: config.vPhone.removeHostCandidates,
            removeIPv6Candidates: config.vPhone.removeIPv6Candidates,
            logger: stationLogger,
            autoReconnect: true,
            reconnectAfter: 5000
          });
          // 4. Initialize phone
          // Pytanie czy STATION_INITIALIZE_ERROR nie powinien być zwracany przez initialize()?
          return phone.initialize(conf).catch(function (err) {
            log.error("Station initialization error", err);
            return 'STATION_INITIALIZE_ERROR';
          });

        }).then(function (phone) {
          var ERRORS = [
            'STATION_NOT_ASSIGNED',
            'STATION_INITIALIZE_ERROR',
            'UNSUPPORTED_STATION_TYPE'
          ];

          if (ERRORS.indexOf(phone) !== -1) {
            return Promise.resolve(phone);
          }

          log.info("Phone initialized", phone);

          // Event handler
          phone.on("callstatechanged", function (evt) {

            $rootScope.$apply();

            log.debug("Call state changed");
            log.debug(evt);

            var cause = li18n.messages.unknown;
            // Buttons are managed by phone itself
            if (evt.target.Call.State === ax.AxCommonLib.Telephony.AxCallState.Disconnected) {

              Data.localRingAudio.element.pause();
              Data.localRingAudio.element.src = '/assets/sounds/failed.wav';
              Data.localRingAudio.element.loop = false;
              Data.localRingAudio.element.play().then(function (p) {
                log.trace("[WebCommunications.oncallstatechanged] Playing failed sound started");
              }).catch(function (err) {
                log.trace("[WebCommunications.oncallstatechanged] Playing failed sound interrupted", err);
              });

              // Show tooltip
              Data.callTooltip.visible = true;
              var c = ax.AxCommonLib.Telephony.AxCallEventCause;

              switch (evt.target.Cause) {
                case c.NormalClearing:
                  cause = li18n.axCallEventCause[48];
                  break;

                case c.Normal:
                  cause = li18n.axCallEventCause[78];
                  break;

                case c.DestNotObtainable:
                  cause = li18n.axCallEventCause[13];
                  break;

                case c.Busy:
                  cause = li18n.axCallEventCause[3];
                  break;

              }
              Data.callTooltip.red = false;
              Data.callTooltip.text = li18n.messages.callEnded;
              Data.callTooltip.cause = cause;
              Data.callTooltip.number = evt.target.Call.Ani.Ani;


              // Ale jeżeli poszedł transfer, to mógł przyjść event rozłączena pierwszego calla
              // i dlatego nie możemy tutaj robić unholdu bo zaraz przyjdzie event rozłączenia drugiego calla...
              // TODO: Maybe it should be retrived by phone???
              // Check if we have holded call
              // If yes, retrieve it
              if (phone.calls.length === 1) {
                var lastCall = phone.calls.getAsArray()[0];
                if (lastCall.State == ax.AxCommonLib.Telephony.AxCallState.Holded) {
                  phone.retrieveCall(lastCall).then(function (res) {
                    log.debug("Call unholded");
                  }, function (err) {
                    log.error("ERROR! Unhold call failed 1")
                    log.error(err);
                  });
                }
              }

              // Send busy indicator
              log.debug("[WebAgent.phone.oncallstatechanged.disconnected] Sending busy indicator calls length: " + phone.calls.length);
              sClient.sendStationBusyIndicator({ stationBusy: phone.calls.length > 0 });

              // TODO: To powinno być jakoś tak zrobiozne, że jest calback podpięty na zmianę liczników
              // i w zależności od tego jaki licznik się zmieni
              // To się coś tam zadzieje
              // Czyli powinna do tego być raczej jakas kontrolka niższego poziomu i to dopiero opakowane w serwis angularea
              // Bo to musi być jakaś sensowna maszyna stanów
              // Pilnowanie tych ujemnych wartości tez powinno być na poziomie maszyny stanów i jakims metodami inc/dec, a nie tutaj
              if (NotificationData.newSessionNotification.voiceSessionCnt > 0) {
                NotificationData.newSessionNotification.voiceSessionCnt -= 1;
              }

              NotificationService.showOrHideSessionNotification({
                voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
                li18n: li18n
              });

            } else if (evt.target.Call.State === ax.AxCommonLib.Telephony.AxCallState.Alerting) {
              if ((evt.target.Call.Direction === ax.AxCommonLib.Telephony.AxCallDirection.Inbound
                || (evt.target.Call.Direction === ax.AxCommonLib.Telephony.AxCallDirection.Outbound &&
                  (evt.target.Call.CreateCause === ax.AxCommonLib.Telephony.AxCallEventCause.MakePbxCall ||
                    (
                      (evt.target.Call.CreateCause === ax.AxCommonLib.Telephony.AxCallEventCause.Consultation
                        || evt.target.Call.CreateCause === ax.AxCommonLib.Telephony.AxCallEventCause.SingleStepTransfer
                      ) && evt.target.Call.Dnis.Ani === sClient.loginResponse.LoginResponseParams.StationData.DialingNumber))))
                && !evt.target.Call.AutoAnswer) {

                Data.localRingAudio.element.pause();
                Data.localRingAudio.element.src = '/assets/sounds/incoming.wav';
                Data.localRingAudio.element.loop = true;
                Data.localRingAudio.element.play().then(function (p) {
                  log.trace("[WebCommunications.oncallstatechanged] Playing incoming sound started");
                }).catch(function (err) {
                  log.trace("[WebCommunications.oncallstatechanged] Playing incoming sound interrupted", err);
                });

              } else if (evt.target.Call.Direction === ax.AxCommonLib.Telephony.AxCallDirection.Inbound && evt.target.Call.AutoAnswer) {
                Data.localRingAudio.element.pause();
                Data.localRingAudio.element.src = '/assets/sounds/autoanswer.wav';
                Data.localRingAudio.element.loop = false;
                Data.localRingAudio.element.play().then(function (p) {
                  log.trace("[WebCommunications.oncallstatechanged] Playing autoanswer sound started");
                }).catch(function (err) {
                  log.trace("[WebCommunications.oncallstatechanged] Playing autoanswer sound interrupted", err);
                });
              }

              // Show tooltip
              Data.callTooltip.visible = true;
              var c = ax.AxCommonLib.Telephony.AxCallEventCause;

              switch (evt.target.Cause) {
                case c.StationBridge:
                  cause = li18n.axCallEventCause[92];
                  break;
              }
              Data.callTooltip.red = true;
              Data.callTooltip.text = li18n.messages.incomingCall;
              Data.callTooltip.cause = cause;
              Data.callTooltip.number = evt.target.Call.Ani.Ani;

              // Show notification
              if (NotificationService.canShowNotification()) {

                if (NotificationData.newSessionNotification === 0) {
                  NotificationData.newSessionNotification.voiceSessionCnt += 1;
                }

                NotificationService.showOrHideSessionNotification({
                  voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
                  li18n: li18n
                });

              }

            } else if (evt.target.Call.State === ax.AxCommonLib.Telephony.AxCallState.Connected) {

              // Hide tooltip
              Data.callTooltip.visible = false;

              if (phone instanceof ax.AxSwitchIntegrationLib.AxSwitchUserPhone) {

                Data.localRingAudio.element.pause();
                Data.localRingAudio.element.loop = false;
              }

              //SoundService.beep(600,0.02,0.3,0.35);
              SoundService.play(SoundType.routing)

              if (phone instanceof ax.AxSwitchIntegrationLib.AxVertoUserPhone &&
                (evt.target.Call.Direction === ax.AxCommonLib.Telephony.AxCallDirection.Outbound &&
                  (evt.target.Call.CreateCause === ax.AxCommonLib.Telephony.AxCallEventCause.Consultation || evt.target.Call.CreateCause === ax.AxCommonLib.Telephony.AxCallEventCause.SingleStepTransfer))
                && !evt.target.Call.AutoAnswer
              ) {

                Data.localRingAudio.element.pause();
                Data.localRingAudio.element.loop = false;

              }

              // No to trzeba zrobić tak, że jeżeli
              // Taaak, tylko co jeżeli to było połączone przez AAA?
              // To wtedy notyfikacja od razu zniknie,
              // No ale chuj.. zobaczymy
              // NotificationData.resetAllCounters();
              // NotificationService.closeAndRemoveSesionNotifications();
              // Bo mogł byc kliknięty tooltip, co powoduje reset liczników, a nie może być uejmnych wartości licznika sesji
              if (NotificationData.newSessionNotification.voiceSessionCnt > 0) {
                NotificationData.newSessionNotification.voiceSessionCnt -= 1;
              }
              NotificationService.showOrHideSessionNotification({
                voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
                li18n: li18n
              });

              // Send busy indicator
              log.debug("[WebAgent.phone.oncallstatechanged.connected] Sending busy indicator calls length: " + phone.calls.length);
              sClient.sendStationBusyIndicator({ stationBusy: phone.calls.length > 0 });

            }

          });

        }).then(function (phone) {

          if (phone === "STATION_NOT_ASSIGNED") {
            PhoneData.stationMessage = li18n.messages.stationNotAssigned;
          } else if (phone === "STATION_INITIALIZE_ERROR") {
            PhoneData.stationMessage = li18n.messages.stationInitializeError;
          } else if (phone === "UNSUPPORTED_STATION_TYPE") {
            PhoneData.stationMessage = li18n.messages.unsupportedStationType;
          } else {
            // Show phone controls
            PhoneData.stationMessage = "";
            Data.phoneVisible = true;
          }

          return Promise.resolve();

        }).then(function () { // MediaProxyClient

          if (config.mediaProxy.enabled === false) {
            log.info("Media proxy not enabled");
            return Promise.resolve();
          }

          log.info("Initializing MediaProxy client");

          MediaProxyClientService.initialize(config.mediaProxy.address);

          return MediaProxyClientService.connect().then(function () {

            if (config.mediaProxy.mediaServers.length === 0) {
              log.warn("No media servers configured. Not logging to media proxy, but still connected");
              return Promise.resolve();
            }

            // Get mediaProxyLoginToken
            return LoginService.getAccessToken({
              refreshToken: Data.refreshToken,
              tokenType: "mediaServerLogin",
              tokenParams: {
                token_lifetime: 60,
                servers: MediaProxyClientService.getMediaServersString(config.mediaProxy.mediaServers)
              }
            }).then(function (mediaProxyLoginToken) {

              return MediaProxyClientService.login(mediaProxyLoginToken.accessToken);

            }).then(function (loginResult) {

              // Log media server login status
              if (loginResult === MediaProxyClient.Enums.LoginResult.Success) {
                log.info("All media proxy backend servers connected and logged in");
              } else if (loginResult === MediaProxyClient.Enums.LoginResult.Partial) {
                log.warn("Some of backend media servers are not connected");
              }

              MediaProxyClientService.on({
                evtName: "answered",
                callback: function (event, data) {

                  WebRtcBuddyService.setRemoteSdp(data.data.sdp).then(function () {
                    log.debug("Remote SDP was set");
                  }).catch(function (err) {
                    log.error("Error setting remote sdp", err);
                  });

                },
                scope: $scope
              });

              MediaProxyClientService.on({
                evtName: "hangup",
                callback: function (event, data) {

                  var session = SessionService.findByVideoCallId(data.target.id);

                  if (session !== null) {

                    // Send helper sent to widget
                    /*var hangupEvt = JsonRpc.JsonRpcEvent({
                                            method: "hangupVideo",
                                            params: {
                                                sessionId: session.session.Session.SessionId,
                                                id: WL.guid(),
                                                name: Data.currentUser.Name,
                                                surname: Data.currentUser.Surname,
                                                duration: 0,
                                                time: (new Date()).toString(),
                                                type: 1,
                                                who: "contact"
                                            }
                                        }).toJSON();

                                        // Send hangup message to widget
                                        SessionHelperService.sendSessionChatResponseMessage({
                                            project: {Id: null, Name: null},
                                            sessionId: session.session.Session.SessionId,
                                            message: "",
                                            messageType: ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage,
                                            messageTag: hangupEvt,
                                            attachments: []
                                        }).then(function (res) {
                                            log.debug("[MediaProxyClientService.onhangup] hangupVideo successfully send", res);
                                        }).catch(function (err) {
                                            log.error("[MediaProxyClientService.onhangup] Error sending hangupVideo", err);
                                            // TODO: Error do gui
                                        })*/

                  }

                  VideoData.remoteVideoSrc = "";
                  VideoData.localVideoSrc = "";

                  // Turn off camera
                  WebRtcBuddyService.close();

                  VideoService.setDialingState(false);
                  VideoService.setAlertingState(false);
                  VideoService.setAssociatedInteraction(null);

                  // Remove video from session object
                  // videoCallActive = false also triggers removing video player from chat interface
                  if (session !== null) {

                    session.data.videoCall = null;
                    session.data.videoCallActive = false;

                  }

                  var btnStates = ax.AxSwitchIntegrationLib.AxPhoneButtonState;
                  var buttons = new ax.AxSwitchIntegrationLib.PhoneButtons();
                  buttons.hangup.state = btnStates.Disabled;
                  var changedButtons = [
                    buttons.hangup
                  ];
                  var evt = new ax.WebLib.Events.AxPhoneButtonStateChangedEvent(buttons, changedButtons);
                  $scope.updateButtons(evt);

                  $rootScope.$broadcast("layoutResize", {});

                },
                scope: $scope
              });

              return Promise.resolve();
            });

          });

        }).then(function () {

          if (config.ufp.enabled === false) {
            log.warn("[initialize] login to UFP is disabled");
            return Promise.resolve();
          }

          var ufpServiceAddress = SettingsData.get('UfpServiceAddress');
          var loginUrl = ufpServiceAddress + config.ufp.loginAddress;

          return UfpService.login({
            url: loginUrl,
            login: config.login,
            password: md5(config.password),
            withCredentials: config.ufp.withCredentials ? config.ufp.withCredentials : false
          }).then(function (data) {

            log.debug("[initialize] Login to UFP OK", data);
            var startUrl = ufpServiceAddress + config.ufp.startPage;

            // TODO: Jak do login response dodamy język w jakim przyszło menu,
            // to tutaj nie trzeba będzie zawsze robić swichLand, bo menu mogę od razu dostać takie jak trzeba...
            // wtedy tutaj nadpiszemy menu i będzie jeden request mniej
            Data.ufpAddress = startUrl;

            var lngToSet = UtilsService.getExtendedLangCode(Data.lang);

            log.debug("[initialize] Trying to set language to " + lngToSet[0]);

            return $scope.switchLang(lngToSet[0]);

          }).then(function () {

            var dashboardMenuItem = $scope.findMenuItem("ufp_dashboard", Data.appModules);
            if (dashboardMenuItem !== null) {
              Data.activeMenuItem = dashboardMenuItem;
            } else {
              // TODO:Chyba wtedypierwszynaliście powinien być???
              //Data.activeMenuItem = Data.li18n.menu.dashboard;
            }
          });
        });
      };

      $scope.findMenuItem = function (menuItem, menu) {
        if (menuItem && menu) {

          var l = menu.length,
            current, sl, sCurrent;
          for (var i = 0; i < l; i++) {
            current = menu[i];
            if (current.item === menuItem) {
              return current;
            } else {

              // Search in submodules
              if ("submodules" in current && current.submodules !== null) {
                sl = current.submodules.length;
                for (var a = 0; a < sl; a++) {
                  sCurrent = current.submodules[a];
                  if (sCurrent.item === menuItem) {
                    return sCurrent;
                  }
                }
              }
            }
          }

        }

        return null;

      };

      // Akcja logowania uruchamiana z okna logowania
      $scope.loginToSystem = function () {

        if (Data.server.length === 0 || Data.login.length === 0 || Data.password.length === 0) {
          return;
        }

        config.server = Data.server + (config.serverPath ? "/" + config.serverPath : "");
        config.login = Data.login;
        config.password = Data.password;
        document.cookie = "login=" + Data.login + ";expires=Fri, 31 Dec 9999 23:59:59 GMT";
        document.cookie = "server=" + Data.server + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        Data.loginInProgress = true;
        Data.loginError = false;
        Data.loginMessage = li18n.messages.agentConnecting;
        log.debug("[WebAgent] Initializing....");

        // Miejsce na to może jest głupie, ale na pewno tutaj istnieje już dokument,
        // więc mogę się dobrać do elementu i złapać eventy animacji bez cudowania z angularową animacją,
        // którą nie bardzo się udało zmusić do działania
        // TODO: Sprawdzić co będzie jak initialize odpali się drugi raz np jak będzie already logged
        // Bo chyba wtedy dopisze się kolejny event handler
        var tProgress = document.getElementById("tProgress");
        tProgress.addEventListener("animationend", function (e) {
          $scope.$apply(function () {
            Data.callTooltip.visible = false;
          });
        }, false);

        var sProgress = document.getElementById("sProgress");
        sProgress.addEventListener("animationend", function (e) {
          $scope.$apply(function () {
            Data.statusProgressVisible = false;
          });
        }, false);

        Data.shiftTimeDatePicker = new Pikaday({
          field: document.getElementById("shiftDateInput"),
          trigger: document.getElementById("shiftDateActivator"),
          format: "YYYY-MM-DD",
          onOpen: function () {
            if (Data.shiftTimeClockPicker !== null) {
              $('.clockpicker input').clockpicker('hide');
            }
          },

          disableDayFn: function (dt) {

            var nowDt = new Date();
            var nowDtZero = new Date(nowDt.getFullYear(), nowDt.getMonth(), nowDt.getDate());
            var shiftTo = null;
            if (Data && Data.currentInteraction && Data.currentInteraction.session && Data.currentInteraction.session.ShiftingParams) {
              shiftTo = Data.currentInteraction.session.ShiftingParams.ShiftTo;
            }
            var toDt = null;
            var toDtZero = null;

            if (dt.getTime() < nowDtZero.getTime()) {
              return true;
            }

            if (shiftTo !== null) {

              toDt = dotNetToJsTime(shiftTo);
              toDtZero = new Date(toDt.getFullYear(), toDt.getMonth(), toDt.getDate());

              if ((dt.getTime() <= toDtZero.getTime())) {
                return false;
              } else {
                return true;
              }

            }

            return false;

          }

        });

        Data.shiftTimeClockPicker = $('.clockpicker input').clockpicker({
            placement: 'bottom',
            align: 'right',
            autoclose: true,
            'default': 'now',
            trigger: document.getElementById("shiftTimeTimeActivator"),
            afterShow: function (th) {
              //$(th.popover).css('left', parseInt($(th.popover).css('left')) + 230 + 'px');
              //$(th.popover).css('top', parseInt($(th.popover).css('top')) + 23 + 'px');
            }
          }
        );

        return $scope.initialize().then(function () {

          saveCurrentUser(sClient.loginResponse.LoginResponseParams.AccountData);
          Data.loggedIn = true;
          Data.loginInProgress = false;

        }).catch(function (err) {

          // Catch all promise errors
          log.error("[loginToSystem] Error", err);
          var loginMessage = Data.loginMessage;
          var statusMessage = Data.statusMessage;

          if (err instanceof LoginService.Error) {

            if (err instanceof LoginService.ERRORS.INVALID_CREDENTIALS) {
              log.debug("[loginToSystem] Login to auth service error");
              loginMessage = getMessageByLoginResult(ax.SessionLib.AxUserLoginResponseResult.BadCredintials);
              statusMessage = loginMessage;
            } else if (err instanceof LoginService.ERRORS.SERVICE_UNAVAILABLE) {
              log.error("[loginToSystem] Auth service not available");
              loginMessage = getMessageByLoginResult(ax.SessionLib.AxUserLoginResponseResult.ServiceUnavaileable);
              statusMessage = loginMessage;
            } else if (err instanceof LoginService.ERRORS.CONNECTION_REFUSED) {
              log.error("[loginToSystem] Auth service connection refused");
              loginMessage = li18n.messages.connectionError;
              statusMessage = li18n.messages.error + ": " + loginMessage;
            } else {
              log.error(log.debug("[loginToSystem] Other Auth service error"));
              loginMessage = li18n.messages.otherError;
              statusMessage = li18n.messages.error + ": " + li18n.messages.otherError;
            }

          } else if (err instanceof ax.SessionLib.AxSessionServerClient) {

            if (err.connected === false) {

              loginMessage = li18n.messages.connectionError;
              statusMessage = li18n.messages.error + ": " + li18n.messages.connectionError;

            } else {
              log.debug("[loginToSystem] Other error");
              loginMessage = li18n.messages.otherError;
              statusMessage = li18n.messages.error + ": " + li18n.messages.otherError;
            }

          } else if (err instanceof ax.SessionLib.AxMessages.AxMsgUserLoginResponse) {

            loginMessage = getMessageByLoginResult(err.LoginResponseParams.Result);
            statusMessage = loginMessage;
          } else if (err instanceof MediaProxyClientService.Error) {

            log.error("[loginToSystem] Mediaproxy connect error");
            loginMessage = getMessageByMediaProxyError(err);
            statusMessage = li18n.messages.error + ": " + loginMessage;

            // TODO: Disconnect sClient and other conneced clients
            // to avoid already logged!!!!!! in next login try without browser reloading

          } else if (err instanceof ax.AxComLib.AxMessages.AxMsgRegisterClientResponse) {

            log.warn("[loginToSystem] Error response from ComClient");
            var rResult = ax.AxComLib.AxRegisterClientResult;
            if (err.Result === rResult.InvalidCredentials) {
              loginMessage = getMessageByLoginResult(ax.SessionLib.AxUserLoginResponseResult.BadCredintials);
              statusMessage = loginMessage;
            } else if (err.Result === rResult.NotSupportedVersion) {
              loginMessage = li18n.messages.otherError;
              statusMessage = li18n.messages.error + ": " + li18n.messages.otherError;
            } else if (err.Result === rResult.PleaseReconnectToSlave) {

              // todo
              loginMessage = 'Please reconnect to slave';
              statusMessage = li18n.messages.error + ": " + loginMessage;

            } else if (err.Result === rResult.ToManyUnsuccessfulLoginsCount) {
              loginMessage = li18n.messages.tooManyUnsuccessfulLogin;
              statusMessage = li18n.messages.error + ": " + loginMessage;
            } else if (err.Result === rResult.ServiceUnavaileable) {
              loginMessage = getMessageByLoginResult(ax.SessionLib.AxUserLoginResponseResult.ServiceUnavaileable);
              statusMessage = loginMessage;
            } else {
              loginMessage = li18n.messages.otherError;
              statusMessage = li18n.messages.error + ": " + li18n.messages.otherError;
            }

          } else if (err instanceof ax.AxComLib.Errors.ConnectError ||
            err instanceof ax.SessionLib.Errors.ConnectError) {

            loginMessage = li18n.messages.connectionError;
            statusMessage = li18n.messages.error + ": " + li18n.messages.connectionError;

          } else if (err && typeof err.msg === "string" && err.msg.indexOf('UFP Login error') !== -1) {
            log.error("[loginToSystem] Error", err);

            if (err && err.data && err.data.resultCode === UFP_LOGIN_ERROR.INVALID_CREDENTIALS) {
              loginMessage = li18n.messages.invalidCredentials;
              statusMessage = li18n.messages.error + ": " + li18n.messages.invalidCredentials;
            } else {
              loginMessage = li18n.messages.otherError;
              statusMessage = li18n.messages.error + ": " + li18n.messages.otherError;
            }

            sClient.removeListener("disconnected", onSessionClientDisconnected);
            sClient.wl.disconnect().then( () => {
              log.debug("[WebCommunications] Disconnected from server");
            }).catch( err => {
              log.error("[WebCommunications] Disconnect error", err);
            });

          } else if (err === "PASSWORD_CHANGE_REQUIRED") {
            log.warn("[WebCommunications] Password change required");
          }

          $scope.$apply(function () {
            Data.loginError = true;
            Data.loginMessage = loginMessage;
            Data.statusMessage = statusMessage;
            Data.loginInProgress = false;
          });

          throw err;
        });
      };

      $scope.logout = function () {
        UfpService.logout();
        Data.logoutTriggeredByUser = true;
        window.location.reload();
      };

      // Ustawia aktywnego call
      $scope.selectCall = function (call) {

        log.debug("Selecting call");

        var phone = userPhone.phone;

        // Only if clicked call is different from active call
        if (call.CallId === phone.activeCall.CallId) {
          return;
        }

        if (call.State === ax.AxCommonLib.Telephony.AxCallState.Holded) {

          // If call is holded then we must hold second call, and next unhold this call
          // Find not holded call...
          var activeCall;
          var calls = phone.calls.getAsArray();
          for (var i in calls) {
            var curr = calls[i];
            if (curr.CallId === call.CallId) { // This call
              continue;
            }

            // But if second phone is holded, we don't touch
            if (curr.State === ax.AxCommonLib.Telephony.AxCallState.Connected) {
              activeCall = curr;
            }
          }

          if (activeCall) {

            phone.holdCall(activeCall).then(function (res) {
              log.debug("Call holded");
              return Promise.resolve(res);
            }, function (err) {
              log.error("ERROR! Hold call failed")
            }).then(function () {
              phone.retrieveCall(call).then(function (res) {
                log.debug("Call unholded");
              }, function (err) {
                log.error("ERROR! Unhold call failed 2");
                log.error(err);
              });
            });

          } else {
            phone.retrieveCall(call).then(function (res) {
              log.debug("Call unholded");
            }, function (err) {
              log("ERROR! Unhold call failed 3");
              log.error(err);
            });
          }

          phone.setActiveCallById(call.CallId);

        }

      };

      // Wysyła request zmiany statusu do serwera
      // @param {object} Obiekt statusu, który ma zostać ustawiony
      $scope.setStatusRequest = function (status, $event) {

        sClient.setStatusById(status.StateId, false);

      };

      $scope.onCallBtnClick = function () {
        Data.currentCallVisible = true;
        Data.noActiveCallsVisible = false;
        Data.currentCallTo = Data.dialNumber;
        Data.tabVisible.dialpad = false;

        if (Data.callHistory.indexOf(Data.currentCallTo) === -1) {
          Data.callHistory.unshift(Data.currentCallTo);
        }

        $scope.makeCall();
      };

      /**
       * Make simple manual call or start manual session depending on current application state
       * Returns Promise resolced with true on success, rejected with false on error
       * @param callNo
       * @return external:Promise
       */
      $scope.makeCall = function (callNo) {

        log.debug("[WebAgent.makeCall] Function argument " + callNo);

        return new Promise(function (resolve, reject) {

          var useManualSessionInsteadOfCall = false,
            sessionServerSessionSerialId = null,
            sessionDataRecordId = null;
          // Wyczyszczenie poprzedniedo wyniku
          Data.lastCallCancelledByUser = false;
          Data.lastCallResultVisible = false;

          if (callNo) {
            Data.dialNumber = callNo.toString();
          }

          if (Data.dialNumber && Data.dialNumber.toString().length > 0) {
            log.debug("[WebAgent.makeCall] Dialing to " + Data.dialNumber);
            var campaignId = null;

            if ((Data.callInCampaign === true || SystemVariables.DisableNoCampaignManualCalling.Value === '1') && DialpadData.selectedCampaign !== null) {
              log.debug("[WebAgent.makeCall] Setting campaign id to: " + DialpadData.selectedCampaign);
              campaignId = DialpadData.selectedCampaign;
            }

            if (campaignId === null && SystemVariables.DisableNoCampaignManualCalling.Value === '1') {
              log.warn("[WebAgent.makeCall] CampaignId is null and DisableNoCampaignManualCalling system var is set to 1. Make call is not possible");
              reject(false);
              return;
            }

            log.debug("[WebAgent.makeCall] Calling with campaign id: " + campaignId);
            var device = sClient.loginResponse.LoginResponseParams.StationData.DialingNumber;

            var ani = device;
            var ddi = sClient.loginResponse.LoginResponseParams.AccountData.ForceDdiNumber;
            if (Data.forceDdiNumber === true && ddi !== null && ddi.toString().length > 0) {

              ani = ddi;
              log.debug("[WebAgent.makeCall] Calling with forced ani: " + ani);
            } else if (Data.forceDdiNumber !== true) {
              ani = null;
            }

            var firstSession = SessionService.getFirstOrNull(function (inter) {
              var sess = inter.session.Session,
                subtype = ax.SessionLib.AxSessionSubtype;
              if ((sess.SubType === subtype.InboundCall
                || sess.SubType === subtype.PredictiveCall
                || sess.SubType === subtype.PreviewCall
                || sess.SubType === subtype.CallBackCall
                || sess.SubType === subtype.ManualCall)
                && sess.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser) {
                return true;
              } else {
                return false;
              }
            });

            if (firstSession !== null) {
              useManualSessionInsteadOfCall = false;
              sessionServerSessionSerialId = firstSession.session.Session.SessionSerialId;
              if (firstSession.session.DataRecord && firstSession.session.DataRecord.Id !== null) {
                sessionDataRecordId = firstSession.session.DataRecord.Id;
              }

            } else {
              useManualSessionInsteadOfCall = true;
            }

            if (useManualSessionInsteadOfCall === true && campaignId !== null) {
              log.debug("[WebAgent.makeCall] starting manual session");
              sClient.startManualSession({
                campaignId: campaignId,
                recordId: null,
                phoneIndex: null,
                phoneNumber: Data.dialNumber,
                contactCustomerId: DialpadData.contactCustomerId,
                contactId: DialpadData.contactId,
                forceAni: ani
              }).then(function (res) {

                log.debug("[WebAgent.makeCall] Manual session stared", res);
                $scope.clearCustomerCallContext();
                resolve(true);
                return;

              }, function (err) {
                log.error("[WebAgent.makeCall] Starting manual session failed", err);
                $scope.clearCustomerCallContext();
                reject(false);
                return;
              });

            } else {
              log.debug("[WebAgent.makeCall] Trying to make manual call");
              return userPhone.phone.makeCall({
                dnis: Data.dialNumber,
                ani: ani,
                device: device,
                cause: ax.AxCommonLib.Telephony.AxCallEventCause.MakeCall,
                userId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
                campaignId: campaignId,
                sessionServerSessionSerialId: sessionServerSessionSerialId
              }).then(function (res) {

                log.debug("[WebAgent.makeCall] Manual call ok", res);
                Data.dialNumber = "";
                resolve(true);
                return;

              }).catch(function (err) {

                log.error("[WebAgent.makeCall] Manual call failed", err);
                Data.dialNumber = "";
                reject(false);
                return;
              });
            }

            Data.dialNumber = "";


          } else {
            log.warn("[WebAgent.makeCall] Number to dial is empty: " + Data.dialNumber);
            reject(false);
            return;
          }

        });

      };

      /**
       * Checks if holding call is allowed
       * @return boolean
       */
      $scope.isHoldRetrieveAllowed = function (activeCall) {

        var session;
        if (activeCall) {

          if (activeCall.State === ax.AxCommonLib.Telephony.AxCallState.Connected ||
            activeCall.State === ax.AxCommonLib.Telephony.AxCallState.Holded) {

            session = SessionHelperService.findSessionByAssociatedCallId(activeCall.CallId);

            if (session !== null) {

              return session.session.IsHoldAllowed;

            }

            return true;
          }
        }

        return false;

      };

      /**
       * Checks if mute is allowed
       * @return boolean
       */
      $scope.isMuteAllowed = function (activeCall) {

        var session;
        if (activeCall) {

          if (activeCall.State === ax.AxCommonLib.Telephony.AxCallState.Connected ||
            activeCall.State === ax.AxCommonLib.Telephony.AxCallState.Holded) {

            session = SessionHelperService.findSessionByAssociatedCallId(activeCall.CallId);

            if (session !== null) {

              return session.session.IsMuteAllowed;

            }

            return true;
          }
        }

        return false;

      };

      // Przełącza hold/unhold
      $scope.toggleHold = function () {

        // Ponieważ mamy jeden przycisk do hold i retreive
        // to musimy sprawdzić jaki jest stan aktualnego połączenia, żeby wiedzeć jaką funkcję wykonać
        var activeCall = userPhone.phone.activeCall,
          session;
        if (activeCall !== null) {

          log.debug("[WebAgent.toggleHold] Active call:", activeCall);

          if (activeCall.State === ax.AxCommonLib.Telephony.AxCallState.Connected) {

            if (this.isHoldRetrieveAllowed(activeCall) == false) {
              log.warn("[WebAgent.toggleHold] Hold is not allowed for this session");
              return;
            }

            userPhone.phone.holdCall(activeCall).then(function (res) {
              log.debug("Call holded");
            }, function (err) {
              log.error("[WebAgent.toggleHold] Hold call failed")
            });

          } else if (activeCall.State === ax.AxCommonLib.Telephony.AxCallState.Holded) {

            userPhone.phone.retrieveCall(activeCall).then(function (res) {
              log.debug("[WebAgent.toggleHold] Call unholded");
            }, function (err) {
              log.error("ERROR! Unhold call failed 4")
              log.error(err);
            });
          }

        } else {
          log.warn("[WebAgent.toggleHold] Not in call");
        }

      };

      /**
       * Transferuje zaholdowane połączenie do aktualnie połączonego (transfer z konsultacją)
       */
      $scope.transfer = function (evt) {

        // TODO: A co, jeżeli mamy zaholdowane obydwa???

        // Find holded call
        var holdedCall;
        var phone = userPhone.phone;
        var calls = phone.calls.getAsArray();
        for (var i in calls) {
          var curr = calls[i];
          if (curr.CallId === phone.activeCall.CallId) { // This call
            continue;
          }
          holdedCall = curr;
        }

        if (holdedCall) {
          phone.transferCall(phone.activeCall, holdedCall).then(function (res) {
            log.debug("Call Transferred");
          }, function (err) {
            log.error("ERROR! Transfer call failed");
            log.error(err);
          });
        } else {
          log.warn("WARNING! Second call is not holded");
        }

      };

      /**
       * Makes blind transfer of active call to selected number
       * @param {String}
       */
      $scope.blindTransfer = function (transferTo, $event) {

        if (Data.dialNumber.length > 0 && userPhone.phone.activeCall !== null) {

          userPhone.phone.blindTransfer(userPhone.phone.activeCall, transferTo).then(function (res) {
            log.debug("Call Transferred to: " + transferTo);
          }, function (err) {
            log.warn("ERROR! Transfer call to number " + transferTo + " failed");
          });

        }

      };

      $scope.toggleMute = function () {

        log.debug("[WebAgent.toggleMute] Toggle");

        var muteAllowed = this.isMuteAllowed();

        if (this.isMuteAllowed(userPhone.phone.activeCall) === false) {

          log.warn("[WebAgent.toggleMute] Mute is not allowed for this session");
          return;
        }

        userPhone.phone.toggleLocalAudio();
      };


      $scope.updateButtons = function (evt) {

        // Musi być taki myk, przez   głupiego angulara, żeby się nie wywalało
        var phase = $rootScope.$root.$$phase;
        if (phase == '$apply' || phase == '$digest') {
          upstat();
        } else {
          $rootScope.$apply(upstat);
        }

        function upstat() {
          evt.changed.forEach(function (btn) {

            if (btn instanceof ax.AxSwitchIntegrationLib.MakeCallButton) {
            } else if (btn instanceof ax.AxSwitchIntegrationLib.HangupButton) {
              Data.toolbar.hangup = btn.state !== ax.AxSwitchIntegrationLib.AxPhoneButtonState.Enabled ? 'ag_hangup.png' : 'ag_hangup_red.png';
            } else if (btn instanceof ax.AxSwitchIntegrationLib.PickupButton) {
              Data.toolbar.pickup = btn.state !== ax.AxSwitchIntegrationLib.AxPhoneButtonState.Enabled ? 'ag_pickup.png' : 'ag_pickup_green.png';
            } else if (btn instanceof ax.AxSwitchIntegrationLib.HoldButton) {
              Data.toolbar.pause = btn.state !== ax.AxSwitchIntegrationLib.AxPhoneButtonState.Enabled ? 'ag_pause.png' : 'ag_pause.png';
            } else if (btn instanceof ax.AxSwitchIntegrationLib.RetrieveButton) {
              Data.toolbar.pause = btn.state !== ax.AxSwitchIntegrationLib.AxPhoneButtonState.Enabled ? 'ag_pause.png' : 'ag_play.png';
            } else if (btn instanceof ax.AxSwitchIntegrationLib.TransferButton) {
              Data.toolbar.transfer = btn.state !== ax.AxSwitchIntegrationLib.AxPhoneButtonState.Enabled ? false : true;
            } else if (btn instanceof ax.AxSwitchIntegrationLib.BlindTransferButton) {
              Data.toolbar.blindTransfer = btn.state !== ax.AxSwitchIntegrationLib.AxPhoneButtonState.Enabled ? false : true;
            } else if (btn instanceof ax.AxSwitchIntegrationLib.MuteButton) {

              if (btn.state === ax.AxSwitchIntegrationLib.AxPhoneButtonState.Unavailable) {
                Data.toolbar.mute = "ag_mic_disabled.png";
              } else if (btn.state === ax.AxSwitchIntegrationLib.AxPhoneButtonState.EnabledUp) {
                Data.toolbar.mute = "ag_mic.png";
              } else if (btn.state === ax.AxSwitchIntegrationLib.AxPhoneButtonState.EnabledDown) {
                Data.toolbar.mute = "ag_mic_disabled.png";
              } else if (btn.state === ax.AxSwitchIntegrationLib.AxPhoneButtonState.DisabledUp) {
                Data.toolbar.mute = "ag_mic.png";
              } else if (btn.state === ax.AxSwitchIntegrationLib.AxPhoneButtonState.DisabledDown) {
                Data.toolbar.mute = "ag_mic_disabled.png";
              }

            }
          });
        };


      };

      $scope.pickup = function () {
        if (Data.localRingAudio.element) {
          Data.localRingAudio.element.pause();
        }

        var phone = userPhone.phone;
        log.debug("[WebAgent.pickup] Pickup button state " + phone.buttons.pickup.state);
        if (phone.buttons.pickup.state === ax.AxSwitchIntegrationLib.AxPhoneButtonState.Enabled) {
          phone.pickupAlertingCall(phone.activeCall);
        }

        // Pickup video call if exists
        if (VideoService.getAlertingState() === true) {

          VideoService.setAlertingState(false);

          // Hide notifications
          NotificationData.resetAllCounters();
          NotificationService.closeAndRemoveSesionNotifications();

          var getRtcConfiguration = makeGetRtcConfiguration(config.rtcConfiguration);
          var callProperties = VideoService.getCallProperties();

          // Change phone buttons in gui
          var btnStates = ax.AxSwitchIntegrationLib.AxPhoneButtonState;
          var buttons = new ax.AxSwitchIntegrationLib.PhoneButtons();
          buttons.pickup.state = btnStates.Disabled;
          var changedButtons = [
            buttons.pickup
          ];
          var evt = new ax.WebLib.Events.AxPhoneButtonStateChangedEvent(buttons, changedButtons);
          $scope.updateButtons(evt);

          VideoService.dialVideo({
            getRtcConfiguration: getRtcConfiguration,
            number: "AGENT" + callProperties.number
          }).then(function (videoCall) {

            var session = VideoService.getAssociatedInteraction();
            // When hangup is clicked immediately after pickup session may be not available
            if (session !== null) {
              session.data.videoCallActive = true;
              session.data.videoCall = videoCall;

              // Send ansveredEvent to widget
              var answeredEvt = JsonRpc.JsonRpcEvent({
                method: "videoCallAnswered",
                params: {
                  sessionId: callProperties.number
                }
              }).toJSON();

              SessionHelperService.sendSessionChatResponseMessage({
                project: { Id: null, Name: null },
                sessionId: session.session.Session.SessionId,
                message: "",
                messageType: ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage,
                messageTag: answeredEvt,
                attachments: []
              }).then(function (res) {
                log.debug("videoCallAnswered successfully send", res);
              }).catch(function (err) {
                log.error("Error sending videoCallAnswered", err);
                // TODO: Error do gui
              });
            }

          }).catch(function (err) {

            if (err instanceof VideoService.ERRORS.DIALING_STATE_CHANGED_EXTERNALLY) {
              log.warn("Video state changed externally");
            } else {
              log.error("Error video dialing", err);
            }

            WebRtcBuddyService.close();
            VideoData.remoteVideoSrc = "";
            VideoData.localVideoSrc = "";

          });

        }

        log.debug("Answering the call of type " + Data.incomingCall.type);
        // W zależności od typu połączenia robi akcję dla SIP lub webrtc
        if (Data.incomingCall.type == 0) { // SIP

          // Jeżeli nie ma jeszcze zgody na mikrofon to pokazujemy komunikat...
          if (Data.voiceMediaAccepted == true) {

            Data.toolbar.pickup = 'ag_pickup.png';

            // Sprawdzamy, czy jest już sesja skojarzona z połączeniem
            // W zależności od konfiguracji kampanii może ona być przysłana jeszcze przed odebraniem połączenia lub dopiero po jego odebraniu
            var associatedSession = SessionService.findByTransferCallId(Data.incomingCall.internalCallId);

            Data.incomingCallVisible = false;
            if (incomingCall) {
              // Wyłączenie ringing
              Data.localRingAudio.element.pause();
              // Odbebranie połączenia
              incomingCall.accept();
              Data.incomingCall.callState = 1;
              callSession = incomingCall;
              incomingCall = null;
            }
            // Ustawiam z powrotem na domyślne
            Data.voiceMediaAccepted = false;

          } else {
            // Nic tu nie robimy, ale może zrobi się tak, że pokażemy komunikat o akceptacji mikrofonu (a domyślnie będzie schowany)
          }

        } else if (Data.incomingCall.type == 1) { // WebRTC AV

          Data.localRingAudio.element.pause();
          Data.incomingCallVisible = false;
          Data.currentCallConnected = true;

          Data.voiceMediaAccepted = true;
          var m = Data.incomingCall.message;
          log.debug("Pickuping WebRTC call");

          // Dla każdego uszczestnika rozmowy tworzymy peerConnection
          peerConnections = [];

          peerConnections.push({
            accountId: Data.currentUser.Id,
            connection: new RTCPeerConnection({ iceServers: config.rtcConfiguration.iceServers }, mock.video.options)
          });
          var talkWith = [];
          m.value.Participants.forEach(function (participant) {

            // TODO: Póki co nie mamy nazwy z serwera sensownej, dlatego szukamy na liście kontaktów
            var l = Data.contacts.length;
            var found = null;
            for (var i = 0; i < l; i++) {
              var contact = Data.contacts[i];
              if (contact.value.UserId == participant.value.AccountId) {
                found = contact.value;
                break;
              }
              found = null;
            }

            var from = found !== null ? found.UserAccount.value.Name + ' ' + found.UserAccount.value.Surname : li18n.messages.unknown;
            talkWith.push(from);

            peerConnections.push({
              accountId: participant.value.AccountId,
              name: from,
              connection: new RTCPeerConnection({ iceServers: config.rtcConfiguration.iceServers }, mock.video.options)
            });

          });

          Data.currentCallTo = talkWith.join(",");
          Data.noActiveCallsVisible = false;
          Data.currentCallVisible = true;
          Data.chatConferenceVisible = false;
          Data.videoConferenceVisible = true;

          function sendPickupIncomingVideoCall(pc) {
            log.debug("sendPickupIncomingVideoCall");
            var pickupVideoCallPromise = Sender.pickupVideoCall(m.value.SessionId, pc.accountId, pc.connection.localDescription.sdp);
            pickupVideoCallPromise.then(function (res) {
              log.debug("Response z odebrania videocalla w alerting");
              log.debug(res.value.Sdp);

              Data.currentChat.user.name = Data.currentVideoCall.recipients[0].name + ' ' + Data.currentVideoCall.recipients[0].surname;
              Data.currentChat.project.name = Data.currentVideoCall.project.name;
              $scope.$apply();

              // Czyli tutaj zrobić set remote description
              var remoteDescr = new RTCSessionDescription({ type: "answer", sdp: res.value.Sdp });

              pc.connection.setRemoteDescription(remoteDescr, function () {
                log.debug("Ustawiłem remote description 123");
                Data.currentVideoCall.state = AxVideoCallState.connected;
              }, function (err) {
                log.debug("Błąd przy ustawianiu remote description");
              });

            }, function (err) {
              log.debug("Pickup call response error");
            });
          }

          // To poleci do promisy
          navigator.getMedia({ video: true, audio: true },
            // Success - zostały pobrany dostęp do kamery i mikrofonu
            function (localMediaStream) {

              log.debug("GetMedia Success");

              // Musimy tak zrobić, bo jest bug w angularze
              // Pakujemy lokalne video do wyświetleniea
              var myVideo = createVideoElement(window.URL.createObjectURL(localMediaStream), Data.currentUser.Id, Data.currentUser.Name + ' ' + Data.currentUser.Surname);
              myVideo.video.muted = true;
              Data.videoContainer.appendChild(myVideo.element);

              peerConnections.forEach(function (peerConnection) {

                peerConnection.connection.onaddstream = function (e) {

                  log.debug("Received stream");
                  var rStr = peerConnection.connection.getRemoteStreams();
                  log.debug(rStr);
                  log.debug(rStr[0].getAudioTracks());
                  log.debug(rStr[0].getVideoTracks());
                  var rVideo = {
                    src: window.URL.createObjectURL(e.stream),
                    accountId: peerConnection.accountId
                  };
                  log.debug(rVideo);
                  if (peerConnection.accountId == Data.currentUser.Id) { // Jeżeli jest to peer lokalny, to elementy video jest inny
                    //document.getElementById("myVideo").src = rVideo.src;
                  } else {  // Jeżeli dostaliśmy stream, to tworzymy element video dla zdalnego peera
                    var nVideo = createVideoElement(rVideo.src, peerConnection.accountId, peerConnection.name);
                    log.debug("Dodaję element video", nVideo.element);
                    log.debug(Data.videoContainer);
                    Data.videoContainer.appendChild(nVideo.element);
                  }

                };

                peerConnection.connection.onremovestream = function (e) {
                  log.debug("onRemoveStream", e);
                };

                // onICECandidate
                var iceCandidatePromise = new Promise(function (resolve, reject) {
                  log.debug("Ice candidate function");
                  var ended = false;
                  peerConnection.connection.onicecandidate = function (e) {
                    log.debug("Ice candidate for remote endpoint");
                    log.debug(e);
                    // Jeżeli był kandydat, to czekamy
                    if (e.candidate !== null) {
                      ended = false;
                      return;
                    } else { // Jeżeli kandydat był nullem, to znaczy, że zostały wygenerowane wszystkie
                      log.debug("Ice candidate for remote endpoint is null. Resolving promise");
                      resolve();
                    }
                  };
                });

                // Opakowane dodatkowo w funkcję, bo musimy przekazać parametr peerConnection
                iceCandidatePromise.then(function (resolve, reject) {
                  sendPickupIncomingVideoCall(peerConnection);
                }, function (e) {
                  log.error("Candidate generation on alerting failed");
                  log.error(e);
                });

                var constraints;
                // Jeżeli jest to peer lokalny, to constrainty ustawiamy inaczej
                if (peerConnection.accountId == Data.currentUser.Id) {
                  peerConnection.connection.addStream(localMediaStream);
                  constraints = { offerToReceiveAudio: false, offerToReceiveVideo: false };
                } else {
                  constraints = { offerToReceiveAudio: true, offerToReceiveVideo: true };
                }

                peerConnection.connection.createOffer(function (desc) {

                  // To uruchamia generowanie ICECandidate
                  peerConnection.connection.setLocalDescription(desc, function () {
                    log.debug("On create local with answer description");
                  }, function () {
                    log.error("create local description failed");
                  });


                }, function () {
                  log.error("create offer when answering failed");
                }, constraints);

              });

            }, function () {
              log.error("Can't get media");
            });

        }

      };

      function hangupVideoCall() {

        var rejectEvt;

        function clean(session) {
          VideoService.setAssociatedInteraction(null);
          VideoService.resetCallProperties();
          WebRtcBuddyService.close();
          VideoData.remoteVideoSrc = "";
          VideoData.localVideoSrc = "";
          $scope.stopRingAlerting();

          // Hide notifications
          NotificationData.resetAllCounters();
          NotificationService.closeAndRemoveSesionNotifications();

          // Add system chat message
          if (session) {
            $scope.pushEndedVideoChatMessage(session);
          }

        }

        function changeButtons() {
          // Change phone buttons in gui
          var btnStates = ax.AxSwitchIntegrationLib.AxPhoneButtonState;
          var buttons = new ax.AxSwitchIntegrationLib.PhoneButtons();
          buttons.pickup.state = btnStates.Disabled;
          buttons.hangup.state = btnStates.Disabled;
          var changedButtons = [
            buttons.pickup,
            buttons.hangup
          ];
          var evt = new ax.WebLib.Events.AxPhoneButtonStateChangedEvent(buttons, changedButtons);
          $scope.updateButtons(evt);
          VideoService.setAlertingState(false);

          rejectEvt = JsonRpc.JsonRpcEvent({
            method: "videoCallRejected",
            params: {
              sessionId: callProperties.number
            }
          }).toJSON();
        }

        var callProperties = VideoService.getCallProperties();

        // Reject incoming video call
        var associatedInteraction = VideoService.getAssociatedInteraction();
        if (VideoService.getAlertingState() === true) {

          MediaProxyClientService.hangupAll().then(function () {
            log.debug("[hangupVideoCall] Video disconnected");
          }).catch(function (err) {
            log.error("[hangupVideoCall] Error disconnecting video call");
          });

          VideoService.setAssociatedInteraction(null);

          changeButtons();

          // Send reject message to widget
          SessionHelperService.sendSessionChatResponseMessage({
            project: { Id: null, Name: null },
            sessionId: associatedInteraction.session.Session.SessionId,
            message: "",
            messageType: ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage,
            messageTag: rejectEvt,
            attachments: []
          }).then(function (res) {
            log.debug("videoCallRejected successfully send", res);
          }).catch(function (err) {
            log.error("Error sending videoCallRejected", err);
            // TODO: Error do gui
          }).finally(function () {
            clean(associatedInteraction);
          });

          sClient.sendStationBusyIndicator({ stationBusy: false });

        } else {

          VideoService.setDialingState(false);
          changeButtons();

          // Disconnect video call
          var vCalls = MediaProxyClientService.getCalls();
          if (vCalls.size > 0) {

            MediaProxyClientService.hangupAll().then(function () {
              log.debug("[hangupVideoCall] Video disconnected");
            }).catch(function (err) {
              log.error("[hangupVideoCall] Error disconnecting video call");
            });

            sClient.sendStationBusyIndicator({ stationBusy: false });

            rejectEvt = JsonRpc.JsonRpcEvent({
              method: "hangupVideo",
              params: {
                sessionId: callProperties.number,
                id: WL.guid(),
                name: Data.currentUser.Name,
                surname: Data.currentUser.Surname,
                duration: 0,
                time: (new Date()).toString(),
                type: 1,
                who: "contact"
              }
            }).toJSON();

            // Send hangup message to widget
            SessionHelperService.sendSessionChatResponseMessage({
              project: { Id: null, Name: null },
              sessionId: associatedInteraction.session.Session.SessionId,
              message: "",
              messageType: ax.SessionLib.Collections.Chat.AxChatMessageType.CommandMessage,
              messageTag: rejectEvt,
              attachments: []
            }).then(function (res) {
              log.debug("hangupVideo successfully send", res);
            }).catch(function (err) {
              log.error("Error sending hangupVideo", err);
              // TODO: Error do gui
            }).finally(function () {
              clean(associatedInteraction);
            });
          }

        }
      }

      /**
       * Hangup call
       */
      $scope.hangup = function () {

        log.debug("Disconnecting");
        log.debug("Actual video state: " + Data.currentVideoCall.state);
        var phone = userPhone.phone;

        // Disconnect active call
        if (phone.activeCall !== null) {
          var hangupPromise = phone.disconnectCall(phone.activeCall, 0).then(function (res) {

            log.debug("Call disconnected");

          }, function (err) {
            log.error("ERROR! Disconnecting call failed");
            log.error(err);
          });
        }

        hangupVideoCall();

        if (Data.currentVideoCall.state > 0) {
          Data.localRingAudio.element.pause();

          if (Data.videoConferenceVisible === true) {
            Data.currentChat.user.name = "";
            Data.currentChat.project.name = "";
          }

          // Jeżeli  nie zostało jeszcze wysłane żądanie makeCall
          // to robimy tylko porządki z tym co zostało utworzone lokalnie
          if (Data.currentVideoCall.state <= AxVideoCallState.makeCallRequestSent) {
            // Usuwam connection główny
            log.debug(peerConnection);
            peerConnection = null;
            // Usuwam elementy video
            Data.videoContainer.innerHTML = "";
            Data.currentVideoCall.state = AxVideoCallState.disconnected;
            return;
          }

          // Jeżeli zostało już wysłane żądanie makeCall
          if (Data.currentVideoCall.state >= AxVideoCallState.makeCallResponseReceived) {

            Data.currentVideoCall.state = AxVideoCallState.disconnecting;
            var hPromise = Sender.hangupVideoCall(Data.currentVideoCall.sessionId);
            Data.currentVideoCall.state = AxVideoCallState.hangupCallRequestSent;

            hPromise.then(function (res) {
              Data.currentVideoCall.state = AxVideoCallState.hangupCallResponseReceived;
              log.debug("Received AxMsgWebRtcDisconnectCallResponse");
              log.debug(res);
              if (res.value.Result == 0) {
                Data.currentVideoCall.state = AxVideoCallState.disconnected;
                // TODO: A gdyby to był obiekt zoribony przez Object.create to można by zapodać w setterze kasowanie sessionId i nie trzeba by było o tym pamiętać
                Data.currentVideoCall.sessionId = null;
                log.debug("Session disconnected succesfully");
              } else {
                log.warn("Session not disconnected result: " + res.value.Result);
              }

              var videoContainer = document.getElementById("ax-video-container");
              videoContainer.innerHTML = "";

              // Wyłączamy kamerkę
              if (peerConnection !== null) {
                var localMedia = peerConnection.getLocalStreams();
                if (localMedia.length > 0) {
                  localMedia[0].stop();
                }
              } else { // W takim przypadku było prawdodopobnie połączenie przychodzące, które nie ma peerConnection, bo peery są w tablicy
                // ... dlatego, żeby wyłączyć kamerkę musimy to zrobić na, którymś z tych tablicowych
                if (peerConnections.length > 0) {
                  var lM = peerConnections[0].connection.getLocalStreams();
                  log.debug(lM);
                  if (lM.length > 0) {
                    lM[0].stop();
                  }
                }
              }

              // Usuwamy połączenia
              peerConnection = null;
              peerConnections = [];

            }, function (e) {
              log.debug("Error on video disconnection");
              log.error(e);
            });

            Data.currentVideoCall.sessionId = null;
          }
        }
      };


      $scope.stopProp = function ($event) {
        $event.stopPropagation();
      };

      /**
       * Wysyła SMS
       * @param {object} $event
       */
      $scope.sendSms = function ($event) {
        var smsPromise = Sender.sendSms(Data.currentUser.Name + ' ' + Data.currentUser.Surname, Data.smsNumber, { id: Data.smsProjectId }, null, Data.smsContent);
        var dT = new Date();
        var sentTime = dT.getFullYear() + '-' + (zeroPad(dT.getMonth() + 1)) + '-' + zeroPad(dT.getDay()) + ' ' + zeroPad(dT.getHours()) + ':' + zeroPad(dT.getMinutes()) + ':' + zeroPad(dT.getSeconds());
        smsPromise.then(function (res) {
          log.debug("[sendSms] Response", res);
          Data.sentSms.push({
            number: Data.smsNumber,
            content: Data.smsContent,
            sentResultDesc: li18n.messages.sent,
            sentTime: sentTime
          });
          Data.smsNumber = "";
          Data.smsContent = "";
          $scope.$apply();
        }, function (e) {
          log.debug("[sendSms] Error sending SMS");
          Data.sentSms.push({
            number: Data.smsNumber,
            content: Data.smsContent,
            sentResultDesc: li18n.messages.notSent,
            sentTime: sentTime
          });
          Data.smsNumber = "";
          Data.smsContent = "";
          $scope.$apply();
        });

        Data.smsProjectId = null;
        Data.smsCampaignId = null;

      };

      $scope.logEvent = function ($event) {
        log.debug($event);
      };

      $scope.maskSms = function ($event) {
        // Puszczamy cyferki, strzałki, tab i backspace
        if ($event.keyCode !== 8 && $event.keyCode !== 9 && $event.keyCode !== 37 && $event.keyCode !== 39) {
          if (String.fromCharCode($event.which).match(/\d|\+/) === null) {
            $event.preventDefault();
          }
        }

      };

      // Wyłącza podświetlenie wszystkich przycisków na diapladzie
      $scope.allBtnOff = function ($event) {
        for (var i in Data.dialButtons) {
          Data.dialButtons[i].bg = "off";
        }
      };

      $scope.hideAllTabs = function ($event) {
        Data.contactListVisible = false;
        Data.chatBoxVisible = false;
        Data.dialpadVisible = false;
        Data.smsVisible = false;
      };


      $scope.toogleConversation = function () {
        if (Data.conversationOpened) {
          Data.conversationOpened = false;
          closeFrame();
        } else {
          Data.conversationOpened = true;
          openFrame();
        }
      };

      /**
       * Open conversation window with selected contact
       * @param {ContactListItem} contact
       * @param $event
       */
      $scope.openConversation = function (contact, $event) {

        openFrame();
        Data.conversationOpened = true;
        log.debug("[webagent] Opening conversation window for contact id: " + contact.user.Id);
        Data.videoConferenceVisible = false;
        Data.chatConferenceVisible = true;

        // Do nagłówka pakujemy imię i nazwisko usera
        Data.currentChat.user.name = contact.user.Name + " " + contact.user.Surname;
        // Sprawdzamy czy okno istnieje
        // Jeżeli tak, to przełączmy mu visibility, a wszystkie inne wyłączamy
        var found = false;
        for (var i in Data.conversations) {
          var currentConversation = Data.conversations[i];
          currentConversation.visible = false;
          //if ( currentConversation.contact.UserId == contact.UserId ) {
          if (currentConversation.contact.user.Id == contact.user.Id) {
            found = true;
            currentConversation.visible = true;
            Data.currentChat.project.name = currentConversation.project.name;
            Data.currentChat.user.id = contact.user.Id;
          }
        }

        // Jeżeli nie, to dodajemy element
        if (found === false) {
          Data.conversations.push({
            visible: true,
            contact: contact,
            project: { id: null, name: "" },
            session: { id: WL.guid(true) },
            messages: []
          });
          Data.currentChat.user.id = contact.user.Id;
          Data.currentChat.project.name = "";
        }

        // Schowanie powiadomienia o unread
        // TODO: To w sumie mogło by być zrobione w templacie, wtedy można by przywrócić przekazywanie tutaj obiektu contact już jako value
        //contact.unread = false;

      };

      // TODO: Dodać zmniejszanie okna, jeżeli wszystkie rozmowy są już zamknięte
      $scope.closeCurrentConversation = function () {

        // Szukamy aktualnego czatu na liscie
        // jezeli jest znaleziony, to go usuwam z listy
        var found = false;
        for (var i in Data.conversations) {
          var currentConversation = Data.conversations[i];
          currentConversation.visible = false;
          if (currentConversation.contact.UserId == Data.currentChat.user.id) {
            found = true;
            break;
          }
        }

        if (found) {
          Data.conversations.splice(i, 1);
          Data.currentChat.user.id = null;
          Data.currentChat.user.name = "";
          Data.currentChat.project.id = null;
          Data.currentChat.project.name = "";
        }

      };

      /**
       * Sends chat message
       * @param {conversation} conversation
       * @param {KeyboardEvent} $event
       */
      $scope.sendInternalChatMessage = function (conversation, $event) {

        if (($event.key == "Enter" || $event.keyCode == 13) && !$event.shiftKey) {
          $event.preventDefault();

          log.debug($event.target.innerHTML);

          // Jeżeli jest pusto, to nie wysyłamy
          if ($event.target.innerHTML.trim().length == 0) {
            return;
          }

          var dt = new Date();
          var dtFormatted = dt.getFullYear() + '-' + zeroPad(dt.getMonth() + 1) + '-' + zeroPad(dt.getDate()) + ' ' + zeroPad(dt.getHours()) + ':' + zeroPad(dt.getMinutes()) + ':' + zeroPad(dt.getSeconds());
          var msgItem = {
            sender: li18n.messages.me,
            sentDate: dtFormatted,
            text: $event.target.innerHTML, delivered: false, deliveryStatus: null
          };
          conversation.messages.push(msgItem);

          var chatMsgPromise = Sender.sendInternalChatMessage({
            Id: Data.currentUser.Id,
            Type: 2
          }, { Id: conversation.contact.user.Id, Type: 2 }, {
            Id: conversation.project.id,
            Name: conversation.project.name
          }, conversation.session.id, $event.target.innerHTML.replace(/\<br\>/g, "\n"));

          chatMsgPromise.then(function (res) {
            // Oznaczamy status dostarczenia wiadomości
            msgItem.deliveryStatus = res.value.Result;
            if (res.value.Result == 0) {
              $scope.$apply(function () {
                msgItem.delivered = true;
              });

            }
          });

          $event.target.innerHTML = "";

        }
      };

      $scope.makeVideoCall = function (contact, project) {

        Data.currentChat.user.id = null;
        Data.currentChat.user.name = contact.value.UserAccount.value.Name + ' ' + contact.value.UserAccount.value.Surname;
        Data.currentVideoCall.recipients.push({
          id: contact.value.UserAccount.value.Id,
          name: contact.value.UserAccount.value.Name,
          surname: contact.value.UserAccount.value.Surname
        });

        if (project) {
          Data.currentChat.project.id = project.id;
          Data.currentChat.project.name = project.name;

          Data.currentVideoCall.project.id = project.id;
          Data.currentVideoCall.project.name = project.name;
        }

        var user = contact.value;

        // Jeżeli jest już prowadzona rozmowa video, to dodajemy do niej usera
        if (Data.currentVideoCall.state > AxVideoCallState.initiated) {
          $scope.addToVideoConference(contact);
          return;
        } else { // Status zmieniamy tylko, jeżeli to jest nowe połączenie
          Data.currentVideoCall.state = AxVideoCallState.initiated;
          // Pokazujemy całe okno
          openFrame();
        }

        // Chowamy okna czatu i pokazujemy video
        Data.chatConferenceVisible = false;
        Data.videoConferenceVisible = true;

        // Najpierw musimy pobrać sobie nasze lokalne video i wygenerować kandydatów
        // TODO: Opcje lecą z mockowego obiektu, docelowo będą pobierane z konfiguracji i z komunikatów
        // Tworzymy połączenie
        peerConnection = new RTCPeerConnection({ iceServers: config.rtcConfiguration.iceServers }, mock.video.options);

        peerConnection.onremovestream = function (e) {
          log.debug("Remote stream removed");
          log.debug(e);
        };

        /***/
        function sendMakeVideoCall() {

          // Może się okazać, że połączenie zostało w międzyczasie zdropowane
          log.debug("sendMakeVideoCall: Current video call state is " + Data.currentVideoCall.state);
          if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
            log.debug("Current video call state is " + Data.currentVideoCall.state + " not sending makeVideoCall");
            return;
          } else {
            log.debug("Sending makeVideoCall");
          }

          // Włączamy sygnał wybierania numeru
          Data.localRingAudio.element.pause();
          Data.localRingAudio.element.src = "/assets/sounds/ringbacktone.wav";
          Data.localRingAudio.element.play().then(function (p) {
            log.trace("[WebCommunications.makeVideoCall] Playing ringback sound started");
          }).catch(function (err) {
            log.trace("[WebCommunications.makeVideoCall] Playing ringback sound interrupted", err);
          });

          var sdp = peerConnection.localDescription.sdp;
          log.debug(sdp);


          var makeVidPromise = Sender.makeVideoCall(user.UserAccount.value.Id, user.UserAccount.value.Name + " " + user.UserAccount.value.Surname, sdp, project);
          Data.currentVideoCall.state = AxVideoCallState.makeCallRequestSent;

          makeVidPromise.then(function (res) { // Jeżeli otrzymaliśmy odpowiedź od serwera na makeVideoCall AxWebRTCMakeCallResponse

            if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
              log.debug("Current video call state is " + Data.currentVideoCall.state + " not setting remote description");
              return Promise.reject("Current video call state is " + Data.currentVideoCall.state + " not setting remote description");
            }

            Data.currentVideoCall.state = AxVideoCallState.makeCallResponseReceived;

            log.debug("Response z videocalla");
            log.debug(res);

            if (res.value.Result == 0) {  // Jeżeli response jest ok
              log.debug("Response value to zero");
              Data.currentVideoCall.sessionId = res.value.SessionId;

              // Ustawiamy remote description
              var remoteDescr = new RTCSessionDescription({ type: "answer", sdp: res.value.Sdp });
              log.debug(remoteDescr);
              return new Promise(function (resolve, reject) {
                peerConnection.setRemoteDescription(remoteDescr, function () {
                  resolve();
                }, function (err) {
                  reject(err);
                });
              });

            } else { // Jeżeli odpowiedź na makeVideo nie była pozytywna to robimy reject
              log.debug("Response value nie jest zerem");
              return Promise.reject("Response value nie jest zerem blblb");
            }

          }, function (err) {
            log.error("Something get wrong with makeVideoCall response");
          }).then(function () { // Jeżeli ustawienie zdalnego description się powiodło
            log.debug("Ustawiłem remote description 456");
            // Tworzymy okno video dla zdalnego - później dodamy do niego stream
            // Wszystko po to, żeby głupio nie wyglądało, że jest już rozmowa z... a nie ma video
            // teraz będzie element informacyjny, że czeka na strumień
            var rVideo = createVideoElement(null, user.UserAccount.value.Id, user.UserAccount.value.Name + ' ' + user.UserAccount.value.Surname);
            Data.videoContainer.appendChild(rVideo.element);
            $scope.$apply();
          }, function (err) {
            log.error("setRemote description failed");
            // Jeżeli się nie udało, np remote descr był failed to robimy czystkę
            Data.currentVideoCall.state = AxVideoCallState.disconnecting;
            Data.localRingAudio.element.pause();
            if (peerConnection !== null) {
              var localMedia = peerConnection.getLocalStreams();
              if (localMedia.length > 0) {
                localMedia[0].stop();
              }
            }
            Data.videoContainer.innerHTML = "";
            // Usuwamy połączenia
            peerConnection = null;
            peerConnections = [];
            // Pokazujemy info, o nieudanym połączeniu
            Data.currentCallVisible = false;
            Data.lastCallResponseCode = li18n.messages.videoError;
            Data.lastCallResultVisible = true;
            Data.currentVideoCall.state = AxVideoCallState.disconnected;
            $scope.$apply();
          });

        }


        // TODO: To samo mamy praktycznie w dwóch czy trzech miejscach, więc można to wywalić do jakiejś osobnej funkcji
        // To wykonuje się tak naprawdę na końcu
        // bo onicecandidate zaczynają się odpalać dopiero po wykonaniu setLocalDescription
        var iceCandidatePromise = new Promise(function (resolve, reject) {

          var ended = false;
          // A w chrome to leci zawsze i z tego eventa pobierani są kandydaci
          // @param {RTCPeerConnectionIceEvent}
          peerConnection.onicecandidate = function (e) {
            log.debug("ONICECANDIDATE");
            log.debug(e);
            // Jeżeli był kandydat, to czekamy
            if (e.candidate !== null) {
              ended = false;
              return;
            } else { // Jeżeli kandydat był nullem, to znaczy, że zostały wygenerowane wszystkie
              resolve();
            }

          };

        });

        iceCandidatePromise.then(function () { // Całe offer z kandydatami zostało wygenerowane

            log.debug("Candidates ready: Current video call state is " + Data.currentVideoCall.state);
            if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
              log.debug("Current video call state is " + Data.currentVideoCall.state + " breaking process");
              return;
            }
            Data.currentVideoCall.state = AxVideoCallState.candidatesReady;
            sendMakeVideoCall();
          }
          , function (e) { // Błąd przy generowaniu kandydatow - to nie powinno się nigdy zdarzyć chyba
            log.error("Candidate generation failed");
            log.error(e);
          });

        // To w sumie też by można jakoś wyrzucić jako osobna funkcja zwracająca od razu promisę to by było tutaj przejszyściej
        // Ale to w kolejnym etapie refaktoringu
        var getMediaPromise = new Promise(function (resolve, reject) {
          Data.currentVideoCall.state = AxVideoCallState.getMediaRequested;
          navigator.getMedia(mock.video.media, function (localMediaStream) {
            resolve(localMediaStream);
          }, function (err) {
            reject(err);
          });
        });

        getMediaPromise.then(function (localMediaStream) { // Jeżeli otrzymaliśmy dostęp do urządzenia

          if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
            log.debug("Current video call state is " + Data.currentVideoCall.state + " breaking process");
            return;
          }
          Data.currentVideoCall.state = AxVideoCallState.getMediaAccepted;

          log.debug("GetMedia Success");
          // Dodajemy lokalny stream do połączenia
          peerConnection.addStream(localMediaStream);

          // Pakujemy lokalne video do wyświetleniea
          var myVideo = createVideoElement(window.URL.createObjectURL(localMediaStream), Data.currentUser.Id, Data.currentUser.Name + ' ' + Data.currentUser.Surname);
          myVideo.video.muted = true;
          Data.videoContainer.appendChild(myVideo.element);

          return new Promise(function (resolve, reject) {

            if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
              log.debug("Current video call state is " + Data.currentVideoCall.state + " breaking process");
              return;
            }
            Data.currentVideoCall.state = AxVideoCallState.createOfferRequested;
            peerConnection.createOffer(function (desc) {

              resolve(desc);

            }, function (err) {
              reject(err);
            }, {
              offerToReceiveAudio: false,
              offerToReceiveVideo: false
            });

          }).then(function (desc) { // Jeżeli createOffer się powiodło
            if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
              log.debug("Current video call state is " + Data.currentVideoCall.state + " breaking process");
              return;
            }
            Data.currentVideoCall.state = AxVideoCallState.offerCreated;
            log.debug("Generated local offer");
            log.debug(desc);
            log.debug(desc.sdp);

            // TODO: W sumie tutaj nie jest potrzebna już promisa
            // Bo nie jest nigdzie używana, chyba tylko do zalogowania, a logowanie możęmy zrobić bezpośrednio, więc trzeba to usunąć
            // A jednak będzie, bo w ff wysyłamy tutaj...
            return new Promise(function (resolve, reject) {

              //desc.sdp = desc.sdp.replace( /a=mid:video\r\n/g , 'a=mid:video\r\nb=AS:256\r\n');
              //desc.sdp = desc.sdp + "a=mid:video\r\nb=AS:128\r\n";
              //desc.sdp = desc.sdp.replace( /a=sendrecv\r\n/g , 'a=sendrecv\r\nb=AS:128\r\n');

              // Mając gotowe RTCSessionDescription ustawiamy go w naszym lokalnym połączeniu
              // setLocalDescription powoduje odpalenie generowania kandydatów
              // http://tools.ietf.org/html/draft-ietf-rtcweb-jsep-03#section-4.2.4
              log.debug("Requesting setLocalDescription Current video call state is " + Data.currentVideoCall.state);
              if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
                log.debug("Current video call state is " + Data.currentVideoCall.state + " breaking process");
                return;
              }
              Data.currentVideoCall.state = AxVideoCallState.setLocalDescriptionRequested;
              peerConnection.setLocalDescription(desc, function () {
                log.debug("Local description set successfully. Resolving promise with sdp");
                var sdp = desc.sdp;
                resolve(sdp);
              }, function (err) {
                log.error("setLocalDescription failed");
                log.error(err);
                reject(err);
              });

            }).then(function (sdp) {// Jeżeli wykonało się setLocalDescription
              // Tutaj już nie nie robimy, bo cała reszta wykonuje się w promisie onicecandidate, która jest zdefiniowana na początku
              log.debug("localDescriptionSet: currentVideoCall state is " + Data.currentVideoCall.state);
              if (Data.currentVideoCall.state === AxVideoCallState.none || Data.currentVideoCall.state === AxVideoCallState.disconnecting || Data.currentVideoCall.state === AxVideoCallState.disconnected) {
                log.debug("Current video call state is " + Data.currentVideoCall.state + " breaking process");
                return;
              }
              Data.currentVideoCall.state = AxVideoCallState.localDescriptionSet;
            }, function (err) {
              log.error("setLocal description failed");
              log.error(err);
            });

          }, function (err) {
            log.err("Error creating offer");
          }).then(function () { // setLocalDescription się zrobiło, czyli jeżeli to jest FF, to możemy od razu wysłać make videoCall
            log.debug("Local description was set. Waiting for icecandidates");
          });
        }, function (err) {
          log.error("The following error occured: ");
          log.error(err);
        });

      };

      // Exportujemy funkcję do dzwonienia video na zewnątrz
      makeVideoCall = $scope.makeVideoCall;


      /**
       * Dodaje usera do aktualnie prowadzoenej konferencji video
       */
      $scope.addToVideoConference = function (contact) {

        var addPromise = Sender.addToVideoConference(contact.value.UserAccount.value.Id, Data.currentVideoCall.sessionId);
        addPromise.then(function (res) {
          log.debug("Received AxMsgWebRtcAddToSessionResponse");
          log.debug(res);
        }, function (e) {
          log.warn("User not added to conference");
          log.warn(e);
        });

      };

      $scope.closePopups = function ($event) {
        if (!$event.originalEvent.isFromPopup) {
          $scope.closeAllButThis(null);
          $scope.toggleFetchContacts();
          $scope.toggleFetchCampaigns({
            inGroup:null,
            withGroups: true,
            onlyDialable:true,
            inboundWorking: null,
            outboundWorking: null,
            withCampaignParameters: false,
            withUserDialingPossibility: true
          });
        }
      };

      /**
       * Odłącza się od aktualnie trwającego połączenia video
       */
      $scope.hangupVideoCall = function () {

        Data.currentChat.user.name = "";
        Data.currentChat.project.name = "";

        var hPromise = Sender.hangupVideoCall(Data.currentVideoCall.sessionId);
        hPromise.then(function (res) {
          log.debug("Response z rozłączenia video");
          if (res.value.Result == 0) {

            var videoContainer = document.getElementById("ax-video-container");
            videoContainer.innerHTML = "";

          } else {
            log.error("Sorry taki mamy server :)");
          }
        }, function (e) {
          log.debug("Błąd przy rozłączeniu video");
          log.error(e);
        });


      };

      /**
       * Pokazuje informacje na temat aktualnego połączenia
       */
      $scope.showActiveCallInfo = function () {

        // Na razie działa tylko dla połączenia video
        if (Data.currentVideoCall.sessionId !== null) {
          Data.chatConferenceVisible = false;
          Data.videoConferenceVisible = true;

          // Przełączamy info o video
          Data.currentChat.user.id = null;
          Data.currentChat.user.name = Data.currentVideoCall.recipients[0].name + ' ' + Data.currentVideoCall.recipients[0].surname;
          Data.currentChat.project.id = Data.currentVideoCall.project.id;
          Data.currentChat.project.name = Data.currentVideoCall.project.name;

        }

      };

      /**
       * Switch current session to first session on list
       */
      $scope.switchToFirstSession = function () {

        var session = SessionHelperService.getFirstInteraction();

        $scope.switchCurrentSession(session);

      };

      /**
       * Przełącza widoczną sesję
       * @param {?InteractionItem} session
       */
      $scope.switchCurrentSession = function (session) {

        if (session === null) {

          // Find first session on list
          session = SessionHelperService.getFirstActiveOrEndedNotAttachedInteraction();

        }

        var previousInteraction = Data.currentInteraction;

        Data.currentInteraction = session;
        Data.lastInteraction = session;

        Data.mainTabVisible.preview = false;
        Data.mainTabVisible.interactions = true;
        if (session) {
          session.data.unreadChatCounter = 0;
        }

      };

      /**
       * Close current session
       */
      $scope.closeCurrentSession = function () {

        SessionHelperService.closeCurrentSession();

      };

      /**
       * Close session given in argument
       * @param {InteractionItem} session Session to close
       */
      $scope.closeSession = function (session) {

        SessionHelperService.closeCurrentSession(session);

      };

      /**
       * Sprawdza czy moze zamknac aktualnie trwającą sesję
       */
      $scope.canCloseCurrentSession = function () {
        return SessionHelperService.canCloseCurrentSession();
      };

      /**
       * Gets campaigns list
       */
      $scope.getCampaignList = function () {
        Sender.getCampaignList(null, true, true).then(function (campaigns) {
          log.debug(campaigns);
        }, function (err) {
          log.error("Error getting campaign list");
        })

      };

      /**
       * Toggle getting contacts list for chat
       */
      $scope.toggleFetchContacts = function () {

        if ((Data.tabVisible.dialpad === true || Data.tabVisible.chatBox === true)) {

          if (chatClient.client !== null) {
            if (chatClient.client.intervalId === null) {
              chatClient.client.startFetchContacts();
            }
          }
        } else {
          if (chatClient.client !== null) {
            chatClient.client.stopFetchContacts();
          }
        }
      };

      /**
       * Toggle getting campaigns list for dialpad
       */
      $scope.toggleFetchCampaigns = function (p) {

        if (Data.tabVisible.dialpad === true ) {
          sClient.startFetchCampaigns(p);
        } else {
          sClient.stopFetchCampaigns();
        }
      };

      /**
       * When button on dialpad was clicked
       * @param {String} btnValue
       * @param {MouseEvent} $event
       */
      $scope.onDialpadButtonClick = function (btnValue, $event) {

        if (Data.sendDtmf && userPhone.phone.activeCall !== null) {

          var prom = WL.sendDtmf(userPhone.phone.activeCall.CallId, btnValue);
          prom.then(function () {
            log.debug("Dtmf: " + btnValue + " was sent");
          }, function () {
            log.warn("Dtmf: " + btnValue + " not sent");
            log.warn(err);
          });

        } else {
          if (Data.sendDtmf === false) {
            Data.dialNumber = Data.dialNumber.toString() + btnValue.toString();
          }
        }

      };

      /**
       * Terminate current active session
       */
      $scope.terminateSession = function () {

        var sess = Data.currentInteraction.session;
        var terminatePromise = WL.terminateSession({
          sessionId: sess.Session.SessionId,
          dataRecordId: sess.DataRecord.Id,
          campaignId: sess.DataRecord.CampaignId,
          terminateReason: Data.terminateReason,
          dataRecordPhoneIndex: sess.DataRecordPhoneIndex
        });

        terminatePromise.then(function (res) {
          if (res.value.Result === 0) {
            log.debug("Session terminated. Result " + res.value.Result);
          } else {
            log.error("Terminate session failed. Result " + res.value.Result);
          }
        }, function (err) {
          log.error("Terminate session failed");
          log.error(err);
        });
      };

      /**
       * Shift session
       */
      $scope.shiftSession = function () {

        var shiftOn = null;
        if (Data.shiftTime.datePicker.dt !== null) {

          var arrDate = Data.shiftTime.datePicker.dt.split('-');

          if (Data.shiftTime.timePicker.time !== null) {
            var arrTime = Data.shiftTime.timePicker.time.split(':');
            shiftOn = new Date(parseInt(arrDate[0]), parseInt(arrDate[1]), parseInt(arrDate[2]), parseInt(arrTime[0]), parseInt(arrTime[1]));
          } else {
            shiftOn = new Date(parseInt(arrDate[0]), parseInt(arrDate[1]), parseInt(arrDate[2]));
          }
        }

        // TODO: Dodać to jako metodę sessionManagera
        var shiftPromise = WL.shiftRecord({
          sessionId: Data.currentInteraction.session.Session.SessionId,
          forUserId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
          campaignId: Data.currentInteraction.session.DataRecord.CampaignId,
          dataRecordId: Data.currentInteraction.session.DataRecord.Id,
          shiftOn: shiftOn,
          tco: (Data.shiftTime.selectedButton === 'user' ? true : false),
          phoneIndex: $('#shiftSessionPhoneSelect').select2('val'),
          note: Data.shiftTime.note

        });

        shiftPromise.then(function (res) {

          if (res.value.Result === ax.SessionLib.AxShiftRecordRequestResult.Shifted) {
            log.debug("Shift record OK");
            Data.currentInteraction.data.web.shifted = true;
            Data.currentInteraction.data.web.breakSession = false;
          } else {
            log.error("Shift record failed. Result: " + res.value.Result);
            log.error(res);
          }
        }, function (err) {
          // TODO: RED Info at staus bar
          log.error("Shift record failed");
          log.error(err);
        });
      };

      /**
       * Close all tabs except indicated
       * Pass null to hide all tabs
       * @param {String} tabName
       */
      $scope.closeAllButThis = function (tabName) {

        for (var pName in Data.tabVisible) {

          if (pName !== tabName) {
            Data.tabVisible[pName] = false;
          } else {

            // Clear ccontact identifier if dialpad is closing
            if (pName === "dialpad" && Data.tabVisible[pName] === true) {
              DialpadData.contactCustomerId = null;
              DialpadData.contactId = null;
              DialpadData.customerName = "";
            }
            Data.tabVisible[pName] = !Data.tabVisible[pName];

          }
        }

      };

      /**
       * Close all main tabs except indicated
       * Pass null to hide all tabs
       * @param {String} tabName
       */
      $scope.closeAllMainButThis = function (tabName) {

        for (var pName in Data.mainTabVisible) {

          if (pName !== tabName) {
            Data.mainTabVisible[pName] = false;
          } else {
            Data.mainTabVisible[pName] = true;
          }
        }

      };
      Data.closeAllMainButThis = $scope.closeAllMainButThis;

      /**
       * Hides date picker in session shift window
       */
      $scope.hideShiftTimeDatePicker = function () {
        if (Data.shiftTimeDatePicker !== null) {
          Data.shiftTimeDatePicker.hide();
        }
      };

      /**
       * Hides date picker in session shift window
       */
      $scope.hideShiftTimeTimePicker = function () {
        if (Data.shiftTimeClockPicker !== null) {
          $('.clockpicker input').clockpicker('hide');
        }
      };


      /**
       * Initialize select phone in session shift window
       */
      $scope.initializeShiftToPhoneSelect = function (evt) {

        $('#shiftSessionPhoneSelect').select2({ placeholder: "Automatycznie" });

      };

      /**
       * Resets phone select in session shift window to it's default value
       */
      $scope.resetShiftToPhone = function (evt) {

        $('#shiftSessionPhoneSelect').select2('val', false);
      };

      /**
       * Check is shifting of current interaction is allowed for selected mode
       */
      $scope.isShiftingAllowed = function (mode) {

        if (!Data.currentInteraction || !Data.currentInteraction.session) {
          return null;
        }

        if (Data.currentInteraction.session.ShiftingParams === null) {
          return null;
        }

        if (mode === null) {

          if (Data.currentInteraction.data.web.shifted === true) {
            return false;
          }

          if (Data.currentInteraction.session.ShiftingParams.ShiftMode === ax.SessionLib.AxDataRecordShiftingMode.Forbidden) {
            return false;
          }
        }

        if (mode === 'user') {
          if (Data.currentInteraction.session.ShiftingParams.ShiftMode === ax.SessionLib.AxDataRecordShiftingMode.Free
            || Data.currentInteraction.session.ShiftingParams.ShiftMode === ax.SessionLib.AxDataRecordShiftingMode.OnlyToMe
            || Data.currentInteraction.session.ShiftingParams.ShiftMode === ax.SessionLib.AxDataRecordShiftingMode.OnlyToMeInCampaignTime) {
            return true;
          }

        } else {

          if (Data.currentInteraction.session.ShiftingParams.ShiftMode === ax.SessionLib.AxDataRecordShiftingMode.Free
            || Data.currentInteraction.session.ShiftingParams.ShiftMode === ax.SessionLib.AxDataRecordShiftingMode.InCampaignTime) {
            return true;
          }

        }

        return false;
      };

      /**
       * Check if chat message can be sent in current session chat
       */
      $scope.isSendSessionChatAllowed = function () {

        var inter = Data.currentInteraction, sState;
        if (inter && (inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat
          || inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial)) {

          sState = inter.session.Session.SessionState;
          if (sState === ax.SessionLib.AxSessionState.EndedByRemoteSide ||
            sState === ax.SessionLib.AxSessionState.EndedByUser ||
            sState === ax.SessionLib.AxSessionState.Rejected ||
            sState === ax.SessionLib.AxSessionState.RejectedByRemoteSide ||
            sState === ax.SessionLib.AxSessionState.RejectedByUser ||
            sState === ax.SessionLib.AxSessionState.SystemTerminated
          ) {
            return false;
          } else {
            return true;
          }

        }

        return false;

      };

      $scope.isRecordingButtonVisible = function () {
        var inter = Data.currentInteraction,
          subType,
          AxSubType = ax.SessionLib.AxSessionSubtype;
        if (inter) {
          subType = inter.session.Session.SubType;

          return !(subType === AxSubType.InboundChat
            || subType === AxSubType.InboundSocial
            || subType === AxSubType.InboundEmail);

        }

        return false;

      };

      $scope.interactionHeaderClass = function () {

        var inter = Data.currentInteraction,
          className = "", postfix = "";
        if (inter) {

          if (inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat ||
            inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial) {
            postfix = "_chat";
          } else if (inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {
            postfix = "_email";
          }

          className = "state_" + inter.session.Session.SessionState + postfix;

        }

        return className;
      };


      $scope.interactionListClass = function (inter) {

        var currentInter = Data.currentInteraction,
          sessionId = inter.session.Session.SessionId,
          className = "state_" + inter.session.Session.SessionState;

        if (inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat) {
          className += "_chat";
        }

        if (currentInter && sessionId === currentInter.session.Session.SessionId) {
          className += ' active';
        }

        return className;
      };

      $scope.interactionListFaClass = function (inter) {

        var currentInter = Data.currentInteraction,
          sessionId = inter.session.Session.SessionId,
          className = "fa fa-",
          subType = inter.session.Session.SubType;

        if (subType === ax.SessionLib.AxSessionSubtype.InboundCall ||
          subType === ax.SessionLib.AxSessionSubtype.PredictiveCall ||
          subType === ax.SessionLib.AxSessionSubtype.PreviewCall ||
          subType === ax.SessionLib.AxSessionSubtype.CallBackCall ||
          subType === ax.SessionLib.AxSessionSubtype.ManualCall) {
          className += "phone";
        } else if (subType === ax.SessionLib.AxSessionSubtype.InboundEmail) {
          className += "envelope";
        } else if (subType === ax.SessionLib.AxSessionSubtype.InboundChat) {
          className += "wechat";
        }

        className += " istate_" + inter.session.Session.SessionState;

        if (currentInter && sessionId === currentInter.session.Session.SessionId) {
          //className += ' active';
        }

        return className;

      };

      $scope.isVideoVisible = function () {

        return true;
        var sState, state = ax.SessionLib.AxSessionState;

        if (Data.currentInteraction && Data.currentInteraction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat) {

          sState = Data.currentInteraction.session.Session.SessionState;

          if (Data.currentInteraction.session.Session.BaseSessionObject.SessionProperties.VideoEnabled === true ||
            Data.currentInteraction.session.Session.BaseSessionObject.SessionProperties.AudioEnabled === true) {

            if (sState === state.EndedByRemoteSide ||
              sState === state.EndedByUser ||
              sState === state.Rejected ||
              sState === state.RejectedByRemoteSide ||
              sState === state.RejectedByUser ||
              sState === state.SystemTerminated) {
              return false;
            } else {
              return true;
            }
          }
        }

        return false;

      };

      $scope.isSessionTabsVisible = function () {

        var axSessionSubType = ax.SessionLib.AxSessionSubtype,
          sessionSubType;
        if (Data.currentInteraction) {

          sessionSubType = Data.currentInteraction.session.Session.SubType;

          if (sessionSubType === axSessionSubType.InboundChat
            || sessionSubType === axSessionSubType.InboundSocial
            || sessionSubType === axSessionSubType.InboundCall
            || sessionSubType === axSessionSubType.PredictiveCall
            || sessionSubType === axSessionSubType.PreviewCall
            || sessionSubType === axSessionSubType.ManualCall
            || sessionSubType === axSessionSubType.InboundEmail) {

            return true;
          } else {
            return false;
          }
        }

        return true;

      };

      $scope.localVideoEnabled = function () {

        return mClient.localVideoEnabled;
      };

      $scope.localAudioEnabled = function () {

        return mClient.localAudioEnabled;
      };

      $scope.toggleLocalVideo = function () {
        mClient.toggleLocalVideo();
      };

      $scope.toggleLocalAudio = function () {
        mClient.toggleLocalAudio();
      };

      $scope.onAnswerItemDragStart = function (answer, evt) {

        // Becouse of jQuery, when using full jQuery (not from angular) we have event from jQuery because of element.on....
        evt = evt.originalEvent || evt;

        evt.dataTransfer.setData('text/object-type', 'answer-template');
        evt.dataTransfer.setData('application/json', JSON.stringify({}));
        evt.dataTransfer.effectAllowed = 'copy';
        Data.dragdrop.currentDraggedItem = { type: 'answer-template', item: answer };

      };

      $scope.onAnswerItemDragEnd = function (answer, evt) {

      };

      $scope.onChatDrop = function (evt) {

        evt = evt.originalEvent || evt;
        // Must stop event propagation because don't want to fire drop event on container
        evt.stopPropagation();

        var droppedType = evt.dataTransfer.getData('text/object-type');

        if (droppedType === 'answer-template') {

          $scope.chatAddTextToinput(Data.currentInteraction.session.Session.SessionId, Data.dragdrop.currentDraggedItem.item);

        }

      };

      $scope.onChatDragOver = function (evt) {

        evt.stopPropagation();

        if (Data.dragdrop.currentDraggedItem) {
          if (Data.dragdrop.currentDraggedItem.type !== "answer-template") {
            evt.originalEvent.dataTransfer.dropEffect = 'none';
            return;
          }

          if (Data.dragdrop.currentDraggedItem.type === "answer-template") {

            // Allow drop only on message input
            if (evt.target.tagName === 'TEXTAREA') {
              evt.originalEvent.dataTransfer.dropEffect = 'copy';
            } else {
              evt.originalEvent.dataTransfer.dropEffect = 'none';
            }
          }
        }

      };

      $scope.onChatDragEnter = function (evt) {


      };

      $scope.onChatDragLeave = function (evt) {


      };

      $scope.shareFile = function (file) {

        sClient.shareFile({
          fileName: file.Name,
          filePathHash: file.FilePathHash,
          sessionId: Data.currentInteraction.session.Session.SessionId,
          campaignId: Data.currentInteraction.session.Session.DestinationObjectId
        }).then(function (res) {

          log.debug("Sharing file success", res);

          var fileMessage = {
            __messageType: "file_share",
            __from: "agent",
            senderName: Data.sClient.loginResponse.LoginResponseParams.AccountData.Name + ' ' + Data.sClient.loginResponse.LoginResponseParams.AccountData.Surname,
            sentOn: moment().format("YYYY-MM-DD HH:mm"),
            fileName: file.DisplayName,
            fileUrl: Data.CONFIG.chat.availableFilesRepositoryRoot + '/' + Data.currentInteraction.session.Session.DestinationObjectId + '/' + file.Name,
            fileSize: file.Size,
            ChatMessageId: UUID()
          };

          Data.currentInteraction.data.messages.push(fileMessage);

        }).catch(function (err) {

          // TODO: Jakaś itemka z błędem dodana do konwersacji czatowej
          log.error("Sharing file error", err);

        });
      };

      /**
       * Interaction leave event handler
       * @param {InteractionItem} interaction Leaved interaction
       */
      function onInteractionLeave(interaction) {

        var sessionId;
        if (interaction !== null) {

          sessionId = interaction.session.Session.SessionId;
          log.warn("[onIntearctionLeave] Leaved interaction " + sessionId);

          if (interaction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {

            SessionClient.sendEmailEvent({
              type: ax.SessionLib.AxEmailEventsType.Session,
              eventType: ax.SessionLib.AxEmailEventsEventType.Leaved,
              userId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
              userStatusUid: Data.currentStatus.UId,
              emailMessageId: interaction.session.Session.BaseMessageObject.Id,
              sessionId: interaction.session.Session.SessionId,
              tag: "WebCommunications"
            }).then(function (res) {
              log.debug("[onInteractionLeave] Send email event 'Leaved' (" + ax.SessionLib.AxEmailEventsEventType.Leaved + ") for sessionId " + sessionId + " OK");
            }).catch(function (err) {
              log.error("[onInteractionLeave] Send email event 'Leaved' (" + ax.SessionLib.AxEmailEventsEventType.Leaved + ")  for sessionId " + sessionId + " error", err);
            });

          }

          Data.currentInteraction = null;

        } else {
          log.warn("[onIntearctionLeave] Leaved interaction is null");
        }

      }

      /**
       * Interaction enter event handler
       * @param {InteractionItem} interaction Entered interaction
       */
      function onInteractionEnter(interaction) {

        var sessionId;
        if (interaction !== null) {
          sessionId = interaction.session.Session.SessionId;

          // Send interaction enter information to child frame
          var frame = document.querySelector("iframe[ax-session-id='" + sessionId + "'][ax-session-frame]");
          if (frame !== null) {

            $timeout(function () {
              log.debug("[onIntearactionEnter] Sending interactionEnter event to session frame");
              frame.contentWindow.postMessage(JsonRpc.JsonRpcEvent({
                method: "interactionEnter",
                params: {
                  sessionId: sessionId
                }

              }).toJSON(), "*");
            });

          }

          if (interaction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {

            SessionClient.sendEmailEvent({
              type: ax.SessionLib.AxEmailEventsType.Session,
              eventType: ax.SessionLib.AxEmailEventsEventType.Displayed,
              userId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
              userStatusUid: Data.currentStatus.UId,
              emailMessageId: interaction.session.Session.BaseMessageObject.Id,
              sessionId: sessionId,
              tag: "WebCommunications"
            }).then(function (res) {
              log.debug("[onInteractionEnter] Send email event 'Displayed' (" + ax.SessionLib.AxEmailEventsEventType.Displayed + ") for sessionId " + sessionId + " OK");
            }).catch(function (err) {
              log.error("[onInteractionEnter] Send email event 'Displayed' (" + ax.SessionLib.AxEmailEventsEventType.Displayed + ") for sessionId " + sessionId + " error", err);
            });

          }

        } else {
          log.warn("[onIntearctionEnter] Entered interaction is null");
        }

      }

      $scope.$on("interactionLeave", function (event, data) {
        onInteractionLeave(data.interaction);
      });
      $scope.$on("interactionEnter", function (event, data) {
        onInteractionEnter(data.interaction);
      });

      /**
       * Left menu click handler
       */
      $scope.$on("menuItemClick", function (event, data) {

        const previousTab = (() => {
          for ( let tab in Data.mainTabVisible ) {
            if ( Data.mainTabVisible[tab] === true ) {
              return tab;
            }
        }})();

        var refresh = false;
        if (data && "item" in data) {
          if (data.item.src !== null && !("webagent" in data.item)) {

            Data.activeMenuItem = data.item;

            var ufpServiceAddress = SettingsData.get('UfpServiceAddress');
            var baseAddress = ufpServiceAddress + data.item.src;

            if (baseAddress === Data.ufpAddress) {
              refresh = true;
            }

            var frameToShow = "ufp";

            if (data.item.item === "ufp_mail") {
              if (Data.ufpMailAddress === "") {
                Data.ufpMailAddress = baseAddress;
              }
              frameToShow = "ufpMail";
            } else if (data.item.item === "ufp_mailqueue") {
              if (Data.ufpMailQueueAddress === "") {
                Data.ufpMailQueueAddress = baseAddress;
              }
              frameToShow = "ufpMailQueue";
            } else {
              Data.ufpAddress = baseAddress;
            }

            // If menu click caused interaction leave
            if (Data.mainTabVisible.interactions === true
              && Data.currentInteraction) {
              log.debug("[onMenuItemClick] Leaved interaction view.");
              $scope.$emit("interactionLeave", { interaction: Data.currentInteraction });
            }

            $scope.closeAllMainButThis(frameToShow);

            // If we want to reload
            if (refresh) {
              Data.refreshFrame = true;
            }

          } else if ("webagent" in data.item) {

            // If menu click caused interaction leave
            if (Data.mainTabVisible.interactions === true
              && Data.currentInteraction) {

              log.debug("[onMenuItemClick] Leaved interaction view.");
              $scope.$emit("interactionLeave", { interaction: Data.currentInteraction });

            }

            $scope.closeAllMainButThis(data.item.webagent);

            if (("submodules" in data.item) === false) {
              Data.activeMenuItem = data.item;
            }

            if (data.item.webagent === "interactions") {

              // TODO, Czy tutaj nie powinno być rootScope? CZy to w ogóle działa???
              $scope.$broadcast("activeInteractionChanged", { interaction: Data.currentInteraction });

            }

          }

          // Toogle fetching chart queues
          if ( Data.mainTabVisible.statistics === true && previousTab !== "statistics" ) {
            log.debug("[onMenuItemClick] Starting fetching queues for statistics");
            StatisticHelperService.startFetchChartQueues();
          } else if (previousTab === "statistics" ) {
            log.debug("[onMenuItemClick] Stopping fetching queues for statistics");
            StatisticHelperService.stopFetchChartQueues();
          }


        }

      });


      /**
       * Left menu interaction click handler
       * @param moduleAddress
       */
      $scope.$on("interactionItemClick", function (event, data) {

        log.debug("[onInteractionItemClick]");

        // Hide notifications
        NotificationData.resetAllCounters();
        NotificationService.closeAndRemoveSesionNotifications();

        var item = null,
          currentSession = Data.currentInteraction !== null ? Data.currentInteraction.session.Session : null,
          currentSessionId = currentSession !== null ? currentSession.SessionId : null;

        if ("item" in data) {

          item = data.item;

          // If interaction really changed
          if (Data.mainTabVisible.interactions === false
            || (Data.mainTabVisible.interactions === true
              && (currentSession === null
                || currentSession.SessionId !== item.session.Session.SessionId
              ))) {

            log.debug("[onInteractionItemClick] Interaction changed from " + currentSessionId + " to " + item.session.Session.SessionId);

            Data.activeMenuItem = $scope.findMenuItem("interactions", Data.appModules);

            //$rootScope.$broadcast("interactionEnter", { interaction : item});

            if (Data.mainTabVisible.interactions === true
              && currentSession !== null
              && currentSessionId !== item.session.Session.SessionId) {

              $scope.$emit("interactionLeave", { interaction: Data.currentInteraction });

            }
            $scope.closeAllMainButThis(item.webagent);
            $scope.switchCurrentSession(item);

            $rootScope.$broadcast("interactionEnter", { interaction: item });

          } else {
            log.warn("[onInteractionItemClick] Interaction not changed");
          }

        } else {
          log.warn("[onInteractionItemClick] Interaction not found in event");
        }

        $scope.$broadcast("activeMenuItemChanged", { item: null });

      });

      $scope.$on("rejectInteractionClick", function (event, data) {

        var item = null;
        if ("item" in data) {
          item = data.item;

          // TODO: Tutaj chyba stop ringa tylko jeżeli nie ma innych dzwoniących interakcji
          $scope.stopRingAlerting();
          SessionService.temporaryRejectSession(item, config.rejectHuntSessionTimeout);

          if (NotificationData.newSessionNotification.voiceSessionCnt > 0) {
            NotificationData.newSessionNotification.voiceSessionCnt -= 1;
          }
          NotificationService.showOrHideSessionNotification({
            voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
            li18n: li18n
          });

        } else {
          log.warn("[onRejectInteractionClick] Interaction not found in event");
        }

      });


      $scope.$on("acceptInteractionClick", function (event, data) {
        if ("item" in data) {
          var item = data.item;
          SessionService.stopAllTemporaryRejectedSessionsTimeouts();
          SessionService.temporaryRejectAcceptableSessions(0, item.session.Session.SessionId);
        } else {
          log.warn("[onAcceptInteractionClick] Interaction not found in event");
        }
      });

      /**
       * Open customer search handler
       * This event can be emitted from axChooseCustomer
       */
      $scope.$on("openCustomerSearchRequest", function (event, data) {
        $scope.openCustomerSearch();
      });

      /**
       * Open file search handler
       * This event can be emitted from axSessionChat
       */
      $scope.$on("openFileSearchRequest", function (event, data) {
        $scope.openFileSearch();
      });

      /**
       * Open folder search handler
       * This event can be emitted from axSessionChat
       */
      $scope.$on("openFolderSearchRequest", function (event, data) {

        $scope.openFolderSearch(data);

      });

      /**
       * Sets session customer context
       * This event can be emitted from axChooseCustomer
       */
      $scope.$on("setSessionCustomerContextRequest", function (event, data) {
        $scope.setSessionCustomerContext({
          customerId: data.customerId,
          contactId: data.contactId
        });
      });

      /**
       * Download file
       * This event can be emitted from axSessionChat
       */
      $scope.$on("downloadFileRequest", function (event, data) {

        $scope.downloadFile({
          fileId: data.fileId,
          fileUrl: data.fileUrl,
          fileName: data.fileName,
          directLink: data.directLink,
          fileType: data.fileType
        });

      });


      /**
       * Save tab size
       * This event can be emitted from axContentTab
       */
      $scope.$on("sessionTabsSplitChanged", function (event, data) {

        if (Data.currentInteraction) {

          $scope.$apply(function () {
            Data.currentInteraction.data.web.sessionTabWidth = data.width;
          });

        }

      });

      /**
       * If any element has changed size, broadcast this evet to whole application
       */
      $scope.$on("sessionTabsLayoutResize", function (event, data) {

        $rootScope.$broadcast("layoutResize", {});

      });

      // Media proxy methods
      $scope.mProxyDial = function () {


      };

      // Media proxy Client Event handlers
      $scope.$on("media-proxy-callcreated", function (event, data) {

        log.debug("[WebCommunications] Received " + event.name, data);

      });

      $scope.$on("media-proxy-answered", function (event, data) {

        log.debug("[WebCommunications] Received " + +event.name, data);

      });

      $scope.$on("media-proxy-hangup", function (event, data) {

        log.debug("[WebCommunications] Received " + event.name, data);

      });

      /*$scope.$on("webrtc-buddy-usermediaacquired", function(event,data) {

            log.debug("[WebCommunications] Received " + event.name, data);

        });*/

      $scope.$on("interactionAddedToList", function (event, data) {

        $scope.ringAlertingIfNotRinging();

        NotificationData.newSessionNotification.voiceSessionCnt += 1;
        NotificationService.showOrHideSessionNotification({
          voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
          li18n: li18n
        });

      });


      $scope.$on("chatPhoneNumberClicked", function (event, data) {

        if ( config.ufp.customCustomerSearchOnPhoneClick === true ) {

          log.debug("Searching for customer by custom action");
          UfpService.execCustomAction({
            actionIdent: config.ufp.customCustomerSearchActionIdent,
            data: {
              phoneNumber: data.number
            }
          }).then( function(customers) {
            log.debug("Customer data received", customers);

            // Assign customer to session
            if ( customers.length > 0 && config.ufp.assignSessionToCustomerWhenCustomFound === true ) {

              $scope.setSessionCustomerContext({
                sessionId: data.interaction.session.Session.SessionId,
                customerId: customers[0].customerId
              }).then( () => {
                $scope.$broadcast("openCrmPage", {

                  interaction: data.interaction,
                  customers: customers

                });
              });

            } else {

              $scope.$broadcast("openCrmPage", {

                interaction: data.interaction,
                customers: customers

              });
            }

          }).catch( err => {
            log.error("Customer not found", err);
          });

        } else {
          log.debug("Opening CRM Page");
          $scope.$broadcast("openCrmPage", {

            interaction: data.interaction,
            number: data.number,
            customers: []

          });

        }

      });

      $scope.switchAppModule = function (moduleAddress) {

        var refresh = false;

        if (moduleAddress !== null) {

          var ufpServiceAddress = SettingsData.get('UfpServiceAddress');
          var baseAddress = ufpServiceAddress + moduleAddress;

          if (baseAddress === Data.ufpAddress) {
            refresh = true;
          }

          Data.ufpAddress = baseAddress;

          // Czy na pewno chcemy tutaj to nullować?
          // To powoduje, że nie da się z poziomu CRM przypisać aktualnej interakcji do
          // klienta
          // Ale to było jeszcze w jakimś celu robione,tylko nie pamiętam w jakim :)
          // W takim, żeby została schowana aktualna interakcja, bo w widoku jest chujowo to ułożone
          Data.currentInteraction = null;

          $scope.closeAllMainButThis("interactions");

          // If we want to reload
          if (refresh) {
            Data.refreshFrame = true;
          }
        }

      };

      $scope.getEmailInteractions = function () {

        return Data.interactions.filter(function (inter) {
          if (inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundEmail) {
            return true;
          }
        });

      };

      // We must refresh statuses afrer language change
      function refreshStatuses() {

        Data.statuses = Data.statuses.map(function (status) {

          if (status.State in li18n.statuses) {
            status.Name = li18n.statuses[status.State];
          }

          return status;
        });

        if (Data.currentStatus.State in li18n.statuses) {
          Data.currentStatus.Name = li18n.statuses[Data.currentStatus.State];
        }

      }

      /**
       * @param {String} lang
       * @returm Promise
       */
      $scope.switchLangAndGetMenu = function (lang) {

        var withCredentials = config.ufp.withCredentials ? config.ufp.withCredentials : false;

        Data.lang = lang;
        li18n = i18n[lang];
        Data.li18n = li18n;

        var lngToSet = UtilsService.getExtendedLangCode(Data.lang);

        refreshStatuses();

        var ufpServiceAddress = SettingsData.get('UfpServiceAddress');

        // todo - 'Utils/Language/Change' ?
        var url = ufpServiceAddress + "Utils/Language/Change";
        var menuUrl = ufpServiceAddress + config.ufp.menuPage;

        return UfpService.switchLang({
          url: url,
          lang: lngToSet[1],
          withCredentials: withCredentials
        }).then(function (res) {

          log.debug("[switchLangAndGetMenu] Language was successfully set");
          Data.refreshFrame = true; // Used by ax-refreshable-frame in ufp iframe

          return UfpService.getMenu({
            url: menuUrl,
            withCredentials: withCredentials,
            li18n: li18n
          })

        }).then(function (menu) {

          log.debug("[switchLangAndGetMenu] Menu successfully received");

          $scope.$apply(function () {
            Data.appModules = menu;
          });

          return Promise.resolve(true);
        });

      };

      $scope.switchLang = function (lang) {

        return new Promise(function (resolve, reject) {

          var lMessage = "";
          Data.lang = lang;
          li18n = i18n[lang];
          Data.li18n = li18n;

          StatisticHelperService.changeLanguage(li18n.statistics);

          refreshStatuses();

          document.cookie = "ax-webagent-lang=" + Data.lang + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
          Data.miniSettingsVisible = false;

          // Update login and status message
          // Po co to tutaj jest????
          if ("loginResponse" in sClient && sClient.loginResponse !== null && "LoginResponseParams" in sClient.loginResponse && "Result" in sClient.loginResponse.LoginResponseParams && Data.loggedIn === false) {
            lMessage = getMessageByLoginResult(sClient.loginResponse.LoginResponseParams.Result);
            Data.loginMessage = lMessage;
            Data.statusMessage = lMessage;
          }

          if ("ufp" in config && "enabled" in config.ufp && config.ufp.enabled === true) {

            var lngToSet = UtilsService.getExtendedLangCode(Data.lang),
              withCredentials = config.ufp.withCredentials ? config.ufp.withCredentials : false;

            log.debug("[switchLang] UFP is enabled Trying to set UFP language to " + lngToSet[1]);

            $scope.switchLangAndGetMenu(lngToSet[0]).then(function () {

              if (Data.activeMenuItem !== null) {
                Data.activeMenuItem = $scope.findMenuItem(Data.activeMenuItem.item, Data.appModules);
              }

              resolve();
            }).catch(function (err) {

              reject();
              log.error("[switchLang] Error setting language or getting menu", err);
              Data.refreshFrame = true; // Used by ax-refreshable-frame on inrame

            });

          } else {

            log.debug("[switchLang] UFP is not enabled. Not trying to set language");
            resolve();
          }

        });

      };

      $scope.confirmCloseChatSession = function (sessionId) {

        var c;

        if (config.chat.confirmCloseChatSession === true) {
          c = confirm(li18n.messages.closeChatSessionMsg);
          if (c) {
            $scope.closeChatSession(sessionId);
          }
        } else {
          $scope.closeChatSession(sessionId);
        }
      };

      $scope.closeChatSession = function (sessionId) {

        sessionId = Data.currentInteraction.session.Session.SessionId;

        log.debug("closeChatSession: Closing chat session " + sessionId);

        // Hangup attached voice calls
        // 1. Rozłączamy call
        // 2. Zamykamy sesję
        SessionHelperService.getAttachedSessions(sessionId).map(function (attachedSession) {

          //calls.items[callid].SessionServerSessionSerialId

          // Find call, and disconnect
          var attachedCall = userPhone.phone.calls.getCall(attachedSession.session.TransferCallId);

          if (attachedCall !== null) {
            log.debug("Trying to disconnect call");
            userPhone.phone.disconnectCall(attachedCall, 0).then(function (res) {

              log.debug("Call disconnected");
              SessionHelperService.closeCurrentSession(attachedSession, true);

            }, function (err) {
              log.error("ERROR! Disconnecting call failed");
              log.error(err);
            });
          }

        });

        // Hangup attached video call
        if ($scope.isVideoCallActive()) {

          hangupVideoCall();

        }

        sClient.closeChatSession({
          sessionId: sessionId,
          reason: ax.SessionLib.AxCloseChatSessionReason
        }).then(function (res) {
          log.debug("closeChatSession: Chat session " + sessionId + " closed");
        }).catch(function (err) {
          log.error("closeChatSession: Chat session " + sessionId + " closing error", err);
        });

      };

      $scope.canCloseCurrentChatSession = function () {

        var sessionToClose = Data.currentInteraction,
          AxState = ax.SessionLib.AxSessionState,
          sState;
        if (sessionToClose) {
          sState = sessionToClose.session.Session.SessionState;
        }

        if (sessionToClose && (sState === AxState.AcceptedByUser ||
          sState === AxState.DirectedToUser)) {

          return true;
          /*var surveyIsReady = true;
                if ( sessionToClose.surveySession ) {
                    if ( sessionToClose.surveySession.Ended === true ) {
                        surveyIsReady = true;
                    } else {
                        surveyIsReady = false;
                    }
                }

                if ( surveyIsReady === true && session.isCrmReady === true ) {
                    return true;
                }*/


        }

        return false;
      };

      $scope.clearCustomerCallContext = function () {

        DialpadData.customerName = "";
        DialpadData.contactCustomerId = null;
        DialpadData.contactId = null;
      };

      $scope.canGetManualChatHint = function () {

        return config.menerva.manualHintEnabled && Data.currentInteraction && Data.currentInteraction.data && Data.currentInteraction.data.messages && Data.currentInteraction.data.messages.length > 0;

      };

      $scope.canInsertManualChatHint = function () {

        return Data.currentInteraction && Data.currentInteraction.data && Data.currentInteraction.data.chatHint && Data.currentInteraction.data.chatHint.length > 0;
      };

      $scope.getChatHint = function () {

        if (config.menerva.manualHintEnabled) {

          Data.currentInteraction.data.chatHintErrorFlag = false;
          Data.currentInteraction.data.chatHintError = Data.li18n.messages.gettingMenervaHint;

          var chatText = "";
          var messages = Data.currentInteraction.data.messages,
            l = Data.currentInteraction.data.messages.length,
            msg;
          for (var i = 0; i < l; i++) {
            msg = messages[i];
            chatText += msg.SenderFriendlyName + "\n" + msg.Message + "\n";
          }

          $http.post(config.menerva.chatServer,
            {
              "configuration": { "alias": config.menerva.chatAlias },
              "command": "Request",
              "commandValue": "Session=" + Data.currentInteraction.session.Session.SessionId + '&Input=' + chatText
            }
          ).then(function (res) {
            var data = res.data;

            log.debug("[WebAgent.getChatHint] Data received from Menerva", data);
            if ("Status" in data && data.Status == 0) {
              if (data.Body && data.Body.CRMPROC && data.Body.CRMPROC.TOPIC && data.Body.CRMPROC.TOPIC !== "UNKNOWN.") {

                Data.currentInteraction.data.chatHintErrorFlag = false;
                Data.currentInteraction.data.chatHintError = "";

                if (data.Body.RESPONSE && data.Body.RESPONSE.TEXT) {
                  Data.currentInteraction.data.chatHint = data.Body.RESPONSE.TEXT;
                }

              } else {
                Data.currentInteraction.data.chatHintErrorFlag = false;
                Data.currentInteraction.data.chatHintError = Data.li18n.messages.noHintsAvailable;
              }
            } else {
              log.warn("[WebAgent.getChatHint] Menerva received error response");
              Data.currentInteraction.data.chatHintErrorFlag = true;
              Data.currentInteraction.data.chatHintError = Data.li18n.messages.errorGettingMenervaHint;
            }

          }).catch(function (res) {
            var status = res.status;

            log.error("Error getting menerva hint");
            log.error("Response status: " + status);
            Data.currentInteraction.data.chatHintErrorFlag = true;
            Data.currentInteraction.data.chatHintError = Data.li18n.messages.errorGettingMenervaHint;
          });

        } else {
          log.warn("[WebAgent.getChatHint] Menerva chat is not enabled");
        }
      };

      $scope.insertChatHint = function () {

        $scope.chatAddTextToinput(Data.currentInteraction.session.Session.SessionId, Data.currentInteraction.data.chatHint);
        Data.currentInteraction.data.chatHint = "";

      };

      $scope.openCustomerSearch = function () {
        var ufpServiceAddress = SettingsData.get('UfpServiceAddress');

        SearchCustomerData.url = UtilsService.createUrl({
          fromDomain: false,
          url: ufpServiceAddress,
          path: Data.CONFIG.ufp.customerListPage
        });

        Data.searchCustomerVisible = true;

      };

      $scope.openFileSearch = function () {
        var ufpServiceAddress = SettingsData.get('UfpServiceAddress');

        SearchFileData.url = UtilsService.createUrl({
          fromDomain: false,
          url: ufpServiceAddress,
          path: Data.CONFIG.ufp.fileSearchPage + "?modalId=fileSearch"
        });

        Data.searchFileVisible = true;

      };

      $scope.openFolderSearch = function (data) {
        var ufpServiceAddress = SettingsData.get('UfpServiceAddress');

        var parameters = ["modalId=" + data.modalId,
          "fileName=" + data.fileName,
          "fileId=" + data.fileId,
          "fileSize=" + data.fileSize];

        SearchFolderData.url = UtilsService.createUrl({
          fromDomain: false,
          url: ufpServiceAddress,
          path: Data.CONFIG.ufp.folderSearchPage + "?" + parameters.join("&")
        });
        Data.searchFolderVisible = true;

      };

      $scope.downloadFile = function (file) {

        if (file.directLink === true) {

          var link = document.createElement('a');
          link.href = file.fileUrl;
          link.target = "_blank";

          const event = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancellable: true
          });
          Data.downloadTriggeredByUser = true;
          link.dispatchEvent(event);

        } else {

          LoginService.getAccessToken({
            refreshToken: Data.refreshToken,
            tokenType: "download",
            tokenParams: {
              file_id: file.fileId,
              token_lifetime: 300000
            }
          }).then(function (res) {

            var accessToken = "";
            if (res !== null) {
              accessToken = res.accessToken;
            }

            var tokenUrl;
            if (file.fileUrl.indexOf("token") !== -1) {
              tokenUrl = file.fileUrl.replace(/(token=).*?(&)/, '$1' + accessToken + '$2');
            } else {
              tokenUrl = file.fileUrl + "?token=" + accessToken;
            }

            // Hack for downloading file works in firefox and chrome without _blank target and websocket disconnection
            const ifr = document.createElement('iframe');
            ifr.addEventListener("load", (evt) => {

              const cd = ifr.contentDocument,
                link = cd.createElement('a');
              link.href = tokenUrl;
              cd.body.appendChild(link);
              // Schedule removing iframe after download click
              link.addEventListener("click", () => setTimeout(() => document.body.removeChild(ifr), 2000));

              const event = new MouseEvent("click");

              Data.downloadTriggeredByUser = true;
              link.dispatchEvent(event);

            });
            document.body.appendChild(ifr);

          }).catch(function (err) {
            // TODO: Emit error and show in GUI
            log.debug("Download error", err);
          });
        }

      };

      $scope.dialpadDialButtonEnabled = function () {

        return Data.dialNumber.length > 0 && userPhone.phone.calls.length < 2 && (SystemVariables.DisableNoCampaignManualCalling.Value === '0' || (SystemVariables.DisableNoCampaignManualCalling.Value === '1' && DialpadData.selectedCampaign !== null)) && $scope.isVideoCallActive() === false;

      };

      $scope.fixTableHeadWidth = function (tabId) {

        // Hacky trick/hacky shit
        $timeout(function () {

          var tab = document.getElementById(tabId);

          var tHead = tab.querySelector("thead");
          var tBody = tab.querySelector("tbody");

          var width = tBody.clientWidth;
          tHead.style.width = width + "px";

        }, 0);

      };

      $scope.hasAttachedInteraction = function (inter) {

        if (SessionHelperService.getAttachedSessions(inter.session.Session.SessionId).length > 0) {
          return true;
        }
        return false;

      };

      $scope.getCustomerPageAddress = function (config) {
        var path = config.ufp.customerPage;

        // If customer page has relative address add base ufp address
        return UtilsService.isAbsolutePath(path)
          ? path : SettingsData.get('UfpServiceAddress') + path;
      };

      $scope.getCustomerListPageAddress = function (config) {
        var path = config.ufp.customerListPage;

        // If customer list page has relative address add base ufp address
        return UtilsService.isAbsolutePath(path)
          ? path : SettingsData.get('UfpServiceAddress') + path;
      };

      $scope.setUfpCss = function () {

        return function (evt) {
          evt.target.contentWindow.postMessage(JSON.stringify({ command: "fixCssForWebCommunications" }), '*');
        }

      };

      $scope.setUfpCssEmail = function () {

        return function (evt) {
          evt.target.contentWindow.postMessage(JSON.stringify({ command: "fixCssForWebCommunicationsEmail" }), '*');
        }

      };

      /**
       * Sets session customer context
       * @param {Object} p Parameters object
       * @param [p.customerId=null] CustomerId
       * @param [p.contactId=null] ContactId
       * @param [p.sessionId=null] Default to current session
       * @return {Promise}
       */
      $scope.setSessionCustomerContext = function (p) {

        var customerId = "customerId" in p && p.customerId !== null ? p.customerId.toString() : null,
          contactId = "contactId" in p && p.contactId !== null ? p.contactId.toString() : null,
          currentSession = "sessionId" in p && p.sessionId !== null ? SessionService.getSessionById(p.sessionId).session.Session : Data.currentInteraction.session.Session;

        return SessionClient.setSessionParam({
          sessionId: currentSession.SessionId,
          param: ax.SessionLib.AxSessionParam.SetCustomerContext,
          paramValue: customerId,
          paramValue2: contactId,
          sessionSerialId: currentSession.SessionSerialId
        }).then(function (res) {
          return SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.SetScriptValue, WL.guidEmpty, customerId, null, null, null, "ContactCustomerId");
        }).then(function () {
          return SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.SetScriptValue, WL.guidEmpty, contactId, null, null, null, "ContactId");
        }).then(function () {

          // Set customer and contact id in current session
          if (Data.currentInteraction) {
            Data.currentInteraction.session.Session.ContactCustomerId = customerId;
            Data.currentInteraction.session.Session.ContactId = contactId;

            /////////////////////////////////////////////////////////////////////
            var customerPageAddress = customerId === null ? $scope.getCustomerListPageAddress(config) : $scope.getCustomerPageAddress(config);

            if (customerId !== null) {
              customerPageAddress += customerId;
            }

            // TODO: Tutaj powinno być formatowanie adresu ze zmiennymi itp....
            // A ten adres powinien przyjścz sesji, a nie z konfiguracji
            Data.currentInteraction.data.web.customerUrl = customerPageAddress;
            /////////////////////////////////////////////////////////////////////
          }

          return Promise.resolve(true);

        }).catch(function (err) {
          return Promise.reject(err);
        });

      };

      $scope.test = function () {

        var api = new ax.HostApi.ApiProxy({ hostWindow: window, hostAppWindow: window });

        api.sendEmailEvent({
          sessionId: Data.currentInteraction.session.Session.SessionId,
          eventType: ax.SessionLib.AxEmailEventsEventType.Received,
          emailMessageId: Data.currentInteraction.session.Session.BaseMessageObject.Id,
          tag: "SSS"
        }).then(function (res) {

          console.log("Poszło", res);

        }).catch(function (err) {
          console.error("Nie poszło", err);
        });
      };

      $scope.isSplitterVisible = function () {
        return true;
        var axSessionSubType = ax.SessionLib.AxSessionSubtype,
          sessionSubType,
          interaction = Data.currentInteraction;

        if (interaction) {
          sessionSubType = interaction.session.Session.SubType;

          if (interaction.surveySession
            || sessionSubType === axSessionSubType.InboundChat
            || sessionSubType === axSessionSubType.InboundEmail
            || sessionSubType === axSessionSubType.InboundSocial
          ) {
            return true;
          }

        }

        return false;

      };

      $scope.getSearchFolderUrl = function () {
        var ufpServiceAddress = SettingsData.get('UfpServiceAddress');
        return ufpServiceAddress + Data.CONFIG.ufp.folderSearchPage;
      };

      $scope.dialVideo = function () {
        processChatSessionCommandMessage(undefined, Data.currentInteraction);
      };

      $scope.hangupVideo = function () {
        var videoCall = Data.currentInteraction.data.videoCall;

        MediaProxyClientService.hangup(videoCall.id).then(function () {
          log.debug("Video disconnected");
        }).catch(function (err) {
          log.error("Error disconnecting video");
        });

      };

      $scope.chatToggleLocalAudio = function () {

        WebRtcBuddyService.toggleLocalAudio()

      };

      $scope.chatToggleLocalVideo = function () {

        WebRtcBuddyService.toggleLocalVideo()

      };

      $scope.getActiveVideoCall = function () {

        return MediaProxyClientService.getActiveCall();

      };

      $scope.getActiveOrIncomingVideoCall = function () {

        var session,
          call = MediaProxyClientService.getActiveCall();

        if (!call) {
          session = VideoService.getAssociatedInteraction();
          if (session !== null) {
            // Fake, but works :)
            if (VideoService.getAlertingState() === true) {
              VideoData.fakeIncomingCall.id = session.session.Session.RemoteSideFriendlyNameComputed;
              call = VideoData.fakeIncomingCall;
            }

          }
        }

        return call;

      };

      $scope.getVideoCallsAsArray = function () {

        return MediaProxyClientService.getCallsAsArray();

      };

      $scope.ringAlerting = function () {
        Data.localRingAudio.element.pause();
        Data.localRingAudio.element.src = '/assets/sounds/incoming.wav';
        Data.localRingAudio.element.loop = true;
        Data.localRingAudio.element.play().then(function (p) {
          log.trace("[WebCommunications.ringAlerting] Playing incoming sound started");
        }).catch(function (err) {
          log.trace("[WebCommunications.ringAlerting] Playing incoming sound interrupted", err);
        });
      };

      $scope.ringAlertingIfNotRinging = function () {

        if ($scope.isAlertingRinging() === false) {
          $scope.ringAlerting();
        }

      };

      $scope.stopRingAlerting = function () {
        Data.localRingAudio.element.pause();
      };

      $scope.isAlertingRinging = function () {

        // Indexof instead of === because src contains domain name
        return Data.localRingAudio.element.src.indexOf("/assets/sounds/incoming.wav") !== 1 && Data.localRingAudio.element.paused === false;

      };

      $scope.chatAddTextToinput = function (sessionId, text) {

        $scope.$broadcast("chatAnswerAddedToInput", {
          sessionId: sessionId,
          text: text
        });

      };

      $scope.getUserSalary = function () {
        sClient.getUserSalary(Data.currentUser.Id).then(function (res) {
          var salaries = StatisticHelperService.mapSalary(res.SallaryReport);
          Data.salaryList = salaries;
        });
      };

      $scope.isPreviewDialingDisabled = function () {

        return $scope.isVideoOrAudioCallActive();

      };

      $scope.pushIncomingVideoChatMessage = function (session) {
        var systemMsgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();
        systemMsgItem.__from = "system";
        systemMsgItem.__messageType = "incoming_video_call";
        systemMsgItem.Message = li18n.messages.videoCall;
        systemMsgItem.SentOn = moment().format("YYYY-MM-DD HH:mm");
        systemMsgItem.SenderType = ax.AxCommonLib.AxObjectType.Service;
        systemMsgItem.SenderFriendlyName = li18n.messages.system;
        systemMsgItem.ChatMessageId = UUID();
        session.data.messages.push(systemMsgItem);
      };

      $scope.pushEndedVideoChatMessage = function (session) {
        var systemMsgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();
        systemMsgItem.__from = "system";
        systemMsgItem.__messageType = "video_call_ended";
        systemMsgItem.Message = li18n.messages.videoCallEnded;
        systemMsgItem.SentOn = moment().format("YYYY-MM-DD HH:mm");
        systemMsgItem.SenderType = ax.AxCommonLib.AxObjectType.Service;
        systemMsgItem.SenderFriendlyName = li18n.messages.system;
        systemMsgItem.ChatMessageId = UUID();
        session.data.messages.push(systemMsgItem);
      };

      $scope.isVideoCallActive = function () {

        return VideoService.getDialingState() === true || VideoService.getAlertingState() || (MediaProxyClientService.getActiveCall() ? true : false);
      };

      $scope.isVideoOrAudioCallActive = function () {
        var uState = ax.SessionLib.AxUserState;
        return $scope.isVideoCallActive() || Data.currentStatus.State === uState.Connecting || Data.currentStatus.State === uState.InSession;
      };

      $scope.sessionRequiredActions = function (inter) {

        var actions = [],
          isCallReady = false;

        if (inter) {

          // Check if there is no associated calls
          if (inter.session && userPhone && userPhone.phone && userPhone.phone.calls) {

            var associatedCall = userPhone.phone.calls.getCall(inter.session.TransferCallId);
            isCallReady = !associatedCall;

          } else {
            isCallReady = true;
          }

          if (isCallReady === false) {
            actions.push("<li>" + li18n.messages.endCall + "</li>");
          }

          if (inter.isCrmReady === false) {
            actions.push("<li>" + li18n.messages.assignCustomer + "</li>");
          }

          if (inter.isEmailReady === false) {
            actions.push("<li>" + li18n.messages.closeEmail + "</li>");
          }

          if (inter.isSurveyReady === false) {
            actions.push("<li>" + li18n.messages.completeSurvey + "</li>");
          }

          if ((inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat
            || inter.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial)
            && inter.session.Session.IsEnded === false) {

            actions.push("<li>" + li18n.messages.endSession + "</li>");

          }

        }

        if (actions.length > 0) {
          actions.unshift("<p class='required-actions-tt-header'>" + li18n.messages.requiredActions + ":</p><ul class='required-actions-tt'>");
          actions.push("</ul>");
        }

        return actions.join("");

      };

      /*
        $scope.showInteractions = function() {

            console.log(Data.interactions);

        };*/

      /* Tests
        $scope.getDbData = function() {

            logWorker.postMessage(createWorkerMessage("get-db-data",{sth:""}));

        }*/

    }]);

})(ax, angular);
