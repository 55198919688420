import template from './AxSurveyFieldTextValue.html';

function AxSurveyFieldTextValue() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {
  var vm = this;
  vm.isValid = true;

  vm.correctValue = null;

  helpers.initField(vm);
  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      if (!val) {
        vm.invalidMessage = vm.li18n.messages.fieldIsRequired;
        return "fieldIsRequired";
      }
    }

    if (val) {
      if (!vm.isValid && val.length >= 0) {
        vm.invalidMessage = vm.li18n.messages.invalidFormat;
        return "invalidFormat";
      }
    }

    if (val === 'null') {
      vm.invalidMessage = vm.li18n.messages.invalidFormat;
      return "invalidFormat";
    }

    return null;
  };

  vm.onValueSet = function (field) {
    field = field || vm.axC;
    field.AnswerDateTime = new Date().getTime();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.input = $element.find("input[data-input-type=text]");

  $scope.$watch('vm.axI18n', function (newVal) {
    if (newVal) {
      $scope.$apply(function () {
        $scope.vm.axI18n = newVal;
      })
    }
  })
}

export { AxSurveyFieldTextValue };
