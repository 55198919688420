function SurveyService(WL, Data, Sender, sClient, config, $rootScope, SessionHelperService, SessionService, SettingsData, UtilsService) {

  this.sendGetDnsRequest = sendGetDnsRequest;
  this.sendValidateEmailRequest = sendValidateEmailRequest;
  this.sendSetDataRecordStage = sendSetDataRecordStage;
  this.startSurveySession = startSurveySession;

  function sendGetDnsRequest(p) {

    return WL.sendGetDnsRequest(p).then(function (res) {

      var r = WL.unpackObject(res);

      return Promise.resolve(r);

    }, function (err) {

      return Promise.reject(err);

    });
  }

  /**
   * @param {Object} p
   * @param {String} p.email Email to validate
   * @param {Number} [p.timeout=null] Check domain timeout in seconds
   * @param {Boolean} p.validateDns If dns record must be checked
   * @param {Boolean} p.validateSmtp If SMTP server must be checked
   * @param {Array.<Number} p.smtpPorts
   * @param {Array.<Number>} p.smtpSslPorts
   * @param {String} p.heloDomain Domain sent in HELO SMTP message
   */
  function sendValidateEmailRequest(p) {

    return WL.validateEmail(p).then(function (res) {

      var r = WL.unpackObject(res);

      return Promise.resolve(r);

    }, function (err) {

      return Promise.reject(err);

    });

  }

  /**
   * @param {Number} level
   * @return {Promise}
   */
  function sendSetDataRecordStage(level) {

    level = typeof level !== "undefined" ? level : null;

    var surveySession = Data.currentInteraction.surveySession;

    var param = {
      type: ax.AxSurveysLib.AxSurveyActionType.SetServingStage,
      sessionId: surveySession.SessionId,
      formId: surveySession.CurrentForm.Form.FormId,
      answer: null,
      shiftResult: null,
      terminateResult: null,
      record: null,
      scriptValueName: null,
      level: level
    };

    return Sender.sendSurveyPageAction(param).then(function (res) {

      var retObj = WL.unpackObject(res);
      return Promise.resolve(retObj);

    }, function (err) {
      return Promise.reject(err)
    });

  }

  function getCustomerListPageAddress() {
    var path = config.ufp.customerListPage;

    // If customer list page has relative address add base ufp address
    return UtilsService.isAbsolutePath(path)
      ? path : SettingsData.get('UfpServiceAddress') + path;
  }

  function getCustomerPageAddress() {
    var path = config.ufp.customerPage;

    // If customer page has relative address add base ufp address
    return UtilsService.isAbsolutePath(path)
      ? path : SettingsData.get('UfpServiceAddress') + path;
  }

  /**
   * @param {Object} p Parameters object
   * @param {InteractionItem} p.interaction
   * @param {ax.SessionLib.Sessions.Actions.AxSessionEventSurveyAction} p.action
   * @param {Logger} p.log Logger
   * @return {Promise}
   */
  function startSurveySession(p) {

    var interaction = p.interaction,
      action = p.action,
      log = p.log,
      session = interaction.session.Session,
      subType = session.SubType,
      AxSessionSubtype = ax.SessionLib.AxSessionSubtype;

    var hasAllowSearchForContact = false;
    var sActions = interaction.session.Actions;
    for (var i = 0; i < sActions.length; i++) {
      if (sActions[i] instanceof ax.SessionLib.Sessions.Actions.AxSessionEventIdentifyCaller) {
        hasAllowSearchForContact = true;
        break;
      }
    }

    // Because of BUG https://unifiedfactory.atlassian.net/browse/UFC-303
    // Get customer id from record data
    var customerField = interaction.session.getDataRecordFieldByName("ContactCustomerId");
    if (customerField instanceof ax.SessionLib.DataContacts.Campaigns.AxDataContactRecordDataField
      && customerField.Value !== null) {

      log.debug("[startSurveySession] ContactCustomerId in record is " + customerField.Value + " Setting session param");
      // Assign customer to session
      sClient.setSessionParam({
        sessionId: session.SessionId,
        param: ax.SessionLib.AxSessionParam.SetCustomerContext,
        paramValue: customerField.Value.toString(),
        paramValue2: null,
        sessionSerialId: interaction.session.Session.SessionSerialId
      }).then(function () {

        log.debug("[startSurveySession] Session param SetCustomerContext was set");
        // Set contact id in session to
        interaction.session.Session.ContactCustomerId = parseInt(customerField.Value);

        interaction.data.web.customerUrl = getCustomerPageAddress(config) + interaction.session.Session.ContactCustomerId + "#tab1";

      }).catch(function (err) {
        log.debug("[startSurveySession] Error setting session param SetCustomerContext", err);
      });

    } else {
      log.debug("[startSurveySession] ContactCustomerId in record is null");
      interaction.data.web.customerUrl = getCustomerListPageAddress(config);
    }

    // Start survey session
    var aP = WL.createAxObject("AxSurveyRunAdditionalParams");
    aP.value.UserId = sClient.loginResponse.LoginResponseParams.AccountData.Id;
    aP.value.UserName = sClient.loginResponse.LoginResponseParams.AccountData.Name;
    aP.value.UserSurname = sClient.loginResponse.LoginResponseParams.AccountData.Surname;
    aP.value.UserEmail = sClient.loginResponse.LoginResponseParams.AccountData.Email;
    aP.value.UserDdi = sClient.loginResponse.LoginResponseParams.AccountData.ForceDdiNumber;
    aP.value.UserLogin = Data.login;
    aP.value.UserPassword = Data.password;

    if (session.BaseCallObject !== null && typeof session.BaseCallObject !== "undefined") {
      aP.value.Ani = session.BaseCallObject.Ani.FriendlyName;
      aP.value.Dnis = session.BaseCallObject.Dnis.FriendlyName;
    } else {
      aP.value.Ani = "";
      aP.value.Dnis = "";
    }
    aP.value.CampaignId = session.DestinationObjectId;
    aP.value.SessionId = session.SessionId;
    aP.value.SerialSessionId = session.DataElementSerialId;
    aP.value.ProjectId = session.ProjectId;
    aP.value.DataRecordPhoneIndex = interaction.session.DataRecordPhoneIndex;
    aP.value.CampaignProperties = action.CampaignProperties;
    aP.value.UserCustomValues = action.UserCustomValues;
    aP.value.ContactCustomerId = session.ContactCustomerId;
    aP.value.ContactId = session.ContactId;
    aP.value.ContactCustomerPersonId = session.ContactCustomerPersonId;
    aP.value.SessionDirection = session.Direction;
    aP.value.SessionInfo = session.SessionInfo;

    interaction.additionalParams = aP;

    var beginSurvey = function (dataRecord) {
      $rootScope.$apply(function () {

        interaction.isSurveyLoading = true;

      });
      if (dataRecord) { // jeśli rekord wybrany z listy lub utworzony
        if (!interaction.dataRecord) {
          interaction.prepareDataFieldsEdit(dataRecord);
        }
        if (!interaction.session.ShiftingParams) {
          var shiftParams = WL.createAxObject("AxDataRecordShiftingOptionParams");
          shiftParams.value.ShifFrom = new Date().getTime();
          shiftParams.value.ShiftMode = ax.SessionLib.AxDataRecordShiftingMode.Free;
          interaction.session.ShiftingParams = shiftParams;
          interaction.session.ShiftingParams = WL.unpackObject(shiftParams);
        }
      }
      var dr = interaction.session.DataRecord || dataRecord || null;
      if (dr !== null) {
        aP.value.DataRecordId = dr.Id;
      } else {
        aP.value.DataRecordId = null;
      }

      WL.beginSurveySession({
        scriptId: action.SurveyToExecute,
        userAccountId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
        dataRecord: dr,
        shiftingParams: interaction.session.ShiftingParams,
        additionalParams: aP
      }).then(function (res) {
        /**
         * @type {ax.AxSurveysLib.AxMessages.AxMsgBeginSurveySessionResponse}
         */
        var unpacked = WL.unpackObject(res);
        if (unpacked.Result === ax.AxSurveysLib.AxMessages.AxComMsgSurveysResult.Success) {
          log.debug("[startSurveySession.beginSurvey] Getting data from external data sources");
          SessionHelperService.getExternalDataSource(unpacked.SessionContainer.CurrentForm.Fields, interaction.validateExternalDataSources).then(function () {
            log.debug("[startSurveySession.beginSurvey] All external data sources received");
          }, function (err) {
            log.debug("[startSurveySession.beginSurvey] Error while getting external data", err);
          }).then(function () {

            // Ponieważ interakcje na liście są podmieniane
            // To tutaj musimy dopisać survey do instancji interackji z listy
            // bo najczęściej będzie tak, że interakcja w zmiennej interaction do której otrzymujemy referencję
            // w momencie wywołania funkcji nie będzie już tą interakcją, która faktycznie jest w aplikacji
            var fromList = SessionService.getSessionById(session.SessionId);

            if (fromList !== null) {
              $rootScope.$apply(function () {
                fromList.surveySession = unpacked.SessionContainer;
                fromList.beginSurveySession = unpacked.SessionContainer;
                fromList.isSurveyLoading = false;
              });
            } else {
              log.error("[startSurveySession.beginSurvey] Interaction not found on list");
            }

          });


        } else {
          log.error("[onSessionDirectedReceived.beginSurvey] Survey session not started. Result: " + unpacked.Result, unpacked);

          if (fromList !== null) {
            var fromList = SessionService.getSessionById(session.SessionId);
            $rootScope.$apply(function () {
              fromList.surveySession = null;
              fromList.beginSurveySession = null;
              fromList.isSurveyLoading = false;
            });
          } else {
            log.error("[startSurveySession.beginSurvey] Interaction not found on list");
          }

        }

      }, function (err) {
        log.error("Survey session not started", err);
        $rootScope.$apply(function () {
          interaction.surveySession = null;
          interaction.beginSurveySession = null;
          interaction.isSurveyLoading = false;
        });
      });
    };

    if (hasAllowSearchForContact === false) {
      beginSurvey();
    } else {
      interaction.beginSurveyAction = beginSurvey;
    }

  }

}

export { SurveyService };
