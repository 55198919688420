import angular from 'angular';
import { ApiRequestWrapperService } from './ax.apirequestwrapper.service';
import { Errors } from './ax.errors.constant';
import { FileService } from './ax.fileservice.service';
import { LoginService } from './ax.login.service';
import { MediaProxyClientService } from './ax.mediaproxyclient.service';
import { MiniApiService } from './ax.miniapi.service';
import { NotificationService } from './ax.notification.service';
import { NotificationData } from './ax.notificationdata.factory';
import { SessionClient } from './ax.sessionclient.service';
import { StatisticHelperService } from './ax.statistics.service';
import { SurveyService } from './ax.survey.service';
import { UfpService } from './ax.ufp.service';
import { VideoService } from './ax.video.service';
import { WebRtcBuddyService } from './ax.webrtcbuddy.service';
import { ComClient } from './ax.comclient.service';
import { UtilsService } from './ax.utils.service';
import { ClipboardService } from "./ax.clipboard.service";
import { SoundService } from "./sound.service";
import { Storage } from "./storage.service";

export default angular.module("ax.WebCommunications.services", [])
  .service("WebRtcBuddyService", [
    "$rootScope",
    WebRtcBuddyService
  ])
  .service("VideoService", [
    "WebRtcBuddyService",
    "MediaProxyClientService",
    "Errors",
    VideoService
  ])
  .service('UfpService', ['$http', 'SettingsData', 'CONFIG', UfpService])
  .service("SurveyService", [
    "WL",
    "Data",
    "Sender",
    "sClient",
    "CONFIG",
    "$rootScope",
    "SessionHelperService",
    "SessionService",
    "SettingsData",
    "UtilsService",
    SurveyService
  ])
  .service('StatisticHelperService', [ 'SessionClient', StatisticHelperService])
  .service('SessionClient', ['sClient', SessionClient])
  .factory('NotificationData', NotificationData)
  .service("NotificationService", ["NotificationData", "CONFIG", "Data", NotificationService])
  .service("MiniApiService", ["Errors", "CONFIG", "Data", "$http", MiniApiService])
  .service("MediaProxyClientService", ["$rootScope", "Errors", MediaProxyClientService])
  .service("LoginService", ["Errors", "CONFIG", "$http", LoginService])
  .service("FileService", ["LoginService", "Errors", "CONFIG", "$http", FileService])
  .service("ApiRequestWrapperService", [
    "Errors",
    "LoginService",
    "CONFIG",
    "Data",
    "$http",
    ApiRequestWrapperService
  ])
  .service("UtilsService", ['SettingsData', UtilsService])
  .service("ComClient", ['UtilsService', ComClient])
  .service("ClipboardService", [ClipboardService])
  .service("SoundService", [
    '$ngRedux',
    'playSoundEnd',
    'playSoundStart',
    'getSoundSettings',
    'PhoneSound',
    'beep',
    'SoundType',
    'isSoundPlaying',
    SoundService])
  .service("Storage", Storage)
  .constant('Errors', Errors)
  .name;
