function AxRefreshableFrame() {
  return {
    restrict: 'A',
    scope: {
      refreshFrame: "=axRefreshableFrame"
    },
    link: function ($scope, $element) {
      $scope.$watch('refreshFrame', function (newVal, oldVal) {
        if ($scope.refreshFrame) {
          $scope.refreshFrame = false;
          $element.attr('src', $element.attr('src'));
        }
      });

    }
  };
}

export { AxRefreshableFrame };
