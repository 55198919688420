function AxSplitterArea() {

  return {
    restrict: "E",
    link: axSplitAreaLink,
    transclude: true,
    scope: true,
    controllerAs: "vma",
    template: "<ng-transclude></ng-transclude>", // Nie ma sesnu tego dawać do templatów w osobnym pliku bo jest to tylko rzecz techniczna, a generowało by to dodaktowy request http
    bindToController: {
      initialHeight: "=axInitialHeight"
    },
    controller: AxSplitAreaController
  }

}

function AxSplitAreaController() {

  var k = 1;

}

function axSplitAreaLink($scope, $element, $attrs, $ctrl, $transclude) {

  var paneScope = $scope.$parent.$parent.$parent;

  if ("vmp" in paneScope) {

    var parentAreas = paneScope.vmp.areas;

    // Sprawdzam, czy jestem już dodany
    if (parentAreas.index($element) == -1) { // Jeżeli nie jestem

      // Jeżeli jest ustawiony initial height, to ustawiam
      if ($scope.vma.initialHeight) {

        $element.css("height", $scope.vma.initialHeight);

      }

      // Muszę znaleźć jeszcze sam swoją pozycję w areas, żeby się pushnąć w odpowiednie miejsce
      var areas = paneScope.vmp.$element.children("ng-transclude").children("ax-splitter-area");
      var myIndex = areas.index($element);

      // Bierzemy areas
      // W pętli sprawdzamy, czy następny element to jest splitter
      // Jezeli nie jest to go wstawiam
      parentAreas = areas;
      parentAreas.each(function (idx) {

        var th = $(this);
        var nextElement = th.next();
        if (nextElement.length > 0) {
          var nextTagName = nextElement.prop("tagName").toLowerCase();

          if (nextTagName === "ax-splitter-area") {

            var newSplitter = paneScope.vmp.createSplitter();
            th.after(newSplitter);

          }

        }

      });


      // Po dodaniu elementu trzeba przeliczyć wysokość ostatniego
      recalculateLastAreaHeight(paneScope.vmp.$element, parentAreas);

    }

  }

}

function AxSplitterPane() {

  return {
    restrict: 'E',
    link: link,
    scope: {
      displayChanged: "=displayChanged"
    },
    transclude: true,
    template: "<ng-transclude></ng-transclude>", // Nie ma sesnu tego dawać do templatów w osobnym pliku bo jest to tylko rzecz techniczna, a generowało by to dodaktowy request http
    controllerAs: 'vmp',
    bindToController: true,
    controller: ["$scope", "$timeout", controller]
  }

}

function controller($scope, $timeout) {

  var vm = this;
  vm.minUpHeight = 20;
  vm.minDownHeight = 20;
  vm.areas = [];

  vm.recalculateLastAreaHeight = function () {
    $timeout(function () {
      recalculateLastAreaHeight(vm.element, vm.areas);
    }, 0);
  };

}

function link($scope, $element, $attrs, $ctrl) {

  $scope.vmp.$element = $element;

  // 1. szukamy elementów spliter-area
  var areas = $element.children("ng-transclude").children("ax-splitter-area");
  $scope.vmp.areas = areas;

  $scope.vmp.element = $element;

  if (areas.length < 2) {
    //throw new Error("AxSplitter need at least two ax-splitter-area descendans");
  }

  // 2. Tworzymy splittery
  var splittersLen = areas.length - 1;
  var splitters = [];

  for (var i = 0; i < splittersLen; i++) {
    splitters.push(createSplitter());
  }

  // Do każdego elementu splitter-area dodajemy splitter
  areas.each(function (idx) {
    if (idx < splittersLen) {
      this.after(splitters[idx]);
    }
  });


  // Ustawienie początkowej wysokości elementów
  // Na razie nie trzeba nic innego, więc ustawiam wysokość ostatniego elementu tak, żeby wypełniał całość kontenera
  recalculateLastAreaHeight($element, areas);

  var dragStarted = false,
    $firstElementToResize,
    $secondElementToResize;

  function splitterMousedown(evt) {
    var target = $(evt.currentTarget);
    $firstElementToResize = target.prev("ax-splitter-area");
    $secondElementToResize = target.next("ax-splitter-area");

    // Blokada pointer eventów, w ewentualnych ramkach
    $firstElementToResize.find("iframe").css("pointer-events", "none");
    $secondElementToResize.find("iframe").css("pointer-events", "none");
    dragStarted = true;
  }

  function splitterHandlerDblClick(evt) {

    var dstHeight;
    var w = $element.width();
    if ($element.height() > $scope.wtf.minimizedHeight) {

      dstHeight = $scope.wtf.minimizedHeight + "px";

    } else {

      dstHeight = "";
    }

    $element.height(dstHeight);

    /*$scope.wtf.emitSplitChanged({
        height : dstHeight
    });*/

    evt.stopPropagation();

  }

  //splitterHandler.addEventListener("dblclick", splitterHandlerDblClick);

  function documentMouseup() {

    if (dragStarted === true) {

      $firstElementToResize.find("iframe").css("pointer-events", "auto");
      $secondElementToResize.find("iframe").css("pointer-events", "auto");
      dragStarted = false;

      // Emitujemy event ze zmianą, a apka sobie zapisze już co chce
      /*$scope.wtf.emitSplitChanged({
          height : $elementToResize.height()
      });*/

      $firstElementToResize = null;
      $secondElementToResize = null;

    }

  }

  document.addEventListener("mouseup", documentMouseup);

  function documentMousemove(evt) {

    if (dragStarted === true) {

      // 1. Ustawiamy wysokość pierwszego
      // Jest to wysokość kontenera - pozycja kursora względem tego kontenera
      var upHeight = (evt.y - $firstElementToResize.offset().top);
      var upHeightPx = upHeight + "px";
      var downHeight = (($element.offset().top + $element.outerHeight()) - evt.y);
      var downHeightPx = downHeight + "px";

      // Zabezpieczenie przed zjechaniem niżej niż to możliwe
      if (downHeight > $scope.vmp.minDownHeight && upHeight > $scope.vmp.minUpHeight) {

        $firstElementToResize.height(upHeightPx);
        $firstElementToResize.css("max-height", upHeightPx);
        // 2. Ustawiamy wysokośc drugiego
        $secondElementToResize.height(downHeight);
        $secondElementToResize.css("max-height", downHeightPx);

      }

    }

  }

  document.addEventListener("mousemove", documentMousemove);

  // Jeżeli element nie był widoczny i nie ma jeszcze obliczonej wysokości
  // To trzeba ją obliczyć
  $scope.$watch("vmp.displayChanged", function (newValue, oldValue) {

    if (oldValue == false && newValue === true) {

      $scope.vmp.recalculateLastAreaHeight();
      $scope.vmp.displayChanged = false;

    }

  });

  /*$scope.$on("$destroy", function (event) {

      splitter.removeEventListener("mousedown", splitterMousedown);
      splitter.removeEventListener("dblclick", splitterDblClick);
      splitterHandler.removeEventListener("dblclick", splitterHandlerDblClick);
      document.removeEventListener("mouseup", documentMouseup );
      document.removeEventListener("mousemove", documentMousemove);
  });*/


  $scope.vmp.createSplitter = createSplitter;

  /**
   * Creates new splitter with assigned event handlers
   * @returns {Element}
   */
  function createSplitter() {
    var splitter = document.createElement("div");
    var splitterHandler = document.createElement("div");
    var splitterHandlerIco = document.createElement("i");
    splitter.classList.add("ax-splitter-horizontal");
    splitterHandler.classList.add("ax-splitter-horizontal-handler");
    splitterHandlerIco.classList.add("fa");
    splitterHandlerIco.classList.add("fa-ellipsis-h");
    splitterHandler.appendChild(splitterHandlerIco);
    splitter.appendChild(splitterHandler);

    splitter.addEventListener("mousedown", splitterMousedown);

    return splitter;
  }

}

function recalculateLastAreaHeight($element, areas) {
  if (areas.length > 0) {
    var lastArea = $(areas[areas.length - 1]);
    var lastAreaHeight = ($element.outerHeight() - lastArea.position().top) + "px";
    lastArea.height(lastAreaHeight);
    lastArea.css("max-height", lastAreaHeight);
  }
}

export { AxSplitterArea, AxSplitterPane };
