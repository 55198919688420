export function SettingsData() {
  var attrs = {};

  return {
    get: get,
    set: set
  }

  function get(key) {
    return attrs[key];
  }

  function set(key, value) {
    attrs[key] = value;
  }
}
