import template from './AxSurveyFieldCheckBox.html';

function AxSurveyFieldCheckBox() {
  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  vm.splitStringValuesSeparator = ", ";
  vm.axC.setValue = function (val) {
    if (vm.field.Values) {
      for (var i = 0; i < vm.field.Values.length; i++) {
        if (val) {
          if (val.indexOf(vm.field.Values[i].Second) > -1)
            vm.field.Values[i].selected = true;
        }
        else {
          vm.field.Values[i].selected = false;
        }
      }
    }
    vm.axC.Value = val;
    return true;
  };
  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      if (!val) {
        return "fieldIsRequired";
      }
    }
    return null;
  };
  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    if (field.Field.Values != null) {
      var value = "";
      for (var i = 0; i < field.Field.Values.length; i++) {
        if (field.Field.Values[i].selected == true)
          value += field.Field.Values[i].Second + vm.splitStringValuesSeparator;
      }
      vm.axC.Value = value;
    }
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);

  vm.labelStyle = vm.axControlLabelStyle(vm.field);
  vm.itemLabelStyle = vm.axControlLabelStyle(vm.field);
  vm.itemLabelStyle.minHeight = "20px";
  vm.itemLabelStyle.display = "inline-block";

}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxSurveyFieldCheckBox };
