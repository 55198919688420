import template from './AxPreviewRecordList.html';

//var hInitialValue = 31;
var hInitialValue = 26;
var h = [hInitialValue];

function AxScroll() {
  return function (scope, element, attrs) {
    angular.element(document.querySelector('.preview-records-list-container-inner')).bind("scroll", function () {

      if (document.querySelector('.preview-records-list')) {
        // Poziome
        var tb = document.querySelector('.preview-records-list');
        var header = tb.getElementsByTagName('thead')[0];
        var headerRow = tb.rows[0];
        var headerRowCells = headerRow.cells;

        header.style.left = -this.scrollLeft + 125 + 'px';

        // Pierwszy cell nagłówka musimy utrzymywać na dotychczasowej pozycji
        headerRowCells[0].style.left = -125 + this.scrollLeft + 'px';
        // Drugi też
        headerRowCells[1].style.left = -30 + this.scrollLeft + 'px';
        // Pionowe
        var l = tb.rows.length, yToSet;
        for (var i = 1; i < l; i++) {
          yToSet = -this.scrollTop + h[i - 1] + 'px';
          tb.rows[i].cells[0].style.top = yToSet;
          tb.rows[i].cells[1].style.top = yToSet;
        }

        scope.$apply();
      }
    });
  };
}

/**
 * Main AxPreviewRecordList directive DOD (Directive Object Declaration)
 */
function AxPreviewRecordList(AxComponentsHelperService, $rootScope, $sce, $timeout) {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      records: '=axRecords',
      columns: '=axColumns',
      campaigns: '=axCampaigns',
      selectedRecord: '=axSelectedRecord',
      tabVisible: '=axTabVisible',
      dialingDisabled: '=axDialingDisabled'
    },
    require: "^axPreview",
    // Link definition mus be herem becouse we need access to $timeout :)
    link: function ($scope, $element, $attrs, $ctrl) {

      $scope.vm.axPreviewCtrl = $ctrl;

      $scope.$watch('vm.tabVisible', function (newVal, oldVal) {

        $timeout(function () {
          if (newVal === true && $scope.vm.records.length > 0) {
            $scope.vm.fixTable();
          }
        });

      });

    },
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['AxComponentsHelperService', '$rootScope', '$scope', '$timeout', '$sce', controller]
  }
}

function controller(AxComponentsHelperService, $rootScope, $scope, $timeout, $sce) {

  var vm = this;

  vm.data = {
    gridVisible: true,
    recordsGridSort: { reverse: false, orderBy: "TCO" }
  };

  var staticColumns = ["TCO", "Id", "RecordState", "ShiftTime", "ShiftTimeReason", "Tries", "BillingTries", "LastTryTime", "Expectation", "CampaignId"];
  Object.defineProperty(vm.data.recordsGridSort, "predicate", {
    get: function () {

      var ret = "";
      if (vm.data.recordsGridSort.reverse) {
        ret += "-";
      }
      if (vm.data.recordsGridSort.orderBy === "TCO") {
        ret += "record.ShiftTimeUserAccountId";
      } else if (staticColumns.indexOf(vm.data.recordsGridSort.orderBy) !== -1) {

        ret += "record." + vm.data.recordsGridSort.orderBy
      } else {
        // Jeżeli nie ma w tablicy, to jest to custom kolumna z pól rekordu

        var idx = vm.records[0].record.DataFields.indexOf(vm.data.recordsGridSort.orderBy);
        ret += "record.DataFields[" + idx + "]" + ".Value";
      }

      return ret;

    }
  });

  vm.recordsGridSorting = function (pred) {
    if (vm.records) {
      vm.data.recordsGridSort.orderBy = pred;
      vm.data.recordsGridSort.reverse = !vm.data.recordsGridSort.reverse;
    }
  };

  /**
   * Returs if grid is sorted by specified column and order
   */
  vm.isSortedBy = function (pred, reverse) {

    return (vm.data.recordsGridSort.orderBy === pred) && (vm.data.recordsGridSort.reverse === reverse);

  }

  Object.defineProperty(vm.data, "columns", {
    get: function () {

      var rec;
      var ret = [];
      if (vm.records.length > 0) {
        rec = vm.records[0].record;
        ret = rec.DataFields.filter(function (field) {
          return field.DisplayAsColumn;
        });
      }

      return ret;

    }
  });

  /**
   * Fix after records collecion change
   */
  $scope.$watchCollection('vm.records', function (newVal, oldVal) {

    $timeout(function () {

      if (newVal.length > 0) {
        //vm.data.gridVisible = true;
        vm.fixTable();
      } else {
        //vm.data.gridVisible = false;
      }
    }, true);

  });

  /**
   * Fix after sorting
   */
  $scope.$watch('vm.data.recordsGridSort.reverse', function (newVal, oldVal) {
    $timeout(function () {
      if (vm.records.length > 0) {
        vm.fixTable();
      }
    });

  });

  vm.startPreviewSession = function (campaignId, recordId) {

    vm.axPreviewCtrl.startPreviewSession(campaignId, recordId);

  }

  vm.shiftTimeReason = function (reason) {

    if (reason !== null) {
      return vm.li18n.axDataContactShiftReason[reason];
    }
    return "";

  };

  vm.recordCampaignName = function (campaignId) {

    var l = vm.campaigns.length, campaign;
    for (var i = 0; i < l; i++) {
      campaign = vm.campaigns[i];
      if (campaign.CampaignId === campaignId) {
        return campaign.CampaignName;
      }
    }
    return "";
  }

  /**
   *
   * @param {Number} fieldId
   * @param {ax.SessionLib.AxPreviewRecordItem} record
   * @returns {String}
   */
  vm.getFieldValue = function (fieldId, record) {

    var l = record.record.DataFields.length, field;

    for (var i = 0; i < l; i++) {
      field = record.record.DataFields[i];

      if (field.Id === fieldId) {
        return field.Value;
      }
    }

    return "";

  }

  vm.formatTime = function (t) {

    if (t !== null) {
      var dt = new Date(t - 62135604000000);

      return dt.getFullYear() + '-' + AxComponentsHelperService.zeroPad(dt.getMonth() + 1) + '-' + AxComponentsHelperService.zeroPad(dt.getDate()) + ' ' + AxComponentsHelperService.zeroPad(dt.getHours()) + ':' + AxComponentsHelperService.zeroPad(dt.getMinutes()) + ':' + AxComponentsHelperService.zeroPad(dt.getSeconds());
    } else {
      return "";
    }
  };


  vm.fixTable = function () {
    if (document.querySelector('.preview-records-list-container-inner')) {

      var lrPadding = 16;

      var inner = document.querySelector('.preview-records-list-container-inner');

      // Fix na szerokość kolumn nagłówka i kolumn body
      // Bierzemy pierwszy wiersz tabeli pod nagłówkiem i na jego postawie ustawiamy szerokość kolumn nagłówka
      var tb = document.querySelector('.preview-records-list');

      var headerRow = tb.rows[0];
      var firstRow = tb.rows[1];

      var firstRowCells = firstRow.cells;
      var headerRowCells = headerRow.cells;

      var l = firstRowCells.length, cell, headerCellWidth, firstRowCellWidth, innerCellDiv, remW = 0,
        headerCell,
        innerHeaderDiv;
      /* Zaczynmy od 2, bo zerowa i pierwsza kolumna są zafixowane i nas nie interesują */
      for (var i = 2; i < l; i++) {
        headerCell = headerRowCells[i];
        innerHeaderDiv = headerCell.getElementsByTagName('div')[0];
        cell = firstRowCells[i];
        innerCellDiv = cell.getElementsByTagName('div')[0];

        headerCellWidth = innerHeaderDiv.offsetWidth;
        firstRowCellWidth = innerCellDiv.offsetWidth;

        // TODO: Poprawić tę rzeźbę, żeby to jakoś po ludzku było zrobione
        /* Może być tak, że kolumna nagłówkowa, jest szersza, od reszty, wtedy, ustawiamy szerokość reszty na szerokość kolumny nagłówka */
        if (i === l - 1) {
          // Jeżeli to ostatnia koluma, to rozciągmy ją do końca
          // Ale tylko, jeżeli całkowita długość tabelki jest mniejsza niż inner
          var w, what;
          if (headerCellWidth > firstRowCellWidth) {
            w = headerCellWidth - 1 - lrPadding;
            what = "r";
          } else if (headerCellWidth < firstRowCellWidth) {
            w = firstRowCellWidth - lrPadding;
            what = "h";
          }

          if ((remW + w) < inner.clientWidth) {
            innerCellDiv.style.width = inner.clientWidth - remW + 4 - lrPadding + 'px';
            innerHeaderDiv.style.width = inner.clientWidth - remW + 4 - lrPadding + 'px';
          } else {
            if (what === "h") {
              var hToSet = (w - lrPadding) + 'px';
              innerHeaderDiv.style.width = w + 'px';

            }
            if (what === "r") {
              innerCellDiv.style.width = w + 'px';
            }
          }


        } else {
          if (headerCellWidth > firstRowCellWidth) {

            /* Tutaj cell musimy rozszerzyć przez rozszerzenie diva, który jest w środku, ponieważ tabela jest auto i komórki dopasowują się do zawartości */
            if (i === 1) { // Chuj wie czemu, ale w pierwszym musi być 1 pixel mniej
              innerCellDiv.style.width = headerCellWidth - 1 - lrPadding + 'px';
            } else {
              innerCellDiv.style.width = headerCellWidth - lrPadding + 'px';
            }
            remW += headerCellWidth + 2;

          } else if (headerCellWidth < firstRowCellWidth) {
            innerHeaderDiv.style.width = firstRowCellWidth - lrPadding + 'px';
            remW += firstRowCellWidth + 2;
          } else {
            remW += firstRowCellWidth + 2;
          }
        }

      }

      h = [hInitialValue];
      // Fix first and second col height
      var tableRows = tb.rows;
      var l = tableRows.length, cell0, cell1, heightToSet;
      var innerContainer = document.querySelector('.preview-records-list-container-inner')
      /* Od 1, bo 0 wiersz to nagłówek */
      for (var i = 1; i < l; i++) {
        cell0 = tableRows[i].cells[0];
        cell1 = tableRows[i].cells[1];
        heightToSet = tableRows[i].cells[2].getElementsByTagName('div')[0].offsetHeight;
        cell0.style.height = heightToSet + 'px';
        cell1.style.height = heightToSet + 'px';
        h.push(h[i - 1] + heightToSet + 1);

        // Ustawiamy wysokość, bo np po pobraniu nowych rekordów mogła się zmienić
        var hToSet = -innerContainer.scrollTop + h[i - 1] + 'px';
        cell0.style.top = hToSet;
        cell1.style.top = hToSet;
      }
    }

  }

}

export { AxPreviewRecordList, AxScroll };
