import * as React from 'react';
import { CompressIcon, ExpandIcon } from '../components/icons';
import { MediaControlProps } from '../models';

export const FullScreenButton = ({ media }: { media: MediaControlProps | any }) => {
  const { isFullScreen, goFullScreen, unFullScreen } = media;
  const onClick = isFullScreen ? unFullScreen : goFullScreen;
  return (
    <button onClick={onClick}>
      {isFullScreen ? (
        <CompressIcon/>
      ) : (
        <ExpandIcon/>
      )}
    </button>
  );
};
