import template from './AxAcceptSessionList.html';

export function AcceptSessionList() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      sessions: '=axSessions',
      itemClass: '=axItemClass',
      userId: '=axUserId'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller
  }

}

/** @ngInject */
function controller($scope) {

  var vm = this;

  vm.isStateAcceptable = function (state) {

    var axState = ax.SessionLib.AxSessionState;
    return (state === axState.Queued || state === axState.DirectedToUser)

  };

  vm.isInteractionAcceptable = function (inter) {

    return vm.isStateAcceptable(inter.session.Session.SessionState) && findAcceptAction(inter) !== null;
  };

  vm.rejectSession = function (inter) {

    $scope.$emit('rejectInteractionClick', {
      item: inter
    });

  };


}

function link($scope, $element) {

  var vm = $scope.vm;

  // Show hide hungrup interactions
  $scope.$watchCollection('vm.sessions', function (newValue) {

    if (newValue !== null && newValue !== undefined) {

      var filtered = newValue.filter(function (inter) {

        return $scope.vm.isInteractionAcceptable(inter);

      });

      if (filtered.length > 0) {
        $element.show();
      } else {
        $element.hide();
      }

    } else {
      $element.hide();
    }

  });

}

/**
 * @param {InteractionItem} inter
 * @returns {?ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession}
 */
function findAcceptAction(inter) {

  var actions = inter.session.Actions;
  if (actions === null) {
    return null;
  }
  var retActions = actions.filter(function (action) {

    return action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession;

  });

  if (retActions.length > 0) {
    return retActions[0];
  } else {
    return null;
  }
}
