function VideoData() {
  return {
    localVideoSrc: "",
    remoteVideoSrc: "",
    fakeIncomingCall: {
      state: 0,
      id: ""
    }
  }
}

export { VideoData }
