import template from './AxSurveyFieldNumber.html';

function AxSurveyFieldNumber() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  vm.repeatSpeed = 250;
  vm.keyDownNumbersOnly = helpers.keyDownNumbersOnly;
  vm.axC.setValue = function (val) {
    if (val || val == 0) {
      var intValue = parseInt(val);
      if (isNaN(intValue)) {
        intValue = 0;
      }
      if (vm.field.MaxValue) {
        intValue = Math.min(intValue, parseInt(vm.field.MaxValue));
      }
      if (vm.field.MinValue) {
        intValue = Math.max(intValue, parseInt(vm.field.MinValue));
      }
      vm.axC.Value = intValue.toString();
    }
    else {
      vm.axC.Value = null;
    }
    return true;
  };

  vm.axC.Validate = function (val) {

    var newValue;

    if (vm.field.Mandatory === true) {
      if (!val && val !== 0) {
        return "fieldIsRequired";
      }
    }

    // Nie sprawdzamy dalej, jeżeli pole nie jest wypełnione
    if (val === null || typeof val === "undefined") {
      return false;
    }

    if (val.length === 0) {
      return false;
    }

    newValue = Number(val);

    if (vm.field.MaxValue && (newValue > Number(vm.field.MaxValue))) {

      return [vm.li18n.messages.value.charAt(0).toUpperCase() + vm.li18n.messages.value.slice(1), newValue, vm.li18n.messages.isGreaterThan, vm.li18n.messages.maximum, vm.field.MaxValue].join(" ");

    }

    if (vm.field.MinValue && (newValue < Number(vm.field.MinValue))) {

      return [vm.li18n.messages.value.charAt(0).toUpperCase() + vm.li18n.messages.value.slice(1), newValue, vm.li18n.messages.isLowerThan, vm.li18n.messages.minimum, vm.field.MinValue].join(" ");

    }

    return null;
  };

  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };
  vm.axC.upDown = function (up) {
    var intValue = 0;
    var value = vm.axC.getValue();
    if (value) {
      intValue = parseInt(value);
    }
    if (up === true) {
      vm.axC.setValue(intValue + parseInt(vm.field.IncrementStep));
    }
    if (up === false) {
      vm.axC.setValue(intValue - parseInt(vm.field.IncrementStep));
    }
  };
  if (vm.axC.Value || vm.axC.Value == 0)
    vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.acceptedRange = formatAcceptedRange($scope.vm.field.MinValue, $scope.vm.field.MaxValue, $scope.vm.li18n);

  $scope.$watch('vm.li18n', function (newVal) {
    $scope.vm.acceptedRange = formatAcceptedRange($scope.vm.field.MinValue, $scope.vm.field.MaxValue, newVal)
  });

  function formatAcceptedRange(min, max, li18n) {
    var acceptedRange = [li18n.messages.acceptedRange + ":"];
    var lFrom = li18n.messages.from.toLowerCase();
    var lTo = li18n.messages.to.toLowerCase();

    if (!min && !max) {
      acceptedRange.push(li18n.messages.anyTimeRange);
    }
    if (min && !max) {
      acceptedRange = acceptedRange.concat([lFrom, min, lTo, li18n.messages.inifinity1]);
    } else if (!min && max) {
      acceptedRange = acceptedRange.concat([lFrom, li18n.messages.inifinity1, lTo, max]);
    } else if (min && max) {
      acceptedRange = acceptedRange.concat([lFrom, min, lTo, max]);
    }

    return acceptedRange.join(" ");
  };
}

export { AxSurveyFieldNumber };
