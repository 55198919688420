export function Storage() {
  var prefix = 'ax';

  this.setPrefix = setPrefix;
  this.set = set;
  this.get = get;

  function setPrefix(newPrefix) {
    prefix = newPrefix;
  }

  function set(key, value) {
    var keyWithPrefix = prefix + '::' + key;
    var stringifiedValue = JSON.stringify(value);

    localStorage.setItem(keyWithPrefix, stringifiedValue);
  }

  function get(key) {
    return JSON.parse(localStorage.getItem(prefix + '::' + key));
  }

}
