import template from './AxComboBox.html';

function AxComboBox() {
  return {
    restrict: 'AEC',
    require: 'ngModel',
    scope: {
      li18n: '=axI18n',
      name: '=axName',
      editValue: '=ngModel',
      allowNullValues: '=axAllowNullValues',
      axAllowSearch: '=axAllowSearch',
      axAllowEdit: '=axAllowEdit',
      isValid: '=axIsValid',
      invalidMessage: '=axInvalidMessage',
      isReadonly: '=ngReadonly',
      dblclick: '&axDblclick',
      items: '=axItems',
      placeholder: '=axPlaceholder',
      dispalayMember: '@axDispalayMember',
      valueMember: '@axValueMember',
      itemTemplate: '=axItemTemplate',
      temp: '=axTemp'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {
  var vm = this;
  vm.helpers = helpers;
  vm.axAllowEdit = vm.axAllowEdit === 'true';
  vm.axAllowSearch = vm.axAllowSearch === 'true';
  vm.isDropdownOpen = false;
  vm.dispalayEditValue = null;
  vm.list = vm.items;

  vm.setValue = function (val) {
    if (typeof val !== "undefined" && val !== null && vm.items) {
      for (var i = 0; i < vm.items.length; i++) {
        var selected = false;
        var item = vm.items[i];
        if ((vm.valueMember ? item[vm.valueMember] : item) == val) {
          vm.editValue = val;
          vm.dispalayEditValue = vm.itemDisplay(item);
          selected = true;
          break;
        }
      }
      if (selected === false) {
        vm.editValue = null;
        vm.dispalayEditValue = null;
      }

      if (vm.axAllowEdit && val) {
        vm.editValue = val;
        vm.dispalayEditValue = vm.itemDisplay({ First: val, Second: val });
      }
    } else {
      vm.inputValue = "";
      vm.editValue = null;
      vm.dispalayEditValue = null;
    }

    return true;
  };

  vm.itemDisplay = function (item) {
    if (vm.itemTemplate) {
      return vm.itemTemplate(item);
    }
    if (vm.dispalayMember) {
      if (vm.dispalayMember in item) {
        return item[vm.dispalayMember].toString();
      } else {
        return "";
      }
    }
    return item;
  };

  /**
   * Filtruje itemki dla okienka wyszukiwania
   */
  vm.filterItems = function () {

    // Nie filtrujemy jeżeli nie mamy nic wpisanego w inpucie
    if (!vm.inputValue || vm.inputValue.length === 0 || vm.inputValue === '') {
      vm.list = vm.items;
      return;
    }

    vm.list = vm.items.filter(function (val) {

      if (!val.First) {
        return false;
      }

      if (val.First.substring) {
        if (val.First.toLowerCase().indexOf(vm.inputValue.toLowerCase()) !== -1) {
          return true;
        }
      } else {
        if (val.First.indexOf(vm.inputValue) !== -1) {
          return true;
        }
      }

      return false;
    });

  };

  vm.openSearch = function () {
    vm.dropdownShow();
    if (vm.axAllowSearch) {
      vm.isActive = true;

      setTimeout(function () {
        angular.element("input.input-field").focus().select();
      }, 0)
    }
  };

  vm.toggleDropdown = function (event) {
    if (vm.isDropdownOpen === true) {
      vm.dropdownHide(event);
    } else {
      vm.dropdownShow(event);
    }

  };

  vm.dropdownShow = function () {

    vm.isDropdownOpen = true;

    if (vm.isReadonly !== true) {

      if (vm.axAllowEdit) {
        vm.inputValue = "";
      }

      vm.isActive = false;
      vm.filterItems();
    }
  };

  vm.dropdownHide = function () {

    if (vm.axAllowEdit && typeof vm.inputValue === "string" && vm.inputValue.length !== 0) {
      vm.setValue(vm.inputValue);
    }

    if (!vm.axAllowEdit) {
      vm.inputValue = "";
    }

    vm.filterItems();
    vm.isActive = false;
    vm.isDropdownOpen = false;
  };


  vm.dropdownSelectItem = function (item) {

    var valToSet = vm.valueMember ? item[vm.valueMember] : item;
    vm.setValue(valToSet);
    vm.inputValue = "";
    vm.filterItems();
    vm.isDropdownOpen = false;
    vm.isActive = false;

  };

  vm.stopProp = function ($event) {
    $event.stopPropagation();
  };

  if (vm.editValue) {
    vm.setValue(vm.editValue);
  }
}

function link($scope) {

  /*angular.element('.survey-form-fields').on('mousewheel', function (e) {
      $scope.vm.dropdown.hide();
      $scope.vm.inputValue = '';
      $scope.vm.filterItems();
      $scope.vm.isActive = false;
  });*/

  $(document).on("mousedown", $scope.vm.dropdownHide);
  $(window).on('resize', $scope.vm.dropdownHide);

  $scope.$watch("vm.editValue", function () {
    $scope.vm.setValue($scope.vm.editValue);
  });

  $scope.$on("$destroy", function () {
    $(document).off("mousedown", $scope.vm.dropdownHide);
    $(window).off('resize', $scope.vm.dropdownHide);
  });
}

export { AxComboBox };
