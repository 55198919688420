import template from './AxTextEdit.html';

function AxTextEdit() {

  return {
    restrict: 'AEC',
    require: 'ngModel',
    scope: {
      li18n: '=axI18n',
      name: '=axName',
      editValue: '=ngModel',
      allowNullValues: '=axAllowNullValues',
      isValid: '=axIsValid',
      invalidMessage: '=axInvalidMessage',
      isReadonly: '=ngReadonly',
      emptyValue: '=axEmptyValue',
      dblclick: '&axDblclick',
      regexMask: '=axRegexMask',
      maxLength: '=axMaxLength'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {
  var vm = this;
  vm.helpers = helpers;
  vm.maxLength = vm.maxLength || 2147483647;
  vm.checkRegexp = function () {
    var patt;

    if (vm.regexMask && vm.editValue.length >= 0) {
      patt = new RegExp(vm.regexMask);
      if (patt.test(vm.editValue)) {
        vm.isValid = true;
        vm.invalidMessage = "";
      } else {
        vm.isValid = false;
        console.log("RRRRRRRRRRRRR", vm.li18n.messages);
        vm.invalidMessage = vm.li18n.messages.invalidFormat;
        if (vm.editValue.length > 0 && vm.correctValue) {
          vm.isValid = true;
        }
      }
    } else {
      vm.validFormat = true;
      vm.isValid = true;
    }
  };
  vm.clickdblclick = function () {
    if (vm.dblclick) {
      vm.dblclick();
    }
    /*vm.input.blur();
    setTimeout(function () {
        vm.input.focus();
    }, 1);*/
  };

  vm.clear = function () {
    if (vm.isReadonly !== true) {
      vm.editValue = typeof vm.emptyValue !== "undefined" ? vm.emptyValue : null;
    }
    var z;
  }
}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxTextEdit };
