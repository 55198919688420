import { Reply } from '@ufkit/hash-hint';

/** @ngInject */
export function ChatReply(reactDirective) {
  return reactDirective(Reply, [
    ['disabled', {
      watchDepth: 'reference'
    }],
    ['focus'],
    ['hashHintData', {
      watchDepth: 'reference'
    }],
    ['placeholder', {
      watchDepth: 'reference'
    }],
    ['value', {
      watchDepth: 'reference'
    }],
    'onValueChange',
    'onKeyDown'
  ]);
}
