import template from './AxSurveysFieldWebBrowser.html';

function AxSurveysFieldWebBrowser() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl' // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);

  if (vm.field.HtmlContent != null && vm.field.HtmlContent != "")
    vm.field.Url = null;

  if (vm.field.Url != null && vm.field.Url != "" && vm.field.Url.indexOf("http") != 0)
    vm.field.Url = "http://" + vm.field.Url;

}

function link($scope, $element, $attrs, $ctrl) {
  if ($scope.vm.field.HtmlContent != null && $scope.vm.field.HtmlContent != "")
    $element.find("iframe").on('load', function () {
      this.contentWindow.document.write($scope.vm.field.HtmlContent);
    });
}

export { AxSurveysFieldWebBrowser };
