import angular from 'angular';
import { AxComponentsHelperService } from './ax.components';
import { AcceptSessionList } from './ax-accept-session-list/axAcceptSessionList';
import { AcceptableSessionItem } from './ax-acceptable-session-item/axAcceptableSessionItem';
import { ChooseCustomer } from './ax-choose-customer/axChooseCustomer';
import { AxContentTab } from './ax-content-tab/axContentTab';
import { AxDraggable } from './axDraggable';
import { AxDroppable } from './axDroppable';
import { AxIsEllipsis } from './axIsEllipsis';
import { AxLoader } from './ax-loader/axLoader';
import { AxOverlay } from './axOverlay';
import { AxRecordingButton } from './ax-recording-button/axRecordingButton';
import { AxRefreshableFrame } from './axRefreshableFrame'
import { AxSessionChat } from './ax-session-chat/axSessionChat';
import { AxSessionChatClientInfo } from './ax-session-chat-client-info/axSessionChatClientInfo';
import { AxSessionChatMessage } from './ax-session-chat/axSessionChatMessage';
import { AxSplitHorizontal } from './axSplitHorizontal';
import { AxSplitterArea, AxSplitterPane } from './axSplitter';
import { AxSplitVertical } from './axSplitVertical';
import { AxSrcdoc } from './axSrcDoc';
import { AxTippy } from './axTippy';
import { AxPopup } from "./popup";
import { AxPhoneNumber } from "./axPhoneNumber";
import { AxRefreshCrmFrame } from "./axRefreshCrmFrame";

export default angular.module('ax.components', [])
  .service('AxComponentsHelperService', AxComponentsHelperService)
  .directive('axAcceptSessionList', AcceptSessionList)
  .directive('axAcceptableSessionItem', AcceptableSessionItem)
  .directive('axChooseCustomer', ChooseCustomer)
  .directive('axContentTab', AxContentTab)
  .directive('axLoader', AxLoader)
  .directive('axRecordingButton', AxRecordingButton)
  .directive('axSessionChat', AxSessionChat)
  .directive('axSessionChatClientInfo', AxSessionChatClientInfo)
  .directive('axDraggable', AxDraggable)
  .directive('axDroppable', AxDroppable)
  .directive("axIsEllipsis", AxIsEllipsis)
  .directive('axOverlay', AxOverlay)
  .directive('axRefreshableFrame', AxRefreshableFrame)
  .directive('axSplitHorizontal', AxSplitHorizontal)
  .directive("axSplitterArea", AxSplitterArea)
  .directive("axSplitterPane", AxSplitterPane)
  .directive('axSplitVertical', AxSplitVertical)
  .directive("axSrcdoc", AxSrcdoc)
  .directive("axTippy", AxTippy)
  .directive("axPopup", AxPopup)
  .directive("axSessionChatMessage", AxSessionChatMessage)
  .directive("axPhoneNumber", AxPhoneNumber)
  .directive("axRefreshCrmFrame", AxRefreshCrmFrame)
  .name;
