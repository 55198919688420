import template from './AxSurveysMaskedTextValue.html';

function AxSurveysMaskedTextValue() {
  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);

  vm.axC.Validate = function (val) {
    var maskComplete = false;
    if (val && (!vm.field.Mask || vm.field.Mask.length == val.length)) {
      maskComplete = true;
    }
    if (vm.field.Mandatory === true && !val) {
      return "fieldIsRequired";
    }
    if (vm.field.Mandatory === true && val && maskComplete !== true) {
      return "invalidFormat";
    }
    if ((vm.field.Mandatory === true || val) && maskComplete !== true) {
      return "invalidFormat";
    }
    if (val) {
      switch (vm.field.ValidationKey) {
        case "AxSurveysMaskedTextValue_REGON":
          if (helpers.validateRegon(val) === false) {
            return vm.field.ValidationScriptMessage || "validationError";
          }
          break;
        case "AxSurveysMaskedTextValue_IBAN":
          if (helpers.validateIban(val) === false) {
            return vm.field.ValidationScriptMessage || "validationError";
          }
          break;
        case "AxSurveysMaskedTextValue_PESEL":
          if (helpers.validatePesel(val) === false) {
            return vm.field.ValidationScriptMessage || "validationError";
          }
          break;
        case "AxSurveysMaskedTextValue_TaxIdNumber":
          if (helpers.validateNip(val) === false) {
            return vm.field.ValidationScriptMessage || "validationError";
          }
          break;
      }
    }
    return null;
  };
  vm.onValueSet = function () {
    vm.AnswerDateTime = Date.now();
    vm.validate();
    if (vm.axC.HasTrigger === true && (!vm.axC.getValue() || (!vm.field.Mask || vm.field.Mask.length == vm.axC.getValue().length))) {
      vm.sendSetValueAction(vm);
    }
  };
  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.input = $element.find("input[data-input-type=textMask]");
  $scope.vm.input.mask($scope.vm.field.Mask || "",
    {
      placeholder: $scope.vm.field.Mask ? $scope.vm.field.Mask.replace(/[09#L?&CAaS]/g, "_") : ""
    });
  setTimeout(function () {
    $scope.vm.input.keydown().keyup();
  }, 100);
}

export { AxSurveysMaskedTextValue };
