import template from './AxDateTimePicker.html';

function AxDateTimePicker() {

  return {
    restrict: 'AEC',
    require: 'ngModel',
    scope: {
      li18n: '=axI18n',
      name: '=axName',
      editValue: '=ngModel',
      allowNullValues: '=axAllowNullValues',
      isValid: '=axIsValid',
      invalidMessage: '=axInvalidMessage',
      isReadonly: '=ngReadonly',
      dblclick: '&axDblclick',
      maxValue: '=axMaxValue',
      minValue: '=axMinValue'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['DateTimeHelpers', controller]
  }
}

function controller(dth) {
  var vm = this;
  vm.dth = dth;
  vm.dateTimeFormat = "YYYY-MM-DD HH:mm";
  vm.dateFormat = "YYYY-MM-DD";
  vm.timeFormat = "HH:mm";
  vm.dateTimeMask = "0000-00-00 00:00";
  vm.dateMask = "0000-00-00";
  vm.timeMask = "00:00";
  vm.editTimeValue = null;
  vm.editDateValue = null;

  vm.clickdblclickTime = function () {
    if (vm.dblclick) {
      vm.dblclick();
    }
    vm.inputTime.blur();
    setTimeout(function () {
      vm.inputTime.focus();
    }, 1);
  };
  vm.clickdblclickDate = function () {
    if (vm.dblclick) {
      vm.dblclick();
    }
    vm.input.blur();
    setTimeout(function () {
      vm.input.focus();
    }, 1);
  };
  vm.clearValue = function () {
    if (vm.isReadonly !== true) {
      vm.editTimeValue = null;
      vm.editDateValue = null;
      vm.editValue = null;
    }
    return false;
  };

  vm.setValue = function (val) {
    if (val) {

      vm.editValue = val;

    } else {
      vm.editTimeValue = null;
      vm.editDateValue = null;
      vm.editValue = null;
      return true;
    }

  };

  if (vm.editValue) {
    vm.setValue(vm.editValue);
  }
}

function link($scope, $element) {

  var vm = $scope.vm;
  $scope.vm.input = $element.find("input[data-input-type=date]");

  $scope.vm.dateImg = $element.find(".cal-png");
  var value = $scope.vm.editValue;

  if (value && vm.dth.checkDateTimeFormatRegexp(value) && vm.dth.checkDateTimeFormatRegexp(value)) {
    if (vm.dth.checkDateInTime(value)) {
      vm.editDateValue = vm.dth.getDatePart(value);
    }

    if (vm.dth.checkTimeInDate(value)) {
      vm.editTimeValue = vm.dth.getTimePart(value);
    }
  }

  var p = {
    field: $scope.vm.input[0],
    trigger: $scope.vm.dateImg[0],
    format: $scope.vm.dateFormat,
    disableDayFn: function (dt) {
      var maxDate = $scope.vm.maxValue ? moment($scope.vm.maxValue).toDate() : null;
      var minDate = $scope.vm.minValue ? moment($scope.vm.minValue).toDate() : null;
      var maxDateZero = maxDate ? new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate()) : null;
      var minDateZero = minDate ? new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()) : null;
      if (maxDateZero && maxDateZero.getTime() < dt.getTime()) {
        return true;
      }
      if (minDateZero && minDateZero.getTime() > dt.getTime()) {
        return true;
      }
      return false;
    }
  };
  if ($scope.vm.li18n) {
    p.i18n = $scope.vm.li18n.pikaday;
  }
  $scope.vm.pickday = new Pikaday(p);

  $scope.vm.input.inputmask("yyyy-mm-dd");

  $scope.vm.inputTime = $element.find("input[data-input-type=time]");

  $scope.vm.timeImg = $element.find(".clock-png");
  $scope.vm.inputTime.clockpicker({
    trigger: $scope.vm.timeImg[0],
    align: 'right',
    autoclose: true,
    'default': value !== null ? moment(value, $scope.vm.timeFormat) : null,
    afterShow: function (th) {
      $scope.vm.popover = $(th.popover);
      var offset = $scope.vm.inputTime.offset();
      if (offset.top - $scope.vm.popover.outerHeight() < 0) {
        $scope.vm.popover.css("top", offset.top + $scope.vm.inputTime.outerHeight());//bottom
      } else {
        $scope.vm.popover.removeClass("bottom").addClass("top").css("top", offset.top - $scope.vm.popover.outerHeight());//top
      }
    }
  });

  $scope.vm.inputTime.inputmask("hh:mm");

  $scope.vm.clockHide = function (e) {
    var target = $(e.target || e.srcElement);
    if (target.closest($element).length === 0 && target.parents(".pika-single:first").length == 0) {
      $scope.vm.pickday.hide();
    }
  };
  $(document).on("mouseup", $scope.vm.clockHide);
  $(window).on('resize', $scope.vm.clockHide);

  setTimeout(function () {
    $scope.vm.input.keydown().keyup();
    $scope.vm.inputTime.keydown().keyup();
  }, 100);

  $scope.$on("$destroy", function (event) {
    $(document).off("mouseup", $scope.vm.clockHide);
    $(window).off('resize', $scope.vm.clockHide);
    $scope.vm.pickday.destroy();
    $scope.vm.inputTime.clockpicker("remove");
    if ($scope.vm.popover) {
      $scope.vm.popover.remove();
    }
  });

  $scope.$watch("vm.editDateValue", function (newVal, oldVal) {

    var dtToSet,
      dt = convertFromEmptyToDateFormat(newVal, vm.editTimeValue),
      calculatedDt = calculateTime(dt, vm.minValue, vm.maxValue, true, vm.dth);

    if (calculatedDt !== null) {
      dtToSet = calculatedDt;
      vm.editTimeValue = vm.dth.getTimePart(dtToSet);
      vm.editDateValue = vm.dth.getDatePart(dtToSet);
    } else {
      dtToSet = dt;
    }

    vm.setValue(dtToSet);

  });

  $scope.$watch("vm.editTimeValue", function (newVal, oldVal) {

    var dtToSet,
      dt = convertFromEmptyToDateFormat($scope.vm.editDateValue, newVal),
      calculatedDt = calculateTime(dt, vm.minValue, vm.maxValue, false, vm.dth);

    if (calculatedDt !== null) {
      dtToSet = calculatedDt;
      vm.editTimeValue = vm.dth.getTimePart(dtToSet);
      vm.editDateValue = vm.dth.getDatePart(dtToSet);
    } else {
      dtToSet = dt;
    }

    vm.setValue(dtToSet);

  });

  $scope.$watch("vm.editValue", function (newVal, oldVal) {
    vm.setValue($scope.vm.editValue);
  });

}

function isEmpty(val) {
  return val === null || val === "";
}

function isNotEmpty(val) {
  return !isEmpty(val);
}

function convertFromEmptyToDateFormat(date, time) {

  var ret;
  if (isEmpty(date) && isEmpty(time)) {

    ret = null;

  }

  if (isEmpty(date) && isNotEmpty(time)) {

    ret = "XXXX-XX-XX " + time;

  }

  if (isNotEmpty(date) && isEmpty(time)) {
    ret = date + " XX:XX";

  }

  if (isNotEmpty(date) && isNotEmpty(time)) {
    ret = date + " " + time;
  }

  return ret;
}

/**
 * Funkcja spodziewa się otrzymać w argumencie datę i czas w formacie XXXX-XX-XX XX:XX
 * Jeżeli jest w stanie wyliczyć, to zwraca czas w poprawnym formacie
 * z uwzględnieniem min i max
 * Jeżeli format jest inny to walnie exception
 * @param {String} dateTime Data, która ma trafić do pola
 * @param {String} minTime
 * @param {String} maxTime
 * @param {Boolean} forDate - jeżeli true, to przyjmuje, że input pochodzi z pola daty i musi dobrać odpowiedni czas, jeżeli false, to odwrotnie
 * @return {?String}
 */
function calculateTime(dateTime, minTime, maxTime, forDate, dth) {

  if (!dateTime) {
    return null;
  }

  var dateFormat = "YYYY-MM-DD",
    timeFormat = "HH:mm",
    dateTimeFormat = dateFormat + " " + timeFormat,
    dateValue = dth.getDatePart(dateTime),
    timeValue = dth.getTimePart(dateTime),
    selectedDateMoment = moment(dateValue, dateFormat),
    maxTimeMoment,
    minTimeMoment,
    nowMoment,
    setToMoment = null;

  try {

    // Jeżeli sprawdzenie dla daty i data nie jest ustawiona, to nic nie robimy
    if (forDate === true && dth.checkDateInTime(dateTime) === false) {
      return null;
    }

    // Jeżeli sprawdzenie dla czasu i czas nie jest ustawiony, to nic nie robimy
    if (forDate === false && dth.checkTimeInDate(dateTime) === false) {
      return null;
    }

    // Jeżeli czas nie jest ustawiony, to ustawiamy go na wyliczoną wartość domyślną
    if (forDate === true) {
      if (dth.checkTimeInDate(dateTime) === false) {

        nowMoment = moment(selectedDateMoment.format(dateFormat) + " " + moment().format(timeFormat));

      } else {
        // Jeżeli czas już jest to tylko sprawdzamy, czy jest ok
        nowMoment = moment(dateTime);
      }
    } else { // Sprawdzamy dla czasu

      if (dth.checkDateInTime(dateTime) === false) {
        // Jeżeli data nie jest ustawiona, to ustawiamy now
        nowMoment = moment(moment().format(dateFormat) + " " + timeValue);
      } else {
        nowMoment = moment(dateTime);
      }

    }

    // Jeżeli jest ograniczenie minimalnego czasu, to ustawiamy czas na now, lub na min jeżeli min jest większy od now
    if (minTime && !maxTime) {
      minTimeMoment = moment(minTime);

      if (minTimeMoment.isAfter(nowMoment)) {
        setToMoment = minTimeMoment
      } else {
        setToMoment = nowMoment
      }

    }

    if (maxTime && !minTime) {
      maxTimeMoment = moment(maxTime);
      if (maxTimeMoment.isBefore(nowMoment)) {
        setToMoment = maxTimeMoment;
      } else {
        setToMoment = nowMoment;
      }
    }

    // Jeżeli jest ustawienie min i max czasu
    if (minTime && maxTime) {

      minTimeMoment = moment(minTime);
      maxTimeMoment = moment(maxTime);

      setToMoment = nowMoment;

      // Jeżeli jest pomiedzy min i max to ustawiamy na now
      if ((nowMoment.isAfter(minTimeMoment) || nowMoment.isSame(minTimeMoment))
        && (nowMoment.isBefore(maxTimeMoment) || nowMoment.isSame(maxTimeMoment))) {

        setToMoment = nowMoment;

      } else {

        if (nowMoment.isBefore(minTimeMoment)) {

          setToMoment = minTimeMoment;
        }

        if (nowMoment.isAfter(maxTimeMoment)) {
          setToMoment = maxTimeMoment;
        }
      }

    }

    return setToMoment.format(dateTimeFormat);

  } catch (e) {
    return null;
  }
  return null;
}

export { AxDateTimePicker };
