import angular from 'angular';
import {
  disableSound,
  enableSound,
  loadSettingsFailure,
  loadSettingsRequest,
  loadSettingsSuccess,
  saveSettingsFailure,
  saveSettingsRequest,
  saveSettingsSuccess,
  SETTINGS_LOAD_FAILURE,
  SETTINGS_LOAD_REQUEST,
  SETTINGS_LOAD_SUCCESS,
  SETTINGS_SAVE_FAILURE,
  SETTINGS_SAVE_REQUEST,
  SETTINGS_SAVE_SUCCESS,
  SETTINGS_SOUND_DISABLE,
  SETTINGS_SOUND_ENABLE,
  SETTINGS_SOUND_TOGGLE,
  toggleSound
} from "./settingsActions";
import {
  playSoundEnd, playSoundStart,
  SOUND_PLAY_END, SOUND_PLAY_START
} from "./soundActions";

export default angular
  .module('Redux.Actions', [])
  .constant('SETTINGS_SOUND_ENABLE', SETTINGS_SOUND_ENABLE)
  .constant('SETTINGS_SOUND_DISABLE', SETTINGS_SOUND_DISABLE)
  .constant('SETTINGS_SOUND_TOGGLE', SETTINGS_SOUND_TOGGLE)
  .constant('SETTINGS_LOAD_REQUEST', SETTINGS_LOAD_REQUEST)
  .constant('SETTINGS_LOAD_SUCCESS', SETTINGS_LOAD_SUCCESS)
  .constant('SETTINGS_LOAD_FAILURE', SETTINGS_LOAD_FAILURE)
  .constant('SETTINGS_SAVE_REQUEST', SETTINGS_SAVE_REQUEST)
  .constant('SETTINGS_SAVE_SUCCESS', SETTINGS_SAVE_SUCCESS)
  .constant('SETTINGS_SAVE_FAILURE', SETTINGS_SAVE_FAILURE)
  .constant('enableSound', enableSound)
  .constant('disableSound', disableSound)
  .constant('toggleSound', toggleSound)
  .constant('loadSettingsRequest', loadSettingsRequest)
  .constant('loadSettingsSuccess', loadSettingsSuccess)
  .constant('loadSettingsFailure', loadSettingsFailure)
  .constant('saveSettingsRequest', saveSettingsRequest)
  .constant('saveSettingsSuccess', saveSettingsSuccess)
  .constant('saveSettingsFailure', saveSettingsFailure)

  .constant('SOUND_PLAY_START', SOUND_PLAY_START)
  .constant('SOUND_PLAY_END', SOUND_PLAY_END)
  .constant('playSoundStart', playSoundStart)
  .constant('playSoundEnd', playSoundEnd)

  .name;
