import template from './AxFilterBuilderItem.html';

function AxFilterBuilderItem() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      // Array of AxDataContactRecordDataField
      fields: '=axFields',
      filterItem: '=ngModel',
      removeCallback: '=axRemoveCallback',
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: [controller],
    require: "ngModel",
  }

}

function controller() {

  var vm = this;

  vm.selectedField = vm.filterItem.fieldId;

  vm.remove = function () {

    vm.removeCallback(vm.filterItem);

  }

}

function link($scope) {

  $scope.$watch("vm.selectedField", function (newValue) {

    var vm = $scope.vm,
      fields, l, i, field;

    if (vm.filterItem !== null && newValue !== null) {

      // Find field type by field id
      fields = vm.fields;
      l = fields.length;
      for (i = 0; i < l; i++) {
        field = fields[i];
        if (field.Id === newValue) {
          vm.filterItem.fieldName = field.Name;
          vm.filterItem.fieldId = field.Id;
          vm.filterItem.fieldType = field.FieldType;
          // Reset value
          vm.filterItem.value = "";
          break;
        }
      }
    }

  });

}

export { AxFilterBuilderItem };
