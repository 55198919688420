import template from './AxSessionChatMessage.html';
import { escape, unescape } from "lodash-es";
import { findNumbers } from "libphonenumber-js";
import "./axSessionChatMessage.css";
import "./axPhoneNumber.css"

const pack = (number, cleanNumber, li18n) => `<span ax-phone-number="${cleanNumber}" title="${li18n.messages.clickToContactSearch}" class="searchable-phone-number">${number}</span>`;

function replacePhoneNumbers(txt, li18n) {

  const numbers = findNumbers(txt, 'PL', { v2: true });

  if (numbers.length > 0) {

    const { tokens, last } = numbers.reduce((acc, n) => {

      let retAcc = [];

      const prev = txt.slice(acc.last, n.startsAt);
      if (prev.length > 0) {
        retAcc.push(prev);
      }

      const originalNumber = txt.slice(n.startsAt, n.endsAt);

      // Check if number not
      const t = txt.slice(n.startsAt - 3, n.startsAt);
      if (n.startsAt > 2 && txt.slice(n.startsAt - 3, n.startsAt) === "://") {
        retAcc.push(originalNumber);
      } else {
        retAcc.push(pack(originalNumber, n.number.nationalNumber, li18n));
      }

      const r = {
        tokens: acc.tokens.concat(retAcc),
        last: n.endsAt
      };

      return r;

    }, { tokens: [], last: 0 });

    return tokens.concat(txt.slice(last)).join("");

  } else {
    return txt;
  }

}

function createTextLinks_(text) {

  return (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    function (match, space, url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return space + `<a href="${hyperlink}" target="_blank">${url}</a>`;
    }
  );

}

/** @ngInject */
export function AxSessionChatMessage($compile) {

  return {
    restrict: 'E',
    controllerAs: 'vm',
    bindToController: true,
    controller,
    template,
    scope: {
      msg: "=axMsg",
      replacePhoneNumbers: "=axReplacePhoneNumbers",
      li18n: "=axI18n"
    },

    link: function ($scope, $element) {

      if (typeof $scope.vm.msg.Message === "string") {

        //const content = "<div>" + replacePhoneNumbers($filter("linky")(escape($scope.vm.msg.Message), "_blank"), $scope.vm.li18n) + "</div>";
        //const content = "<div>" + $filter("linky")(replacePhoneNumbers(escape($scope.vm.msg.Message), $scope.vm.li18n),"_blank") + "</div>";

        const content = (() => {
          if ( $scope.vm.replacePhoneNumbers === true ) {
            //console.log("Szukam numerów");
            return "<div>" + createTextLinks_(replacePhoneNumbers(escape($scope.vm.msg.Message), $scope.vm.li18n)) + "</div>";
          } else {
            //console.log("Nie szukam numerów");
            return "<div>" + createTextLinks_(escape($scope.vm.msg.Message), $scope.vm.li18n) + "</div>";
          }
        })();

        const compiled = $compile(content)($scope);

        Array.prototype.forEach.call(compiled[0].childNodes, function (el) {
          if (el.nodeType === 3) {
            el.nodeValue = unescape(el.nodeValue);
          }

        });

        $element.find('[ax-chat-msg-content]')[0].appendChild(compiled[0]);

      }

    }
  }

}

function controller() {
  var vm = this;
}
