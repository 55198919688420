function MiniApiService(Errors, CONFIG, Data, $http) {

  this.getCustomer = getCustomer;
  this.getTurnData = getTurnData;

  /**
   * Gets turn data for rtc configuration
   * @param {Object} Parameters
   * @param {Boolean} [p.withCredentials=false]
   * @return {Promise} Promise resolved with response data
   */
  function getTurnData(p) {

    var path = createUrl(CONFIG, "turn/get-token");

    return new Promise(function (resolve, reject) {

      $http.get(path,

        {
          withCredentials: p.withCredentials ? p.withCredentials : false,
          headers: {
            "Authorization": "Bearer " + Data.accessToken
          }
        }
      ).then(function (res) {
        var data = res.data, status = res.status;

        if (status === 200) {
          resolve(data);
        } else {
          reject({
            msg: "MiniApi getTurnData Error. Response status: " + status,
            data: data
          });
        }

      }).catch(function (res) {
        var data = res.data, status = res.status;

        reject({
          msg: "MiniApi getTurnData Error. Response status: " + status,
          data: data
        });

      });

    });

  }

  /**
   * Gets customer record by customer id
   * @param {Object} Parameters
   * @param {Boolean} [p.withCredentials=false]
   * @param {String} p.id Customer id to get
   * @return {Promise} Promise resolved with response data
   */
  function getCustomer(p) {

    var path = "customers/" + p.id;

    return new Promise(function (resolve, reject) {

      $http.get(createUrl(CONFIG, path),
        {
          withCredentials: p.withCredentials ? p.withCredentials : false,
          headers: {
            "Authorization": "Bearer " + Data.accessToken
          }
        }
      ).then(function (res) {
        var data = res.data, status = res.status;

        if (status === 200) {

          if ("customer" in data && data.customer !== null) {
            resolve(data.customer);
          } else {

            if (data.result === 1) { // Invalid token

              reject(raiseError(Errors.CommonRestErrors.INVALID_TOKEN))

            } else {

              // TODO: Error code po ludzku
              reject({
                msg: "MiniApi getCustomer error. Customer not exists",
                data: data
              })
            }
          }
        } else {

          // TODO: Error code po ludzku
          reject({
            msg: "MiniApi getCustomer error. Response status: " + status,
            data: data
          });
        }

      }).catch(function (res) {
        var data = res.data, status = res.status;

        reject({
          msg: "MiniApi getCustomer error. Response status: " + status,
          data: data
        });

      });

    });

  }

  function createUrl(config, path) {
    var retUrl = "",
      port = "";
    if (config.services.miniApi.fromDomain === true) {

      if (window.location.port.length > 0) {
        port = ":" + window.location.port;
      }

      retUrl = window.location.protocol + "//" + window.location.host + port + "/" + config.services.miniApi.url + "/" + path;

    } else {
      retUrl = config.services.miniApi.url + "/" + path;
    }

    return retUrl;

  }


  function raiseError(err) {
    return new err;
  }

  /** Error definitions */
  function Error() {
  }

  Error.prototype = Object.create(Errors.Error.prototype);
}

export { MiniApiService };
