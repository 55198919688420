import * as React from 'react';
import {
  CameraBasicIcon, CameraSlashIcon
} from '../components/icons';
import { MediaControlProps } from '../models';

export const MuteVideoButton = ({ media }: { media: MediaControlProps | any }) => {
  const { isMuted, mute } = media;
  return (
    <button onClick={mute}>
      {isMuted ? (
        <CameraSlashIcon/>
      ) : (
        <CameraBasicIcon/>
      )}
    </button>
  );
};
