import template from './AxSurveyFieldSetDataRecordStage.html';

function AxSurveyFieldSetDataRecordStage() {

  return {
    restrict: 'E',
    scope: {
      axC: '=axControl',
      li18n: '=axI18n',
      sharedData: '=axSharedData'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  vm.error = false;
  helpers.initField(vm);
  vm.stageIsSet = false;

  vm.setStage = function () {

    var stage = vm.axC.Value !== null ? Number(vm.axC.Value) : null;

    helpers.sendSetDataRecordStage(stage).then(function (res) {

      if (stage !== null) {
        vm.error = false;
        vm.infoText = vm.li18n.messages.stageSetTo + ": " + stage;
        vm.showInfo = true;
        vm.sharedData.dataRecordStage = stage;
        vm.stageIsSet = true;
        vm.validate();
      }

    }, function (err) {

      vm.infoText = vm.li18n.messages.setStageError;
      vm.error = true;
      vm.showInfo = true;

    });

  }

  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      if (vm.stageIsSet === false) {

        return "fieldIsRequired";
      }
    }
    return null;
  };

  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };

  if (typeof vm.sharedData.dataRecordStage === "undefined") {
    vm.axC.setValue(vm.axC.Field.DefaultValue);
  } else {
    vm.axC.setValue(vm.sharedData.dataRecordStage);
    vm.infoText = vm.li18n.messages.stageSetTo + ": " + vm.sharedData.dataRecordStage;
    vm.showInfo = true;
    vm.stageIsSet = true;
  }
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxSurveyFieldSetDataRecordStage };
