import { uniq } from "lodash-es";

function SessionTabsCtrl( $scope, Data, CONFIG, SettingsData) {
  var stc = this, li18n = Data.li18n;

  // This property is set by ng-init from template
  stc.interaction = undefined;
  stc.activeTab = CONFIG.tabs.active;
  stc.tabsOrder = CONFIG.tabs.order;

  stc.isCrmReady = isCrmReady;
  stc.crmRequiredAction = crmRequiredAction;
  stc.isSessionChatToolsVisible = isSessionChatToolsVisible;
  stc.isManualHintEnabled = isManualHintEnabled;
  stc.isSurveyTabEnabled = isSurveyTabEnabled;
  stc.surveyRequiredAction = surveyRequiredAction;
  stc.isCrmTabEnabled = isCrmTabEnabled;

  $scope.$on("openCrmPage", function (event, data) {

    if( data.interaction && data.interaction.session.Session.SessionId === stc.interaction.session.Session.SessionId ) {

      stc.activeTab = "crm";

      const { customers } = data;

      const newUrl = (() => {

        // Uniq bo zwracane są właściwie kontakty, więc może się pojawić 2 razy ten sam customer, bo by spowodowało, że zamiast jaego rekordy zostanie wyświetlona niepotrzebnie szukajka
        const filtered = uniq(customers.map( c => c.customerId)),
          len = filtered.length;

        if ( len === 0 ) {
          return  SettingsData.get("UfpServiceAddress") + CONFIG.ufp.customerListPage + `?f.cc.ContactClear~=${data.number}`;
        } else if ( len === 1 ) {
          return  SettingsData.get("UfpServiceAddress") + CONFIG.ufp.customerPage + `${customers[0].customerId}`;
        } else { // > 1
          return  SettingsData.get("UfpServiceAddress") + CONFIG.ufp.customerListPage + "?" + filtered.map( id => `f.c.Id[]=${id}`).join("&");
        }
      })();

      stc.interaction.data.web.customerUrl = newUrl;

      $scope.$broadcast("refreshCrmFrame",{
        newUrl,
        interaction: data.interaction
      });

    }

  });

  /**
   * @param {InteractionItem} interaction
   */
  function isCrmReady(interaction) {
    if (!interaction) {
      return true;
    }

    if (interaction.data.web.forceAssignSessionToCustomer === true) {
      return interaction.session.Session.ContactCustomerId !== null;
    }

    return true;
  }

  /**
   * @param {InteractionItem} interaction
   */
  function crmRequiredAction(inter) {
    var actions = [];
    if (inter.isCrmReady === false) {
      actions.push("<p class='required-actions-tt-header'>" + li18n.messages.requiredActions + ":</p><ul class='required-actions-tt'>");
      actions.push("<li>" + li18n.messages.assignCustomer + "</li>");
      actions.push("</ul>");
    }

    return actions.join("");
  }

  function isSessionChatToolsVisible() {
    return Data.currentInteraction && (
      Data.currentInteraction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat ||
      Data.currentInteraction.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial);
  }

  function isManualHintEnabled() {
    return CONFIG.menerva.manualHintEnabled && isSessionChatToolsVisible();
  }

  /**
   * @param {InteractionItem} interaction
   */
  function isSurveyTabEnabled(inter) {

    var subType = inter.session.Session.SubType,
      AxSubType = ax.SessionLib.AxSessionSubtype;

    if (subType === AxSubType.InboundChat
      || subType === AxSubType.InboundSocial
      || subType === AxSubType.InboundEmail) {

      if (inter.surveySession) {
        return true;
      } else {
        return false;
      }
    }

    return false;

  }

  /**
   * @param {InteractionItem} interaction
   */
  function surveyRequiredAction(inter) {

    var actions = [];
    if (inter.isSurveyReady === false) {
      actions.push("<p class='required-actions-tt-header'>" + li18n.messages.requiredActions + ":</p><ul class='required-actions-tt'>");
      actions.push("<li>" + li18n.messages.completeSurvey + "</li>");
      actions.push("</ul>");
    }

    return actions.join("");

  }

  /**
   * @param {InteractionItem} inter
   */
  function isCrmTabEnabled(inter) {

    return true;

  }

}

export { SessionTabsCtrl };
