import template from './AxSurveyFieldSetAfterSessionState.html';

function AxSurveyFieldSetAfterSessionState() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n',
      sharedData: '=axSharedData'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);

  vm.showInfo = false;
  vm.infoText = "";
  vm.error = false;

  vm.canSetAfterSessionState = function () {

    var session = helpers.getCurrentSession();
    if (session !== null) {
      var state = session.session.Session.SessionState
      var sState = ax.SessionLib.AxSessionState;

      var isEnded = (state === sState.EndedByRemoteSide ||
        state === sState.EndedByUser ||
        state === sState.InitiationFailed ||
        state === sState.InvalidDestination ||
        state === sState.Rejected ||
        state === sState.RejectedByRemoteSide ||
        state === sState.RejectedByUser ||
        state === sState.SystemTerminated ||
        state === sState.UnableToEstablisheWithUser);

      return !isEnded;
    }

    return false;

  };

  vm.setAfterSessionState = function () {

    var duration = vm.axC.Field.StatusTime === null || typeof  vm.axC.Field.StatusTime === "undefined" ? null : moment.duration(vm.axC.Field.StatusTime).asSeconds();
    helpers.setAfterSessionState({
      stateId: vm.axC.Field.StateId,
      stateTime: duration
    }).then(function (res) {
      vm.showInfo = true;
      vm.infoText = vm.li18n.messages.statusSetAfterSession + ": " + vm.li18n.statuses[res.ResultObject];
      vm.sharedData.afterSessionState = res.ResultObject;
      console.debug("[AxSurveyFieldSessionState] Set after session state response", res);
    }, function (err) {
      console.error("[AxSurveyFieldSessionState] Set after session state error", err);
      vm.showInfo = vm.infoText = vm.li18n.messages.errorSettingStatus;
    });

  };

  if (typeof vm.sharedData.afterSessionState === "undefined") {
    vm.axC.setValue(vm.axC.Field.DefaultValue);
  } else {
    vm.axC.setValue(vm.sharedData.afterSessionState);
    vm.infoText = vm.li18n.messages.statusSetAfterSession + ": " + vm.li18n.statuses[vm.sharedData.afterSessionState];
    vm.showInfo = true;
  }

  helpers.endInitField(vm);

}

function link($scope, $element, $attrs, $ctrl) {

  var vm = $scope.vm
  $scope.$watch("vm.sharedData.afterSessionState", function (newValue, oldValue) {

    if (newValue !== undefined) {

      vm.infoText = vm.li18n.messages.statusSetAfterSession + ": " + vm.li18n.statuses[newValue];
      vm.showInfo = true;

    }

  });

}

export { AxSurveyFieldSetAfterSessionState };
