import { beep, PhoneSound } from "./common/phoneSound";

const UFP_LOGIN_ERROR = {
  // Incorrect username or password.
  INVALID_CREDENTIALS: 4
};

export const SoundType = {
  chatStart: 'chatStart',
  chatNewMessage: 'chatNewMessage',
  chatEnd: 'chatEnd',
  newEmail: 'newEmail',
  routing: 'routing'
};

angular.module('ax.WebCommunications')
  .constant('UFP_LOGIN_ERROR', UFP_LOGIN_ERROR)
  .constant('PhoneSound', PhoneSound)
  .constant('beep', beep)
  .constant('SoundType', SoundType);
