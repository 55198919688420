function AxSrcdoc() {

  return {
    restrict: "A",
    link: link
  }

}

function link($scope, $element, $attrs, $ctrl, $transclude) {

  $scope.$watch(function ($scope) {
    return $attrs.axSrcdoc;
  }, function (newValue, oldValue) {

    if (typeof newValue === "string" && newValue.length > 0) {
      $element[0].srcdoc = newValue;
    }

  });

}

export { AxSrcdoc };
