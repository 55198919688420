import CryptoJS from "crypto-js";

const k = CryptoJS.enc.Hex.parse("d86dd5490cf8af2ec83f5b21599386ce8d4d45e8c489b7439b659c209a94bfc4");
const i = CryptoJS.enc.Hex.parse("758f373b3f7d705bc70ddab45fba24b8");

export function SimpleDecode(value) {
    return CryptoJS.AES.decrypt(value, k, {iv: i, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}).toString(CryptoJS.enc.Utf8);
}

export function SimpleEncode(value) {
    const valueEncoded = CryptoJS.enc.Utf16LE.parse(value);
    return CryptoJS.AES.encrypt(valueEncoded, k, {iv: i, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}).toString()
}

