/**
 * Main AxDraggable directive DOD (Directive Object Declaration)
 */
function AxDraggable() {

  return {
    restrict: 'A',

    link: function link($scope, $element, $attrs, $ctrl) {

      $element.attr('draggable', true);
      $element.on("dragstart",
        /**
         * @param {DragEvent} evt
         */
        function (evt) {

          $scope.$apply(function () {
            //$parse($element.attr("ax-on-drag-start"))($scope, {evt : evt});
            $scope.$eval($element.attr("ax-on-drag-start"), { evt: evt });
          });
        });
      $element.on("dragend", function (evt) {
        $scope.$apply(function () {
          $scope.$eval($element.attr("ax-on-drag-end"), { evt: evt });
        });
      });
    }
  }
}

export { AxDraggable };
