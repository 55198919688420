function NotificationService(NotificationData, CONFIG, Data) {

  var sessionNotifications = [];

  this.showSessionNotification = showSessionNotification;
  this.createSessionNotification = createSessionNotification;
  this.sessionNotificationBody = sessionNotificationBody;
  this.removeSessionNotifications = removeSessionNotifications;
  this.closeAndRemoveSesionNotifications = closeAndRemoveSesionNotifications;
  this.showOrHideSessionNotification = showOrHideSessionNotification;
  this.canShowNotification = canShowNotification;

  /**
   * Checks if window is hidden against notifications configuration
   * returns true if notification can be showed. If not returs false
   * @return {Boolean}
   */
  function canShowNotification() {

    if (CONFIG.notifications.showNotifications === "always") {
      return true;
    } else if (CONFIG.notifications.showNotifications === "never") {
      return false;
    } else { // minimized
      // If window is hidden, show notification
      return document.hidden;

    }

  }

  /**
   * Show notification wrapper
   * Shows notification only if at least one counter is > 0
   * If counter is not given in parameter, its value is get from NotificationData
   * @param {Object} p Parameters literal object
   * @param {Number} p.voiceSessionCnt Voice sessions quantity
   * @param {Number} p.chatSessionCnt Chat sessions quantity
   * @param {Number} p.facebookSessionCnt Facebook sesion quantity
   * @param {Number} p.emailSessionCnt Email sessions quantity
   * @param {Number} p.unreadChatMessagesCnt Unread chat messages quantity
   * @param {Number} p.videoSessionCnt Video session quantity
   * @param {Object} p.li18n
   */
  function showOrHideSessionNotification(p) {

    if (["voiceSessionCnt" in p ? p.voiceSessionCnt : NotificationData.newSessionNotification.voiceSessionCnt,
      "chatSessionCnt" in p ? p.chatSessionCnt : NotificationData.newSessionNotification.chatSessionCnt,
      "facebookSessionCnt" in p ? p.facebookSessionCnt : NotificationData.newSessionNotification.facebookSessionCnt,
      "unreadChatMessagesCnt" in p ? p.unreadChatMessagesCnt : NotificationData.newSessionNotification.unreadChatMessagesCnt,
      "emailSessionCnt" in p ? p.emailSessionCnt : NotificationData.newSessionNotification.emailSessionCnt,
      "videoSessionCnt" in p ? p.videoSessionCnt : NotificationData.newSessionNotification.videoSessionCnt].filter(function (el) {

      return el > 0;

    }).length > 0) {

      createSessionNotification({
        body: sessionNotificationBody({
          voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
          chatSessionCnt: NotificationData.newSessionNotification.chatSessionCnt,
          emailSessionCnt: NotificationData.newSessionNotification.emailSessionCnt,
          facebookSessionCnt: NotificationData.newSessionNotification.facebookSessionCnt,
          unreadChatMessagesCnt: NotificationData.newSessionNotification.unreadChatMessagesCnt,
          videoSessionCnt: NotificationData.newSessionNotification.videoSessionCnt,
          li18n: p.li18n
        }),
        li18n: p.li18n
      });

    } else {

      closeAndRemoveSesionNotifications();

    }

  }

  /**
   * Creates new notification body
   * @param {Object} p Prameters literal object
   * @param {Number} p.voiceSessionCnt Voice sessions quantity
   * @param {Number} p.chatSessionCnt Chat sessions quantity
   * @param {Number} p.facebookSessionCnt Facebook sesion quantity
   * @param {Number} p.emailSessionCnt Email sessions quantity
   * @param {Number} p.unreadChatMessagesCnt Unread chat messages quantity
   * @param {Number} p.videoSessionCnt Video session quantity
   * @param {Object} p.li18n
   * @returns {String}
   */
  function sessionNotificationBody(p) {

    var ret = [];

    var voiceSessionCnt = "voiceSessionCnt" in p ? p.voiceSessionCnt : NotificationData.newSessionNotification.voiceSessionCnt;
    var chatSessionCnt = "chatSessionCnt" in p ? p.chatSessionCnt : NotificationData.newSessionNotification.chatSessionCnt;
    var facebookSessionCnt = "facebookSessionCnt" in p ? p.facebookSessionCnt : NotificationData.newSessionNotification.facebookSessionCnt;
    var unreadChatMessagesCnt = "unreadChatMessagesCnt" in p ? p.unreadChatMessagesCnt : NotificationData.newSessionNotification.unreadChatMessagesCnt;
    var emailSessionCnt = "emailSessionCnt" in p ? p.emailSessionCnt : NotificationData.newSessionNotification.emailSessionCnt;
    var videoSessionCnt = "videoSessionCnt" in p ? p.videoSessionCnt : NotificationData.newSessionNotification.videoSessionCnt;

    if (voiceSessionCnt > 0) {
      ret.push(p.li18n.messages.voice + ": " + voiceSessionCnt);
    }

    if (chatSessionCnt > 0) {
      ret.push(p.li18n.messages.chat + ": " + chatSessionCnt);
    }

    if (unreadChatMessagesCnt > 0) {
      ret.push(p.li18n.messages.unreadChatMessages + ": " + unreadChatMessagesCnt);
    }

    if (emailSessionCnt > 0) {
      ret.push(p.li18n.messages.email + ": " + emailSessionCnt);
    }

    if (videoSessionCnt > 0) {
      ret.push(p.li18n.messages.video + ": " + videoSessionCnt);
    }

    return ret.join("\n");

  }

  /**
   * Creates and display session notification
   * @param {Object} p Parameteres literal object
   * @param {String} p.body
   * @param {Object} p.li18n
   */
  function createSessionNotification(p) {

    var n = new Notification(p.li18n.messages.newSessions, {
      body: p.body,
      icon: "/assets/images/notification_icon.png",
      tag: "NewSessions" // Notifications with same tag are replaced
    });

    function resetAndClose() {
      NotificationData.resetAllCounters();
      removeSessionNotifications();
      this.close();
      window.focus();
    }

    function close() {
      this.close();
    }

    n.onclick = resetAndClose.bind(n);
    n.onclose = close.bind(n);
    sessionNotifications.push(n);

  }

  /**
   * @param {AxSessionServiceSessionBase} sessions
   * @returns {Boolean}
   */
  function hasAcceptAction(session) {

    var actions = session.Actions;
    if (actions === null || typeof actions === "undefined") {
      return false;
    }

    var ret = (actions.filter(function (action) {

      return action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession;

    }).length > 0);


    return ret;

  }

  /**
   * Show session notification
   * @param {Object} p Parameters literal object
   * @param {ax.SessionLib.AxMessages.Sessions.AxMsgSessionDirectedEvent} p.event
   * @param {Object} p.li18n Translation object
   */
  function showSessionNotification(p) {

    var session = p.event.Session,
      sState = session.SessionState,
      AxState = ax.SessionLib.AxSessionState,
      sSubType = session.SubType,
      AxSubType = ax.SessionLib.AxSessionSubtype,
      show = false;

    // Huntgrout session also incoming when user has status other that available
    // so dont show notification in this case
    if (Data.currentStatus.State !== ax.SessionLib.AxUserState.Available) {
      return;
    }

    if ((
      (((sState === AxState.DirectedToUser && hasAcceptAction(session) === false) || sState === AxState.Queued) &&
        (sSubType === AxSubType.InboundChat || sSubType === AxSubType.InboundSocial || sSubType === AxSubType.InboundCall || sSubType === AxSubType.PredictiveCall)) ||
      (sState === AxState.AcceptedByUser && sSubType === AxSubType.InboundEmail)
    ) && canShowNotification() === true) {

      if (typeof Notification !== "undefined") {

        if (sSubType === AxSubType.InboundChat) {
          NotificationData.newSessionNotification.chatSessionCnt += 1;
        } else if (sSubType === AxSubType.InboundSocial) {
          NotificationData.newSessionNotification.facebookSessionCnt += 1;
        } else if (sSubType === AxSubType.InboundEmail) {
          NotificationData.newSessionNotification.emailSessionCnt += 1;
        } else if (sSubType === AxSubType.InboundCall) {
          NotificationData.newSessionNotification.voiceSessionCnt += 1;
        }
        /* else if ( sSubType === AxSubType.InboundCall || sSubType === AxSubType.InboundCall || sSubType === AxSubType.PredictiveCall ) {
            NotificationData.newSessionNotification.voiceSessionCnt += 1;
        }*/

        createSessionNotification({
          body: sessionNotificationBody(
            {
              voiceSessionCnt: NotificationData.newSessionNotification.voiceSessionCnt,
              chatSessionCnt: NotificationData.newSessionNotification.chatSessionCnt,
              facebookSessionCnt: NotificationData.facebookSessionCnt,
              emailSessionCnt: NotificationData.newSessionNotification.emailSessionCnt,
              unreadChatMessagesCnt: NotificationData.newSessionNotification.unreadChatMessagesCnt,
              videoSessionCnt: NotificationData.newSessionNotification.videoSessionCnt,
              li18n: p.li18n
            })
          , li18n: p.li18n
        });

      }

    }

  }

  function removeSessionNotifications() {
    sessionNotifications.length = 0;
  }

  function closeAndRemoveSesionNotifications() {

    sessionNotifications.forEach(function (n) {
      n.close();
    });
    removeSessionNotifications();
  }
}

export { NotificationService };
