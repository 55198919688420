import template from './AxSurveyFieldDataRecordAbort.html';

function AxSurveyFieldDataRecordAbort() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n',
      inter: "=axInteractionItem"
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);

  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
  };
}

function link($scope, $element, $attrs, $ctrl) {

}

export { AxSurveyFieldDataRecordAbort };
