import template from './AxSurveyFieldRecording.html';

function AxSurveyFieldRecording() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n',
      session: '=axSession'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);

  //vm.onValueSet = function (field) {
  //    field.AnswerDateTime = new Date().getTime();
  //    //todo:: wysłać request
  //};

  //vm.axC.setValue(vm.axC.Value);
  //vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  var input = $element.find("input[type=button]");
}

export { AxSurveyFieldRecording };
