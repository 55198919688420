/**
 * AxSplitHorizontal DOD
 */
function AxSplitHorizontal() {

  return {
    restrict: 'A',
    link: link,
    priority: 100,
    controllerAs: 'wtf', // Nie mam pojęcia czemu, ale jeżeli tutaj użyję normalnie vm, to wyżej się coś wywala
    bindToController: true,
    controller: ['$scope', controller]
  }

}

function controller($scope) {

  var vm = this;
  vm.minimizedHeight = 20;
  vm.footerHeight = 18;

  vm.emitSplitChanged = function (data) {
    //$scope.$emit('sessionTabsSplitChanged', data);
  }
}

function link($scope, $element, $attrs, $ctrl) {

  var splitter = document.createElement("div");
  var splitterHandler = document.createElement("div");
  //var splitterHandlerIco = document.createElement("i");
  splitter.classList.add("ax-splitter-horizontal");

  splitterHandler.classList.add("ax-splitter-horizontal-handler");

  //splitterHandlerIco.classList.add("fa");
  //splitterHandlerIco.classList.add("fa-angle-top");

  //splitterHandler.appendChild(splitterHandlerIco);
  splitter.appendChild(splitterHandler);

  $element.prepend(splitter);

  var dragStarted = false,
    $elementToResize;

  function splitterMousedown(evt) {

    $elementToResize = $element;
    console.log($elementToResize);
    $elementToResize.find("iframe").css("pointer-events", "none");
    dragStarted = true;


  }

  splitter.addEventListener("mousedown", splitterMousedown);

  function splitterDblClick(evt) {

    $scope.wtf.emitSplitChanged({
      width: ""
    })

  }

  // Reset to default size on dblclick
  splitter.addEventListener("dblclick", splitterDblClick);

  function splitterHandlerDblClick(evt) {

    var dstHeight;
    var w = $element.width();
    if ($element.height() > $scope.wtf.minimizedHeight) {

      dstHeight = $scope.wtf.minimizedHeight + "px";

    } else {

      dstHeight = "";
    }

    $element.height(dstWidth);

    $scope.wtf.emitSplitChanged({
      height: dstHeight
    });

    evt.stopPropagation();

  }

  splitterHandler.addEventListener("dblclick", splitterHandlerDblClick);

  function documentMouseup() {

    if (dragStarted === true) {

      //$elementToResize.find("iframe").css("pointer-events", "auto");

      dragStarted = false;


      // Emitujemy event ze zmianą, a apka sobie zapisze już co chce
      $scope.wtf.emitSplitChanged({
        height: $elementToResize.height()
      });

      $elementToResize = null;

    }

  }

  document.addEventListener("mouseup", documentMouseup);

  function documentMousemove(evt) {

    //var targetH;
    if (dragStarted === true) {

      console.log("Drag jest started");

      // Po chuj to jest?
      //$elementToResize.height(targetH);

      // Wysokość to całość ekranu minus pozycja kursora
      // minu odległość dołu diva od dołu strony


      // Wysokość parenta minus (pozyja od góry plus wysokość)
      var fromBottom = $elementToResize.offsetParent().outerHeight() - ($elementToResize.position().top + $elementToResize.outerHeight() - $scope.wtf.footerHeight);


      console.log("TOP " + $elementToResize.position().top + "HEIGHT " + $elementToResize.outerHeight() + " FROM BOTTOM: " + fromBottom);

      var dstHeight = $("body").height() - evt.y - fromBottom;
      console.log("Ustawiam wysokośc na " + dstHeight);

      if (dstHeight > $scope.wtf.minimizedHeight) {
        console.log("JEszcze mogę ustawić, to ustawiam");
        $elementToResize.height(dstHeight + "px");
      }

    } else {
      console.log("Drag nie jest started");
    }

  }

  document.addEventListener("mousemove", documentMousemove);

  $scope.$watch($attrs.axSplitHorizontal, function (newValue, oldValue) {

    if (newValue === true) {
      splitter.style.display = "block";
    } else {
      splitter.style.display = "none";
    }

  });

  $scope.$on("$destroy", function (event) {

    splitter.removeEventListener("mousedown", splitterMousedown);
    splitter.removeEventListener("dblclick", splitterDblClick);
    splitterHandler.removeEventListener("dblclick", splitterHandlerDblClick);
    document.removeEventListener("mouseup", documentMouseup);
    document.removeEventListener("mousemove", documentMousemove);
  });

}

export { AxSplitHorizontal };
