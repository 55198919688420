import isString from 'lodash-es/isString';

export function UtilsService(SettingsData) {
  var service = {
    prepareUrl: prepareUrl,
    createUrl: createUrl,
    findSystemSetting: findSystemSetting,
    getExtendedLangCode: getExtendedLangCode,
    isAbsolutePath: isAbsolutePath,
    zeroPad: zeroPad
  };

  return service;

  function prepareUrl(url, data) {
    var ufpBaseUrl = SettingsData.get('UfpServiceAddress');
    var loginParam = "&user_login=$UserLogin";
    var pwdParam = "&user_pwd=$UserPassword";
    var lng = getExtendedLangCode(data.Lang)[1];

    // Podmiana może nie zadziałać gdy parametr
    // będzie występował jako pierwszy w search
    loginParam = url.indexOf(loginParam) !== -1
      ? loginParam : loginParam.slice(1);

    pwdParam = url.indexOf(pwdParam) !== -1
      ? pwdParam : pwdParam.slice(1);

    return url
      .replace(loginParam, "")
      .replace(pwdParam, "")
      .replace("menu_mode=mini", "")
      // $UfpRootUrl/ -> /
      .replace("$UfpRootUrl/", ufpBaseUrl)
      .replace("$UserId", data.Id)
      .replace("$UserName", data.Name)
      .replace("$UserSurname", data.Surname)
      .replace("$UserEmail", data.Email)
      .replace("$user_lang", lng)
      .replace("$userlang", lng);
  }


  /**
   * Creates absolute or relative url depending on configuration settings
   * @param {Object} p Parameters object
   * @param {Boolean} p.fromDomain
   * @param {String} p.url
   * @param {String} [p.path=""]
   * @returns {String}
   */
  function createUrl(p) {

    var retUrl = "",
      port = "",
      path = typeof p.path === "string" && p.path.length > 0 ? p.path : "";

    if (p.path.length > 0 && p.path.startsWith("/") === false) {
      path = "/" + path;
    }

    if (p.fromDomain === true) {

      if (window.location.port.length > 0) {
        port = ":" + window.location.port;
      }

      retUrl = window.location.protocol + "//" + window.location.host + port + "/" + p.url;

      // Remove trailing slash
      if (retUrl.endsWith("/") && path.length > 0) {
        retUrl = retUrl.slice(0, -1) + path;
      } else {
        retUrl += path;
      }

    } else {
      if (p.url.endsWith("/") && path.length > 0) {
        retUrl = p.url.slice(0, -1) + path;
      } else {
        retUrl = p.url + path;
      }
    }

    return retUrl;

  }

  /**
   * Find system variable by name from SystemVariables collection.
   * If not found returns undefined.
   * @param {String} name
   * @param {Array<ax.AxEnvironmentLib.Collections.AxSystemVarDataElement>} from
   * @returns {ax.AxEnvironmentLib.Collections.AxSystemVarDataElement}
   */
  function findSystemSetting(name, from) {
    return from.find(function (setting) {
      return setting.Name === name;
    });
  }

  /**
   * Returns two element array
   * First element contains simple lang code
   * Second element contains extended lang code
   * @param {String} lang Simple lang code
   * @return Array
   */
  function getExtendedLangCode(lang) {

    var exLang;
    switch (lang) {
      case "en":
        exLang = "en-US";
        break;

      case "ro":
        exLang = "ro-RO";
        break;

      case "pl":
        exLang = "pl-PL";
        break;

      case "cs":
        exLang = "cs-CZ";
        break;

      default:
        exLang = "en-US";
        break;
    }

    return [lang, exLang];
  }

  function isAbsolutePath(path) {
    return isString(path) && path.startsWith('http');
  }

  function zeroPad(n) {
    return n < 10 ? '0' + n : n;
  }
}
