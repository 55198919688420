import MediaProxyClient from '@uf/media-proxy-client';

function MediaProxyClientService($rootScope, Errors) {

  this.isInitialized = isInitialized;
  this.initialize = initialize;
  this.connect = connect;
  this.login = login;
  this.getMediaServersString = getMediaServersString;
  this.dial = dial;
  this.hangup = hangup;
  this.hangupAll = hangupAll;
  this.on = on;
  this.getCalls = getCalls;
  this.getCallsAsArray = getCallsAsArray;
  this.getActiveCall = getActiveCall;

  var mClient;

  function isInitialized() {
    return mClient instanceof MediaProxyClient;
  }

  function initialize(server) {
    mClient = MediaProxyClient({
      server: server
    });
  }

  function connect() {

    if (isInitialized() === false) {
      Promise.reject(raiseError(ERRORS.NOT_INITIALIZED))
    }

    return mClient.connect().catch(function (err) {
      return Promise.reject(raiseError(ERRORS.CONNECT_ERROR));
    });

  }

  function login(mediaProxyLoginToken) {

    if (isInitialized() === false) {
      Promise.reject(raiseError(ERRORS.NOT_INITIALIZED))
    }

    return mClient.login(mediaProxyLoginToken).then(function (loginResult) {

      if (loginResult === MediaProxyClient.Enums.LoginResult.Failure ||
        loginResult === MediaProxyClient.Enums.LoginResult.Unknown) {
        return Promise.reject(raiseError(ERRORS.LOGIN_ERROR));
      }

      // Assign event handlers
      mClient.on("callcreated", onClientEvent);
      mClient.on("answered", onClientEvent);
      mClient.on("hangup", onClientEvent);

      return Promise.resolve(loginResult);

    }).catch(function (err) {
      return Promise.reject(raiseError(ERRORS.LOGIN_ERROR));
    });

  }

  /**
   * Dials to specified number
   * @param {Object} p
   * @param {String} p.sdp Local spd string
   * @param {String} p.number Number to dial
   * @returns {Promise}
   */
  function dial(p) {
    return mClient.dial(p);
  }

  /**
   * Hangupcall
   * @param {String} callId callId to hangup
   * @return {Promise}
   */
  function hangup(callId) {

    return mClient.hangup(callId, false);

  }


  /**
   * Hangup all calls
   * @return Promise
   */
  function hangupAll() {
    return mClient.hangupAll();
  }


  function onClientEvent(evt) {
    // Emit prefixed event to controllers
    emit(evt.type, evt);
  }

  /**
   * Helper to get string of media proxy backed servers ready to use in LoginService.getAccessToken (for mediaServerLogin token type) method as severs query parameter value
   * @param {Array} mediaServers Array of two elements arrays of media proxy backend servers for more information see description of mediaProxy.mediaServes in application configuration file
   * @return {String}
   */
  function getMediaServersString(mediaServers) {

    return mediaServers.map(function (sEntry) {

      if (sEntry.length == 1) {
        return sEntry[0];
      } else {
        return sEntry[0] + "=" + sEntry[1];
      }

    }).join(",");

  }

  /**
   * Gets call map
   * @returns {Map}
   */
  function getCalls() {

    if (isInitialized() === false) {
      throw (raiseError(ERRORS.NOT_INITIALIZED));
    }

    return mClient.calls;

  }

  function getActiveCall() {

    if (isInitialized() === true) {
      return mClient.activeCall;
    } else {
      return undefined;
    }

  }

  //var calls = [];
  function getCallsAsArray() {

    var calls = [];

    if (isInitialized() === true) {

      calls = mClient.callsAsArray;

    }

    return calls;

  }

  /**
   * Register event handler
   * @param {Object} p Parameters object
   * @param {String} p.evtName
   * @param {Object} p.scope Angular $scope from which event handler is registerd (need for cleanup on scope destroy)
   * @param {Function} p.callback Event handler function
   */
  function on(p) {
    var handler = $rootScope.$on(p.evtName, p.callback);
    p.scope.$on("$destroy", handler);
  }

  /**
   * Emits event from service
   * @param evtName
   * @param evt
   */
  function emit(evtName, evt) {

    $rootScope.$emit(evtName, evt);

  }


  /** Error definitions */
  function Error() {
  }

  Error.prototype = Object.create(Errors.Error.prototype);

  function NOT_INITIALIZED() {
  }

  NOT_INITIALIZED.prototype = Object.create(Error.prototype, {
    code: { value: 1, enumerable: true },
    message: { value: "Media proxy client is not initialized", enumerable: true }
  });

  function CONNECT_ERROR() {
  }

  CONNECT_ERROR.prototype = Object.create(Error.prototype, {
    code: { value: 2, enumerable: true },
    message: { value: "Media proxy connect error", enumerable: true }
  });

  function LOGIN_ERROR() {
  }

  LOGIN_ERROR.prototype = Object.create(Error.prototype, {
    code: { value: 3, enumerable: true },
    message: { value: "Media proxy login error", enumerable: true }
  });

  var ERRORS = Object.create(null, {
    NOT_INITIALIZED: {
      value: NOT_INITIALIZED,
      enumerable: true
    },
    CONNECT_ERROR: {
      value: CONNECT_ERROR,
      enumerable: true
    },
    LOGIN_ERROR: {
      value: LOGIN_ERROR,
      enumerable: true
    }
  });

  this.Error = Error;
  this.ERRORS = ERRORS;

}

function raiseError(err) {
  return new err;
}

export { MediaProxyClientService };
