import * as React from 'react';

export const MicrophoneBasicIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="currentColor">
      <g>
        <path
          d="M6.3 10.7C7.7 10.7 8.9 9.5 8.9 8.1L8.9 2.7C8.9 1.2 7.7 0 6.3 0 4.8 0 3.6 1.2 3.6 2.7L3.6 8.1C3.6 9.5 4.8 10.7 6.3 10.7L6.3 10.7ZM12.5 8.1L11 8.1C11 10.7 8.7 12.6 6.3 12.6 3.8 12.6 1.5 10.7 1.5 8.1L0 8.1C0 11.1 2.4 13.6 5.4 14.1L5.4 17 7.2 17 7.2 14.1C10.1 13.6 12.5 11.1 12.5 8.1L12.5 8.1Z"
        />
      </g>
    </svg>
  );
};
