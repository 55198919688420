import { Statistics } from '@next/webagent';

/** @ngInject */
export function AxStatistics(reactDirective) {
  return reactDirective(Statistics, [
    'language',
    'queues',
    'counters',
    'infoTiles',
    'chartTiles',
    'salaries',
    'fetchSalaries'
  ]);
}
