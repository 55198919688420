import template from './AxSurveyFieldEmail.html';

function AxSurveyFieldEmail() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  var domainCheckResult = {};
  var domainCheckInProgress = [];

  vm.axC.Validate = function (val) {
    var domain;
    var email;
    if (vm.field.Mandatory === true) {
      if (!val) {
        return "fieldIsRequired";
      }
    }

    if (val) {

      if (helpers.validateEmail(val) === false) {
        return "invalidFormat";
      }

      email = val;
      domain = val.split("@")[1];

      if (vm.field.ValidateDomain === true || vm.field.ValidateSmtp === true) {

        if (email in domainCheckResult) {

          if (checkResult(domainCheckResult[email].IsDnsValid, domainCheckResult[email].IsSmtpValid) === true) {
            return null;
          } else {
            return formatResult(domainCheckResult[email].IsDnsValid, domainCheckResult[email].IsSmtpValid, vm.li18n, email);
          }


        } else if (domainCheckInProgress.indexOf(email) !== -1) {

          return "emailCheckingInProgress";

        } else {

          if (domainCheckInProgress.indexOf(email) !== -1) {
            return "emailCheckingInProgress";
          }

          domainCheckInProgress.push(email);

          helpers.sendValidateEmailRequest({
            email: val,
            timeout: 500000, // TODO Z ustawień fielda wyciągnąć
            validateDns: vm.field.ValidateDomain,
            validateSmtp: vm.field.ValidateSmtp,
            smtpPorts: vm.field.ValidateSmtpPorts,
            smtpSslPorts: vm.field.ValidateSmtpSslPorts,
            heloDomain: vm.field.ValidateSmtpDomain
          }).then(function (res) {

            domainCheckResult[email] = res;
            var idx = domainCheckInProgress.indexOf(email);

            if (checkResult(domainCheckResult[email].IsDnsValid, domainCheckResult[email].IsSmtpValid) === true) {
              // Jeżeli jest to wynik sprawdzania domeny innej niz ostatnia na liście, to wyniki tutaj otrzymane olewamy
              // Bierzemy tylko wyniki sprawdzania dla nowej domeny
              if (idx === domainCheckInProgress.length - 1) {
                vm.setValid();
              }

            } else {

              if (idx === domainCheckInProgress.length - 1) {
                vm.setInvalid(formatResult(domainCheckResult[email].IsDnsValid, domainCheckResult[email].IsSmtpValid, vm.li18n, email));
              }

            }

            // Remove
            domainCheckInProgress.splice(idx, 1);

          }, function (err) {

            console.error("Received error response: ", err);
            var idx = domainCheckInProgress.indexOf(email);
            domainCheckResult[email] = { error: true };
            domainCheckInProgress.splice(idx, 1);

          });

          return "emailCheckingInProgress";

        }
      }
    }
    return null;
  };

  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };


  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}


/**
 * Checks if validate email result received from server is positive
 * @param {Boolean} dnsFlag
 * @param {Boolean} smtpFlag
 */
function checkResult(dnsFlag, smtpFlag) {

  return (dnsFlag === true && smtpFlag === true) || (dnsFlag === null && smtpFlag === true) || (dnsFlag === true && smtpFlag === null)

}

/**
 * Formats validate message
 * @param {Boolean} dnsFlag
 * @param {Boolean} smtpFlag
 * @param {Object} li18n
 * @param {String} email
 */
function formatResult(dnsFlag, smtpFlag, li18n, email) {

  if ((dnsFlag === true && smtpFlag === true)
    || (dnsFlag === null && smtpFlag === true)
    || (dnsFlag === true && smtpFlag === null)) {
    return null;

  } else if (dnsFlag === false) {

    return "domainNotExists";

  } else if (smtpFlag === false) {

    return [li18n.messages.email, " ", email, li18n.messages.notExists].join(" ");

  }

}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxSurveyFieldEmail };
