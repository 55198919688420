import angular from 'angular';
import ngAngular from 'angular-animate';
import ngRedux from 'ng-redux';

// AxComponents
import ComponentsModule from './axcomponents';

// Controllers
import ControllersModule from './controllers';

// Factories
import DataFactoriesModule from './data-factories';

// editors
import EditorsModule from './editors';

// Filters
import FiltersModule from "./filters";

// Menu
import MenuModule from './menu';

// Preview
import PreviewModule from './preview';

// React components wrapped into angular directives
import ReactComponentsModule from './reactComponents';

// Services
import ServicesModule from './services';

// Survey
import SurveyModule from './survey';


import ActionsModule from './actions';
import ReducersModule from './reducers';

import { SettingsEpics } from './epics/SettingsEpics';
import { RootEpics } from './epics/RootEpics';

// Configuration
if (process.env.NODE_ENV !== 'production') {
  require('./config.js');
}

export default angular.module('ax.WebCommunications', [
  ngAngular,
  'ui.bootstrap',
  'luegg.directives',
  FiltersModule,
  ServicesModule,
  ControllersModule,
  SurveyModule,
  EditorsModule,
  ComponentsModule,
  PreviewModule,
  MenuModule,
  DataFactoriesModule,
  ReactComponentsModule,
  ngRedux,
  ReducersModule,
  ActionsModule
])
  .service('SettingsEpics', [
    'SETTINGS_LOAD_REQUEST',
    'loadSettingsSuccess',
    'loadSettingsFailure',
    'SETTINGS_SAVE_REQUEST',
    'saveSettingsSuccess',
    'saveSettingsRequest',
    'Storage',
    'getSettings',
    SettingsEpics])
  .service('RootEpics', ['SettingsEpics', RootEpics]);
