/**
 * Appender sends log messages to specified worker
 * Requires log4javascript
 */
(function (log4javascript) {

  if (typeof  log4javascript === "undefined") {
    throw "log4javascript is unavailable";
  }

  var WorkerAppender = function (worker) {
    this.worker = worker;
  };

  WorkerAppender.prototype = new log4javascript.Appender();
  var consoleLayout = new log4javascript.PatternLayout("%d{yyyy-MM-dd HH:mm:ss.SSS} [%c] [%-5p] - %m{10}%n");
  WorkerAppender.prototype.layout = consoleLayout;
  WorkerAppender.prototype.threshold = log4javascript.Level.DEBUG;

  WorkerAppender.prototype.append = function (loggingEvent) {
    var appender = this;
    var getFormattedMessage = function () {
      var layout = appender.getLayout();
      var formattedMessage = layout.format(loggingEvent);
      if (layout.ignoresThrowable() && loggingEvent.exception) {
        formattedMessage += loggingEvent.getThrowableStrRep();
      }
      return formattedMessage;
    };

    appender.worker.postMessage({ type: "log", value: getFormattedMessage() });

  };

  log4javascript.WorkerAppender = WorkerAppender;

})(log4javascript);
