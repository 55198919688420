function ApiRequestWrapperService(Errors, LoginService, CONFIG, Data, $http) {

  this.wrapRequest = wrapRequest;

  /**
   * Wraps calls to rest API.
   * When response from api is "Invalid token error" - calls refreshToken, and retries api call with refreshed token
   * @param p {Object} Parameters object
   * @param {Function} p.requestFnc Request function
   * @param {Array} p.requestFncParams Array of parameters passed to request function
   * @param {Number} [p.authCount=2] - number of retries authRequest - not used
   * @param {Object} p.that - Reference to this
   * @param {Object} p.errorDef Invalid token error definition this error will be compared to catched response error
   * @return {Promise} Promise resolved with result of requestFnc
   */
  function wrapRequest(p) {

    var authCount = "authCount" in p ? p.authCount : 2;

    function apiWrapper(that, requestFncParams) {
      return p.requestFnc.apply(that, requestFncParams);
    }

    return apiWrapper(p.that, p.requestFncParams).catch(function (err) {

      if (err instanceof p.errorDef) {

        return LoginService.getAccessToken({ refreshToken: Data.refreshToken }).then(function (res) {
          Data.accessToken = res.accessToken;
          return apiWrapper(p.that, p.requestFncParams);
        })

      } else { // In case of unknown error pass error as is
        return Promise.reject(err);
      }

    });

  }

}

export { ApiRequestWrapperService };
