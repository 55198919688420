function videoAssociatedSessionNumber(SessionService) {

  return function (videoCall) {

    var ret = "",
      session;
    if (videoCall) {
      session = SessionService.findByVideoCallId(videoCall.id);

      if (session) {

        ret = session.session.Session.RemoteSideFriendlyNameComputed;

      } else {
        ret = videoCall.id;
      }
    }

    return ret;

  }

}

export { videoAssociatedSessionNumber };
