import template from './AxDataRecordEdit.html';

function AxDataRecordEdit() {
  return {
    restrict: 'A',
    scope: {
      li18n: '=axI18n',
      inter: "=axInteractionItem",
      dataRecord: '=axDataRecord',
      phones: '=axPhones',
      fields: '=axFields',
      campaignId: '=axCampaignId',
      recordId: '=axRecordId',
      callSessionId: '=axCallSessionId',
      userId: '=axUserId',
      alwaysShowHistory: '=axAlwaysShowHistory',
      allowAddNewRecord: '=axAllowAddNewRecord',
      enableEditPhones: '=axEnableEditPhones',
      enableEditFields: '=axEnableEditFields',
      showOnlyHistory: '=axShowOnlyHistory',
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', '$rootScope', controller]
  }
}

function controller(helpers, $rootScope) {
  var vm = this;
  vm.dateTimeFormat = "YYYY-MM-DD HH:mm";
  vm.dateFormat = "YYYY-MM-DD";
  vm.timeFormat = "HH:mm";
  vm.popupHistoryIsOpen = false;

  vm.helpers = helpers;
  vm.isTrue = helpers.isTrue;

  vm.hasNewRecord = false;
  vm.createNewRecord = function () {
    if (vm.hasNewRecord == false && vm.isTrue(vm.allowAddNewRecord) === true && vm.campaignId >= 0 && !vm.dataRecord) {
      vm.hasNewRecord = true;
      var ani = "";
      if (vm.inter && vm.inter.additionalParams && vm.inter.additionalParams.value) {
        ani = vm.inter.additionalParams.value.Ani;
      }
      vm.helpers.createNewRecord(vm.userId, vm.campaignId, vm.callSessionId, null, ani, function (res) {
        $rootScope.$apply(function () {
          if (res && res.value && res.value.Record && vm.inter) {
            vm.dataRecord = res.value.Record;
            vm.recordId = vm.dataRecord.Id;
            vm.inter.prepareDataFieldsEdit(vm.dataRecord);
            vm.helpers.sendSetDataRecordAction(vm.dataRecord);
          }
          else {
            //todo filed - nie wiem o co chodziło
            vm.hasNewRecord = false;
          }
        });
      });
    }

  };

  vm.historyGridColumns = ['Date', 'User', 'SessionSubType', 'Duration', 'Result', 'IntermediateResult', 'CreateCause', 'CampaignRecordState', 'InitiationResult', 'Dnis', 'Note', 'SerialSessionId', 'SerialConnectionId', 'SerialScriptId'];
  vm.historyGridSort = { predicate: "[0]", reverse: true };
  vm.recordHistory = null;
  vm.historyGridSorting = function (col) {
    if (vm.recordHistory) {
      var index = vm.recordHistory.Columns.indexOf(col);
      if (index > -1) {
        vm.historyGridSort.predicate = "[" + index + "]";
        vm.historyGridSort.reverse = !vm.historyGridSort.reverse;
      }
    }
  };
  vm.historyGridCellValue = function (val, col) {
    if (!val)
      return "";
    switch (col) {
      case "Date":
        return moment(val).format(vm.dateTimeFormat);
      case "SessionSubType":
        return vm.li18n.axSessionSubtype[val];
      case "Duration":
        return moment(val).format(vm.timeFormat);
      case "CreateCause":
        return vm.li18n.axCallEventCause[val];
      case "CampaignRecordState":
        return vm.li18n.axDataContactRecordState[val];
      case "InitiationResult":
        return vm.li18n.axSessionInitiationResult[val];
      default:
        return val;
    }
  }
  vm.showHistory = function () {
    if (vm.isTrue(vm.showOnlyHistory) == true || vm.isTrue(vm.alwaysShowHistory) == true) {
      return true;
    }
    return false;
  };
  vm.showPopupHistoryButton = function () {
    if (vm.isTrue(vm.alwaysShowHistory) === false && vm.isTrue(vm.hasNewRecord) === false && vm.isTrue(vm.allowAddNewRecord) === false && !!vm.dataRecord) {
      return true;
    }
    return false;
  };

  vm.isFieldType = function (field, type) {
    if (field && field.DataField) {
      switch (type) {
        case "ComboBox":
          if (field.ValidationValues && field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.Text) {
            return true;
          }
          break;
        case "Text":
          if (!field.ValidationValues && field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.Text) {
            return true;
          }
          break;
        case "Number":
          if (field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.Number) {
            return true;
          }
          break;
        case "FloatNumber":
          if (field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.FloatNumber) {
            return true;
          }
          break;
        case "Boolean":
          if (field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.Boolean) {
            return true;
          }
          break;
        case "DateTime":
          if (field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.DateTime) {
            return true;
          }
          break;
        case "TimeSpan":
          if (field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.TimeSpan) {
            return true;
          }
          break;
        case "PhoneNumber":
          if (field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.PhoneNumber) {
            return true;
          }
          break;
        case "Email":
          if (field.DataField.FieldType === ax.SessionLib.AxDataContactFieldType.Email) {
            return true;
          }
          break;
      }
    }
    return false;
  };
  vm.phoneStateColor = helpers.phoneStateColor;
  vm.getDateTime = function (date) {
    if (date) {
      return new moment(date - 62135604000000).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  };

  vm.editPhone = function (phone) {
    if (phone && vm.isTrue(phone.IsClosed) === false && vm.isTrue(phone.IsEditing) === false && phone.DataField && vm.isTrue(phone.DataField.Editable)) {
      phone.IsEditing = true;
    }
  };
  vm.savePhone = function (phone) {
    if (phone && vm.isTrue(phone.IsClosed) === false && vm.isTrue(phone.IsEditing) && phone.DataField && vm.isTrue(phone.DataField.Editable)) {
      phone.Value = phone.TempValue;
      phone.IsEditing = false;
      vm.helpers.modifyDataRecordPhone(vm.userId);
    }
  };
  vm.cancelEditPhone = function (phone) {
    if (phone) {
      phone.IsEditing = false;
      phone.Value = phone.TempValue = phone.OldValue;
    }
  };
  vm.closePhone = function (phone, message) {
    var result = confirm(message);
    if (result === false) {
      return;
    }
    var temp = phone.TempValue;
    phone.Value = phone.TempValue = phone.OldValue;
    phone.IsClosed = true;
    phone.CloseSet = true;
    phone.IsEditing = false;
    vm.helpers.modifyDataRecordPhone(vm.userId, function (res) {
      if (res !== ax.SessionLib.AxModifyDataRecordPhoneResult.Success) {
        phone.Value = temp;
        phone.IsClosed = false;
      }
      phone.CloseSet = false;
    });

  };

  vm.editField = function (field) {
    if (field && vm.isTrue(field.IsClosed) === false && vm.isTrue(field.IsEditing) === false && field.DataField && vm.isTrue(field.DataField.Editable)) {
      field.IsEditing = true;
    }
  };
  vm.saveField = function (field) {
    if (field && vm.isTrue(field.IsClosed) === false && vm.isTrue(field.IsEditing) && field.DataField && vm.isTrue(field.DataField.Editable)) {
      field.Value = field.TempValue;
      field.IsEditing = false;
      vm.helpers.modifyDataRecord(vm.userId);
    }
  };
  vm.cancelEditField = function (field) {
    if (field) {
      field.IsEditing = false;
      field.Value = field.TempValue = field.OldValue;
    }
  };

  vm.getRecordHistory = function (callback) {
    if (vm.recordHistory) {
      if (callback) {
        callback();
      }
      return;
    }
    vm.isLoading = true;
    helpers.getRecordHistory(vm.userId, vm.campaignId, vm.recordId, function (result) {
      vm.isLoading = false;
      if (result && result.RecordHistory) {
        vm.recordHistory = result.RecordHistory.value;
      }
      else {
        vm.recordHistory = null;
      }
      if (callback) {
        callback();
      }
    });
  };
  vm.showPopupHistory = function () {

  };

  if (vm.phones) {
    for (var i = 0; i < vm.phones.length; i++) {
      var phone = vm.phones[i].value;
      if (phone.DataField && vm.isTrue(phone.DataField.Editable)) {
        phone.IsEditing = vm.isTrue(vm.enableEditPhones);
      }
    }
  }
  if (vm.fields) {
    for (var i = 0; i < vm.fields.length; i++) {
      var field = vm.fields[i].value;
      if (field.DataField && vm.isTrue(field.DataField.Editable)) {
        field.IsEditing = vm.isTrue(vm.enableEditFields);
      }
    }
  }
  if (vm.isTrue(vm.alwaysShowHistory) === true
    && vm.hasNewRecord === false
    && vm.isLoading !== true) {
    vm.getRecordHistory(function () {
      if (vm.onlyHistory) {
        vm.onlyHistory.find(".spinloader").hide();
      }
    });
  }
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.popupHistoryOpenHide = function (e) {
    $scope.$apply(function () {
      if ($scope.vm.popupHistoryIsOpen === true) {
        $scope.vm.popupHistoryHide(e);
      }
      else {
        $scope.vm.popupHistory.appendTo("body");
        $scope.vm.popupHistoryShow();
        $scope.vm.popupHistoryIsOpen = true;
        $scope.vm.getRecordHistory(function () {
          $scope.vm.tableHistory.appendTo($scope.vm.popupHistory);
          $scope.vm.popupHistory.find(".spinloader").hide();
        });
      }
    });
    return false;
  };
  $scope.vm.popupHistoryShow = function () {
    $scope.vm.popupHistory.find(".spinloader").show();
    var offset = $scope.vm.popupHistoryImg.offset();
    var outerWidth = $scope.vm.popupHistory.outerWidth();
    var outerHeight = $scope.vm.popupHistory.outerHeight();
    if (offset.top + outerHeight < $("body").outerHeight()) {
      $scope.vm.popupHistory.css({
        top: offset.top,
        left: offset.left - outerWidth
      }).show();//bottom
    }
    else {
      $scope.vm.popupHistory.css({
        top: offset.top - outerHeight,
        left: offset.left - outerWidth
      }).show();//top
    }
  };
  $scope.vm.popupHistoryHide = function (e) {
    var target = $(e.target || e.srcElement);
    if (target.closest($scope.vm.popupHistory).length === 0) {
      if ($scope.vm.popupHistoryIsOpen === true) {
        $scope.vm.tableHistory.appendTo($scope.vm.onlyHistory);
        $scope.vm.popupHistory.hide();
        $scope.vm.popupHistoryIsOpen = false;
      }
    }
  };
  $scope.vm.tableHistory = $element.find(".record-history");
  $scope.vm.popupHistory = $element.find(".popup-record-history");
  $scope.vm.onlyHistory = $element.find(".only-record-history");
  $scope.vm.popupHistoryImg = $element.find(".record-history-image").on("click", $scope.vm.popupHistoryOpenHide);

  $(document).on("mousedown", $scope.vm.popupHistoryHide);
  $(window).on('resize', $scope.vm.popupHistoryHide);

  $scope.$on("$destroy", function (event) {
    $scope.vm.popupHistoryImg.off("click", $scope.vm.popupHistoryOpenHide);
    $(document).off("mousedown", $scope.vm.popupHistoryHide);
    $(window).off('resize', $scope.vm.popupHistoryHide);
    $scope.vm.popupHistory.remove();
  });

  if ($scope.vm.isLoading === true) {
    $scope.vm.onlyHistory.find(".spinloader").show();
  }
}

export { AxDataRecordEdit };
