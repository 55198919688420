export function isSoundPlaying(state) {
  return state.sound.isPlaying;
}

export function SoundReducers(
  SOUND_PLAY_START,
  SOUND_PLAY_END
) {
  this.reducer = function (state, action) {
    state = state || {};

    switch (action.type) {
      case SOUND_PLAY_START:
        return Object.assign({}, state, {
          isPlaying: true
        });
      case SOUND_PLAY_END:
        return Object.assign({}, state, {
          isPlaying: false
        });
      default:
        return state;
    }
  }
}
