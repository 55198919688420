import template from './AxDataRecordsListView.html';
import "./axDataRecordListView.css";

function AxDataRecordsListView() {
  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      inter: "=axInteractionItem",
      name: "=axName",
      userId: '=axUserId',
      callSessionId: '=axCallSessionId',
      campaignId: "=axCampaignId",
      searchPattern: "=axSearchPattern"
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', '$rootScope', controller]
  }
}

function controller(helpers, $rootScope) {
  var vm = this;
  vm.newDataRecord = null;
  vm.newDataRecordId = null;
  vm.phones = null;
  vm.fields = null;
  vm.selectedRowId = null;
  vm.records = null;
  vm.helpers = helpers;
  vm.showLoader = function (show) {
    if (vm.loader) {
      if (show === true || show === 1) {
        vm.loader.show();
      }
      else {
        vm.loader.hide();
      }
    }
  }
  vm.recordsSort = { predicate: "[0]", reverse: false };
  vm.createDt = function (records) {
    if (records && records.length > 0) {
      var dt = {
        Columns: ["Id", vm.li18n.messages.recordState],
        Rows: []
      };
      var dtColIndex = 2;
      var phoneNumber = ax.SessionLib.AxDataContactFieldType.PhoneNumber;
      var system = ax.SessionLib.AxDataContactFieldCategory.System;
      for (var i = 0; i < records.length; i++) {
        var rec = records[i].value;
        if (i === 0) {
          for (var p = 0; p < rec.ContactPhones.length; p++) {
            var cp = rec.ContactPhones[p].value;
            dt.Columns[dtColIndex++] = cp.DisplayName || cp.PhoneName;
          }
          for (var p = 0; p < rec.DataFields.length; p++) {
            var df = rec.DataFields[p].value;
            if (df.Visible === true && df.FieldType !== phoneNumber && df.Category !== system) {
              dt.Columns[dtColIndex++] = df.DisplayName || df.Name;
            }
          }
        }
        var nr = [];
        var idx = 0;
        nr[idx++] = rec.Id;
        nr[idx++] = vm.li18n.axDataContactRecordState[rec.RecordState];
        for (var p = 0; p < rec.ContactPhones.length; p++) {
          var cp = rec.ContactPhones[p].value;
          nr[idx++] = cp.Number;
        }
        for (var p = 0; p < rec.DataFields.length; p++) {
          var df = rec.DataFields[p].value;
          if (df.Visible === true && df.FieldType !== phoneNumber && df.Category !== system) {
            nr[idx++] = df.Value;
          }
        }
        dt.Rows.push(nr);
      }
      return dt;
    }
    return null;
  };
  vm.fetchRecords = function () {
    if (vm.fetchRecordsTimeout) {
      clearTimeout(vm.fetchRecordsTimeout);
    }
    if (vm.userId && vm.campaignId && vm.searchPattern) {
      var offset = 0;
      var limit = 100;
      var resultAsDataTable = true;
      vm.fetchRecordsTimeout = setTimeout(function () {
        vm.showLoader(1);
        vm.helpers.findDataRecord(vm.userId, vm.campaignId, vm.searchPattern, offset, limit, resultAsDataTable, function (res) {

          var hasRecords = false;
          if (res && res.Records) {
            hasRecords = true;
            var df = vm.createDt(res.Records);
            $rootScope.$apply(function () {
              vm.records = df;
            });

          }
          else if (res && res.RecordsDataTable) {
            hasRecords = true;
            var df = res.RecordsDataTable.value;
            $rootScope.$apply(function () {
              vm.records = df;
            });
          }

          if (hasRecords === false) {
            // TODO: Disable przycisku
          } else {

            // TODO: Sprawdzenie, czy jest chociaż jedna kolumna z numerem telefonu
            var l = vm.records.length, record;
            for (var i = 0; i < l; i++) {

              record = vm.records[i];


            }
          }

          vm.showLoader();
        });
      }, 500);
    }
  };
  vm.selectRecord = function (record) {
    if ((vm.selectedRowId || vm.selectedRowId === 0) && vm.userId && vm.campaignId) {
      vm.showLoader(1);
      const setSessionParamResults = ax.SessionLib.AxSetSessionParamResult;
      //get full record
      vm.helpers.getDataRecord(vm.userId, vm.campaignId, vm.selectedRowId, function (res) {
        if (res && res.Record && vm.inter && vm.inter.beginSurveyAction) {
          var dr = res.Record;
          //set DataRecordId to session
          vm.helpers.setSessionParam(vm.callSessionId, ax.SessionLib.AxSessionParam.DataRecordId, vm.selectedRowId.toString(), vm.campaignId, function (res) {
            if (res && res.Result === setSessionParamResults.Success) {
              //close datarecord to prevent predictive dialing
              vm.helpers.setSessionParam(vm.callSessionId, ax.SessionLib.AxSessionParam.CloseDataRecord, vm.selectedRowId.toString(), vm.campaignId, function (res) {
                if (res && res.Result === setSessionParamResults.Success) {
                  setTimeout(function () {
                    //load survey
                    vm.inter.beginSurveyAction(dr);
                  }, 100);
                }
                else {
                  //todo: log jakiś
                }
                vm.showLoader();
              });
            }
            else {
              //todo: log jakiś
              vm.showLoader();
            }
          });
        }
        else {
          //todo: log jakiś
          vm.showLoader();
        }
      });
    }
  };
  vm.createNewRecord = function () {
    //userId, campaignId, sessionId, importId, ani
    if (vm.userId && vm.campaignId && vm.callSessionId) {
      //create record
      vm.showLoader(1);
      vm.helpers.createNewRecord(vm.userId, vm.campaignId, vm.callSessionId, null, vm.searchPattern, function (res) {
        if (res && res.value && res.value.Record && vm.inter) {
          vm.newDataRecord = res.value.Record;
          vm.newDataRecordId = vm.newDataRecord.Id;
          vm.inter.prepareDataFieldsEdit(vm.newDataRecord);
        }
        else {
          //todo: log jakiś
        }
        vm.showLoader();
      });
    }
  };
  vm.saveCreatedNewRecord = function () {

    if (vm.newDataRecord && vm.userId && vm.campaignId && vm.newDataRecordId) {
      vm.showLoader(1);

      const setDataFieldValueByName = (name, value, dataRecord) => {
        if (dataRecord && dataRecord.DataFields) {
          for (var i = 0; i < dataRecord.DataFields.length; i++) {
            if (dataRecord.DataFields[i].Name === name) {
              dataRecord.DataFields[i].Value = value;
            }
          }
        }
      };

      const setPhoneValueByName = (name, value, dataRecord) => {
        if (dataRecord && dataRecord.DataFields) {
          for (var i = 0; i < dataRecord.ContactPhones.length; i++) {
            const phone = dataRecord.ContactPhones[i];
            if (phone.PhoneName === name) {
              phone.Number = value;
            }
          }
        }
      };

      // Set phone values to current data record
      vm.inter.phoneDataFieldsEditCollection.forEach(function (p) {
        var value = p.value.Value || p.value.TempValue;
        setPhoneValueByName(p.value.Name, value, vm.newDataRecord);
      });

      // Set field values to current data record
      vm.inter.dataFieldsEditCollection.forEach(function (p) {
        const value = p.value.Value || p.value.TempValue;
        setDataFieldValueByName(p.value.Name, value, vm.newDataRecord);
      });

      //save edit phones
      vm.helpers.modifyDataRecordPhone(vm.userId, function (res) {

        if (res === ax.SessionLib.AxModifyDataRecordPhoneResult.Success ) {

          //save edit fields
          vm.helpers.modifyDataRecord(vm.userId, function (res) {

            if (res === ax.SessionLib.AxModifyDataRecordResult.Success && vm.inter && vm.inter.beginSurveyAction) {

              //set DataRecordId to session
              vm.helpers.setSessionParam(vm.callSessionId, ax.SessionLib.AxSessionParam.DataRecordId, vm.newDataRecordId.toString(), vm.campaignId, function (res) {
                if (res && res.Result === ax.SessionLib.AxSetSessionParamResult.Success) {
                  //close datarecord to prevent predictive dialing
                  vm.helpers.setSessionParam(vm.callSessionId, ax.SessionLib.AxSessionParam.CloseDataRecord, vm.newDataRecordId.toString(), vm.campaignId, function (res) {
                    if (res && res.Result === ax.SessionLib.AxSetSessionParamResult.Success) {
                      // Po co jest ten timeout???
                      setTimeout(function() {
                        //load survey
                        vm.inter.beginSurveyAction(vm.newDataRecord);
                        vm.newDataRecord = null;
                        vm.newDataRecordId = null;
                      }, 100);
                    } else {
                      //todo: log jakiś
                    }
                    vm.showLoader();
                  });
                }
                else {
                  //todo: log jakiś
                  vm.showLoader();
                }
              });
            } else {
              //todo: log jakiś
              vm.showLoader();
            }
          });
        } else {
          console.error("Failed to modify data record");
          vm.showLoader();
        }
      });
    }
  };

  vm.cancelNewRecord = function () {
    if (vm.newDataRecord && vm.inter && vm.userId && vm.campaignId && vm.newDataRecordId) {
      vm.showLoader(1);
      //cancel ne record and delete
      vm.helpers.deleteDataRecord(vm.userId, vm.campaignId, vm.newDataRecordId, function (res) {
        if (res && res.Success === true) {
          vm.inter.clearDataFieldsEdit();
          vm.newDataRecord = null;
          vm.newDataRecordId = null;
        }
        else {
          //todo: log jakiś
        }
        vm.showLoader();
      });
    }
  };
}

function link($scope, $element, $attrs, $ctrl) {

  $scope.vm.loader = $element.find(".spinloader");

  $scope.$watchGroup(['vm.userId', 'vm.campaignId', 'vm.searchPattern'], function () {

    // Only if element is visible
    //if ($element.hasClass('ng-hide') === false ) {
    $scope.vm.fetchRecords();
    //}
  });
}

export { AxDataRecordsListView };
