import angular from 'angular';
import { combineReducers } from 'redux';
import { getSettings, SettingsReducers } from "./settingsReducers";
import { isSoundPlaying, SoundReducers } from "./soundReducers";
import { getSoundSettings, SoundSettingsReducers } from "./soundSettingsReducers";

// todo deps
export default angular.module('Redux.Reducers', ['Redux.Actions'])
  .service('RootReducers', [
    'SettingsReducers',
    'SoundReducers',
    RootReducers
  ])
  .service('SettingsReducers', [
    'SETTINGS_LOAD_SUCCESS',
    'SoundSettingsReducers',
    SettingsReducers
  ]).constant('getSettings', getSettings)
  .service('SoundReducers', [
    'SOUND_PLAY_START',
    'SOUND_PLAY_END',
    SoundReducers
  ])
  .constant('isSoundPlaying', isSoundPlaying)
  .service('SoundSettingsReducers', [
    'SETTINGS_SOUND_ENABLE',
    'SETTINGS_SOUND_DISABLE',
    'SETTINGS_SOUND_TOGGLE',
    SoundSettingsReducers
  ])
  .constant('getSoundSettings', getSoundSettings)
  .name;

function RootReducers(SettingsReducers, SoundReducers) {
  this.reducer = combineReducers({
    settings: SettingsReducers.reducer,
    sound: SoundReducers.reducer
  });
}
