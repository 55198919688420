export function SmsCtrl($scope, Data, Sender, SystemVariables) {
  var vm = this;

  vm.withDialpad = true;
  vm.from = '';
  vm.data = Data;
  vm.phoneNumber = '';
  vm.message = '';
  vm.accountName = SystemVariables.SmsPadAccount !== undefined ? SystemVariables.SmsPadAccount.Value : undefined;
  vm.onSendClick = sendSms;
  // TODO: Parametrize this
  vm.maxSms = 4;
  vm.sv = SystemVariables;

  // TODO
  // change when move to different model
  // f.e. redux, observable etc...
  $scope.$watch('data.lang', function (newVal) {
    if (newVal) {
      vm.lang = newVal;
    }
  });

  $scope.$watch(function () {
    return vm.sv.SmsPadAccount;
  }, function (newVal) {
    if (newVal && "Value" in newVal) {
      vm.accountName = newVal.Value;
    }
  });

  function sendSms(from, to, message, accountName) {

    return Sender.sendSms(from, to, null, null, message, accountName)
      .then(function (res) {
        console.log('Msg send', res);
      });
  }
}
