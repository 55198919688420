import template from './AxTimePicker.html';

function AxTimePicker() {

  return {
    restrict: 'AEC',
    require: 'ngModel',
    scope: {
      li18n: '=axI18n',
      name: '=axName',
      editValue: '=ngModel',
      allowNullValues: '=axAllowNullValues',
      isValid: '=axIsValid',
      invalidMessage: '=axInvalidMessage',
      isReadonly: '=ngReadonly',
      dblclick: '&axDblclick'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {
  var vm = this;
  vm.dateFormat = "HH:mm";
  vm.timeMask = "00:00";
  vm.clickdblclick = function () {
    if (vm.dblclick) {
      vm.dblclick();
    }
    vm.input.blur();
    setTimeout(function () {
      vm.input.focus();
    }, 1);
  };
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.input = $element.find("input[data-input-type=time]").on('change', function () {
    $scope.$apply(function () {
      $scope.vm.editValue = $scope.vm.input.val();
    });
  });
  $scope.vm.timeImg = $element.find(".clock-png");
  $scope.vm.input.clockpicker({
    trigger: $scope.vm.timeImg[0],
    align: 'right',
    autoclose: true,
    'default': $scope.vm.editValue,
    afterShow: function (th) {
      $scope.vm.popover = $(th.popover);
      var offset = $scope.vm.input.offset();
      if (offset.top - $scope.vm.popover.outerHeight() < 0) {
        $scope.vm.popover.css("top", offset.top + $scope.vm.input.outerHeight());//bottom
      } else {
        $scope.vm.popover.removeClass("bottom").addClass("top").css("top", offset.top - $scope.vm.popover.outerHeight());//top
      }
    }
  });

  $scope.vm.input.mask($scope.vm.timeMask);
  setTimeout(function () {
    $scope.vm.input.keydown().keyup();
  }, 100);


  $scope.$on("$destroy", function (event) {
    $scope.vm.input.clockpicker("remove");
    if ($scope.vm.popover) {
      $scope.vm.popover.remove();
    }
  });
}

export { AxTimePicker };
