import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { Player } from './Player';
import {
  FullScreenButton, MuteAudioButton, MuteVideoButton
} from './controls';
import {
  fullScreenChange, goFullScreen, unFullScreen
} from './utils';

export interface RTCPlayerProps {
  externalSrc: MediaStream;
  internalSrc: MediaStream;
  isAudioMuted?: boolean;
  isVideoMuted?: boolean;
  onFullScreen?: () => void;
  onMuteAudio?: () => void;
  onMuteVideo?: () => void;
  volume?: number;
}

export interface RTCPlayerState {
  internal: boolean;
  isFullScreen: boolean;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  volume: number;
}

export class RTCPlayer extends React.Component<RTCPlayerProps, RTCPlayerState> {
  externalPlayer: Player = null;
  internalPlayer: Player = null;

  constructor(props: RTCPlayerProps) {
    super(props);

    const {
      isAudioMuted = false,
      isVideoMuted = false,
      volume = 1
    } = this.props;

    this.state = {
      internal: false,
      isFullScreen: false,
      isAudioMuted,
      isVideoMuted,
      volume
    };
  }

  componentDidMount() {
    fullScreenChange('add', this.onFullScreenChanged);
  }

  componentWillReceiveProps(nextProps: any) {
    const { isAudioMuted, isVideoMuted } = nextProps;

    if (isAudioMuted !== this.state.isAudioMuted) {
      this.setState({ isAudioMuted });
    }

    if (isVideoMuted !== this.state.isVideoMuted) {
      this.setState({ isVideoMuted });
    }
  }

  componentWillUnmount() {
    fullScreenChange('remove', this.onFullScreenChanged);
  }

  onFullScreenChanged = () => {
    const { onFullScreen } = this.props;
    const { isFullScreen } = this.state;

    this.setState({ isFullScreen: !isFullScreen });

    if (onFullScreen) {
      onFullScreen();
    }
  };

  goFullScreen = () => {
    const { internal, isFullScreen } = this.state;
    const element = findDOMNode(
      internal
        ? this.internalPlayer
        : this.externalPlayer
    );

    if (!isFullScreen && element) {
      element[goFullScreen]();
    }
  };

  unFullScreen = () => {
    const { internal, isFullScreen } = this.state;
    const element = findDOMNode(
      internal
        ? this.internalPlayer
        : this.externalPlayer
    );

    if (isFullScreen && element) {
      element[unFullScreen]();
    }
  };

  muteAudio = () => {
    const { onMuteAudio } = this.props;
    const { isAudioMuted } = this.state;

    this.setState({ isAudioMuted: !isAudioMuted });

    if (onMuteAudio) {
      onMuteAudio();
    }
  };

  muteVideo = () => {
    const { onMuteVideo } = this.props;
    const { isVideoMuted } = this.state;

    this.setState({ isVideoMuted: !isVideoMuted });

    if (onMuteVideo) {
      onMuteVideo();
    }
  };

  onPlayerClick = () => {
    const { internal } = this.state;
    this.setState({ internal: !internal });
  };

  setExternalPlayer = (player: Player) => {
    this.externalPlayer = player;
  };

  setInternalPlayer = (player: Player) => {
    this.internalPlayer = player;
  };

  render() {
    const { externalSrc, internalSrc } = this.props;
    const { internal } = this.state;

    const fullScreen = {
      isFullScreen: this.state.isFullScreen,
      goFullScreen: this.goFullScreen,
      unFullScreen: this.unFullScreen
    };

    const muteAudio = {
      isMuted: this.state.isAudioMuted,
      mute: this.muteAudio
    };

    const muteVideo = {
      isMuted: this.state.isVideoMuted,
      mute: this.muteVideo
    };

    const fst: any = { className: 'player ' + (internal ? 'min' : 'max') };
    const snd: any = { className: 'player ' + (internal ? 'max' : 'min') };

    if (internal) {
      fst.onDoubleClick = this.onPlayerClick;
    } else {
      snd.onDoubleClick = this.onPlayerClick;
    }

    return (
      <div className="player-wrapper">

        <Player
          {...fst} ref={this.setExternalPlayer} src={externalSrc}
          playing>
        </Player>

        <Player
          {...snd} ref={this.setInternalPlayer} src={internalSrc}
          playing muted>
        </Player>

        <div className="player-controls">
          <FullScreenButton media={fullScreen}/>
          <MuteAudioButton media={muteAudio}/>
          <MuteVideoButton media={muteVideo}/>
        </div>
      </div>
    );
  }
}
