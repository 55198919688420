import * as React from 'react';

export const CameraSlashIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="currentColor">
      <g>
        <path
          d="M19 4.5L15 8.5 15 5C15 4.5 14.6 4 14 4L7.8 4 19 15.2 19 4.5 19 4.5ZM1.3 0L0 1.3 2.7 4 2 4C1.4 4 1 4.5 1 5L1 15C1 15.5 1.4 16 2 16L14 16C14.2 16 14.4 15.9 14.5 15.8L17.7 19 19 17.7 1.3 0 1.3 0Z"
        />
      </g>
    </svg>
  );
};
