import isFunction from 'lodash-es/isFunction';
import template from './AxSessionChat.html';

function AxSessionChat() {
  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      // Array of AxChatMessageDataElement
      messages: '=axMessages',
      senderName: '=axSenderName',
      sessionId: '=axSessionId',
      // This flag can block possibility of sending messages (session ended etc)
      allowSend: '=axAllowSend',
      sessionInfo: '=axSessionInfo',
      session: '=axSession',
      toggleLocalAudioHandler: '=axOnToggleLocalAudioClick',
      toggleLocalVideoHandler: '=axOnToggleLocalVideoClick',
      typingData: '=axTypingData',
      replacePhoneNumbers: '=axReplacePhoneNumbers'
    },
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['$scope', 'AxComponentsHelperService', 'UtilsService', 'VideoData', controller]
  }

}

function controller($scope, AxComponentsHelperService, UtilsService, VideoData) {

  var vm = this, typingMessageIndex = -1;
  var zeroPad = UtilsService.zeroPad;

  var chatAnswerAddedToInputListener = $scope.$on(
    'chatAnswerAddedToInput', onChatAnswerAddedToInput);

  var interactionEnterListener = $scope.$on(
    "interactionEnter", onDisplayChanged);

  var sessionTabsSplitChangedListener = $scope.$on(
    "sessionTabsSplitChanged", onDisplayChanged);

  var layoutResizeListener = $scope.$on(
    "layoutResize", onDisplayChanged);

  vm.msgToSend = '';
  vm.placeholder = vm.li18n.messages.replyPlaceholder;
  vm.displayChanged = false;
  vm.VideoData = VideoData;

  // Manually initialize component
  // See below
  onInit();

  // Why $onInit and $onDestroy?
  // https://docs.angularjs.org/guide/component
  function onInit() {
    vm.hashHintData = prepareHashHintData(vm.session.data.chatAnswersTags);
    $scope.$watch('vm.typingData', onTypingDataChanged);
    $scope.$on('$destroy', $onDestroy);

  }

  /**
   * Sends chat message
   * @param $event
   */
  vm.send = function ($event) {
    if (!$event || (($event.key == "Enter" || $event.keyCode == 13) && !$event.shiftKey)) {

      if ($event) {
        $event.preventDefault();
      }

      var msgToSend = vm.msgToSend;

      // Not sent empty message
      if (vm.canSend() === false) {
        return;
      }

      var msgItem = new ax.SessionLib.Collections.Chat.AxChatMessageDataElement();
      msgItem.__from = "agent";
      msgItem.Message = msgToSend;

      msgItem.SentOn = getSentOn();
      msgItem.SenderType = ax.AxCommonLib.AxObjectType.User;
      msgItem.SenderFriendlyName = vm.senderName;
      msgItem.ChatMessageId = UUID();

      vm.messages.push(msgItem);

      vm.msgToSend = "";

      AxComponentsHelperService.sendSessionChatResponseMessage({
        project: { Id: null, Name: null },
        sessionId: vm.sessionId,
        message: msgToSend
      }).then(function (res) {
      }, function (err) {
        console.error("Can't send chat message", err);
      });
    }
  };

  vm.onValueChange = function (value) {
    vm.msgToSend = value;
  };

  vm.openFileSearch = function () {
    $scope.$emit("openFileSearchRequest");
  };

  vm.openFolderSearch = function (msg) {
    $scope.$emit("openFolderSearchRequest", {
      fileName: msg.fileName,
      fileSize: msg.fileSize,
      fileUrl: msg.fileUrl,
      fileId: msg.fileId,
      modalId: "folderSearch"
    });
  };

  vm.downloadFile = function (msg) {
    $scope.$emit("downloadFileRequest", {
      fileUrl: msg.fileUrl,
      fileId: msg.fileId,
      fileName: msg.fileName,
      directLink: msg.directLink,
      fileType: msg.fileType
    });
  };

  vm.canSend = function () {
    return vm.msgToSend.trim().length > 0 && vm.allowSend === true;
  };

  vm.canOpenFileSearch = function () {
    return vm.allowSend === true && vm.session.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat || vm.session.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundSocial;
  };

  vm.onMuteAudio = function () {
    if (isFunction(vm.toggleLocalAudioHandler)) {
      vm.toggleLocalAudioHandler();
    }
  };

  vm.onMuteVideo = function () {
    if (isFunction(vm.toggleLocalVideoHandler)) {
      vm.toggleLocalVideoHandler();
    }
  };

  // Helpers
  function getSenderFriendlyName(session) {
    return session && session.session && session.session.Session &&
      session.session.Session.SessionInfoRemoteSideFriendlyName || '';
  }

  function prepareHashHintData(data) {
    if (!Array.isArray(data)) {
      return {};
    }

    return data.reduce(function (result, item) {
      result[item.Tag] = [];
      item.Answers.forEach(function (hint) {
        result[item.Tag].push(hint.Message);
      });
      return result;
    }, {});
  }

  // TODO move to utils
  function getSentOn(dt) {
    dt = dt || new Date();
    //return dt.getTime() + 62135604000000; // Because we need TimeSpan
    //return dt.getTime() + 62135596800000; // Because we need TimeSpan
    return dt.getFullYear() + '-' + zeroPad(dt.getMonth() + 1) + '-' +
      zeroPad(dt.getDate()) + ' ' + zeroPad(dt.getHours()) + ':' +
      zeroPad(dt.getMinutes()) + ':' + zeroPad(dt.getSeconds())
  }

  // Listeners
  function onTypingDataChanged(data) {
    var isTyping = data.isTyping, text = data.text;

    if (isTyping && typingMessageIndex === -1) {

      var message = {
        __from: 'client', __messageType: 'typing',
        ChatMessageId: UUID(),
        SenderFriendlyName: getSenderFriendlyName(vm.session),
        Message: text
      };

      typingMessageIndex = vm.messages.push(message) - 1;
      return;
    }

    if (!isTyping && typingMessageIndex !== -1 && !text) {
      vm.messages.splice(typingMessageIndex, 1);
      typingMessageIndex = -1;
      return;
    }

    if (typingMessageIndex !== -1) {
      vm.messages[typingMessageIndex].Message = text;
      return;
    }
  }

  // Listeners
  function onChatAnswerAddedToInput($event, data) {
    if (data.sessionId !== vm.session.session.Session.SessionId) {
      return;
    }

    vm.msgToSend += data.text;
  }

  function onDisplayChanged() {
    vm.displayChanged = true;
  }

  function $onDestroy() {
    chatAnswerAddedToInputListener && chatAnswerAddedToInputListener();
    interactionEnterListener && interactionEnterListener();
    sessionTabsSplitChangedListener && sessionTabsSplitChangedListener();
    layoutResizeListener && layoutResizeListener();
  }
}

export { AxSessionChat };
