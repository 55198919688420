import template from './AxRadioButtonsEdit.html';

function AxRadioButtonsEdit() {

  return {
    restrict: 'AEC',
    require: 'ngModel',
    scope: {
      li18n: '=axI18n',
      name: '=axName',
      editValue: '=ngModel',
      allowNullValues: '=axAllowNullValues',
      mandatoryBacklight: "=axMandatoryBacklight",
      isValid: '=axIsValid',
      invalidMessage: '=axInvalidMessage',
      isReadonly: '=ngReadonly',
      dblclick: '&axDblclick',
      orientation: "=axOrientation",
      items: '=axItems',
      dispalayMember: '@axDispalayMember',
      valueMember: '@axValueMember',
      onSetValue: '=axOnSetValue',
      labelStyle: '=axLabelStyle',
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {
  var vm = this;
  vm.mandatoryBacklight = vm.mandatoryBacklight || 1;
  vm.helpers = helpers;
  vm.clickdblclick = function () {
    if (vm.dblclick) {
      vm.dblclick();
    }
    vm.input.blur();
    setTimeout(function () {
      vm.input.focus();
    }, 1);
  };
  vm.onValueSet = function () {
    if (vm.onSetValue) {
      vm.onSetValue(vm.editValue);
    }
  };
}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxRadioButtonsEdit };
