import template from './AxHostMenuInteractions.html';

function InteractionItemCtrl($scope, SessionClient, Data) {

  var that = this,
    timer;
  that.userId = null;
  that.inter = null;
  that.interactions = [];
  that.maxWaitingTime = that.progressValue = 0;
  that.acceptInProgress = false;
  that.accepted = false;

  that.isAcceptInProgress = function () {

    return that.acceptInProgress || that.accepted;

  };

  that.acceptSession = function (inter) {

    // Emitujemy event, żeby aplikacja mogła np schować pozostałe sesje
    $scope.$emit('acceptInteractionClick', {
      item: inter
    });

    that.acceptInProgress = true;
    Data.acceptSessionInProgress = inter.session.Session.SessionId;

    SessionClient.acceptSession({
      sessionId: inter.session.Session.SessionId,
      userId: that.userId
    }).then(function (res) {

      that.acceptInProgress = false;
      that.accepted = true;

    }).catch(function (err) {

      that.acceptInProgress = false;
      Data.acceptSessionInProgress = null;
      console.error("Error accepting session", err);
      // TODO: Emit error i w apce globalny handler na errory pokazujący jakoś ładnie błędy
      // niekonieczenie znikające w pasku na dole, powinien być też jakiś log tych błędów dostępny z GUI

    });

  };

  $scope.$watch("iItemCtrl.inter", function (newVal, oldVal) {

    if (newVal) {

      var acceptAction = findAcceptAction(that.inter);

      if (acceptAction !== null) {

        if (acceptAction.TimeoutSeconds > 0) {

          that.maxWaitingTime = that.progressValue = acceptAction.TimeoutSeconds;

          timer = setInterval(function () {

            if (that.progressValue > 0) {
              that.progressValue = that.progressValue - 1;
            } else {
              $scope.$emit('rejectInteractionClick', {
                item: that.inter
              });
              clearInterval(timer);
            }

          }, 1000);
        }

      }

    }

  });

}

/**
 * Main AxHostMenuInteractions directive DOD (Directive Object Declaration)
 */
function AxHostMenuInteractions() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      currentInteraction: '=axCurrentInteraction',
      interactions: '=axInteractions',
      defaultMenuState: '=axDefaultMenuState', // true - opened, false - closed
      userId: '=axUserId'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ["$scope", "SessionClient", "VideoService", controller]
  }
}

function controller($scope, SessionClient, VideoService) {

  var vm = this;

  vm.activeInteraction = null;

  vm.menuClass = null;

  vm.toggleMenu = function () {

    vm.menuOpened = !vm.menuOpened;

  };

  vm.onInteractionClick = function (inter) {

    var acceptAction = findAcceptAction(inter);
    var sessionState = inter.session.Session.SessionState;
    if (acceptAction !== null && vm.isStateAcceptable(sessionState)) {
      console.warn("Session must be accepted");
      return;
    }

    if (inter.session)

      var iItem = findMenuItem("interactions", vm.menu);

    if (iItem !== null) {
      vm.activeItem = iItem;
    }
    vm.activeInteraction = inter;
    $scope.$emit('interactionItemClick', {
      item: inter
    });

  };

  vm.interactionListFaClass = function (inter) {

    var currentInter = vm.currentInteraction,
      sessionId = inter.session.Session.SessionId,
      className = "menu-item-icon ",
      subType = inter.session.Session.SubType;

    if (subType === ax.SessionLib.AxSessionSubtype.InboundCall ||
      subType === ax.SessionLib.AxSessionSubtype.PredictiveCall ||
      subType === ax.SessionLib.AxSessionSubtype.PreviewCall ||
      subType === ax.SessionLib.AxSessionSubtype.CallBackCall ||
      subType === ax.SessionLib.AxSessionSubtype.ManualCall) {
      className += "fa fa-headphones";
    } else if (subType === ax.SessionLib.AxSessionSubtype.InboundEmail) {
      className += "fa fa-envelope";
    } else if (subType === ax.SessionLib.AxSessionSubtype.InboundChat) {
      className += "fa fa-comments";
    } else if (subType === ax.SessionLib.AxSessionSubtype.InboundSocial) {
      className += "fab fa-facebook-messenger";
    }

    className += " istate_" + inter.session.Session.SessionState;

    if (currentInter && sessionId === currentInter.session.Session.SessionId) {
      className += ' active';
    }

    return className;

  };

  vm.hasAttachedInteraction = function (inter) {

    var l = vm.interactions.length, current;
    for (var i = 0; i < l; i++) {

      current = vm.interactions[i];

      if (current.session.Session.AttachToSessionId === inter.session.Session.SessionId) {
        return true;
      }

    }

    return false;

  };

  vm.hasVideoCallActive = function (inter) {

    var associatedVideoInteraction = VideoService.getAssociatedInteraction();

    if (associatedVideoInteraction !== null) {

      return inter.session.Session.SessionId === associatedVideoInteraction.session.Session.SessionId;

    } else {
      return false;
    }

  };

  vm.isStateAcceptable = function (state) {

    var axState = ax.SessionLib.AxSessionState;
    return (state === axState.Queued || state === axState.DirectedToUser)

  };

  vm.isInteractionAcceptable = function (inter) {

    return inter.isStateAcceptable() && inter.isAcceptable();

  };

  vm.rejectSession = function (inter) {

    $scope.$emit('rejectInteractionClick', {
      item: inter
    });

  };

}

function link($scope, $element, $attrs, $ctrl) {

  $scope.$on("activeInteractionChanged", function (event, data) {

    if (data && "interaction" in data) {

      $scope.vm.activeInteraction = data.interaction;

    }

  });

}

function findMenuItem(menuItem, menu) {

  if (menuItem && menu) {

    var l = menu.length,
      current;
    for (var i = 0; i < l; i++) {
      current = menu[i];
      if (current.item === menuItem) {
        return current;
      }
    }

  }

  return null;

}

/**
 * @param {InteractionItem} inter
 * @returns {?ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession}
 */
function findAcceptAction(inter) {

  var actions = inter.session.Actions;
  if (actions === null) {
    return null;
  }
  var retActions = actions.filter(function (action) {

    return action instanceof ax.SessionLib.Sessions.Actions.AxSessionEventAcceptSession;

  });

  if (retActions.length > 0) {
    return retActions[0];
  } else {
    return null;
  }
}

export { AxHostMenuInteractions, InteractionItemCtrl };
