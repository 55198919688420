import template from './AxSurveyFieldRadioButtons.html';

function AxSurveyFieldRadioButtons() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;

  helpers.initField(vm);
  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      if (!val) {
        return "fieldIsRequired";
      }
    }
    return null;
  };
  vm.onValueSet = function (value) {
    vm.axC.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };
  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);

  vm.labelStyle = vm.axControlLabelStyle(vm.field);
  vm.itemLabelStyle = vm.axControlLabelStyle(vm.field);
  vm.itemLabelStyle.minHeight = "20px";
  vm.itemLabelStyle.display = "inline-block";
}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxSurveyFieldRadioButtons };
