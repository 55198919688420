import { combineEpics, ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';

export function SettingsEpics(
  SETTINGS_LOAD_REQUEST,
  loadSettingsSuccess,
  loadSettingsFailure,
  SETTINGS_SAVE_REQUEST,
  saveSettingsSuccess,
  saveSettingsRequest,
  Storage,
  getSettings
) {
  this.epic = combineEpics(
    settingsLoadEpic,
    settingsChangedEpic,
    settingsSaveEpic
  );

  function settingsLoadEpic(action$) {
    return action$.pipe(
      ofType(SETTINGS_LOAD_REQUEST),
      map(function (action) {
        Storage.setPrefix(action.payload.userId);
        var loadedState = Storage.get('settings');

        if (loadedState) {
          return loadSettingsSuccess(loadedState);
        } else {
          return loadSettingsFailure()
        }
      })
    )
  }

  function settingsChangedEpic(action$, store$) {
    return action$.pipe(
      filter(function (action) {
        return action.type.startsWith('SETTINGS') &&
          !action.type.startsWith('SETTINGS_LOAD') &&
          !action.type.startsWith('SETTINGS_SAVE')
      }),
      map(function () {
        return saveSettingsRequest(getSettings(store$.value));
      })
    )
  }

  function settingsSaveEpic(action$) {
    return action$.pipe(
      ofType(SETTINGS_SAVE_REQUEST),
      map(function (action) {
        Storage.set('settings', action.payload);
        return saveSettingsSuccess();
      })
    )
  }
}
