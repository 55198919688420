import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import { AxPreview } from './ax-preview/axPreview';
import { AxPreviewRecordList, AxScroll } from './ax-preview-record-list/axPreviewRecordList';
import FilterBuilderModule from '../filterBuilder';

export default angular
  .module('ax.webagent.preview', [
    ngSanitize,
    FilterBuilderModule
  ])
  .directive('axPreview', AxPreview)
  .directive('axPreviewRecordList', ['AxComponentsHelperService', '$rootScope', '$sce', '$timeout', AxPreviewRecordList])
  .directive("axScroll", AxScroll)
  .name;
