/** @ngInject */
export function AxIsEllipsis($timeout) {
  return {
    restrict: "A",
    scope: {
      isEllipsis: "=axIsEllipsis",
      visible: "=axVisibile"
    },
    link: function ($scope, $element) {
      var el = $element[0];

      if ($scope.visible === true) {

        $timeout(function () {

          if ($scope.isEllipsis !== true) {

            if (el.offsetWidth < el.scrollWidth) {
              $scope.isEllipsis = true;
            } else {
              $scope.isEllipsis = false;
            }
          }
        });

      }

      $scope.$watch("visible", function (newValue) {

        if (newValue === true && typeof $scope.isEllipsis === "undefined") {

          $timeout(function () {

            if (el.offsetWidth < el.scrollWidth) {
              $scope.isEllipsis = true;
            } else {
              $scope.isEllipsis = false;
            }
          });
        }

      });

    }

  }
}
