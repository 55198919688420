import angular from 'angular';
import { SessionChatAnswersCtrl } from './SessionChatAnswersCtrl';
import { SessionTabsCtrl } from './SessionTabsCtrl';
import { ChangePasswordCtrl, ChangePasswordData } from './ChangePasswordCtrl';
import { SmsCtrl } from "./SmsCtrl";

export default angular
  .module('ax.WebCommunications.controllers', [])
  .controller("SessionChatAnswersCtrl", [SessionChatAnswersCtrl])
  .controller("SessionTabsCtrl", ["$scope", "Data", "CONFIG", "SettingsData", SessionTabsCtrl])
  .factory("ChangePasswordData", ChangePasswordData)
  .controller("ChangePasswordCtrl", ["$rootScope", "ComClient", "Data", "ChangePasswordData", "CONFIG", "WL", ChangePasswordCtrl])
  .controller("SmsCtrl", ["$scope", "Data", "Sender", "SystemVariables", SmsCtrl])
  .name;
