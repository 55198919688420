import WebRtcBuddy from '@unifiedfactory/buddy/dist/WebRtcBuddy.umd';

function WebRtcBuddyService($rootScope) {

  this.initialize = initialize;
  this.prepare = prepare;
  this.getAvailableConstraints = getAvailableConstraints;
  this.setRemoteSdp = setRemoteSdp;
  this.close = close;
  this.on = on;
  this.toggleLocalAudio = toggleLocalAudio;
  this.toggleLocalVideo = toggleLocalVideo;

  var buddy;

  /**
   * Creates buddy instance
   * @param {Object} p Parameters object
   * @param p.iceTimeout Ice timeout in milliseconds
   */
  function initialize(p) {

    buddy = new WebRtcBuddy({
      iceTimeout: p.iceTimeout
    });

    buddy.on("remotestreamadded", function (evt) {
      emit(evt.type, evt);
    });

    buddy.on("remotetrackadded", function (evt) {
      emit(evt.type, evt);
    });

    buddy.on("usermediaacquired", function (evt) {
      emit(evt.type, evt);
    });

    buddy.on("remotestreamremoved", function (evt) {
      emit(evt.type, evt);
    });

  }

  /**
   * Direct wrapper for WebRtcBuddy prepare method
   * @param {Object} p Parameters obejct
   */
  function prepare(p) {
    return buddy.prepare(p);
  }

  /**
   * Direct wrapper for WebRtcBuddy getAvailableConstraints method
   */
  function getAvailableConstraints() {
    return buddy.getAvailableConstraints();
  }

  /**
   * Direct wrapper for WebRtcBuddy setRemoteSdp method
   */
  function setRemoteSdp(sdp) {
    return buddy.setRemoteSdp(sdp);
  }

  /**
   * Direct wrapper for WebRtcBuddy close method
   */
  function close() {
    return buddy.close();
  }

  /**
   * Direct wrapper for WebRtcBuddy toggleLocalAudio method
   */
  function toggleLocalAudio() {
    buddy.toggleLocalAudio();
  }

  /**
   * Direct wrapper for WebRtcBuddy toggleLocalVideo method
   */
  function toggleLocalVideo() {
    buddy.toggleLocalVideo();
  }

  /**
   * Register event handler
   * @param {Object} p Parameters object
   * @param {String} p.evtName
   * @param {Object} p.scope Angular $scope from which event handler is registerd (need for cleanup on scope destroy)
   * @param {Function} p.callback Event handler function
   */
  function on(p) {
    var handler = $rootScope.$on(p.evtName, function (evtName, evt) {
      p.callback(evt);
    });
    p.scope.$on("$destroy", handler);
  }

  /**
   * Emits event from service
   * @param evtName
   * @param evt
   */
  function emit(evtName, evt) {

    $rootScope.$emit(evtName, evt);

  }
}

export { WebRtcBuddyService };
