import template from './AxPreview.html';

function AxPreview() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      autoNavigateToInteraction: '=axAutoNavigateToInteraction',
      tabVisible: '=axTabVisible', // If preview tab is visible. Directive need to know moment when is showed, to fix records grid (it only can be fixed when is rendered)
      dialingDisabled: '=axDialingDisabled'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', 'Sender', 'AxComponentsHelperService', 'userPhone', 'sClient', '$scope', '$rootScope', controller]
  }
}

function controller(Helpers, Sender, AxComponentsHelperService, userPhone, sClient, $scope, $rootScope) {
  var vm = this;

  vm.historyGridColumns = ['Date', 'User', 'SessionSubType', 'Duration', 'Result', 'IntermediateResult', 'CreateCause', 'CampaignRecordState', 'InitiationResult', 'Dnis', 'Note', 'SerialSessionId', 'SerialConnectionId', 'SerialScriptId'];
  vm.historyGridSort = { predicate: "[0]", reverse: true };
  vm.recordHistory = null;
  vm.inProgress = false;
  vm.dateTimeFormat = "YYYY-MM-DD HH:mm";
  vm.dateFormat = "YYYY-MM-DD";
  vm.timeFormat = "HH:mm";

  vm.data = {
    selectedCampaign: null,
    model: 1, // ???? WTFIT ????
    fieldsVisible: true,
    historyVisible: false,
    selectedRecord: null,
    lastHistoryFor: null,
    // Filter items indexed by campaignId (filters are defined for campaign)
    filterItems: {},
    receivedRecordsFor: [],
    // Campaign fields collection indexed by campaignId
    campaignFields: {}
  };

  // Becaouse angular throws infdig, when returning always new object
  var campaigns = [];
  Object.defineProperty(vm.data, "campaigns", {
    get: function () {
      if (sClient) {
        return sClient.assignedPreviewCampaigns;
      } else {
        return campaigns;
      }
    }
  });


  // Becaouse angular throws infdig, when returning always new object
  /*var dataFields = [];
  Object.defineProperty(vm.data, "campaignFields", { get : function() {
      if ( sClient ) {
           var records = sClient.fetchedPreviewRecords;

           if ( records.length > 0 ) {
              return records[0].record.DataFields;
           }

       }

      return dataFields;

  }});*/

  // Becaouse angular throws infdig, when returning always new object
  var records = [];
  Object.defineProperty(vm.data, "records", {
    get: function () {

      if (sClient) {
        return sClient.fetchedPreviewRecords;
      } else {
        return records;
      }

    }
  });


  $scope.$watch('vm.data.selectedRecord',
    /**
     * @param {ax.SessionLib.AxPreviewRecordItem} newRecord
     * @param {ax.SessionLib.AxPreviewRecordItem} oldRecord
     */
    function (newRecord, oldRecord) {

      if (newRecord !== null && vm.data.historyVisible === true) {
        vm.getRecordHistory(newRecord);
      }

    });

  $scope.$watch('vm.data.historyVisible',
    function (newVal, oldVal) {

      if (oldVal === false && newVal === true && vm.data.selectedRecord !== null && vm.recordHistory !== null

        && vm.data.lastHistoryFor !== vm.data.selectedRecord.record.Id) {
        vm.getRecordHistory(vm.data.selectedRecord);
      }

    });

  function fetchRecords(campaignId, filter) {
    vm.inProgress = true;
    vm.data.selectedRecord = null;

    AxComponentsHelperService.getPreviewRecords({
      campaignId: campaignId,
      filter: filter
    }).then(function (res) {

      var fetchedRecords = res.Records,
        l,
        cFields,
        dFields,
        dL,
        i;
      ;
      if (fetchedRecords !== null) {

        l = fetchedRecords.length

        if (vm.data.receivedRecordsFor.indexOf(campaignId) === -1) {
          vm.data.receivedRecordsFor.push(campaignId);
        }

        if (l > 0) {

          if ((campaignId in vm.data.campaignFields) === false) {

            vm.data.campaignFields[campaignId] = res.Records[0].DataFields;

          }
          /*
           cFields = vm.data.campaignFields[campaignId];

           // Here we can't create new array, becouse we need one reference in angular
           // Clean array
           cFields.length = 0;
           dFields = res.Records[0].DataFields;
           dL = dFields.length;

           // TODO: No i chuj kurwa
           // Bo tutaj i tak bedą w tablicy nowe obiekty więc cała konstrukcja fitrów w angularze się rozpierdoli przez to
           // Trzeba po Tutaj updatować obiekty, które już są
           // Ablona razie olać tego ulepa i po prostu nie robić upa, co spowoduje, że nowe pola beda widoczne tylko po restarcie kampanii
           for(i=0; i < dL;i++) {
           cFields.push(dFields[i]);
           }*/

        }
      }

      vm.inProgress = false;
    }).catch(function (res) {
      console.error(res);
    });
  }

  vm.fetchRecords = function (campaignId) {

    var filter = null;
    var filterItems = [];
    if (campaignId in vm.data.filterItems) {
      filterItems = vm.data.filterItems[campaignId];
    }

    var filterEntries = filterItems.map(function (filterItem) {

      var filterEntry = new ax.SessionLib.Definitions.AxRequestRecordsFilterEntry();

      filterEntry.FieldName = filterItem.fieldName;
      filterEntry.Condition = filterItem.condition;
      filterEntry.Value = filterItem.value;
      return filterEntry;

    });

    if (filterEntries.length > 0) {
      filter = new ax.SessionLib.Definitions.AxRequestRecordsFilter();
      filter.Filters = filterEntries;
    }

    // TODO: Dodać checkbox
    // filter.FetchClosedRecords
    // TODO: Jakie są domyślne ordeby???

    // Żeby wiedzieć później czy wyświetlać filtry musimy znać ustawienia kampanii
    // W tym celu musimy mieć pewność, że lista kampanii została pobrana
    if (sClient.campaignsWasFetched === false) {

      sClient.fetchCampaigns().then(function (campaigns) {

        fetchRecords(campaignId, filter);

      }).catch(function (err) {

        console.error(err);

      });

    } else {

      fetchRecords(campaignId, filter);

    }

  }

  vm.releaseRecords = function () {

    AxComponentsHelperService.getPreviewRecords({ releaseRecords: true }).then(function (res) {

      // Podpięte z klienta, więc tutaj nic nie robimy...

    }, function (res) {

    });

  }

  /**
   * @param {ax.SessionLib.AxPreviewRecordItem} recordItem
   */
  vm.getRecordHistory = function (recordItem) {
    var r = recordItem.record;
    AxComponentsHelperService.getRecordHistory({
      UserId: sClient.loginResponse.LoginResponseParams.AccountData.Id,
      CampaignId: r.CampaignId,
      DataRecordId: r.Id
    }).then(
      function (res) {
        console.log("Received record history", res);
        if (res && res.value.RecordHistory) {
          vm.data.lastHistoryFor = res.value.DataRecordId;
          vm.recordHistory = res.value.RecordHistory.value;
        }
      },
      function (err) {

      });
  }

  vm.historyGridSorting = function (col) {
    if (vm.recordHistory) {
      var index = vm.recordHistory.Columns.indexOf(col);
      if (index > -1) {
        vm.historyGridSort.predicate = "[" + index + "]";
        vm.historyGridSort.reverse = !vm.historyGridSort.reverse;
      }
    }
  };

  vm.historyGridCellValue = function (val, col) {
    if (!val)
      return "";
    switch (col) {
      case "Date":
        return moment(val).format(vm.dateTimeFormat);
      case "SessionSubType":
        return vm.li18n.axSessionSubtype[val];
      case "Duration":
        return moment(val).format(vm.timeFormat);
      case "CreateCause":
        return vm.li18n.axCallEventCause[val];
      case "CampaignRecordState":
        return vm.li18n.axDataContactRecordState[val];
      case "InitiationResult":
        return vm.li18n.axSessionInitiationResult[val];
      default:
        return val;
    }
  }

  vm.startPreviewSession = function (campaignId, recordId, phoneIndex) {

    var appendRecordFromDatabase = vm.filtersVisible(campaignId);
    var removeResult;
    AxComponentsHelperService.startPreviewSession({
      campaignId: campaignId,
      recordId: recordId,
      phoneIndex: phoneIndex,
      appendRecordFromDatabase: appendRecordFromDatabase
    }).then(function (res) {

      console.log("[AxPreview.startPreviewSession] Start preview session response: ", res);
      removeResult = AxComponentsHelperService.removePreviewRecord(recordId);
      console.log("[AxPreview.startPreviewSession] Remove record from list result: " + removeResult);

    }, function (err) {

      console.warn("Start preview session error: ", err);
      removeResult = AxComponentsHelperService.removePreviewRecord(recordId);
      console.log("[AxPreview.startPreviewSession] Remove record from list result: " + removeResult);

    });

  }

  function isBitSet(needle, haystack) {
    return (haystack & needle) === needle;
  }

  vm.filtersVisible = function (campaignId) {

    if (campaignId !== vm.data.selectedCampaign) {
      return false;
    }

    if (sClient.campaignsWasFetched === true) {

      if (vm.data.receivedRecordsFor.indexOf(campaignId) !== -1) {

        var campaign = sClient.findAssignedPreviewCampaign(campaignId);

        if (campaign !== null && isBitSet(ax.SessionLib.AxCampaignTypeFlags.PreviewRecordBrowser, campaign.CampaignTypeFlags)) {

          var fields = vm.data.campaignFields[vm.data.selectedCampaign];
          if (typeof fields !== "undefined") {

            return fields.length > 0;

          }

          return false;

        } else {

          return false;

        }

        return false;
      }

      return false;

    } else {

      return false;

    }

  }
}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxPreview };
