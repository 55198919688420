import * as React from 'react';

export const CompressIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="currentColor">
      <g>
        <path
          d="M0 11L3 11 3 14 5 14 5 9 0 9 0 11 0 11ZM3 3L0 3 0 5 5 5 5 0 3 0 3 3 3 3ZM9 14L11 14 11 11 14 11 14 9 9 9 9 14 9 14ZM11 3L11 0 9 0 9 5 14 5 14 3 11 3 11 3Z"
        />
      </g>
    </svg>
  );
};
