import angular from 'angular';
import { AxFilterBuilderOperatorList } from './ax-filter-builder-operator-list/axFilterBuilderOperatorList';
import { AxFilterBuilder } from './ax-filter-builder/axFilterBuilder';
import { AxFilterBuilderItem } from './ax-filter-builder-item/axFilterBuilderItem';
import { AxFilterCheckbox } from './ax-filter-check-box/axFilterCheckbox';
import { AxFilterDateTime } from './ax-filter-date-time/axFilterDateTime';
import { AxFilterSpin } from './ax-filter-spin/axFilterSpin';
import { AxFilterText } from './ax-filter-text/axFilterText';
import { AxFilterTimeSelect } from './ax-filter-time-select/axFilterTimeSelect';
import { AxTimeSelect } from './ax-time-select/axTimeSelect';
import { FilterItemFactory } from './FilterItem';
import { OperatorFactory } from './Operator';

export default angular.module('ax.webagent.filterbuilder', [])
  .directive("axFilterBuilderOperatorList", ["Operator", AxFilterBuilderOperatorList])
  .directive('axFilterBuilder', ['FilterItem', AxFilterBuilder])
  .directive('axFilterBuilderItem', [AxFilterBuilderItem])
  .directive('axFilterCheckbox', [AxFilterCheckbox])
  .directive('axFilterDateTime', [AxFilterDateTime])
  .directive('axFilterSpin', [AxFilterSpin])
  .directive('axFilterText', [AxFilterText])
  .directive('axFilterTimeSelect', [AxFilterTimeSelect])
  .directive('axTimeSelect', [AxTimeSelect])
  .factory('FilterItem', FilterItemFactory)
  .factory('Operator', OperatorFactory)
  .name;
