import template from './AxCheckBox.html';

function AxCheckBox() {

  return {
    restrict: 'AEC',
    require: 'ngModel',
    scope: {
      li18n: '=axI18n',
      name: '=axName',
      editValue: '=ngModel',
      allowNullValues: '=axAllowNullValues',
      isValid: '=axIsValid',
      invalidMessage: '=axInvalidMessage',
      onValueSet: '=axOnValueSet',
      isReadonly: '=ngReadonly',
      dblclick: '&axDblclick'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {
  var vm = this;
  vm.isTrue = helpers.isTrue;
  vm.clickdblclick = function () {
    if (vm.dblclick) {
      vm.dblclick();
    }
    vm.input.blur();
    setTimeout(function () {
      vm.input.focus();
    }, 1);
  };
  vm.setValue = function (val) {
    if (val || val === false) {
      vm.editValue = helpers.isTrue(val) ? "True" : "False";
    } else {
      vm.editValue = null;
    }
    return true;
  };
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.input = $element.find("input[type=checkbox]").on("click", function () {
    $scope.$apply(function () {
      if ($scope.vm.isReadonly === true) {
        return false;
      }
      var value = $scope.vm.input.prop("checked");
      if (value === true) {
        $scope.vm.setValue(true);
      }
      if (value === false) {
        $scope.vm.setValue(false);
      }
      if (value === false && !$scope.vm.allowNullValues) {
        setTimeout(function () {
          $scope.vm.editValue = null;
        }, 0)
      }
    });
  });

  $scope.$watch("vm.editValue", function (val) {
    if (val || val === false) {
      $scope.vm.input.prop("checked", $scope.vm.isTrue(val));
    }
    $scope.vm.input.prop('indeterminate', $scope.vm.allowNullValues === true && !val);
  });
}

export { AxCheckBox };
