import * as React from 'react';

export const CameraBasicIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="currentColor">
      <g>
        <path
          d="M14 4.5L14 1C14 0.5 13.6 0 13 0L1 0C0.4 0 0 0.5 0 1L0 11C0 11.5 0.4 12 1 12L13 12C13.6 12 14 11.5 14 11L14 7.5 18 11.5 18 0.5 14 4.5 14 4.5Z"
        />
      </g>
    </svg>
  );
};
