import template from './AxHostMenuLeft.html';

function SubmenuCtrl() {

  var that = this;
  that.submenuVisible = false;

  this.onMouseOver = function () {
    that.showSubmenu();
  };

  this.onMouseOut = function () {
    that.hideSubmenu()
  };

  this.onSubmenuItemClick = function () {
    that.hideSubmenu();
  };

  this.showSubmenu = function () {
    that.submenuVisible = true;
  };

  this.hideSubmenu = function () {
    that.submenuVisible = false;
  }

}

/**
 * Main AxHostMenuLeft directive DOD (Directive Object Declaration)
 */
function AxHostMenuLeft() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      defaultMenuItem: '=axDefaultMenuItem',
      menu: '=axMenu',
      currentInteraction: '=axCurrentInteraction',
      interactions: '=axInteractions',
      defaultMenuState: '=axDefaultMenuState' // true - opened, false - closed
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['$scope', controller]
  }
}

function controller($scope) {

  var vm = this;

  vm.currentAddress = "";

  vm.activeItem = null;

  vm.interactionsVisible = true;
  vm.menuOpened = vm.defaultMenuState;

  vm.toggleMenu = function () {

    vm.menuOpened = !vm.menuOpened;

  };

  vm.onMenuItemClick = function (menuItem) {

    // Change active item only if there is no submodules
    if (!menuItem.submodules) {
      vm.activeItem = menuItem;
    }

    $scope.$emit('menuItemClick', {
      item: menuItem
    });

  };

  vm.isExpanded = function (menuItem) {

    return menuItem.webagent && menuItem.webagent === 'interactions';

  };

  vm.isInteractions = function (menuItem) {

    return menuItem.webagent && menuItem.webagent === 'interactions'

  };

  vm.interactionListFaClass = function (inter) {

    var currentInter = vm.currentInteraction,
      sessionId = inter.session.Session.SessionId,
      className = "fa fa-",
      subType = inter.session.Session.SubType;

    if (subType === ax.SessionLib.AxSessionSubtype.InboundCall ||
      subType === ax.SessionLib.AxSessionSubtype.PredictiveCall ||
      subType === ax.SessionLib.AxSessionSubtype.PreviewCall ||
      subType === ax.SessionLib.AxSessionSubtype.CallBackCall ||
      subType === ax.SessionLib.AxSessionSubtype.ManualCall) {
      className += "phone";
    } else if (subType === ax.SessionLib.AxSessionSubtype.InboundEmail) {
      className += "envelope";
    } else if (subType === ax.SessionLib.AxSessionSubtype.InboundChat) {
      className += "wechat";
    }

    className += " istate_" + inter.session.Session.SessionState;

    if (currentInter && sessionId === currentInter.session.Session.SessionId) {
      //className += ' active';
    }

    return className;

  };

  vm.hasAttachedInteraction = function (inter) {

    var l = vm.interactions.length, current;
    for (var i = 0; i < l; i++) {

      current = vm.interactions[i];

      if (current.session.Session.AttachToSessionId === inter.session.Session.SessionId) {
        return true;
      }

    }

    return false;

  };

  vm.isAddressActive = function (menuItem) {

    if (vm.activeItem !== null && vm.activeItem.parent && vm.activeItem.parent.item === menuItem.item) {
      return true;
    }

    return menuItem === vm.activeItem && !menuItem.submodules;

  }

}

function link($scope, $element, $attrs, $ctrl) {

  $scope.$watch("vm.menu", function (newVal, oldVal) {

    // Add reference to parent element in menu
    // Need this to set active parent element if child element was clicked (second menu level)
    if (newVal) {
      linkParent($scope.vm.menu);
    }

    if ($scope.vm.activeItem === null && newVal) {

      $scope.vm.activeItem = findMenuItem($scope.vm.defaultMenuItem, $scope.vm.menu);

    } else {

      $scope.vm.activeItem = findMenuItem($scope.vm.activeItem.item, $scope.vm.menu);

    }

  });

  $scope.$on("activeMenuItemChanged", function (event, data) {

    if (data && "item" in data) {

      $scope.vm.activeItem = data.item;

    }

  });

}

function linkParent(menu) {

  var l = menu.length,
    current,
    sl, sm;
  for (var i = 0; i < l; i++) {
    current = menu[i];
    if (current.submodules) {
      sl = current.submodules.length;
      for (var a = 0; a < sl; a++) {
        sm = current.submodules[a];
        sm.parent = current;
      }
    }
  }

}

function findMenuItem(menuItem, menu) {

  if (menuItem && menu) {

    var l = menu.length,
      current;
    for (var i = 0; i < l; i++) {
      current = menu[i];
      if (current.item === menuItem) {
        return current;
      }
    }

  }

  return null;

}

export { AxHostMenuLeft, SubmenuCtrl };
