import * as React from 'react';
import { isString } from '../utils';
import { isFunction } from "../utils";
import { MediaStreamBuilder } from "../models";

const ON_ENDED: string = 'ended';
const ON_ERROR: string = 'error';
const ON_PAUSE: string = 'pause';
const ON_PLAY: string = 'play';
const ON_READY: string = 'canplay';
const ON_SEEK: string = 'seeked';

export class FilePlayer extends React.Component<any> {
  player: HTMLAudioElement | HTMLVideoElement;

  componentDidMount() {
    const { src } = this.props;
    this.addListeners();
    this.attachSrc(src);
  }

  componentWillReceiveProps(next: any) {
    this.attachSrc(next.src);
  }

  componentWillUnmount() {
    this.removeListeners();
  }


  attachSrc(src: MediaStream | MediaStreamBuilder | string ) {
    if (!this.player) {
      return;
    }

    const { src: prevSrc } = this.props;
    const nextSrc = src;

    if (prevSrc === nextSrc) {
      return;
    }

    if (isString(nextSrc)) {
      this.player.src = nextSrc as string;
    } else {


      const stream = isFunction(nextSrc) ? (nextSrc as MediaStreamBuilder)() : (nextSrc as MediaStream);

      try {

        this.player.srcObject = stream as MediaStream;

      } catch (error) {
        this.player.src = URL.createObjectURL(stream);
      }
    }
  }

  addListeners() {
    const { onEnded, onError, onPause, onPlay, onReady, onSeek } = this.props;
    this.player.addEventListener(ON_ENDED, onEnded);
    this.player.addEventListener(ON_ERROR, onError);
    this.player.addEventListener(ON_PAUSE, onPause);
    this.player.addEventListener(ON_PLAY, onPlay);
    this.player.addEventListener(ON_READY, onReady);
    this.player.addEventListener(ON_SEEK, onSeek);
  }

  removeListeners() {
    const { onEnded, onError, onPause, onPlay, onReady, onSeek } = this.props;
    this.player.removeEventListener(ON_ENDED, onEnded);
    this.player.removeEventListener(ON_ERROR, onError);
    this.player.removeEventListener(ON_PAUSE, onPause);
    this.player.removeEventListener(ON_PLAY, onPlay);
    this.player.removeEventListener(ON_READY, onReady);
    this.player.removeEventListener(ON_SEEK, onSeek);
  }

  pause() {
    this.player.pause();
  }

  play() {
    return this.player.play();
  }

  seek(seconds: number) {
    this.player.currentTime = seconds;
  }

  stop() {
    this.player.removeAttribute('src');
  }

  volume(volume: number = null): number {
    if (volume === null) {
      return this.player.volume;
    }

    return this.player.volume = volume;
  }

  ref = (player: HTMLAudioElement | HTMLVideoElement) => {
    this.player = player;
  };

  // todo onDoubleClick
  render() {
    const { poster, className, onDoubleClick } = this.props;

    const style = {
      // width: '100%',
      // height: '100%',
      ...this.props.style
    };

    // todo className
    return (
      <video
        onDoubleClick={onDoubleClick}
        style={style}
        ref={this.ref}
        poster={poster}
        className={className}>
      </video>
    );
  }
}
