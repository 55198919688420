export const SETTINGS_SOUND_ENABLE = 'SETTINGS_SOUND_ENABLE';
export const SETTINGS_SOUND_DISABLE = 'SETTINGS_SOUND_DISABLE';
export const SETTINGS_SOUND_TOGGLE = 'SETTINGS_SOUND_TOGGLE';

export const SETTINGS_LOAD_REQUEST = 'SETTINGS_LOAD_REQUEST';
export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const SETTINGS_LOAD_FAILURE = 'SETTINGS_LOAD_FAILURE';
export const SETTINGS_SAVE_REQUEST = 'SETTINGS_SAVE_REQUEST';
export const SETTINGS_SAVE_SUCCESS = 'SETTINGS_SAVE_SUCCESS';
export const SETTINGS_SAVE_FAILURE = 'SETTINGS_SAVE_FAILURE';

/**
 * Action creators
 */
export function enableSound(soundType) {
  return {
    type: SETTINGS_SOUND_ENABLE,
    payload: {
      soundType: soundType
    }
  };
}

export function disableSound(soundType) {
  return {
    type: SETTINGS_SOUND_DISABLE,
    payload: {
      soundType: soundType
    }
  }
}

export function toggleSound(soundType) {
  return {
    type: SETTINGS_SOUND_TOGGLE,
    payload: {
      soundType: soundType
    }
  }
}

export function loadSettingsRequest(userId) {
  return {
    type: SETTINGS_LOAD_REQUEST,
    payload: {
      userId: userId
    }
  }
}

export function loadSettingsSuccess(payload) {
  return {
    type: SETTINGS_LOAD_SUCCESS,
    payload: payload
  }
}

export function loadSettingsFailure(payload) {
  return {
    type: SETTINGS_LOAD_FAILURE,
    payload: payload
  }
}

export function saveSettingsRequest(settings) {
  return {
    type: SETTINGS_SAVE_REQUEST,
    payload: settings
  }
}

export function saveSettingsSuccess() {
  return {
    type: SETTINGS_SAVE_SUCCESS
  }
}

export function saveSettingsFailure() {
  return {
    type: SETTINGS_SAVE_FAILURE
  }
}
