/** @ngInject */
export function AxOverlay($compile, $http) {
  return {
    restrict: 'E',
    controllerAs: 'vm',
    bindToController: true,
    controller,
    scope: {
      params: '=axOverlayParams', // Object with any required parameters
      show: '=axOverlayShow'
    },
    link: function ($scope, $element, $attrs) {

      // Put references to elements in "global" scope
      var overlayTrans;
      var overlay;

      // Watch for changes on show property
      // If show property change to true, create overlay opacity layer,
      // create modal container, append template to this layer and apendd all to body
      // If show property change to false, remove created layers from body
      $scope.$watch('vm.show', function (newValue, oldValue) {

        var newScope;

        if (newValue !== oldValue) {

          newScope = $scope.$new();

          if (newValue === true) {

            overlayTrans = angular.element(document.createElement('div'));
            overlayTrans.attr("data-overlayTrans", "visible");
            overlay = angular.element(document.createElement('div'));
            overlay.attr("data-overlay", "visible");

            $http.get($attrs.axOverlayTemplate, {}).then(function (res) {

              var overlayContent = $compile(res.data)(newScope);
              overlay.append(overlayContent);

              // If scope will not be destroyed, functions associated with templates will still be running
              overlay.on('$destroy', function () {
                newScope.$destroy();
              });

              angular.element("body").append(overlayTrans);
              angular.element("body").append(overlay);

            });

          } else {
            if (overlayTrans) {
              overlayTrans.remove();
            }

            if (overlay) {
              overlay.remove();
            }

          }
        }

      });


    }
  }
}

function controller() {
  var vm = this;

  vm.close = function () {
    vm.show = false;
  }
}
