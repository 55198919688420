export function ClipboardService() {

  this.copyToClipboard = copyToClipboard;


  function copyToClipboard(evt) {
    console.log(evt);
    var text = evt.target,
      ret = false,
      range = document.createRange();
    range.selectNode(text);
    var selection = window.getSelection();
    selection.empty();
    selection.addRange(range);

    try {
      // Now that we've selected the anchor text, execute the copy command
      ret = document.execCommand('copy');

    } catch (err) {
      console.warn('Oops, unable to copy');
      ret = false;
    }

    selection.empty();

    return ret;
  }
}
