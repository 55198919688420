function DialpadData() {
  return {
    contactCustomerId: null,
    contactId: null,
    customerName: "",
    selectedCampaign: null,
    selectedUser: null,
    searchCampaign: {},
    searchUser: {}
  }
}

export { DialpadData };
