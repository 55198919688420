/** @ngInject */
export function AxComponentsHelperService(SessionHelperService, TimerService, Sender) {

  /**
   * Sets session param for specified session
   * @param {Object} params Parameters object
   * @param {ax.SessionLib.AxSessionServerSessionBase} params.session
   * @param {String} params.sessionId Id (guid) of session to set params
   * @param {ax.SessionLib.AxSessionParam} params.param Parameter to set
   * @param {String} [params.paramValue=""] Parameter value to set
   * @param {?Number} [params.campaignId=null] Campaign id
   * @return {external:Promise}
   */
  this.setSessionParamBySession = function (params) {

    return SessionHelperService.setSessionParamBySession(params);

  };

  /**
   * Starts countdown pause recording time for interaction
   * @param {InteractionItem} interaction
   */
  this.startCountdownRecordingPauseTime = function (interaction) {
    TimerService.startCountdownRecordingPauseTime(interaction);
  };

  /**
   * Stops countdown pause recording time for interaction
   * @param {InteractionItem} interaction
   */
  this.stopCountdownRecordingPauseTime = function (interaction) {
    TimerService.stopCountdownRecordingPauseTime(interaction);
  };

  /**
   * Formats interval as "HH:MM:SS"
   * @param {Number} ms
   * @return {String}
   */
  this.formatInterval = function (ms) {

    var days = Math.floor(ms / (1000 * 60 * 60 * 24));
    ms -= days * (1000 * 60 * 60 * 24);

    var hours = Math.floor(ms / (1000 * 60 * 60));
    ms -= hours * (1000 * 60 * 60);

    var mins = Math.floor(ms / (1000 * 60));
    ms -= mins * (1000 * 60);

    var seconds = Math.floor(ms / (1000));

    return this.zeroPad(hours) + ':' + this.zeroPad(mins) + ':' + this.zeroPad(seconds);
  };

  this.zeroPad = function (n) {
    return n < 10 ? '0' + n : n;
  };

  /**
   * Gets recordset for preview campaign
   * @param {Object} p
   * @param {Number} p.campaignId Campaign id to get records for
   * @param {Boolean} [p.releaseRecords=false] if this flag will be set. Command will release already received records
   * @param {ax.SessionLib.Definitions.AxRequestRecordsFilter} [p.filter=null]
   * @return {external:Promise}
   */
  this.getPreviewRecords = function (p) {

    return SessionHelperService.getPreviewRecords(p);

  };

  /**
   * Starts preview session
   * @param {Object} p
   * @param {Number} p.campaignId
   * @param {Number} p.recordId
   * @param {Number} [p.phoneIndex=null]
   * @param {Boolean} [p.appendRecordFromDatabase=false]
   * @return {external:Promise}
   */
  this.startPreviewSession = function (p) {
    return SessionHelperService.startPreviewSession(p);
  };

  /**
   * removes preview record
   * @param {Number} recordId
   * @return {Boolean}
   */
  this.removePreviewRecord = function (recordId) {
    return SessionHelperService.removePreviewRecord(recordId);
  };

  /**
   * Gets record history
   * @param {Object} p
   * @param {Number} p.userId
   * @param {number} p.campaingId
   * @param {Number} p.dataRecordId
   * @return {external:Promise}
   */
  this.getRecordHistory = function (p) {
    return Sender.getRecordHistory(p);
  };

  /**
   * Sends response to session chat message
   * @param {Object} p
   * @param {Object} p.project
   * @param {Number} p.project.Id
   * @param {String} p.project.Name
   * @param {String} p.sessionId
   * @param {String} p.message
   * @return {external:Promise}
   */
  this.sendSessionChatResponseMessage = function (p) {
    return SessionHelperService.sendSessionChatResponseMessage(p);
  };

}
