import template from './AxSpinTextBox.html';

function AxSpinTextBox() {

  return {
    restrict: 'AEC',
    require: 'ngModel',
    scope: {
      li18n: '=axI18n',
      name: '=axName',
      editValue: '=ngModel',
      allowNullValues: '=axAllowNullValues',
      isValid: '=axIsValid',
      invalidMessage: '=axInvalidMessage',
      isReadonly: '=ngReadonly',
      dblclick: '&axDblclick',
      maxValue: '=axMaxValue',
      minValue: '=axMinValue',
      incrementStep: '=axIncrementStep',
      precision: '=axPrecision',
      allowDouble: '=axAllowDouble'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {
  var vm = this;
  vm.incrementStep = vm.incrementStep || 1;
  vm.precision = vm.precision || 3;
  vm.allowDouble = vm.allowDouble || false;
  vm.repeatSpeed = 100;
  vm.keyDownNumbersOnly = helpers.keyDownNumbersOnly;
  vm.keyDownFloatOnly = helpers.keyDownFloatOnly;
  vm.keyDownIntegersOnly = helpers.keyDownIntegersOnly;

  vm.clickdblclick = function () {
    if (vm.dblclick) {
      vm.dblclick();
    }
    vm.input.blur();
    setTimeout(function () {
      vm.input.focus();
    }, 1);
  };

  vm.setValue = function (val) {
    if (val || val === 0) {
      if (vm.allowDouble !== true) {
        var intValue = parseInt(val);
        if (isNaN(intValue)) {
          intValue = 0;
        }
        if (vm.maxValue) {
          intValue = Math.min(intValue, parseInt(vm.maxValue));
        }
        if (vm.minValue) {
          intValue = Math.max(intValue, parseInt(vm.minValue));
        }
        vm.editValue = intValue.toString();
      }
      else {
        var floatValue = parseFloat(val.toString().replace(",", "."));
        if (isNaN(floatValue)) {
          floatValue = 0;
        }
        if (vm.maxValue) {
          floatValue = Math.min(floatValue, parseFloat(vm.maxValue));
        }
        if (vm.minValue) {
          floatValue = Math.max(floatValue, parseFloat(vm.minValue));
        }
        if (vm.precision && floatValue.toString().indexOf(".") === -1) {
          vm.editValue = floatValue.toString() + ".0";
        } else {
          vm.editValue = helpers.round(floatValue, vm.precision).toString();
        }
      }
    }
    else {
      vm.editValue = null;
    }
    return true;
  };
  vm.upDown = function (up) {
    if (vm.allowDouble !== true) {
      var intValue = 0;
      var value = vm.editValue;
      if (value) {
        intValue = parseInt(value);
      }
      if (up === true) {
        vm.setValue(intValue + parseInt(vm.incrementStep));
      }
      if (up === false) {
        vm.setValue(intValue - parseInt(vm.incrementStep));
      }
    }
    else {
      var floatValue = 0;
      var value = vm.editValue;
      if (value) {
        floatValue = parseFloat(value.toString().replace(",", "."));
      }
      if (up === true) {
        vm.setValue(floatValue + parseFloat(vm.incrementStep));
      }
      if (up === false) {
        vm.setValue(floatValue - parseFloat(vm.incrementStep));
      }
    }
  };
}

function link($scope, $element, $attrs, $ctrl) {

  $scope.vm.input = $element.find("input[data-input-type=text]")
    .on('focusout', function () {
      $scope.vm.setValue($scope.vm.editValue)
    });
  $scope.vm.input.on("keydown", $scope.vm.keyDownNumbersOnly);
  if ($scope.vm.allowDouble) {
    $scope.vm.input.on("keydown", $scope.vm.keyDownFloatOnly);
  } else {
    $scope.vm.input.on("keydown", $scope.vm.keyDownIntegersOnly);
  }

  $scope.vm.upImg = $element.find(".up-arr-png")
    .on("mousedown", function () {
      if ($scope.vm.isReadonly === true) {
        return false;
      }
      if ($scope.vm.upInterval) {
        clearInterval($scope.vm.upInterval);
      }
      $scope.vm.upDown(true);
      $scope.vm.upInterval = setInterval(function () {
        $scope.$apply(function () {
          $scope.vm.upDown(true);
        });
      }, $scope.vm.repeatSpeed);
    }).on("mouseup", function () {
      clearInterval($scope.vm.upInterval);
      $scope.vm.upInterval = null;
    }).on("mouseout", function () {
      clearInterval($scope.vm.upInterval);
      $scope.vm.upInterval = null;
    });
  $scope.vm.downImg = $element.find(".down-arr-png")
    .on("mousedown", function () {
      if ($scope.vm.isReadonly === true) {
        return false;
      }
      if ($scope.vm.downInterval) {
        clearInterval($scope.vm.downInterval);
      }
      $scope.vm.upDown(false);
      $scope.vm.downInterval = setInterval(function () {
        $scope.$apply(function () {
          $scope.vm.upDown(false);
        });
      }, $scope.vm.repeatSpeed);
    }).on("mouseup", function () {
      clearInterval($scope.vm.downInterval);
      $scope.vm.downInterval = null;
    }).on("mouseout", function () {
      clearInterval($scope.vm.downInterval);
      $scope.vm.downInterval = null;
    });
}

export { AxSpinTextBox };
