function AxTippy() {

  return {
    restrict: "A",
    link: link
  }

}

function link($scope, $element, $attrs, $ctrl, $transclude) {

  // function($scope){ return $attrs.axTippy;}
  $scope.$watch($attrs.axTippy, function (newValue, oldValue) {

    var el = $element[0],
      html;
    if (typeof newValue === "string" && newValue.length > 0) {

      if (el._tippy) {
        el._tippy.destroy();
      }

      html = document.createElement("div");
      html.innerHTML = newValue;

      tippy($element[0], {
        html: html
      });

    } else {
      if (el._tippy) {
        el._tippy.destroy();
      }
    }

  });

}

export { AxTippy };
