import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { Player } from './Player';
import { MediaControlProps } from './models';
import { goFullScreen } from './utils';

export class Media extends React.Component<any> {
  player: Player = null;

  state = {
    isFullScreen: false,
    isLoading: true,
    isMuted: false,
    isPlaying: false,
    volume: 1
  };

  mute = () => {
    this.setState({ isMuted: !this.state.isMuted });
  };

  ref = (player: Player) => {
    this.player = player;
  };

  goFullScreen = () => {
    const element = findDOMNode(this.player);

    if (!element) return;

    element[goFullScreen]();
  };

  render() {
    let { children } = this.props;

    const { goFullScreen, mute } = this;

    let hasPlayer = false;

    return React.Children.map(children, (child: any) => {
      // todo
      const props: any = {
        muted: this.state.isMuted
      };

      if (child.type === Player && hasPlayer) {
        return child;
      }

      if (child.type === Player && !hasPlayer) {
        hasPlayer = true;
        props.ref = this.ref;
      } else {
        props.media = { ...this.state, goFullScreen, mute };
      }

      return React.cloneElement(child as
        React.ReactElement<MediaControlProps>, props);
    });
  }
}
