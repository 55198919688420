import * as React from 'react';

export const ExpandIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="currentColor">
      <g>
        <path
          d="M2 9L0 9 0 14 5 14 5 12 2 12 2 9 2 9ZM0 5L2 5 2 2 5 2 5 0 0 0 0 5 0 5ZM12 12L9 12 9 14 14 14 14 9 12 9 12 12 12 12ZM9 0L9 2 12 2 12 5 14 5 14 0 9 0 9 0Z"
        />
      </g>
    </svg>
  );
};
