import angular from 'angular';
import { AxHostMenuInteractions, InteractionItemCtrl } from './ax-host-menu-interactions/axHostMenuInteractions';
import { AxHostMenuLeft, SubmenuCtrl } from './ax-host-menu-left/axHostMenuLeft';

export default angular.module('ax.webagent.menu', [])
  .directive("axHostMenuInteractions", AxHostMenuInteractions)
  .controller("InteractionItemCtrl", ["$scope", "SessionClient", "Data", InteractionItemCtrl])
  .directive('axHostMenuLeft', AxHostMenuLeft)
  .controller('Submenu', SubmenuCtrl)
  .name;
