import template from './AxRecordingButton.html';

/**
 * Recording button DOD (Directive Object Declaration)
 */
function AxRecordingButton() {

  return {
    restrict: 'A',
    scope: {
      interaction: '=axInteraction', // InteractionItem
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['AxComponentsHelperService', controller]
  }

}

function controller(AxComponentsHelperService) {

  var vm = this;

  vm.data = {
    optionsVisible: false,
    buttons: {
      stop: {},
      both: {},
      user: {}
    }
  };

  Object.defineProperty(vm.data.buttons.stop, "enabled", {
    get: function () {
      if (typeof vm.interaction !== "undefined" && vm.interaction !== null) {
        var session = vm.interaction.session.Session;
        var recording = vm.interaction.data.recording;

        if (recording && session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser
          && recording.AllowStopResumeRecording === true
          && (recording.RecordingState === ax.SessionLib.AxSessionRecordingState.RecordingUser
            || recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Recording)) {
          return true;
        }
        return false;
      }
      return false;
    }
  });

  Object.defineProperty(vm.data.buttons.user, "enabled", {
    get: function () {
      if (typeof vm.interaction !== "undefined" && vm.interaction !== null) {
        var session = vm.interaction.session.Session;
        var recording = vm.interaction.data.recording;
        if (recording && session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser
          && recording.UserRecordingAllowed === true
          && (recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Recording
            || recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Stopped
            || recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Paused)) {
          return true;
        }
        return false;
      }
      return false;
    }
  });

  Object.defineProperty(vm.data.buttons.both, "enabled", {
    get: function () {

      if (typeof vm.interaction !== "undefined" && vm.interaction !== null) {
        var session = vm.interaction.session.Session;
        var recording = vm.interaction.data.recording;
        if (recording && session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser
          && recording.BothSidesRecordingAllowed === true
          && (recording.RecordingState === ax.SessionLib.AxSessionRecordingState.RecordingUser
            || recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Stopped
            || recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Paused)) {
          return true;
        }
        return false;
      }
      return false;
    }
  });

  Object.defineProperty(vm.data, "recordingPaused", {
    get: function () {
      if (typeof vm.interaction !== "undefined" && vm.interaction !== null
        && vm.interaction.data.recording
        && vm.interaction.session.Session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser
        && (vm.interaction.data.recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Paused)) {
        return true;
      }
      return false;
    }
  });

  Object.defineProperty(vm.data, "recording", {
    get: function () {
      if (typeof vm.interaction !== "undefined" && vm.interaction !== null) {
        var session = vm.interaction.session.Session;
        var recording = vm.interaction.data.recording;
        if (recording && recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Recording
          && session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser
          && vm.data.recordingPaused === false) {
          return true;
        }
        return false;
      }
      return false;
    }
  });

  Object.defineProperty(vm.data, "recordingUser", {
    get: function () {
      if (typeof vm.interaction !== "undefined" && vm.interaction !== null && vm.interaction.data.recording
        && vm.interaction.data.recording.RecordingState === ax.SessionLib.AxSessionRecordingState.RecordingUser
        && vm.interaction.session.Session.SessionState === ax.SessionLib.AxSessionState.AcceptedByUser && vm.data.recordingPaused === false) {
        return true;
      }
      return false;
    }
  });

  Object.defineProperty(vm.data, "recordingStopped", {
    get: function () {
      if (typeof vm.interaction !== "undefined" && vm.interaction !== null && vm.interaction.data.recording
        && (vm.interaction.data.recording.RecordingState === ax.SessionLib.AxSessionRecordingState.Stopped
          || vm.interaction.session.Session.SessionState !== ax.SessionLib.AxSessionState.AcceptedByUser)) {
        AxComponentsHelperService.stopCountdownRecordingPauseTime(vm.interaction);
        vm.interaction.recordingDuration = null;
        return true;
      }

      return false;
    }
  });

  Object.defineProperty(vm.data, "recordingText", {
    get: function () {

      if (vm.data.recording === true) {
        return vm.li18n.recordingButton.recording;
      }

      if (vm.data.recordingUser === true) {
        return vm.li18n.recordingButton.recordingUser;
      }

      if (vm.data.recordingPaused === true) {
        return vm.li18n.recordingButton.paused;
      }

      if (vm.data.recordingStopped === true) {
        return vm.li18n.recordingButton.stopped;
      }

    }

  });

  Object.defineProperty(vm.data, "recordingDuration", {
    get: function () {

      if (typeof vm.interaction === "undefined"
        || vm.interaction === null
        || vm.interaction.recordingDuration === null) {
        return "";
      }
      return vm.interaction.recordingDuration;

    }
  });

  /***
   * Sets actual recording state
   * @param {ax.SessionLib.AxSessionParam} param
   */
  vm.setRecordingParam = function (param) {

    if (param === 1) { // Stop

      if (vm.data.buttons.stop.enabled === false) {
        return;
      }

    } else if (param === 0) { // Record both

      if (vm.data.buttons.both.enabled === false) {
        return;
      }

    } else if (param === 5) { // Record user
      if (vm.data.buttons.user.enabled === false) {
        return;
      }
    }

    AxComponentsHelperService.setSessionParamBySession({
      session: vm.interaction.session.Session,
      param: param
    }).then(function (res) {

      if (param === ax.SessionLib.AxSessionParam.StartRecording) {
        vm.interaction.data.recording.RecordingState = ax.SessionLib.AxSessionRecordingState.Recording;
      } else if (param === ax.SessionLib.AxSessionParam.PauseRecording) {
        vm.interaction.lastRecordingState = vm.interaction.data.recording.RecordingState;
        vm.interaction.data.recording.RecordingState = ax.SessionLib.AxSessionRecordingState.Paused;

        // Start timer if we have to resume recording after specified time
        if (vm.interaction.data.recording.AutoResumeRecordingAfter !== null) {
          vm.interaction.recordingDuration = AxComponentsHelperService.formatInterval(vm.interaction.data.recording.AutoResumeRecordingAfter);
          AxComponentsHelperService.startCountdownRecordingPauseTime(vm.interaction);
        }

      } else if (param === ax.SessionLib.AxSessionParam.StartRecordingOnlyUser) {
        vm.interaction.data.recording.RecordingState = ax.SessionLib.AxSessionRecordingState.RecordingUser;
      }

    }, function (err) {
      /// ???
    });

  }

  vm.stopProp = function ($event) {
    $event.stopPropagation();
  };
}

function link($scope, $element, $attrs, $ctrl) {
  $(document).on("click", function () {
    if ($scope.vm.data.optionsVisible === true) {
      $scope.vm.data.optionsVisible = false;
    }

  });
  $(window).on("resize", function () {
    $scope.vm.data.optionsVisible = false
  });
}

export { AxRecordingButton };
