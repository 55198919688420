function StatisticHelperService(SessionClient) {
  var that = this;
  var statisticData = null;
  var translation = {};

  const chartQueues = [];

  function changeLanguage(trans) {
    translation = trans;
  }

  function __(key) {
    return translation[key];
  }

  function setStatisticData(data) {
    statisticData = data;
  }

  function formatTime(time) {
    var rest;
    var hours = Math.floor(time / (60 * 60 * 1000));
    rest = time % (60 * 60 * 1000);

    var minutes = Math.floor(rest / (60 * 1000));
    rest = rest % (60 * 1000);

    var seconds = Math.floor(rest / 1000);

    return [
      ('00' + hours).substr(-2),
      ('00' + minutes).substr(-2),
      ('00' + seconds).substr(-2)
    ].join(':');
  }

  function getInfoTiles() {

    var tiles = [
      {
        value: formatTime(statisticData ? statisticData.TotalLoginTime : 0),
        description: __('STATISTICS.TILES.LOGGED'),
        color: 'black'
      },
      {
        value: formatTime(statisticData ? statisticData.AvailableTime : 0),
        description: __('STATISTICS.TILES.AVAILABLE'),
        color: 'black'
      },
      {
        value: formatTime(statisticData ? statisticData.TotalInSessionTime : 0),
        description: __('STATISTICS.TILES.IN_SESSION'),
        color: 'black'
      },
      {
        value: formatTime(statisticData ? statisticData.AuxInWorkTime : 0),
        description: __('STATISTICS.TILES.PRODUCTIVE_BREAKS'),
        color: 'black'
      },
      {
        value: formatTime(statisticData ? statisticData.AuxOutOfWorkTime : 0),
        description: __('STATISTICS.TILES.NONPRODUCTIVE_BREAKS'),
        color: 'black'
      },
      {
        value: formatTime(statisticData ? statisticData.AcwTime : 0),
        description: __('STATISTICS.TILES.WRAPUP'),
        color: 'black'
      },
      {
        value: statisticData ? statisticData.InboundVoiceAcceptedSessions : 0,
        ofValue: statisticData ? statisticData.InboundVoiceRejectedSessions :0,
        description: __('STATISTICS.TILES.INCOMING'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.INCOMING_TOOLTIP')
      },
      {
        value: statisticData ? statisticData.OutboundVoiceAcceptedSessions : 0,
        ofValue: statisticData ? statisticData.OutboundVoiceRejectedSessions : 0,
        description: __('STATISTICS.TILES.OUTGOING'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.OUTGOING_TOOLTIP')
      },
      {
        value: statisticData ? statisticData.ManualVoiceInitiatedSessions : 0,
        ofValue: statisticData ? statisticData.ManualVoiceUserInitiationFailedSessions : 0,
        description: __('STATISTICS.TILES.MANUAL'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.MANUAL_TOOLTIP')
      },
      {
        value: statisticData ? statisticData.PreviewVoiceInitiatedSessions : 0,
        ofValue: statisticData ? statisticData.PreviewVoiceUserInitiationFailedSessions : 0,
        description: __('STATISTICS.TILES.PREVIEW'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.PREVIEW_TOOLTIP')
      },
      {
        value: statisticData ? statisticData.EmailAcceptedSessions : 0,
        ofValue: statisticData ? statisticData.EmailRejectedSessions :0,
        description: __('STATISTICS.TILES.EMAIL'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.EMAIL_TOOLTIP')
      },
      {
        value: statisticData ? statisticData.ChatAcceptedSessions : 0,
        ofValue: statisticData ? statisticData.ChatRejectedSessions : 0,
        description: __('STATISTICS.TILES.CHAT'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.CHAT_TOOLTIP')
      },
      {
        value: statisticData ? statisticData.SocialAcceptedSessions : 0,
        ofValue: statisticData ? statisticData.SocialRejectedSessions : 0,
        description: __('STATISTICS.TILES.SOCIAL'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.SOCIAL_TOOLTIP')
      },
      {
        value: statisticData ? statisticData.WorkflowAcceptedSessions : 0,
        ofValue: statisticData ? statisticData.WorkflowRejectedSessions : 0,
        description: __('STATISTICS.TILES.WORKFLOW'),
        color: 'black',
        tooltip: __('STATISTICS.TILES.WORKFLOW_TOOLTIP')
      }
    ];

    return tiles;
  }

  function getCounters() {

    var serviceTime = statisticData ? statisticData.TotalInSessionTime + statisticData.AcwTime + statisticData.ConnectingTime : 0;
    var substativeProductivity =  statisticData ? Math.round((serviceTime / (statisticData.TotalLoginTime - statisticData.AuxInWorkTime - statisticData.AuxOutOfWorkTime)) * 100) : 0;
    var realProductivity = statisticData ? Math.round((serviceTime / statisticData.TotalLoginTime ) * 100) : 0;

    var counters = [
      {
        title: __('STATISTICS.COUNTERS.SERVICE_TIME'),
        value: formatTime(serviceTime)
      },
      {
      title: __('STATISTICS.COUNTERS.SUBSTANTIVE_PRODUCTIVITY'),
      value: substativeProductivity.toFixed(2) + '%'
    },
      {
        title: __('STATISTICS.COUNTERS.REAL_PRODUCTIVITY'),
        value: realProductivity.toFixed(2) + '%'
      },
      {
        title: __('STATISTICS.COUNTERS.INBOUND_SUCCEED'),
        value: statisticData ? statisticData.InboundVoiceSuccedCodeSessions : 0
      },
      {
        title: __('STATISTICS.COUNTERS.INBOUND_TIME'),
        value: formatTime(statisticData ? statisticData.InboundVoiceInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.INBOUND_AVG_TIME'),
        value: formatTime(statisticData ? statisticData.InboundVoiceAverageInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.INBOUND_SHIFTED'),
        value: statisticData ? statisticData.InboundVoiceShiftedSessions : 0
      },
      {
        title: __('STATISTICS.COUNTERS.OUTBOUND_SUCCEED'),
        value: statisticData ? statisticData.OutboundVoiceSuccedCodeSessions : 0
      },
      {
        title: __('STATISTICS.COUNTERS.OUTBOUND_TIME'),
        value: formatTime(statisticData ? statisticData.OutboundVoiceInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.OUTBOUND_AVG_TIME'),
        value: formatTime(statisticData ? statisticData.OutboundVoiceAverageInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.OUTBOUND_SHIFTED'),
        value: statisticData ? statisticData.OutboundVoiceShiftedSessions : 0
      },
      {
        title: __('STATISTICS.COUNTERS.PREVIEW_SUCCEED'),
        value: statisticData ? statisticData.PreviewVoiceSuccedCodeSessions : 0
      },
      {
        title: __('STATISTICS.COUNTERS.PREVIEW_TIME'),
        value: formatTime(statisticData ? statisticData.PreviewVoiceInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.PREVIEW_AVG_TIME'),
        value: formatTime(statisticData ? statisticData.PreviewVoiceAverageInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.PREVIEW_SHIFTED'),
        value: statisticData ? statisticData.PreviewVoiceShiftedSessions :0
      },
      {
        title: __('STATISTICS.COUNTERS.MANUAL_SUCCEED'),
        value: statisticData ? statisticData.ManualVoiceConnectedSessions : 0
      },
      {
        title: __('STATISTICS.COUNTERS.MANUAL_TIME'),
        value: formatTime(statisticData ? statisticData.ManualVoiceInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.MANUAL_AVG_TIME'),
        value: formatTime(statisticData ? statisticData.ManualVoiceAverageInSessionTime : 0)
      },
      {
        title: __('STATISTICS.COUNTERS.MANUAL_SHIFTED'),
        value: statisticData ? statisticData.ManualVoiceShiftedSessions : 0
      }];

    return counters;
  }

  function getChartsData() {

    var ChartType = {
      DOUGHNUT: 0,
      PIE: 1
    };

    var colors = ['#007c9c', '#019fcc', '#8fcd3e', '#82b93a'];

    var acceptedSessions = statisticData ? statisticData.InboundVoiceAcceptedSessions +
      statisticData.OutboundVoiceAcceptedSessions +
      statisticData.PreviewVoiceConnectedSessions +
      statisticData.ChatAcceptedSessions +
      statisticData.SocialAcceptedSessions +
      statisticData.EmailAcceptedSessions +
      statisticData.WorkflowAcceptedSessions +
      statisticData.ManualVoiceConnectedSessions : 0;

    var rejectedSessions = statisticData ? statisticData.InboundVoiceRejectedSessions +
      statisticData.OutboundVoiceRejectedSessions +
      statisticData.ChatRejectedSessions +
      statisticData.SocialRejectedSessions +
      statisticData.EmailRejectedSessions +
      statisticData.WorkflowRejectedSessions +
      statisticData.ManualVoiceNotConnectedSessions : 0;

    var success = statisticData ? statisticData.InboundVoiceSuccedCodeSessions +
      statisticData.OutboundVoiceSuccedCodeSessions +
      statisticData.PreviewVoiceSuccedCodeSessions +
      statisticData.ChatSuccedCodeSessions +
      statisticData.SocialSuccedCodeSessions +
      statisticData.EmailSuccedCodeSessions +
      statisticData.WorkflowSuccedCodeSessions +
      statisticData.ManualVoiceSuccedCodeSessions : 0;

    var charts = [{
      title: __('STATISTICS.CHARTS.STATES.TITLE'),
      subtitle: __('STATISTICS.CHARTS.STATES.SUBTITLE'),
      type: ChartType.PIE,
      data: [
        statisticData ? statisticData.AvailableTime : 0,
        statisticData ? statisticData.TotalInSessionTime : 0,
        statisticData ? statisticData.AcwTime : 0,
        statisticData ? statisticData.AuxInWorkTime + statisticData.AuxOutOfWorkTime : 0
      ],
      labels: [
        __('STATISTICS.CHARTS.STATES.LABELS.AVAILABLE'),
        __('STATISTICS.CHARTS.STATES.LABELS.IN_SESSION'),
        __('STATISTICS.CHARTS.STATES.LABELS.WRAPUP'),
        __('STATISTICS.CHARTS.STATES.LABELS.BREAKS')],
      colors: ['#57A253', '#849BB0', '#c53369', '#FC7A2A'],
      tooltipFormatter: formatTime
    },
      {
        title: __('STATISTICS.CHARTS.BREAKS.TITLE'),
        subtitle: __('STATISTICS.CHARTS.BREAKS.SUBTITLE'),
        type: ChartType.DOUGHNUT,
        data: [
          statisticData ? statisticData.AuxOutOfWorkTime : 0,
          statisticData ? statisticData.AuxInWorkTime : 0
        ],
        labels: [
          __('STATISTICS.CHARTS.BREAKS.LABELS.NON_PRODUCTIVE'),
          __('STATISTICS.CHARTS.BREAKS.LABELS.PRODUCTIVE')
        ],
        colors: ['#FC7A2A', '#ffb64f'],
        tooltipFormatter: formatTime
      },
      {
        title: __('STATISTICS.CHARTS.SESSIONS.TITLE'),
        subtitle: __('STATISTICS.CHARTS.SESSIONS.SUBTITLE'),
        type: ChartType.DOUGHNUT,
        data: [
          statisticData ? statisticData.InboundVoiceAcceptedSessions
          + statisticData.OutboundVoiceAcceptedSessions
          + statisticData.ManualVoiceConnectedSessions
          + statisticData.PreviewVoiceConnectedSessions : 0,
          statisticData ? statisticData.ChatAcceptedSessions : 0,
          statisticData ? statisticData.EmailAcceptedSessions : 0,
          statisticData ? statisticData.SocialAcceptedSessions : 0
        ],
        labels: [
          __('STATISTICS.CHARTS.SESSIONS.LABELS.CONNECTIONS'),
          __('STATISTICS.CHARTS.SESSIONS.LABELS.CHAT'),
          __('STATISTICS.CHARTS.SESSIONS.LABELS.EMAIL'),
          __('STATISTICS.CHARTS.SESSIONS.LABELS.SOCIAL')],
        colors: colors
      },
      {
        title: __('STATISTICS.CHARTS.ACCEPTED_SESSIONS.TITLE'),
        subtitle: __('STATISTICS.CHARTS.ACCEPTED_SESSIONS.SUBTITLE'),
        type: ChartType.DOUGHNUT,
        data: [
          acceptedSessions,
          rejectedSessions
        ],
        labels: [
          __('STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.ACCEPTED'),
          __('STATISTICS.CHARTS.ACCEPTED_SESSIONS.LABELS.REJECTED')],
        colors: colors
      },
      {
        title: __('STATISTICS.CHARTS.SUCCESSES.TITLE'),
        subtitle: __('STATISTICS.CHARTS.SUCCESSES.SUBTITLE'),
        type: ChartType.DOUGHNUT,
        data: [success, acceptedSessions],
        labels: [
          __('STATISTICS.CHARTS.SUCCESSES.LABELS.SUCCESSES'),
          __('STATISTICS.CHARTS.SUCCESSES.LABELS.ACCEPTED')],
        colors: colors
      }];

    return charts;
  }

  /**
   * maps from AxDataTable to Salary
   */
  function mapSalary(data) {
    return data.Rows.map(function (row) {
      var result = {};

      for (var i = 0; i < row.length; i++) {
        var key = data.Columns[i];
        result[key] = row[i];
      }

      return result;
    });
  }

  const fetchQueuesRequestId = "statistics-queues";

  const onCampaignsListReceived = (evt) => {

    if ( evt.identifier === fetchQueuesRequestId ) {

      chartQueues.length = 0;
      chartQueues.push.apply(chartQueues, evt.entries.filter( c => c.ShowOnUserChart ).map( c => ( {
          label: c.Name,
          value: c.QueuedSessions
      })));
    }
  };

  const startFetchChartQueues = () => {

    SessionClient.on("campaignslistreceived", onCampaignsListReceived);
    SessionClient.startFetchCampaigns({
      inGroup: null,
      withGroups: true,
      onlyDialable: false,
      inboundWorking: true,
      outboundWorking: null,
      withCampaignParameters: false,
      withUserDialingPossibility: false,
      requestIdentifier: fetchQueuesRequestId
    });

  };

  const stopFetchChartQueues = () => {

    SessionClient.stopFetchCampaigns( { fetchQueuesRequestId });
    SessionClient.removeListener("campaignslistreceived", onCampaignsListReceived);

  };

  const getChartQueues = () => chartQueues;

  this.getInfoTiles = getInfoTiles;
  this.setStatisticData = setStatisticData;
  this.getCounters = getCounters;
  this.getChartsData = getChartsData;
  this.mapSalary = mapSalary;
  this.changeLanguage = changeLanguage;
  this.startFetchChartQueues = startFetchChartQueues;
  this.stopFetchChartQueues = stopFetchChartQueues;
  this.getChartQueues = getChartQueues;
}

export { StatisticHelperService };
