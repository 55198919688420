import template from './AxFilterText.html';

function AxFilterText() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      filterItem: '=ngModel'
    },
    link: link,
    require: 'ngModel',
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: [controller]
  }

}

function controller() {

  var vm = this;

  vm.isValid = true;
  vm.invalidMessage = "";
  vm.allowNull = true;

  var conds = ax.AxDataLib.AxDataQueryCondition;

  vm.allowedOperators = [conds.Like,
    conds.NotLike,
    conds.Equal,
    conds.NotEqual];

}

function link($scope, $element, $attrs, $ngModel) {

}

export { AxFilterText };
