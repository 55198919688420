function VideoService(WebRtcBuddyService, MediaProxyClientService, Errors) {

  // Public API
  this.dialVideo = dialVideo;
  this.setDialingState = setDialingState;
  this.getDialingState = getDialingState;
  this.setAlertingState = setAlertingState;
  this.getAlertingState = getAlertingState;
  this.setAssociatedInteraction = setAssociatedInteraction;
  this.getAssociatedInteraction = getAssociatedInteraction;
  this.setCallProperties = setCallProperties;
  this.getCallProperties = getCallProperties;
  this.resetCallProperties = resetCallProperties;

  // Private Code
  var dialing = false,
    alerting = false,
    associatedInteraction = null,
    callProperties = {};

  function setDialingState(state) {
    dialing = state;
  }

  function getDialingState() {
    return dialing;
  }

  function setAlertingState(state) {
    alerting = state;
  }

  function getAlertingState() {
    return alerting;
  }

  function setAssociatedInteraction(interaction) {
    associatedInteraction = interaction;
  }

  function getAssociatedInteraction() {
    return associatedInteraction;
  }

  function setCallProperties(props) {

    var propNames = Object.getOwnPropertyNames(props);
    propNames.forEach(function (propName) {
      callProperties[propName] = props[propName];
    });

  }

  function getCallProperties() {
    return callProperties;
  }

  function resetCallProperties() {
    var propNames = Object.getOwnPropertyNames(callProperties);
    propNames.forEach(function (propName) {
      delete callProperties[propName];
    });
  }

  /**
   * Makes video call to specified number
   * Returns promise resolved with videoCall or rejected with error
   * @param {Object} p Configuration object
   * @param {Function} p.rtcConfiguration Function to get rtcConfigiration
   * @param {String} p.number
   * @return {Promise}
   */
  function dialVideo(p) {

    dialing = true;

    // TODO: Tutaj na każdym etapie musimy dorobić sprawdzanie, czy state dzwonienia jest active
    // Bo może zostać klikniety hangup
    // np zanim zaczniemy dzwonić
    return WebRtcBuddyService.getAvailableConstraints().then(function (constraints) {

      if (constraints.length == 0) {
        return Promise.reject(GET_USER_MEDIA_ERROR());
      }

      // Biere pierwszy z brzegu
      var constraint = constraints[0];
      if (dialing === false) {
        return Promise.reject(DIALING_STATE_CHANGED_EXTERNALLY());
      }
      return p.getRtcConfiguration().then(function (rtcConfiguration) {
        return {
          rtcConfiguration: rtcConfiguration,
          constraint: constraint
        }
      });

    }).then(function (r) {

      if (dialing === false) {
        return Promise.reject(DIALING_STATE_CHANGED_EXTERNALLY());
      }

      return WebRtcBuddyService.prepare({
        outgoing: true,
        relayOnly: true,
        removeHostCandidates: true,
        removeIPv6Candidates: true,
        rtcConfiguration: r.rtcConfiguration,
        audio: r.constraint.audio,
        video: r.constraint.video
      })
    }).then(function (sdp) {

      if (dialing === false) {
        return Promise.reject(DIALING_STATE_CHANGED_EXTERNALLY());
      }
      return MediaProxyClientService.dial({
        sdp: sdp,
        number: p.number
      })

    }).then(function (videoCall) {

      if (dialing === false) {
        return Promise.reject(DIALING_STATE_CHANGED_EXTERNALLY());
      }
      dialing = false;
      return Promise.resolve(videoCall);

    }).catch(function (err) {
      dialing = false;
      return Promise.reject(err);
    });

  }

  /** Error definitions */
  function Error() {
    throw "Can't instantiate this is abstract class";
  }

  Error.prototype = Object.freeze(Object.create(Errors.Error.prototype));

  function DIALING_STATE_CHANGED_EXTERNALLY() {
    return Object.freeze(Object.create(DIALING_STATE_CHANGED_EXTERNALLY.prototype));
  }

  DIALING_STATE_CHANGED_EXTERNALLY.prototype = Object.freeze(Object.create(Error.prototype, {
    code: { value: 0, enumerable: true },
    message: { value: "Dialing state changed externally", enumerable: true }
  }));

  function GET_USER_MEDIA_ERROR() {
    return Object.freeze(Object.create(GET_USER_MEDIA_ERROR.prototype));
  }

  GET_USER_MEDIA_ERROR.prototype = Object.freeze(Object.create(Error.prototype, {
    code: { value: 1, enumerable: true },
    message: { value: "Get user media error", enumerable: true }
  }));

  function OTHER_EXCEPTION() {
    return Object.freeze(Object.create(OTHER_EXCEPTION.prototype));
  }

  OTHER_EXCEPTION.prototype = Object.freeze(Object.create(Error.prototype, {
    code: { value: 255, enumerable: true },
    message: { value: "Other exception", enumerable: true }
  }));

  var ERRORS = Object.freeze(Object.create(null, {
    DIALING_STATE_CHANGED_EXTERNALLY: {
      value: DIALING_STATE_CHANGED_EXTERNALLY,
      enumerable: true
    },
    GET_USER_MEDIA_ERROR: {
      value: GET_USER_MEDIA_ERROR,
      enumerable: true
    },
    OTHER_EXCEPTION: {
      value: OTHER_EXCEPTION,
      enumerable: true
    }
  }));

  this.Error = Error;
  this.ERRORS = ERRORS;
}

export { VideoService };
