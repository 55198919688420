export function AxRefreshCrmFrame() {
  return {
    restrict: 'A',

    link: function ($scope, $element, $attrs) {

      const interactionId = $attrs.axInteractionId;

      $scope.$on("refreshCrmFrame", function (event, data) {

        if (interactionId === data.interaction.session.Session.SessionId) {


          let oldUrl;
          // Because cross origin iframe access to contentWindow is not allowed
          try {
            oldUrl = $element[0].contentWindow.location.href;
          } catch (e) {
            console.warn("CRM is on different origin. Reloading unconditionally");
          }

          if (oldUrl == "" || data.newUrl !== oldUrl) {
            $element.attr('src', $element.attr('src'));
          }
        }

      });

    }
  };
}
