import template from './AxSurveyFieldTime.html';

function AxSurveyFieldTime() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  vm.timeFormat = "HH:mm";
  vm.axC.getValue = function () {
    if (vm.axC.Value) {
      return moment(vm.axC.Value, vm.timeFormat).format(vm.timeFormat);
    }
    return null;
  };
  vm.axC.setValue = function (val) {
    if (val) {
      vm.axC.Value = moment(val, vm.timeFormat).format(vm.timeFormat);
    }
    else {
      vm.axC.Value = null;
    }
    return true;
  };
  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      if (!val) {
        return "fieldIsRequired";
      }
    }
    return null;
  };
  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true && (!vm.axC.getValue() || vm.timeFormat.length == vm.axC.getValue().length)) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
}

export { AxSurveyFieldTime };
