import { Sms } from '@next/webagent';

/** @ngInject */
export function AxSms(reactDirective) {
  return reactDirective(Sms, [
    'language',
    'from',
    'phoneNumber',
    'message',
    'maxSms',
    'accountName',
    'onSend',
    'dialpad'
  ]);
}
