import * as React from 'react';
import { FilePlayer } from './players';
import { PLAYER_DEFAULT_PROPS } from './constants'
import { isFunction } from './utils'

export class Player extends React.Component<any> {
  static defaultProps = { ...PLAYER_DEFAULT_PROPS };

  isLoading: boolean = true;
  isPlaying: boolean = false;
  player: FilePlayer;

  componentWillReceiveProps(next: any) {
    const prev = this.props;
    const { muted: prevMuted, playing: prevPlaying, src: prevSrc } = prev;
    const { muted: nextMuted, playing: nextPlaying, src: nextSrc } = next;

    if (prevSrc !== nextSrc) {
      this.isLoading = true;
    }

    if (nextPlaying && !this.isPlaying) {
      this.player.play();
    }

    if (prevPlaying && !nextPlaying && this.isPlaying) {
      this.player.pause();
    }

    if (prevMuted !== nextMuted) {
      this.player.volume(nextMuted ? 0 : next.volume);
    }
  }

  componentWillUnmount() {
    this.player.stop();
  }

  onEnded = () => {
    const { onEnded } = this.props;

    this.isPlaying = false;

    if (isFunction(onEnded)) {
      onEnded();
    }
  };

  onPause = () => {
    const { isLoading } = this;
    const { onPause } = this.props;

    this.isPlaying = false;

    if (isFunction(onPause) && !isLoading) {
      onPause();
    }
  };

  onPlay = () => {
    const { onPlay } = this.props;

    this.isLoading = false;
    this.isPlaying = true;

    if (isFunction(onPlay)) {
      onPlay();
    }
  };

  onReady = () => {
    const { muted, playing, volume, onReady } = this.props;

    this.isLoading = false;
    this.player.volume(muted ? 0 : volume);

    // Invoke onReady before onPlay will be invoked
    if (isFunction(onReady)) {
      onReady();
    }

    if (playing) {
      this.player.play();
    }
  };

  onSeek = (event: any) => {
    console.info('onSeek from base player', event);
    // const { onSeek } = this.props;
  };

  ref = (player: FilePlayer) => {
    if (!player) return;
    this.player = player;
  };

  render() {
    return (
      <FilePlayer
        {...this.props} ref={this.ref}
        onEnded={this.onEnded}
        onPause={this.onPause}
        onPlay={this.onPlay}
        onReady={this.onReady}
        onSeek={this.onSeek}>
      </FilePlayer>
    );
  }
}
