import { SimpleEncode } from "../ax.stringencryptor";

function ChangePasswordData() {
  return {
    visible: false
  }
}

function ChangePasswordCtrl($rootScope, ComClient, Data, ChangePasswordData, CONFIG, WL) {

  var ctrl = this;
  ctrl.data = ChangePasswordData;
  ctrl.inProgress = false;
  ctrl.oldPassword = "";
  ctrl.newPassword = "";
  ctrl.confirmNewPassword = "";
  ctrl.error = false;
  ctrl.message = "";
  ctrl.syntaxMsg = "";

  ctrl.changePassword = changePassword;
  ctrl.checkPasswordSyntax = checkPasswordSyntax;

  function registerOrResolve(client) {

    if ( client.connected === true ) {
      return Promise.resolve();
    } else {

      return client.initialize( {
        settings: new ax.AxComLib.AxComClientSettings({
        proto: CONFIG.proto,
        server: CONFIG.server,
        port: CONFIG.port,
        login: CONFIG.login,
        password: SimpleEncode(ctrl.oldPassword),
        tag: "AxHostWin"
      }),
        weblib: WL
      });
    }
  }

  function changePassword() {

    ctrl.inProgress = true;
    ctrl.message = Data.li18n.messages.changingPassword;
    var oldPassword = SimpleEncode(ctrl.oldPassword);
    var newPassword = SimpleEncode(ctrl.newPassword);

    registerOrResolve(ComClient).then( () => ComClient.register({
      login: Data.login,
      password: oldPassword,
      tag: "",
      services: [],
      filters: [],
      clientVersion: "",
      newPassword: newPassword
    })).then(function () {

      ComClient.disconnect();
      $rootScope.$apply(function () {
        ctrl.message = Data.li18n.messages.passwordChanged;
        ctrl.error = false;
        ctrl.inProgress = false;

        ctrl.oldPassword = ctrl.newPassword = ctrl.confirmNewPassword = "";
        ChangePasswordData.visible = false;
      });

    }).catch(function (err) {

      $rootScope.$apply(function () {

        ctrl.message = Data.li18n.messages.passwordChangeError + ": ";

        if (err instanceof ax.AxComLib.AxMessages.AxMsgRegisterClientResponse) {

          var rResult = ax.AxComLib.AxRegisterClientResult;
          if (err.Result === rResult.InvalidCredentials || err.Result === rResult.CannotChangePassword ) {
            ctrl.message += Data.li18n.messages.invalidCredentials;
          } else if (err.Result === rResult.NotSupportedVersion) {
            ctrl.message += Data.li18n.messages.otherError;
          } else if (err.Result === rResult.ServiceUnavaileable) {
            ctrl.message += Data.li18n.messages.serviceUnavailable;
          } else {
            ctrl.message += Data.li18n.messages.otherError;
          }

        }

        ctrl.error = true;
        ctrl.inProgress = false;
        ctrl.oldPassword = ctrl.newPassword = ctrl.confirmNewPassword = "";
      });
    });

  }

  function checkPasswordSyntax() {

    var pass = ctrl.newPassword,
      flags = ComClient.getPasswordFlags(),
      minPassLen = ComClient.getPasswordMinLength();

    if (minPassLen > 0 && ctrl.newPassword && ctrl.newPassword.length < minPassLen) {
      ctrl.syntaxMsg = [Data.li18n.messages.minimumPasswordLength,
        ": ",
        minPassLen.toString(),
        " ",
        Data.li18n.messages.signs
      ].join("");
      return;
    }

    if ((flags & ComClient.PASS_CONSTRAINTS.DIGITS_AND_LETTERS) === ComClient.PASS_CONSTRAINTS.DIGITS_AND_LETTERS) {

      if (((/\d/.test(ctrl.newPassword)) && (/[a-zA-Z]/.test(ctrl.newPassword))) === false) {
        ctrl.syntaxMsg = [Data.li18n.messages.passwordMustContain,
          " ",
          Data.li18n.messages.digitsAndLetters].join("");
        return;
      }

    }

    if ((flags & ComClient.PASS_CONSTRAINTS.BIG_AND_SMALL_LETTERS) === ComClient.PASS_CONSTRAINTS.BIG_AND_SMALL_LETTERS) {

      if ((/[A-Z]/.test(ctrl.newPassword) && /[a-z]/.test(ctrl.newPassword)) === false) {
        ctrl.syntaxMsg = [Data.li18n.messages.passwordMustContain,
          " ",
          Data.li18n.messages.lowercaseAndUppercaseLetters].join("");
        return;
      }

    }

    ctrl.syntaxMsg = "";

  }

}

ChangePasswordCtrl.prototype = Object.create(null, {
  visible: {
    get: function () {
      return this.data.visible
    },
    set: function (val) {
      this.data.visible = val;
    }
  }
});

export { ChangePasswordCtrl, ChangePasswordData };
