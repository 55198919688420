import * as React from 'react';

export const MicrophoneSlashIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="currentColor">
      <g>
        <path
          d="M15 8.8L13.5 8.8C13.5 9.5 13.4 10.1 13.1 10.6L14.2 11.7C14.7 10.9 15 9.9 15 8.8L15 8.8ZM11.5 9C11.5 8.9 11.5 8.9 11.5 8.8L11.5 3.6C11.5 2.1 10.3 0.9 8.8 0.9 7.4 0.9 6.2 2.1 6.2 3.6L6.2 3.7 11.5 9 11.5 9ZM2 1.8L0.9 2.9 6.2 8.2 6.2 8.8C6.2 10.3 7.4 11.5 8.8 11.5 9 11.5 9.2 11.5 9.4 11.4L10.9 12.9C10.3 13.2 9.6 13.3 8.8 13.3 6.4 13.3 4.2 11.5 4.2 8.8L2.7 8.8C2.7 11.8 5.1 14.3 8 14.8L8 17.6 9.7 17.6 9.7 14.8C10.5 14.6 11.3 14.4 12 14L15.6 17.6 16.8 16.5 2 1.8 2 1.8Z"
        />
      </g>
    </svg>
  );
};
