import template from './AxSessionChatClientInfo.html';

/**
 * Session chat client info DOD (Directive Object Declaration)
 */
function AxSessionChatClientInfo() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      sessionInfo: '=axSessionInfo',
      session: '=axSession'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: [controller]
  }

}

function controller() {

  var vm = this;
  vm.urlVisible = vm.session.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat;
  vm.emailVisible = vm.session.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat;
  vm.languageVisible = vm.session.session.Session.SubType === ax.SessionLib.AxSessionSubtype.InboundChat;

  vm.clientEmail = function () {

    if (vm.sessionInfo && vm.sessionInfo.ContactInfo) {
      return vm.sessionInfo.ContactInfo.email || vm.li18n.messages.unknown;
    } else {
      return vm.li18n.messages.unknown;
    }

  };

  vm.copyEmailToClipboard = function (evt) {

    //evt.preventDefault();
    var emailLink = evt.target;
    var range = document.createRange();
    range.selectNode(emailLink);
    var selection = window.getSelection();
    selection.empty();
    selection.addRange(range);

    try {
      // Now that we've selected the anchor text, execute the copy command
      var successful = document.execCommand('copy');

    } catch (err) {
      console.warn('Oops, unable to copy');
    }

    selection.empty();

  };

}

function link($scope, $element, $attrs, $ctrl) {

}

export { AxSessionChatClientInfo }
