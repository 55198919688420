import angular from 'angular';
import { WebagentEditors } from './ax.webagent.editors';
import { AxCheckBox } from './ax-check-box/axCheckBox';
import { AxComboBox } from './ax-combo-box/axComboBox';
import { AxDateTimePicker } from './ax-date-time-picker/axDateTimePicker';
import { AxRadioButtonsEdit } from './ax-radio-buttons-edit/axRadioButtonsEdit';
import { AxSpinTextBox } from './ax-spin-text-box/axSpinTextBox';
import { AxTextEdit } from './ax-text-edit/axTextEdit';
import { AxTimePicker } from './ax-time-picker/axTimePicker';

export default angular.module('ax.webagent.editors', [])
  .controller("WebagentEditors", [WebagentEditors])
  .directive('axCheckBox', AxCheckBox)
  .directive('axComboBox', AxComboBox)
  .directive('axDateTimePicker', AxDateTimePicker)
  .directive('axRadioButtonsEdit', AxRadioButtonsEdit)
  .directive('axSpinTextBox', AxSpinTextBox)
  .directive('axTextEdit', AxTextEdit)
  .directive('axTimePicker', AxTimePicker)
  .name;
