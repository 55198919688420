import template from './AxContentTab.html';

export function AxContentTab() {

  return {
    restrict: 'E',
    scope: {
      width: "=axWidth"
    },
    link: link,
    transclude: true,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: [controller]
  }

}

function controller() {

  var vm = this;

}

function link($scope, $element) {

  $scope.$watch("vm.width", function (newValue) {

    if (typeof newValue !== "undefined") {

      $element.width(newValue);

    }

  });
}
