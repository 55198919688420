import template from './AxSurvey.html';

function AxSurvey() {
  $.jMaskGlobals.watchInputs = false;

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      // AxSurveySessionContainer
      container: '=axSurveyContainer', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      beginContainer: '=axSurveyBeginContainer',
      surveyInteraction: '=axSurveyInteraction',
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', 'Sender', 'SessionHelperService', 'userPhone', '$rootScope', controller]
  }
}

function controller(Helpers, Sender, SessionHelperService, userPhone, $rootScope) {
  var vm = this;

  /*vm.sharedData = {
      dataRecordStage : undefined
  };*/

  // Obiekt dzielony pomiędzy wszystkimi kontorlkami, indeksowany po id sesji skryptu
  vm.sharedData = {};

  vm.isTypeOf = function (o, t) {
    if (typeof ax.AxSurveysLib.Fields[t] === "function") {
      return o.Field instanceof ax.AxSurveysLib.Fields[t];
    }
    return false;
  };
  vm.canNext = function () {
    return SessionHelperService.canNext();
  };
  vm.next = function () {
    SessionHelperService.next();
    vm.scrollTop();
  };
  vm.back = function () {
    SessionHelperService.back();
    vm.scrollTop();
  };
  vm.canEnd = function () {
    return SessionHelperService.canCloseCurrentSession();
  };
  vm.end = function () {

    // Remove shared data
    delete vm.sharedData[vm.container.SessionId];
    var j = 1;
    SessionHelperService.closeCurrentSession();
  };
  vm.abort = function () {
    SessionHelperService.sendSurveyPageAction(ax.AxSurveysLib.AxSurveyActionType.AbortSession, null, null, null, null, null);
  };
  vm.showScriptHeader = function () {
    if (vm.beginContainer && vm.beginContainer.ScriptHeader) {
      return true;
    }
    return false;
  };
  vm.showScriptFooter = function () {
    if (vm.beginContainer && vm.beginContainer.ScriptFooter) {
      return true;
    }
    return false;
  };
  vm.showFormHeader = function () {
    if (vm.container && vm.container.CurrentForm && vm.container.CurrentForm.Form && vm.container.CurrentForm.Form.Header) {
      return true;
    }
    return false;
  };
  vm.showFormFooter = function () {
    if (vm.container && vm.container.CurrentForm && vm.container.CurrentForm.Form && vm.container.CurrentForm.Form.Footer) {
      return true;
    }
    return false;
  };
  vm.showResultState = function () {
    if (vm.container && vm.container.Ended === true && vm.container.ResultState) {
      return true;
    }
    return false;
  };
  vm.showIntermediateResultStates = function () {
    if (vm.container && vm.container.Ended && vm.container.IntermediateResultStates && vm.container.IntermediateResultStates.length > 0) {
      return true;
    }
    return false;
  };
  vm.showSummaryScriptValues = function () {
    if (vm.container && vm.container.Ended && vm.container.SummaryScriptValues && vm.container.SummaryScriptValues.length > 0) {
      return true;
    }
    return false;
  };
  vm.showSummaryFields = function () {
    if (vm.container && vm.container.Ended === true) {
      return true;
    }
    return false;
  };

  vm.scrollTop = function () {
    var formField = angular.element('.survey-form-fields');

    if (formField.offset().top !== 0) {
      formField.animate({ scrollTop: 0 }, 400);
    }
  }
}

function link($scope, $element, $attrs, $ctrl) {

  $scope.$watch('vm.container', function (newValue, oldValue) {

    if (newValue !== null && typeof newValue !== "undefined" && "SessionId" in newValue) {

      if (typeof $scope.vm.sharedData[newValue.SessionId] === "undefined") {

        $scope.vm.sharedData[newValue.SessionId] = {
          dataRecordStage: undefined,
          afterSessionState: undefined
        }

      }

    }

  });

}

export { AxSurvey };
