import { RTCPlayer as Player } from '@ufkit/media-player';

/** @ngInject */
export function RTCPlayer(reactDirective) {
  return reactDirective(Player, [
    'internalSrc',
    'externalSrc',
    'onMuteAudio',
    'onMuteVideo'
  ]);
}
