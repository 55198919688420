import angular from 'angular';
import { DialpadData } from './DialpadData.factory';
import { PhoneData } from './PhoneData.factory';
import { SearchCustomerData } from './SearchCustomerData.factory';
import { SearchFileData } from './SearchFileData.factory';
import { SearchFolderData } from './SearchFolderData.factory';
import { VideoData } from './VideoData.factory';
import { SettingsData } from './SettingsData.factory';

export default angular
  .module("ax.webagent.dataFactories", [])
  .factory('DialpadData', DialpadData)
  .factory("PhoneData", PhoneData)
  .factory("SearchCustomerData", SearchCustomerData)
  .factory("SearchFileData", SearchFileData)
  .factory("SearchFolderData", SearchFolderData)
  .factory('VideoData', VideoData)
  .factory('SettingsData', SettingsData)
  .name;
