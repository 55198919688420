export function PhoneSound(freq, soundDuration, silenceDuration) {

  this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  this.oscillator = this.audioCtx.createOscillator();
  this.gainNode = this.audioCtx.createGain();

  this.oscillator.type = 'sine';
  this.oscillator.frequency.setValueAtTime(freq, this.audioCtx.currentTime);
  this.oscillator.connect(this.gainNode);

  this.hasOscillatorStart = false;
  this.soundDuration = soundDuration;
  this.silenceDuration = silenceDuration || this.soundDuration;

  this.callbacks = {
    play: [],
    ended: []
  };

  this.addEventListener = function (eventType, fn) {
    if (['play', 'ended'].indexOf(eventType) === -1) {
      throw new Error('PhoneSound: not supported event ' + eventType);
    }

    this.callbacks[eventType].push(fn);
  };

  this.runCallbacks = function (eventType) {
    this.callbacks[eventType].forEach(function (fn) {
      fn();
    });
  };

  this.play = function () {

    var that = this;
    if (!this.interval) {

      function setGain() {
        const changeTime = 0.002;

        that.gainNode.gain.setTargetAtTime(0.5, that.audioCtx.currentTime, changeTime);
        that.gainNode.gain.setTargetAtTime(0, that.audioCtx.currentTime + that.soundDuration / 1000, changeTime);

      };

      setGain();
      this.interval = setInterval(setGain, this.soundDuration + this.silenceDuration);

      this.gainNode.connect(this.audioCtx.destination);
      this.runCallbacks('play');
    }

    if (!this.hasOscillatorStart) {
      this.oscillator.start();
      this.hasOscillatorStart = true;
    }
  };

  this.stop = function () {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
      this.gainNode.disconnect(this.audioCtx.destination);
      this.runCallbacks('ended')
    }
  }
}


export function beep(freq, incDuration, decDuration, volume) {

  var MIN_VALUE = 0.00001;
  var ctx = new AudioContext(),
    currentTime = ctx.currentTime,
    osc = ctx.createOscillator(),
    gain = ctx.createGain();

  osc.connect(gain);
  gain.connect(ctx.destination);

  gain.gain.setValueAtTime(MIN_VALUE, currentTime);
  gain.gain.exponentialRampToValueAtTime(volume, currentTime + incDuration);
  gain.gain.exponentialRampToValueAtTime(MIN_VALUE, currentTime + incDuration + decDuration);

  osc.onended = function () {
    gain.disconnect(ctx.destination);
    osc.disconnect(gain);
    ctx.close();
  };

  osc.type = 'sine';
  osc.frequency.value = freq;

  osc.start();
  osc.stop(currentTime + incDuration + decDuration);
}
