import template from './AxSurveyFieldFlowButton.html';

function AxSurveyFieldFlowButton() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  vm.axControlButtonStyle = function (field, style) {
    style = style || {};
    if (field.ButtonForeColor) {
      style.color = vm.getColor(field.ButtonForeColor)
    }
    if (field.ButtonColor) {
      style.backgroundColor = vm.getColor(field.ButtonColor)
    }
    if (field.ButtonWidth) {
      style.width = parseFloat(field.ButtonWidth.replace(",", "."))
    }
    if (field.ButtonHeight) {
      style.height = parseFloat(field.ButtonHeight.replace(",", "."))
    }
    if (field.HorizontalAlignment) {
      style.display = "block";
      switch (field.HorizontalAlignment) {
        case "Left":
          style.marginRight = "auto";
          style.marginLeft = 0;
          break;
        case "Center":
          style.marginRight = "auto";
          style.marginLeft = "auto";
          break;
        case "Right":
          style.marginRight = 0;
          style.marginLeft = "auto";
          break;
        case "Stretch":
          if (!field.ButtonWidth) {
            style.width = "100%";
          }
          break;
      }
    }
    return style;
  };
  vm.canValueSet = function () {
    return helpers.canSendFlowButtonAction();
  };
  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true) {
      return "fieldIsRequired";
    }
    return null;
  };
  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    helpers.sendFlowButtonAction(vm);
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.vm.input = $element.find("input[type=button]");
}

export { AxSurveyFieldFlowButton };
