import angular from 'angular';
import { AxStatistics } from './Statistics';
import { ChatReply } from './ChatReply';
import { RTCPlayer } from './RTCPlayer';
import { AxSms } from "./Sms";

export default angular
  .module("ax.reactComponents", ["react"])
  .directive("axStatistics", AxStatistics)
  .directive("chatReply", ChatReply)
  .directive("rtcPlayer", RTCPlayer)
  .directive("axSms", AxSms)
  .name;
