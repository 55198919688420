import template from './AxFilterSpin.html';

function AxFilterSpin() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      filterItem: '=ngModel',
      allowDouble: '=axAllowDouble'
    },
    link: link,
    require: 'ngModel',
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: [controller]
  }

}

function controller() {

  var vm = this;

  vm.isValid = true;
  vm.invalidMessage = "";
  vm.allowNull = true;

  var conds = ax.AxDataLib.AxDataQueryCondition;

  vm.allowedOperators = [conds.Equal,
    conds.NotEqual,
    conds.Greater,
    conds.GreaterOrEqual,
    conds.Lower,
    conds.LowerOrEqual];

}

function link($scope, $element, $attrs, $ngModel) {

}

export { AxFilterSpin };
