import template from './AxDataRecordShiftEdit.html';

function AxDataRecordShiftEdit() {
  return {
    restrict: 'A',
    scope: {
      li18n: '=axI18n',
      inter: "=axInteractionItem",
      name: "=axName",
      editValue: "=ngModel",
      userId: '=axUserId',
      shiftingParams: "=axShiftingParams",
      callSessionId: '=axCallSessionId',
      campaignId: "=axCampaignId",
      recordId: "=axRecordId",
      recordWasShifted: "=axRecordWasShifted",
      sessionWasAborted: "=axSessionWasAborted",
      mandatory: "=axMandatory",
      wideLabel: "=axWideLabel"
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', '$rootScope', controller]
  }
}

function controller(helpers, $rootScope) {
  var vm = this;

  vm.shiftOn = null;
  vm.shiftPhone = null;
  vm.selectedButton = null;
  vm.shiftNote = null;

  vm.shifFrom = new Date();
  vm.shifTo = null;

  if (vm.shiftingParams && vm.shiftingParams.ShiftFrom) {
    if (vm.shiftingParams.ShiftFromHour) {
      vm.shifFrom = new Date((vm.shiftingParams.ShiftFrom - 62135604000000) + vm.shiftingParams.ShiftFromHour / 1000);
    }
    else {
      vm.shifFrom = new Date(vm.shiftingParams.ShiftFrom - 62135604000000);
    }
  }
  vm.shifFrom = vm.shifFrom.getTime() >= new Date().getTime() ? vm.shifFrom : new Date();

  if (vm.shiftingParams && vm.shiftingParams.ShiftTo) {
    if (vm.shiftingParams.ShiftToHour) {
      vm.shifTo = new Date((vm.shiftingParams.ShiftTo - 62135604000000) + vm.shiftingParams.ShiftToHour / 1000);
    }
    else {
      vm.shifTo = new Date(vm.shiftingParams.ShiftTo - 62135604000000);
    }
  }
  vm.allwoShiftOrAbort = function () {
    if (vm.recordWasShifted == null && vm.sessionWasAborted == null && vm.shiftingParams) {
      return true;
    }
    return false;
  };
  vm.allowShiftToCampaign = function () {
    if (vm.allwoShiftOrAbort() &&
      (vm.shiftingParams.ShiftMode == ax.SessionLib.AxDataRecordShiftingMode.Free ||
        vm.shiftingParams.ShiftMode == ax.SessionLib.AxDataRecordShiftingMode.InCampaignTime)) {
      return true;
    }
    return false;
  }

  vm.allowShiftToMe = function () {
    if (vm.allwoShiftOrAbort() && vm.shiftingParams &&
      (vm.shiftingParams.ShiftMode == ax.SessionLib.AxDataRecordShiftingMode.Free ||
        vm.shiftingParams.ShiftMode == ax.SessionLib.AxDataRecordShiftingMode.OnlyToMe ||
        vm.shiftingParams.ShiftMode == ax.SessionLib.AxDataRecordShiftingMode.OnlyToMeInCampaignTime)) {
      return true;
    }
    return false;
  }

  vm.message = null;
  vm.isSending = false;
  vm.helpers = helpers;
  vm.editValue = vm.editValue || 0;
  vm.isTrue = helpers.isTrue;
  vm.canShiftSession = function () {
    if (vm.allwoShiftOrAbort() === false && vm.isSending == false) {
      return false;
    }
    return true;
  };
  vm.shiftSession = function () {
    if (vm.canShiftSession() === false) {
      return;
    }
    var result = confirm(vm.li18n.messages.recordShiftingMsg);
    if (result === false) {
      return;
    }
    vm.isSending = true;
    //sessionId, dataRecordId, campaignId, note, tco, forUserId, shiftOn, phoneIndex, callback
    var shiftOn = vm.shiftOn;
    if (shiftOn) {
      shiftOn = moment(shiftOn).toDate();
    }

    vm.helpers.shiftSession(vm.callSessionId, vm.recordId, vm.campaignId, vm.shiftNote, vm.selectedButton === 'user', vm.selectedButton === 'user' ? vm.userId : null, shiftOn, vm.shiftPhone, function (res) {
      $rootScope.$apply(function () {
        vm.recordWasShifted = res;
      });
      if (res === ax.SessionLib.AxShiftRecordRequestResult.Shifted) {
        vm.helpers.sendShiftSessionAction(res);
      }
      vm.isSending = false;
    });
  };

  vm.telephoneItemTemplate = function (item) {
    if (item) {
      return '<div class="phone-status ' + helpers.phoneStateColor(item.State) + '"></div>' + item.PhoneName + '(' + item.Number + ')';
    }
    return item;
  };
}

function link($scope, $element, $attrs, $ctrl) {
  $scope.$watch("vm.recordWasShifted", function () {
    var val = $scope.vm.recordWasShifted;
    if (val || val === 0) {
      if (val === ax.SessionLib.AxShiftRecordRequestResult.Shifted) {
        $scope.vm.message = "[color=green]" + $scope.vm.li18n.messages.recordShiftedSuccesfully + "[color]";
      }
      else {
        $scope.vm.message = "[color=red]" + $scope.vm.li18n.messages.unableToShiftRecord + "[color]";
      }
    }
    else {
      $scope.vm.message = null;
    }
  });

  $scope.$on("$destroy", function (event) {

  });
}

export { AxDataRecordShiftEdit };
