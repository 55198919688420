export function getSettings(state) {
  return state.settings;
}

export function SettingsReducers(SETTINGS_LOAD_SUCCESS, SoundSettingsReducers) {

  this.reducer = function (state, action) {
    state = state || {};

    if (action.type === SETTINGS_LOAD_SUCCESS) {
      return action.payload;
    }

    return {
      sound: SoundSettingsReducers.reducer(state.sound, action)
    };
  }
}
