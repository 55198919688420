import template from './AxSurveyFieldDate.html';

function AxSurveyFieldDate() {
  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', 'DateTimeHelpers', controller]
  }
}

function controller(helpers, dth) {
  var vm = this;
  helpers.initField(vm);
  vm.dateFormat = "YYYY-MM-DD";
  vm.dateMask = "0000-00-00";
  vm.axC.getValue = function () {
    if (vm.axC.Value) {
      return moment(vm.axC.Value, vm.dateFormat).format(vm.dateFormat);
    }
    return null;
  };
  vm.axC.setValue = function (val) {
    if (val) {
      vm.axC.Value = moment(val, vm.dateFormat).format(vm.dateFormat);
    }
    else {
      vm.axC.Value = null;
    }
    return true;
  };
  vm.axC.Validate = function (val) {
    var v = val,
      valueMoment,
      maxValueMoment,
      minValueMoment,
      errorMessage = [];

    if (vm.field.Mandatory === true) {
      if (!val) {
        return "fieldIsRequired";
      }
    }

    if (dth.dateRegexp(val) === false && val) {
      return [vm.li18n.messages.badFormat, " - ", vm.li18n.messages.check].concat(errorMessage).join(" ");
    }

    valueMoment = moment(v, vm.dateFormat);

    if (vm.field.MinDate) {
      minValueMoment = moment(vm.field.MinDate);
      if (valueMoment.isBefore(minValueMoment)) {

        return [vm.li18n.messages.enteredDate, vm.li18n.messages.isEarlierThan, minValueMoment.format(vm.dateFormat)].join(" ");
      }
    }

    if (vm.field.MaxDate) {
      maxValueMoment = moment(vm.field.MaxDate);
      if (valueMoment.isAfter(maxValueMoment)) {

        return [vm.li18n.messages.enteredDate, vm.li18n.messages.isLaterThan, maxValueMoment.format(vm.dateFormat)].join(" ");
      }

    }


    return null;
  };
  vm.onValueSet = function (field) {
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    vm.input.keydown().keyup();
    if (vm.axC.HasTrigger === true && (!vm.axC.getValue() || vm.dateMask.length == vm.axC.getValue().length)) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);
}

function link($scope, $element, $attrs, $ctrl) {
  var field = $scope.vm.field;

  $scope.vm.acceptedTimeRange = formatAcceptedTimeMessage(field.MinDate, field.MaxDate, $scope.vm.li18n);

  $scope.$watch('vm.li18n', function (newVal) {
    $scope.vm.acceptedTimeRange = formatAcceptedTimeMessage(field.MinDate, field.MaxDate, newVal)
  });

  function formatAcceptedTimeMessage(min, max, li18n) {

    var acceptedTimeRange = [li18n.messages.acceptedDateTimeRange + ":"],
      minMoment = moment(min),
      maxMoment = moment(max),
      dateTimeFormat = "YYYY-MM-DD",
      lFrom = li18n.messages.from.slice(0, 1).toLowerCase() + li18n.messages.from.slice(1).toLowerCase(),
      lTo = li18n.messages.to.slice(0, 1).toLowerCase() + li18n.messages.to.slice(1).toLowerCase();

    if (!min && !max) {
      acceptedTimeRange.push(li18n.messages.anyTimeRange);
    }

    if (min && !max) {
      acceptedTimeRange = acceptedTimeRange.concat([lFrom, minMoment.format(dateTimeFormat), lTo, li18n.messages.inifinity1]);
    }

    if (min && max) {
      acceptedTimeRange = acceptedTimeRange.concat([lFrom, minMoment.format(dateTimeFormat), lTo, maxMoment.format(dateTimeFormat)]);
    }

    if (!min && max) {
      acceptedTimeRange = acceptedTimeRange.concat([lFrom, li18n.messages.inifinity1, lTo, maxMoment.format(dateTimeFormat)]);
    }

    return acceptedTimeRange.join(" ");

  }

  $scope.vm.input = $element.find("input[data-input-type=date]");
  $scope.vm.dateImg = $element.find("img.cal-png");
  var value = $scope.vm.axC.getValue();
  var p = {
    field: $scope.vm.input[0],
    trigger: $scope.vm.dateImg[0],
    format: $scope.vm.dateFormat,
    disableDayFn: function (dt) {
      var maxDate = $scope.vm.maxValue ? moment($scope.vm.maxValue).toDate() : null;
      var minDate = $scope.vm.minValue ? moment($scope.vm.minValue).toDate() : null;

      var maxDate = $scope.vm.field.MaxDate ? moment($scope.vm.field.MaxDate).toDate() : null;
      var minDate = $scope.vm.field.MinDate ? moment($scope.vm.field.MinDate).toDate() : null

      var maxDateZero = maxDate ? new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate()) : null;
      var minDateZero = minDate ? new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()) : null;
      if (maxDateZero && maxDateZero.getTime() < dt.getTime()) {
        return true;
      }
      if (minDateZero && minDateZero.getTime() > dt.getTime()) {
        return true;
      }
      return false;
    }
  };
  if ($scope.vm.li18n) {
    p.i18n = $scope.vm.li18n.pikaday
  }
  $scope.vm.pickday = new Pikaday(p);
  $scope.vm.input.mask($scope.vm.dateMask);
  setTimeout(function () {
    $scope.vm.input.keydown().keyup();
  }, 100);

  $scope.vm.clockHide = function (e) {
    var target = $(e.target || e.srcElement);
    if (target.closest($element).length === 0 && target.parents(".pika-single:first").length == 0) {
      $scope.vm.pickday.hide();
    }
  };
  $(document).on("mouseup", $scope.vm.clockHide);
  $(window).on('resize', $scope.vm.clockHide);
  $scope.$on("$destroy", function (event) {
    $(document).off("mouseup", $scope.vm.clockHide);
    $(window).off('resize', $scope.vm.clockHide);
    $scope.vm.pickday.destroy();
  });
}

export { AxSurveyFieldDate }
