import template from './AxFilterBuilderOperatorList.html';

function AxFilterBuilderOperatorList() {

  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      // Array.<ax.AxDataLib.AxDataQueryCondition>
      allowedOperators: '=axAllowedOperators',
      selectedOperator: '=ngModel'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ["Operator", controller],
    require: "ngModel",
  }

}

function controller(Operator) {

  var vm = this;

  vm.operators = vm.allowedOperators.map(function (operator) {

    var names = vm.li18n.axDataQueryCondition;

    // Gdyby nie było tłumaczenia
    var name = "Unknown";

    if (operator in names) {
      name = names[operator];
    }

    var oper = new Operator(name, operator);

    return oper;

  });

  if (vm.operators.length > 0) {
    vm.selectedOperator = vm.operators[0].value;
  }

}

function link($scope, $element, $attrs, $ngModel) {
}

export { AxFilterBuilderOperatorList };
