/**
 * AxSplitVertical DOD
 */
function AxSplitVertical() {

  return {
    restrict: 'A',
    link: link,
    priority: 100,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['$scope', controller]
  }

}

function controller($scope) {

  var vm = this;
  vm.minimizedWidth = 10;

  vm.emitSplitChanged = function (data) {
    $scope.$emit('sessionTabsSplitChanged', data);
  };

  vm.emitLayoutResize = function () {
    $scope.$emit('sessionTabsLayoutResize', {});
  };

}

function link($scope, $element, $attrs, $ctrl) {

  var splitter = document.createElement("div");
  var splitterHandler = document.createElement("div");
  var splitterHandlerIco = document.createElement("i");
  splitter.classList.add("ax-splitter-vertical");

  splitterHandler.classList.add("ax-splitter-vertical-handler");

  splitterHandlerIco.classList.add("fa");
  splitterHandlerIco.classList.add("fa-ellipsis-v");

  splitterHandler.appendChild(splitterHandlerIco);
  splitter.appendChild(splitterHandler);

  $element.prepend(splitter);

  var dragStarted = false,
    $elementToResize;

  function splitterMousedown(evt) {

    $elementToResize = $element;
    //$elementToResize.find("iframe").css("pointer-events", "none");
    $("body").find("iframe").css("pointer-events", "none");
    dragStarted = true;

  }

  splitter.addEventListener("mousedown", splitterMousedown);

  function splitterDblClick(evt) {

    $scope.vm.emitSplitChanged({
      width: ""
    })

  }

  // Reset to default size on dblclick
  splitter.addEventListener("dblclick", splitterDblClick);

  function splitterHandlerDblClick(evt) {

    var dstWidth;
    var w = $element.width();
    if ($element.width() > $scope.vm.minimizedWidth) {
      dstWidth = $scope.vm.minimizedWidth + "px";
    } else {
      dstWidth = "";
    }

    $element.width(dstWidth);

    $scope.vm.emitSplitChanged({
      width: dstWidth
    });


    evt.stopPropagation();

  }

  splitterHandler.addEventListener("dblclick", splitterHandlerDblClick);

  function documentMouseup() {

    if (dragStarted === true) {

      $("body").find("iframe").css("pointer-events", "auto");

      dragStarted = false;

      // Emitujemy event ze zmianą, a apka sobie zapisze już co chce
      $scope.vm.emitSplitChanged({
        width: $elementToResize.width()
      });

      $elementToResize = null;

    }

  }

  document.addEventListener("mouseup", documentMouseup);

  function documentMousemove(evt) {

    var targetW;
    if (dragStarted === true) {

      $elementToResize.width(targetW);

      var dstWidth = $("body").width() - evt.x;

      // Szerokość to całość ekranu minus pozycja kursora
      if (dstWidth > $scope.vm.minimizedWidth) {
        $elementToResize.width(dstWidth + "px");
        $scope.vm.emitLayoutResize();
      }

    }

  }

  document.addEventListener("mousemove", documentMousemove);

  $scope.$watch($attrs.axSplitVertical, function (newValue, oldValue) {

    if (newValue === true) {
      splitter.style.display = "block";
    } else {
      splitter.style.display = "none";
    }

  });

  $scope.$on("$destroy", function (event) {

    splitter.removeEventListener("mousedown", splitterMousedown);
    splitter.removeEventListener("dblclick", splitterDblClick);
    splitterHandler.removeEventListener("dblclick", splitterHandlerDblClick);
    document.removeEventListener("mouseup", documentMouseup);
    document.removeEventListener("mousemove", documentMousemove);
  });

}

export { AxSplitVertical };
