var filterItem = function FilterItem(value) {
  this.value = value;
  this.condition = null;
  this.fieldName = "";
  this.fieldId = null;
}

/**
 * FilterFactory
 */
function FilterItemFactory() {

  return filterItem;

}

export { FilterItemFactory };
