import template from './AxChooseCustomer.html';

export function ChooseCustomer() {
  return {
    restrict: 'E',
    scope: {
      li18n: '=axI18n',
      selectedCustomer: '=axSelectedCustomer',
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller
  }

}

/** @ngInject */
function controller($scope, Errors, ApiRequestWrapperService, MiniApiService) {

  var vm = this;
  vm.customer = null;

  vm.showData = function () {
    console.log(vm);
  };

  vm.getCustomer = function (id) {

    ApiRequestWrapperService.wrapRequest({
      requestFnc: MiniApiService.getCustomer,
      requestFncParams: [{ id: id }],
      that: MiniApiService,
      errorDef: Errors.CommonRestErrors.INVALID_TOKEN
    }).then(function (customer) {

      vm.customer = customer;

    }).catch(function (err) {

      console.error("Get customer error", err);

    });

  };

  vm.openCustomerSearch = function () {

    $scope.$emit("openCustomerSearchRequest");

  };

  vm.removeCustomerAssign = function () {

    $scope.$emit("setSessionCustomerContextRequest", { customerId: null, contactId: null });

  };

}

function link($scope) {

  $scope.$watch('vm.selectedCustomer', function (newValue) {

    //if (  typeof newValue !== "undefined" && newValue !== oldValue ) {
    if (typeof newValue !== "undefined") {

      if (newValue !== null) {

        if (($scope.vm.customer === null) || ($scope.vm.customer !== null && $scope.vm.customer.Id !== newValue)) {

          $scope.vm.getCustomer(newValue);

        }


      } else {
        $scope.vm.customer = null;
      }

    }

  });

}
