function FileService(LoginService, Errors, CONFIG, $http) {

  this.getImage = getImage;
  this.getFile = getFile;

  /**
   * 1. Gets download access token
   * 2. Gets image with token
   * Returns promise with imageUrl
   * @param {Object} p Parameters object
   * @param {String} p.refreshToken Refresh token
   * @param {String} p.fileId Image id to download
   * @param {String} p.fileUrl File url
   * @param {Number} p.tokenLifetime Requested download token lifetime
   * @return {Promise}
   */
  function getImage(p) {

    return new Promise(function (resolve, reject) {

      LoginService.getAccessToken({
        refreshToken: p.refreshToken,
        tokenType: "download",
        tokenParams: {
          file_id: p.fileId,
          token_lifetime: p.tokenLifetime
        }
      }).then(function (res) {
        var tokenUrl;

        if (p.fileUrl.indexOf("token") !== -1) {
          tokenUrl = p.fileUrl.replace(/(token=).*?(&)/, '$1' + res.accessToken + '$2');
        } else {
          tokenUrl = p.fileUrl + "?token=" + res.accessToken;
        }

        $http.get(tokenUrl, {
          responseType: "arraybuffer"
        }).then(function (res) {
          var data = res.data,
            urlCreator = window.URL || window.webkitURL,
            imageUrl = "", blob;

          if (data !== null) {
            blob = new Blob([data], { type: "image/png" });

            imageUrl = urlCreator.createObjectURL(blob);
          }

          resolve(imageUrl);
        }).catch(function (res) {
          reject(res.data);
        })

      });
    });
  }


  function getFile(url) {

    return new Promise(function (resolve, reject) {

      $http.get(url, {
        responseType: "arraybuffer"
      }).then(function (res) {
        var data = res.data;

        var urlCreator = window.URL || window.webkitURL,
          imageUrl = "",
          blob;

        if (data !== null) {
          //blob = new Blob([data], {type: "image/png"});
          blob = new Blob([data], { type: "octet/stream" });
          imageUrl = urlCreator.createObjectURL(blob);
        }

        resolve(imageUrl);
      }).catch(function (res) {
        reject(res.data);
      })
    });

  }

  /** Error definitions */
  function Error() {
  }

  Error.prototype = Object.create(Errors.Error.prototype);
}

export { FileService }
