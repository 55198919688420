import angular from 'angular';
import { Helpers, DateTimeHelpers, surveyModel } from './ax.webagent.survey';
import { AxDataRecordAbortEdit } from './ax-data-record-abort-edit/axDataRecordAbortEdit';
import { AxDataRecordEdit } from './ax-data-record-edit/axDataRecordEdit';
import { AxDataRecordShiftEdit } from './ax-data-record-shift-edit/axDataRecordShiftEdit';
import { AxDataRecordsListView } from './ax-data-records-list-view/axDataRecordsListView';
import { AxSurvey } from './ax-survey/axSurvey';
import { AxSurveyFieldBlindTransfer } from './fields/ax-survey-field-blind-transfer/axSurveyFieldBlindTransfer';
import { AxSurveyFieldCheckBox } from './fields/ax-survey-field-check-box/axSurveyFieldCheckBox';
import { AxSurveyFieldDataRecord } from './fields/ax-survey-field-data-record/axSurveyFieldDataRecord';
import { AxSurveyFieldDataRecordAbort } from './fields/ax-survey-field-data-record-abort/axSurveyFieldDataRecordAbort';
import { AxSurveyFieldDataRecordShift } from './fields/ax-survey-field-data-record-shift/axSurveyFieldDataRecordShift';
import { AxSurveyFieldDate } from './fields/ax-survey-field-date/axSurveyFieldDate';
import { AxSurveyFieldDateTime } from './fields/ax-survey-field-date-time/axSurveyFieldDateTime';
import { AxSurveyFieldDisconnectCall } from './fields/ax-survey-field-disconnect-call/axSurveyFieldDisconnectCall';
import { AxSurveyFieldDropDownList } from './fields/ax-survey-field-drop-down-list/axSurveyFieldDropDownList';
import { AxSurveyFieldDtmf } from './fields/ax-survey-field-dtmf/axSurveyFieldDtmf';
import { AxSurveyFieldEmail } from './fields/ax-survey-field-email/axSurveyFieldEmail';
import { AxSurveyFieldFloatNumber } from './fields/ax-survey-field-float-number/axSurveyFieldFloatNumber';
import { AxSurveyFieldFlowButton } from './fields/ax-survey-field-flow-button/axSurveyFieldFlowButton';
import { AxSurveyFieldHtml } from './fields/ax-survey-field-html/axSurveyFieldHtml';
import { AxSurveyFieldMakeCall } from './fields/ax-survey-field-make-call/axSurveyFieldMakeCall';
import { AxSurveyFieldNumber } from './fields/ax-survey-field-number/axSurveyFieldNumber';
import { AxSurveyFieldRadioButtons } from './fields/ax-survey-field-radio-buttons/axSurveyFieldRadioButtons';
import { AxSurveyFieldRecording } from './fields/ax-survey-field-recording/axSurveyFieldRecording';
import { AxSurveyFieldSendMail } from './fields/ax-survey-field-send-mail/axSurveyFieldSendMail';
import { AxSurveyFieldSendSms } from './fields/ax-survey-field-send-sms/axSurveyFieldSendSms';
import { AxSurveyFieldSetAfterSessionState } from './fields/ax-survey-field-set-after-session-state/axSurveyFieldSetAfterSessionState';
import { AxSurveyFieldSetDataRecordStage } from './fields/ax-survey-field-set-data-record-stage/axSurveyFieldSetDataRecordStage';
import { AxSurveyFieldText } from './fields/ax-survey-field-text/axSurveyFieldText';
import { AxSurveyFieldTextValue } from './fields/ax-survey-field-text-value/axSurveyFieldTextValue';
import { AxSurveyFieldTime } from './fields/ax-survey-field-time/axSurveyFieldTime';
import { AxSurveysFieldWebBrowser } from './fields/ax-surveys-field-web-browser/axSurveysFieldWebBrowser';
import { AxSurveySingleFieldCheckBox } from './fields/ax-survey-single-field-check-box/axSurveySingleFieldCheckBox';
import { AxSurveysMaskedTextValue } from './fields/ax-surveys-masked-text-value/axSurveysMaskedTextValue';

export default angular.module('ax.webagent.survey', ['ngSanitize'])
  .service('Helpers', ['SessionHelperService', 'SurveyService', 'userPhone', '$rootScope', Helpers])
  .service('DateTimeHelpers', [DateTimeHelpers])
  .directive('surveyModel', ['$filter', '$window', '$parse', '$timeout', surveyModel])
  .directive('axDataRecordAbortEdit', AxDataRecordAbortEdit)
  .directive('axDataRecordEdit', AxDataRecordEdit)
  .directive('axDataRecordShiftEdit', AxDataRecordShiftEdit)
  .directive('axDataRecordsListView', AxDataRecordsListView)
  .directive('axSurvey', AxSurvey) // Name must be lowercase!!!
  .directive('axSurveyFieldBlindTransfer', AxSurveyFieldBlindTransfer) // Name must be lowercase!!!
  .directive('axSurveyFieldCheckBox', AxSurveyFieldCheckBox) // Name must be lowercase!!!
  .directive('axSurveyFieldDataRecord', AxSurveyFieldDataRecord) // Name must be lowercase!!!
  .directive('axSurveyFieldDataRecordAbort', AxSurveyFieldDataRecordAbort) // Name must be lowercase!!!
  .directive('axSurveyFieldDataRecordShift', AxSurveyFieldDataRecordShift) // Name must be lowercase!!!
  .directive('axSurveyFieldDate', AxSurveyFieldDate) // Name must be lowercase!!!
  .directive('axSurveyFieldDateTime', AxSurveyFieldDateTime) // Name must be lowercase!!!
  .directive('axSurveyFieldDisconnectCall', AxSurveyFieldDisconnectCall) // Name must be lowercase!!!
  .directive('axSurveyFieldDropDownList', AxSurveyFieldDropDownList) // Name must be lowercase!!!
  .directive('axSurveyFieldDtmf', AxSurveyFieldDtmf) // Name must be lowercase!!!
  .directive('axSurveyFieldEmail', AxSurveyFieldEmail) // Name must be lowercase!!!
  .directive('axSurveyFieldFloatNumber', AxSurveyFieldFloatNumber) // Name must be lowercase!!!
  .directive('axSurveyFieldFlowButton', AxSurveyFieldFlowButton) // Name must be lowercase!!!
  .directive('axSurveyFieldHtml', AxSurveyFieldHtml) // Name must be lowercase!!!
  .directive('axSurveyFieldMakeCall', AxSurveyFieldMakeCall) // Name must be lowercase!!!
  .directive('axSurveyFieldNumber', AxSurveyFieldNumber) // Name must be lowercase!!!
  .directive('axSurveyFieldRadioButtons', AxSurveyFieldRadioButtons) // Name must be lowercase!!!
  .directive('axSurveyFieldRecording', AxSurveyFieldRecording) // Name must be lowercase!!!
  .directive('axSurveyFieldSendMail', AxSurveyFieldSendMail) // Name must be lowercase!!!
  .directive('axSurveyFieldSendSms', AxSurveyFieldSendSms) // Name must be lowercase!!!
  .directive('axSurveyFieldSetAfterSessionState', AxSurveyFieldSetAfterSessionState)
  .directive('axSurveyFieldSetDataRecordStage', AxSurveyFieldSetDataRecordStage) // Name must be lowercase!!!
  .directive('axSurveyFieldText', AxSurveyFieldText) // Name must be lowercase!!!
  .directive('axSurveyFieldTextValue', AxSurveyFieldTextValue) // Name must be lowercase!!!
  .directive('axSurveyFieldTime', AxSurveyFieldTime) // Name must be lowercase!!!
  .directive('axSurveysFieldWebBrowser', AxSurveysFieldWebBrowser) // Name must be lowercase!!!
  .directive('axSurveySingleFieldCheckBox', AxSurveySingleFieldCheckBox) // Name must be lowercase!!!
  .directive('axSurveysMaskedTextValue', AxSurveysMaskedTextValue) // Name must be lowercase!!!
  .name;
