import template from './AxLoader.html';

/** @ngInject */
export function AxLoader($timeout, $interval) {
  return {
    restrict: 'E',
    scope: {
      loading: '=loading'
    },
    link: function ($scope) {
      $scope.$watch("vm.loading", watchLoading);

      function watchLoading(newVal, oldVal) {
        if (newVal) {
          $scope.vm.showLoader = true;

          $scope.vm.watchTime = $scope.$watch("vm.timeBump", function () {
            $timeout($scope.vm.increaseTime, $scope.vm.timeBump);
          });

          if ($scope.vm.progress >= 100) {
            $scope.vm.progress = 0;
          }
        } else if (oldVal) {
          var interval = $interval(function () {
            $scope.vm.progress += 3;

            if ($scope.vm.progress >= 100) {
              $scope.vm.watchTime();
              $interval.cancel(interval);
              $scope.vm.showLoader = false;
              $scope.vm.timeBump = 10;
              $scope.vm.progress = 0;
            }
          }, 5);
        }
      }
    },
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: AxLoaderController
  }
}

function AxLoaderController() {
  var vm = this;

  vm.progress = 0;
  vm.timeBump = 10;
  vm.showLoader = false;
  vm.increaseTime = increaseTime;

  //////

  function increaseTime() {
    if (vm.progress >= 80) {
      vm.progress += 0.05;
    } else {
      vm.progress += 0.1;
    }

    vm.timeBump = vm.timeBump * 1.005;
  }
}
