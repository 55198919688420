function NotificationData() {
  return {
    resetAllCounters: function () {
      this.newSessionNotification.voiceSessionCnt = 0;
      this.newSessionNotification.chatSessionCnt = 0;
      this.newSessionNotification.facebookSessionCnt = 0;
      this.newSessionNotification.emailSessionCnt = 0;
      this.newSessionNotification.unreadChatMessagesCnt = 0;
      this.newSessionNotification.videoSessionCnt = 0;
    },
    newSessionNotification: {
      voiceSessionCnt: 0,
      chatSessionCnt: 0,
      facebookSessionCnt: 0,
      emailSessionCnt: 0,
      unreadChatMessagesCnt: 0,
      videoSessionCnt: 0
    }
  }
}

export { NotificationData };
