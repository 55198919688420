function SessionClient(sClient) {

  this.on = on;
  this.removeListener = removeListener;
  this.sendEmailEvent = sendEmailEvent;
  this.acceptSession = acceptSession;
  this.rejectSession = rejectSession;
  this.setSessionParam = setSessionParam;
  this.sendSessionChatResponseMessage = sendSessionChatResponseMessage;
  this.getChatSessionMessages = getChatSessionMessages;
  this.getUserSalary = getUserSalary;
  this.startFetchCampaigns = startFetchCampaigns;
  this.stopFetchCampaigns = stopFetchCampaigns;


  function on(evtName, callback) {
    return sClient.on(evtName, callback);
  }

  function removeListener(evtName, callback ){
    return sClient.removeListener(evtName, callback);
  }

  /**
   * Sends email event
   * @param Object p Parameters object
   * @param {ax.SessionLib.AxEmailEventsType} p.type
   * @param {ax.SessionLib.AxEmailEventsEventType} p.eventType
   * @param {Number} p.userId
   * @param {String} p.userStatusUid
   * @param {String} p.emailMessageId
   * @param {String} [p.sessionId=null]
   * @param {String} [p.tag=""]
   * @returns {Promise}
   */
  function sendEmailEvent(p) {

    return sClient.sendEmailEvent(p);

  }

  /**
   * Accepts session
   * @param Object p Parameters object
   * @param {String} sessionId Session id as GUID
   * @param {Number} userId User which accepts session
   * @returns {Promise}
   */
  function acceptSession(p) {

    return sClient.acceptSession(p);
  }

  /**
   * Rejects session
   * @param Object p Parameters object
   * @param {String} sessionId Session id as GUID
   * @param {Number} userId User which rejects session
   * @returns {Promise}
   */
  function rejectSession(p) {

    return sClient.rejectSession(p);
  }


  /**
   * Sets session param
   * @function
   * @param {Object} p Parameters object
   * @param {String} p.sessionId Id (guid) of session to set params
   * @param {ax.SessionLib.AxSessionParam} p.param Parameter to set
   * @param {String} [p.paramValue=""] Parameter value to set
   * @param {String} [p.paramValue2=""] Parameter value2 to set
   * @param {?Number} [p.campaignId=null] Campaign id
   * @param {?Number} [p.sessionSerialId=null] Session serial id
   * @return {external:Promise}
   */
  function setSessionParam(p) {

    return sClient.setSessionParam(p);

  }

  /**
   * Sends response to session chat message
   * @param {Object} p
   * @param {Object} p.project
   * @param {Number} p.project.Id
   * @param {String} p.project.Name
   * @param {String} p.sessionId
   * @param {String} p.message
   * @param {Array} [attachments=[]] Attachments
   * @return {external:Promise}
   */
  function sendSessionChatResponseMessage(p) {
    return sClient.sendSessionChatResponseMessage(p);
  }

  /**
   * Gets chat session messages
   * @param {Object} p Parameters object
   * @param {String} p.sessionId
   * @param {?String} [p.conversationTag=null]
   * @param {?Number} [p.page=null]
   * @param {?Number} [p.pageLength=null]
   */
  function getChatSessionMessages(p) {
    return sClient.getChatSessionMessages(p);
  }

  /**
   * Gets user salary
   * @param {Number} userId
   */
  function getUserSalary(userId) {
    return sClient.getUserSalary(userId);
  }


    /**
     * Starts cyclic fetch campaigns (only if is not already started)
     * @function
     * @param {Object} p Parameters object
     * @param {?Numeric} p.inGroup
     * @param {Boolean} p.withGroups
     * @param {Boolean} p.onlyDialable
     * @param {?Boolean} [p.inboundWorking=null]
     * @param {?Boolean} [p.outboundWorking=null]
     * @param {Boolean} [p.withCampaignParameters=false]
     * @param {Boolean} [p.withUserDialingPossibility=false]
     * @param {Number} [refreshInterval] Optional refresh interval in milliseconds. If not set then interval will be get from refreshInterval object property. Setting this param also overwrites refreshInterval object property.
     * @param {Mixed} [requestIdentifier=undefined] Will be added to campaignslistreceived
     */
  function startFetchCampaigns(p) {
      return sClient.startFetchCampaigns(p);
  }

  /**
   * Stops fetching campaigns with given request indentifier
   * @param {Mixed} requestIdentifier
   */
  function stopFetchCampaigns(requestIdentifier) {
    return sClient.stopFetchCampaigns(requestIdentifier);
  }

}

export { SessionClient };
