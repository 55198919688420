import template from './AxSurveyFieldMakeCall.html';

function AxSurveyFieldMakeCall() {

  return {
    restrict: 'E',
    scope: {
      // We don't want long names :)
      axC: '=axControl', // Bi-directional binding - to można zapodawac do kązdej dyrektywy - definiuje jak są ich wartości bindowane
      li18n: '=axI18n'
    },
    link: link,
    template,
    controllerAs: 'vm',
    bindToController: true,
    controller: ['Helpers', controller]
  }
}

function controller(helpers) {

  var vm = this;
  helpers.initField(vm);
  vm.canMakeCall = function () {
    return helpers.canMakeCall(vm.axC.Value);
  };

  vm.makeCall = function () {

    var ani = vm.field.Ani !== null ? vm.field.Ani : "";
    helpers.makeCall(vm.axC.Value, ani);

  };

  vm.axC.Validate = function (val) {
    if (vm.field.Mandatory === true && helpers.isTrue(vm.field.Editable)) {
      if (!val) {
        return "fieldIsRequired";
      }
    }
    return null;
  };
  vm.onValueSet = function (field) {
    field = field || vm.axC;
    field.AnswerDateTime = new Date().getTime();
    vm.validate();
    if (vm.axC.HasTrigger === true) {
      vm.sendSetValueAction(vm);
    }
  };

  vm.axC.setValue(vm.axC.Value);
  vm.validate();
  helpers.endInitField(vm);

}

function link($scope, $element, $attrs, $ctrl) {
  var input = $element.find("input[type=button]");
}

export { AxSurveyFieldMakeCall };
